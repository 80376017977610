import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import { IdInterface } from 'types/store';
import {
  addUsersGroupsSourceAccess,
  deleteAdminSource,
  deleteSourceAccessGroup,
  deleteSourceAccessUser,
  loadAdminSources,
  loadAdminSourceUsersAndGroups,
  updateSourceAccessGroup,
  updateSourceAccessUser,
} from 'store/reducers/adminSources/api';
import {
  ActiveSourceInterface,
  AdminSourcesActionsTypes,
  DeleteGroupSourceAccessByIdPayload,
  DeleteUserSourceAccessByIdPayload,
} from 'store/reducers/adminSources/types';
import { SourceDataInterface } from 'store/reducers/sources/types';
import { changeActiveSource, deleteByIdAdminSource, deleteByIdAdminSourceAccess, updateSourceAccess } from '.';
import {
  AccessInterface,
  AddUsersGroupsSourceAccessPayload,
  UpdateGroupSourceAccessByIdPayload,
  UpdateUserSourceAccessByIdPayload,
} from 'types/types';
import { TState } from 'store/index';
import { getSourceUsersAndGroups } from 'store/reducers/adminSources/getters';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');

  return rejectWithValue(errorMessage);
};

export const loadAdminSourcesAction = createAsyncThunk<SourceDataInterface[], void, { rejectValue: null }>(
  AdminSourcesActionsTypes.LOAD_ADMIN_SOURCES,
  async (_, { rejectWithValue }) => {
    try {
      const response = await loadAdminSources();

      return response.data.adminSourcesList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as SourceDataInterface[];
    }
  },
);

export const loadAdminSourceUsersAndGroupsAction = createAsyncThunk<AccessInterface[], IdInterface, { rejectValue: null }>(
  AdminSourcesActionsTypes.LOAD_ADMIN_SOURCES_USER_AND_GROUP,
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await loadAdminSourceUsersAndGroups(id);

      const res = response.data.adminSourceUsersAndGroupsList;

      return res.map(({ id, name, sourceGroupType, entity }) => ({
        entity,
        name,
        type: sourceGroupType,
        id,
      }));
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as AccessInterface[];
    }
  },
);

export const deleteAdminSourceAction = createAsyncThunk<string, string, { rejectValue: null }>(
  AdminSourcesActionsTypes.DELETE_SOURCE,
  async (userId, { rejectWithValue }) => {
    try {
      const response = await deleteAdminSource(userId);
      Snackbar.show('Удалено', 'success');

      return response.data.adminSourceDelete;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const addUsersGroupsSourceAccessAction = createAsyncThunk<
  FastBoard.API.ApiAdminSourceAddUsersAndGroupsResponseDTO['kind'],
  AddUsersGroupsSourceAccessPayload
>(AdminSourcesActionsTypes.ADD_USER_GROUP_SOURCE_ACCESS, async ({ sourceId, users, groups, type }, { rejectWithValue }) => {
  try {
    const response = await addUsersGroupsSourceAccess({ sourceId, users, groups, type });

    Snackbar.show('Доступы созданы', 'success');

    return response.data.kind;
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    validateError(err, rejectWithValue);
    return rejectWithValue(null);
  }
});

export const deleteSourceAccessGroupAction = createAsyncThunk<
  FastBoard.API.FlowUserResponseDTO,
  DeleteGroupSourceAccessByIdPayload
>(AdminSourcesActionsTypes.DELETE_GROUP_SOURCE_ACCESS_TYPE, async ({ groupId, sourceId }, { rejectWithValue }) => {
  try {
    const response = await deleteSourceAccessGroup({ sourceId, groupId });

    Snackbar.show('Доступ группы удален', 'success');

    return response.data;
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    return validateError(err, rejectWithValue);
  }
});

export const deleteSourceAccessUserAction = createAsyncThunk<
  FastBoard.API.FlowUserResponseDTO,
  DeleteUserSourceAccessByIdPayload
>(AdminSourcesActionsTypes.DELETE_USER_SOURCE_ACCESS_TYPE, async ({ userId, sourceId }, { rejectWithValue }) => {
  try {
    const response = await deleteSourceAccessUser({ sourceId, userId });

    Snackbar.show('Доступ пользователя удален', 'success');

    return response.data;
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    return validateError(err, rejectWithValue);
  }
});

export const changeGroupSourceAccessTypeAction = createAsyncThunk<
  FastBoard.API.ApiAdminSourceGroupeDTO,
  UpdateGroupSourceAccessByIdPayload
>(AdminSourcesActionsTypes.CHANGE_GROUP_SOURCE_ACCESS_TYPE, async ({ groupId, type, sourceId }, { rejectWithValue }) => {
  try {
    const response = await updateSourceAccessGroup({ sourceId, type, groupId });

    Snackbar.show('Статус группы изменен', 'success');

    return response.data.adminSourceGroup;
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    return validateError(err, rejectWithValue);
  }
});

export const changeUserSourceAccessTypeAction = createAsyncThunk<FastBoard.API.SourceUserDTO, UpdateUserSourceAccessByIdPayload>(
  AdminSourcesActionsTypes.CHANGE_USER_SOURCE_ACCESS_TYPE,
  async ({ userId, type, sourceId }, { rejectWithValue }) => {
    try {
      const response = await updateSourceAccessUser({ sourceId, type, userId });

      Snackbar.show('Статус пользователя изменен', 'success');

      return response.data.adminSourceUser;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateSourceAccessByIdAction = createAsyncThunk<void, { sourceAccess: AccessInterface }>(
  AdminSourcesActionsTypes.UPDATE_SOURCE_ACCESS,
  async ({ sourceAccess }, { dispatch, getState }) => {
    const sourceAccesses = getSourceUsersAndGroups(getState() as TState).sourcesUsersAndGroupsList.map((value) =>
      value?.id === sourceAccess?.id ? { ...value, ...sourceAccess } : value,
    );

    dispatch(updateSourceAccess(sourceAccesses));
  },
);

export const deleteByIdAdminSourceAction = createAsyncThunk(
  AdminSourcesActionsTypes.DELETE_BY_ID_SOURCE,
  (id: string, { dispatch }) => {
    dispatch(deleteByIdAdminSource({ id }));
  },
);

export const deleteByIdAdminSourceAccessAction = createAsyncThunk(
  AdminSourcesActionsTypes.DELETE_BY_ID_SOURCE_ACCESS,
  (id: string, { dispatch }) => {
    dispatch(deleteByIdAdminSourceAccess({ id }));
  },
);

export const changeActiveSourceAction = createAsyncThunk<void, ActiveSourceInterface>(
  AdminSourcesActionsTypes.ACTIVE_SOURCE_ID,
  ({ id, title }, { dispatch }) => {
    dispatch(changeActiveSource({ id, title }));
  },
);
