import { CircularProgress } from '@mui/material';
import { ErrorIcon, SuccessIcon } from 'assets/icons/withContainer';
import { Button } from 'modules/ui';
import React, { useCallback, useState } from 'react';
import { loadCheckingConnectionAction } from 'store/reducers/sources/actions';
import { FlexContainer } from 'styles/FlexContainer';
import { SourceCallbackConnectionData, SourceDriverType } from 'components/console/elements/sideBar/Forms/DatabaseForm/types';
import { useAppDispatch } from 'store';
import { UseFormWatch } from 'react-hook-form';

interface ConnectionCheckerProps {
  selectedDriver: SourceDriverType;
  watch: UseFormWatch<SourceCallbackConnectionData>;
}

export const ConnectionChecker: React.FC<ConnectionCheckerProps> = ({ selectedDriver, watch }) => {
  const dispatch = useAppDispatch();

  const [loadingChecking, setLoadingChecking] = useState(false);
  const [successChecking, setSuccessChecking] = useState(false);
  const [errorChecking, setErrorChecking] = useState(false);

  const onCheckingConnection = useCallback(async () => {
    try {
      setLoadingChecking(true);
      setErrorChecking(false);

      const action = await dispatch(
        loadCheckingConnectionAction({
          driver: selectedDriver,
          credentials: {
            database: watch('database'),
            user: watch('user'),
            host: watch('host'),
            port: Number(watch('port')),
            https: watch('https'),
            password: watch('password'),
          },
        }),
      ).unwrap();

      if (action === 'Ok') {
        setLoadingChecking(false);
        setSuccessChecking(true);
      }
    } catch (e) {
      setErrorChecking(true);
      setLoadingChecking(false);
      setSuccessChecking(false);
    }
  }, [selectedDriver, watch, dispatch]);

  return (
    <FlexContainer gap="8px" alignItems="center">
      {loadingChecking && <CircularProgress size="24px" />}
      {successChecking && <SuccessIcon />}
      {errorChecking && <ErrorIcon />}
      <Button
        text="Проверить соединение"
        iconSize="normal"
        heightSize="normal"
        needBackground={false}
        onClick={onCheckingConnection}
      />
    </FlexContainer>
  );
};
