import React, { FC } from 'react';
import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import Sidebar from 'components/console/elements/sideBar';
import WorkSpace from 'components/console/elements/workSpace';
import { LoadLayout } from 'components/layouts/LoadLayout';
import { menuItemsConsole } from 'components/grid/headers/LoadHeader/constants';

const ConsoleSpace: FC = () => {
  return (
    <LoadLayout menuItems={menuItemsConsole}>
      <Body>
        <Sidebar />
        <WorkSpace />
      </Body>
    </LoadLayout>
  );
};

export default ConsoleSpace;

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  background: ${`var(${ColorVarsEnum.Level_3_menu})`};
`;
