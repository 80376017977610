import { OnValueChange } from 'types/global';
import { FilterNameSettingsInterface } from 'store/reducers/filters/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { IsRealDataInterface } from 'types/store';
import { TextField } from 'modules/ui';

interface NameFilterSettingsProps extends OnValueChange<FilterNameSettingsInterface['nameSettings']>, IsRealDataInterface {}

export const NameFilterSettings = ({ isRealData, value, onChange }: NameFilterSettingsProps) => {
  const { name, nameFromDatabase } = value;

  const containerProps = {
    titleText: `Название${isRealData ? ' из источника' : ''}`,
    switcherState: isRealData ? nameFromDatabase : undefined,
    switcherChange: isRealData ? () => onChange({ ...value, nameFromDatabase: !nameFromDatabase }) : undefined,
  };

  return (
    <MainContainerSettings {...containerProps}>
      <TextField
        width="100%"
        name="name-filter"
        value={name}
        useDebounce
        onChange={(e) => onChange({ ...value, name: e.target.value })}
      ></TextField>
    </MainContainerSettings>
  );
};
