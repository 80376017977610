import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import {
  addUser,
  addUserFlow,
  addUserSource,
  changeActiveUser,
  deleteByIdUser,
  deleteByIdUserDraftProject,
  deleteByIdUserFlow,
  deleteByIdUserGroupAccess,
  deleteByIdUserSource,
  updateUserDraftProjects,
  updateUserFlows,
  updateUserGroupsAccess,
  updateUsers,
  updateUserSources,
} from '.';
import { TState } from 'store/index';
import {
  copyUserDraftProjectVersionAnother,
  createUser,
  createUserDraftProjectVersionAnother,
  createUserFlow,
  createUserGroupAccess,
  createUserSource,
  deleteUser,
  deleteUserDraftProject,
  deleteUserFlow,
  deleteUserGroupAccess,
  deleteUserSource,
  loadDraftProjects,
  loadDraftsOfAllUsers,
  loadNoUserFlows,
  loadNoUserGroups,
  loadNoUserSources,
  loadShortDraftUserProject,
  loadUserDraftProjectHistoryVersion,
  loadUserDraftProjectInfo,
  loadUserFlows,
  loadUserGroups,
  loadUserInfo,
  loadUsers,
  loadUserSources,
  moveUserDraftProject,
  protectUserDraftProject,
  renameDraftUserProject,
  restoreHistoryUserDraftProject,
  updateUser,
  updateUserFlow,
  updateUserSource,
} from 'store/reducers/adminUsers/api';
import {
  CreateUserFlowsPayload,
  CreateUserGroupAccessPayload,
  CreateUserSourcesPayload,
  DeleteUserFlowPayload,
  DeleteUserGroupsPayload,
  DeleteUserSourcePayload,
  LoadUserFlowsPayload,
  LoadUserGroupsPayload,
  LoadUserSourcesPayload,
  UpdateUserDraftProjectByIdPayload,
  UpdateUserFlowsPayload,
  UpdateUserSourcePayload,
  UserDeleteDraftProjectsPayload,
  UserFlowsListInterface,
  UserInfo,
  UserLoadDraftProjectsPayload,
  UserPayload,
  UsersActionsTypes,
  UsersGroupListInterface,
  UsersListInterface,
  UserSourcesListInterface,
  UserUpdatePayload,
} from 'store/reducers/adminUsers/types';
import { getUserDraftProjects, getUserFlows, getUserGroups, getUsers, getUserSources } from 'store/reducers/adminUsers/getters';
import {
  CopyProjectVersionAnotherPayload,
  CreateProjectVersionAnotherPayload,
  FlowListInterface,
  FlowProjectHistoryVersion,
  FlowProjectInfoInterface,
  FlowShortListInterface,
  MoveProjectPayload,
  ProtectProjectPayload,
  RenameProjectPayload,
  RestoreHistoryProjectPayload,
} from 'store/reducers/projectManager/types';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');

  return rejectWithValue(errorMessage);
};

export const loadUsersAction = createAsyncThunk<UsersListInterface[], void, { rejectValue: null }>(
  UsersActionsTypes.LOAD_USERS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await loadUsers();

      return response.data.adminUserShortList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as UsersListInterface[];
    }
  },
);

export const loadUserInfoAction = createAsyncThunk<UserInfo, string>(
  UsersActionsTypes.LOAD_USER_INFO,
  async (userId, { rejectWithValue }) => {
    try {
      const response = await loadUserInfo(userId);
      const {
        id,
        login,
        role: { id: roleType },
        license,
      } = response.data.adminUser;

      return { id, login, role: roleType, isLicense: license } as UserInfo;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return {} as UserInfo;
    }
  },
);

export const changeActiveUserAction = createAsyncThunk<void, UsersListInterface>(
  UsersActionsTypes.ACTIVE_USER_ID,
  ({ id, login }, { dispatch }) => {
    dispatch(changeActiveUser({ id, login }));
  },
);

export const deleteByIdUserAction = createAsyncThunk(UsersActionsTypes.DELETE_BY_ID_USER, (userId: string, { dispatch }) => {
  dispatch(deleteByIdUser({ id: userId }));
});

export const deleteUserAction = createAsyncThunk<string, string, { rejectValue: null }>(
  UsersActionsTypes.DELETE_USER,
  async (userId, { rejectWithValue }) => {
    try {
      const response = await deleteUser(userId);
      Snackbar.show('Удалено', 'success');

      return response.data.adminUser.id;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const createUserAction = createAsyncThunk<FastBoard.API.ApiAdminUserDTO, UserPayload, { rejectValue: null }>(
  UsersActionsTypes.CREATE_USER,
  async ({ role, password, login, isLicense }, { rejectWithValue }) => {
    try {
      const response = await createUser({ login, isLicense, role, password });
      Snackbar.show('Пользователь создан', 'success');

      return response.data.adminUser;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const addUserAction = createAsyncThunk<void, UsersListInterface>(UsersActionsTypes.ADD_USER, (data, { dispatch }) => {
  dispatch(addUser(data));
});

export const updateUserAction = createAsyncThunk<FastBoard.API.ApiAdminUserDTO, UserUpdatePayload & { userId: string }>(
  UsersActionsTypes.UPDATE_USER,
  async ({ userId, role, password, isLicense }, { rejectWithValue }) => {
    try {
      const response = await updateUser({ userId, role, password, isLicense });
      Snackbar.show('Изменения успешно сохранены', 'success');

      return response.data.adminUser;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateUsersAction = createAsyncThunk<void, { user: UsersListInterface }>(
  UsersActionsTypes.UPDATE_USERS,
  ({ user }, { dispatch, getState }) => {
    const users = getUsers(getState() as TState).usersList.map((value) => (value.id === user.id ? { ...value, ...user } : value));

    dispatch(updateUsers(users));
  },
);

/* Draft Projects */

export const loadDraftProjectsAction = createAsyncThunk<FlowListInterface[], UserLoadDraftProjectsPayload>(
  UsersActionsTypes.LOAD_DRAFT_PROJECTS,
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await loadDraftProjects({ userId });

      return response.data.adminProjectList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FlowListInterface[];
    }
  },
);

export const loadShortDraftUserProjectAction = createAsyncThunk<FlowShortListInterface[], string>(
  UsersActionsTypes.LOAD_SHORT_DRAFT_USER_PROJECTS,
  async (id, { rejectWithValue }) => {
    try {
      const response = await loadShortDraftUserProject(id);

      return response.data.adminProjectShortList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FlowShortListInterface[];
    }
  },
);

export const loadDraftsOfAllUsersAction = createAsyncThunk<FastBoard.API.ApiAdminFlowDTO[], void>(
  UsersActionsTypes.LOAD_DRAFT_OF_ALL_USERS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await loadDraftsOfAllUsers();

      return response.data.adminFlowsList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FastBoard.API.ApiAdminFlowDTO[];
    }
  },
);

export const loadUserDraftProjectInfoAction = createAsyncThunk<FlowProjectInfoInterface, UserDeleteDraftProjectsPayload>(
  UsersActionsTypes.LOAD_USER_DRAFT_PROJECT_INFO,
  async ({ projectId }, { rejectWithValue }) => {
    try {
      const response = await loadUserDraftProjectInfo({ projectId });

      const {
        updatedDataAt,
        isProtected,
        title,
        created,
        createdAt,
        updated,
        id: projectID,
        avatar,
      } = response.data.adminProjectMeta;

      return {
        id: projectID,
        updated: { dateAt: updated?.updatedAt, userName: updated?.user?.name },
        created: { dateAt: created?.createdAt, userName: created?.user?.name },
        createdAt,
        title,
        updatedDataAt,
        isProtected,
        avatar,
      } as FlowProjectInfoInterface;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return {} as FlowProjectInfoInterface;
    }
  },
);

export const loadUserDraftProjectHistoryVersionAction = createAsyncThunk<
  FlowProjectHistoryVersion[],
  UserDeleteDraftProjectsPayload
>(UsersActionsTypes.LOAD_USER_DRAFT_PROJECT_HISTORY_VERSION, async ({ projectId }, { rejectWithValue }) => {
  try {
    const response = await loadUserDraftProjectHistoryVersion({ projectId });

    const res = response.data.adminProjectVersionHistory;
    return res.map(({ id, ts, user, method }) => ({
      id,
      dateTimeHistory: ts,
      userName: user?.login,
      storageMethod: method,
    })) as FlowProjectHistoryVersion[];
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as FlowProjectHistoryVersion[];
  }
});

export const deleteUserDraftProjectProjects = createAsyncThunk<string, UserDeleteDraftProjectsPayload, { rejectValue: null }>(
  UsersActionsTypes.DELETE_USER_DRAFT_PROJECT,
  async ({ projectId }, { rejectWithValue }) => {
    try {
      const response = await deleteUserDraftProject({ projectId });
      Snackbar.show('Удалено', 'success');

      return response;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const restoreHistoryUserDraftProjectAction = createAsyncThunk<
  FastBoard.API.IncomingWriteResponseDTO[],
  RestoreHistoryProjectPayload,
  { rejectValue: null }
>(UsersActionsTypes.RESTORE_HISTORY_USER_DRAFT_PROJECT, async ({ projectId, dateTimeHistory }, { rejectWithValue }) => {
  try {
    const response = await restoreHistoryUserDraftProject({ projectId, dateTimeHistory });
    Snackbar.show('Версия успешно применена', 'success');

    return response.data.adminIncomings;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as FastBoard.API.IncomingWriteResponseDTO[];
  }
});

export const createUserDraftProjectVersionAnotherAction = createAsyncThunk<
  string,
  CreateProjectVersionAnotherPayload,
  { rejectValue: null }
>(
  UsersActionsTypes.CREATE_USER_DRAFT_PROJECT_VERSION_ANOTHER,
  async ({ projectId, dateTimeHistory, name, flowId, runImport }, { rejectWithValue }) => {
    try {
      const response = await createUserDraftProjectVersionAnother({ projectId, dateTimeHistory, name, flowId, runImport });
      Snackbar.show('Копия успешно создана', 'success');

      return response.data.adminProjectVersionCreateFromMessage;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const copyUserDraftProjectVersionAnotherAction = createAsyncThunk<
  FastBoard.API.IncomingWriteResponseDTO[],
  CopyProjectVersionAnotherPayload,
  { rejectValue: null }
>(
  UsersActionsTypes.COPY_USER_DRAFT_PROJECT_VERSION_ANOTHER,
  async ({ projectFromId, projectToId, runImport }, { rejectWithValue }) => {
    try {
      const response = await copyUserDraftProjectVersionAnother({ projectToId, projectFromId, runImport });
      Snackbar.show('Проект успешно заменен', 'success');

      return response.data.adminIncomings;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FastBoard.API.IncomingWriteResponseDTO[];
    }
  },
);

export const protectUserDraftProjectAction = createAsyncThunk<string, ProtectProjectPayload>(
  UsersActionsTypes.USER_DRAFT_PROTECT_PROJECT,
  async ({ projectId, isProtected }, { rejectWithValue }) => {
    try {
      const response = await protectUserDraftProject({ projectId, isProtected });
      Snackbar.show(isProtected ? 'Защита включена' : 'Защита выключена', 'success');

      return response.data.adminProjectUpdateProtectMessage;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const moveUserDraftProjectAction = createAsyncThunk<string, MoveProjectPayload, { rejectValue: null }>(
  UsersActionsTypes.MOVE_DRAFT_USER_PROJECT,
  async ({ projectId, flowId }, { rejectWithValue }) => {
    try {
      const response = await moveUserDraftProject({ projectId, flowId });
      Snackbar.show('Проект успешно перемещен', 'success');

      return response.data.adminProjectTransferToFlowMessage;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const renameDraftUserProjectAction = createAsyncThunk<FastBoard.API.ProjectListItemDTO, RenameProjectPayload>(
  UsersActionsTypes.RENAME_DRAFT_USER_PROJECT,
  async ({ id, name }, { rejectWithValue }) => {
    try {
      const response = await renameDraftUserProject({ id, name });
      Snackbar.show('Проект переименован', 'success');

      return response.data.adminProject;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateUserDraftProjectByIdAction = createAsyncThunk<void, UpdateUserDraftProjectByIdPayload>(
  UsersActionsTypes.UPDATE_USER_DRAFT_PROJECT_BY_ID,
  ({ userDraftProject }, { dispatch, getState }) => {
    const flowProjects = getUserDraftProjects(getState() as TState).flowProjectsList.map((value) =>
      value?.id === userDraftProject?.id ? { ...value, ...userDraftProject } : value,
    );

    dispatch(updateUserDraftProjects(flowProjects));
  },
);

export const deleteByIdUserDraftProjectAction = createAsyncThunk(
  UsersActionsTypes.DELETE_BY_ID_USER_DRAFT_PROJECT,
  (id: string, { dispatch }) => {
    dispatch(deleteByIdUserDraftProject({ id }));
  },
);

export const loadUserSourcesAction = createAsyncThunk<UserSourcesListInterface[], LoadUserSourcesPayload, { rejectValue: null }>(
  UsersActionsTypes.LOAD_USER_SOURCES,
  async (params, { rejectWithValue }) => {
    try {
      const response = await loadUserSources(params);

      return response.data.adminSourceUserList.map((source) => ({ ...source, groupName: source?.groupName || '' }));
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as UserSourcesListInterface[];
    }
  },
);

export const loadNoUserSourcesAction = createAsyncThunk<
  FastBoard.API.ApiAdminSourcesListItemDTO[],
  LoadUserSourcesPayload,
  { rejectValue: null }
>(UsersActionsTypes.LOAD_NO_USER_SOURCES, async (params, { rejectWithValue }) => {
  try {
    const response = await loadNoUserSources(params);

    return response.data.adminSourcesList;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as FastBoard.API.ApiAdminSourcesListItemDTO[];
  }
});

export const createUserSourcesAction = createAsyncThunk<string, CreateUserSourcesPayload, { rejectValue: null }>(
  UsersActionsTypes.CREATE_USER_SOURCES,
  async ({ userId, type, sourcesId }, { rejectWithValue }) => {
    try {
      const response = await createUserSource({ userId, type, sourcesId });
      Snackbar.show('Доступ успешно добавлен', 'success');

      return response.data.adminSourcesUser;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const addUserSourcesAction = createAsyncThunk<void, UserSourcesListInterface>(
  UsersActionsTypes.ADD_USER_SOURCE,
  (data, { dispatch }) => {
    dispatch(addUserSource(data));
  },
);

export const updateUserSourceAction = createAsyncThunk<FastBoard.API.SourceUserDTO, UpdateUserSourcePayload>(
  UsersActionsTypes.UPDATE_USER_SOURCE,
  async ({ userId, sourceId, type }, { rejectWithValue }) => {
    try {
      const response = await updateUserSource({ userId, sourceId, type });
      Snackbar.show('Изменения успешно сохранены', 'success');

      return response.data.adminSourceUser;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateUserSourcesAction = createAsyncThunk<void, { source: UserSourcesListInterface }>(
  UsersActionsTypes.UPDATE_USER_SOURCES,
  ({ source }, { dispatch, getState }) => {
    const sources = getUserSources(getState() as TState).userSourcesList.map((value) =>
      value.id === source.id ? { ...value, ...source } : value,
    );

    dispatch(updateUserSources(sources));
  },
);

export const deleteByIdUserSourceAction = createAsyncThunk(
  UsersActionsTypes.DELETE_BY_ID_USER_SOURCE,
  (sourceId: string, { dispatch }) => {
    dispatch(deleteByIdUserSource({ id: sourceId }));
  },
);

export const deleteUserSourceAction = createAsyncThunk<string, DeleteUserSourcePayload, { rejectValue: null }>(
  UsersActionsTypes.DELETE_USER_SOURCE,
  async (params, { rejectWithValue }) => {
    try {
      const response = await deleteUserSource(params);
      Snackbar.show('Удалено', 'success');

      return response.data.kind;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadUserGroupsAction = createAsyncThunk<UsersGroupListInterface[], LoadUserGroupsPayload, { rejectValue: null }>(
  UsersActionsTypes.LOAD_USER_GROUPS,
  async (props, { rejectWithValue }) => {
    try {
      const response = await loadUserGroups(props);

      return response.data.adminGroupList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as UsersGroupListInterface[];
    }
  },
);

export const loadNoUserGroupsAction = createAsyncThunk<
  FastBoard.API.ApiAdminUserGroupListItemResponseDTO[],
  LoadUserGroupsPayload,
  { rejectValue: null }
>(UsersActionsTypes.LOAD_NO_USER_GROUPS, async (props, { rejectWithValue }) => {
  try {
    const response = await loadNoUserGroups(props);

    return response.data.adminUserGroupList;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as FastBoard.API.ApiAdminUserGroupListItemResponseDTO[];
  }
});

export const deleteByIdUserGroupAccessAction = createAsyncThunk(
  UsersActionsTypes.DELETE_BY_ID_USER_GROUP_ACCESS,
  (groupId: string, { dispatch }) => {
    dispatch(deleteByIdUserGroupAccess({ id: groupId }));
  },
);

export const deleteUserGroupAccessAction = createAsyncThunk<string, DeleteUserGroupsPayload, { rejectValue: null }>(
  UsersActionsTypes.DELETE_USER_GROUP_ACCESS,
  async (props, { rejectWithValue }) => {
    try {
      const response = await deleteUserGroupAccess(props);
      Snackbar.show('Удалено', 'success');

      return response.data.adminGroupsAccessRemoveMessage;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const createUserGroupAccessAction = createAsyncThunk<string, CreateUserGroupAccessPayload>(
  UsersActionsTypes.CREATE_USER_GROUP_ACCESS,
  async (props, { rejectWithValue }) => {
    try {
      const response = await createUserGroupAccess(props);
      Snackbar.show('Доступ успешно добавлен', 'success');

      return response.data.adminGroupsAddManyToUserMessage;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateUsersGroupAccessAction = createAsyncThunk<void, { group: UsersGroupListInterface }>(
  UsersActionsTypes.UPDATE_USERS_GROUP_ACCESS,
  ({ group }, { dispatch, getState }) => {
    const groups = getUserGroups(getState() as TState).groupsList.map((value) =>
      value.id === group.id ? { ...value, ...group } : value,
    );

    dispatch(updateUserGroupsAccess(groups));
  },
);

export const loadUserFlowsAction = createAsyncThunk<UserFlowsListInterface[], LoadUserFlowsPayload, { rejectValue: null }>(
  UsersActionsTypes.LOAD_USER_FLOWS,
  async (props, { rejectWithValue }) => {
    try {
      const response = await loadUserFlows(props);

      return response.data.adminFlowsUserWithTypeList.map((flow) => ({ ...flow, groupName: flow?.groupName || '' }));
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as UserFlowsListInterface[];
    }
  },
);

export const loadNoUserFlowsAction = createAsyncThunk<
  FastBoard.API.ApiAdminFlowDTO[],
  LoadUserFlowsPayload,
  { rejectValue: null }
>(UsersActionsTypes.LOAD_NO_USER_FLOWS, async (props, { rejectWithValue }) => {
  try {
    const response = await loadNoUserFlows(props);

    return response.data.adminFlowsList;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as FastBoard.API.ApiAdminFlowDTO[];
  }
});

export const createUserFlowAction = createAsyncThunk<string, CreateUserFlowsPayload, { rejectValue: null }>(
  UsersActionsTypes.CREATE_USER_FLOWS,
  async (props, { rejectWithValue }) => {
    try {
      const response = await createUserFlow(props);
      Snackbar.show('Доступ успешно добавлен', 'success');

      return response.data.adminFlowsUser;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const addUserFlowAction = createAsyncThunk<void, UserFlowsListInterface>(
  UsersActionsTypes.ADD_USER_FLOW,
  (data, { dispatch }) => {
    dispatch(addUserFlow(data));
  },
);

export const updateUserFlowAction = createAsyncThunk<FastBoard.API.ApiAdminFlowUserDTO, UpdateUserFlowsPayload>(
  UsersActionsTypes.UPDATE_USER_FLOW,
  async ({ userId, flowId, type }, { rejectWithValue }) => {
    try {
      const response = await updateUserFlow({ flowId, userId, type });
      Snackbar.show('Изменения успешно сохранены', 'success');

      return response.data.adminFlowUser;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateUsersFlowAction = createAsyncThunk<void, { flow: UserFlowsListInterface }>(
  UsersActionsTypes.UPDATE_USERS_FLOW,
  ({ flow }, { dispatch, getState }) => {
    const flows = getUserFlows(getState() as TState).userFlowsList.map((value) =>
      value.id === flow.id ? { ...value, ...flow } : value,
    );

    dispatch(updateUserFlows(flows));
  },
);

export const deleteByIdUserFlowAction = createAsyncThunk(
  UsersActionsTypes.DELETE_BY_ID_USER_FLOW,
  (userId: string, { dispatch }) => {
    dispatch(deleteByIdUserFlow({ id: userId }));
  },
);

export const deleteUserFlowAction = createAsyncThunk<string, DeleteUserFlowPayload, { rejectValue: null }>(
  UsersActionsTypes.DELETE_USER_FLOW,
  async (params, { rejectWithValue }) => {
    try {
      const response = await deleteUserFlow(params);
      Snackbar.show('Удалено', 'success');

      return response.data.adminFlowUser.flowId;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);
