import { MenuItemInterface } from 'modules/ui/menu/ContextMenu/types';
import { useCallback } from 'react';
import {
  isDownLayerHotkey,
  isStepRedo,
  isStepUndo,
  isToBottomLayerHotkey,
  isToggleViewModeHotkey,
  isToTopLayerHotkey,
  isUpLayerHotkey,
} from 'modules/workspace/constans';
import { useAppDispatch } from 'store';
import { setActiveBoardElement } from 'store/reducers/board';
import { updateProjectSettingsAction } from 'store/reducers/projectSettings/actions';
import { updateLayersByIdsAction } from 'store/reducers/board/actions';
import { MoveToType } from 'utils/utils';
import { StepToType } from 'modules/settingsContainer/ContextMenuWrapper/types';
import { NoopType, NoopValueType } from 'types/global';
import { redoHistoryStepAction, undoHistoryStepAction } from 'store/reducers/history/actions';

interface UseWorkAreaMenuProps {
  isViewMode: boolean;
  hasRedoHistory: boolean;
  hasUndoHistory: boolean;
  activeWidgetId: string | null;
  onExport: NoopValueType<'xlsx' | 'csv'>;
}

export const useWorkAreaMenu = ({
  isViewMode,
  hasRedoHistory,
  hasUndoHistory,
  onExport,
  activeWidgetId,
}: UseWorkAreaMenuProps) => {
  const dispatch = useAppDispatch();
  const isWidgetActive = !!activeWidgetId;

  const onStepInHistory: Record<StepToType, NoopType> = {
    undo: () => dispatch(undoHistoryStepAction()),
    redo: () => dispatch(redoHistoryStepAction()),
  };

  const onMoveTo = useCallback(
    (moveTo: MoveToType) => () => {
      isWidgetActive ? dispatch(updateLayersByIdsAction({ id: activeWidgetId, moveTo })) : undefined;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeWidgetId, isWidgetActive],
  );

  const onToggleViewMode = useCallback(() => {
    dispatch(updateProjectSettingsAction({ isViewMode: !isViewMode }));
    dispatch(setActiveBoardElement(null));
  }, [isViewMode, dispatch]);

  const menuList: MenuItemInterface[] = [
    {
      title: isViewMode ? 'Выйти из режима просмотра' : 'Режим просмотра',
      hotKeyParams: {
        onHandle: onToggleViewMode,
        hotKeyEvent: isToggleViewModeHotkey,
        icon: 'alt + Y',
      },
      onClick: onToggleViewMode,
    },
  ];

  const additionalMenuList: MenuItemInterface[] = [
    {
      title: 'В самый низ',
      hotKeyParams: {
        onHandle: onMoveTo('top'),
        hotKeyEvent: isToTopLayerHotkey,
        icon: '[',
        disabled: !isWidgetActive,
      },
      onClick: onMoveTo('top'),
    },
    {
      title: 'В самый верх',
      hotKeyParams: {
        onHandle: onMoveTo('bottom'),
        hotKeyEvent: isToBottomLayerHotkey,
        icon: ']',
        disabled: !isWidgetActive,
      },
      onClick: onMoveTo('bottom'),
    },
    {
      title: 'На уровень вниз',
      hotKeyParams: {
        onHandle: onMoveTo('up'),
        hotKeyEvent: isUpLayerHotkey,
        icon: 'ctrl + [',
        disabled: !isWidgetActive,
      },
      onClick: onMoveTo('up'),
    },
    {
      title: 'На уровень вверх',
      hotKeyParams: {
        onHandle: onMoveTo('down'),
        hotKeyEvent: isDownLayerHotkey,
        icon: 'ctrl + ]',
        disabled: !isWidgetActive,
      },
      onClick: onMoveTo('down'),
    },
  ];

  const menuStepInHistoryList: MenuItemInterface[] = [
    {
      title: 'Отмена',
      hotKeyParams: {
        onHandle: onStepInHistory.undo,
        hotKeyEvent: isStepUndo,
        icon: 'ctrl + Z',
        disabled: hasUndoHistory,
      },
      onClick: onStepInHistory.undo,
    },
    {
      title: 'Повтор',
      hotKeyParams: {
        onHandle: onStepInHistory.redo,
        hotKeyEvent: isStepRedo,
        icon: 'ctrl + shift + Z',
        disabled: hasRedoHistory,
      },
      onClick: onStepInHistory.redo,
    },
  ];

  const exportMenu: MenuItemInterface[] = [
    {
      title: 'Экспорт в XLSX',
      onClick: () => onExport('xlsx'),
    },
    {
      title: 'Экспорт в CSV',
      onClick: () => onExport('csv'),
    },
  ];

  return { menuList, additionalMenuList, menuStepInHistoryList, exportMenu };
};
