import React, { useCallback, useEffect, useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { UserForm } from 'components/admin/usersPage/Forms/UserForm';
import { loadUserInfoAction } from 'store/reducers/adminUsers/actions';
import { useAppDispatch } from 'store';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { getActiveUser } from 'store/reducers/adminUsers/getters';
import { UserInfo } from 'store/reducers/adminUsers/types';
import { Button } from 'modules/ui';

export const Data = () => {
  const dispatch = useAppDispatch();

  const activeUser = useSelector(getActiveUser);
  const activeUserId = activeUser?.id || '';

  const [editUser, setEditUser] = useState<UserInfo | null>(null);

  const getUserInfo = useCallback(async () => {
    try {
      const actionResult = await dispatch(loadUserInfoAction(activeUserId));

      const unwrappedResult = unwrapResult(actionResult);

      setEditUser(unwrappedResult);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUserId]);

  useEffect(() => {
    void getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUserId]);

  const renderFooter = (): JSX.Element => (
    <FlexContainer width="100%" justifyContent="flex-start" gap="10px" marginTop="32px" flexDirection="row">
      <Button text="Сохранить" iconSize="normal" heightSize="normal" type="submit" />
    </FlexContainer>
  );

  return (
    <FlexContainer width="100%" height="100%">
      <FlexContainer gap="2px" flexWrap="wrap" position="relative" width="250px" height="100%">
        <UserForm mode="edit" data={editUser} footer={renderFooter()} />
      </FlexContainer>
    </FlexContainer>
  );
};
