import React, { FC } from 'react';
import { Button, TextField } from 'modules/ui';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RadioButton } from 'shared/ui';
import { useAppDispatch } from 'store';
import { setVersionStrategy } from 'store/reducers/adminSettings';
import { updateVersionStrategyAction } from 'store/reducers/adminSettings/actions';
import { getAdminSettingsVersionStrategy } from 'store/reducers/adminSettings/getters';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { FlexContainer } from 'styles/FlexContainer';
import { Form } from './styles';
import { VersionStrategyFormType } from 'components/admin/settings/elements/System/Forms/VersionStrategyForm/types';
import { handleVersionStrategyFieldError } from './validations';

export const VersionStrategyForm: FC = () => {
  const dispatch = useAppDispatch();
  const { versionStrategyData } = useSelector(getAdminSettingsVersionStrategy);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<VersionStrategyFormType>({
    mode: 'onSubmit',
    defaultValues: {
      type: versionStrategyData?.type,
      ageVersion: versionStrategyData?.ageVersion,
      count: versionStrategyData?.count,
    },
  });

  const textProps = {
    color: `var(${ColorVarsEnum.Level_2})`,
    fontSize: '12px',
  };

  const options = [
    {
      value: 'items',
      label: 'Количество',
      component: (
        <FlexContainer padding="0 0 0 28px" marginBottom="24px" flexDirection="column">
          <PrimaryTextSpan marginBottom="16px" {...textProps}>
            Для каждого проекта будет храниться указанное число последних версий. При сохранении удаляется самая старшая
          </PrimaryTextSpan>
          <Controller
            name="count"
            control={control}
            render={({ field }) => (
              <TextField type="number" error={!!errors.count} widthContainer="66px" width="66px" {...field} />
            )}
          />
        </FlexContainer>
      ),
    },
    {
      value: 'time',
      label: 'Возраст',
      component: (
        <FlexContainer padding="0 0 0 28px" marginBottom="24px" flexDirection="column">
          <PrimaryTextSpan marginBottom="16px" {...textProps}>
            При сохранении проекта будут удаляться версии страше указанного срока
          </PrimaryTextSpan>
          <FlexContainer alignItems="center" justifyContent="flex-start">
            <Controller
              name="ageVersion"
              control={control}
              render={({ field }) => (
                <TextField type="number" error={!!errors.ageVersion} widthContainer="66px" width="66px" {...field} />
              )}
            />
            <PrimaryTextSpan marginLeft="8px" {...textProps}>
              дней
            </PrimaryTextSpan>
          </FlexContainer>
        </FlexContainer>
      ),
    },
  ];

  const setFieldError = (field: keyof VersionStrategyFormType, message: string) => setError(field, { message });

  const onSubmit: SubmitHandler<VersionStrategyFormType> = async ({ type, ageVersion, count }) => {
    const { value, message } = handleVersionStrategyFieldError(type, ageVersion, count);

    if (value && message) {
      return setFieldError(value, message);
    }

    const params = {
      type,
      ...(type === 'time' && { ageVersion: +ageVersion }),
      ...(type === 'items' && { count: +count }),
    };
    try {
      const response = await dispatch(updateVersionStrategyAction(params)).unwrap();

      if (response) {
        dispatch(setVersionStrategy(response));
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="type"
        rules={{ required: true }}
        control={control}
        render={({ field }) => <RadioButton options={options} {...field} />}
      />

      <Button text="Применить стратегию" width="fit-content" type="submit" disabled={isSubmitting} />
      <PrimaryTextSpan marginTop="8px" {...textProps}>
        В соответствии с выбранной стратегией или новыми настройками из историй проектов будут удалены версии не удовлетворяющие
        новым условиям.
      </PrimaryTextSpan>
    </Form>
  );
};
