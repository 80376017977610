import { TRenderByConditionType } from 'modules/settingsContainer/common/WrapperBySettings/types';
import { ColorByConditionInterface } from 'store/reducers/visualisations/types';
import { useLocalValues } from 'utils/hooks/localValues';
import React, { useEffect } from 'react';
import { ColorValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { FlexContainer } from 'styles/FlexContainer';
import { SqlAutocomplete } from 'modules/ui';
import { SaveCancelButtons } from 'modules/ui/blocks/SaveCancelButtons';
import { ColorByPicker } from 'modules/ui/ColorByPicker';
import { AddColorButton } from 'modules/ui/buttons/AddColorButton';

export const ColorByCondition: TRenderByConditionType<ColorByConditionInterface> = ({ metric: value, onChange }) => {
  const { localValues, setLocalValues, onSave, onCancel, hasChanges } = useLocalValues({ value, onChange });

  const { colors, sqlCondition } = localValues;

  useEffect(() => {
    setLocalValues(value);
  }, [setLocalValues, value]);

  const onAddColor = () =>
    setLocalValues((localValues) => ({
      ...localValues,
      colors: [
        ...colors,
        {
          value: null,
        },
      ],
    }));

  const onDeleteColor = (deletedIndex: number) => () =>
    setLocalValues((localValues) => ({
      ...localValues,
      colors: colors.filter((_, index) => index !== deletedIndex),
    }));

  const onChangeColor = (colorIndex: number) => (colorValue: ColorValuesByThemeType | null) => {
    setLocalValues((localValues) => ({
      ...localValues,
      colors: colors.map((color, index) => (index === colorIndex ? { ...color, value: colorValue } : color)),
    }));
  };

  const onSqlChange = (sql: string) =>
    setLocalValues((localValues) => ({
      ...localValues,
      sqlCondition: sql || null,
    }));

  return (
    <>
      <GroupContainerSettings>
        <ElementContainerSettings>
          <FlexContainer padding="24px 8px" gap="20px" width="100%" flexWrap="wrap">
            {colors.map(({ value }, index) => (
              <ColorByPicker
                value={value}
                onChange={onChangeColor(index)}
                name={index + 1}
                onDelete={onDeleteColor(index)}
                key={index}
              />
            ))}
            <AddColorButton onAdd={onAddColor} />
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
      <GroupContainerSettings>
        <ElementContainerSettings>
          <FlexContainer padding="0 10px 10px 2px" flexDirection="column" width="100%">
            <SqlAutocomplete height="270px" handleChange={onSqlChange} value={sqlCondition || ''} />
            <FlexContainer justifyContent="flex-end" margin="10px 8px 0 0">
              <SaveCancelButtons onCancel={onCancel} onSave={onSave} hasChanges={hasChanges} />
            </FlexContainer>
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
    </>
  );
};
