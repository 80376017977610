import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSliceFn } from 'constants/store';
import { initialBlockVisibilitySettingsState } from './constants';

export const blockVisibilitySettingsSlice = createSlice({
  name: 'blockVisibilitySettings',
  initialState: initialBlockVisibilitySettingsState,
  reducers: {
    blockToggleSetting: (state, { payload: titleText }: PayloadAction<string>) => {
      if (state.blockVisibility[titleText]) {
        state.blockVisibility[titleText].isOpen = !state.blockVisibility[titleText].isOpen;
      } else {
        state.blockVisibility[titleText] = { isOpen: true };
      }
    },
    setSlice: setSliceFn,
  },
});

export const { setSlice, blockToggleSetting } = blockVisibilitySettingsSlice.actions;

export default blockVisibilitySettingsSlice.reducer;
