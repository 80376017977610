import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialAstStoreState } from 'store/reducers/ast/constants';
import {
  EnabledFiltersAstType,
  SetAstOfEnabledFilterPayload,
  SetAstOfVisualisationPayload,
  SourcesAstType,
} from 'store/reducers/ast/types';
import { setSliceFn } from 'constants/store';

export const astSlice = createSlice({
  name: 'AST',
  initialState: initialAstStoreState,
  reducers: {
    setAstOfVisualisation: (state, { payload: { id, astData } }: PayloadAction<SetAstOfVisualisationPayload>) => {
      state.visualisations = {
        ...state.visualisations,
        [id]: astData,
      };
    },
    removeAstOfVisualisationById: (state, { payload: id }: PayloadAction<string>) => {
      if (state.visualisations[id]) {
        delete state.visualisations[id];
      }
    },
    setAstOfEnabledFilter: (state, { payload: { id, astData } }: PayloadAction<SetAstOfEnabledFilterPayload>) => {
      state.enabledFilters = {
        ...state.enabledFilters,
        [id]: astData,
      };
    },
    removeAstOfEnabledFilterById: (state, { payload: id }: PayloadAction<string>) => {
      if (state.enabledFilters[id]) {
        delete state.enabledFilters[id];
      }
    },
    updateAstOfEnabledFilters: (state, { payload: enabledFilters }: PayloadAction<EnabledFiltersAstType>) => {
      state.enabledFilters = enabledFilters;
    },
    updateAstOfSources: (state, { payload: source }: PayloadAction<SourcesAstType>) => {
      state.sources = source;
    },
    setSlice: setSliceFn,
  },
});

export const {
  setAstOfVisualisation,
  removeAstOfVisualisationById,
  removeAstOfEnabledFilterById,
  setAstOfEnabledFilter,
  updateAstOfEnabledFilters,
  updateAstOfSources,
  setSlice,
} = astSlice.actions;

export default astSlice.reducer;
