import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const FilterContainerWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  background-color: ${`var(${ColorVarsEnum.Level_4_widget})`};
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  border-radius: 2px;
  gap: 8px;
  border: 1px solid ${`var(${ColorVarsEnum.Level_4_widget})`};

  :hover {
    border: 1px solid ${`var(${ColorVarsEnum.Level_5})`};
  }
`;
