import React from 'react';
import { OnValueChange } from 'types/global';
import { BubbleSizeModeSettingsInterface } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { elementTypeOptions } from 'modules/settingsContainer/common/data/BubbleSizeModeSettings/constants';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { pxUnit } from 'constants/global';
import { FlexContainer } from 'styles/FlexContainer';

type BubbleSizeModeSettingsProps = OnValueChange<BubbleSizeModeSettingsInterface>;

export const BubbleSizeModeSettings = ({ value, onChange }: BubbleSizeModeSettingsProps) => {
  const { type, parameters } = value;
  const { min, max } = value.parameters.minAndMax;

  return (
    <MainContainerSettings titleText="Тип элемента">
      <GroupContainerSettings>
        <ElementContainerSettings>
          <SettingsRadio
            onChange={({ value: type }) => onChange({ ...value, type })}
            activeValue={getActiveRadioValue(elementTypeOptions, type)}
            options={elementTypeOptions}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
      {type === 'default' && (
        <GroupContainerSettings>
          <ElementContainerSettings>
            <NumberPropertiesBlock
              properties="Толщина"
              name="lineWidth"
              unit={pxUnit}
              value={parameters.diameter}
              onChange={(diameter) => onChange({ ...value, parameters: { ...parameters, diameter } })}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      )}
      {type === 'byIndicator' && (
        <GroupContainerSettings>
          <ElementContainerSettings>
            <FlexContainer maxWidth="80%" alignItems="center" flexDirection="row" justifyContent="space-between" width="100%">
              <NumberPropertiesBlock
                properties="Мин"
                name="lineWidth"
                unit={pxUnit}
                value={min}
                onChange={(min) =>
                  onChange({ ...value, parameters: { ...parameters, minAndMax: { ...parameters.minAndMax, min } } })
                }
              />
              <NumberPropertiesBlock
                properties="Макс"
                name="lineWidth"
                unit={pxUnit}
                value={max}
                onChange={(max) =>
                  onChange({ ...value, parameters: { ...parameters, minAndMax: { ...parameters.minAndMax, max } } })
                }
              />
            </FlexContainer>
          </ElementContainerSettings>
        </GroupContainerSettings>
      )}
    </MainContainerSettings>
  );
};
