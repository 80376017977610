import { ByType } from 'types/store';

export type DateOriginType = Date;

export type PeriodDateType<DateType extends DateOriginType = Date> = [DateType | null, DateType | null];

export type DatePickerValue<
  WithRange extends boolean | undefined = undefined,
  DateType extends DateOriginType = Date,
> = WithRange extends false | undefined ? DateType | null : WithRange extends true ? PeriodDateType<DateType> : never;

export type OnChangeType<WithRange extends boolean | undefined = undefined, DateType extends DateOriginType = Date> = (
  value: { byType: ByType; dates: DatePickerValue<WithRange, DateType> },
  type: 'change' | 'clear',
) => void;

export interface DateOptionsInput {
  years: number[];
  months: number[];
  days: number[];
}

export interface DateRange {
  minDate: Date;
  maxDate: Date;
}

export enum PeriodEnum {
  DAY = 'byDay',
  MONTH = 'byMonth',
  QUARTER = 'byQuarter',
  WEEK = 'byWeek',
  TODAY = 'byToday',
  YEAR = 'byYear',
}
