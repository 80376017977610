import { useCallback } from 'react';
import { useAppDispatch } from 'store';

import { closeModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { changeActiveSourceId } from 'store/reducers/sources';
import { DataSelectionModal } from '../SourcesConnection/DataSelectionModal';
import { dataSelectionModal, widthSelectionModal } from '../SourcesConnection/DataSelectionModal/constants';

interface ConnectionProps {
  sourceId: string;
  name: string;
}

export const useConnection = (projectId: string) => {
  const dispatch = useAppDispatch();

  const onCloseModal = useCallback(() => {
    dispatch(changeActiveSourceId(null));

    dispatch(closeModalAction(dataSelectionModal));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = useCallback(
    (sourceId: string, name: string) => {
      dispatch(
        openModalTypedAction({
          Component: DataSelectionModal,
          componentProps: {
            onClose: onCloseModal,
            id: sourceId,
            name,
            projectId,
          },
          modalSettings: {
            headerText: 'Выбрать данные',
            position: 'static',
            padding: '0',
            width: widthSelectionModal,
          },
          name: dataSelectionModal,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseModal, projectId],
  );

  const connectToSource = useCallback(
    ({ sourceId, name }: ConnectionProps) => {
      dispatch(changeActiveSourceId(sourceId));
      handleOpenModal(sourceId, name);
    },
    [dispatch, handleOpenModal],
  );

  return connectToSource;
};
