import { axiosClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';

const authUrl = '/api/auth';

export const signIn = (connectionData: FastBoard.API.AuthGetTokenDTO): Promise<AxiosResponse<string>> =>
  axiosClient.post(`${authUrl}/get_token`, connectionData);

export const logOut = (): Promise<AxiosResponse<string>> => axiosClient.delete(`${authUrl}`);

export const logOutFromAllMySession = (): Promise<AxiosResponse<boolean>> =>
  axiosClient.delete(`${authUrl}/logout/all-my-sessions`);

export const loadRolesListEnum = (): Promise<AxiosResponse<FastBoard.API.RolesListResponseDTO>> => axiosClient.get('/api/roles');

export const refreshToken = (): Promise<AxiosResponse<string>> => axiosClient.post('/api/auth/refresh');

export const loadUser = (): Promise<AxiosResponse<FastBoard.API.AuthWhoamiDTO>> => axiosClient.get(authUrl);

export const hasAccessToProject = ({
  projectId,
}: {
  projectId: string;
}): Promise<AxiosResponse<FastBoard.API.ProjectAccessResponseDTO>> => axiosClient.get(`/api/v1/projects/${projectId}/access`);
