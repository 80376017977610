import React, { useMemo, useState } from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  RowSelectionState,
  Row,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { v4 } from 'uuid';
import { rowHeights } from './constants';
import { ArrowSvg, MyTable, TBody, ThBody, THead, ThHead, ThHeadContent, TrBody, TrHead } from './styles';
import { RowHeightVariants } from './types';

interface TableProps<T extends object, D> {
  rows: T[];
  columns: ColumnDef<T, D>[];
  filters?: ColumnFiltersState;
  onRowClick?: any;
  rowHeightVariant?: RowHeightVariants;
  height?: string;
}

export const Table = <T extends object, D>({
  rows,
  columns,
  height,
  filters,
  rowHeightVariant = 'big',
  onRowClick,
}: TableProps<T, D>) => {
  const rowHeight = rowHeights[rowHeightVariant];

  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const colWidth = useMemo(() => `${100 / columns.length}%`, [columns]);

  const table = useReactTable({
    data: rows,
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters: filters,
    },
    enableMultiRowSelection: false,
    getRowId: () => v4(),
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const handleRowClick = (row: Row<T>) => {
    onRowClick(row.original);
    row.toggleSelected();
  };

  return (
    <MyTable height={height}>
      <THead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TrHead height={rowHeight} key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <ThHead width={colWidth} key={header.id}>
                <ThHeadContent
                  {...{
                    pointer: header.column.getCanSort(),
                    onClick: header.column.getToggleSortingHandler(),
                  }}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}

                  {{
                    asc: <ArrowSvg />,
                    desc: <ArrowSvg inverted={true} />,
                  }[header.column.getIsSorted() as string] ?? null}
                </ThHeadContent>
              </ThHead>
            ))}
          </TrHead>
        ))}
      </THead>
      <TBody>
        {table.getRowModel().rows.map((row) => (
          <TrBody
            isPointer={!!onRowClick}
            height={rowHeight}
            key={row.id}
            isSelected={row.getIsSelected()}
            {...(onRowClick && { onClick: () => handleRowClick(row) })}
          >
            {row.getVisibleCells().map((cell) => (
              <ThBody width={colWidth} key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </ThBody>
            ))}
          </TrBody>
        ))}
      </TBody>
    </MyTable>
  );
};
