import { PaletteLinkInterface, ThemeColorsInterface, ThemeItemInterface } from 'store/reducers/themes/types';
import { getMapObject } from 'utils/utils';
import { defaultTheme, getCommonThemeById } from 'store/reducers/themes/constants';
import { createSelector } from 'reselect';
import { getState } from 'store/utils';
import { isEqual } from 'lodash';

export const getThemesStore = createSelector(getState, (state) => state.themes);

export const getThemesLoading = createSelector(getThemesStore, (state) => state.themesLoading);

export const getThemes = createSelector(getThemesStore, (state) => state.themes);
export const getServerStateOfThemes = createSelector(getThemesStore, (state) => state.serverStateOfThemes);
export const getHasChangesOfThemes = createSelector([getServerStateOfThemes, getThemes], (serverState, currentState) =>
  serverState === null ? false : !isEqual(serverState, currentState),
);

export const getThemesAsArray = createSelector(getThemes, (themes) => Object.values(themes) as ThemeItemInterface[]);

export const getDefaultTheme = createSelector(getThemesAsArray, (themes) => themes?.[0]);

export const getThemeById = (id: string) => createSelector(getThemes, (themes) => themes[id]);

export const getActiveThemeId = createSelector(getThemesStore, (state) => state.activeThemeId);
export const getServerStateOfActiveThemeId = createSelector(getThemesStore, (state) => state.serverStateOfActiveThemeId);
export const getHasChangesOfActiveThemeId = createSelector(
  [getServerStateOfActiveThemeId, getActiveThemeId],
  (serverState, currentState) => (serverState === null ? false : serverState !== currentState),
);

export const getActiveTheme = createSelector(
  [getState, getActiveThemeId],
  (state, activeThemeId) => getThemeById(activeThemeId || '')(state) || getCommonThemeById(activeThemeId) || defaultTheme,
);

export const getActiveThemeColors = createSelector(getActiveTheme, (activeTheme) => {
  return [
    ...activeTheme.elementColors,
    ...activeTheme.backgroundColors,
    ...activeTheme.subBackgroundColors,
    ...activeTheme.subElementColors,
  ].reduce((colors, { cssVariable, hex }) => ({ ...colors, [cssVariable]: hex }), {} as ThemeColorsInterface);
});

export const getActiveThemeCodeEditorTheme = createSelector(
  getActiveTheme,
  (activeTheme) => activeTheme.codeEditorTheme || defaultTheme.codeEditorTheme,
);

export const getActiveThemeLoading = createSelector(getThemesStore, (state) => state.activeThemeLoading);

export const getPaletteLinks = createSelector(getThemesStore, (state) => state.paletteLinks);
export const getServerStateOfPaletteLinks = createSelector(getThemesStore, (state) => state.serverStateOfPaletteLinks);
export const getHasChangesOfPaletteLinks = createSelector(
  [getServerStateOfPaletteLinks, getPaletteLinks],
  (serverState, currentState) => (serverState === null ? false : !isEqual(serverState, currentState)),
);

export const getPaletteLinksByPaletteId = createSelector(getPaletteLinks, (paletteLinks) =>
  getMapObject(paletteLinks, 'paletteId'),
);

export const getPaletteLinksByThemeId = createSelector(getPaletteLinks, (paletteLinks) => getMapObject(paletteLinks, 'themeId'));

export const getPaletteLinkByThemeId = (themeId: string) =>
  createSelector(
    getPaletteLinksByThemeId,
    (paletteLinksByThemeId) => paletteLinksByThemeId[themeId] as PaletteLinkInterface | undefined,
  );

export const getActivePaletteId = createSelector(
  [getState, getActiveThemeId, getDefaultTheme],
  (state, activeThemeId, defaultTheme) => {
    const paletteLink = getPaletteLinkByThemeId(activeThemeId || defaultTheme?.id)(state);

    if (paletteLink) {
      return paletteLink.paletteId;
    }

    return null;
  },
);

export const getPaletteLinksLoading = createSelector(getThemesStore, (state) => state.paletteLinksLoading);
