import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { defaultScrollbarMixin } from 'constants/styles';

export const ModelItemContent = styled.div`
  width: 100%;
  height: 100%;
  border-top: 1px solid var(${ColorVarsEnum.Level_5});
  display: flex;
  flex-direction: row;
`;

export const ModelControls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  border-right: 1px solid var(${ColorVarsEnum.Level_5});
`;

export const ModelItemRelations = styled.div<{ isList?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  border-bottom: 1px solid var(${ColorVarsEnum.Level_5});

  ${({ isList }) =>
    isList &&
    css`
      max-height: 40%;
      overflow: auto;
      ${defaultScrollbarMixin};
      padding: 10px 10px 10px 0;
    `}
`;

export const TablePreview = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
`;
