import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const TextDefault: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fill = `var(${ColorVarsEnum.Accent})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <rect opacity="0.15" width="56" height="45" transform="matrix(-1 0 0 1 63 17)" fill={fillDefault} />
        <path opacity="0.35" d="M44 40L29 58H59L44 40Z" fill={fillDefault} />
        <circle cx="51.5" cy="27.5" r="7.5" fill="white" />
        <path d="M22.4422 32L11 45.8V58H44L22.4422 32Z" fill={fill} />
        <path
          d="M14.5534 27V25.3558L16.711 24.9904V9.94437H12.3429L11.959 12.5804H10V8H26.0164V12.5804H24.0838L23.6867 9.94437H19.3186V24.9904L21.4762 25.3558V27H14.5534Z"
          fill={fillDefault}
        />
        <path
          d="M27.7283 27V25.3558L29.8859 24.9904V14.8771H26.8679L26.6694 17.0824H24.4456V12.8805H38V17.0824H35.763L35.5645 14.8771H32.4935V24.9904L34.6511 25.3558V27H27.7283Z"
          fill={fillDefault}
        />
      </svg>
    </FlexContainer>
  );
};
