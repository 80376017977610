import { FlowProjectHistoryVersionInterface, FlowProjectsInterface, SortProject } from 'store/reducers/projectManager/types';
import { EntityType, GroupType, IdInterface, LoadingInterface } from 'types/store';

export interface FlowsListInterface extends IdInterface {
  name: string;
}

export interface FlowsInterface extends LoadingInterface {
  flowsList: FlowsListInterface[];
}

export interface FlowAccessListInterface extends IdInterface {
  name: string;
  entity: EntityType;
  type: GroupType;
}

export interface FlowAccessInterface extends LoadingInterface {
  flowAccessList: FlowAccessListInterface[];
}

export interface FlowsSliceStateInterface {
  flows: FlowsInterface;
  flowAccess: FlowAccessInterface;
  flowProjects: FlowProjectsInterface;
  activeFlowProjectId: string | null;
  sortFlowProject: SortProject;
  flowProjectHistoryVersion: FlowProjectHistoryVersionInterface;
  activeFlow: FlowsListInterface | null;
}

/* Action Types */
export enum FlowsActionsTypes {
  LOAD_FLOWS = 'LOAD_FLOWS',
  ACTIVE_FLOW_ID = 'ACTIVE_FLOW_ID',
  CREATE_FLOW = 'CREATE_FLOW',
  ADD_FLOW = 'ADD_FLOW',
  UPDATE_FLOW = 'UPDATE_FLOW',
  UPDATE_FLOWS = 'UPDATE_FLOWS',
  DELETE_FLOW = 'DELETE_FLOW',
  DELETE_BY_ID_FLOW = 'DELETE_BY_ID_FLOW',

  LOAD_FLOW_ACCESS = 'LOAD_FLOW_ACCESS',
  LOAD_MOVE_FLOW_PROJECTS = 'LOAD_MOVE_FLOW_PROJECTS',
  LOAD_NO_FLOW_ACCESS = 'LOAD_NO_FLOW_ACCESS',
  CREATE_FLOW_ACCESS = 'CREATE_FLOW_ACCESS',
  UPDATE_FLOW_USER_ACCESS = 'UPDATE_FLOW_USER_ACCESS',
  UPDATE_FLOW_GROUP_ACCESS = 'UPDATE_FLOW_GROUP_ACCESS',
  UPDATE_FLOWS_ACCESS = 'UPDATE_FLOWS_ACCESS',
  DELETE_FLOW_USER_ACCESS = 'DELETE_FLOW_USER_ACCESS',
  DELETE_FLOW_GROUP_ACCESS = 'DELETE_FLOW_GROUP_ACCESS',
  DELETE_BY_ID_FLOW_ACCESS = 'DELETE_BY_ID_FLOW_ACCESS',

  LOAD_FLOW_PROJECTS = 'LOAD_FLOW_PROJECTS',
  LOAD_FLOW_PROJECT_INFO = 'LOAD_FLOW_PROJECT_INFO',
  LOAD_FLOW_PROJECT_HISTORY_VERSION = 'LOAD_FLOW_PROJECT_HISTORY_VERSION',
  RESTORE_HISTORY_FLOW_PROJECT = 'RESTORE_HISTORY_FLOW_PROJECT',
  CREATE_FLOW_PROJECT_VERSION_ANOTHER = 'CREATE_FLOW_PROJECT_VERSION_ANOTHER',
  COPY_FLOW_PROJECT_VERSION_ANOTHER = 'COPY_FLOW_PROJECT_VERSION_ANOTHER',
  MOVE_FLOW_PROJECT = 'MOVE_FLOW_PROJECT',
  DELETE_BY_ID_FLOW_PROJECT = 'DELETE_BY_ID_FLOW_PROJECT',
  FLOW_PROTECT_PROJECT = 'FLOW_PROTECT_PROJECT',
  UPDATE_FLOW_PROJECT_BY_ID = 'UPDATE_FLOW_PROJECT_BY_ID',
  LOAD_SHORT_FLOW_PROJECTS = 'LOAD_SHORT_FLOW_PROJECTS',
  RENAME_FLOW_PROJECT = 'RENAME_FLOW_PROJECT',
  DELETE_FLOW_PROJECT = 'DELETE_FLOW_PROJECT',
}

/* Payload Interfaces */

type TypePayload = {
  type: GroupType;
};

export type CreateFlowPayload = {
  name: string;
};

export type DeleteFlowPayload = {
  flowId: string;
};

export type UpdateFlowPayload = CreateFlowPayload & DeleteFlowPayload;

export type LoadFlowAccessPayload = DeleteFlowPayload;

export type CreateFlowAccessPayload = LoadFlowAccessPayload &
  TypePayload & {
    users: string[];
    groups: string[];
  };

export type DeleteFlowUserAccessPayload = LoadFlowAccessPayload & {
  userId: string;
};

export type UpdateFlowUserAccessPayload = DeleteFlowUserAccessPayload & TypePayload;

export type DeleteFlowGroupAccessPayload = LoadFlowAccessPayload & {
  groupId: string;
};

export type UpdateFlowGroupAccessPayload = DeleteFlowGroupAccessPayload & TypePayload;

export type LoadFlowProjectsPayload = DeleteFlowPayload;
