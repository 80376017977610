import { AST } from 'types/ast';
import { IdInterface } from 'types/store';
import { MapRecordType } from 'types/global';

export type IncisionsAstType = AST.BasicColumn | AST.ColumnFromParser;
export type IndicatorAstType = AST.BasicFunctionColumn | AST.ColumnFromParser;

export interface VisualisationAstInterface {
  incisions: IncisionsAstType[];
  activeIncisionIndex: number | null;
  indicators: IndicatorAstType[];
  variables: AST.ColumnFromParser[];
  images: AST.ColumnFromParser[];
  serviceValues: AST.ColumnFromParser[];
  groupBy: AST.GroupByColumn[];
  limit: AST.Limit | null;
  filtersAndGroups: AST.FilterAndGroupType;
}

export type VisualisationsAstType = MapRecordType<VisualisationAstInterface>;

export type EnabledFilterAstInterface = AST.WhereIn;

export type EnabledFiltersAstType = MapRecordType<EnabledFilterAstInterface>;

export interface SourceAstItemInterface extends IdInterface {
  ast: AST.FromFromParser;
}

export type SourcesAstType = Record<string, SourceAstItemInterface | undefined>;

/* Store SLice Type */

export interface AstStateInterface {
  visualisations: VisualisationsAstType;
  enabledFilters: EnabledFiltersAstType;
  sources: SourcesAstType;
}

/* Action Types */

export enum AstActionsTypes {
  SET_AST_OF_VISUALISATION = 'SET_AST_OF_VISUALISATION',
  REMOVE_AST_OF_VISUALISATION = 'REMOVE_AST_OF_VISUALISATION',
  SET_AST_OF_ENABLED_FILTER = 'SET_AST_OF_ENABLED_FILTER',
  REMOVE_AST_OF_ENABLED_FILTER = 'REMOVE_AST_OF_ENABLED_FILTER',
  UPDATE_AST_OF_ENABLED_FILTERS = 'UPDATE_AST_OF_ENABLED_FILTERS',
  UPDATE_AST_OF_SOURCES = 'UPDATE_AST_OF_SOURCES',
  CLEAR_AST_STORE = 'CLEAR_AST_STORE',
}

/* Payloads */

export interface SetAstOfVisualisationPayload extends IdInterface {
  astData: VisualisationAstInterface;
}

export interface SetAstOfEnabledFilterPayload extends IdInterface {
  astData: EnabledFilterAstInterface;
}
