import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { PositionSettingType } from 'types/store';

export const titlePositionOptions: SettingsRadioItem<PositionSettingType>[] = [
  {
    value: 'flex-start',
    label: 'Слева',
  },
  {
    value: 'center',
    label: 'По центру',
  },
  {
    value: 'flex-end',
    label: 'Справа',
  },
];
