import { Column, Condition, JoinType, LinkRelationType, RelationInterface } from 'store/reducers/models/types';

export interface RelationInitialInterface
  extends Pick<RelationInterface, 'type' | 'link'>,
    Partial<Pick<RelationInterface, 'condition'>> {}

export class Relation {
  type: JoinType;
  link: LinkRelationType;
  condition: Condition | null;

  constructor({ type, link, condition = null }: RelationInitialInterface) {
    this.type = type;
    this.link = link;
    /* TODO: Make test for condition */
    this.condition = condition;
  }

  get isSelf() {
    const { left, right } = this.link;

    return left.table === right.table;
  }

  get key() {
    const { left, right } = this.link;

    return `${left.table}_${left.column}_${right.table}_${right.column}`;
  }

  getRelative(alias: string) {
    const { link } = this;

    if (!this.isSelf && alias === link.right.table) {
      return { ...this, link: { ...this.link, left: link.right, right: link.left } };
    }

    return this;
  }

  aliasUsedAsLink(alias: string) {
    const { left, right } = this.link;

    return [left.table, right.table].some((tableAlias) => tableAlias === alias);
  }

  changeAliasInLink({ fromAlias, toAlias }: { fromAlias: string; toAlias: string }) {
    if (this.aliasUsedAsLink(fromAlias)) {
      if (this.link.left.table === fromAlias) {
        this.link.left.table = toAlias;
      }

      if (this.link.right.table === fromAlias) {
        this.link.right.table = toAlias;
      }
    }
  }

  getOuterLinkByAlias(alias: string) {
    const { left, right } = this.link;

    if (!this.isSelf) {
      const links = [left, right].reduce<Column[]>((links, link) => (link.table !== alias ? [...links, link] : links), []);

      if (links.length === 1) {
        return links[0];
      }
    }

    return null;
  }
}
