import styled from 'styled-components';
import { Color, groupNameTextStyleMixin, Groups } from 'modules/settingsContainer/ColorPicker/styles';
import { defaultScrollbarMixin } from 'constants/styles';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const GroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  ${defaultScrollbarMixin};

  ${Groups} {
    cursor: auto;
  }

  ${Color} {
    :hover {
      transform: scale(1.3);
    }
  }
`;

export const GroupNameTextSpan = styled.span`
  cursor: pointer;
  ${groupNameTextStyleMixin};
`;

export const StyledTextInput = styled.input`
  ${groupNameTextStyleMixin};
  background: none;
  outline: none;
  border-radius: 5px;
  border: 1px solid ${`var(${ColorVarsEnum.Level_4})`};
  max-width: 100%;
`;

export const GroupNameWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  align-items: center;
  min-height: 20px;
`;
