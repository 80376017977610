import React from 'react';
import { ModalComponentType } from 'store/reducers/modals/types';
import { FlexContainer } from 'styles/FlexContainer';
import { Button } from 'modules/ui';
import { UserForm } from 'components/admin/usersPage/Forms/UserForm';

export const CreateUserModal: ModalComponentType = ({ onClose }) => {
  const renderFooter = (): JSX.Element => (
    <FlexContainer width="100%" justifyContent="flex-end" gap="10px" marginTop="32px" flexDirection="row">
      <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
      <Button text="Создать" iconSize="normal" heightSize="normal" type="submit" />
    </FlexContainer>
  );

  return (
    <>
      <UserForm onClose={onClose} mode="add" footer={renderFooter()} />
    </>
  );
};
