import CircularProgress from '@mui/material/CircularProgress';
import { FlexContainer } from 'styles/FlexContainer';
import { Button } from 'modules/ui';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FiltersValuesType } from 'store/reducers/filters/types';
import { OnValueChange } from 'types/global';
import { SearchInput } from 'modules/ui/inputs/SearchInput';
import { SortingPanel } from 'components/shared/SortingPanel';
import { Divider } from '@mui/material';
import { FilterElementModeEnum } from 'types/store';
import { getActiveRadioValue } from '../SettingsRadio/constants';
import { SettingsRadio } from '../SettingsRadio';
import { SelectedItemCounter } from 'components/shared/SelectedItemCounter';
import { SettingsRadioItem } from '../SettingsRadio/types';
import { useFilterQuery } from 'modules/filters/hooks/filter';
import { useGetActiveFilter } from 'modules/filters/hooks/getActiveFilter';
import { VirtualizedList } from 'shared/ui/VirtualizedList';
import { DynamicValuesRow } from './DynamicValuesRow';
import { MainValuesRow } from './MainValuesRow';
import { SelectedValuesRow } from './SelectedValuesRow';
import { filterElementModeValues, sortingOptions } from './constants';
import { SortingValueEnum } from 'components/shared/SortingPanel/types';

interface FilterValuesListProps extends OnValueChange<string[]> {
  loading?: boolean;
  filterValues: FiltersValuesType;
  filterElementMode: FilterElementModeEnum;
  onFilterModeChange: (filterElementMode: FilterElementModeEnum) => void;
  fieldName: string | null;
}

export const FilterValuesList = ({
  loading,
  filterValues,
  value: selectedData,
  onChange,
  filterElementMode,
  onFilterModeChange,
  fieldName,
}: FilterValuesListProps) => {
  const [selectedLocalValues, setSelectedLocalValues] = useState<string[]>([]);
  const [selectedListValues, setSelectedListValues] = useState<{ value: string; count: number }[] | null>(null);
  const [selectedListSortingStatus, setSelectedListSortingStatus] = useState<string | null>(null);
  const [sortingStatus, setSortingStatus] = useState<string | null>(null);
  const [searchString, setSearchString] = useState('');

  const { data } = useGetActiveFilter({ type: 'single' });

  const { id, nameSettings, modelId, limit, sqlData } = data;

  const { isValuesOverLimit } = useFilterQuery({
    nameSettings,
    id,
    modelId,
    searchString,
    sortingStatus,
    limit,
    sqlData,
    isFetchRequiredWhenDataExists: true,
  });

  const isLocalChecked = useCallback((value: string) => selectedLocalValues?.includes(value), [selectedLocalValues]);

  const isChecked = useCallback((value: string) => selectedData?.includes(value), [selectedData]);

  const activeMode = useMemo(() => getActiveRadioValue(filterElementModeValues, filterElementMode), [filterElementMode]);

  const onAddToSelectedData = useCallback(
    (value: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const newSelectedData = new Set(selectedLocalValues);

      if (e.target.checked) {
        newSelectedData.add(value);
      } else {
        newSelectedData.delete(value);
      }

      setSelectedLocalValues(Array.from(newSelectedData));
    },
    [selectedLocalValues, setSelectedLocalValues],
  );

  const onSelectedDataRemove = useCallback(
    (value: string) => () => {
      const newSelectedData = new Set(selectedData);

      newSelectedData.delete(value);

      const newSelected = Array.from(newSelectedData);
      onChange(newSelected);
      setSelectedLocalValues(newSelected);
    },
    [selectedData, onChange],
  );

  const onSelectAll = useCallback(() => {
    const newSelectedData = filterValues.map(({ value }) => value);

    onChange(newSelectedData);
  }, [onChange, filterValues]);

  const onSelectAllLocalValues = useCallback(() => {
    const newSelectedData = filterValues.map(({ value }) => value);

    setSelectedLocalValues(newSelectedData);
  }, [setSelectedLocalValues, filterValues]);

  const onSelectNone = () => {
    setSelectedLocalValues([]);
  };

  const onRemoveAllSelected = () => {
    onSelectNone();
    onChange([]);
  };

  const onSave = () => {
    selectedLocalValues.length && onChange(selectedLocalValues);
  };

  const onFilterModeClick = (value: FilterElementModeEnum) => {
    onFilterModeChange(value);

    if (value === FilterElementModeEnum.MANUAL) {
      onSelectNone();
      onChange([]);
    }
  };

  const mainListValues = useMemo(() => {
    return filterValues.filter(({ value }) => {
      const normalizedValue = value !== '' ? value : 'NULL';
      return !selectedData.includes(normalizedValue);
    });
  }, [filterValues, selectedData]);

  const handleInvertLocalSelection = useCallback(() => {
    const selectedDataSet = new Set(selectedLocalValues);
    const allValuesSet = new Set(filterValues.map(({ value }) => value));

    const newSelectedValues = [...allValuesSet].filter((value) => !selectedDataSet.has(value));

    setSelectedLocalValues(newSelectedValues);
  }, [filterValues, setSelectedLocalValues, selectedLocalValues]);

  const handleInvertSelection = useCallback(() => {
    const selectedDataSet = new Set(selectedData);
    const allValuesSet = new Set(filterValues.map(({ value }) => value));

    const newSelectedValues = [...allValuesSet].filter((value) => !selectedDataSet.has(value));

    onChange(newSelectedValues);
    onSelectNone();
  }, [filterValues, selectedData, onChange]);

  const selectedValues = useMemo(() => {
    let mappedValues = selectedData.map((value) => {
      const count = filterValues.find((item) => item.value === value)?.count || 0;
      return { value, count };
    });

    if (selectedListSortingStatus === SortingValueEnum.ASC || selectedListSortingStatus === SortingValueEnum.DESC) {
      mappedValues = mappedValues.sort((a, b) => {
        const indexA = filterValues.findIndex((item) => item.value === a.value);
        const indexB = filterValues.findIndex((item) => item.value === b.value);
        return selectedListSortingStatus === SortingValueEnum.DESC ? indexB - indexA : indexA - indexB;
      });
    }

    return mappedValues;
  }, [selectedData, filterValues, selectedListSortingStatus]);

  const sortSelectedValueUp = (index: number) => () => {
    if (index === 0) return;
    const newSelectedValues = selectedListValues;

    if (newSelectedValues) {
      const temp = newSelectedValues[index - 1];
      newSelectedValues[index - 1] = newSelectedValues[index];
      newSelectedValues[index] = temp;
      onChange(newSelectedValues.map(({ value }) => value));
    }
  };

  const sortSelectedValueDown = (index: number) => () => {
    if (index === selectedValues.length - 1) return;
    const newSelectedValues = selectedListValues;

    if (newSelectedValues) {
      const temp = newSelectedValues[index + 1];
      newSelectedValues[index + 1] = newSelectedValues[index];
      newSelectedValues[index] = temp;
      onChange(newSelectedValues.map(({ value }) => value));
    }
  };

  useEffect(() => {
    if (filterElementMode === FilterElementModeEnum.DYNAMICALLY) {
      onSelectAll();
    }
  }, [filterElementMode, filterValues, onSelectAll]);

  useEffect(() => {
    setSelectedLocalValues(selectedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedListValues(selectedValues);
  }, [selectedValues]);

  const createVirtualizedProps = (props: Record<string, unknown>) => ({
    ...props,
  });

  const dynamicListProps = createVirtualizedProps({ values: filterValues });

  const mainListProps = createVirtualizedProps({ values: mainListValues, onAddToSelectedData, isLocalChecked });

  const selectedListProps = createVirtualizedProps({
    values: selectedListValues,
    onSelectedDataRemove,
    isChecked,
    selectedListSortingStatus,
    sortSelectedValueUp,
    sortSelectedValueDown,
  });

  if (filterElementMode === FilterElementModeEnum.DYNAMICALLY) {
    return (
      <FlexContainer flexDirection="column" width="100%" position="relative" marginTop="6px">
        <FlexContainer minHeight={loading ? '70px' : undefined}>
          {loading ? (
            <FlexContainer width="100%" justifyContent="center" alignItems="center">
              <CircularProgress />
            </FlexContainer>
          ) : (
            <FlexContainer width="100%" flexDirection="column" position="relative" gap="8px" marginTop="8px">
              <FlexContainer justifyContent="space-between">
                <SettingsRadio
                  onChange={({ value }: SettingsRadioItem<FilterElementModeEnum>) => onFilterModeClick(value)}
                  activeValue={activeMode}
                  options={filterElementModeValues}
                />
                <SortingPanel onSortingClick={setSortingStatus} selectedSorting={sortingStatus} />
              </FlexContainer>
              <FlexContainer width="100%" flexDirection="column" position="relative" maxHeight="400px" overflow="auto">
                <VirtualizedList data={dynamicListProps}>{DynamicValuesRow}</VirtualizedList>
              </FlexContainer>
            </FlexContainer>
          )}
        </FlexContainer>
      </FlexContainer>
    );
  }

  return (
    <FlexContainer flexDirection="column" width="100%" position="relative" marginTop="6px">
      <FlexContainer minHeight={loading ? '70px' : undefined}>
        <FlexContainer width="100%" flexDirection="column" position="relative" gap="8px">
          {fieldName && (
            <FlexContainer flexDirection="column" gap="8px" marginTop="8px">
              <SettingsRadio
                onChange={({ value }: SettingsRadioItem<FilterElementModeEnum>) => onFilterModeClick(value)}
                activeValue={activeMode}
                options={filterElementModeValues}
              />
              <FlexContainer>
                <SearchInput
                  useDebounce
                  name="search_filter"
                  needBackground={false}
                  type="text"
                  width="100%"
                  value={searchString}
                  onClear={() => setSearchString('')}
                  onChange={(e) => setSearchString(e.target.value)}
                />
              </FlexContainer>
            </FlexContainer>
          )}
          {!!mainListValues.length && (
            <>
              {loading ? (
                <FlexContainer width="100%" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </FlexContainer>
              ) : (
                <>
                  <FlexContainer justifyContent="space-between">
                    <Button onClick={onSave} heightSize="small" text="Выбрать" />
                    <SortingPanel
                      onSelectAllClick={onSelectAllLocalValues}
                      onSelectNoneClick={onSelectNone}
                      onSelectInvertClick={handleInvertLocalSelection}
                      onSortingClick={setSortingStatus}
                      selectedSorting={sortingStatus}
                    />
                  </FlexContainer>
                  <FlexContainer width="100%" flexDirection="column" position="relative" maxHeight="200px" overflow="auto">
                    <VirtualizedList data={mainListProps}>{MainValuesRow}</VirtualizedList>
                  </FlexContainer>
                </>
              )}
            </>
          )}
        </FlexContainer>
      </FlexContainer>
      {!!selectedValues.length && (
        <FlexContainer flexDirection="column">
          {!!mainListValues.length && <Divider sx={{ margin: '12px -12px 0' }} />}
          <FlexContainer justifyContent="space-between" margin="8px 0">
            <SelectedItemCounter
              selectedCounter={selectedValues.length}
              listCounter={filterValues.length}
              isOverLimit={isValuesOverLimit}
            />
            <SortingPanel
              onSelectNoneClick={onRemoveAllSelected}
              onSelectInvertClick={handleInvertSelection}
              onSortingClick={setSelectedListSortingStatus}
              selectedSorting={selectedListSortingStatus}
              options={sortingOptions}
            />
          </FlexContainer>
          <FlexContainer maxHeight="200px" overflow="auto" width="100%" flexDirection="column" position="relative">
            <VirtualizedList data={selectedListProps}>{SelectedValuesRow}</VirtualizedList>
          </FlexContainer>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};
