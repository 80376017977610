import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialModalsStoreState } from 'store/reducers/modals/constants';
import { ConfirmationModalInterface, ModalInfo, ModalsStateInterface, WizardModalInterface } from './types';

export const modalsSlice = createSlice({
  name: 'modals',
  initialState: initialModalsStoreState,
  reducers: {
    openModal: (state: ModalsStateInterface, { payload }: PayloadAction<ModalInfo>) => {
      state.modals = [...state.modals, payload];
    },
    closeModal: (state: ModalsStateInterface, { payload: modalName }: PayloadAction<string>) => {
      state.modals = state.modals.filter((modal) => modal.name !== modalName);
    },
    updateConfirmationModal: (state, { payload }: PayloadAction<Partial<ConfirmationModalInterface>>) => {
      state.confirmationModal = { ...state.confirmationModal, ...payload };
    },
    updateWizardModal: (state, { payload }: PayloadAction<Partial<WizardModalInterface>>) => {
      state.wizardModal = { ...state.wizardModal, ...payload };
    },
  },
  extraReducers: () => {},
});

export const { openModal, closeModal, updateConfirmationModal, updateWizardModal } = modalsSlice.actions;

export default modalsSlice.reducer;
