import { VisualisationOriginInterface, VisualisationRenderInterface } from 'modules/workspace/components/VisualisationArea/types';
import { TreeVisualisation } from 'modules/visualisations/Tree/visualisation';
import { TreeMainSettings } from 'modules/visualisations/Tree/settings/MainSettings';
import { DefaultVisualisationOptionsType } from 'store/reducers/visualisations/types';
import { TextVisualisation } from 'modules/visualisations/Text/visualisation';
import { TextMainSettings } from 'modules/visualisations/Text/settings/MainSettings';
import { PieMainSettings } from 'modules/visualisations/Pie/settings/MainSettings';
import { PieVisualisation } from 'modules/visualisations/Pie/visualisation';
import { LineAndBarMainSettings } from 'modules/visualisations/LineAndBar/settings/MainSettings';
import { LineAndBarVisualisation } from 'modules/visualisations/LineAndBar/visualisation';
import { TableVisualisation } from 'modules/visualisations/Table/visualisation';
import { TableMainSettings } from 'modules/visualisations/Table/settings/MainSettings';
import { WaterfallVisualisation } from 'modules/visualisations/Waterfall/visualisation';
import { WaterfallMainSettings } from 'modules/visualisations/Waterfall/settings/MainSettings';
import { HeatmapVisualisation } from 'modules/visualisations/Heatmap/visualisation';
import { HeatmapMainSettings } from 'modules/visualisations/Heatmap/settings/MainSettings';
import { BubbleVisualisation } from 'modules/visualisations/Bubble/visualisation';
import { BubbleMainSettings } from 'modules/visualisations/Bubble/settings/MainSettings';

export const defaultIncrementsRnd = 10;

export const handleClasses = {
  bottom: 'point',
  bottomLeft: 'point',
  bottomRight: 'point',
  left: 'point',
  right: 'point',
  top: 'point',
  topLeft: 'point',
  topRight: 'point',
};

export const EmptyComponent = () => null;

export const VisualisationRender: VisualisationRenderInterface = {
  tree: {
    Visualisation: TreeVisualisation as VisualisationOriginInterface<DefaultVisualisationOptionsType>,
    MainSettings: TreeMainSettings,
  },
  text: {
    Visualisation: TextVisualisation as VisualisationOriginInterface<DefaultVisualisationOptionsType>,
    MainSettings: TextMainSettings,
  },
  pie: {
    Visualisation: PieVisualisation as VisualisationOriginInterface<DefaultVisualisationOptionsType>,
    MainSettings: PieMainSettings,
  },
  lineAndBar: {
    Visualisation: LineAndBarVisualisation as VisualisationOriginInterface<DefaultVisualisationOptionsType>,
    MainSettings: LineAndBarMainSettings,
  },
  waterfall: {
    Visualisation: WaterfallVisualisation as VisualisationOriginInterface<DefaultVisualisationOptionsType>,
    MainSettings: WaterfallMainSettings,
  },
  heatmap: {
    Visualisation: HeatmapVisualisation as VisualisationOriginInterface<DefaultVisualisationOptionsType>,
    MainSettings: HeatmapMainSettings,
  },
  bubble: {
    Visualisation: BubbleVisualisation as VisualisationOriginInterface<DefaultVisualisationOptionsType>,
    MainSettings: BubbleMainSettings,
  },
  table: {
    Visualisation: TableVisualisation as VisualisationOriginInterface<DefaultVisualisationOptionsType>,
    MainSettings: TableMainSettings,
  },
};
