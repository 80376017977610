import { ArrowIcon } from 'modules/ui/icons/ArrowIcon/styles';
import React, { memo } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { iconContainerWidth } from 'modules/ui/lists/MapList/item/CommonItem/constants';
import { CommonItem, CommonItemProps } from '../CommonItem';

interface TitleItemProps extends Omit<CommonItemProps, 'afterSlot'> {
  isOpen: boolean;
}

export const TitleItemComponent = ({ title, Icon, isOpen, disabled, onClick }: TitleItemProps) => {
  return (
    <CommonItem
      title={title}
      Icon={Icon}
      disabled={disabled}
      onClick={onClick}
      afterSlot={
        <FlexContainer alignItems="center" justifyContent="center" width={iconContainerWidth} height={iconContainerWidth}>
          <ArrowIcon isActive={isOpen} />
        </FlexContainer>
      }
    />
  );
};

export const TitleItem = memo(TitleItemComponent);
