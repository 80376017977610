import React, { useMemo } from 'react';
import { BooleanSettings } from 'modules/settingsContainer/common/BooleanSettings';
import { NoopValueType } from 'types/global';
import { FilterTypeEnum } from 'store/reducers/filters/types';
import isNull from 'lodash/isNull';

interface MultipleChoiceSettingsInterface {
  value: boolean | null;
  onChange: NoopValueType<boolean>;
  type: FilterTypeEnum;
}

export const MultipleChoiceSettings = ({ value, onChange, type }: MultipleChoiceSettingsInterface) => {
  const booleanValue = useMemo(() => {
    if (type === FilterTypeEnum.SINGLE) {
      return !isNull(value) ? value : false;
    }

    if (type === FilterTypeEnum.MULTIPLE) {
      return !isNull(value) ? value : true;
    }

    return false;
  }, [value, type]);

  return <BooleanSettings titleText="Множественный выбор" value={booleanValue} onChange={onChange} />;
};
