import { defaultScrollbarMixin } from 'constants/styles';
import { Chip, Paper, TextField } from '@mui/material';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ClearIcon, DownIcon } from 'assets/icons/withContainer';
import styled, { css } from 'styled-components';

export const AutocompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .MuiAutocomplete-endAdornment {
    display: flex;
    gap: 4px;
    top: 50%;
  }
`;

export const StyledPaper = styled(Paper)`
  border-radius: 0 !important;
  background-color: var(${ColorVarsEnum.Level_3_menu}) !important;

  .MuiAutocomplete-listbox {
    border-radius: 2px;
    border: 1px solid var(${ColorVarsEnum.Level_4});
    ${defaultScrollbarMixin};
  }
`;

export const CustomChip = styled(Chip)`
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400;
  color: ${`var(${ColorVarsEnum.Level_1})`} !important;
  height: 24px !important;
  border-radius: 30px;
  position: relative;
  background-color: transparent;
  overflow: hidden;

  .MuiChip-label {
    padding: 0 10px 0 8px;
  }

  svg {
    position: relative;
    z-index: 1;
  }

  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: var(${ColorVarsEnum.Level_1});
    opacity: 0.1;
  }
`;

export const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    padding: 4px 40px 4px 8px !important;
    align-items: center;
  }

  .MuiInputBase-input {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: ${`var(${ColorVarsEnum.Level_1})`};
    height: 24px;
    padding: 0 !important;
  }

  fieldset {
    border: 1px solid ${`var(${ColorVarsEnum.Level_4})`} !important;
  }
`;

type MenuItemType = {
  underLine?: boolean;
};

export const MenuItem = styled.li<MenuItemType>`
  position: relative;
  z-index: 3;
  padding: 4px 8px !important;
  display: flex;
  gap: 8px;

  ${({ underLine }) =>
    underLine &&
    css`
      border-bottom: 1px solid var(${ColorVarsEnum.Level_3});
    `}

  :hover {
    p {
      color: var(${ColorVarsEnum.Level_1});
    }

    :before {
      background: var(${ColorVarsEnum.Level_1}) !important;
      opacity: 0.1;
    }
  }
`;

export const CheckBoxWrapper = styled.div`
  margin-left: auto;
`;

export const ArrowIcon = styled(DownIcon)`
  height: 16px;
  width: 16px;
`;

type RemoveIconType = {
  small?: boolean;
};

export const RemoveIcon = styled(ClearIcon)<RemoveIconType>`
  height: ${({ small }) => (small ? 12 : 16)}px;
  width: ${({ small }) => (small ? 12 : 16)}px;
`;
