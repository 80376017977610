import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import styled, { css } from 'styled-components';
import { FlexContainer } from 'styles/FlexContainer';
import {
  headerSidebarDropDownHeight,
  sidebarContainerHeight,
  sidebarPadding,
} from 'modules/settingsContainer/SideBarWrapper/constants';
import { PrimaryTextParagraph } from 'styles/TextsElements';

interface SidebarContainerProps {
  width: string;
  height?: string;
  backgroundColor?: string;
}

export const ButtonContainer = styled(FlexContainer)``;

export const SidebarContainer = styled.div<SidebarContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: ${({ width }) => width};
  min-width: ${({ width }) => width};
  background-color: ${({ backgroundColor }) => backgroundColor || `var(${ColorVarsEnum.Level_5_application})`};
  border-right: 1px solid var(${ColorVarsEnum.Level_5});
  z-index: 2;
  transition: all 150ms;
  box-sizing: border-box;

  ${({ width }) => width && `width: ${width};`};
  height: ${({ height }) => height || sidebarContainerHeight};
  ${ButtonContainer}: {
    position: fixed;
    bottom: 0;
  }
`;

interface SidebarDropDownProps {
  isShowSidebar: boolean;
}

export const SidebarHeaderDropDown = styled.div<SidebarDropDownProps>`
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  flex: 0 0 40px;
  transition: all 150ms;
  border-bottom: 1px solid var(${ColorVarsEnum.Level_5});

  ${({ isShowSidebar }) =>
    !isShowSidebar &&
    css`
      padding: 12px 0;
      writing-mode: vertical-rl;
      transform: scale(-1);
    `};
`;

interface TitleSidebarProps {
  isShowSidebar: boolean;
}

export const TitleSidebar = styled(PrimaryTextParagraph)<TitleSidebarProps>`
  padding: ${({ isShowSidebar }) => `0 ${isShowSidebar ? sidebarPadding : 0} 0 `};
  margin-bottom: ${({ isShowSidebar }) => (isShowSidebar ? '0' : sidebarPadding)};
`;

export const SidebarBodyDropDown = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100% - ${headerSidebarDropDownHeight});
`;
