import React from 'react';
import { DefaultFilterDataSettings } from 'modules/settingsContainer/common/data/DefaultFilterDataSettings';
import { useGetActiveFilter } from 'modules/filters/hooks/getActiveFilter';
import {
  onFilterElementsChange,
  onSqlDataChange,
} from 'modules/settingsContainer/common/data/DefaultFilterDataSettings/constants';
import { LimitSettings } from 'modules/settingsContainer/common/data/LimitSettings';
import { onAlwaysOpenChange, onLimitChange, onMultipleModeChange } from 'modules/filters/Single/settings/DataTab/constants';
import { MultipleChoiceSettings } from 'modules/settingsContainer/common/data/MultipleChoiceSettings';
import { FilterTypeEnum } from 'store/reducers/filters/types';
import { AlwaysOpenSettings } from 'modules/settingsContainer/common/data/AlwaysOpenSettings';
import { FilterIncisionsSettings } from 'modules/settingsContainer/FIlterIncisionsSettings';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import { sqlParser } from 'utils/SQL/genereteAst';
import Snackbar from 'services/Snackbar';
import { ErrorMessageInterface } from 'types/global';

export const DataTab = () => {
  const { data, modelIdValue, tableFields } = useGetActiveFilter({ type: 'date' });
  const { modelMetaData } = useDataSettingsMetric(data.modelId);

  const { isRealData, nameSettings, fictionalData, limit, isMultipleMode, isAlwaysOpen } = data;

  const onChangeSqlData = ({
    incisionRequest,
    filterAndGroupRequest,
  }: {
    incisionRequest: string;
    filterAndGroupRequest: string;
  }) => {
    try {
      const isValidIncisionAst = !incisionRequest ? true : sqlParser.astify(`SELECT ${incisionRequest}`);

      if (isValidIncisionAst) {
        onSqlDataChange({
          sqlData: {
            incisionRequest,
            filterAndGroupRequest,
          },
        });
      }
    } catch (error) {
      const message = (error as ErrorMessageInterface)?.message || '';
      Snackbar.show(message, 'error');
    }
  };

  return (
    <>
      <DefaultFilterDataSettings modelIdValue={modelIdValue} data={data}>
        <FilterIncisionsSettings
          onChange={onFilterElementsChange}
          isRealData={isRealData}
          options={tableFields}
          value={{ fictionalData, nameSettings }}
          modelMetaData={modelMetaData}
          onChangeSqlData={onChangeSqlData}
          data={data}
        />
        <LimitSettings value={limit} onChange={onLimitChange} width="auto" />
        <MultipleChoiceSettings value={isMultipleMode} onChange={onMultipleModeChange} type={FilterTypeEnum.MULTIPLE} />
        <AlwaysOpenSettings value={isAlwaysOpen!} onChange={onAlwaysOpenChange} />
      </DefaultFilterDataSettings>
    </>
  );
};
