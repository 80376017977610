import React, { FC, useEffect, useMemo } from 'react';
import { LoadLayoutAdmin } from 'components/layouts/LoadLayoutAdmin';
import { UniversalLeftBarList } from 'modules/settingsContainer/UniversalLeftBarList';
import { SectionMiddleUI } from 'modules/ui/ManagerOrAdminComponentsUI/SectionMiddleUI';
import { useDeleteGroup } from './hook/useDeleteGroup';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { SourceIcon } from 'assets/icons/navigations';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Tab } from 'modules/ui/tabs/types';
import styled from 'styled-components';
import { changeActiveSourceAction, loadAdminSourcesAction } from 'store/reducers/adminSources/actions';
import { getAdminActiveSource, getAdminSources } from 'store/reducers/adminSources/getters';
import { ActiveSourceInterface } from 'store/reducers/adminSources/types';
import { AccessSource } from './elements/AccessSource';

export const AdminSources: FC = () => {
  const dispatch = useAppDispatch();

  const { loading, sourcesList } = useSelector(getAdminSources);
  const activeSource = useSelector(getAdminActiveSource);

  const activeSourceId = activeSource?.id;
  const activeSourceTitle = activeSource?.title;

  const { onDeleteModal } = useDeleteGroup();

  useEffect(() => {
    dispatch(loadAdminSourcesAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!activeSourceId && sourcesList.length > 0) onSelectedItem({ id: sourcesList[0]?.id, title: sourcesList[0]?.name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourcesList]);

  const modelMetaDataList = sourcesList?.map(({ id, name }) => ({
    items: [],
    id: id,
    title: name,
    type: name,
  }));

  const tabs: Tab[] = useMemo(
    () =>
      [
        {
          content: 'Доступ',
          prompt: 'Доступ',
          type: 'text',
          id: 'access',
          Component: AccessSource,
        },
      ].filter(({ Component }) => Component) as Tab[],
    [],
  );

  const onSelectedItem = ({ id, title }: ActiveSourceInterface) => {
    dispatch(changeActiveSourceAction({ id, title }));
  };

  return (
    <LoadLayoutAdmin>
      <Body>
        <UniversalLeftBarList
          universalLeftBarMarginBottom="0"
          disabledMobileVersion
          data={modelMetaDataList}
          activeTitle={activeSourceTitle}
          activeId={activeSourceId}
          loadingList={loading}
          disabledAdditionalField
          onSelectedItem={onSelectedItem}
          onDeleteItem={onDeleteModal}
        />

        {activeSourceId && activeSourceTitle && (
          <SectionMiddleUI
            tabs={tabs}
            title={activeSourceTitle}
            id={activeSourceId}
            onDeleteModal={onDeleteModal}
            isAccessControl
            HeaderIcon={SourceIcon}
            disabledEdit
            disabledMobileVersion
          />
        )}
      </Body>
    </LoadLayoutAdmin>
  );
};

const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background: ${`var(${ColorVarsEnum.Level_5_application})`};
  flex-wrap: nowrap;
  gap: 24px;
`;
