import React, { useCallback } from 'react';
import { ModalComponentType } from 'store/reducers/modals/types';
import { closeConfirmationModalAction } from 'store/reducers/modals/actions';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { CreateCopyOfVersionModalUI } from 'modules/ui/ManagerOrAdminComponentsUI/ModalsUI/CreateCopyOfVersionModalUI';
import Snackbar from 'services/Snackbar';
import { createUserDraftProjectVersionAnotherAction, loadDraftProjectsAction } from 'store/reducers/adminUsers/actions';
import { getActiveUser } from 'store/reducers/adminUsers/getters';

interface CreateUserDraftCopyOfVersionModalProps {
  projectId: string;
  projectName: string;
  dateTimeHistory: string;
}

export const CreateUserDraftCopyOfVersionModal: ModalComponentType<CreateUserDraftCopyOfVersionModalProps> = ({
  onClose,
  projectId,
  projectName,
  dateTimeHistory,
}) => {
  const dispatch = useAppDispatch();

  const activeUser = useSelector(getActiveUser);

  const activeUserID = activeUser?.id;

  const onCreateProjectVersionAnother = useCallback(
    async ({ renameValue, runImport }: { renameValue: string; runImport: boolean }) => {
      if (!renameValue) {
        return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
      }
      if (!activeUserID) {
        return;
      }

      try {
        const projectVersionCreationParams = {
          projectId,
          name: renameValue,
          flowId: activeUserID,
          dateTimeHistory,
          runImport,
        };

        const actionCreateProjectVersion = await dispatch(
          createUserDraftProjectVersionAnotherAction(projectVersionCreationParams),
        ).unwrap();

        if (actionCreateProjectVersion) {
          await dispatch(loadDraftProjectsAction({ userId: activeUserID }));
          dispatch(closeConfirmationModalAction());
          onClose();
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUserID, projectId, dateTimeHistory, onClose],
  );

  return (
    <CreateCopyOfVersionModalUI
      onRestoreVersionModal={onCreateProjectVersionAnother}
      onClose={onClose}
      projectName={projectName}
    />
  );
};
