import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const TableDefault: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fill = `var(${ColorVarsEnum.Accent})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <rect x="45" y="30" width="14" height="8" fill={fill} />
        <path
          opacity="0.75"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62 8H8V19H62V8ZM56 12H48V15H56V12ZM31 12H39V15H31V12ZM22 12H14V15H22V12Z"
          fill={fillDefault}
        />
        <path
          opacity="0.15"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 48.0001V38.3497V38.0001L8 27.5765L8 27.0001L8 19H11L11 27.0001L25 27.0001L25 19.0001L28 19.0001L28 27.0001H42V19.0001L45 19.0001V27.0001L59 27.0001L59 19.0001L62 19.0001L62 58.9999V61.9999L62 62.0001H59V61.9999H45V62.0001H42V61.9999H28V62.0001H25V61.9999H11V62H8.00001L8.00001 61.9999V58.9999L8.00001 51.0001L8 48.0001ZM11 58.9999H25L25 51.0001H11L11 58.9999ZM28 58.9999H42L42 51.0001H28L28 58.9999ZM45 58.9999H59L59 51.0001H45L45 58.9999ZM59 30.0001H45V38.0001H59L59 30.0001ZM42 30.0001H28L28 38.0001H42V30.0001ZM25 30.0001H11L11 38.0001H25L25 30.0001ZM11 48.0001L11 41.0001H25L25 48.0001H11ZM28 48.0001L28 41.0001H42V48.0001H28ZM45 48.0001V41.0001H59L59 48.0001H45Z"
          fill={fillDefault}
        />
        <rect x="28" y="51" width="14" height="8" fill={fill} />
      </svg>
    </FlexContainer>
  );
};
