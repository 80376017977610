import { useCallback } from 'react';
import { useAppDispatch } from 'store';
import { openConfirmationModalAction } from 'store/reducers/modals/actions';
import { getGroups } from 'store/reducers/adminGroups/getters';
import { changeActiveGroupAction, deleteByIdGroupAction, deleteGroupAction } from 'store/reducers/adminGroups/actions';
import { useSelector } from 'react-redux';

export const useDeleteGroup = () => {
  const dispatch = useAppDispatch();
  const { groupsList } = useSelector(getGroups);

  const onDelete = useCallback(
    async (groupId: string) => {
      try {
        const action = await dispatch(deleteGroupAction(groupId)).unwrap();
        if (action) {
          await dispatch(deleteByIdGroupAction(action));
          await dispatch(changeActiveGroupAction({ id: groupsList[0]?.id, title: groupsList[0]?.title }));
        }
      } catch (e) {
        console.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onDeleteModal = useCallback(
    ({ id, title }: { id: string; title: string }) =>
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          subTitleText: `Действительно удалить подключение «${title}»?`,
          onConfirm: () => onDelete(id),
          titleText: 'Удаление подключения',
          width: '320px',
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  return { onDelete, onDeleteModal };
};
