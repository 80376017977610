import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ReactNode } from 'react';

export enum WrapperIconEnum {
  WRAP = 'WRAP',
  NOWRAP = 'NOWRAP',
}

export const FilterInfluenceIconWrapper = ({ children, onClick }: { children: ReactNode; onClick: () => void }) => {
  return (
    <div
      onClick={onClick}
      style={{
        background: `var(${ColorVarsEnum.Level_2_btn})`,
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '2px',
        cursor: 'pointer',
      }}
    >
      {children}
    </div>
  );
};
