import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ProjectIdParam } from 'constants/Routes';
import { useAppDispatch } from 'store';
import { loadFirstPageByProjectIdAction } from 'store/reducers/projectPages/actions';
import { ReactChildrenType } from 'types/global';

interface ProjectRoureProps {
  children: ReactChildrenType;
}

export const ProjectRoure = ({ children }: ProjectRoureProps) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { projectId } = useParams<ProjectIdParam>();

  useEffect(() => {
    if (projectId) {
      dispatch(loadFirstPageByProjectIdAction(projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, pathname]);

  return <>{children}</>;
};
