import React from 'react';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { FlexContainer, FlexContainerProps } from 'styles/FlexContainer';
import { getStringDateByFormat } from 'utils/dates';
import { byTypeFormat, defaultTimeSecondFormat } from 'constants/dates';
import { Button } from 'modules/ui/index';
import { NoopType, NoopValueType } from 'types/global';
import { ProjectHistoryVersionList } from 'modules/ui/ManagerOrAdminComponentsUI/HistoryVersionProjectUI/types';
import { HistoryVersionItem } from 'modules/ui/ManagerOrAdminComponentsUI/HistoryVersionProjectUI/styles';

interface HistoryVersionProjectUIProps {
  padding?: string;
  footerPosition?: FlexContainerProps['justifyContent'];
  onClose?: NoopType;
  onRestoreVersionModal: NoopType;
  onCreateCopyOfVersion: NoopType;
  data: ProjectHistoryVersionList[];
  isLoading: boolean;
  selectedVersion: ProjectHistoryVersionList | null;
  onItemSelect: NoopValueType<ProjectHistoryVersionList>;
}
export const HistoryVersionProjectUI = ({
  data,
  isLoading,
  footerPosition,
  padding = '0 24px',
  onCreateCopyOfVersion,
  onRestoreVersionModal,
  selectedVersion,
  onItemSelect,
}: HistoryVersionProjectUIProps) => {
  return (
    <>
      <FlexContainer overflow="auto" flexDirection="column" position="relative" padding={padding} height="100%">
        <LoadingOverlay loading={isLoading} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />

        {data.map(({ id, dateTimeHistory, userName, storageMethod }) => (
          <HistoryVersionItem
            key={id}
            onClick={() => onItemSelect({ id, dateTimeHistory, userName, storageMethod })}
            activeSelected={id === selectedVersion?.id}
          >
            <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_1})`} fontSize="14px" lineHeight="120%" marginRight="8px">
              {getStringDateByFormat(new Date(dateTimeHistory), byTypeFormat.byDay)}
            </PrimaryTextSpan>
            <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_1})`} fontSize="14px" lineHeight="120%" marginRight="16px">
              {getStringDateByFormat(new Date(dateTimeHistory), defaultTimeSecondFormat)}
            </PrimaryTextSpan>
            {userName && (
              <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_3})`} fontSize="14px" lineHeight="120%" marginRight="8px">
                {userName}
              </PrimaryTextSpan>
            )}
          </HistoryVersionItem>
        ))}
      </FlexContainer>
      <FlexContainer
        position="fixed"
        bottom="0"
        alignItems="center"
        justifyContent={footerPosition || 'center'}
        width="100%"
        maxWidth="400px"
        height="76px"
        gap="16px"
        right="0"
        padding="0 20px"
      >
        <Button text="Откатить" needBackground={true} disabled={!selectedVersion} onClick={onRestoreVersionModal} />
        <Button disabled={!selectedVersion} text="Создать копию" needBackground={true} onClick={onCreateCopyOfVersion} />
      </FlexContainer>
    </>
  );
};
