import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import {
  CreateTablesScriptActionPayload,
  FieldsPreviewTablesPayload,
  InformationTableSourceInterface,
  InformationTableSourcePayload,
  LoadingScriptActionsTypes,
  SourceTablesInterface,
  TableFieldsPreviewInterface,
  UploadSQLScriptPayload,
} from 'store/reducers/loadingScript/types';
import {
  createTablesScript,
  loadCopyURLScript,
  loadFieldsPreviewTables,
  loadInformationTableSource,
  loadSQLScript,
  loadSQLScriptStatus,
  loadTables,
  runSQLScript,
  uploadSQLScript,
} from 'store/reducers/loadingScript/api';
import { initialLoadingScriptStoreState } from 'store/reducers/loadingScript/constants';
import { setSlice } from '.';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');

  return rejectWithValue(errorMessage);
};

export const loadSQLScriptAction = createAsyncThunk<string, string, { rejectValue: null }>(
  LoadingScriptActionsTypes.GET_SQL_SCRIPT,
  async (projectId: string, { rejectWithValue }) => {
    try {
      const response = await loadSQLScript(projectId);

      return response.data.script;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const runSQLScriptAction = createAsyncThunk<string, string, { rejectValue: null }>(
  LoadingScriptActionsTypes.RUN_SQL_SCRIPT,
  async (projectId: string, { rejectWithValue }) => {
    try {
      const response = await runSQLScript(projectId);
      return response.data;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadTablesAction = createAsyncThunk<SourceTablesInterface[], string>(
  LoadingScriptActionsTypes.LOADING_TABLES,
  async (sourceId: string, { rejectWithValue }) => {
    try {
      const response = await loadTables(sourceId);

      return response.data.sourceTables.map((el) => ({ items: 'items' in el ? el?.items : [], type: el.type, title: el.title }));
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as SourceTablesInterface[];
    }
  },
);

export const loadFieldsPreviewTablesAction = createAsyncThunk<TableFieldsPreviewInterface, FieldsPreviewTablesPayload>(
  LoadingScriptActionsTypes.LOADING_PREVIEW_TABLES,
  async ({ sourceId, tableName, schemaName }, { rejectWithValue }) => {
    try {
      const response = await loadFieldsPreviewTables({ sourceId, tableName, schemaName });

      return response.data.sourceTablePreview;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadInformationTableSourceAction = createAsyncThunk<
  InformationTableSourceInterface['rowCount'],
  InformationTableSourcePayload
>(LoadingScriptActionsTypes.LOADING_PREVIEW_TABLES, async ({ sourceId, tableName, schemaName }, { rejectWithValue }) => {
  try {
    const response = await loadInformationTableSource({ sourceId, tableName, schemaName });

    return response.data.sourceTableMeta.rowCount;
  } catch (err: any) {
    return validateError(err, rejectWithValue);
  }
});

export const createTablesScriptAction = createAsyncThunk<string, CreateTablesScriptActionPayload, { rejectValue: null }>(
  LoadingScriptActionsTypes.CREATE_TABLES_SCRIPT,
  async ({ sourceId, tables, projectId }, { rejectWithValue }) => {
    try {
      const response = await createTablesScript({ sourceId, projectId, tables });
      return response.data;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const uploadSQLScriptAction = createAsyncThunk(
  LoadingScriptActionsTypes.UPLOAD_SQL_SCRIPT,
  async ({ script, projectId }: UploadSQLScriptPayload, { rejectWithValue }) => {
    try {
      return await uploadSQLScript({ script, projectId });
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadSQLScriptStatusAction = createAsyncThunk<FastBoard.API.ProjectsLoadingStatusResponseDTO, string>(
  LoadingScriptActionsTypes.LOAD_SOURCE_FILE_STATUS,
  async (loadingId: string, { rejectWithValue }) => {
    try {
      const response = await loadSQLScriptStatus(loadingId);
      return response.data;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadCopyURLScriptAction = createAsyncThunk(
  LoadingScriptActionsTypes.LOAD_COPY_URL_SCRIPT,
  async (projectId: string, { rejectWithValue }) => {
    try {
      const response = await loadCopyURLScript(projectId);
      return response.data;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const clearLoadingScriptStore = createAsyncThunk(LoadingScriptActionsTypes.CLEAR_LOADING_SCRIPT, (_, { dispatch }) => {
  dispatch(setSlice(initialLoadingScriptStoreState));
});

export const clearSourceTablesStore = createAsyncThunk(LoadingScriptActionsTypes.CLEAR_SOURCE_TABLES, (_, { dispatch }) => {
  dispatch(setSlice(initialLoadingScriptStoreState.sourceTables));
});
