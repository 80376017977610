import React, { useState } from 'react';
import { MultiSelect, MultiSelectType } from 'modules/ui/MultiSelect';
import { Button } from 'modules/ui';
import { FlexContainer } from 'styles/FlexContainer';
import { ModalComponentType } from 'store/reducers/modals/types';
import { NoopValueType } from 'types/global';

type MultiSelectFilterProps = {
  label?: string;
  onSaveFilter: NoopValueType<MultiSelectType<string>[]>;
  filterOptions: MultiSelectType<string>[];
  saveMultiFilter: MultiSelectType<string>[];
};

export const MultiSelectFilter: ModalComponentType<MultiSelectFilterProps> = ({
  onClose,
  label = 'Выберите типы',
  filterOptions,
  onSaveFilter,
  saveMultiFilter,
}) => {
  const [selectedValue, setSelectedValue] = useState<MultiSelectType<string>[]>(saveMultiFilter);

  return (
    <FlexContainer position="relative" width="100%" flexDirection="column" padding="4px" gap="16px" height="100%">
      <MultiSelect disabledFirst label={label} valueOption={selectedValue} options={filterOptions} onChange={setSelectedValue} />

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button
          text="Добавить"
          iconSize="normal"
          heightSize="normal"
          onClick={() => {
            onSaveFilter(selectedValue);
            onClose();
          }}
        />
      </FlexContainer>
    </FlexContainer>
  );
};
