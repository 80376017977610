import { SingleEnabledFilter } from 'modules/filters/Single/view/EnabledFilter';
import { MultipleEnabledFilter } from 'modules/filters/Multiple/view/EnabledFilter';
import { EnabledFilterRenderInterface, EnabledFilterViewInterface } from 'modules/workspace/components/FilterRow/types';
import { DateEnabledFilter } from 'modules/filters/Date/view/EnabledFilter';

export const enabledFilterView: EnabledFilterRenderInterface = {
  single: SingleEnabledFilter as EnabledFilterViewInterface,
  multiple: MultipleEnabledFilter as EnabledFilterViewInterface,
  date: DateEnabledFilter as EnabledFilterViewInterface,
};
