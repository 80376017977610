import styled from 'styled-components';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export interface DraftsContainerProps {
  activeSelected?: boolean;
}

export const InfoContainer = styled(FlexContainer)`
  min-width: 400px;
  border-left: 1px solid var(${ColorVarsEnum.Level_5});
  @media (max-width: 1024px) {
    display: none;
  }
`;
