import styled from 'styled-components';
import { contentPadding } from 'pages/Models/constants';

export const ModelsBoardWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ModelContent = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: ${contentPadding};
`;
