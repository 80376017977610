import { PrimaryTextParagraph } from 'styles/TextsElements';
import React from 'react';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { FlexContainer } from 'styles/FlexContainer';
import { DeleteIcon, EditIcon, IconType } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { HeaderTitleWrapper, IconsBlock } from './styles';
import { NoopType } from 'types/global';

interface HeaderTitleProps {
  title: string;
  onDelete: NoopType;
  onEdit: NoopType;
  Icon?: IconType;
  padding?: string;
  disabledHover?: boolean;
  disabledEdit?: boolean;
}

export const HeaderTitle = ({
  title,
  Icon,
  padding,
  onDelete,
  onEdit,
  disabledHover,
  disabledEdit = false,
}: HeaderTitleProps) => {
  return (
    <HeaderTitleWrapper flexDirection="column" padding={padding} cursor="pointer">
      <FlexContainer flexDirection="row" alignItems="center" gap="16px">
        {Icon && (
          <IconWrapper
            containerHeight="50px"
            iconWidth="48px"
            iconHeight="48px"
            Icon={Icon}
            colorVar={ColorVarsEnum.Level_4}
            hoverColorVar={ColorVarsEnum.Level_4}
            opacity={0}
          />
        )}
        {title && (
          <PrimaryTextParagraph color={`var(${ColorVarsEnum.Level_1})`} fontSize="24px" fontWeight="bold">
            {title}
          </PrimaryTextParagraph>
        )}
        {!disabledHover && (
          <IconsBlock>
            {!disabledEdit && (
              <IconWrapper
                onClick={onEdit}
                iconWidth="17px"
                iconHeight="17px"
                containerHeight="30px"
                containerWidth="30px"
                Icon={EditIcon}
              />
            )}
            <IconWrapper
              onClick={onDelete}
              iconWidth="17px"
              iconHeight="17px"
              containerHeight="30px"
              containerWidth="30px"
              Icon={DeleteIcon}
            />
          </IconsBlock>
        )}
      </FlexContainer>
    </HeaderTitleWrapper>
  );
};
