import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { cssVar, lighten } from 'polished';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

import { DBIcon, Home } from 'assets/icons/forDelete';
import { selectPage } from 'store/reducers/projectPages';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import ColorTheme from 'constants/ColorTheme';
import { goToBoardUrl, goToConsolePageUrl, PageIdParam, ProjectIdParam, RoutesURL } from 'constants/Routes';
import { FlexContainer } from 'styles/FlexContainer';
import { useWindowDimensions } from 'utils/hooks/screenSizeHook';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { DownIcon } from 'assets/icons/withContainer';
import { startMobileSize } from 'constants/constants';
import { useToggle } from 'utils/hooks/toggle';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { NotViewerWrapper } from 'shared/accessWrapper';
import { AfterLineBottom } from 'modules/ui/AfterLine';
import { getPages, getPagesLoading } from 'store/reducers/projectPages/getters';
import { Loader } from 'shared/ui/Loader';

const TooltipModded = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  () => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `var(${ColorVarsEnum.Level_5_application})`,
      border: `1px solid var(${ColorVarsEnum.Level_5})`,
      padding: 0,
    },
  }),
);

export const WorkSpaceHeader = () => {
  const navigate = useNavigate();
  const pages = useSelector(getPages);
  const dispatch = useDispatch();
  const { projectId } = useParams<ProjectIdParam>();
  const { pageId } = useParams<PageIdParam>();
  const { width } = useWindowDimensions();
  const pagesLoading = useSelector(getPagesLoading);

  const [showDropDownMenu, toggleShowDropDownMenu] = useToggle(false);

  const visiblePages = pages.filter((page) => !page?.isHidden && page);

  const onTabNavigate = (id: string) => () => {
    navigate(goToBoardUrl(projectId, id));
    toggleShowDropDownMenu();
  };

  const renderTabs = () => {
    if (pages) {
      if (width > startMobileSize) {
        return visiblePages.map((page) => (
          <Tab key={page?.id} active={page?.id === pageId} onClick={onTabNavigate(page?.id)}>
            {page.name}
          </Tab>
        ));
      } else {
        return (
          <TooltipModded
            title={
              <FlexContainer backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} flexDirection="column">
                {visiblePages.map((el) => (
                  <AfterLineBottom key={el?.id}>
                    <FlexContainer
                      alignItems="center"
                      minWidth="172px"
                      padding="10px"
                      cursor="pointer"
                      onClick={onTabNavigate(el?.id)}
                    >
                      <PrimaryTextSpan
                        fontSize="12px"
                        lineHeight="150%"
                        color={el?.id === pageId ? `var(${ColorVarsEnum.Accent})` : `var(${ColorVarsEnum.Level_1})`}
                      >
                        {el.name}
                      </PrimaryTextSpan>
                    </FlexContainer>
                  </AfterLineBottom>
                ))}
              </FlexContainer>
            }
            id="dropDownMenu"
            open={showDropDownMenu}
            placement="bottom"
          >
            <FlexContainer onClick={toggleShowDropDownMenu} alignItems="center">
              <Tab active>{pages.find((el) => el.id === pageId)?.name || ''}</Tab>
              <IconWrapper
                Icon={DownIcon}
                colorVar={ColorVarsEnum.Accent}
                hoverColorVar={ColorVarsEnum.Accent}
                iconWidth="16px"
                iconHeight="16px"
              />
            </FlexContainer>
          </TooltipModded>
        );
      }
    }

    return '';
  };

  useEffect(() => {
    if (pageId !== undefined) {
      dispatch(selectPage({ id: pageId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  useEffect(() => {
    if (pages.length < Number(pageId)) {
      navigate(goToBoardUrl(projectId, '0'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  return (
    <HeaderPreview>
      <HomeSvg onClick={() => navigate(RoutesURL.projectsList)} />
      {pagesLoading || pages.length === 0 ? (
        <FlexContainer>
          <Loader size="small" title="Загрузка страниц" backgroundColor={ColorVarsEnum.Level_5_application} />
        </FlexContainer>
      ) : (
        <TabsContainer maxWidth={width - 150}>{renderTabs()}</TabsContainer>
      )}

      <FlexContainer>
        <NotViewerWrapper>
          <DBIconSvg onClick={() => navigate(goToConsolePageUrl(projectId))} />
        </NotViewerWrapper>
      </FlexContainer>
    </HeaderPreview>
  );
};

const HeaderPreview = styled.div`
  min-height: 50px;
  background: ${`var(${ColorVarsEnum.Level_5_application})`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  border-bottom: 1px solid ${`var(${ColorVarsEnum.Level_5})`};
  gap: 16px;
`;

interface ITabsContainer {
  maxWidth: number;
}

const TabsContainer = styled.div<ITabsContainer>`
  display: flex;
  align-items: center;
  overflow: auto;
  gap: 30px;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    transform: rotate(90deg);
    top: 20px;
    right: 20px;
    position: absolute;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${`var(${ColorVarsEnum.Level_5})`};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${`var(${ColorVarsEnum.Level_4})`};
  }
`;

const Tab = styled.button<{ active: boolean }>`
  outline: unset;
  border: unset;
  background-color: transparent;
  padding: 10px;
  font-size: 14px;
  line-height: 100%;
  color: ${({ active }) => (active ? `var(${ColorVarsEnum.Accent})` : `var(${ColorVarsEnum.Level_2})`)};
  cursor: pointer;

  :focus {
    outline: unset;
  }
`;

const HomeSvg = styled(Home)`
  cursor: pointer;

  path,
  circle {
    stroke: ${`var(${ColorVarsEnum.Level_3})`};
    transition: all 450ms;
  }

  &:hover {
    path {
      stroke: ${lighten(0.1, cssVar(ColorVarsEnum.Level_2, ColorTheme.default.level_2) as string)};
    }
  }
`;

const DBIconSvg = styled(DBIcon)`
  cursor: pointer;

  path,
  circle {
    stroke: ${`var(${ColorVarsEnum.Level_3})`};
    transition: all 450ms;
  }

  &:hover {
    path {
      stroke: ${lighten(0.1, cssVar(ColorVarsEnum.Level_2, ColorTheme.default.level_2) as string)};
    }
  }
`;
