import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { GroupSvg, UserSvg } from 'components/admin/sources/elements/AccessSource/styles';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCell } from 'modules/ui/Table/TableCell';
import { TableWithSearch } from 'modules/ui/TableWithSearch';
import { useAppDispatch } from 'store';
import { SourceModal } from 'components/admin/usersPage/elemets/Sources/Modal/SourcesModal';
import { getActiveUser, getUserSources } from 'store/reducers/adminUsers/getters';
import {
  deleteByIdUserSourceAction,
  deleteUserSourceAction,
  loadNoUserSourcesAction,
  loadUserSourcesAction,
} from 'store/reducers/adminUsers/actions';
import { UserSourcesListInterface } from 'store/reducers/adminUsers/types';
import {
  closeConfirmationModalAction,
  closeModalAction,
  openConfirmationModalAction,
  openModalTypedAction,
} from 'store/reducers/modals/actions';
import { accessText } from 'components/admin/groups/constants';
import { addUserSource, editUserSource } from 'components/admin/usersPage/elemets/Sources/constants';
import { useSelector } from 'react-redux';
import { FlexContainer } from 'styles/FlexContainer';

export const Sources: FC = () => {
  const dispatch = useAppDispatch();

  const activeUser = useSelector(getActiveUser);
  const activeUserId = activeUser?.id;
  const { loading, userSourcesList } = useSelector(getUserSources);

  useEffect(() => {
    if (activeUserId) {
      dispatch(loadUserSourcesAction({ userId: activeUserId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUserId]);

  const onDelete = useCallback(
    async (source: UserSourcesListInterface) => {
      try {
        if (!activeUserId) {
          return;
        }

        const sourceId = source.id;

        const response = await dispatch(deleteUserSourceAction({ sourceId, userId: activeUserId })).unwrap();

        if (response) {
          dispatch(deleteByIdUserSourceAction(sourceId));
          dispatch(closeConfirmationModalAction());
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUserId],
  );

  const onDeleteModal = useCallback(
    async (source: UserSourcesListInterface) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          description: `Действительно удалить доступ к подключению «${source.name}»?`,
          onConfirm: () => onDelete(source),
          titleText: 'Удаление доступа',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  const onCloseEditUserSourceModal = () => dispatch(closeModalAction(editUserSource));

  const onEditModal = useCallback(
    (source: UserSourcesListInterface) =>
      dispatch(
        openModalTypedAction({
          Component: SourceModal,
          componentProps: {
            onClose: onCloseEditUserSourceModal,
            data: source,
            mode: 'edit',
          },
          modalSettings: {
            headerText: 'Изменение прав доступа',
            width: '320px',
          },
          name: editUserSource,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseEditUserSourceModal],
  );

  const onCloseAddUserSourceModal = () => dispatch(closeModalAction(addUserSource));

  const onAddModal = useCallback(
    async () => {
      try {
        if (!activeUserId) {
          return;
        }

        const noUserSources = await dispatch(loadNoUserSourcesAction({ userId: activeUserId })).unwrap();
        const selectData = noUserSources.map((source) => ({ title: source.name, value: source.id }));

        dispatch(
          openModalTypedAction({
            Component: SourceModal,
            componentProps: {
              onClose: onCloseAddUserSourceModal,
              selectData,
              mode: 'add',
            },
            modalSettings: {
              headerText: 'Добаление доступа',
            },
            name: addUserSource,
          }),
        );
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUserId, onCloseAddUserSourceModal],
  );

  const columnHelper = createColumnHelper<UserSourcesListInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Источник',
        cell: (info) => (
          <TableCell
            text={info.getValue()}
            actions={{
              ...(!info.row.original.groupName && {
                onRemove: async () => {
                  await onDeleteModal(info.row.original);
                },
              }),
            }}
          />
        ),
      }),
      columnHelper.accessor('sourceGroupType', {
        header: 'Права',
        cell: (info) => {
          const rowData = info.row.original;

          return (
            <TableCell
              text={accessText[rowData.sourceGroupType]}
              actions={{
                ...(!info.row.original.groupName && {
                  onEdit: async () => {
                    await onEditModal(rowData);
                  },
                }),
              }}
            />
          );
        },
      }),
      columnHelper.accessor('groupName', {
        header: 'Тип доступа/группа',
        cell: (info) => <TableCell Icon={info.getValue() ? GroupSvg : UserSvg} text={info.getValue() || 'Личный'} />,
      }),
    ],
    [columnHelper, onDeleteModal, onEditModal],
  );

  return (
    <FlexContainer position="relative" width="100%" padding="24px" maxWidth="800px">
      <TableWithSearch
        filterColumnName="name"
        columns={columns}
        rows={userSourcesList}
        onAddModal={onAddModal}
        loading={loading}
      />
    </FlexContainer>
  );
};
