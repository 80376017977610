import { backgroundLineAndBarValueNameTitle, colorValueNameTitle } from 'constants/global';
import { PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { ColorBySettings } from 'modules/settingsContainer/common/ColorBySettings';
import { DefaultDataSettings } from 'modules/settingsContainer/common/data/DefaultDataSettings';
import { EmptyValuesElementSettings } from 'modules/settingsContainer/common/data/EmptyValuesElementSettings';
import { FormattingSettings } from 'modules/settingsContainer/common/data/FormattingSettings';
import { GroupByDateSettings } from 'modules/settingsContainer/common/data/GroupByDateSettings';
import { LimitSettings } from 'modules/settingsContainer/common/data/LimitSettings';
import { MetricSettings } from 'modules/settingsContainer/common/data/MetricSettings';
import { FieldSettingsRenderType, MetricsRenderType } from 'modules/settingsContainer/common/data/MetricSettings/types';
import { NameFromDatabaseSettings } from 'modules/settingsContainer/common/data/NameFromDatabaseSettings';
import { PieFormatValueSettings } from 'modules/settingsContainer/common/data/PieFormatValueSettings';
import { PieLabelFormatSettings } from 'modules/settingsContainer/common/data/PieLabelFormatSettings';
import { PieStyleSettings } from 'modules/settingsContainer/common/data/PieStyleSettings';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';
import { SqlSettings } from 'modules/settingsContainer/common/data/SqlSettings';
import { TypeOfPieSettings } from 'modules/settingsContainer/common/data/TypeOfPieSettings';
import { FictionalMetricField } from 'modules/settingsContainer/common/FictionalMetricField';
import { IndicatorMetricField } from 'modules/settingsContainer/common/IndicatorMetricField';
import { ModelSelectorSettings } from 'modules/settingsContainer/common/ModelSelectorSettings';
import { SettingsFieldEntry } from 'modules/settingsContainer/SettingsFieldEntry';
import { settingsLayoutWidthSecondLevel } from 'modules/settingsContainer/SettingsLayout/constants';
import {
  onAddNewIncision,
  onAddNewIndicator,
  onChangeEmptyValue,
  onChangeFictionalData,
  onChangeIncisionCustomRequest,
  onChangeIncisionFieldName,
  onChangeIncisionName,
  onChangeIncisionNameFromDataBase,
  onChangeIndicatorCustomRequest,
  onChangeIndicatorFormatting,
  onChangeIndicatorName,
  onDeleteIncision,
  onDeleteIndicator,
  onLimitChange,
  onModelIdChange,
  onSqlSettingsSave,
} from 'modules/visualisations/common/onChangeFunctions';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import {
  onChangeIncisionDateSettings,
  onChangeIndicatorLabelFormat,
  onChangeIndicatorLabelProperties,
  onChangeIndicatorStyledSettings,
  onChangeIndicatorValueFormat,
  onChangeRoseType,
  onColorBySettingsChange,
  onColorByValueSettingsChange,
  onColorIncisionChange,
  onMoveIncision,
} from 'modules/visualisations/Pie/settings/DataTab/constants';
import React from 'react';
import {
  defaultPieDataSettings,
  defaultSqlDataSettings,
  getVisualisationFieldName,
} from 'store/reducers/visualisations/constants';
import { PieIncisionInterface, PieIndicatorInterface } from 'store/reducers/visualisations/types';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import { getIncisionAndIndicatorsCompletions } from 'utils/sqlSettings';

export const DataTab = () => {
  const { dataSettings, codeEditorData, astOfVisualisation } = useGetActiveVisualisationSettings({
    defaultData: defaultPieDataSettings,
    defaultSqlData: defaultSqlDataSettings,
  });

  const { isRealData, activeIncisionId } = dataSettings;

  const { tableFields, controls, onMetricClick, onSetupClick, setupIsOpen, metricIsSelected, modelMetaData } =
    useDataSettingsMetric(dataSettings.modelId);

  const incisionRender: MetricsRenderType<PieIncisionInterface> = ({ metrics }) => (
    <>
      {metrics.map(({ fieldName, name, fictionalData, id, colors, settings: { nameFromDatabase, customRequest } }) => {
        const onUpClick = () => onMoveIncision(dataSettings, id, 'up');
        const onDownClick = () => onMoveIncision(dataSettings, id, 'down');
        const onChangeColors = (colors: PaletteValuesByThemeType | null) => onColorIncisionChange(dataSettings, colors, id);

        return (
          <SettingsFieldEntry
            setupIsOpen={setupIsOpen(id)}
            onSetupClick={onSetupClick(id)}
            onClick={onMetricClick(id)}
            isSelected={metricIsSelected(id)}
            isActive={id === activeIncisionId}
            fieldValue={getVisualisationFieldName({ nameFromDatabase, fieldName, name })}
            canChangeField={!nameFromDatabase}
            onFieldChange={(name) => onChangeIncisionName(dataSettings, name, id)}
            onDownClick={onDownClick}
            onUpClick={onUpClick}
            onChangeColors={onChangeColors}
            colorsValue={colors}
            colorPickerType="palette"
            key={id}
          >
            <FictionalMetricField
              onCustomRequestChange={(customRequest) => onChangeIncisionCustomRequest(dataSettings, customRequest, id)}
              customRequest={customRequest || ''}
              disabled={!!customRequest}
              isRealData={isRealData}
              options={tableFields}
              value={{ fictionalData, fieldName }}
              onChange={({ fictionalData, fieldName }) => {
                fieldName && onChangeIncisionFieldName(dataSettings, fieldName || '', id);
                fictionalData && onChangeFictionalData(dataSettings, fictionalData, id);
              }}
              modelMetaData={modelMetaData}
            />
          </SettingsFieldEntry>
        );
      })}
    </>
  );

  const incisionFieldSettingsRender: FieldSettingsRenderType<PieIncisionInterface> = ({ metric: incision }) => {
    const {
      id,
      settings: {
        emptyValues: { isEmptyValue, value },
        nameFromDatabase,
        groupByDateSettings,
      },
    } = incision;

    return (
      <>
        <EmptyValuesElementSettings
          switcherValue={isEmptyValue}
          value={value}
          onChange={(value) => onChangeEmptyValue(dataSettings, value, id)}
        />
        <NameFromDatabaseSettings
          value={nameFromDatabase}
          onChange={(nameFromDatabase: boolean) => onChangeIncisionNameFromDataBase(dataSettings, nameFromDatabase, id)}
        />
        <GroupByDateSettings
          onChange={(value) => onChangeIncisionDateSettings(dataSettings, value, id)}
          value={groupByDateSettings}
        />
      </>
    );
  };

  const indicatorRender: MetricsRenderType<PieIndicatorInterface> = ({ metrics }) => (
    <>
      {metrics.map(({ id, fieldName, name, operationType, customRequest, settings: { nameFromDatabase } }) => {
        return (
          <SettingsFieldEntry
            setupIsOpen={setupIsOpen(id)}
            onSetupClick={onSetupClick(id)}
            onClick={onMetricClick(id)}
            isSelected={metricIsSelected(id)}
            fieldValue={getVisualisationFieldName({ name, nameFromDatabase, fieldName })}
            canChangeField={!nameFromDatabase}
            onFieldChange={(name) => onChangeIndicatorName(dataSettings, name, id)}
            disableColorPicker
            disableChangePriory
            key={id}
          >
            <IndicatorMetricField
              options={tableFields}
              dataSettings={dataSettings}
              id={id}
              isRealData={isRealData}
              fieldName={fieldName}
              operationType={operationType}
              onCustomRequestChange={(customRequest) => onChangeIndicatorCustomRequest(dataSettings, customRequest, id)}
              customRequest={customRequest}
              modelMetaData={modelMetaData}
            />
          </SettingsFieldEntry>
        );
      })}
    </>
  );

  const indicatorFieldSettingsRender: FieldSettingsRenderType<PieIndicatorInterface> = ({ metric: indicator }) => {
    const {
      id,
      settings: { formatting, styleSettings, labelFormat, valueFormat, properties },
    } = indicator;

    return (
      <>
        <PieStyleSettings value={styleSettings} onChange={(value) => onChangeIndicatorStyledSettings(dataSettings, value, id)} />
        <PieLabelFormatSettings value={labelFormat} onChange={(value) => onChangeIndicatorLabelFormat(dataSettings, value, id)} />
        <PropertiesSettings
          rightPositionModal={settingsLayoutWidthSecondLevel}
          value={properties}
          indicators={dataSettings.indicators}
          onChange={(value) => onChangeIndicatorLabelProperties(dataSettings, value, id)}
          isMainContainerColorSettings
          disabledOpacity
          disabledPadding
          disabledLineHeight
          disabledFontColorByBlock
          disabledBackgroundColorBy
          disabledUnderline
          disabledLetterSpacing
        />
        <PieFormatValueSettings value={valueFormat} onChange={(value) => onChangeIndicatorValueFormat(dataSettings, value, id)} />
        <FormattingSettings value={formatting} onChange={(value) => onChangeIndicatorFormatting(dataSettings, value, id)} />
      </>
    );
  };

  return (
    <>
      <DefaultDataSettings dataSettings={dataSettings} />
      <TypeOfPieSettings value={dataSettings.roseType} onChange={(value) => onChangeRoseType(value)} />
      <ModelSelectorSettings value={dataSettings.modelId} onChange={onModelIdChange} />
      <MetricSettings
        titleText="Разрезы"
        addButtonText="Добавить разрез"
        fieldSettingsRender={incisionFieldSettingsRender}
        metricRender={incisionRender}
        metrics={dataSettings.incisions}
        onAdd={() => onAddNewIncision(dataSettings, 'pie')}
        onDelete={(id) => id && onDeleteIncision(dataSettings, id)}
        controls={controls}
      />
      <MetricSettings
        titleText="Показатели"
        addButtonText="Добавить показатель"
        fieldSettingsRender={indicatorFieldSettingsRender}
        metricRender={indicatorRender}
        metrics={dataSettings.indicators}
        onAdd={() => onAddNewIndicator(dataSettings, 'pie')}
        onDelete={(id) => id && onDeleteIndicator(dataSettings, id)}
        disableAddingMetric={dataSettings.indicators.length === 1}
        controls={controls}
      />
      <ColorBySettings
        title={backgroundLineAndBarValueNameTitle}
        indicators={dataSettings.indicators}
        value={dataSettings.colorBySettings}
        onChange={onColorBySettingsChange}
      />
      <ColorBySettings
        title={colorValueNameTitle}
        indicators={dataSettings.indicators}
        value={dataSettings.colorByValueSettings}
        onChange={onColorByValueSettingsChange}
      />
      <SqlSettings
        astData={astOfVisualisation}
        sqlData={codeEditorData}
        adviceEditorIncision={getIncisionAndIndicatorsCompletions(dataSettings.incisions)}
        adviceEditorIndicator={getIncisionAndIndicatorsCompletions(dataSettings.indicators)}
        onSave={(sqlSettingsChanges) =>
          onSqlSettingsSave(dataSettings, sqlSettingsChanges, 'pie', {
            incision: { minValue: 1 },
            indicator: { minValue: 1, maxValue: 1 },
          })
        }
        modelMetaData={modelMetaData}
      />
      <LimitSettings value={dataSettings.limit} onChange={onLimitChange} />
    </>
  );
};
