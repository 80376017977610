import { ScatterSeriesOption } from 'echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import {
  onDataVisualMapSelectedMinAndMaxSettingsChange,
  onDataZoomHorizontalStartAndEndSettingsChange,
  onDataZoomVerticalStartAndEndSettingsChange,
} from 'modules/visualisations/Bubble/settings/ViewTab/constants';
import {
  calculateMinMaxValue,
  defaultGridDimension,
  getAxisMinAndMaxValue,
  getBarGraphGridDimensions,
  getBubbleData,
  getLegendConfigWithGridDimensions,
  onActiveIncisionIdChange,
} from 'modules/visualisations/Bubble/visualisation/constants';
import {
  BubbleEChartsOption,
  FormatterDataParams,
  SeriesDataType,
  TooltipFormatterCallback,
  TooltipFormatterParams,
} from 'modules/visualisations/Bubble/visualisation/types';
import {
  getIncisionAxisConfigWithGridDimensions,
  getPositionLabel,
  onActiveNextIncisionIdChange,
  onDeleteFiltersForDrillDownVisualisation,
} from 'modules/visualisations/common/constants';
import { SingleIncisionLayout } from 'modules/visualisations/components/SingleIncisionLayout';
import { WrappedReactECharts } from 'modules/visualisations/components/WrappedReactECharts';
import { useManualResize } from 'modules/visualisations/hooks/manualResize';
import { useDataVisualMapSelected } from 'modules/visualisations/hooks/useDataVisualMapSelected';
import { useDataZoom } from 'modules/visualisations/hooks/useDataZoom';
import { useVisualisation } from 'modules/visualisations/hooks/visualisation';
import { VisualisationOriginInterface } from 'modules/workspace/components/VisualisationArea/types';
import { memo, useCallback, useMemo } from 'react';
import { getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import { BarValuePositionType, BubbleVisualisationType, VisualisationValuesInterface } from 'store/reducers/visualisations/types';
import { DefaultAxisInterface, GridDimensionsInterface, ItemValueColor } from 'types/echarts';
import { TopAndBottomType } from 'types/styles';
import { calculateGridDimension, initialDimensions } from 'utils/generateConfigGraphic';
import { createLinearScale, isPositiveNumber } from 'utils/utils';

export const BubbleVisualisationComponent: VisualisationOriginInterface<BubbleVisualisationType> = ({ data, sqlRequest }) => {
  const {
    id,
    dataSettings,
    viewSettings: {
      isVisible,
      dataZoomVerticalStartAndEnd,
      dataVisualMapSelectedMinAndMax,
      dataZoomHorizontalStartAndEnd,
      description,
      header,
      axisXIncisionSettings,
      axisYIncisionSettings,
      horizontalZoom,
      verticalZoom,
      showTips,
      visualMapSettings,
      visualisationPaddings: {
        isShow: visualisationPaddingsIsShow,
        paddingsVisualisation: { topPadding, rightPadding, leftPadding, bottomPadding },
      },
      shadowSettings,
      incisionSelectorPosition,
    },
    sqlData,
    events,
  } = data;

  const {
    shadowColorSettings: { shadowColorBy },
  } = shadowSettings;

  const [indicatorX, indicatorY, indicatorSize] = dataSettings.indicators,
    indicatorSizeSettings = indicatorSize?.settings.sizeSettings,
    { gradientBackgroundByValueSettings, gradientColorByValueSettings, activeIncisionId, isDrillDown } = dataSettings;

  const {
    visualisationNormalizedValues,
    getColorByValue,
    isColorByCondition,
    getVisualisationColorsAndImagesData,
    updateFilter,
    formattingParams: { formatting },
    eChartRef,
    enabledFilters,
  } = useVisualisation({
    sqlData,
    id,
    dataSettings,
    colorsBy: [gradientBackgroundByValueSettings, gradientColorByValueSettings, shadowColorBy],
    events,
    limit: dataSettings.limit,
    sqlRequest,
  });

  const incision = dataSettings.incisions.find((incision) => incision.id === activeIncisionId) || dataSettings.incisions[0];

  const {
    name: nameXAxis,
    fieldName: fieldNameXAxis,
    settings: { nameFromDatabase: nameFromDatabaseXAxis, minAndMax: minAndMaxXAxis },
  } = indicatorX;

  const {
    name: nameYAxis,
    fieldName: fieldNameYAxis,
    settings: { nameFromDatabase: nameFromDatabaseYAxis, minAndMax: minAndMaxYAxis },
  } = indicatorY;

  const {
    name: nameSize,
    fieldName: fieldNameSize,
    settings: { nameFromDatabase: nameFromDatabaseSize },
  } = indicatorSize;

  const {
    name: nameIncision,
    fieldName: fieldNameIncision,
    settings: {
      nameFromDatabase: nameFromDatabaseIncision,
      showValue: { properties, isShow: isShowIncisionsValue },
    },
  } = incision;

  const indicatorXAxisName = getVisualisationFieldName({
    name: nameXAxis,
    fieldName: fieldNameXAxis,
    nameFromDatabase: nameFromDatabaseXAxis,
  });

  const indicatorSizeName = getVisualisationFieldName({
    name: nameSize,
    fieldName: fieldNameSize,
    nameFromDatabase: nameFromDatabaseSize,
  });

  const indicatorYAxisName = getVisualisationFieldName({
    name: nameYAxis,
    fieldName: fieldNameYAxis,
    nameFromDatabase: nameFromDatabaseYAxis,
  });

  const indicatorsNames = useMemo(
    () => [indicatorXAxisName, indicatorYAxisName, indicatorSizeName],
    [indicatorSizeName, indicatorXAxisName, indicatorYAxisName],
  );

  const incisionAxisName = getVisualisationFieldName({
    name: nameIncision,
    fieldName: fieldNameIncision,
    nameFromDatabase: nameFromDatabaseIncision,
  });

  const { getColorValues, activeThemeSchema, defaultColor } = useColorValues();

  const colorsGradient = useMemo(
    () => gradientBackgroundByValueSettings.byValueGradient.colors || {},
    [gradientBackgroundByValueSettings.byValueGradient.colors],
  );
  const colorsSteps = useMemo(
    () => gradientBackgroundByValueSettings.byValueSteps.colors || {},
    [gradientBackgroundByValueSettings.byValueSteps.colors],
  );

  const colorsData = useMemo(() => {
    if (!['default', 'condition'].includes(gradientBackgroundByValueSettings.type)) {
      const colors = gradientBackgroundByValueSettings.type === 'valueSteps' ? colorsSteps : colorsGradient;

      return colors[dataSettings.rangeIndicatorId]?.map((color) => getColorValues(color.value))?.reverse() || [defaultColor];
    }
  }, [
    gradientBackgroundByValueSettings.type,
    colorsSteps,
    colorsGradient,
    dataSettings.rangeIndicatorId,
    defaultColor,
    getColorValues,
  ]);

  const bubbleData = useMemo(
    () => getBubbleData({ indicators: dataSettings.indicators }, visualisationNormalizedValues, incision),
    [incision, dataSettings.indicators, visualisationNormalizedValues],
  );

  useManualResize({ eChartRef: eChartRef.current, deps: [description, header, dataSettings.incisions.length] });

  const [minIndicatorSize, maxIndicatorSize] = useMemo(
    () => bubbleData?.minAndMaxIndicatorSize[indicatorSizeName] || [],
    [bubbleData?.minAndMaxIndicatorSize, indicatorSizeName],
  );

  const { min: minSizeSettingsParameters, max: maxSizeSettingsParameters } = useMemo(
    () => indicatorSizeSettings?.parameters.minAndMax || {},
    [indicatorSizeSettings?.parameters.minAndMax],
  );

  const getResultSymbolSize = useMemo(
    () => createLinearScale([minIndicatorSize, maxIndicatorSize], [minSizeSettingsParameters, maxSizeSettingsParameters]),
    [minIndicatorSize, maxIndicatorSize, maxSizeSettingsParameters, minSizeSettingsParameters],
  );

  const indexIndicator = useMemo(
    () => dataSettings.indicators.findIndex((item) => item.id === dataSettings.rangeIndicatorId),
    [dataSettings.indicators, dataSettings.rangeIndicatorId],
  );

  const getValueColor = useCallback(
    (alias: string) => (params: ItemValueColor) => {
      const { data, dataIndex, seriesName } = params;

      let value: string | number | null | undefined = data as number;

      if (isColorByCondition(alias)) {
        value = getVisualisationColorsAndImagesData(alias)[dataIndex];
      }

      return getColorByValue({ value, indicatorName: seriesName || '', alias });
    },
    [getColorByValue, isColorByCondition, getVisualisationColorsAndImagesData],
  );

  const aliasColorByValue = gradientColorByValueSettings.byCondition.alias,
    aliasBackgroundByValue = gradientBackgroundByValueSettings.byCondition.alias;

  const getItemBackgroundColor = getValueColor(aliasBackgroundByValue),
    getValueByColor = getValueColor(aliasColorByValue);

  const bubbleDataResult = useCallback(
    <T extends BarValuePositionType & TopAndBottomType>(seriesName: string, position: T, isHorizontalOrientation: boolean) =>
      (bubbleData?.data[indicatorXAxisName] as SeriesDataType)?.map((value, index) => {
        const indicatorsData = value,
          [xAxis, yAxis, size, name] = indicatorsData,
          isDefaultTypeColor = gradientColorByValueSettings.type === 'default',
          itemValueColor = !isDefaultTypeColor
            ? getValueByColor({
                data: indicatorsData[indexIndicator],
                dataIndex: index,
                seriesName: indicatorsNames[indexIndicator],
              })
            : undefined;

        const color = itemValueColor || getColorValues(properties?.fontColor) || activeThemeSchema[ColorVarsEnum.Level_1];

        const symbolSize =
          indicatorSizeSettings?.type === 'byIndicator'
            ? getResultSymbolSize(Number(size))
            : indicatorSizeSettings?.parameters.diameter;

        return {
          value: value ? [xAxis, yAxis, size] : [],
          symbolSize,
          label: {
            ...getPositionLabel(isPositiveNumber(Number(value)), isHorizontalOrientation)[position],
            color,
          },
          name,
        };
      }),
    [
      bubbleData?.data,
      indicatorXAxisName,
      gradientColorByValueSettings.type,
      getValueByColor,
      indexIndicator,
      indicatorsNames,
      getColorValues,
      properties?.fontColor,
      activeThemeSchema,
      indicatorSizeSettings?.type,
      indicatorSizeSettings?.parameters.diameter,
      getResultSymbolSize,
    ],
  );

  const incisionShowValue = incision.settings.showValue;

  const series = useMemo<ScatterSeriesOption>(() => {
    return {
      name: indicatorXAxisName,
      data: bubbleDataResult(
        incisionAxisName,
        incisionShowValue.position as BarValuePositionType & TopAndBottomType,
        incisionShowValue.orientation === 'horizontal',
      ),
      type: 'scatter',
      label: {
        fontSize: properties?.fontSize,
        fontWeight: properties?.fontStyle.bold ? 'bold' : 'normal',
        fontStyle: properties?.fontStyle.italic ? 'italic' : 'normal',
        backgroundColor: 'transparent',
        show: isShowIncisionsValue,
        rotate: incision.settings.showValue.orientation === 'horizontal' ? 0 : 90,
        formatter: (params) => {
          const value = params.data as { name: string };
          if (value !== null) {
            return value.name;
          }
        },
      },
      itemStyle: {
        color: getItemBackgroundColor,
      },
    } as ScatterSeriesOption;
  }, [
    indicatorXAxisName,
    bubbleDataResult,
    incisionAxisName,
    incisionShowValue.position,
    incisionShowValue.orientation,
    properties?.fontSize,
    properties?.fontStyle.bold,
    properties?.fontStyle.italic,
    isShowIncisionsValue,
    incision.settings.showValue.orientation,
    getItemBackgroundColor,
  ]);

  const formattingXAxisFunction = formatting[nameXAxis];

  const xAxisValue = useMemo(
    () =>
      getIncisionAxisConfigWithGridDimensions({
        minAndMax: minAndMaxXAxis,
        axisIncisionSettings: axisXIncisionSettings,
        fieldName: indicatorXAxisName,
        activeThemeSchema,
        disabledTypeCategory: true,
        formatting: formattingXAxisFunction,
      }),
    [activeThemeSchema, axisXIncisionSettings, formattingXAxisFunction, indicatorXAxisName, minAndMaxXAxis],
  );

  const formattingYAxisFunction = formatting[nameYAxis];

  const yAxisValue = useMemo(
    () =>
      getIncisionAxisConfigWithGridDimensions({
        minAndMax: minAndMaxYAxis,
        axisIncisionSettings: axisYIncisionSettings,
        fieldName: indicatorYAxisName,
        activeThemeSchema,
        formatting: formattingYAxisFunction,
        disabledTypeCategory: true,
      }),
    [activeThemeSchema, axisYIncisionSettings, formattingYAxisFunction, indicatorYAxisName, minAndMaxYAxis],
  );

  const axisValues = useMemo(
    () => ({ xAxis: xAxisValue.config, yAxis: yAxisValue.config }),
    [xAxisValue.config, yAxisValue.config],
  );

  const maxIndicatorValue = bubbleData?.maxIndicatorSum[dataSettings.rangeIndicatorId];

  const minAndMaxLegendValue = useMemo(() => {
    const { min: minXAxis, max: maxXAxis, isShow: isShowXAxis } = minAndMaxXAxis;
    const { min: minYAxis, max: maxYAxis, isShow: isShowYAxis } = minAndMaxYAxis;

    const minXAxisValue = getAxisMinAndMaxValue(isShowXAxis, minXAxis);
    const minYAxisValue = getAxisMinAndMaxValue(isShowYAxis, minYAxis);
    const maxXAxisValue = getAxisMinAndMaxValue(isShowXAxis, maxXAxis);
    const maxYAxisValue = getAxisMinAndMaxValue(isShowYAxis, maxYAxis);

    const minResult = calculateMinMaxValue(minXAxisValue, minYAxisValue, 'min'),
      maxResult = calculateMinMaxValue(maxXAxisValue, maxYAxisValue, 'max');

    const maxValue = isShowXAxis || isShowYAxis ? maxResult : maxIndicatorValue || 0;
    const minValue = isShowXAxis || isShowYAxis ? minResult : 0;

    return { min: minValue, max: maxValue };
  }, [minAndMaxXAxis, maxIndicatorValue, minAndMaxYAxis]);

  const legendValue = useMemo(
    () =>
      getLegendConfigWithGridDimensions({
        startAndEnd: dataVisualMapSelectedMinAndMax,
        typeLegend: gradientBackgroundByValueSettings.type,
        maxAndMinValue: { maxValue: minAndMaxLegendValue.max, minValue: minAndMaxLegendValue.min },
        colors: [...((colorsData || [getColorValues(incision.colors) || defaultColor]) as string[])],
        visualMapSettings,
        defaultColor,
        dimension: dataSettings.indicators.findIndex((item) => item.id === dataSettings.rangeIndicatorId),
      }),
    [
      dataVisualMapSelectedMinAndMax,
      gradientBackgroundByValueSettings.type,
      minAndMaxLegendValue.max,
      minAndMaxLegendValue.min,
      colorsData,
      getColorValues,
      incision.colors,
      defaultColor,
      visualMapSettings,
      dataSettings.indicators,
      dataSettings.rangeIndicatorId,
    ],
  );

  const { onDataZoom, zoomValues } = useDataZoom({
    data: bubbleData.data as VisualisationValuesInterface,
    legendSettings: visualMapSettings,
    horizontalZoom,
    verticalZoom,
    dataZoomHorizontalStartAndEnd,
    dataZoomVerticalStartAndEnd,
    onDataZoomHorizontalStartAndEndSettingsChange,
    onDataZoomVerticalStartAndEndSettingsChange,
  });

  const { onDataVisualMapSelected } = useDataVisualMapSelected({
    dataVisualMapSelectedMinAndMax,
    onDataVisualMapSelectedMinAndMaxSettingsChange,
  });

  const { horizontalSliderXZoomValue, horizontalInsideYZoomValue, verticalInsideXZoomValue, verticalSliderYZoomValue } =
    zoomValues;

  const barGraphGridDimensions = useMemo<GridDimensionsInterface>(
    () => getBarGraphGridDimensions(dataSettings.incisions),
    [dataSettings.incisions],
  );

  const lineGraphGridDimensions = useMemo<GridDimensionsInterface>(() => {
    let isActiveGrid = false;

    dataSettings.incisions.forEach(
      ({
        settings: {
          showValue: { isShow, position },
        },
      }) => {
        if (!isActiveGrid) {
          isActiveGrid = isShow && (position as TopAndBottomType) === 'top';
        }
      },
    );

    const shiftPosition = 'top';

    return isActiveGrid ? { ...initialDimensions, [shiftPosition]: 20 } : initialDimensions;
  }, [dataSettings.incisions]);

  const gridValue = useMemo<BubbleEChartsOption['grid']>(
    () =>
      calculateGridDimension([
        defaultGridDimension,
        legendValue.gridDimensions,
        horizontalInsideYZoomValue.gridDimensions,
        verticalInsideXZoomValue.gridDimensions,
        initialDimensions,
        lineGraphGridDimensions,
        barGraphGridDimensions,
        xAxisValue.gridDimensions,
        yAxisValue.gridDimensions,
      ]),
    [
      legendValue.gridDimensions,
      horizontalInsideYZoomValue.gridDimensions,
      verticalInsideXZoomValue.gridDimensions,
      lineGraphGridDimensions,
      barGraphGridDimensions,
      xAxisValue.gridDimensions,
      yAxisValue.gridDimensions,
    ],
  );

  const tooltipFormatter: TooltipFormatterCallback = useMemo(
    () => (params: TooltipFormatterParams) => {
      if (Array.isArray(params)) {
        return params[0].seriesName || '';
      }

      const data = params.data as FormatterDataParams;

      const indicatorX = data.value[0];
      const indicatorY = data.value[1];
      const indicatorSize = data.value[2];
      const incisionValue = data.name;
      const indicatorMarker = params.marker;
      const stylesHTML = (text: number | string) => `<span style="font-weight:bold">${text}</span>`;
      const indicatorYFormatting = formattingYAxisFunction && formatting ? formattingYAxisFunction(indicatorY) : indicatorY;
      const indicatorXFormatting = formattingXAxisFunction && formatting ? formattingXAxisFunction(indicatorX) : indicatorX;

      return `${indicatorMarker + incisionAxisName}  ${stylesHTML(incisionValue)}<br>
              ${indicatorXAxisName} ${stylesHTML(indicatorXFormatting)}<br>
              ${indicatorYAxisName}  ${stylesHTML(indicatorYFormatting)}<br>
              ${indicatorSizeName}  ${stylesHTML(indicatorSize)}`;
    },
    [
      formatting,
      formattingXAxisFunction,
      formattingYAxisFunction,
      incisionAxisName,
      indicatorSizeName,
      indicatorXAxisName,
      indicatorYAxisName,
    ],
  );

  const tooltipValue = useMemo<BubbleEChartsOption['tooltip']>(
    () => ({
      show: showTips,
      formatter: tooltipFormatter,
      axisPointer: {
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
        crossStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
    }),
    [showTips, tooltipFormatter, activeThemeSchema],
  );

  const option = useMemo<BubbleEChartsOption>(
    () => ({
      textStyle: {
        color: defaultColor,
      },
      series: series as BubbleEChartsOption['series'],
      visualMap: gradientBackgroundByValueSettings.type !== 'condition' ? legendValue.config : undefined,
      tooltip: tooltipValue,
      grid: visualisationPaddingsIsShow
        ? {
            top: topPadding,
            bottom: bottomPadding,
            left: leftPadding,
            right: rightPadding,
          }
        : gridValue,
      dataZoom: [
        horizontalSliderXZoomValue.config,
        verticalSliderYZoomValue.config,
        horizontalInsideYZoomValue.config,
        verticalInsideXZoomValue.config,
      ],
      ...(axisValues as DefaultAxisInterface),
    }),
    [
      defaultColor,
      series,
      gradientBackgroundByValueSettings.type,
      legendValue.config,
      tooltipValue,
      visualisationPaddingsIsShow,
      topPadding,
      bottomPadding,
      leftPadding,
      rightPadding,
      gridValue,
      horizontalSliderXZoomValue.config,
      verticalSliderYZoomValue.config,
      horizontalInsideYZoomValue.config,
      verticalInsideXZoomValue.config,
      axisValues,
    ],
  );

  const onChartClick = useCallback(
    (params: CallbackDataParams) => {
      onActiveNextIncisionIdChange({
        id,
        dataSettings,
        events,
        activeIncisionId: dataSettings.activeIncisionId,
        onChange: onActiveIncisionIdChange,
      });
      updateFilter({ selectedValue: params.name, fieldName: fieldNameIncision });
    },
    [dataSettings, events, fieldNameIncision, id, updateFilter],
  );

  const onChangeActiveIncision = (activeIncisionId: string | null) => {
    if (enabledFilters && dataSettings.activeIncisionId && activeIncisionId) {
      onDeleteFiltersForDrillDownVisualisation({
        dataSettings,
        enabledFilters,
        lastActiveIncisionId: dataSettings.activeIncisionId,
        nextActiveIncisionId: activeIncisionId,
      });
    }

    activeIncisionId && onActiveIncisionIdChange({ activeIncisionId, id });
  };

  const onEvents = {
    dataZoom: onDataZoom,
    click: onChartClick,
    datarangeselected: onDataVisualMapSelected,
  };

  return (
    <SingleIncisionLayout
      incisions={dataSettings.incisions}
      value={dataSettings.activeIncisionId}
      incisionSelectorPosition={incisionSelectorPosition}
      isDrillDown={!!isDrillDown}
      isVisible={isVisible}
      onChange={onChangeActiveIncision}
    >
      <WrappedReactECharts
        onEvents={onEvents}
        notMerge
        ref={(e) => {
          eChartRef.current = e?.getEchartsInstance();
        }}
        style={{ width: '100%', height: '100%' }}
        option={option}
      />
    </SingleIncisionLayout>
  );
};

export const BubbleVisualisation = memo(BubbleVisualisationComponent) as VisualisationOriginInterface<BubbleVisualisationType>;
