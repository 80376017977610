import React, { memo, useMemo } from 'react';
import { NoopType, ReactChildrenType } from 'types/global';
import {
  ButtonContainer,
  SidebarBodyDropDown,
  SidebarContainer,
  SidebarHeaderDropDown,
} from 'modules/settingsContainer/SideBarWrapper/styles';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { FlexContainer } from 'styles/FlexContainer';
import { Button } from 'modules/ui';
import { AddIcon } from 'assets/icons/withContainer';
import { closedSidebarWidth, openedSidebarWidth } from 'modules/settingsContainer/SideBarWrapper/constants';
import { ArrowIcon } from 'modules/ui/icons/ArrowIcon/styles';

type SidebarHeaderProps = Omit<SidebarWrapperProps, 'onOpenAddSource' | 'children'>;

const SidebarHeaderComponent = ({
  isShowSidebar,
  onOpenSidebar,
  description,
  secondTitle,
  title = 'Подключения',
}: SidebarHeaderProps) => {
  const paddingTitle = useMemo(() => `0  ${isShowSidebar ? '16px' : 0} 0 0`, [isShowSidebar]);
  const marginBottom = useMemo(() => (isShowSidebar ? '0' : '16px'), [isShowSidebar]);

  return (
    <SidebarHeaderDropDown isShowSidebar={isShowSidebar} onClick={onOpenSidebar}>
      <FlexContainer>
        <PrimaryTextParagraph
          padding={paddingTitle}
          fontSize="14px"
          lineHeight="14px"
          color={`var(${ColorVarsEnum.Level_1})`}
          fontWeight={400}
          marginBottom={marginBottom}
        >
          {title}
        </PrimaryTextParagraph>
        {secondTitle && isShowSidebar && (
          <PrimaryTextParagraph fontSize="14px" lineHeight="14px" color={`var(${ColorVarsEnum.Level_2})`} fontWeight={400}>
            {secondTitle}
          </PrimaryTextParagraph>
        )}
      </FlexContainer>
      <FlexContainer gap="8px" alignItems="center">
        <ArrowIcon isActive={isShowSidebar} />
        {description && (
          <PrimaryTextParagraph fontSize="12px" lineHeight="12px" color={`var(${ColorVarsEnum.Level_1})`}>
            {description}
          </PrimaryTextParagraph>
        )}
      </FlexContainer>
    </SidebarHeaderDropDown>
  );
};

export const SidebarHeader = memo(SidebarHeaderComponent);

interface SidebarWrapperProps {
  isShowSidebar: boolean;
  children: ReactChildrenType;
  description?: string;
  title?: string;
  buttonTitle?: string;
  onOpenSidebar?: NoopType;
  height?: string;
  secondTitle?: string;
  buttonMinHeight?: string;
  onClick?: NoopType;
  marginBottom?: string;
  backgroundColorSidebar?: string;
  disabledButton?: boolean;
}

export const SidebarWrapperComponent = ({
  isShowSidebar,
  onOpenSidebar,
  description,
  children,
  height,
  buttonTitle,
  secondTitle,
  buttonMinHeight,
  title,
  marginBottom = '70px',
  onClick,
  backgroundColorSidebar,
  disabledButton = false,
}: SidebarWrapperProps) => {
  const width = useMemo(() => (isShowSidebar ? openedSidebarWidth : closedSidebarWidth), [isShowSidebar]);

  return (
    <SidebarContainer width={width} height={height} backgroundColor={backgroundColorSidebar}>
      <FlexContainer marginBottom={marginBottom} flexDirection="column" height="100%">
        {title && (
          <SidebarHeader
            title={title}
            secondTitle={secondTitle}
            description={description}
            isShowSidebar={isShowSidebar}
            onOpenSidebar={onOpenSidebar}
          />
        )}
        {isShowSidebar && (
          <SidebarBodyDropDown>
            <FlexContainer display={isShowSidebar ? 'flex' : 'none'} flexDirection="column" height="100%">
              {children}
            </FlexContainer>
          </SidebarBodyDropDown>
        )}
      </FlexContainer>
      {onClick && isShowSidebar && !disabledButton && (
        <ButtonContainer
          position="absolute"
          bottom="0"
          width="299px"
          justifyContent="center"
          alignItems="center"
          minHeight={buttonMinHeight || '70px'}
        >
          <Button width="auto" text={buttonTitle || 'Создать подключение'} leftIcon={<AddIcon />} onClick={onClick} />
        </ButtonContainer>
      )}
    </SidebarContainer>
  );
};

export const SidebarWrapper = memo(SidebarWrapperComponent);
