import { useCallback, useState } from 'react';

export const useModalState = (defaultOpenState?: boolean) => {
  const [isOpen, setIsOpen] = useState(defaultOpenState || false);

  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);
  const toggleOpen = useCallback(() => setIsOpen((state) => !state), []);

  return { onOpen, onClose, toggleOpen, isOpen };
};
