import React, { FC } from 'react';
import { IconProps } from './types';

export const VerticalCenter: FC<IconProps> = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.3335 12.416H18.6668" stroke={isActive ? '#26A5ED' : '#9DA1AF'} strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M12 4.5L12 9.5M12 9.5L14.5 7.35714M12 9.5L9.5 7.35714"
        stroke={isActive ? '#26A5ED' : '#9DA1AF'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 20.334L12 15.334M12 15.334L9.5 17.4768M12 15.334L14.5 17.4768"
        stroke={isActive ? '#26A5ED' : '#9DA1AF'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
