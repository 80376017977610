import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { OnValueChange } from 'types/global';

export interface GeneralSettingsSwitchProps extends OnValueChange<boolean> {
  titleText: string;
}

export const GeneralSettingsSwitch = ({ value, titleText, onChange }: GeneralSettingsSwitchProps) => (
  <MainContainerSettings titleText={titleText} switcherState={value} switcherChange={() => onChange(!value)} />
);
