import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ModalComponentType } from 'store/reducers/modals/types';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useOpenSelector } from 'modules/ui/Select';
import { Table } from 'components/console/elements/SourcesConnection/DataSelectionModal/Table';
import { useAppDispatch } from 'store';
import {
  createTablesScriptAction,
  loadFieldsPreviewTablesAction,
  loadInformationTableSourceAction,
  loadSQLScriptAction,
  loadTablesAction,
} from 'store/reducers/loadingScript/actions';
import { unwrapResult } from '@reduxjs/toolkit';
import { SourceTablesInterface } from 'store/reducers/loadingScript/types';
import { ListWithSearch } from 'modules/settingsContainer/SearchList/ListWithSearch';
import { ListItem } from 'modules/ui/lists/MapList/item/ListItem';
import { SidebarWrapper } from 'modules/settingsContainer/SideBarWrapper';
import { SelectedItemInterface } from 'modules/ui/lists/MapList/list/types';
import {
  FieldsPreviewTablesListType,
  SelectedTableNameAndSchemaType,
} from 'components/console/elements/SourcesConnection/DataSelectionModal/types';
import { Button } from 'modules/ui';
import { loadSourcesAction } from 'store/reducers/sources/actions';
import { useSelector } from 'react-redux';
import { getSourcesActiveLoading } from 'store/reducers/sources/getters';

interface SourceEditModalProps {
  id: string;
  name: string;
  projectId: string;
}

export const DataSelectionModal: ModalComponentType<SourceEditModalProps> = ({ id: sourceId, projectId, name, onClose }) => {
  const dispatch = useAppDispatch();
  const { open, toggleOpen } = useOpenSelector();
  const sourcesActiveLoadingData = useSelector(getSourcesActiveLoading);

  const isSourceLoading = useMemo(
    () => !sourcesActiveLoadingData.find((item) => sourceId === item.sourceId),
    [sourceId, sourcesActiveLoadingData],
  );

  const [fieldsPreviewTablesList, setFieldsPreviewTablesList] = useState<FieldsPreviewTablesListType>({});
  const [sourceTables, setSourceTables] = useState<SourceTablesInterface[]>([]);
  const [selectedTable, setSelectedTable] = useState<SelectedItemInterface[]>([]);
  const [selectedTableNameAndSchema, setSelectedTableNameAndSchema] = useState<SelectedTableNameAndSchemaType>({});
  const [rowCountTable, setRowCountTable] = useState<number>(0);

  const [loadingSourceTables, setLoadingSourceTables] = useState(true);
  const [loadingTableInfo, setLoadingTableInfo] = useState(false);
  const [loadingCreateTable, setLoadingCreateTable] = useState(false);

  const tableSelectedColumn = fieldsPreviewTablesList[selectedTableNameAndSchema?.selectedTableName || ''];

  const getSourceTablesData = useCallback(async () => {
    setLoadingSourceTables(true);
    try {
      const actionResult = await dispatch(loadTablesAction(sourceId));

      const unwrappedResult = unwrapResult(actionResult);

      setSourceTables(unwrappedResult);
      setLoadingSourceTables(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSourceTables(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId]);

  useEffect(() => {
    if (isSourceLoading && sourceTables.length === 0) {
      void getSourceTablesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSourceLoading]);

  const loadTablePreview = useCallback(
    async ({ tableName, schema }: { tableName: string; schema: string }) => {
      setLoadingTableInfo(true);

      setSelectedTableNameAndSchema({ selectedTableName: tableName, selectedSchema: schema });

      let updatedList = { ...fieldsPreviewTablesList };

      const informationTable = await dispatch(
        loadInformationTableSourceAction({ tableName: tableName, sourceId, schemaName: schema || undefined }),
      ).unwrap();

      setRowCountTable(informationTable);

      try {
        if (!fieldsPreviewTablesList[tableName]) {
          const fieldsPreviewTablesData = await dispatch(
            loadFieldsPreviewTablesAction({ tableName, sourceId: sourceId, schemaName: schema || undefined }),
          );

          const unwrappedResult = unwrapResult(fieldsPreviewTablesData);
          updatedList = {
            ...fieldsPreviewTablesList,
            [tableName]: { ...unwrappedResult },
          };
          setFieldsPreviewTablesList(updatedList);
        }

        return updatedList;
      } catch (error) {
        console.error('Error loading table preview:', error);
        return null;
      } finally {
        setLoadingTableInfo(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldsPreviewTablesList, sourceId],
  );
  const [prevSelectedItems, setPrevSelectedItems] = useState<SelectedItemInterface[]>([]);

  const onCheckedItem = useCallback(
    async (selectedItems: SelectedItemInterface[]) => {
      /*Finding deleted elements*/
      const removedItems = prevSelectedItems.filter(
        (prevItem) => !selectedItems.some((currItem) => currItem.listId === prevItem.listId),
      );

      if (removedItems.length > 0) {
        setSelectedTable((prevSelectedItems) =>
          prevSelectedItems.filter((item) => !removedItems.some((removedItem) => removedItem.listId === item.listId)),
        );
      }

      /*Finding added elements*/
      const addedItems = selectedItems.filter(
        (currItem) => !prevSelectedItems.some((prevItem) => currItem.listId === prevItem.listId),
      );

      for (const addedItem of addedItems) {
        const { listId: selectedTableName, groupId: schemaName } = addedItem;
        let previewTables;

        if (fieldsPreviewTablesList[selectedTableName]) {
          previewTables = fieldsPreviewTablesList[selectedTableName];
        } else {
          try {
            const fieldsPreviewTables = await loadTablePreview({ tableName: selectedTableName, schema: schemaName });
            if (fieldsPreviewTables && fieldsPreviewTables[selectedTableName]) {
              setFieldsPreviewTablesList((prev) => ({
                ...prev,
                [selectedTableName]: fieldsPreviewTables[selectedTableName],
              }));
              previewTables = fieldsPreviewTables[selectedTableName];
            }
          } catch (error) {
            console.error('Error loading table preview:', error);
            continue;
          }
        }

        if (previewTables) {
          const allColumnsData = previewTables.meta.map((field) => field.name) || [];

          setSelectedTable((prevSelectedItems) => {
            const newItem = {
              groupId: schemaName || '',
              listId: selectedTableName,
              columns: allColumnsData,
            };
            return [...prevSelectedItems, newItem];
          });
        }
      }

      setPrevSelectedItems(selectedItems);
    },
    [prevSelectedItems, setFieldsPreviewTablesList, setSelectedTable, fieldsPreviewTablesList, loadTablePreview],
  );

  const onCreateSelectedTable = useCallback(async () => {
    try {
      setLoadingCreateTable(true);
      const actionRes = await dispatch(
        createTablesScriptAction({
          sourceId,
          projectId,
          tables: selectedTable.map(({ listId, groupId, columns }) => ({
            table: listId,
            columns: columns || [],
            schema: groupId === listId ? undefined : groupId,
          })),
        }),
      ).unwrap();
      if (actionRes) {
        setLoadingCreateTable(false);
        onClose();

        dispatch(loadSourcesAction(projectId));
        dispatch(loadSQLScriptAction(projectId));
      }
    } catch (e) {
      setLoadingCreateTable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId, projectId, selectedTable]);

  const [statusCheck, setStatusCheck] = useState<'primary' | 'secondary' | undefined>();

  return (
    <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" height="100%">
      <LoadingOverlay loading={loadingCreateTable} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />

      <FlexContainer
        width="100%"
        justifyContent="center"
        padding="16px 0"
        borderBottom={`1px solid var(${ColorVarsEnum.Level_5})`}
      >
        <PrimaryTextParagraph fontWeight={700} lineHeight="24px" fontSize="20px" color={`var(${ColorVarsEnum.Level_1})`}>
          {name}
        </PrimaryTextParagraph>
      </FlexContainer>
      <FlexContainer>
        <FlexContainer height="80vh" position="relative">
          <LoadingOverlay loading={loadingSourceTables} />

          <SidebarWrapper
            height="100%"
            title="Таблицы"
            isShowSidebar={!open}
            onOpenSidebar={toggleOpen}
            backgroundColorSidebar={`var(${ColorVarsEnum.Level_3_menu})`}
          >
            {sourceTables.length >= 1 && (
              <ListWithSearch
                modelMetaData={sourceTables || []}
                onSelectItem={(selectedItem) => void onCheckedItem(selectedItem)}
                onChecked={(checkedItem) => {
                  if (checkedItem.listId !== selectedTableNameAndSchema.selectedTableName) {
                    void loadTablePreview({ tableName: checkedItem.listId, schema: checkedItem.groupId });
                  }
                }}
                selectedOptions={selectedTable.map(({ listId, groupId }) => ({
                  listId,
                  groupId,
                }))}
                renderItem={({ item, key, isSelected, onSelectChange, onChecked }) => (
                  <ListItem
                    key={key}
                    id={item.id}
                    activeSelected={item.title === selectedTableNameAndSchema?.selectedTableName}
                    isCheckboxLast
                    title={item.title}
                    disabled={item.disabled}
                    onChecked={onChecked}
                    onChange={onSelectChange}
                    isChecked={isSelected}
                    checkType={statusCheck}
                  />
                )}
              />
            )}
          </SidebarWrapper>
        </FlexContainer>
        <FlexContainer flexDirection="column" justifyContent="space-between">
          <Table
            setColumnTableSelected={setSelectedTable}
            rowCountTable={rowCountTable}
            columnTableSelected={selectedTable}
            nameTableAndSchema={selectedTableNameAndSchema}
            data={tableSelectedColumn}
            loading={loadingTableInfo}
            onPrevTableSelected={(selectedTable) =>
              setPrevSelectedItems((prev) => (selectedTable ? [...prev, selectedTable] : []))
            }
            onStatusCheck={setStatusCheck}
          />

          <FlexContainer width="100%" justifyContent="flex-end">
            <FlexContainer gap="10px" margin="24px">
              <Button
                text="Вставить в скрипт"
                iconSize="normal"
                heightSize="normal"
                needBackground={true}
                disabled={loadingCreateTable || selectedTable.length === 0}
                onClick={onCreateSelectedTable}
              />
              <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={true} onClick={onClose} />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
