import { FormControlLabel, Radio } from '@mui/material';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { RadioProps } from '@mui/material/Radio/Radio';
import { StyledFormControlLabelProps } from 'shared/ui/RadioButton/types';
import styled from 'styled-components';

export const StyledFormControlLabel = styled(FormControlLabel)<StyledFormControlLabelProps>`
  &.MuiFormControlLabel-root {
    margin: 0;
  }

  .MuiFormControlLabel-label {
    color: ${({ checked }) => (checked ? `var(${ColorVarsEnum.Accent})` : `var(${ColorVarsEnum.Level_1})`)};
  }
`;

export const StyledRadio = styled(Radio)<RadioProps>`
  &.MuiRadio-root {
    margin-right: 8px;
    padding: 0;
    color: var(${ColorVarsEnum.Level_3});
  }

  &.Mui-checked {
    color: var(${ColorVarsEnum.Accent}) !important;
  }
`;
