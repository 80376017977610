import { createSelector } from 'reselect';
import { getState } from 'store/utils';

export const getHistoryStore = createSelector(getState, (state) => state.history);

export const getUndoLengthStore = createSelector(getHistoryStore, (state) => state.undo.length);

export const getRedoLengthStore = createSelector(getHistoryStore, (state) => state.redo.length);

export const getHasUndoHistory = createSelector(getUndoLengthStore, (state) => state >= 0);

export const getHasRedoHistory = createSelector(getRedoLengthStore, (state) => state >= 0);

export const getIsAnotherPage = createSelector(getHistoryStore, (state) => state.isAnotherPage);
