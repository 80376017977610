import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FilterInfluenceIconProps } from '../fontStyle/types';
import { FilterInfluenceIconWrapper, WrapperIconEnum } from './FilterInfluenceIconWrapper';
import ColorTheme from 'constants/ColorTheme';

export const ApplyIcon = ({ size = 20, isActive, onClick, type = WrapperIconEnum.NOWRAP }: FilterInfluenceIconProps) => (
  <>
    {type === WrapperIconEnum.WRAP ? (
      <FilterInfluenceIconWrapper onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" style={{ cursor: 'pointer' }}>
          <path
            stroke={isActive ? ColorTheme.default.accent : `var(${ColorVarsEnum.Level_2})`}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M8.412 17.5V9.47L2.5 2.5h15l-5.294 6.97v6.353L8.412 17.5Z"
          />
        </svg>
      </FilterInfluenceIconWrapper>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        width={size}
        height={size}
        fill="none"
        style={{ cursor: 'pointer' }}
      >
        <path
          stroke={isActive ? ColorTheme.default.accent : `var(${ColorVarsEnum.Level_2})`}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M8.412 17.5V9.47L2.5 2.5h15l-5.294 6.97v6.353L8.412 17.5Z"
        />
      </svg>
    )}
  </>
);
