import React, { useCallback, useEffect, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCell } from 'modules/ui/Table/TableCell';
import {
  closeConfirmationModalAction,
  closeModalAction,
  openConfirmationModalAction,
  openModalTypedAction,
} from 'store/reducers/modals/actions';
import { useAppDispatch } from 'store';
import { EditAccessModal } from './EditAccessModal';
import { AddAccessModal } from './AddAccessModal';
import { getActiveFlowId, getFlowAccesses, getFlowDraftInfoId } from 'store/reducers/projectManager/getters';
import {
  deleteByIdFlowAccessAction,
  deleteGroupFlowAccessTypeAction,
  deleteUserFlowAccessTypeAction,
  loadFlowAccessAction,
} from 'store/reducers/projectManager/actions';
import { TableWithSearch } from 'modules/ui/TableWithSearch';
import { FlexContainer } from 'styles/FlexContainer';
import { accessAdd, accessEdit } from './constants';
import { accessIcons, accessText } from './constants';
import { useSelector } from 'react-redux';
import { FlowAccessInterface } from 'store/reducers/projectManager/types';

export const Access = () => {
  const dispatch = useAppDispatch();
  const { id: activeFlowId } = useSelector(getActiveFlowId);
  const draftId = useSelector(getFlowDraftInfoId);
  const { loading, flowAccessList } = useSelector(getFlowAccesses);

  useEffect(() => {
    if (activeFlowId !== draftId) {
      dispatch(loadFlowAccessAction(activeFlowId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFlowId, draftId]);

  const onCloseEditAccessModal = () => dispatch(closeModalAction(accessEdit));
  const onCloseAddAccessModal = () => dispatch(closeModalAction(accessAdd));

  const onDelete = useCallback(
    async (flowAccess: FlowAccessInterface) => {
      try {
        const flowAccessId = flowAccess.id;

        const response =
          flowAccess.entity === 'group'
            ? await dispatch(deleteGroupFlowAccessTypeAction({ flowId: activeFlowId, groupId: flowAccessId })).unwrap()
            : await dispatch(deleteUserFlowAccessTypeAction({ flowId: activeFlowId, userId: flowAccessId })).unwrap();

        if (response) {
          dispatch(deleteByIdFlowAccessAction(flowAccessId));
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      } finally {
        dispatch(closeConfirmationModalAction());
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFlowId],
  );

  const onDeleteModal = useCallback(
    async (flowAccess: FlowAccessInterface) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          description: `Действительно удалить доступ «${flowAccess.name}» к группе?`,
          onConfirm: () => onDelete(flowAccess),
          titleText: 'Удаление доступа',
        }),
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  const onEditModal = useCallback(
    (flowAccess: FlowAccessInterface) =>
      dispatch(
        openModalTypedAction({
          Component: EditAccessModal,
          componentProps: {
            onClose: onCloseEditAccessModal,
            flowAccess,
          },
          modalSettings: {
            headerText: 'Изменение прав доступа',
            width: '320px',
          },
          name: accessEdit,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseEditAccessModal],
  );

  const onAddModal = useCallback(
    () =>
      dispatch(
        openModalTypedAction({
          Component: AddAccessModal,
          componentProps: {
            onClose: onCloseAddAccessModal,
          },
          modalSettings: {
            headerText: 'Добавление доступа',
          },
          name: accessAdd,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseAddAccessModal],
  );

  const columnHelper = createColumnHelper<FlowAccessInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Группа/пользователь',
        cell: (info) => {
          const rowData = info.row.original;

          return (
            <TableCell
              text={info.getValue()}
              Icon={accessIcons[rowData.entity]}
              actions={{
                onRemove: async () => {
                  await onDeleteModal(rowData);
                },
              }}
            />
          );
        },
      }),
      columnHelper.accessor('type', {
        header: 'Права',
        cell: (info) => (
          <TableCell
            text={accessText[info.getValue()]}
            actions={{
              onEdit: async () => {
                await onEditModal(info.row.original);
              },
            }}
          />
        ),
      }),
    ],
    [columnHelper, onDeleteModal, onEditModal],
  );

  return (
    <FlexContainer position="relative" width="100%" padding="24px" maxWidth="800px">
      <TableWithSearch columns={columns} rows={flowAccessList} onAddModal={onAddModal} loading={loading} />
    </FlexContainer>
  );
};
