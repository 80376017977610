import React from 'react';
import { OnValueChange } from 'types/global';
import { BackgroundImagesSettingsInterface } from 'store/reducers/visualisations/types';
import { backgroundNameTitle } from 'constants/global';
import { ImageBySettings } from 'modules/settingsContainer/common/ImageBySettings';

type TextBackgroundImageSettingsProps = OnValueChange<BackgroundImagesSettingsInterface>;

export const TextBackgroundImagesSettings = ({ value: backgroundImagesSettings, onChange }: TextBackgroundImageSettingsProps) => {
  const { imagesSettings } = backgroundImagesSettings;

  return (
    <ImageBySettings
      title={backgroundNameTitle}
      value={backgroundImagesSettings.imagesSettings.backgroundImagesBy}
      onChange={(backgroundImagesBy) =>
        onChange({ ...backgroundImagesSettings, imagesSettings: { ...imagesSettings, backgroundImagesBy } })
      }
    />
  );
};
