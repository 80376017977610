import { ConfirmationModalInterface, ModalsStateInterface, WizardModalInterface } from 'store/reducers/modals/types';

export const defaultWizardModal: WizardModalInterface = {
  isOpen: false,
  stepDataList: {},
  onClose: () => {},
  firstStepComponent: '',
  defaultTitle: '',
};

export const defaultConfirmationModal: ConfirmationModalInterface = {
  isOpen: false,
  isRenamable: false,
  valueInput: '',
  titleText: '',
  confirmationButtonText: '',
  description: '',
  subTitleText: '',
  onConfirm: () => {},
  maxHeight: '',
  width: '',
  cancelButtonText: '',
  onCancel: () => {},
};

export const initialModalsStoreState: ModalsStateInterface = {
  modals: [],
  confirmationModal: defaultConfirmationModal,
  wizardModal: defaultWizardModal,
};
