import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const FilterDateDefault: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fill = `var(${ColorVarsEnum.Accent})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <path
          opacity="0.15"
          d="M58 25V50C58 54.4183 54.4183 58 50 58H20C15.5817 58 12 54.4183 12 50V25"
          stroke={fillDefault}
          strokeWidth="4"
        />
        <path opacity="0.15" d="M10 20C10 15.5817 13.5817 12 18 12H52C56.4183 12 60 15.5817 60 20V25H10V20Z" fill={fillDefault} />
        <rect x="21" y="9" width="4" height="8" fill={fillDefault} />
        <rect opacity="0.75" x="21" y="28" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="29" y="28" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="37" y="28" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="45" y="28" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="21" y="35" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="29" y="35" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="37" y="35" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="45" y="35" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="21" y="42" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="29" y="42" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="37" y="42" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="45" y="42" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="21" y="49" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="29" y="49" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="37" y="49" width="4" height="4" fill={fillDefault} />
        <rect opacity="0.75" x="45" y="49" width="4" height="4" fill={fillDefault} />
        <rect x="29" y="35" width="20" height="4" fill={fill} />
        <rect x="21" y="42" width="20" height="4" fill={fill} />
        <rect x="45" y="9" width="4" height="8" fill={fillDefault} />
      </svg>
    </FlexContainer>
  );
};
