import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadGroupAccessUsersAction, loadGroupFlowsAction, loadGroupsAction, loadGroupSourcesAction } from './actions';
import { setSliceFn } from 'constants/store';
import { initialAdminGroupsStoreState } from './constants';
import { GroupFlowsListInterface, GroupsListInterface, GroupSourcesListInterface } from './types';
import { IdInterface } from 'types/store';

export const adminGroupsSlice = createSlice({
  name: 'adminGroupsSlice',
  initialState: initialAdminGroupsStoreState,
  reducers: {
    setSlice: setSliceFn,
    addGroup: (state, { payload: data }: PayloadAction<GroupsListInterface>) => {
      state.groups.groupsList = [...state.groups.groupsList, data];
    },
    changeActiveGroup: (state, { payload }: PayloadAction<GroupsListInterface>) => {
      state.activeGroup = payload;
    },
    deleteByIdGroup: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.groups.groupsList = state.groups.groupsList.filter((page) => page.id !== id);
    },
    updateGroups: (state, { payload: groups }: PayloadAction<GroupsListInterface[]>) => {
      state.groups.groupsList = groups;
    },

    deleteByIdUsersAccess: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.groupAccessUsers.groupAccessUsersList = state.groupAccessUsers.groupAccessUsersList.filter((page) => page.id !== id);
    },

    updateFlows: (state, { payload: flows }: PayloadAction<GroupFlowsListInterface[]>) => {
      state.groupFlows.groupFlowsList = flows;
    },
    deleteByIdFlow: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.groupFlows.groupFlowsList = state.groupFlows.groupFlowsList.filter((page) => page.id !== id);
    },

    updateSources: (state, { payload: sources }: PayloadAction<GroupSourcesListInterface[]>) => {
      state.groupSources.groupSourcesList = sources;
    },
    deleteByIdSource: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.groupSources.groupSourcesList = state.groupSources.groupSourcesList.filter((page) => page.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadGroupsAction.pending, (state) => {
      state.groups = { ...state.groups, loading: true };
      state.groups.groupsList = [];
    });
    builder.addCase(loadGroupsAction.fulfilled, (state, { payload }) => {
      state.groups = { ...state.groups, loading: false };
      state.groups.groupsList = payload;
    });
    builder.addCase(loadGroupsAction.rejected, (state) => {
      state.groups = { ...state.groups, loading: false };
      state.groups.groupsList = [];
    });

    builder.addCase(loadGroupAccessUsersAction.pending, (state) => {
      state.groupAccessUsers = { ...state.groupAccessUsers, loading: true };
      state.groupAccessUsers.groupAccessUsersList = [];
    });
    builder.addCase(loadGroupAccessUsersAction.fulfilled, (state, { payload }) => {
      state.groupAccessUsers = { ...state.groupAccessUsers, loading: false };
      state.groupAccessUsers.groupAccessUsersList = payload;
    });
    builder.addCase(loadGroupAccessUsersAction.rejected, (state) => {
      state.groupAccessUsers = { ...state.groupAccessUsers, loading: false };
      state.groupAccessUsers.groupAccessUsersList = [];
    });

    builder.addCase(loadGroupFlowsAction.pending, (state) => {
      state.groupFlows = { ...state.groupFlows, loading: true };
      state.groupFlows.groupFlowsList = [];
    });
    builder.addCase(loadGroupFlowsAction.fulfilled, (state, { payload }) => {
      state.groupFlows = { ...state.groupFlows, loading: false };
      state.groupFlows.groupFlowsList = payload;
    });
    builder.addCase(loadGroupFlowsAction.rejected, (state) => {
      state.groupFlows = { ...state.groupFlows, loading: false };
      state.groupFlows.groupFlowsList = [];
    });

    builder.addCase(loadGroupSourcesAction.pending, (state) => {
      state.groupSources = { ...state.groupSources, loading: true };
      state.groupSources.groupSourcesList = [];
    });
    builder.addCase(loadGroupSourcesAction.fulfilled, (state, { payload }) => {
      state.groupSources = { ...state.groupSources, loading: false };
      state.groupSources.groupSourcesList = payload;
    });
    builder.addCase(loadGroupSourcesAction.rejected, (state) => {
      state.groupSources = { ...state.groupSources, loading: false };
      state.groupSources.groupSourcesList = [];
    });
  },
});

export const {
  setSlice,
  addGroup,
  changeActiveGroup,
  deleteByIdGroup,
  updateGroups,
  updateFlows,
  deleteByIdFlow,
  deleteByIdSource,
  updateSources,
  deleteByIdUsersAccess,
} = adminGroupsSlice.actions;

export default adminGroupsSlice.reducer;
