import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { PieIndicatorValueFormatType } from 'store/reducers/visualisations/types';

export const pieIndicatorFormatValueOptions: SettingsRadioItem<PieIndicatorValueFormatType>[] = [
  {
    value: 'absolute',
    label: 'Абсолютные',
  },
  {
    value: 'percent',
    label: 'В процентах',
  },
];
