import { ActiveColorInterface } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/types';
import { ActiveColorItemInterface, GroupItemInterface } from 'store/reducers/palettes/types';

export const defaultActiveColor: ActiveColorInterface = { activeColorIds: null, colorIndex: null };

export const isActiveColor = (
  checkedActiveColorIds: ActiveColorItemInterface | null,
  activeColorIds: ActiveColorItemInterface | null,
) => activeColorIds?.colorId === checkedActiveColorIds?.colorId && activeColorIds?.groupId == checkedActiveColorIds?.groupId;

//Функция используется что бы найти количество элементов с одинаковым названием для того что бы задать номер копии
export const findCopyIndex = (names: string[], baseName: string) => {
  const matchingNames = names.filter((name) => {
    const basePattern = new RegExp(`^${baseName} \\(\\d+\\)$`);
    return basePattern.test(name);
  });

  const indices = matchingNames.map((name) => {
    const match = name.match(/\((\d+)\)$/);
    return match ? parseInt(match[1], 10) : 0;
  });

  return indices.length ? Math.max(...indices) + 1 : 1;
};

//Функция используеться для нахождение индекса последнего похожего по названию элемента исключая ...(number).
export const findLastCopyIndex = (groups: GroupItemInterface[], baseName: string) => {
  const lastCopyIndex = groups.reduce((lastIndex, group, index) => {
    const basePattern = new RegExp(`^${baseName} \\(\\d+\\)$`);
    if (basePattern.test(group.name) || group.name === baseName) {
      return index;
    }
    return lastIndex;
  }, -1);

  return lastCopyIndex;
};
