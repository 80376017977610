import { FC } from 'react';
import { actionsList } from 'modules/ui/Table/constants';
import { TableActions, TableActionsIcons } from 'modules/ui/Table/types';
import { IconType } from 'assets/icons/withContainer';
import { ButtonsWrapper, ButtonWrapper, EditSvg, RemoveSvg, TableCellWrapper } from 'modules/ui/Table/TableCell/styles';

interface TableCellProps {
  text: string;
  actions?: TableActions;
  Icon?: IconType;
}

const actionIcons: TableActionsIcons = {
  onEdit: <EditSvg />,
  onRemove: <RemoveSvg />,
};

export const TableCell: FC<TableCellProps> = ({ actions, text, Icon }) => (
  <TableCellWrapper>
    {Icon && <Icon />}
    {text}
    {actions && (
      <ButtonsWrapper>
        {actionsList.map(
          (action) =>
            actions[action] && (
              <ButtonWrapper onClick={actions[action]} key={action}>
                {actionIcons[action]}
              </ButtonWrapper>
            ),
        )}
      </ButtonsWrapper>
    )}
  </TableCellWrapper>
);
