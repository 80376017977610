/* TODO: Make test for All Function */
/* Generate string: */
/* 'data1', 'data2', 'data3' */
/* 'data1' */
import { VisualisationOperationTypesType } from 'store/reducers/visualisations/types';
import { WhereQueryInterface } from 'types/store';

export const generateSqlInValues = (data?: string | string[]) => {
  if (data) {
    const fieldData = typeof data === 'string' ? [data] : data;

    return fieldData.map((value) => `'${value}'`).join(', ');
  }

  return '';
};

/* Generate string: */
/* fieldName IN ('data1', 'data2') */
/* fieldName IN ('data1') */
export const generateSqlWhereInFilter = ({ fieldName, data }: { fieldName: string; data: string | string[] }) => {
  const values = generateSqlInValues(data);

  return `${fieldName} IN (${values})`;
};

/* Generate string: */
/* min(Car.SALESMAN) */
/* count(Car.SALESMAN) */
/* field value */
export const generateSqlWrappedFunction = (fn: VisualisationOperationTypesType, field?: string | null) => {
  if (field && field !== '') {
    const value = fn !== 'other' ? `${fn}(${field})` : field;

    return value.trim();
  }
};

/* Generate string: */
/* Car.BRAND AS "Разрез 0" */
/* count(Car.SALESMAN) AS "Показатель 0" */
export const generateSqlSelectValue = (field?: string | null, fieldName?: string) => {
  if (field && fieldName) {
    return `${field} AS "${fieldName}"`;
  }
};

/* Generate string: */
/* Car.BRAND AS "Разрез 0", Car.CAR_MODEL AS "Разрез 1", count(Car.SALESMAN) AS "Показатель 0", count(Car.BRAND) AS "Показатель 1" */
/* Car.BRAND AS "Разрез 0" */
/* Car.BRAND, Car.SALESMAN, Car.BRAND */
/* Car.BRAND */
export const generateSqlCommaSeparatedString = (values?: Array<string | undefined>) => {
  const filteredValues = values?.filter((value) => value);
  if (filteredValues && filteredValues.length > 0) {
    return filteredValues.join(', ').trim();
  }
};

/* Generate string: */
/* SELECT Car.BRAND AS "Разрез 0", Car.CAR_MODEL AS "Разрез 1", count(Car.SALESMAN) AS "Показатель 0", count(Car.BRAND) AS "Показатель 1" */
/* SELECT Car.BRAND AS "Разрез 0" */
/* GROUP BY  Car.BRAND, Car.SALESMAN, Car.BRAND */
/* GROUP BY  Car.BRAND */
export const generateSqlCommaSeparatedQuery = (query: 'SELECT' | 'GROUP BY', value?: Array<string | undefined> | string) => {
  const commaSeparatedString =
    typeof value === 'string' ? (value !== '' ? value : undefined) : generateSqlCommaSeparatedString(value);
  if (commaSeparatedString) {
    return `${query} ${commaSeparatedString}`;
  }
};

/* Generate string: */
/* SELECT Car.BRAND AS "Разрез 0", Car.CAR_MODEL AS "Разрез 1", count(Car.SALESMAN) AS "Показатель 0", count(Car.BRAND) AS "Показатель 1" */
/* SELECT Car.BRAND AS "Разрез 0" */
export const generateSqlSelectQuery = (selectValues?: Array<string | undefined> | string) =>
  generateSqlCommaSeparatedQuery('SELECT', selectValues);

/* Generate string: */
/* GROUP BY  Car.BRAND, Car.SALESMAN, Car.BRAND */
/* GROUP BY  Car.BRAND */
export const generateSqlGroupByQuery = (groupByValues?: Array<string | undefined> | string) =>
  generateSqlCommaSeparatedQuery('GROUP BY', groupByValues);

/* Generate string: */
/* WHERE fieldName1 IN ('data1', 'data2') AND fieldName2 IN ('data3')  AND fieldName3 IN ('data4') */
export const generateSqlWhereFilter = (filtersString: string[]) => {
  const fullFilterString = filtersString.join(' AND ').trim();

  return filtersString.length ? `WHERE ${fullFilterString}` : '';
};

/* Generate string: */
/* FROM "Car" INNER JOIN "Client" AS "Client" ON  "Client"."id" = "Car"."clientId" WHERE Client IN ('data1', 'data2') */
export const generateSqlFrom = (fromTableName?: string) => {
  if (fromTableName) {
    return `FROM ${fromTableName}`;
  }
};

interface SqlLimitParams {
  from?: number;
  to?: number;
}

/* Generate string: */
/* LIMIT 0, 100 */
export const generateSqlLimit = ({ from = 0, to }: SqlLimitParams) => {
  if (to) {
    return `LIMIT ${from}, ${to}`;
  }
};

export interface FullQueryProps extends WhereQueryInterface {
  selectQuery?: string;
  fromQuery?: string;
  groupByQuery?: string;
  orderByQuery?: string;
  limitQuery?: string;
  unionQueries?: string[];
}

const getPartOfQuery = (part: string) => (part !== '' ? `${part} ` : '');

/* Generate string: */
/* WHERE Client IN ('data1', 'data2') GROUP BY Car.BRAND, Car.CAR_MODEL ORDER BY Car.BRAND ASC, Car.CAR_MODEL ASC LIMIT 0, 1 */
export const generateEndSqlQuery = ({
  whereQuery = '',
  groupByQuery = '',
  orderByQuery = '',
  limitQuery = '',
}: Pick<FullQueryProps, 'whereQuery' | 'groupByQuery' | 'orderByQuery' | 'limitQuery'>) => {
  const filterQuery = getPartOfQuery(whereQuery.trim()),
    groupQuery = getPartOfQuery(groupByQuery.trim()),
    orderQuery = getPartOfQuery(orderByQuery.trim()),
    limitPartQuery = getPartOfQuery(limitQuery.trim());

  return filterQuery + groupQuery + orderQuery + limitPartQuery;
};

/* Generate string: */
/* SELECT Car.BRAND AS "Разрез 0", Car.CAR_MODEL AS "Разрез 1", count(Car.SALESMAN) AS "Показатель 0", count(Car.BRAND) AS "Показатель 1" FROM "Car" INNER JOIN "Client" AS "Client" ON  "Client"."id" = "Car"."clientId" WHERE Client IN ('data1', 'data2') GROUP BY Car.BRAND, Car.CAR_MODEL ORDER BY Car.BRAND ASC, Car.CAR_MODEL ASC LIMIT 0, 1 */
export const generateSqlFullQuery = ({
  cteQuery = '',
  selectQuery = '',
  fromQuery = '',
  whereQuery = '',
  groupByQuery = '',
  orderByQuery = '',
  limitQuery = '',
  unionQueries = [],
}: FullQueryProps & { cteQuery?: string; unionQueries?: string[] }) => {
  const ctePart = getPartOfQuery(cteQuery.trim()); // Обрабатываем CTE часть
  const baseQuery = getPartOfQuery(`${selectQuery.trim()} ${fromQuery.trim()}`);
  const endQuery = generateEndSqlQuery({ whereQuery, groupByQuery, orderByQuery, limitQuery });

  let fullQuery = ctePart + baseQuery + endQuery;

  // Обработка UNION запросов, если они есть
  if (unionQueries.length > 0) {
    fullQuery += ' UNION ALL ' + unionQueries.join(' UNION ALL ');
  }

  return fullQuery;
};

export const getWhereGroupByAndOrderBy = (sqlString: string) => {
  const hasGroupBy = sqlString.indexOf('GROUP BY') !== -1;

  let groupByPart: string | undefined, orderByPart: string | undefined;

  const [wherePart, groupByAndOrderBy] = hasGroupBy ? sqlString.split(/GROUP BY/i) || [] : sqlString.split(/ORDER BY/i) || [];

  if (hasGroupBy) {
    [groupByPart, orderByPart] = groupByAndOrderBy?.split(/ORDER BY/i) || [];
  } else {
    orderByPart = groupByAndOrderBy && groupByAndOrderBy !== '' ? `ORDER BY ${groupByAndOrderBy.trim()}` : undefined;
  }

  const where = wherePart && wherePart.indexOf('WHERE') !== -1 ? wherePart.trim() : undefined;
  const groupBy = groupByPart && groupByPart !== '' ? `GROUP BY ${groupByPart.trim()}` : undefined;
  const orderBy =
    orderByPart && orderByPart !== ''
      ? `${orderByPart.indexOf('ORDER BY') === -1 ? 'ORDER BY ' : ''}${orderByPart.trim()}`
      : undefined;

  return {
    where,
    groupBy,
    orderBy,
  };
};

/* Generate from [string] to [string]: */
/* WHERE Car.CAR_MODEL IN ('Corolla') -> Car.CAR_MODEL IN ('Corolla') */
/* WHERE Car.CAR_MODEL IN ('Corolla') AND Car.CAR_MODEL IN ('Camry') -> Car.CAR_MODEL IN ('Corolla') AND Car.CAR_MODEL IN ('Camry') */
export const getWhereParams = (whereSql?: string) => {
  if (whereSql) {
    return whereSql.replace('WHERE', '').trim();
  }
};

/* Generate string: */
/* WHERE Car.CAR_MODEL IN ('Corolla') */
/* WHERE Car.CAR_MODEL IN ('Corolla') AND Car.CAR_MODEL IN ('Camry') */
export const generateSqlUnionWhere = (whereSqlQuery: Array<string | undefined>) => {
  const filteredValues = whereSqlQuery?.filter((value) => value) as string[],
    whereParams = filteredValues.map((whereSqlQuery) => getWhereParams(whereSqlQuery) as string);

  if (whereParams.length > 0) {
    return generateSqlWhereFilter(whereParams);
  }
};

interface WhereAndGroupSQLProps {
  sqlRequest: string;
  filtersSQL?: string;
}

export const getWhereAndGroupSQL = ({ sqlRequest, filtersSQL }: WhereAndGroupSQLProps) => {
  const [, filterAndGroup] = sqlRequest.split(/WHERE/i),
    [whereData, groupData] = filterAndGroup?.split(/GROUP BY/i) || [],
    whereSql = filtersSQL ? `${filtersSQL}${whereData ? `AND ${whereData}` : ''}` : whereData ? `WHERE ${whereData}` : '',
    groupDataSql = groupData ? `GROUP BY ${groupData}` : '';

  return { whereSql, groupDataSql };
};
