import { createSelector } from 'reselect';
import { defaultAstOfFilterAndGroups } from 'store/reducers/ast/constants';
import { EnabledFilterAstInterface, VisualisationAstInterface } from 'store/reducers/ast/types';
import { getState } from 'store/utils';

export const getAstStore = createSelector(getState, (state) => state.ast);

export const getAstOfVisualisations = createSelector(getAstStore, (state) => state.visualisations);
export const getArrayAstOfVisualisations = createSelector(
  getAstOfVisualisations,
  (astOfVisualisations) => (Object.values(astOfVisualisations) as VisualisationAstInterface[]) || [],
);

export const getAstOfVisualisationById = (id: string) =>
  createSelector(getAstOfVisualisations, (astOfVisualisations) => {
    const {
      incisions = [],
      indicators = [],
      groupBy = [],
      variables = [],
      images = [],
      serviceValues = [],
      limit = null,
      filtersAndGroups = defaultAstOfFilterAndGroups,
      activeIncisionIndex = null,
    } = astOfVisualisations[id] || {};

    return { incisions, indicators, variables, images, groupBy, serviceValues, filtersAndGroups, limit, activeIncisionIndex };
  });

export const getAstOfEnabledFilters = createSelector(getAstStore, (state) => state.enabledFilters);
export const getArrayAstOfEnabledFilters = createSelector(
  getAstOfEnabledFilters,
  (astOfEnabledFilters) => (Object.values(astOfEnabledFilters) as EnabledFilterAstInterface[]) || [],
);

export const getAstOfEnabledFilterById = (id: string) =>
  createSelector(getAstOfEnabledFilters, (astOfEnabledFilters) => astOfEnabledFilters[id]);

export const getAstOfSources = createSelector(getAstStore, (state) => state.sources);

export const getAstOfSourceById = (id: string) => createSelector(getAstOfSources, (astOfSources) => astOfSources[id]);
