import { ModalUniversal } from 'modules/ui/ModalUniversal';
import { SqlField, SqlSection } from '../SqlSettings';
import { NoopType, NoopValueType } from 'types/global';
import { ModelFromMetaType } from 'store/reducers/models/types';

interface FilterSqlModalProps {
  isOpen: boolean;
  onCloseEditor: NoopType;
  localValues: {
    incisionRequest: string;
    filterAndGroupRequest: string;
  };
  setLocalValues: NoopValueType<{
    incisionRequest: string;
    filterAndGroupRequest: string;
  }>;
  currentFullSize: string | null;
  onResizeChange: (value: string) => () => void;
  onFormatClick: NoopType;
  modelMetaData: ModelFromMetaType[];
  onSaveEditor: NoopType;
  hasChanges: boolean;
}

export const FilterSqlModal = ({
  isOpen,
  onCloseEditor,
  localValues,
  setLocalValues,
  currentFullSize,
  onResizeChange,
  onFormatClick,
  modelMetaData,
  onSaveEditor,
  hasChanges,
}: FilterSqlModalProps) => {
  return (
    <ModalUniversal
      open={isOpen}
      onClose={onCloseEditor}
      headerText="Редактор SQL-запроса"
      width="95vw"
      maxHeight="95vh"
      disableEscapeKeyDown
    >
      <SqlSection
        sqlEditorSlot={({ onEditorFocus }) => (
          <>
            <SqlField
              title="Разрезы"
              handleChange={(incisionRequest) => setLocalValues({ ...localValues, incisionRequest })}
              value={localValues?.incisionRequest}
              onFocus={onEditorFocus}
              currentFullSize={currentFullSize}
              onResizeChange={onResizeChange}
            />
            <SqlField
              title="Группировки и фильтры"
              handleChange={(filterAndGroupRequest) => setLocalValues({ ...localValues, filterAndGroupRequest })}
              value={localValues?.filterAndGroupRequest}
              onFocus={onEditorFocus}
              currentFullSize={currentFullSize}
              onResizeChange={onResizeChange}
            />
          </>
        )}
        onFormatClick={onFormatClick}
        modelMetaData={modelMetaData}
        onSaveEditor={onSaveEditor}
        hasChanges={hasChanges}
        onCloseEditor={onCloseEditor}
      />
    </ModalUniversal>
  );
};
