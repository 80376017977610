import { store } from 'store';
import { updateBubbleViewSettingsAction } from 'store/reducers/visualisations/actions';
import {
  AxisSettingsInterface,
  LegendSettingsInterface,
  PaddingVisualisationInterface,
  VisualMapSettingsInterface,
  ZoomSettingsInterface,
} from 'store/reducers/visualisations/types';
import { PositionSettingType, StartAndEndInterface } from 'types/store';

const dispatch = store.dispatch;

export const onXAxisIncisionChange = (axisXIncisionSettings: AxisSettingsInterface) =>
  dispatch(updateBubbleViewSettingsAction({ axisXIncisionSettings }));

export const onYAxisIncisionChange = (axisYIncisionSettings: AxisSettingsInterface) =>
  dispatch(updateBubbleViewSettingsAction({ axisYIncisionSettings }));

export const onLegendSettingsChange = (legendSettings: LegendSettingsInterface) =>
  dispatch(updateBubbleViewSettingsAction({ legendSettings }));

export const onVisualMapSettingsChange = (visualMapSettings: VisualMapSettingsInterface) =>
  dispatch(updateBubbleViewSettingsAction({ visualMapSettings }));

export const onDataZoomHorizontalStartAndEndSettingsChange = (dataZoomHorizontalStartAndEnd: StartAndEndInterface) =>
  dispatch(updateBubbleViewSettingsAction({ dataZoomHorizontalStartAndEnd }));

export const onDataZoomVerticalStartAndEndSettingsChange = (dataZoomVerticalStartAndEnd: StartAndEndInterface) =>
  dispatch(updateBubbleViewSettingsAction({ dataZoomVerticalStartAndEnd }));

export const onDataVisualMapSelectedMinAndMaxSettingsChange = (dataVisualMapSelectedMinAndMax: StartAndEndInterface) =>
  dispatch(updateBubbleViewSettingsAction({ dataVisualMapSelectedMinAndMax }));

export const onShowTipsChange = (showTips: boolean) => dispatch(updateBubbleViewSettingsAction({ showTips }));

export const onIncisionSelectorPositionChange = (incisionSelectorPosition: PositionSettingType) =>
  dispatch(updateBubbleViewSettingsAction({ incisionSelectorPosition }));

export const onChangePaddingVisualisation = (visualisationPaddings: PaddingVisualisationInterface) =>
  dispatch(
    updateBubbleViewSettingsAction({
      visualisationPaddings,
    }),
  );

export const onChangeHorizontalZoomChange = (horizontalZoom: ZoomSettingsInterface) =>
  dispatch(updateBubbleViewSettingsAction({ horizontalZoom }));

export const onChangeVerticalZoomChange = (verticalZoom: ZoomSettingsInterface) =>
  dispatch(updateBubbleViewSettingsAction({ verticalZoom }));
