import React from 'react';
import { PositionSettingType } from 'types/store';
import { useAppDispatch } from 'store';
import { updateViewSettingsAction } from 'store/reducers/visualisations/actions';
import { HorizontalPositionSettings } from 'modules/settingsContainer/common/HorizontalPositionSettings';

interface TitlePositionSettingsProps {
  value: PositionSettingType;
  onChange?: (value: PositionSettingType) => void;
}

export const TitlePositionSettings = ({ value, onChange: onOriginExternalChange }: TitlePositionSettingsProps) => {
  const dispatch = useAppDispatch();

  const onLocalChange = (headerPosition: PositionSettingType) => {
    dispatch(updateViewSettingsAction({ headerPosition }));
  };

  const onExternalChange = onOriginExternalChange
    ? (value: PositionSettingType) => {
        onOriginExternalChange(value);
      }
    : undefined;

  const onChange = onExternalChange || onLocalChange;

  return <HorizontalPositionSettings titleText="Расположение" onChange={onChange} value={value} />;
};
