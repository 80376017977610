import { unwrapResult } from '@reduxjs/toolkit';
import { TaskFormData } from 'components/admin/tasks/Modal/TaskModal/types';
import { RadioSelected } from 'components/RadioSelected';
import { TaskRadio } from 'components/TaskRadio';
import { defaultCronDate } from 'constants/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { createTaskOptions } from 'modules/settingsContainer/common/data/PropertiesSettings/constants';
import { Button, Switcher, TextField } from 'modules/ui';
import { Combobox } from 'modules/ui/Combobox';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Snackbar from 'services/Snackbar';
import { useAppDispatch } from 'store';
import {
  addTaskAction,
  addTaskInfoAction,
  createTaskAction,
  loadAdminFlowsAction,
  loadAdminShortProjectAction,
  updateTaskAction,
  updateTasksAction,
} from 'store/reducers/adminTasks/actions';
import { getAdminTasksData } from 'store/reducers/adminTasks/getters';
import { TaskInterface } from 'store/reducers/adminTasks/types';
import { loadDraftsOfAllUsersAction } from 'store/reducers/adminUsers/actions';
import { ModalComponentType } from 'store/reducers/modals/types';
import { loadShortFlowAction } from 'store/reducers/projectManager/actions';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { NoopValueType } from 'types/global';
import { ModeForm, SelectInterface } from 'types/store';
import { Form } from './styles';

type TaskModalProps = {
  mode: ModeForm;
  dataEdit?: TaskFormData;
  updateSetTaskInfo: NoopValueType<TaskInterface>;
};

export const TaskModal: ModalComponentType<TaskModalProps> = ({ dataEdit, updateSetTaskInfo, mode, onClose }) => {
  const uniqueId = useId();
  const dispatch = useAppDispatch();
  const { tasks } = useSelector(getAdminTasksData);

  const [typeFlow, setTypeFlow] = useState<(typeof createTaskOptions)[0]>(createTaskOptions[0]);
  const [projectsList, setProjectsList] = useState<SelectInterface[]>([]);
  const [flowsList, setFlowsList] = useState<SelectInterface[]>([]);
  const isDraft = useMemo(() => typeFlow.label === 'Черновик', [typeFlow.label]);

  const [loading, setLoading] = useState(false);

  const isAdd = mode === 'add';

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm<TaskFormData>({
    mode: 'onSubmit',
    defaultValues: {
      isActive: dataEdit?.isActive || true,
      cron: dataEdit?.cron || defaultCronDate,
    },
  });

  const projectName = watch('projectId')?.value;

  const getFlowsData = useCallback(async () => {
    try {
      const actionFlowsResponse = await dispatch(loadAdminFlowsAction());

      const unwrappedFlowsResult = unwrapResult(actionFlowsResponse);

      if (unwrappedFlowsResult) {
        const formattedFlowList = unwrappedFlowsResult.map(({ id, name }) => ({ name: id, value: name }));
        setFlowsList(formattedFlowList);

        void (await getProjectsData(formattedFlowList[0].name));
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsersData = useCallback(async () => {
    try {
      const response = await dispatch(loadDraftsOfAllUsersAction());
      const payload = response.payload as FastBoard.API.ApiAdminFlowDTO[];

      if (payload) {
        setFlowsList(
          payload.map(({ id, name }) => {
            return {
              name: id,
              value: name,
            };
          }),
        );
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProjectsData = useCallback(
    async (name: string) => {
      try {
        if (!name) return;

        const actionProjectsResponse = isDraft
          ? await dispatch(loadAdminShortProjectAction(name))
          : await dispatch(loadShortFlowAction(name));
        const unwrappedProjectsResult = unwrapResult(actionProjectsResponse);

        if (unwrappedProjectsResult) {
          const formattedFlowList = unwrappedProjectsResult.map(({ id, title }) => ({ name: id, value: title }));

          const taskIds = new Set(tasks.map((task) => task.id));

          setProjectsList(formattedFlowList.filter((select) => !taskIds.has(select.name)));
        }
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSubmit: SubmitHandler<TaskFormData> = useCallback(
    async ({ cron, isActive, projectId }) => {
      if (!isDirty) {
        return null;
      }

      if (!projectId?.name) {
        return Snackbar.show('Проект не выбран', 'error');
      }

      setLoading(true);

      let actionTaskRes;
      try {
        if (isAdd) {
          actionTaskRes = await dispatch(createTaskAction({ isActive, cron, id: projectId?.name })).unwrap();

          if (actionTaskRes) {
            dispatch(addTaskAction(actionTaskRes));
          }
        }

        if (!isAdd) {
          actionTaskRes = await dispatch(updateTaskAction({ isActive, cron, id: projectId?.name })).unwrap();

          if (actionTaskRes) {
            dispatch(updateTasksAction({ task: actionTaskRes }));
            dispatch(addTaskInfoAction(actionTaskRes));
          }
        }

        if (actionTaskRes) {
          updateSetTaskInfo(actionTaskRes);
        }

        if (!actionTaskRes) {
          setLoading(false);
          return;
        }

        if (onClose) {
          onClose();
        }
      } catch (e) {
        setLoading(false);

        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAdd, isDirty, onClose],
  );

  useEffect(() => {
    if (dataEdit) {
      const { projectId, cron, isActive } = dataEdit;

      setValue('projectId', projectId);
      setValue('cron', cron);
      setValue('isActive', isActive);
    }
  }, [dataEdit, setValue]);

  useEffect(() => {
    !isDraft && void getFlowsData();
    isDraft && void getUsersData();
  }, [isDraft, getFlowsData, getUsersData]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={loading} />
      <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" height="100%" gap="32px" padding="12px">
        {isAdd && (
          <RadioSelected
            onChange={(option) => setTypeFlow(option)}
            activeValue={typeFlow}
            options={createTaskOptions}
            gap="16px"
            width="100%"
          />
        )}
        <FlexContainer width="100%" flexDirection="column" height="100%" gap="32px">
          {isAdd && (
            <Controller
              render={({ field }) => {
                return (
                  <Combobox
                    title={isDraft ? 'Выберите пользователя' : 'Выберите поток'}
                    tooltipDisableHoverListener
                    options={flowsList}
                    width="100%"
                    placeholder={isDraft ? 'Выберите пользователя' : 'Выберите поток'}
                    disabled={!isAdd}
                    value={field.value || (flowsList.length > 0 ? flowsList[0] : null)}
                    name="flowsTasks"
                    onChange={(e) => {
                      void getProjectsData(e?.name);
                      setValue('projectId', undefined);
                      field.onChange(e);
                    }}
                  />
                );
              }}
              name="flowsId"
              control={control}
            />
          )}

          {isAdd && (
            <Controller
              name="projectId"
              control={control}
              render={({ field }) => (
                <Combobox
                  title="Проект"
                  tooltipDisableHoverListener
                  options={projectsList}
                  width="100%"
                  placeholder="Выберите проект"
                  value={field.value || (projectsList.length > 0 ? undefined : null)}
                  onChange={field.onChange}
                  name="taskProject"
                  disabled={!isAdd}
                />
              )}
            />
          )}

          {!isAdd && <TextField name="taskProject" value={projectName} disabled label="Название" width="100%" />}

          <Controller
            name="isActive"
            control={control}
            render={({ field }) => (
              <FlexContainer flexDirection="row" alignItems="center" gap="8px">
                <Switcher id={uniqueId} value={field.value} onClick={field.onChange} />

                <PrimaryTextParagraph lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                  Регулярная синхронизация
                </PrimaryTextParagraph>
              </FlexContainer>
            )}
          />

          <Controller
            name="cron"
            control={control}
            render={({ field }) => <TaskRadio onChange={field.onChange} value={field.value} />}
          />

          <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
            <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={false} onClick={onClose} />
            <Button
              text={isAdd ? 'Создать' : 'Изменить'}
              iconSize="normal"
              needBackground={true}
              heightSize="normal"
              type="submit"
            />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Form>
  );
};
