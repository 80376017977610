import React from 'react';
import { useAppDispatch } from 'store';
import { updateViewSettingsAction } from 'store/reducers/visualisations/actions';
import { BooleanSettingsControlInterface } from 'modules/settingsContainer/common/BooleanSettings/types';
import { BooleanElementSettings } from 'modules/settingsContainer/common/BooleanElementSettings';

type BooleanDraggingSettingsProps = BooleanSettingsControlInterface;

export const BooleanDraggingSettings = ({ value, onChange: onExternalChange }: BooleanDraggingSettingsProps) => {
  const dispatch = useAppDispatch();

  const onLocalChange = (disableDragging: boolean) => {
    dispatch(updateViewSettingsAction({ disableDragging }));
  };

  const onChange = onExternalChange || onLocalChange;

  return <BooleanElementSettings titleText="Отключить перемещение контейнера" value={value} onChange={onChange} />;
};
