import { SingleFilter } from 'modules/filters/Single/view/Filter';
import { MultipleFilter } from 'modules/filters/Multiple/view/Filter';
import { DateFilter } from 'modules/filters/Date/view/Filter';
import { FilterRenderInterface } from 'modules/workspace/components/FilterArea/types';
import { MultipleFilterMainSettings } from 'modules/filters/Multiple/settings/MainSettings';
import { SingleFilterMainSettings } from 'modules/filters/Single/settings/MainSettings';
import { DateFilterMainSettings } from 'modules/filters/Date/settings/MainSettings';

export const FilterRender: FilterRenderInterface = {
  single: { Filter: SingleFilter, MainSettings: SingleFilterMainSettings },
  multiple: { Filter: MultipleFilter, MainSettings: MultipleFilterMainSettings },
  date: { Filter: DateFilter, MainSettings: DateFilterMainSettings },
};
