import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { PieRoseTypeTypes } from 'store/reducers/visualisations/types';

export const pieTitleTypesOptions: SettingsRadioItem<PieRoseTypeTypes>[] = [
  {
    value: 'pie',
    label: 'Пирог',
  },
  {
    value: 'area',
    label: 'Роза',
  },
];
