import {
  FleLocationType,
  MaskMultiFilePatternType,
  SourceDriverType,
} from 'components/console/elements/sideBar/Forms/FileForm/types';

export const driverItems = (isManyFiles?: boolean): Array<{ name: string; value: SourceDriverType | 'auto' }> => {
  const baseDriverItems: Array<{ name: string; value: SourceDriverType | 'auto' }> = [
    { name: 'QVD', value: 'qvd' },
    { name: 'XLSX/XLS', value: 'xlsx' },
    { name: 'CSV', value: 'csv' },
    { name: 'TXT', value: 'txt' },
    { name: 'JSON', value: 'json' },
    { name: 'XML', value: 'xml' },
  ];

  return isManyFiles ? baseDriverItems : [{ name: 'Авто', value: 'auto' }, ...baseDriverItems];
};

export const driverIdMap: Record<SourceDriverType, string> = {
  qvd: '',
  xlsx: '',
  csv: '',
  txt: '',
  json: '',
  xml: '',
};

export const driverIdValidation = Object.keys(driverIdMap) as SourceDriverType[];

export const fileLocationItems: Array<{ name: string; value: FleLocationType }> = [
  { name: 'С сервера', value: 'fromTheServer' },
  { name: 'Загрузить', value: 'fromTheLocal' },
];

export const fileLocationMap: Record<FleLocationType, string> = {
  fromTheServer: '',
  fromTheLocal: '',
};

export const fileLocationValidation = Object.keys(fileLocationMap) as FleLocationType[];

export const textTypes = ['txt', 'csv', 'qvd'];

export const maskMultiFilePatternItems: Array<{ name: string; value: MaskMultiFilePatternType }> = [
  { name: 'Равно', value: '=' },
  { name: 'Не равно', value: '!=' },
  { name: 'Содержит', value: '^' },
  { name: 'Не содержит', value: '!^' },
];

export const fileTypeXlsxMap: { xls: string } = {
  xls: 'xlsx',
};
