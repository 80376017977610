import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';

export const loadVersionStrategy = (): Promise<AxiosResponse<FastBoard.API.SettingsDeleteOldIncomingResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/settings/delete-old-incoming`);

export const updateVersionStrategy = (
  setting: FastBoard.API.SettingsDeleteOldIncomingTypeTimeDTO | FastBoard.API.SettingsDeleteOldIncomingTypeItemsDTO,
) =>
  axiosClient.put<
    string,
    AxiosResponse<FastBoard.API.SettingsDeleteOldIncomingResponseDTO>,
    FastBoard.API.SettingsDeleteOldIncomingUpdateDTO
  >(`/api/v2/admin/settings/delete-old-incoming`, { setting });
