import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';

export const ProtectIconIconWrapper = styled(IconWrapper)``;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CardIconContainer = styled.div`
  display: flex;
  width: 100%;
  height: 120px;
  align-items: center;
  justify-content: center;
`;

export const ProtectIconIconContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 35px;
  width: 35px;
  position: absolute;
  bottom: 0;
  right: 0;

  border-top-left-radius: 100%;
  background-color: var(${ColorVarsEnum.Level_5_application});
`;

type FlowCardIconContainerProps = {
  opacity?: string;
};

export const FlowCardIconContainer = styled.div<FlowCardIconContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${({ opacity }) => opacity};
  height: 120px;
  ${CardIconContainer} {
    svg,
    path,
    circle,
    rect {
      fill: var(${ColorVarsEnum.Level_1});
    }
  }

  @media (max-width: 1024px) {
    width: 97px;
    height: 64px;
  }
`;
