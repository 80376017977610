import styled from 'styled-components';

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  @media (max-width: 1024px) {
    display: none;
  }
`;
