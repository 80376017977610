import styled from 'styled-components';
import { settingsLayoutMixin } from 'modules/settingsContainer/SettingsLayout/styles';

type FieldSettingsAreaLayoutProps = {
  right?: string;
  top?: string;
};

export const FieldSettingsAreaLayout = styled.div<FieldSettingsAreaLayoutProps>`
  position: fixed;
  top: ${({ top }) => top || '0'};
  right: ${({ right }) => right || '0'};
`;

export interface HeaderSettingsWrapperProps {
  width?: string;
  minWidth?: string;
  height?: string;
  maxHeight?: string;
  minHeight?: string;
}

export const HeaderSettingsWrapper = styled.div<HeaderSettingsWrapperProps>`
  ${settingsLayoutMixin};
  width: unset;
  overflow: auto;
  ${({ width }) => width && `width: ${width};`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`};
  ${({ height }) => height && `height: ${height};`};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`};
`;
