import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ArrowTableIcon } from 'assets/icons/table/index';
import { ButtonWrapper } from 'modules/ui/Table/TableCell/styles';

const backgroundStyles = css`
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(${ColorVarsEnum.Level_1});
`;

interface MyTableProps {
  height?: string;
}

export const MyTable = styled.table<MyTableProps>`
  width: 100%;
  height: 100%;
  display: block;
`;

export const THead = styled.thead`
  width: 100%;
  display: block;
  border-bottom: 1px solid var(${ColorVarsEnum.Level_5});
  position: relative;

  :after {
    opacity: 0.05;
    ${backgroundStyles}
  }
`;

export const TBody = styled.tbody`
  display: block;
  height: calc(100% - 32px);
  overflow: auto;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    transform: rotate(90deg);
    top: 20px;
    right: 20px;
    position: absolute;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${`var(${ColorVarsEnum.Level_5})`};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${`var(${ColorVarsEnum.Level_4})`};
  }
`;

interface TrProps {
  height: number;
  isPointer?: boolean;
}

export const Tr = styled.tr<TrProps>`
  width: 100%;
  border-bottom: 1px solid var(${ColorVarsEnum.Level_5});
  min-height: ${({ height }) => height}px;
  display: flex;

  ${({ isPointer }) =>
    isPointer &&
    css`
      cursor: pointer;
    `}
`;

export const TrHead = styled(Tr)``;

interface TrBodyProps {
  isSelected?: boolean;
}

export const TrBody = styled(Tr)<TrBodyProps>`
  position: relative;

  :after {
    opacity: 0;
    ${backgroundStyles}
  }

  :hover:after {
    opacity: 0.05;
  }

  :hover {
    ${ButtonWrapper} {
      display: flex;
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      :after {
        background-color: var(${ColorVarsEnum.Accent});
        opacity: 0.15;
      }
    `}
`;

interface ThProps {
  width: string;
}

export const Th = styled.th`
  padding: 6px 8px;
  color: var(${ColorVarsEnum.Level_1});
`;

export const ThHead = styled(Th)<ThProps>`
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 13px;
  display: block;
`;

interface ThHeadContentProps {
  pointer?: boolean;
}

export const ThHeadContent = styled.div<ThHeadContentProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
`;

export const ThBody = styled(Th)<ThProps>`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(${ColorVarsEnum.Level_1});
`;

interface ArrowSvgProps {
  inverted?: boolean;
}

export const ArrowSvg = styled(ArrowTableIcon)<ArrowSvgProps>`
  height: 10px;
  width: 10px;

  svg,
  path {
    fill: var(${ColorVarsEnum.Level_1});
  }

  ${({ inverted }) =>
    inverted &&
    css`
      transform: rotate(180deg);
    `}
`;
