import React from 'react';
import { useAppDispatch } from 'store';
import { updateViewSettingsAction } from 'store/reducers/visualisations/actions';
import { NoopValueType } from 'types/global';
import { ShadowSettingInterface } from 'types/store';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { pxUnit } from 'constants/global';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { ColorBySettings } from 'modules/settingsContainer/common/ColorBySettings';
import { ColorPickerBlock } from 'modules/settingsContainer/common/view/TextBackgroundSettings';

interface ShadowSettingsProps {
  onChange?: NoopValueType<ShadowSettingInterface>;
  value: ShadowSettingInterface;
  disabledComponentByCondition?: boolean;
}

export const ShadowSettings = ({ value, onChange: onExternalChange, disabledComponentByCondition }: ShadowSettingsProps) => {
  const { isActive, offsetY, offsetX, blurRadius, shadowColorSettings } = value;
  const { shadowColorBy, hover, shadowColor } = shadowColorSettings;
  const dispatch = useAppDispatch();

  const { defaultColor } = useColorValues();

  const onLocalChange = (shadowSettings: ShadowSettingInterface) => {
    dispatch(updateViewSettingsAction({ shadowSettings }));
  };

  const onChange = onExternalChange || onLocalChange;

  return (
    <>
      <GroupContainerSettings
        titleText="Тень"
        switcherState={isActive}
        switcherChange={() => onChange({ ...value, isActive: !isActive })}
      >
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Радиус размытия"
            name="width"
            unit={pxUnit}
            value={blurRadius}
            onChange={(blurRadius) =>
              onChange({
                ...value,
                blurRadius,
              })
            }
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Смещение X"
            name="width"
            unit={pxUnit}
            value={offsetX}
            onChange={(offsetX) =>
              onChange({
                ...value,
                offsetX,
              })
            }
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Смещение Y"
            name="width"
            unit={pxUnit}
            value={offsetY}
            onChange={(offsetY) =>
              onChange({
                ...value,
                offsetY,
              })
            }
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
      <ColorBySettings
        titleMainContainerText="Цвет тени"
        disabledComponentByValue
        disabledComponentByCondition={disabledComponentByCondition}
        isMainContainerSettings
        value={shadowColorBy}
        onChange={(shadowColorBy) => onChange({ ...value, shadowColorSettings: { ...shadowColorSettings, shadowColorBy } })}
      >
        <GroupContainerSettings>
          <ColorPickerBlock
            defaultActivatorBackgroundColor={defaultColor}
            onShowChange={(isShow) =>
              onChange({
                ...value,
                shadowColorSettings: {
                  ...shadowColorSettings,
                  shadowColor: { ...shadowColor, isShow },
                },
              })
            }
            onChangeColors={(color) =>
              onChange({
                ...value,
                shadowColorSettings: {
                  ...shadowColorSettings,
                  shadowColor: { ...shadowColor, color },
                },
              })
            }
            colorsValue={shadowColor.color}
            isShow={shadowColor.isShow}
            description="Цвет заливки"
          />
          <ColorPickerBlock
            defaultActivatorBackgroundColor={defaultColor}
            onShowChange={(isShow) =>
              onChange({
                ...value,
                shadowColorSettings: {
                  ...shadowColorSettings,
                  hover: { ...hover, isShow },
                },
              })
            }
            onChangeColors={(color) =>
              onChange({
                ...value,
                shadowColorSettings: {
                  ...shadowColorSettings,
                  hover: { ...hover, color },
                },
              })
            }
            colorsValue={hover.color}
            isShow={hover.isShow}
            description="Цвет при наведении"
          />
        </GroupContainerSettings>
      </ColorBySettings>
    </>
  );
};
