import { Content, MainLayoutWrapper } from 'components/layouts/MainLayout/styles';
import { FC, ReactFCNoop } from 'types/global';

export interface MainLayoutProps {
  Header: ReactFCNoop;
}

export const MainLayout: FC<MainLayoutProps> = ({ children, Header }) => {
  return (
    <MainLayoutWrapper>
      <Header />
      <Content>{children}</Content>
    </MainLayoutWrapper>
  );
};
