import { totalRowPositionOptions } from 'constants/global';
import { MainContainerSettings } from 'modules/settingsContainer';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import React from 'react';
import { IndentStyledComponent } from 'shared/ui/IndentStyledComponent';
import { TotalRowSettingsInterface } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import SubSection from 'shared/ui/subSection';

type TotalRowSettingsProps = OnValueChange<TotalRowSettingsInterface>;

export const GlobalStylesSettings = ({ value, onChange, title }: TotalRowSettingsProps & { title: string }) => {
  const {
    isShow,
    location: { position, isShow: isShowLocation },
    properties,
    overfill,
    hasIndentation,
    indentation,
  } = value;

  return (
    <MainContainerSettings
      titleText={title}
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
    >
      <SubSection>
        <GroupContainerSettings titleText="Расположение">
          <ElementContainerSettings>
            <SettingsRadio
              onChange={({ value: isTop }) =>
                onChange({
                  ...value,
                  location: { isShow: isShowLocation, position: { ...position, value: isTop } },
                })
              }
              activeValue={getActiveRadioValue(totalRowPositionOptions, position.value)}
              options={totalRowPositionOptions}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      </SubSection>
      <SubSection>
        <PropertiesSettings
          title="Свойства ячейки"
          isMainContainerColorSettings
          isMainContainerSettings
          disabledComponentColorByValue
          disabledFontColorByBlock
          disabledPadding
          value={properties}
          onChange={(properties) => onChange({ ...value, properties })}
          disabledOpacity
        />
      </SubSection>
      <SubSection>
        <GroupContainerSettings
          titleText="Заливка фона поверх столбцов"
          switcherState={overfill}
          switcherChange={() => onChange({ ...value, overfill: !overfill })}
        />
      </SubSection>
      <SubSection>
        <GroupContainerSettings
          titleText="Отступы в ячейках"
          switcherState={hasIndentation}
          switcherChange={() => onChange({ ...value, hasIndentation: !hasIndentation })}
        >
          <IndentStyledComponent
            value={indentation}
            onChange={(indentation) => onChange({ ...value, indentation })}
            disabledHorizontal
          />
        </GroupContainerSettings>
      </SubSection>
    </MainContainerSettings>
  );
};
