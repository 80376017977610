import React from 'react';
import { TextSettingInterface } from 'store/reducers/visualisations/types';
import { updateViewSettingsAction } from 'store/reducers/visualisations/actions';
import { useAppDispatch } from 'store';
import { TextSettingsControlInterface } from 'modules/settingsContainer/common/TextSettingsGroup/types';
import { TextSettingsGroup } from 'modules/settingsContainer/common//TextSettingsGroup';

type TitleSettingsProps = TextSettingsControlInterface;

export const TitleSettings = ({ value, onChange: onExternalChange }: TitleSettingsProps) => {
  const dispatch = useAppDispatch();

  const onLocalChange = (header: TextSettingInterface) => {
    dispatch(updateViewSettingsAction({ header }));
  };

  const onChange = onExternalChange || onLocalChange;

  return <TextSettingsGroup value={value} onChange={onChange} titleText="Основной" />;
};
