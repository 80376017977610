import { ColoredCanvas, ColorSliderWrapper, SliderExtended } from 'modules/ui/colors/ColorSlider/styles';
import { SliderProps } from '@mui/material';
import { useEffect, useRef } from 'react';
import { colorBarHeight, sliderHeight, thumbSize } from 'modules/ui/colors/ColorSlider/constants';
import { ColorFnType } from 'modules/ui/colors/ColorSlider/types';
import { OnValueChange } from 'types/global';
import { canvasFiller } from 'modules/ui/colors/GradientBar/constants';

export interface ColorSliderProps extends Partial<Pick<SliderProps, 'min' | 'max' | 'step' | 'disabled'>>, OnValueChange<number> {
  colorFn: ColorFnType;
  width: number;
}

export const ColorSlider = ({ min = 1, max = 100, step = 1, colorFn, value, onChange, width, disabled }: ColorSliderProps) => {
  const canvas = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    canvasFiller(canvas.current, colorFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas.current, colorFn]);

  return (
    <ColorSliderWrapper disabled={disabled}>
      <SliderExtended
        size="small"
        min={min}
        max={max}
        aria-label="Small"
        disabled={disabled}
        valueLabelDisplay="off"
        step={step}
        value={value}
        onChange={(_, value) => onChange(value as number)}
      />
      <ColoredCanvas top={sliderHeight / 4} height={colorBarHeight} width={width - thumbSize} ref={canvas} />
    </ColorSliderWrapper>
  );
};
