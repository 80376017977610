import {
  RenderFooterType,
  SourceCallbackConnectionData,
  SourceConnectionData,
  SourceEditConnection,
} from 'components/console/elements/sideBar/Forms/DatabaseForm/types';
import { Form } from 'components/console/elements/sideBar/Forms/DatabaseForm/styles';
import { FlexContainer } from 'styles/FlexContainer';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Checkbox, Select, TextField } from 'modules/ui';
import React, { useCallback, useEffect, useState } from 'react';
import { NoopType } from 'types/global';
import { driverItems } from 'components/console/elements/sideBar/Forms/DatabaseForm/constants';
import { useAppDispatch } from 'store';
import {
  addSourceAction,
  changeActiveSourceIdAction,
  createDataBaseConnectionAction,
  updateDataBaseConnectionAction,
  updateSourceByIdAction,
} from 'store/reducers/sources/actions';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { ConnectionChecker } from 'components/console/elements/sideBar/Forms/DatabaseForm/ConnectionChecker';
import Snackbar from 'services/Snackbar';
import { useSelector } from 'react-redux';
import { getSources } from 'store/reducers/sources/getters';
import { useConnection } from 'components/console/elements/hooks/useConnection';

interface DatabaseFormProps {
  onClose?: NoopType;
  data?: SourceEditConnection | null;
  renderFooter?: RenderFooterType;
  mode: 'add' | 'edit';
  projectId?: string;
}

export const DatabaseForm = ({ mode, data, onClose, renderFooter, projectId }: DatabaseFormProps) => {
  const dispatch = useAppDispatch();
  const onConnection = useConnection(projectId || '');

  const { sourcesList } = useSelector(getSources);

  const [loading, setLoading] = useState(false);

  const editSourceId = data?.id;
  const sourceData = data?.sourceData;

  const isAdd = mode === 'add';

  const hasName = useCallback((name: string) => sourcesList.find((obj) => obj.name === name), [sourcesList]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<SourceCallbackConnectionData>({
    defaultValues: {
      driverId: 'postgres',
    },
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (sourceData) {
      setValue('driverId', sourceData.driverId || 'postgres');
      setValue('name', sourceData.name);

      if (sourceData.credentials) {
        const { host, port, database, user, dataFormat, password, https } = sourceData.credentials;
        setValue('host', host);
        setValue('port', port);
        setValue('dataFormat', dataFormat);
        setValue('database', database);
        setValue('user', user);
        setValue('password', password);
        setValue('https', https);
      }
    }
  }, [sourceData, setValue]);

  const selectedDriver = watch('driverId'),
    isClickHouseDriver = selectedDriver === 'clickHouse',
    isOracleDriver = selectedDriver === 'oracle';

  const onSubmit: SubmitHandler<SourceCallbackConnectionData> = useCallback(
    async ({ name, driverId, database, https, dataFormat, user, host, password, port }) => {
      if (!name) {
        return Snackbar.show('Поле «Название подключения» обязательно для заполнения', 'error');
      }

      if (hasName(name) && sourceData?.name !== name) {
        return Snackbar.show('Источник с таким именем уже существует', 'error');
      }

      setLoading(true);

      let credentials: SourceConnectionData = { host, port: Number(port), database, user, password };

      if (isClickHouseDriver) {
        credentials = { ...credentials, https };
      }

      if (isOracleDriver) {
        credentials = { ...credentials, dataFormat };
      }

      let action;
      try {
        if (isAdd) {
          action = await dispatch(createDataBaseConnectionAction({ name, driverId, credentials })).unwrap();

          if (action) {
            const createSourceId = action.id;
            const createIsValid = action.isValid;

            dispatch(addSourceAction({ id: createSourceId, name, isValid: createIsValid, type: 'database', driver: driverId }));
            dispatch(changeActiveSourceIdAction(createSourceId));

            onConnection({ sourceId: createSourceId, name });
          }
        } else {
          if (editSourceId) {
            action = await dispatch(
              updateDataBaseConnectionAction({ id: editSourceId, sourceData: { name, driverId, credentials } }),
            ).unwrap();
          }

          if (action) {
            const updatedSourceId = action.id;
            const updatedIsValid = action.isValid;

            dispatch(
              updateSourceByIdAction({
                id: updatedSourceId,
                source: { name, isValid: updatedIsValid, type: 'database', driver: driverId },
              }),
            );
          }
        }
        if (!action) {
          setLoading(false);
          return;
        }

        if (onClose) {
          onClose();
        }
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDriver, onClose, sourceData?.name, isAdd, hasName, editSourceId],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {isAdd && <LoadingOverlay loading={loading} />}

      <FlexContainer width="100%" flexDirection="column" marginBottom="40px">
        <FlexContainer flexDirection="column" gap="8px">
          <Controller
            name="name"
            rules={{ required: true }}
            control={control}
            render={({ field }) => <TextField error={!!errors.name} label="Название подключения" width="100%" {...field} />}
          />
          <Controller
            render={({ field }) => (
              <Select title="СУБД" options={driverItems} width="100%" placeholder="СУБД" {...field} value={field.value} />
            )}
            name="driverId"
            control={control}
          />
          {isOracleDriver && (
            <Controller
              name="dataFormat"
              control={control}
              render={({ field }) => (
                <TextField label="Формат даты (По умолчанию установленный в вашей СУБД)" width="100%" {...field} />
              )}
            />
          )}
          <Controller name="host" control={control} render={({ field }) => <TextField label="Хост" width="100%" {...field} />} />
          <Controller name="port" control={control} render={({ field }) => <TextField label="Порт" width="100%" {...field} />} />
          <Controller
            name="database"
            control={control}
            render={({ field }) => <TextField label="База данных" width="100%" {...field} />}
          />
          <Controller name="user" control={control} render={({ field }) => <TextField label="Логин" width="100%" {...field} />} />
          <Controller
            name="password"
            control={control}
            render={({ field }) => <TextField type="password" label="Пароль" width="100%" {...field} />}
          />
          <FlexContainer justifyContent={isClickHouseDriver ? 'space-between' : 'flex-end'} marginTop="16px" alignItems="center">
            {isClickHouseDriver && (
              <Controller
                name="https"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    name="https"
                    checked={field.value}
                    label="Использовать протокол https"
                    id="protocol_flag"
                    onChange={field.onChange}
                  />
                )}
              />
            )}
            <ConnectionChecker selectedDriver={selectedDriver} watch={watch} />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      {renderFooter && renderFooter({ onClose })}
    </Form>
  );
};
