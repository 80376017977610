import { Model } from 'models/model/Model';
import { updateTabByIdAction } from 'store/reducers/models/actions';
import { useSelector } from 'react-redux';
import { getActiveTab } from 'store/reducers/models/getters';
import { useAppDispatch } from 'store';
import { ModelTabInterface } from 'store/reducers/models/types';
import { useMemo } from 'react';
import Snackbar from 'services/Snackbar';

export const useModelTab = () => {
  const dispatch = useAppDispatch();
  const activeTab = useSelector(getActiveTab);

  const updateTab = (models?: Model[]) =>
    dispatch(updateTabByIdAction({ id: activeTab?.id || '', data: { models: models || activeTab?.models || [] } }));

  const updaterTab = (action: (activeTab: ModelTabInterface | null) => void | undefined | Model[]) => {
    try {
      const models = action(activeTab) || undefined;

      updateTab(models);
    } catch {
      Snackbar.show('Не удалось создать', 'error');
    }
  };

  const flatModelItems = useMemo(
    () => (activeTab?.models || []).map((model) => model.modelItems.map((modelItem) => ({ modelItem, model }))).flat(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(activeTab?.models)],
  );

  const flatRelations = useMemo(
    () => (activeTab?.models || []).map((model) => model.allRelationsWithMeta).flat(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(activeTab?.models)],
  );

  return { activeTab, updaterTab, dispatch, flatModelItems, flatRelations };
};
