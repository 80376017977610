import { TimeIcon } from 'assets/icons/editor';
import { HomeIcon } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import styled, { css } from 'styled-components';

export const ConsoleContainer = styled.div`
  display: flex;
  padding: 24px;
  height: 100%;
  border-bottom: 1px solid ${`var(${ColorVarsEnum.Level_5})`};
`;

interface ClockProps {
  active?: boolean;
}

export const ClockIcon = styled(TimeIcon)<ClockProps>`
  width: 16px;
  height: 16px;

  ${({ active }) =>
    active &&
    css`
      path {
        stroke: var(${ColorVarsEnum.Accent}) !important;
      }
    `}
`;

export const ScheduleIcon = styled(HomeIcon)`
  width: 16px;
  height: 16px;
`;
