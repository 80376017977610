import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FilterRow } from 'modules/workspace/components/FilterRow';
import { WorkAreaSpace } from 'modules/workspace/components/WorkAreaSpace';
import { useWindowDimensions } from 'utils/hooks/screenSizeHook';
import { useRole } from 'utils/hooks/useRole';
import { getActivePageId, getActivePageSize } from 'store/reducers/projectPages/getters';
import {
  getDashboardComponentsLoading,
  getDashboardElementsLoading,
  getIsShowWorkSpace,
  getIsViewMode,
  getScaleOfPage,
} from 'store/reducers/projectSettings/getters';
import { isHasFiltersByPageId } from 'store/reducers/filters/getters';
import { Loader } from 'shared/ui/Loader';
import { LoaderWrapper } from 'modules/workspace/components/board/styles';
import { loaderTransitionDurationMs } from 'modules/workspace/components/board/constants';
import { Transition } from 'react-transition-group';

export const Board: FC = () => {
  const { width: widthDisplay } = useWindowDimensions();
  const { isViewer } = useRole();
  const isViewMode = useSelector(getIsViewMode),
    isShowWorkSpace = useSelector(getIsShowWorkSpace),
    scaleOfPage = useSelector(getScaleOfPage);
  const activePageId = useSelector(getActivePageId);
  const isHasFiltersOnPage = useSelector(isHasFiltersByPageId(activePageId));
  const { width, height } = useSelector(getActivePageSize);

  const dashboardComponentsLoading = useSelector(getDashboardComponentsLoading);
  const dashboardElementsLoading = useSelector(getDashboardElementsLoading);

  const needScale = useMemo(
    () => isViewer && scaleOfPage.isActive && widthDisplay < scaleOfPage.width,
    [isViewer, widthDisplay, scaleOfPage],
  );

  /* TODO: Delete after render optimization */
  // useEffect(() => {
  //   const handleTouchMove = (event: TouchEvent) => {
  //     // Логика проверки isIOSDevice теперь внутри handleTouchMove
  //     if (isIOSDevice && isViewer) {
  //       const customEvent = event as TouchEvent & { scale?: number };
  //
  //       if (customEvent.scale && customEvent.scale !== 1) {
  //         event.preventDefault();
  //       }
  //     }
  //   };
  //
  //   document.addEventListener('touchmove', handleTouchMove, { passive: false });
  //
  //   return () => {
  //     document.removeEventListener('touchmove', handleTouchMove);
  //   };
  // }, [isIOSDevice, isViewer]);

  return (
    <FlexContainer
      alignItems={needScale ? 'flex-start' : 'center'}
      justifyContent="flex-start"
      flex="1"
      backgroundColor={`var(${ColorVarsEnum.Level_5_application})`}
      flexDirection="column"
      position="relative"
    >
      <Transition
        in={dashboardComponentsLoading || dashboardElementsLoading}
        timeout={loaderTransitionDurationMs}
        mountOnEnter
        unmountOnExit
      >
        {(status) => (
          <LoaderWrapper transitionDuration={loaderTransitionDurationMs} transitionStatus={status}>
            <Loader
              backgroundColor={ColorVarsEnum.Level_5_application}
              title="Загрузка визуализаций"
              size="small"
              direction="column"
            />
          </LoaderWrapper>
        )}
      </Transition>
      <FlexContainer
        maxWidth={needScale ? 'unset' : `${width}px`}
        width={needScale ? `${widthDisplay}px` : '100%'}
        justifyContent="space-between"
        margin={isHasFiltersOnPage ? '10px 0' : '0'}
        height={isHasFiltersOnPage ? '30px' : '0'}
        flexDirection="row"
        transformOrigin="center"
        transition="all 0.35s"
      >
        <FilterRow />
      </FlexContainer>
      <WorkAreaSpace
        isShowWorkSpace={isShowWorkSpace}
        isViewMode={isViewMode}
        isViewer={isViewer}
        width={width}
        height={height}
        needScale={needScale}
      />
    </FlexContainer>
  );
};
