import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const StyledSubSection = styled.div<{ width: string; offsetLeft: string }>`
  position: relative;
  width: ${(props) => props.width};
  left: ${(props) => props.offsetLeft};
  padding: 12px;
  border-bottom: 1px solid var(${ColorVarsEnum.Level_5});
  gap: 16px;
  > * + * {
    margin-top: 16px;
  }
`;
