import { bottomLineMixin } from 'constants/styles';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactChildrenType } from 'types/global';

type Props = {
  children: ReactChildrenType;
};

export const AfterLineBottom: FC<Props> = ({ children }) => <AfterLine>{children}</AfterLine>;

export const AfterLine = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  :hover .backgroundOnHover {
    opacity: 0.02 !important;
  }

  :not(:last-child) {
    :after {
      ${bottomLineMixin}
    }
  }
`;
