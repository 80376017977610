import { EditTableIcon, RemoveTableIcon } from 'assets/icons/table';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import styled, { css } from 'styled-components';

export const TableCellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 100%;
`;

const iconStyles = css`
  svg,
  path {
    stroke: var(${ColorVarsEnum.Level_3});
  }
  height: 20px;
  width: 20px;
`;

export const EditSvg = styled(EditTableIcon)`
  ${iconStyles}
`;

export const RemoveSvg = styled(RemoveTableIcon)`
  ${iconStyles}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  gap: 4px;
`;

export const ButtonWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;

  :hover:after {
    opacity: 0.1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(${ColorVarsEnum.Level_1});
  }
`;
