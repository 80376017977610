import { updateDataSettingsAction, updateTreeDataSettingsAction } from 'store/reducers/visualisations/actions';
import { store } from 'store';
import { OnChangeValue } from 'modules/visualisations/Tree/settings/DataTab/types';
import { DefaultPropertiesInterface, TreeDataSettings } from 'store/reducers/visualisations/types';
import { moveArrayItem, MoveToType } from 'utils/utils';
import { PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';

const dispatch = store.dispatch;

/* Incision change */
export const onColorIncisionChange: OnChangeValue<PaletteValuesByThemeType | null> = (dataSettings, colors, id) =>
  dispatch(
    updateTreeDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) => (id === incision.id ? { ...incision, colors } : incision)),
    }),
  );

export const onMoveIncision = (dataSettings: TreeDataSettings, incisionId: string, moveTo: MoveToType) => {
  const incisions = dataSettings.incisions,
    indexOfIncision = incisions.findIndex(({ id }) => id === incisionId),
    { newArray, newIndex, oldIndex } = moveArrayItem(incisions, indexOfIncision, moveTo);
  let newIncisions = [...newArray];

  if ((newIndex === 0 && oldIndex === 1) || (newIndex === 1 && oldIndex === 0)) {
    const colors = newIncisions[1].colors;
    newIncisions = newIncisions.map((incision, index) => (index === 0 ? { ...incision, colors } : { ...incision, colors: null }));
  }

  dispatch(
    updateTreeDataSettingsAction({
      ...dataSettings,
      incisions: newIncisions,
    }),
  );
};

/* Indicator change */

export const onChangeShowValue: OnChangeValue<boolean> = (dataSettings, isShowValue, id) =>
  dispatch(
    updateTreeDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, isShowValue } } : indicator,
      ),
    }),
  );

export const onChangeIncisionPropertiesIncisionName: OnChangeValue<DefaultPropertiesInterface> = (
  dataSettings,
  propertiesIncisionName,
  id,
) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) =>
        id === incision.id ? { ...incision, propertiesIncisionName } : incision,
      ),
    }),
  );

export const onChangeIncisionPropertiesNameValue: OnChangeValue<DefaultPropertiesInterface> = (
  dataSettings,
  propertiesIncisionNameValue,
  id,
) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) =>
        id === incision.id ? { ...incision, propertiesIncisionNameValue } : incision,
      ),
    }),
  );

export const onChangeIncisionPropertiesValue: OnChangeValue<DefaultPropertiesInterface> = (
  dataSettings,
  propertiesIncisionValue,
  id,
) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) =>
        id === incision.id ? { ...incision, propertiesIncisionValue } : incision,
      ),
    }),
  );
