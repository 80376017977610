import { PrimaryTextParagraph } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Select } from 'modules/ui/index';
import { FlexContainer } from 'styles/FlexContainer';
import { IProps } from 'modules/ui/Select';

interface TitledSelector extends IProps {
  title: string;
}

export const TitledSelector = ({ title, width, ...selectorProps }: TitledSelector) => {
  return (
    <FlexContainer flexDirection="column" gap="3px" width={width}>
      <PrimaryTextParagraph fontSize="10px" color={`var(${ColorVarsEnum.Level_2})`}>
        {title}
      </PrimaryTextParagraph>
      <Select {...selectorProps} width="100%" />
    </FlexContainer>
  );
};
