import { HorizontalPositionSettingsProps } from 'modules/settingsContainer/common/HorizontalPositionSettings';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { titlePositionOptions } from 'modules/settingsContainer/common/HorizontalPositionSettings/constants';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import React from 'react';
import { IsVisibleInterface } from 'store/reducers/visualisations/types';
import { NoopValueType } from 'types/global';

type IncisionsSelectorPositionSettingsProps = Omit<HorizontalPositionSettingsProps, 'titleText'>;

export const IncisionsSelectorPositionSettings = ({
  isVisible,
  value,
  onChange,
  onChangeVisible,
}: IncisionsSelectorPositionSettingsProps &
  IsVisibleInterface & {
    onChangeVisible?: NoopValueType<IsVisibleInterface>;
  }) => {
  const activeValue = getActiveRadioValue(titlePositionOptions, value);

  return (
    <MainContainerSettings
      titleText="Навигация по разрезам"
      switcherState={isVisible}
      switcherChange={() => onChangeVisible && onChangeVisible({ isVisible: !isVisible })}
    >
      <GroupContainerSettings>
        <SettingsRadio onChange={({ value }) => onChange(value)} activeValue={activeValue} options={titlePositionOptions} />
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
