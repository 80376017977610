import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const HeatmapDefault: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fill = `var(${ColorVarsEnum.Accent})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <rect x="8" y="14" width="16" height="12" fill={fillDefault} />
        <rect x="27" y="14" width="16" height="12" fill={fill} />
        <rect opacity="0.35" x="46" y="14" width="16" height="12" fill={fillDefault} />
        <rect opacity="0.65" x="8" y="29" width="16" height="12" fill={fillDefault} />
        <rect opacity="0.35" x="27" y="29" width="16" height="12" fill={fillDefault} />
        <rect opacity="0.15" x="46" y="29" width="16" height="12" fill={fillDefault} />
        <rect opacity="0.35" x="8" y="44" width="16" height="12" fill={fillDefault} />
        <rect opacity="0.15" x="27" y="44" width="16" height="12" fill={fillDefault} />
        <rect opacity="0.05" x="46" y="44" width="16" height="12" fill={fillDefault} />
      </svg>
    </FlexContainer>
  );
};
