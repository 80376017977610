import { store } from 'store';
import { updateFilterAction } from 'store/reducers/filters/actions';
import {
  BoardPositionConfigInterface,
  BorderStyleSettingsInterface,
  PositionSettingType,
  ShadowSettingInterface,
  SqlDataInterface,
} from 'types/store';
import { FilterNameSettingsInterface } from 'store/reducers/filters/types';
import { MapRecordType } from 'types/global';
import { FilterElementsInterface } from 'modules/settingsContainer/FIlterIncisionsSettings/types';

const dispatch = store.dispatch;

export const onRealDataChange = (isRealData: boolean) => dispatch(updateFilterAction({ isRealData }));
export const onDisableDraggingChange = (disableDragging: boolean) => dispatch(updateFilterAction({ disableDragging }));
export const onShadowSettingsChange = (shadowSettings: ShadowSettingInterface) =>
  dispatch(updateFilterAction({ shadowSettings }));
export const onShowBackgroundChange = (showBackground: boolean) => dispatch(updateFilterAction({ showBackground }));
export const onBorderSettingsChange = (borderSettings: BorderStyleSettingsInterface) =>
  dispatch(updateFilterAction({ borderSettings }));
export const onPositionConfigChange = (positionConfig: BoardPositionConfigInterface) =>
  dispatch(updateFilterAction({ positionConfig }));
export const onModelIdChange = (modelId: string | null) => dispatch(updateFilterAction({ modelId }));
export const onGlobalChange = (isGlobal: boolean) => dispatch(updateFilterAction({ isGlobal }));
export const onFilterInfluencesChange = (filterInfluences: MapRecordType<boolean>) =>
  dispatch(updateFilterAction({ filterInfluences }));
export const onSqlDataChange = (sqlData: SqlDataInterface) => dispatch(updateFilterAction({ ...sqlData }));
export const onFilterElementsChange = (filterElements: FilterElementsInterface) =>
  dispatch(updateFilterAction({ ...filterElements }));

export const onPositionChange = (position: PositionSettingType) => dispatch(updateFilterAction({ position }));

export const onNameSettingsChange = (nameSettings: FilterNameSettingsInterface['nameSettings']) =>
  dispatch(updateFilterAction({ nameSettings }));
