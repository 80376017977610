import { createSelector } from 'reselect';
import { getState } from 'store/utils';

export const getModalsStore = createSelector(getState, (state) => state.modals);

export const getModals = createSelector(getModalsStore, (state) => state.modals);

export const getConfirmationModals = createSelector(getModalsStore, (state) => state.confirmationModal);

export const getWizardModals = createSelector(getModalsStore, (state) => state.wizardModal);
