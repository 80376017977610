import React, { memo } from 'react';

import { InfoProjectUI } from 'modules/ui/ManagerOrAdminComponentsUI/InfoProjectUI';
import { useUserDraftProjectInfo } from '../hook/useUserDraftProjectInfo';
import { NoopValueType } from 'types/global';
import { AvatarProjectInterface } from 'types/types';

interface InfoUserDraftProjectProps {
  activeProjectId: string;
  padding?: string;
  onChangeAvatar?: NoopValueType<AvatarProjectInterface>;
}

export const InfoUserDraftProjectComponent = ({ activeProjectId, padding, onChangeAvatar }: InfoUserDraftProjectProps) => {
  const {
    loadingInfoProject,
    infoProject: infoProjectData,
    changeProjectAvatar,
  } = useUserDraftProjectInfo(activeProjectId || '');

  const handleChangeAvatar = ({ avatar, projectId }: AvatarProjectInterface) => {
    changeProjectAvatar(avatar);
    onChangeAvatar && onChangeAvatar({ avatar, projectId });
  };

  return (
    <InfoProjectUI
      title={infoProjectData?.title}
      id={infoProjectData?.id}
      isProtected={infoProjectData?.isProtected}
      createdAt={infoProjectData?.createdAt}
      created={infoProjectData?.created}
      updated={infoProjectData?.updated}
      avatar={infoProjectData?.avatar}
      updatedDataAt={infoProjectData?.updatedDataAt}
      isLoading={loadingInfoProject}
      padding={padding}
      onChangeAvatar={handleChangeAvatar}
    />
  );
};

export const InfoUserDraftProject = memo(InfoUserDraftProjectComponent);
