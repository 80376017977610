import styled from 'styled-components';
import { InfoIcon } from 'assets/icons/withContainer';

export const StyledInfoIcon = styled(InfoIcon)`
  width: 16px;
`;

export interface LocalValuesInterface {
  incisionRequest?: string;
  indicatorRequest?: string;
  serviceRequest?: string;
  filterAndGroupRequest?: string;
}
