import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FilterInfluenceIconProps } from '../fontStyle/types';
import { FilterInfluenceIconWrapper, WrapperIconEnum } from './FilterInfluenceIconWrapper';
import ColorTheme from 'constants/ColorTheme';

export const DenyIcon = ({ size = 20, isActive, onClick, type = WrapperIconEnum.NOWRAP }: FilterInfluenceIconProps) => (
  <>
    {type === WrapperIconEnum.WRAP ? (
      <FilterInfluenceIconWrapper onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" style={{ cursor: 'pointer' }}>
          <path
            stroke={isActive ? ColorTheme.default.accent : `var(${ColorVarsEnum.Level_2})`}
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth={1.5}
            d="m4.167 4.167 11.666 11.667M4.167 15.834 15.832 4.167"
          />
        </svg>
      </FilterInfluenceIconWrapper>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        width={size}
        height={size}
        fill="none"
        style={{ cursor: 'pointer' }}
      >
        <path
          stroke={isActive ? ColorTheme.default.accent : `var(${ColorVarsEnum.Level_2})`}
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={1.5}
          d="m4.167 4.167 11.666 11.667M4.167 15.834 15.832 4.167"
        />
      </svg>
    )}
  </>
);
