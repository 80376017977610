import { NoopType, ReactFC, WizardItemType, WizardsStepDataInterface } from 'types/global';

export interface BaseModalParams {
  headerText?: string;
  width?: string;
  padding?: string;
  maxHeight?: string;
  disableModalPortal?: boolean;
  position?: 'relative' | 'absolute' | 'static';
}

export type BaseComponentProps = {
  onClose: NoopType;
};

export type ModalComponentType<Props = unknown> = ReactFC<Props & BaseComponentProps>;

export interface ModalInfo<Props = unknown> {
  Component: ModalComponentType<Props>;
  name: string;
  modalSettings?: BaseModalParams;
  componentProps: Props & BaseComponentProps;
}

export interface IsOpenInterface {
  isOpen: boolean;
}

export interface ConfirmationModalInterface extends IsOpenInterface {
  onConfirm: ((value?: string) => Promise<void>) | ((value?: string) => void) | NoopType;
  onCancel?: NoopType;
  titleText: string;
  confirmationButtonText: string;
  subTitleText?: string;
  cancelButtonText?: string;
  description?: string;
  width?: string;
  maxHeight?: string;
  valueInput?: string;
  isRenamable?: boolean;
  renamableTitle?: string;
  disableModalPortal?: boolean;
}

export interface WizardModalData<WizardStepDataList extends WizardsStepDataInterface<keyof WizardStepDataList>> {
  stepDataList: WizardStepDataList;
  defaultTitle: string;
  onClose: NoopType;
  firstStepComponent: keyof WizardStepDataList;
  projectId?: string;
}

export interface WizardModalInterface
  extends WizardModalData<Record<string, WizardItemType<string> | undefined>>,
    IsOpenInterface {}

export interface ModalsStateInterface {
  modals: Array<ModalInfo>;
  confirmationModal: ConfirmationModalInterface;
  wizardModal: WizardModalInterface;
}

/* Action Types */

export enum ModalsActionsTypes {
  OPEN_MODAL = 'OPEN_MODAL',
  OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL',
  OPEN_WIZARD_MODAL = 'OPEN_WIZARD_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL',
  CLOSE_WIZARD_MODAL = 'CLOSE_WIZARD_MODAL',
}
