import { GroupsSliceStateInterface } from './types';

export const initialAdminGroupsStoreState: GroupsSliceStateInterface = {
  groups: {
    groupsList: [],
    loading: false,
  },
  groupAccessUsers: {
    groupAccessUsersList: [],
    loading: false,
  },
  groupFlows: {
    groupFlowsList: [],
    loading: false,
  },
  groupSources: {
    groupSourcesList: [],
    loading: false,
  },
  activeGroup: null,
};
