import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import styled from 'styled-components';

export const IndentStyledBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  svg,
  path,
  rect {
    stroke: var(${ColorVarsEnum.Level_3});
  }
`;
