import { HorizontalPositionSettingsProps } from 'modules/settingsContainer/common/HorizontalPositionSettings';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { titlePositionOptions } from 'modules/settingsContainer/common/HorizontalPositionSettings/constants';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import React from 'react';
import { IsVisibleInterface } from 'store/reducers/visualisations/types';
import { NoopValueType } from 'types/global';

type IncisionsSelectorPositionSettingsProps = Omit<HorizontalPositionSettingsProps, 'titleText'>;

export const IncisionsSelectorPositionSettingsGroup = ({
  isVisible,
  value,
  onChange,
  onChangeVisible,
}: IncisionsSelectorPositionSettingsProps &
  IsVisibleInterface & {
    onChangeVisible?: NoopValueType<IsVisibleInterface>;
  }) => {
  const activeValue = getActiveRadioValue(titlePositionOptions, value);

  return (
    <GroupContainerSettings
      titleText="Альтернативные разрезы"
      switcherState={isVisible}
      switcherChange={() => onChangeVisible && onChangeVisible({ isVisible: !isVisible })}
    >
      <SettingsRadio onChange={({ value }) => onChange(value)} activeValue={activeValue} options={titlePositionOptions} />
    </GroupContainerSettings>
  );
};
