import { FlexContainer } from 'styles/FlexContainer';
import CircularProgress from '@mui/material/CircularProgress';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import React, { useEffect, useState } from 'react';

type LoadingOverlayProps = {
  loading: boolean;
  backgroundColor?: string;
};

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  loading,
  backgroundColor = `var(${ColorVarsEnum.Level_3_menu})`,
}) => {
  const [shouldDisplay, setShouldDisplay] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (loading) {
      timer = setTimeout(() => {
        setShouldDisplay(true);
      }, 1000);
    } else {
      setShouldDisplay(false);
    }

    return () => clearTimeout(timer);
  }, [loading]);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <FlexContainer
      position="absolute"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      backgroundColor={backgroundColor}
      zIndex="3"
      left="0px"
      top="0px"
    >
      <CircularProgress />
    </FlexContainer>
  );
};
