import React, { FC, memo, useCallback } from 'react';
import { SettingsIcon } from '../../../../assets/icons/forDelete';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { CodeAlterIcon } from '../../../../assets/icons/withContainer';
import { getVisualSettings } from 'store/reducers/visualSettings/getters';
import { SaveDashboardIcon } from '../../../../assets/icons/withContainer/index';
import { BoardMenuItem } from 'modules/workspace/components/BoardMenuItem';
import { settingsLayoutWidth } from 'modules/settingsContainer/SettingsLayout/constants';
import { SETTINGS_BUTTON_LABEL, SQL_BUTTON_LABEL } from 'modules/workspace/constans';
import { useModalState } from 'utils/hooks/modalState';
import { SQLRequest } from 'modules/workspace/components/SQLRequests';
import { getLoadInProgress, getSaveInProgress } from 'store/reducers/projectSettings/getters';

interface IProps {
  onClickSave: () => Promise<any>;
  hasChanges: boolean;
}

export const RightBarComponent: FC<IProps> = ({ onClickSave, hasChanges }) => {
  const { SettingComponent } = useSelector(getVisualSettings);
  const saveInProgress = useSelector(getSaveInProgress);
  const loadInProgress = useSelector(getLoadInProgress);

  const settingsButtonState = useModalState();
  const sqlButtonState = useModalState();

  const onSettingsOpen = useCallback(() => {
    sqlButtonState.onClose();
    settingsButtonState.onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sqlButtonState.onClose, settingsButtonState.onOpen]);

  const onSqlOpen = useCallback(() => {
    settingsButtonState.onClose();
    sqlButtonState.onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsButtonState.onClose, sqlButtonState.onOpen]);

  const MemoizedSettingComponent = useCallback(() => <SettingComponent />, [SettingComponent]);

  const onSaveClick = useCallback(async () => {
    await onClickSave();
  }, [onClickSave]);

  return (
    <FlexContainer
      flexDirection="column"
      maxWidth="56px"
      backgroundColor={`var(${ColorVarsEnum.Level_5_application})`}
      zIndex="10"
      flex="1"
    >
      <BoardMenuItem
        modalDataWidth={settingsLayoutWidth}
        closeOnClickAway={false}
        Icon={SettingsIcon}
        ModalData={MemoizedSettingComponent}
        placement="left-start"
        id={SETTINGS_BUTTON_LABEL}
        externalModalState={{ isOpen: settingsButtonState.isOpen, onOpen: onSettingsOpen, onClose: settingsButtonState.onClose }}
      />
      <BoardMenuItem
        externalModalState={{ isOpen: sqlButtonState.isOpen, onOpen: onSqlOpen, onClose: sqlButtonState.onClose }}
        id={SQL_BUTTON_LABEL}
        closeOnClickAway={false}
        ModalData={SQLRequest}
        Icon={CodeAlterIcon}
      />
      <BoardMenuItem
        Icon={SaveDashboardIcon}
        onClick={onSaveClick}
        loading={saveInProgress}
        disabled={saveInProgress || loadInProgress || !hasChanges}
      />
    </FlexContainer>
  );
};

export const RightBar = memo(RightBarComponent);
