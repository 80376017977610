import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { fileInputMixin } from 'constants/styles';

export const FileInputWrapper = styled.div`
  position: relative;
  flex-direction: column;
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
`;

export const FileInput = styled.input`
  ${fileInputMixin}
`;

type FileInputLabelProps = {
  fontSize?: string;
  padding?: string;
  lineHeight?: string;
};

export const FileInputLabel = styled.label<FileInputLabelProps>`
  display: flex;
  border-radius: 2px;
  flex-direction: row;
  gap: 4px;
  position: relative;
  min-width: 55px;
  cursor: pointer;
  align-items: center;
  line-height: ${({ lineHeight }) => `${lineHeight || '14px'};`};
  font-size: ${({ fontSize }) => `${fontSize || '14px'};`};
  color: var(${ColorVarsEnum.Level_1});
  padding: ${({ padding }) => `${padding || ' 10px 9px'};`};
  z-index: 0;
  transition: all 200ms;

  :after {
    content: '';
    position: absolute;
    opacity: 0.07;
    border-radius: 2px;
    background: var(${ColorVarsEnum.Level_1});
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
  }
  :hover {
    background-color: var(${ColorVarsEnum.Level_1_hover_btn});
  }
`;
