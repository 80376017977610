import React from 'react';
import { useAppDispatch } from 'store';
import { updatePositionConfigAction } from 'store/reducers/visualisations/actions';
import { NoopValueType } from 'types/global';
import { BoardPositionConfigInterface, DisableDraggingInterface } from 'types/store';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { pxUnit } from 'constants/global';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { FlexContainer } from 'styles/FlexContainer';
import { BooleanDraggingSettings } from 'modules/settingsContainer/common/view/BooleanDraggingSettings';

export interface ContainerPositionSettingsProps extends DisableDraggingInterface {
  onChange?: NoopValueType<BoardPositionConfigInterface>;
  value: BoardPositionConfigInterface;
  onExternalDisableDraggingChange?: NoopValueType<boolean>;
}

export const ContainerPositionSettings = ({
  value,
  onChange: onExternalChange,
  onExternalDisableDraggingChange,
  disableDragging,
}: ContainerPositionSettingsProps) => {
  const { x, y, height, width } = value;
  const dispatch = useAppDispatch();

  const onLocalChange = (positionConfig: BoardPositionConfigInterface) => {
    dispatch(updatePositionConfigAction(positionConfig));
  };

  const onChange = onExternalChange || onLocalChange;

  return (
    <GroupContainerSettings showBottomLine>
      <ElementContainerSettings>
        <FlexContainer gap="50px">
          <FlexContainer flexDirection="column">
            <NumberPropertiesBlock properties="X" unit={pxUnit} name="x" value={x} onChange={(x) => onChange({ ...value, x })} />
            <NumberPropertiesBlock properties="Y" unit={pxUnit} name="y" value={y} onChange={(y) => onChange({ ...value, y })} />
          </FlexContainer>
          <FlexContainer flexDirection="column">
            <NumberPropertiesBlock
              properties="Ш"
              unit={pxUnit}
              name="width"
              value={width}
              onChange={(width) => onChange({ ...value, width })}
            />
            <NumberPropertiesBlock
              properties="В"
              unit={pxUnit}
              name="height"
              value={height}
              onChange={(height) => onChange({ ...value, height })}
            />
          </FlexContainer>
        </FlexContainer>
      </ElementContainerSettings>
      <BooleanDraggingSettings value={disableDragging} onChange={onExternalDisableDraggingChange} />
    </GroupContainerSettings>
  );
};
