import { FC, useCallback, useEffect, useId, useState } from 'react';
import { WorkSpaceTaskModal } from 'components/console/elements/workSpace/Modal/WorkSpaceTaskModal';
import { createTask, editTask } from 'components/console/elements/workSpace/Modal/WorkSpaceTaskModal/constants';
import { ClockIcon, ConsoleContainer } from 'components/console/elements/workSpace/styles';
import { Button } from 'modules/ui';
import Snackbar from 'services/Snackbar';
import { useAppDispatch } from 'store';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectIdParam, RoutesURL } from 'constants/Routes';
import { TaskInterface } from 'store/reducers/adminTasks/types';
import { closeModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { loadProjectTaskAction } from 'store/reducers/projectSettings/actions';
import { getProjectTask } from 'store/reducers/projectSettings/getters';
import { FlexContainer } from 'styles/FlexContainer';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-pgsql';
import 'ace-builds/src-noconflict/theme-github_dark';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ModeForm } from 'types/store';
import { getTimeFromTimestamp } from 'utils/utils';
import { useSelector } from 'react-redux';
import { getSQLScript } from 'store/reducers/loadingScript/getters';
import {
  loadSQLScriptAction,
  loadSQLScriptStatusAction,
  runSQLScriptAction,
  uploadSQLScriptAction,
} from 'store/reducers/loadingScript/actions';
import { ConsoleUploadStatus, HistoryScriptRun } from 'components/console/elements/workSpace/types';
import { loadSourcesAction } from 'store/reducers/sources/actions';

/* TODO: When the time comes it will be necessary to bring him back to human form */

const WorkSpace: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const id = useId();

  const scriptRequest = useSelector(getSQLScript);
  const { loading, task } = useSelector(getProjectTask);

  const [loadingInfo, setLoadingInfo] = useState<ConsoleUploadStatus>({ percentStatus: null, timestamp: null });
  const [loadingUUID, setLoadingUUID] = useState<string | null>(null);
  const [sqlRequest, setSQLRequest] = useState('');
  const [lastUpdateSQLRequest, setLastUpdateSQLRequest] = useState('');
  const [historyOutput, setHistoryOutput] = useState<HistoryScriptRun[]>([]);
  const projectParams = useParams<ProjectIdParam>();
  const projectId = projectParams?.projectId || '';

  if (!projectId) {
    navigate(RoutesURL.projectsList);
  }

  const handleAddHistory = useCallback(
    ({ message, type }: Omit<HistoryScriptRun, 'timestamp'>) => {
      setHistoryOutput((prev) => [
        ...prev,
        {
          timestamp: Date.now(),
          message,
          type,
        },
      ]);
    },
    [setHistoryOutput],
  );

  useEffect(() => {
    dispatch(loadSQLScriptAction(projectId));
    dispatch(loadProjectTaskAction(projectId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setSQLRequest(scriptRequest);
    setLastUpdateSQLRequest(scriptRequest);
    setHistoryOutput([
      {
        timestamp: Date.now(),
        message: 'Получен скрипт',
        type: 'ok',
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptRequest]);

  const saveRequest = useCallback(async () => {
    //if (lastUpdateSQLRequest !== sqlRequest) {
    setLastUpdateSQLRequest(sqlRequest);
    const uploadSQL = await dispatch(uploadSQLScriptAction({ projectId, script: sqlRequest })).unwrap();

    if (uploadSQL) {
      dispatch(loadSourcesAction(projectId));
    }

    handleAddHistory({
      message: 'Скрипт сохранен',
      type: 'ok',
    });
    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sqlRequest, lastUpdateSQLRequest, projectId, historyOutput]);

  useEffect(() => {
    // const interval = setInterval(saveRequest, 10000);

    return () => {
      // clearInterval(interval);
      if (sqlRequest) {
        dispatch(uploadSQLScriptAction({ projectId, script: sqlRequest }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runScript = useCallback(() => {
    dispatch(runSQLScriptAction(projectId)).then((response: any) => {
      if (response?.error?.message !== 'Rejected') {
        setLoadingUUID(response.payload);
        handleAddHistory({
          message: 'Скрипт запущен',
          type: 'ok',
        });
      } else {
        Snackbar.show(response.payload, 'error');
        handleAddHistory({
          message: response.payload,
          type: 'error',
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, historyOutput, setHistoryOutput]);

  useEffect(() => {
    if (loadingUUID) {
      const checkStatus = setInterval(async () => {
        await dispatch(loadSQLScriptStatusAction(loadingUUID)).then((status: any) => {
          if (status.payload.status === 'aborted') {
            setLoadingUUID(null);
            setLoadingInfo({ percentStatus: null, timestamp: null });
            Snackbar.show(status.payload.message, 'error');
            clearInterval(checkStatus);
            handleAddHistory({
              message: status.payload.message,
              type: 'error',
            });
          } else {
            setLoadingInfo({ percentStatus: status.payload.progress, timestamp: Date.now() });
            if (status.payload.status === 'success') {
              Snackbar.show('Загрузка прошла успешно', 'success');

              setLoadingInfo({ percentStatus: 100, timestamp: Date.now() });
              clearInterval(checkStatus);
              handleAddHistory({
                message: 'Загрузка прошла успешно',
                type: 'ok',
              });

              setTimeout(() => {
                setLoadingUUID(null);
                setLoadingInfo({ percentStatus: null, timestamp: null });
              }, 500);
            }
          }
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUUID]);

  const onCloseWorkspaceTaskModal = useCallback(
    (name: string) => dispatch(closeModalAction(name)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onWorkspaceTaskModal = useCallback(
    ({
      modalName,
      task,
      mode,
      headerTextModal,
    }: {
      modalName: string;
      mode: ModeForm;
      headerTextModal: string;
      task?: TaskInterface;
    }) => {
      dispatch(
        openModalTypedAction({
          Component: WorkSpaceTaskModal,
          componentProps: {
            onClose: () => onCloseWorkspaceTaskModal(modalName),
            task,
            mode,
            projectId,
          },
          modalSettings: {
            position: 'static',
            width: '575px',
            headerText: headerTextModal,
          },
          name: modalName,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseWorkspaceTaskModal, projectId],
  );

  const onCreateTask = useCallback(
    () => onWorkspaceTaskModal({ modalName: createTask, headerTextModal: 'Создание задания', mode: 'add' }),
    [onWorkspaceTaskModal],
  );

  const onEditTask = useCallback(
    () =>
      task &&
      onWorkspaceTaskModal({
        modalName: editTask,
        mode: 'edit',
        headerTextModal: 'Изменение задания',
        task,
      }),
    [onWorkspaceTaskModal, task],
  );

  return (
    <FlexContainer
      flexDirection="column"
      flex="1"
      width="100%"
      backgroundColor={`var(${ColorVarsEnum.Level_5_application})`}
      position="relative"
    >
      <ConsoleContainer>
        <AceEditor
          mode="pgsql"
          theme="github_dark"
          name={id}
          width="100%"
          height="100%"
          onChange={(text) => setSQLRequest(text)}
          fontSize={14}
          showPrintMargin={false}
          showGutter={false}
          highlightActiveLine={false}
          value={sqlRequest}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
            cursorStyle: 'ace',
          }}
        />
      </ConsoleContainer>
      <FlexContainer
        margin="24px"
        position="relative"
        flexDirection="column"
        gap="4px"
        height="100px"
        overflow="auto"
        width="calc(100% - 325px)"
      >
        {historyOutput.map((el) => (
          <FlexContainer key={el.timestamp} width="100%" gap="10px">
            <PrimaryTextSpan
              lineHeight="12px"
              fontSize="12px"
              color={el.type === 'ok' ? `var(${ColorVarsEnum.Level_2})` : `var(${ColorVarsEnum.Alert})`}
              width="46px"
            >
              {getTimeFromTimestamp(el.timestamp)}
            </PrimaryTextSpan>
            <PrimaryTextSpan
              lineHeight="12px"
              fontSize="12px"
              overflowWrap="anywhere"
              color={el.type === 'ok' ? `var(${ColorVarsEnum.Level_1})` : `var(${ColorVarsEnum.Alert})`}
            >
              {el.message}
            </PrimaryTextSpan>
          </FlexContainer>
        ))}
        {loadingInfo.timestamp && (
          <FlexContainer width="100%" gap="10px">
            <PrimaryTextSpan lineHeight="12px" fontSize="12px" color={`var(${ColorVarsEnum.Level_2})`} width="46px">
              {getTimeFromTimestamp(loadingInfo.timestamp)}
            </PrimaryTextSpan>
            <PrimaryTextSpan lineHeight="12px" fontSize="12px" color={`var(${ColorVarsEnum.Level_1})`}>
              Загружено {loadingInfo.percentStatus}%
            </PrimaryTextSpan>
          </FlexContainer>
        )}
      </FlexContainer>
      <FlexContainer flexDirection="column" gap="12px" alignItems="flex-end" right="24px" bottom="24px" position="absolute">
        <FlexContainer gap="12px">
          <Button text="Сохранить" onClick={saveRequest} />
          <Button text="Запустить" onClick={runScript} />
        </FlexContainer>
        <Button
          leftIcon={<ClockIcon active={task?.isActive} />}
          width="fit-content"
          text="Обновление данных"
          onClick={!loading && !task ? onCreateTask : onEditTask}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default WorkSpace;
