import { FunctionIcon } from 'assets/icons/forDelete';
import { SetupAlterIcon } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ColorPicker, ColorPickerProps } from 'modules/settingsContainer/ColorPicker';
import {
  ColorPickerTypesType,
  ColorValuesByThemeType,
  PaletteValuesByThemeType,
} from 'modules/settingsContainer/ColorPicker/types';
import { PriorityChangerButtons, PriorityChangerButtonsProps } from 'modules/settingsContainer/PriorityChangerButtons';
import {
  SettingsFieldContainer,
  SetupButtonContainer,
  SetupIconWrapper,
} from 'modules/settingsContainer/SettingsFieldEntry/styles';
import Button from 'modules/ui/Button';
import TextField from 'modules/ui/TextField';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { SettingsFieldWrapper, SettingsFieldWrapperProps } from 'modules/ui/wrappers/SettingsFieldWrapper';
import React, { MouseEventHandler } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ReactChildrenType } from 'types/global';
import { formattingNameIncision } from 'utils/formatting';

interface SettingsFieldEntryProps<ColorValue extends PaletteValuesByThemeType | ColorValuesByThemeType>
  extends PriorityChangerButtonsProps,
    SettingsFieldWrapperProps {
  children?: ReactChildrenType;
  leftTextField?: ReactChildrenType;
  beforeField?: ReactChildrenType;
  fieldValue: string;
  onFieldChange: (fieldValue: string) => void;
  canChangeField?: boolean;
  disableChangePriory?: boolean;
  onSetupClick?: MouseEventHandler<HTMLButtonElement>;
  setupIsOpen?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  disableColorPicker?: boolean;
  disabledViewColorPicker?: boolean;
  disabledViewSetup?: boolean;
  disabledViewChildren?: boolean;
  colorByHand?: boolean;
  colorPickerType?: ColorPickerTypesType;
  onChangeColors?: ColorPickerProps<ColorValue>['onChange'];
  colorsValue?: ColorPickerProps<ColorValue>['value'];
}

export const SettingsFieldEntry = <ColorValue extends PaletteValuesByThemeType | ColorValuesByThemeType>({
  fieldValue,
  onFieldChange,
  canChangeField = true,
  beforeField,
  onSetupClick,
  setupIsOpen = false,
  onUpClick,
  onDownClick,
  isSelected,
  isActive,
  onClick,
  disableColorPicker,
  onChangeColors,
  colorsValue,
  colorPickerType,
  disableChangePriory = false,
  disabledViewColorPicker = false,
  disabledViewSetup = false,
  disabledViewChildren = false,
  colorByHand = true,
  children,
  leftTextField,
}: SettingsFieldEntryProps<ColorValue>) => (
  <SettingsFieldWrapper onClick={onClick} isSelected={isSelected} isActive={isActive}>
    <SettingsFieldContainer disabledViewSetup={disabledViewSetup}>
      {!disabledViewColorPicker &&
        (colorByHand ? (
          <ColorPicker
            closeOnClick
            type={colorPickerType}
            onChange={onChangeColors}
            value={colorsValue}
            disabled={disableColorPicker}
          />
        ) : (
          <IconWrapper
            hoverColorVar={ColorVarsEnum.Level_1}
            colorVar={ColorVarsEnum.Level_3}
            containerWidth="20px"
            containerHeight="20px"
            iconHeight="16px"
            iconWidth="16px"
            Icon={FunctionIcon}
          />
        ))}

      {beforeField && <FlexContainer flex="1 1 0">{beforeField}</FlexContainer>}

      {canChangeField ? (
        <>
          <TextField
            name="xAxisName"
            width="100%"
            needBackground={false}
            needBorderBottom={false}
            value={fieldValue}
            useDebounce
            onChange={(e) => onFieldChange(formattingNameIncision(e.target.value))}
            formattingValue={(value) => formattingNameIncision(value)}
          />
          {leftTextField}
        </>
      ) : (
        <FlexContainer flex="1 1 0">
          <PrimaryTextSpan lineHeight="14px" fontSize="14px" marginLeft="8px" color={`var(${ColorVarsEnum.Level_1})`}>
            {fieldValue}
          </PrimaryTextSpan>
        </FlexContainer>
      )}
      <SetupButtonContainer disabledViewSetup={disabledViewSetup} isSelected={isSelected}>
        <Button
          disabled={!onSetupClick}
          needBackground={false}
          leftIcon={
            <SetupIconWrapper isActive={setupIsOpen}>
              <SetupAlterIcon />
            </SetupIconWrapper>
          }
          onClick={onSetupClick}
        />
      </SetupButtonContainer>
    </SettingsFieldContainer>

    {isSelected && !disabledViewChildren && (
      <FlexContainer alignItems="center" gap="10px">
        <FlexContainer flex="1 1 0">{children}</FlexContainer>
        {!disableChangePriory && <PriorityChangerButtons onDownClick={onDownClick} onUpClick={onUpClick} />}
      </FlexContainer>
    )}
  </SettingsFieldWrapper>
);
