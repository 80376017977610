import { useMemo } from 'react';
import { getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import { DefaultIncisionInterface } from 'store/reducers/visualisations/types';

interface HeatmapIncisionsProps<Incision extends DefaultIncisionInterface> {
  activeHorizontalIncision: Incision;
  activeVerticalIncision: Incision;
}

export const useHeatmapIncisions = <Incision extends DefaultIncisionInterface>({
  activeHorizontalIncision,
  activeVerticalIncision,
}: HeatmapIncisionsProps<Incision>) => {
  return useMemo(() => {
    const incisionXAxis = activeHorizontalIncision;
    const incisionYAxis = activeVerticalIncision;

    const {
      name: nameXAxis,
      fieldName: fieldNameXAxis,
      settings: { nameFromDatabase: nameFromDatabaseXAxis },
    } = incisionXAxis;

    const {
      name: nameYAxis,
      fieldName: fieldNameYAxis,
      settings: { nameFromDatabase: nameFromDatabaseYAxis },
    } = incisionYAxis;

    return {
      xAxisIncisions: {
        fieldName: getVisualisationFieldName({
          name: nameXAxis,
          fieldName: fieldNameXAxis,
          nameFromDatabase: nameFromDatabaseXAxis,
        }),
        data: incisionXAxis as Incision,
      },
      yAxisIncisions: {
        fieldName: getVisualisationFieldName({
          name: nameYAxis,
          fieldName: fieldNameYAxis,
          nameFromDatabase: nameFromDatabaseYAxis,
        }),
        data: incisionXAxis as Incision,
      },
    };
  }, [activeHorizontalIncision, activeVerticalIncision]);
};
