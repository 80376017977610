import React, { FC, useCallback, useEffect } from 'react';
import { useAppDispatch } from 'store';
import { Sources } from 'components/admin/usersPage/elemets/Sources';
import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Flows } from 'components/admin/usersPage/elemets/Flows';
import { LoadLayoutAdmin } from 'components/layouts/LoadLayoutAdmin';
import { changeActiveUserAction, loadDraftsOfAllUsersAction, loadUsersAction } from 'store/reducers/adminUsers/actions';
import { useSelector } from 'react-redux';
import { getUsers, getActiveUser } from 'store/reducers/adminUsers/getters';
import { UniversalLeftBarList } from 'modules/settingsContainer/UniversalLeftBarList';
import { SelectedUserInterface } from 'components/admin/usersPage/types';
import { useDeleteUser } from 'components/admin/usersPage/hook/useDeleteUser';
import { closeModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { createUser } from './Modal/CreateUserModal/constants';
import { CreateUserModal } from 'components/admin/usersPage/Modal/CreateUserModal';
import { SectionMiddleUI } from 'modules/ui/ManagerOrAdminComponentsUI/SectionMiddleUI';
import { Tab } from 'modules/ui/tabs/types';
import { useRole } from 'utils/hooks/useRole';
import { Data, Groups } from 'components/admin/usersPage/elemets';
import { UserIcon } from 'assets/icons/navigations';
import { DraftProjectsSearchList } from 'components/admin/usersPage/elemets/DraftProjectsSearchList';
import { loadFlowDraftInfoAction } from 'store/reducers/projectManager/actions';

export const AdminUsers: FC = () => {
  const dispatch = useAppDispatch();
  const { isViewer } = useRole();

  const { loading, usersList } = useSelector(getUsers);
  const activeUser = useSelector(getActiveUser);
  const activeUserId = activeUser?.id;
  const activeUserLogin = activeUser?.login;

  const { onDeleteModal } = useDeleteUser();

  useEffect(() => {
    dispatch(loadUsersAction());
    dispatch(loadFlowDraftInfoAction());
    dispatch(loadDraftsOfAllUsersAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectedItem = useCallback(
    async ({ id, title }: SelectedUserInterface) => {
      dispatch(changeActiveUserAction({ id, login: title }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const modelMetaDataList = usersList?.map(({ id, login }) => ({
    items: [],
    id: id,
    title: login,
    type: login,
  }));

  const onCloseCreateUserModal = () => dispatch(closeModalAction(createUser));

  const onCreateUserModal = useCallback(() => {
    dispatch(
      openModalTypedAction({
        Component: CreateUserModal,
        componentProps: {
          onClose: onCloseCreateUserModal,
        },
        modalSettings: {
          position: 'static',
          width: '320px',
          headerText: 'Создание пользователя',
        },
        name: createUser,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCloseCreateUserModal]);

  const tabs: Tab[] = [
    {
      content: 'Данные',
      prompt: 'Данные',
      type: 'text',
      id: 'data',
      Component: Data,
    },
    {
      content: 'Черновики',
      prompt: 'Черновики',
      type: 'text',
      id: 'draftFlow',
      Component: DraftProjectsSearchList,
    },
    {
      content: 'Подключения',
      prompt: 'Подключения',
      type: 'text',
      id: 'sources',
      Component: Sources,
    },
    {
      content: 'Потоки',
      prompt: 'Потоки',
      type: 'text',
      id: 'flows',
      Component: Flows,
    },
    {
      content: 'Группы',
      prompt: 'Группы',
      type: 'text',
      id: 'groups',
      Component: Groups,
    },
  ].filter(({ Component }) => Component) as Tab[];

  useEffect(() => {
    if (!activeUserId && usersList.length > 0) void onSelectedItem({ id: usersList[0]?.id, title: usersList[0]?.login });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewer, usersList]);

  return (
    <LoadLayoutAdmin>
      <Body>
        <UniversalLeftBarList
          buttonTitle="Создать пользователя"
          disabledMobileVersion
          data={modelMetaDataList}
          activeTitle={activeUserLogin}
          activeId={activeUserId}
          loadingList={loading}
          disabledAdditionalField
          onSelectedItem={onSelectedItem}
          onDeleteItem={onDeleteModal}
          onCreateItem={onCreateUserModal}
        />

        {activeUserLogin && activeUserId && (
          <SectionMiddleUI
            tabs={tabs}
            title={activeUserLogin}
            id={activeUserId}
            onEditModal={() => {}}
            onDeleteModal={onDeleteModal}
            isViewer={isViewer}
            isAccessControl
            HeaderIcon={UserIcon}
            disabledEdit
            disabledMobileVersion
          />
        )}
      </Body>
    </LoadLayoutAdmin>
  );
};

const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background: ${`var(${ColorVarsEnum.Level_5_application})`};
  flex-wrap: nowrap;
`;
