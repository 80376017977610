import React, { useCallback } from 'react';
import { ModalComponentType } from 'store/reducers/modals/types';
import { closeConfirmationModalAction } from 'store/reducers/modals/actions';
import { useAppDispatch } from 'store';
import {
  createProjectVersionAnotherAction,
  loadDraftFlowAction,
  loadFlowAction,
  loadFlowDraftInfoAction,
} from 'store/reducers/projectManager/actions';
import { useSelector } from 'react-redux';
import { getActiveFlowId, getFlowDraftInfo } from 'store/reducers/projectManager/getters';
import { CreateCopyOfVersionModalUI } from 'modules/ui/ManagerOrAdminComponentsUI/ModalsUI/CreateCopyOfVersionModalUI';
import Snackbar from 'services/Snackbar';

interface CreateCopyOfVersionModalProps {
  projectId: string;
  projectName: string;
  dateTimeHistory: string;
}

export const CreateCopyOfVersionModal: ModalComponentType<CreateCopyOfVersionModalProps> = ({
  onClose,
  projectId,
  projectName,
  dateTimeHistory,
}) => {
  const dispatch = useAppDispatch();

  const { id: flowId } = useSelector(getActiveFlowId);
  const flowDraftInfo = useSelector(getFlowDraftInfo);
  const flowDraftId = flowDraftInfo?.id;

  const onCreateProjectVersionAnother = useCallback(
    async ({ renameValue, runImport }: { renameValue: string; runImport: boolean }) => {
      if (!renameValue) {
        return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
      }

      try {
        let flowIdToUse = flowId;

        if (!flowIdToUse) {
          const { id } = await dispatch(loadFlowDraftInfoAction()).unwrap();
          flowIdToUse = id;
        }

        const projectVersionCreationParams = {
          projectId,
          name: renameValue,
          flowId: flowIdToUse,
          dateTimeHistory,
          runImport,
        };

        const actionCreateProjectVersion = await dispatch(
          createProjectVersionAnotherAction(projectVersionCreationParams),
        ).unwrap();

        if (actionCreateProjectVersion) {
          flowId !== flowDraftId ? await dispatch(loadFlowAction(flowId)) : await dispatch(loadDraftFlowAction());
          dispatch(closeConfirmationModalAction());
          onClose();
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowId, projectId, flowDraftId, dateTimeHistory, onClose],
  );

  return (
    <CreateCopyOfVersionModalUI
      onRestoreVersionModal={onCreateProjectVersionAnother}
      onClose={onClose}
      projectName={projectName}
    />
  );
};
