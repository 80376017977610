import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ServerCode, serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import { hasAccessToProject, loadRolesListEnum, loadUser, logOut, logOutFromAllMySession, signIn } from 'store/reducers/auth/api';
import { ActionsTypes, IRolesList, IUser } from './types';
import {
  getRolesListLoaded,
  getRolesListTimestampState,
  getToken,
  getUserLoaded,
  getUserTimestampState,
} from 'store/reducers/auth/index';
import { TState } from 'store/index';
import { isDayPassed } from 'utils/dates';
import { authService } from 'services/AuthServices';
import { RoutesURL } from 'constants/Routes';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { NoopValueType } from 'types/global';
import { KeysLocalStorage } from 'constants/LocalStorage';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');
  return rejectWithValue(errorMessage);
};

export const logoutFromAllMySessionAction = createAsyncThunk(
  ActionsTypes.LOGOUT_FROM_ALL_MY_SESSION,
  async (_, { rejectWithValue }) => {
    try {
      const response = await logOutFromAllMySession();
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.message);
    }
  },
);

export const login = createAsyncThunk<
  string | null,
  FastBoard.API.AuthGetTokenDTO & { navigate: NavigateFunction; setError: NoopValueType<boolean> }
>(ActionsTypes.LOGIN, async ({ navigate, setError, ...connectionData }, { rejectWithValue, dispatch }) => {
  try {
    const response = await signIn(connectionData);
    authService.signIn(response.data);

    const lastLocal = localStorage.getItem(KeysLocalStorage.lastLocal);
    const lastProjectId = localStorage.getItem(KeysLocalStorage.lastProjectId);

    if (lastProjectId && lastLocal) {
      try {
        const { data } = await hasAccessToProject({ projectId: lastProjectId });

        if (data.projectAccess) {
          navigate(lastLocal);
        } else {
          Snackbar.show('У вас недостаточно прав', 'error');
          navigate(RoutesURL.projectsList);
        }
      } catch (error) {
        navigate(RoutesURL.projectsList);
      }
    } else if (lastLocal) {
      navigate(lastLocal);
    } else {
      navigate(RoutesURL.projectsList);
    }

    localStorage.removeItem(KeysLocalStorage.lastLocal);
    localStorage.removeItem(KeysLocalStorage.lastProjectId);

    return response.data;
  } catch (err: any) {
    if (err.response.status === 409) {
      const token = err.response.data.token;
      authService.signIn(token);
      const isLogout = await dispatch(logoutFromAllMySessionAction());

      if (isLogout) {
        dispatch(login({ ...connectionData, setError, navigate }));
      }
    } else {
      setError(true);
    }
    rejectWithValue(err?.response?.data?.message);
    return null;
  }
});

export const logout = createAsyncThunk(ActionsTypes.LOGOUT, async (any, { rejectWithValue }) => {
  try {
    await logOut();
    authService.signOut();
    return undefined;
  } catch (err: any) {
    return validateError(err, rejectWithValue);
  }
});

export const getRolesListEnum = createAsyncThunk<IRolesList | void>(
  ActionsTypes.GET_ROLES_LIST_ENUM,
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as TState,
        rolesListLoaded = getRolesListLoaded(state),
        rolesListTimestampState = getRolesListTimestampState(state);

      if (!rolesListLoaded || isDayPassed(rolesListTimestampState)) {
        const response = await loadRolesListEnum();
        return response.data;
      }
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const getUser = createAsyncThunk<IUser | void>(ActionsTypes.GET_USER, async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState() as TState,
      userLoaded = getUserLoaded(state),
      userTimestamp = getUserTimestampState(state),
      userToken = getToken(state);

    if ((!userLoaded || isDayPassed(userTimestamp)) && userToken) {
      const response = await loadUser();
      return response.data;
    }
  } catch (err: any) {
    return validateError(err, rejectWithValue);
  }
});

export const getHasAccessToProject = createAsyncThunk<
  boolean,
  FastBoard.API.ProjectVersionCopyToDTO & { navigate: NavigateFunction }
>(ActionsTypes.GET_HAS_ACCESS_TO_PROJECT, async ({ navigate, projectId }, { rejectWithValue }) => {
  try {
    const response = await hasAccessToProject({ projectId });

    return response.data.projectAccess;
  } catch (err: any) {
    if (err.response.status !== ServerCode.AuthError) {
      validateError(err, rejectWithValue);
      navigate(RoutesURL.projectsList);
    }
    return rejectWithValue([]);
  }
});
