import React, { FC } from 'react';
import { IconProps } from './types';

export const VerticalStart: FC<IconProps> = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.33301 5.33398L18.6663 5.33398"
        stroke={isActive ? '#26A5ED' : '#9DA1AF'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.9998 18.668L11.9998 9.5013M11.9998 9.5013L8.6665 12.4234M11.9998 9.5013L15.3332 12.4234"
        stroke={isActive ? '#26A5ED' : '#9DA1AF'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
