import { ByConnectionIcon, ByFileIcon, ByRestAPIIcon } from 'assets/icons/source';
import { SourcesListData as SourcesListDataUi } from 'modules/ui/SourceList/types';

import { SourcesListData } from 'modules/settingsContainer/SourcesList/types';

export const sourcesIcons = {
  file: ByFileIcon,
  database: ByConnectionIcon,
  rest_api: ByRestAPIIcon,
};

export const getMapSourcesData = (data: SourcesListData[], disabledOnDelete?: boolean): SourcesListDataUi[] => {
  return data.map((item) => {
    const { type } = item;
    const commonProperties = {
      disabledEditButton: type === 'rest_api',
      disabledFooter: type !== 'file',
      disabledOnDelete,
      disabledUpdateButton: type !== 'file',
      Icon: sourcesIcons[type],
      hasError: item.isValid,
    };

    return {
      ...item,
      ...commonProperties,
    };
  });
};
