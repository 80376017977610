import { backgroundLineAndBarValueNameTitle, colorValueNameTitle } from 'constants/global';
import { ColorValuesByThemeType, PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { BooleanSettings } from 'modules/settingsContainer/common/BooleanSettings';
import { ColorBySettings } from 'modules/settingsContainer/common/ColorBySettings';
import { AdditionalIndicatorsSettings } from 'modules/settingsContainer/common/data/AdditionalIndicatorsSettings';
import { DefaultDataSettings } from 'modules/settingsContainer/common/data/DefaultDataSettings';
import { EmptyValuesElementSettings } from 'modules/settingsContainer/common/data/EmptyValuesElementSettings';
import { FormattingSettings } from 'modules/settingsContainer/common/data/FormattingSettings';
import { LimitSettings } from 'modules/settingsContainer/common/data/LimitSettings';
import { LineAndBarElementSettings } from 'modules/settingsContainer/common/data/LineAndBarElementSettings';
import { LineAndBarShowValueSettings } from 'modules/settingsContainer/common/data/LineAndBarShowValueSettings';
import { MetricSettings } from 'modules/settingsContainer/common/data/MetricSettings';
import { FieldSettingsRenderType, MetricsRenderType } from 'modules/settingsContainer/common/data/MetricSettings/types';
import { MinAndMaxSettings } from 'modules/settingsContainer/common/data/MinAndMaxSettings';
import { NameFromDatabaseSettings } from 'modules/settingsContainer/common/data/NameFromDatabaseSettings';
import { ShowSumSettings } from 'modules/settingsContainer/common/data/ShowSumSettings';
import { SqlSettings } from 'modules/settingsContainer/common/data/SqlSettings';
import { TypeOfLineAndBarSettings } from 'modules/settingsContainer/common/data/TypeOfLineAndBarSettings';
import { FictionalMetricField } from 'modules/settingsContainer/common/FictionalMetricField';
import { IndicatorMetricField } from 'modules/settingsContainer/common/IndicatorMetricField';
import { ModelSelectorSettings } from 'modules/settingsContainer/common/ModelSelectorSettings';
import { SettingsFieldEntry } from 'modules/settingsContainer/SettingsFieldEntry';
import {
  onAddNewIncision,
  onAddNewIndicator,
  onChangeEmptyValue,
  onChangeFictionalData,
  onChangeIncisionCustomRequest,
  onChangeIncisionFieldName,
  onChangeIncisionName,
  onChangeIncisionNameFromDataBase,
  onChangeIndicatorCustomRequest,
  onChangeIndicatorFormatting,
  onChangeIndicatorName,
  onChangeIndicatorNameFromDataBase,
  onDeleteIncision,
  onDeleteIndicator,
  onLimitChange,
  onModelIdChange,
  onSqlSettingsSave,
} from 'modules/visualisations/common/onChangeFunctions';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import {
  onChangeAdditionalIndicators,
  onChangeElementSettings,
  onChangeShowValue,
  onColorBySettingsChange,
  onColorByValueSettingsChange,
  onColorIncisionChange,
  onColorIndicatorChange,
  onMinAndMaxAdditionalChange,
  onMinAndMaxChange,
  onMoveIncision,
  onMoveIndicator,
  onRotateChange,
  onShowSumChange,
  onTypeChange,
} from 'modules/visualisations/LineAndBar/settings/DataTab/constants';
import { BarTypesEnum } from 'modules/visualisations/LineAndBar/visualisation/constants';
import React from 'react';
import {
  defaultLineAndBarDataSettings,
  defaultLineAndBarViewSettings,
  defaultSqlDataSettings,
  getVisualisationFieldName,
} from 'store/reducers/visualisations/constants';
import { LineAndBarIncisionInterface, LineAndBarIndicatorInterface } from 'store/reducers/visualisations/types';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import { getIncisionAndIndicatorsCompletions } from 'utils/sqlSettings';

export const DataTab = () => {
  const { dataSettings, codeEditorData, viewSettings, astOfVisualisation } = useGetActiveVisualisationSettings({
    defaultData: defaultLineAndBarDataSettings,
    defaultView: defaultLineAndBarViewSettings,
    defaultSqlData: defaultSqlDataSettings,
  });

  const { isRealData, barType, activeIncisionId } = dataSettings;

  const { tableFields, controls, onMetricClick, onSetupClick, setupIsOpen, metricIsSelected, modelMetaData } =
    useDataSettingsMetric(dataSettings.modelId);

  const incisionRender: MetricsRenderType<LineAndBarIncisionInterface> = ({ metrics }) => (
    <>
      {metrics.map(({ fieldName, name, fictionalData, id, colors, settings: { nameFromDatabase, customRequest } }) => {
        const onUpClick = () => onMoveIncision(dataSettings, id, 'up');
        const onDownClick = () => onMoveIncision(dataSettings, id, 'down');
        const onChangeColors = (colors: PaletteValuesByThemeType | null) => {
          onColorIncisionChange(dataSettings, colors, id);
        };

        return (
          <SettingsFieldEntry
            setupIsOpen={setupIsOpen(id)}
            onSetupClick={onSetupClick(id)}
            onClick={onMetricClick(id)}
            isSelected={metricIsSelected(id)}
            isActive={id === activeIncisionId}
            fieldValue={getVisualisationFieldName({ nameFromDatabase, fieldName, name })}
            canChangeField={!nameFromDatabase}
            onFieldChange={(name) => onChangeIncisionName(dataSettings, name, id)}
            onDownClick={onDownClick}
            onUpClick={onUpClick}
            onChangeColors={onChangeColors}
            colorsValue={colors}
            colorPickerType="palette"
            key={id}
          >
            <FictionalMetricField
              onCustomRequestChange={(customRequest) => onChangeIncisionCustomRequest(dataSettings, customRequest, id)}
              customRequest={customRequest || ''}
              disabled={!!customRequest}
              isRealData={isRealData}
              options={tableFields}
              value={{ fictionalData, fieldName }}
              onChange={({ fictionalData, fieldName }) => {
                fieldName && onChangeIncisionFieldName(dataSettings, fieldName || '', id);
                fictionalData && onChangeFictionalData(dataSettings, fictionalData, id);
              }}
              modelMetaData={modelMetaData}
            />
          </SettingsFieldEntry>
        );
      })}
    </>
  );

  const incisionFieldSettingsRender: FieldSettingsRenderType<LineAndBarIncisionInterface> = ({ metric: incision }) => {
    const {
      id,
      settings: {
        emptyValues: { isEmptyValue, value },
        nameFromDatabase,
      },
    } = incision;

    return (
      <>
        <EmptyValuesElementSettings
          switcherValue={isEmptyValue}
          value={value}
          onChange={(value) => onChangeEmptyValue(dataSettings, value, id)}
        />
        <NameFromDatabaseSettings
          value={nameFromDatabase}
          onChange={(nameFromDatabase: boolean) => onChangeIncisionNameFromDataBase(dataSettings, nameFromDatabase, id)}
        />
      </>
    );
  };

  const indicatorRender: MetricsRenderType<LineAndBarIndicatorInterface> = ({ metrics }) => (
    <>
      {metrics.map(({ id, fieldName, name, operationType, color, customRequest, settings: { nameFromDatabase } }) => {
        const onUpClick = () => onMoveIndicator(dataSettings, id, 'up');
        const onDownClick = () => onMoveIndicator(dataSettings, id, 'down');
        const onChangeColors = (colors: ColorValuesByThemeType | null) => onColorIndicatorChange(dataSettings, colors, id);

        return (
          <>
            <SettingsFieldEntry
              setupIsOpen={setupIsOpen(id)}
              onSetupClick={onSetupClick(id)}
              onClick={onMetricClick(id)}
              isSelected={metricIsSelected(id)}
              fieldValue={getVisualisationFieldName({ name, nameFromDatabase, fieldName })}
              canChangeField={!nameFromDatabase}
              onFieldChange={(name) => onChangeIndicatorName(dataSettings, name, id)}
              onUpClick={onUpClick}
              onDownClick={onDownClick}
              onChangeColors={onChangeColors}
              colorsValue={color}
              key={id}
            >
              <IndicatorMetricField
                onCustomRequestChange={(customRequest) => onChangeIndicatorCustomRequest(dataSettings, customRequest, id)}
                customRequest={customRequest}
                options={tableFields}
                dataSettings={dataSettings}
                id={id}
                isRealData={isRealData}
                fieldName={fieldName}
                operationType={operationType}
                modelMetaData={modelMetaData}
              />
            </SettingsFieldEntry>
          </>
        );
      })}
    </>
  );

  const indicatorFieldSettingsRender: FieldSettingsRenderType<LineAndBarIndicatorInterface> = ({ metric: indicator }) => {
    const {
      id,
      settings: { nameFromDatabase, elementSettings, showValue, formatting, additionalIndicators },
    } = indicator;

    return (
      <>
        <NameFromDatabaseSettings
          value={nameFromDatabase}
          onChange={(nameFromDatabase: boolean) => onChangeIndicatorNameFromDataBase(dataSettings, nameFromDatabase, id)}
        />
        <AdditionalIndicatorsSettings
          value={additionalIndicators}
          onChange={(additionalIndicators: boolean) => onChangeAdditionalIndicators(dataSettings, additionalIndicators, id)}
        />
        <LineAndBarElementSettings
          value={elementSettings}
          disabledStack={barType.type === BarTypesEnum.Nominated}
          onChange={(elementSettings) => onChangeElementSettings(dataSettings, elementSettings, id)}
          disabledSwitcher={dataSettings.barType.type === BarTypesEnum.Nominated}
        />
        <LineAndBarShowValueSettings
          type={elementSettings.type}
          indicators={dataSettings.indicators.filter((indicator) => indicator.id === id)}
          value={showValue}
          isRotated={dataSettings.rotateTo90}
          onChange={(showValue) => onChangeShowValue(dataSettings, showValue, id)}
        />
        <FormattingSettings value={formatting} onChange={(value) => onChangeIndicatorFormatting(dataSettings, value, id)} />
      </>
    );
  };

  return (
    <>
      <DefaultDataSettings dataSettings={dataSettings} />
      <BooleanSettings
        titleText="Повернуть на 90°"
        value={dataSettings.rotateTo90}
        onChange={(rotateTo90) => onRotateChange(rotateTo90, viewSettings)}
      />
      <TypeOfLineAndBarSettings value={barType} onChange={(barType) => onTypeChange(barType, dataSettings.indicators)} />
      {false && <ShowSumSettings value={dataSettings.showSum} onChange={onShowSumChange} />}
      <ModelSelectorSettings value={dataSettings.modelId} onChange={onModelIdChange} />
      <MetricSettings
        titleText="Разрезы"
        addButtonText="Добавить разрез"
        fieldSettingsRender={incisionFieldSettingsRender}
        metricRender={incisionRender}
        metrics={dataSettings.incisions}
        onAdd={() => onAddNewIncision(dataSettings, 'lineAndBar')}
        onDelete={(id) => id && onDeleteIncision(dataSettings, id)}
        controls={controls}
      />
      <MetricSettings
        titleText="Показатели"
        addButtonText="Добавить показатель"
        fieldSettingsRender={indicatorFieldSettingsRender}
        metricRender={indicatorRender}
        metrics={dataSettings.indicators}
        onAdd={() => onAddNewIndicator(dataSettings, 'lineAndBar')}
        onDelete={(id) => id && onDeleteIndicator(dataSettings, id)}
        controls={controls}
      />
      <SqlSettings
        astData={astOfVisualisation}
        sqlData={codeEditorData}
        adviceEditorIncision={getIncisionAndIndicatorsCompletions(dataSettings.incisions)}
        adviceEditorIndicator={getIncisionAndIndicatorsCompletions(dataSettings.indicators)}
        onSave={(sqlSettingsChanges) => onSqlSettingsSave(dataSettings, sqlSettingsChanges, 'lineAndBar')}
        modelMetaData={modelMetaData}
      />
      <LimitSettings value={dataSettings.limit} onChange={onLimitChange} />
      <ColorBySettings
        title={backgroundLineAndBarValueNameTitle}
        indicators={dataSettings.indicators.filter(
          ({
            settings: {
              elementSettings: { type },
            },
          }) => type !== 'line',
        )}
        value={dataSettings.colorBySettings}
        onChange={onColorBySettingsChange}
      />
      <ColorBySettings
        title={colorValueNameTitle}
        indicators={dataSettings.indicators}
        value={dataSettings.colorByValueSettings}
        onChange={onColorByValueSettingsChange}
      />
      <MinAndMaxSettings title="Мин/Макс для основных" value={dataSettings.minAndMax} onChange={onMinAndMaxChange} />
      <MinAndMaxSettings
        title="Мин/Макс для дополнительных"
        value={dataSettings.minAndMaxAdditional}
        onChange={onMinAndMaxAdditionalChange}
      />
    </>
  );
};
