import React, { useEffect, useRef, useState } from 'react';
import { Option } from './types';
import { DownIcon, IconType, UpIcon } from 'assets/icons/withContainer';
import { CustomSelectStyled, IconStyled, SelectItemList, SelectList, SelectTitle } from './styles';
import { FlexContainer } from 'styles/FlexContainer';
import { NoopType, NoopValueType } from 'types/global';

export type CustomSelectProps = {
  value: string;
  options: Option[];
  placeholder?: string;
  mode?: 'rows' | 'cells';
  status?: 'default' | 'invalid';
  onChange: NoopValueType<string>;
  onClose?: NoopType;
  IconUp?: IconType;
  IconDown?: IconType;
  needBackground?: boolean;
  width?: number;
};

const CustomSelect = ({
  options,
  placeholder,
  value,
  onChange,
  IconUp = UpIcon,
  IconDown = DownIcon,
  needBackground = true,
  width,
}: CustomSelectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (rootRef.current && event.target instanceof Node && !rootRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <CustomSelectStyled ref={rootRef} needBackground={needBackground} width={width}>
      <FlexContainer alignItems="center" cursor="pointer" width="100%" justifyContent="space-between" onClick={toggleDropdown}>
        <SelectTitle tabIndex={0}>{value || placeholder}</SelectTitle>
        <IconStyled>{isOpen ? <IconUp /> : <IconDown />}</IconStyled>
      </FlexContainer>
      {isOpen && (
        <SelectList>
          {options.map(({ value, title, disabled }) => (
            <SelectItemList
              key={`${title}${value}`}
              onClick={() => {
                if (!disabled) {
                  onChange(value);
                  setIsOpen(false);
                }
              }}
              tabIndex={0}
              aria-disabled={disabled}
              disabled={disabled}
            >
              <span>{title}</span>
            </SelectItemList>
          ))}
        </SelectList>
      )}
    </CustomSelectStyled>
  );
};

export default CustomSelect;
