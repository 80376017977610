import { useSelector } from 'react-redux';
import { getUserRole } from 'store/reducers/auth';
import { ROLES_ENUM } from 'enums/RolesEnum';
import { useMemo } from 'react';

export const useRole = () => {
  const userRole = useSelector(getUserRole);

  const isAdmin = useMemo(() => userRole === ROLES_ENUM.admin, [userRole]);
  const isDeveloper = useMemo(() => userRole === ROLES_ENUM.developer, [userRole]);
  const isAnalyst = useMemo(() => userRole === ROLES_ENUM.analyst, [userRole]);
  const isViewer = useMemo(() => userRole === ROLES_ENUM.viewer, [userRole]);

  return { isAdmin, isDeveloper, isAnalyst, isViewer, isNotViewer: !isViewer };
};
