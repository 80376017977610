import styled from 'styled-components';

export const TextFieldActivatorWrapper = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;

  input {
    cursor: pointer;
    padding-right: 35px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  svg {
    height: 17px;
  }
`;
