import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { SignaturesInterface } from 'store/reducers/visualisations/types';
import {
  signaturesLocationOptions,
  signaturesPositionOptions,
} from 'modules/settingsContainer/common/data/SignaturesSettings/constants';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { OnValueChange } from 'types/global';

type SignaturesSettingsProps = OnValueChange<SignaturesInterface>;

export const SignaturesSettings = ({ value, onChange }: SignaturesSettingsProps) => {
  const { isShow, position, location } = value;

  const activeLocationValue = getActiveRadioValue(signaturesLocationOptions, location);

  const activePositionValue = getActiveRadioValue(signaturesPositionOptions, position);

  return (
    <MainContainerSettings
      titleText="Подписи"
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
    >
      <GroupContainerSettings titleText="Расположение">
        <SettingsRadio
          onChange={({ value: location }) => onChange({ ...value, location: location as SignaturesInterface['location'] })}
          activeValue={activeLocationValue}
          options={signaturesLocationOptions}
        />
      </GroupContainerSettings>
      <GroupContainerSettings titleText="Позиционирование">
        <SettingsRadio
          onChange={({ value: position }) => onChange({ ...value, position: position as SignaturesInterface['position'] })}
          activeValue={activePositionValue}
          options={signaturesPositionOptions}
        />
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
