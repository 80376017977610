import axios from 'axios';
import env from 'config/settings';
import { authService } from './AuthServices';
import { refreshToken } from 'store/reducers/auth/api';
import { ServerCode } from 'constants/ServerCode';

const client = axios.create({
  baseURL: `${env.API_HOST}`,
  withCredentials: true,
  responseType: 'json',
});

const initAxios = () => {
  client.defaults.headers.common['X-Auth-Token'] = authService.token;
};

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { status } = error.response;

    if (status === ServerCode.AuthError && authService.token) {
      try {
        const originalRequest = error.config;

        const response = await refreshToken();

        authService.signIn(response.data);
        return axios(originalRequest);
      } catch (errorRefreshToken) {
        authService.signOut();

        return Promise.reject(error);
      }
    }
    if (status !== ServerCode.AuthError) {
      return Promise.reject(error);
    }
  },
);

export const AxiosConfig = { client, initAxios };

export const axiosClient = AxiosConfig.client;
