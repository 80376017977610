import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { backgroundOpacityMixin, bottomLineMixin } from 'constants/styles';
import { FlexContainer } from 'styles/FlexContainer';

export const SourcesContainer = styled.div`
  display: flex;
  flex-direction: column;

  :after:not(:last-child) {
    ${bottomLineMixin}
  }
`;

export const SourcesGroupContainer = styled(FlexContainer)`
  position: relative;
  border-bottom: 1px solid var(${ColorVarsEnum.Level_5});

  :after {
    ${backgroundOpacityMixin}
  }
`;
