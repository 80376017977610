import { TextCarouselAutoPlayInterface } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { OnValueChange } from 'types/global';

type CarouselSettingsProps = OnValueChange<TextCarouselAutoPlayInterface>;

export const CarouselSettings = ({ value, onChange }: CarouselSettingsProps) => {
  const { value: autoPlay, isActive } = value;

  return (
    <MainContainerSettings
      titleText="Слайд-шоу"
      switcherState={isActive}
      switcherChange={() => onChange({ ...value, isActive: !isActive })}
    >
      <GroupContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Интервал"
            name="limit"
            value={autoPlay}
            onChange={(autoPlay) => onChange({ ...value, value: autoPlay })}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
