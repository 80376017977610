import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';

export const loadThemes = (projectId: string): Promise<AxiosResponse<FastBoard.API.ProjectThemesResponseDTO>> =>
  axiosClient.get(`/api/v2/project/${projectId}/themes`);

export const loadActiveTheme = (projectId: string): Promise<AxiosResponse<FastBoard.API.ProjectActiveThemeResponseDTO>> =>
  axiosClient.get(`/api/v2/project/${projectId}/activeThemeId`);

export const loadPaletteLinks = (projectId: string): Promise<AxiosResponse<FastBoard.API.ProjectPaletteLinksResponseDTO>> =>
  axiosClient.get(`/api/v2/project/${projectId}/paletteLinks`);
