import styled from '@emotion/styled';

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 4px;
  gap: 16px;
`;
