import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import React from 'react';
import { titlePositionOptions } from 'modules/settingsContainer/common/HorizontalPositionSettings/constants';
import { OnValueChange } from 'types/global';
import { PositionSettingType } from 'types/store';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';

export interface HorizontalPositionSettingsProps extends OnValueChange<PositionSettingType> {
  titleText: string;
}

export const HorizontalPositionSettings = ({ onChange, titleText, value }: HorizontalPositionSettingsProps) => {
  const activeValue = getActiveRadioValue(titlePositionOptions, value);

  return (
    <GroupContainerSettings titleText={titleText}>
      <SettingsRadio onChange={({ value }) => onChange(value)} activeValue={activeValue} options={titlePositionOptions} />
    </GroupContainerSettings>
  );
};
