import { createSelector } from 'reselect';
import { getState } from 'store/utils';
import { getProjectManagerStore } from 'store/reducers/projectManager/getters';

export const getUsersStore = createSelector(getState, (state) => state.adminUsers);

export const getUsers = createSelector(getUsersStore, (state) => state.users);

export const getUserSources = createSelector(getUsersStore, (state) => state.userSources);

export const getUserGroups = createSelector(getUsersStore, (state) => state.userGroups);

export const getUserFlows = createSelector(getUsersStore, (state) => state.userFlows);

export const getActiveUser = createSelector(getUsersStore, (state) => state.activeUser);

export const getUserDraftProjects = createSelector(getUsersStore, (state) => state.usersDraftProjects);

export const getDraftsOfAllUsers = createSelector(getUsersStore, (state) => state.draftsOfAllUsers);

export const getActiveUserDraftProjectId = createSelector(getUsersStore, (state) => state.activeUserDraftProjectId);

export const getUserDraftShortProject = createSelector(getUsersStore, (state) => state.shortUserDraftProject);

export const getUsersDraftProjectHistoryVersion = createSelector(getUsersStore, (state) => state.usersDraftProjectHistoryVersion);

export const getFlowsAndDraft = createSelector(getProjectManagerStore, (state) => [
  state.flowDraftInfo,
  ...state.flows.flowsList,
]);

export const getAdminFlowsAndDraft = createSelector(getProjectManagerStore, (state) => [
  state.flowDraftInfo,
  ...state.flows.flowsList,
]);
