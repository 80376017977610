import { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ReactChildrenType } from 'types/global';

interface IProps {
  children: ReactChildrenType;
}

export const ElementContainerSettings: FC<IProps> = ({ children }) => {
  return (
    <FlexContainer minHeight="24px" alignItems="center" width="100%">
      {children}
    </FlexContainer>
  );
};
