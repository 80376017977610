import React, { useMemo } from 'react';
import { getFlowData } from './constants';
import { ListBlockUI, ListUIProps } from 'modules/ui/lists/BlockList/ListBlockUI';
import { FlowListInterface } from './types';

export interface FlowListProps {
  data: FlowListInterface[];
  openFlowProject: NonNullable<ListUIProps['onItemClick']>;
  onActiveFlow: NonNullable<ListUIProps['onActiveItem']>;
  activeFlowProject: NonNullable<ListUIProps['activeItem']>;
  onOpenInformationModal: NonNullable<ListUIProps['onOpenInformationModal']>;
  selectedInfoId: NonNullable<ListUIProps['selectedInfoId']>;
  disabledAddCard: NonNullable<ListUIProps['disabledAddCard']>;
  isViewer: NonNullable<ListUIProps['isViewer']>;
  onAddItem: NonNullable<ListUIProps['onAddItem']>;
}

export const FlowList = ({
  data,
  openFlowProject,
  onActiveFlow,
  activeFlowProject,
  onOpenInformationModal,
  disabledAddCard,
  selectedInfoId,
  isViewer,
  onAddItem,
}: FlowListProps) => {
  const processedData = useMemo(() => getFlowData(data), [data]);

  return (
    <ListBlockUI
      data={processedData}
      onItemClick={openFlowProject}
      onOpenInformationModal={onOpenInformationModal}
      onActiveItem={onActiveFlow}
      activeItem={activeFlowProject}
      selectedInfoId={selectedInfoId}
      disabledAddCard={disabledAddCard}
      isViewer={isViewer}
      onAddItem={onAddItem}
    />
  );
};
