import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  copyFlowProjectVersionAnotherAction,
  createFlowProjectVersionAnotherAction,
  loadFlowProjectsAction,
  loadShortFlowProjectAction,
} from 'store/reducers/adminFlows/actions';
import { getFlowsAndDraft } from 'store/reducers/adminFlows/getters';
import { closeConfirmationModalAction, openConfirmationModalAction } from 'store/reducers/modals/actions';
import { useAppDispatch } from 'store';
import { unwrapResult } from '@reduxjs/toolkit';
import { CopyProjectModalComponentUI } from 'modules/ui/ManagerOrAdminComponentsUI/ModalsUI/CopyProjectModalUI';
import { useSelector } from 'react-redux';
import { ModalComponentType } from 'store/reducers/modals/types';
import { FlowType } from './types';
import { RestoreVersionCallbackProps } from 'modules/ui/ManagerOrAdminComponentsUI/ModalsUI/CopyProjectModalUI/types';
import Snackbar from 'services/Snackbar';

interface CopyFlowProjectModalProps {
  projectId: string;
  flowId: string;
  projectName: string;
}

export const CopyFlowProjectModal: ModalComponentType<CopyFlowProjectModalProps> = ({
  onClose,
  projectId,
  projectName,
  flowId,
}) => {
  const dispatch = useAppDispatch();

  const flowsAndDraft = useSelector(getFlowsAndDraft);

  const flowsItems = useMemo(
    () => flowsAndDraft?.map((flow) => ({ name: flow?.id || '', value: flow?.name || '' })),
    [flowsAndDraft],
  );

  const [selectFlows, setSelectFlows] = useState<FlowType>(flowsItems[0]);
  const [flowProjectsList, setFlowProjectsList] = useState<FlowType[]>([]);

  const selectFlowsMame = selectFlows?.name;
  const selectFlowName = selectFlows?.name;

  const getFlowData = useCallback(async () => {
    if (!selectFlowName) return;

    try {
      const actionResult = await dispatch(loadShortFlowProjectAction(selectFlowName));
      const unwrappedResult = unwrapResult(actionResult);
      const formattedFlowList = unwrappedResult.map((flow) => ({ name: flow.id, value: flow.title }));

      setFlowProjectsList(formattedFlowList);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFlowName]);

  useEffect(() => {
    if (selectFlowName) {
      void getFlowData();
    }
  }, [getFlowData, projectId, selectFlowName]);

  const onCopyProjectVersionAnother = useCallback(
    async ({
      replaceExistingChecked,
      selectProjectId,
      renameValue,
      runImport,
    }: {
      replaceExistingChecked: boolean;
      selectProjectId?: string;
      renameValue: string;
      runImport: boolean;
    }) => {
      try {
        let actionCopyProjectVersion;
        let actionCreateProjectVersion;

        if (!replaceExistingChecked && selectFlowsMame) {
          actionCreateProjectVersion = await dispatch(
            createFlowProjectVersionAnotherAction({ projectId, name: renameValue, flowId: selectFlowsMame, runImport }),
          ).unwrap();
        }

        if (replaceExistingChecked && selectProjectId) {
          actionCopyProjectVersion = await dispatch(
            copyFlowProjectVersionAnotherAction({ projectFromId: projectId, projectToId: selectProjectId, runImport }),
          ).unwrap();
        }

        if (actionCreateProjectVersion || actionCopyProjectVersion) {
          await dispatch(loadFlowProjectsAction({ flowId: flowId }));
          dispatch(closeConfirmationModalAction());
          onClose();
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectFlowsMame, projectId, flowId, onClose],
  );

  const hasName = useCallback(
    (renameValue: string) => flowProjectsList.find(({ value }) => value === renameValue),
    [flowProjectsList],
  );

  const onRestoreVersionModal = useCallback(
    async ({ renameValue, replaceExistingChecked, selectProject, flowsName, runImport }: RestoreVersionCallbackProps) => {
      if (!flowsName) {
        return Snackbar.show('Поле «Поток» обязательно для заполнения', 'error');
      }

      if (replaceExistingChecked && !selectProject) {
        return Snackbar.show('Поле «Проекты» обязательно для заполнения', 'error');
      }

      if (!replaceExistingChecked && !renameValue) {
        return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
      }

      if (!replaceExistingChecked && hasName(renameValue)) {
        return Snackbar.show('Проект с таким именем уже существует', 'error');
      }

      if (replaceExistingChecked) {
        dispatch(
          openConfirmationModalAction({
            confirmationButtonText: 'Да',
            cancelButtonText: 'Нет',
            subTitleText: `Действительно перезаписать проект «${selectProject?.value}»?`,
            onConfirm: () =>
              onCopyProjectVersionAnother({
                selectProjectId: selectProject?.name,
                renameValue,
                replaceExistingChecked,
                runImport,
              }),
            titleText: 'Перезапись проекта',
            width: '320px',
            onCancel: () => {},
          }),
        );
      }

      if (!replaceExistingChecked) {
        void (await onCopyProjectVersionAnother({
          selectProjectId: selectProject?.name,
          renameValue,
          replaceExistingChecked,
          runImport,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasName, onCopyProjectVersionAnother],
  );

  return (
    <CopyProjectModalComponentUI
      titleFlow="В поток"
      projectName={projectName}
      onClose={onClose}
      flowsItems={flowsItems}
      projectsItems={flowProjectsList}
      onSelectFlows={setSelectFlows}
      flows={selectFlows}
      onRestoreVersionModal={onRestoreVersionModal}
    />
  );
};
