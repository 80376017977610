import styled from 'styled-components';
import { contentPadding, tableListWidth } from 'pages/Models/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const ModelsBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

export const TableListWrapper = styled.div`
  height: 100%;
  padding: ${contentPadding};
  width: ${tableListWidth};
  flex: 0 0 ${tableListWidth};
  border-right: 1px solid var(${ColorVarsEnum.Level_5});
  overflow-y: auto;
`;

export const ModelsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
`;
