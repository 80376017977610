import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { MainContainerSettings } from 'modules/settingsContainer';
import React from 'react';
import { codeEditorThemeOptions } from 'modules/workspace/components/panelSettingsApp/tabsContent/ThemesTab/CodeEditorThemeSettings/constants';
import { OnValueChange } from 'types/global';
import { ThemeItemInterface } from 'store/reducers/themes/types';

type CodeEditorThemeSettingsProps = OnValueChange<ThemeItemInterface['codeEditorTheme']>;

export const CodeEditorThemeSettings = ({ onChange, value }: CodeEditorThemeSettingsProps) => {
  return (
    <MainContainerSettings titleText="Тема для редактора кода">
      <SettingsRadio
        onChange={({ value }) => onChange(value)}
        activeValue={getActiveRadioValue(codeEditorThemeOptions, value)}
        options={codeEditorThemeOptions}
      />
    </MainContainerSettings>
  );
};
