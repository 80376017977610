import { TableView, TableViewProps } from 'components/contents/Models/TableView';
import React, { useEffect } from 'react';
import { StyledBoardAreaContainer } from 'modules/models/components/BoardAreaContainter/styles';
import { IdInterface, PositionConfigInterface } from 'types/store';
import { NoopValueType } from 'types/global';
import { ACTIVE_MODEL_TABLE_AREA_CLASS } from 'modules/workspace/constans';
import { RndDragCallback } from 'react-rnd';
import { BezierItem } from 'modules/ui/blocks/BezierBoard';

interface BoardAreaContainerProps extends TableViewProps, IdInterface {
  config: PositionConfigInterface;
  onPositionChange: NoopValueType<PositionConfigInterface>;
  onActiveChange: NoopValueType<boolean>;
  scale: number;
}

export const BoardAreaContainer = ({
  scale,
  config,
  onPositionChange,
  onActiveChange,
  isActive,
  alias,
  id,
  ...tableViewProps
}: BoardAreaContainerProps) => {
  const onContainerClick = () => !isActive && onActiveChange(true);

  const onDragStop: RndDragCallback = (e, d) => {
    onPositionChange({ x: d.x, y: d.y });
  };

  useEffect(() => {
    document.getElementById(alias)?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const callback = (e: MouseEvent) => {
      const container = document.getElementById(alias);
      const menu = document.getElementById(ACTIVE_MODEL_TABLE_AREA_CLASS);
      const clickedElement = document.getElementsByClassName(ACTIVE_MODEL_TABLE_AREA_CLASS)[0];
      const isClickToSelectorItem = document.getElementsByClassName('MuiPaper-root')[0];

      const isClickToItem = [container, menu, clickedElement].some((item) => item && item.contains(e.target as Node));

      if (!isClickToSelectorItem && !isClickToItem) {
        onActiveChange(false);
      }
    };
    document.addEventListener('mousedown', callback);
    return () => {
      document.removeEventListener('mousedown', callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledBoardAreaContainer
      scale={scale}
      id={id}
      bounds="parent"
      dragGrid={[0.1, 0.1]}
      onClick={onContainerClick}
      onFocus={onContainerClick}
      position={config}
      onDragStop={onDragStop}
      disableDragging={!isActive}
      enableResizing={false}
      className={isActive ? ACTIVE_MODEL_TABLE_AREA_CLASS : ''}
    >
      <BezierItem id={alias}>
        <TableView isActive={isActive} alias={alias} {...tableViewProps} />
      </BezierItem>
    </StyledBoardAreaContainer>
  );
};
