import styled, { css } from 'styled-components';
import { defaultSelectedListItem } from 'constants/styles';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export interface HistoryVersionItemProps {
  activeSelected?: boolean;
}

export const HistoryVersionItem = styled.div<HistoryVersionItemProps>`
  position: relative;
  min-height: 41px;
  display: flex;
  width: 100%;
  padding: 0 12px;
  border-bottom: 1px solid var(${ColorVarsEnum.Level_5});
  cursor: pointer;
  align-items: center;
  z-index: 0;
  ${({ activeSelected }) =>
    activeSelected &&
    css`
      :after {
        ${defaultSelectedListItem}
      }
    `};

  :hover {
    background-color: var(${ColorVarsEnum.Level_1_hover_btn});
  }
`;
