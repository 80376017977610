import {
  ColorAndImageByInterfaceType,
  DefaultPropertiesInterface,
  TableDataSettings,
  TableIncisionInterface,
} from 'store/reducers/visualisations/types';
import { SortingInterface, SortType } from 'modules/visualisations/Table/visualisation/types';
import { MoveToType } from 'utils/utils';
import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';

export type OnChangeValue<T> = (dataSettings: TableDataSettings, value: T, id: string) => void;
export type OnChangeValueIncisions<T> = (
  dataSettings: TableDataSettings,
  value: T,
  id: string,
  incisionsKey: IncisionsKeysTypes,
) => void;

export interface SortChangeInterface {
  dataSettings: TableDataSettings;
  columnName: string;
  type: SortType;
  sortItsColumn: SortingInterface;
  isActive?: boolean;
}

export interface onStepIncisionClickWithGroupingInterface {
  dataSettings: TableDataSettings;
  hasIncisionsGrouping: TableIncisionInterface[];
  id: string;
  moveTo: MoveToType;
}

export interface CommonSettingsInterface {
  properties: DefaultPropertiesInterface;
  onChange: any;
  options: SettingsRadioItem<ColorAndImageByInterfaceType>[];
  id: string;
}

export type IncisionsKeysTypes = 'incisions' | 'incisionsInHeader';

export enum IncisionsKeysEnum {
  Incisions = 'incisions',
  IncisionsInHeader = 'incisionsInHeader',
}
