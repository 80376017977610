import { SortType } from 'components/console/elements/SourcesConnection/types';
import { Option } from 'components/shared/ui/menu/MenuIconList/types';

export const alreadyAddedTitle = 'В проекте';
export const otherTitle = 'Все подключения';

export const filterOptions = [
  { meta: 'type', value: 'database', title: 'Все базы данных' },
  { meta: 'type', value: 'file', title: 'Все файлы' },
  { meta: 'driver', value: 'clickHouse', title: 'ClickHouse' },
  { meta: 'driver', value: 'postgres', title: 'PostgresSQL' },
  { meta: 'driver', value: 'ms', title: 'MSSQL' },
  { meta: 'driver', value: 'my', title: 'MYSQL' },
  { meta: 'driver', value: 'oracle', title: 'ORACLE' },
  { meta: 'driver', value: 'hive', title: 'HIVE' },
  { meta: 'driver', value: 'qvd', title: 'QVD' },
  { meta: 'driver', value: 'xlsx', title: 'XLSX/XLS' },
  { meta: 'driver', value: 'csv', title: 'CSV' },
  { meta: 'driver', value: 'txt', title: 'TXT' },
  { meta: 'driver', value: 'json', title: 'JSON' },
  { meta: 'driver', value: 'xml', title: 'XML' },
];

export const options: Option<SortType>[] = [
  { name: 'Алфавит А-Я', value: 'ascendingAlphabet' },
  { name: 'Алфавит Я-А', value: 'descendingAlphabet' },
  { name: 'Дата по возрастанию', value: 'dataSortedAscending' },
  { name: 'Дата по убыванию', value: 'dataSortedDescending' },
];
