import { GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';
import React from 'react';
import { IndentStyledComponent } from 'shared/ui/IndentStyledComponent';
import { TableHeaderSettingsInterface } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import SubSection from 'shared/ui/subSection';

type HeaderStylesSettingsProps = OnValueChange<TableHeaderSettingsInterface>;
export const HeaderStylesSettings = ({ value, onChange }: HeaderStylesSettingsProps) => {
  const { isShow, properties, overfill, headerBeatLine, hasIndentation, indentation } = value;

  return (
    <MainContainerSettings
      titleText="Шапка"
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
    >
      <SubSection>
        <PropertiesSettings
          title="Свойства ячейки"
          isMainContainerColorSettings
          isMainContainerSettings
          disabledComponentColorByValue
          disabledFontColorByBlock
          disabledPadding
          value={properties}
          onChange={(properties) => onChange({ ...value, properties })}
          disabledOpacity
        />
      </SubSection>
      <SubSection>
        <GroupContainerSettings
          titleText="Заливка фона поверх столбцов"
          switcherState={overfill}
          switcherChange={() => onChange({ ...value, overfill: !overfill })}
        />
      </SubSection>
      <SubSection>
        <GroupContainerSettings
          titleText="Отбивка линией"
          switcherState={headerBeatLine.isBeat}
          switcherChange={() => onChange({ ...value, headerBeatLine: { ...headerBeatLine, isBeat: !headerBeatLine.isBeat } })}
          color={headerBeatLine.color}
          colorChange={(color) => onChange({ ...value, headerBeatLine: { ...headerBeatLine, color } })}
          countUnit={headerBeatLine.countUnit}
          thicknessBorderChange={(thickness) =>
            onChange({
              ...value,
              headerBeatLine: { ...headerBeatLine, countUnit: thickness },
            })
          }
        />
      </SubSection>
      <SubSection>
        <GroupContainerSettings
          titleText="Отступы в ячейках"
          switcherState={hasIndentation}
          switcherChange={() => onChange({ ...value, hasIndentation: !hasIndentation })}
        >
          <IndentStyledComponent
            value={indentation}
            onChange={(indentation) => onChange({ ...value, indentation })}
            disabledHorizontal
          />
        </GroupContainerSettings>
      </SubSection>
    </MainContainerSettings>
  );
};
