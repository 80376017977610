import React, { useCallback, useMemo } from 'react';
import { ModalComponentType } from 'store/reducers/modals/types';
import { closeConfirmationModalAction, openConfirmationModalAction } from 'store/reducers/modals/actions';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { MoveProjectModalUI } from 'modules/ui/ManagerOrAdminComponentsUI/ModalsUI/MoveProjectModalUI';
import Snackbar from 'services/Snackbar';
import { FlowType } from './types';
import { getDraftsOfAllUsers } from 'store/reducers/adminUsers/getters';
import {
  deleteByIdUserDraftProjectAction,
  loadShortDraftUserProjectAction,
  moveUserDraftProjectAction,
} from 'store/reducers/adminUsers/actions';

interface MoveDraftUserProjectModalProps {
  projectId: string;
  projectName: string;
  activeFlowId: string;
}

export const MoveDraftUserProjectModal: ModalComponentType<MoveDraftUserProjectModalProps> = ({
  onClose,
  projectId,
  activeFlowId,
  projectName,
}) => {
  const dispatch = useAppDispatch();
  const { draftsOfAllUsersList } = useSelector(getDraftsOfAllUsers);

  const flowsItems = useMemo(() => {
    return draftsOfAllUsersList?.reduce<FlowType[]>((acc, flow) => {
      if (flow?.id !== activeFlowId) {
        acc.push({
          name: flow?.id || '',
          value: flow?.name || '',
        });
      }
      return acc;
    }, []);
  }, [draftsOfAllUsersList, activeFlowId]);

  const onMoveProject = useCallback(
    async (selectFlowsName: string) => {
      try {
        const moveProjectRes = await dispatch(moveUserDraftProjectAction({ projectId, flowId: selectFlowsName })).unwrap();

        if (moveProjectRes) {
          dispatch(deleteByIdUserDraftProjectAction(projectId));
          dispatch(closeConfirmationModalAction());
          onClose();
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, onClose],
  );

  const onMoveProjectModal = useCallback(
    async ({ value, name }: FlowType) => {
      if (!name) {
        return Snackbar.show('Поле «Поток» обязательно для заполнения', 'error');
      }

      const moveFLowActions = await dispatch(loadShortDraftUserProjectAction(name)).unwrap();
      const hasName = moveFLowActions?.find(({ title }) => title === projectName);

      if (hasName) {
        return Snackbar.show('У пользователя уже есть проект с таким названием. Измените название и повторите попытку', 'error');
      }

      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Да',
          cancelButtonText: 'Нет',
          subTitleText: `Действительно переместить проект «${projectName}» в черновики пользователя ${value}?`,
          onConfirm: () => onMoveProject(name),
          titleText: 'Перемещение проекта',
          width: '320px',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onMoveProject, projectName],
  );

  return (
    <MoveProjectModalUI
      titleSelect="В черновики пользователя"
      onMoveProjectModal={onMoveProjectModal}
      onClose={onClose}
      flowsItems={flowsItems}
    />
  );
};
