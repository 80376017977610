import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialHistoryStoreState } from 'store/reducers/history/constants';
import {
  SetIsAnotherPageActionPayload,
  SetRedoHistoryStepPayload,
  SetUndoHistoryStepPayload,
  StepAddRedoPayload,
  StepAddUndoPayload,
} from './types';
import { setSliceFn } from 'constants/store';

export const historySlice = createSlice({
  name: 'history',
  initialState: initialHistoryStoreState,
  reducers: {
    addUndoHistoryStep: (state, { payload: { undo } }: PayloadAction<StepAddUndoPayload>) => {
      state.undo = [...state.undo, undo];
    },

    addRedoHistoryStep: (state, { payload: { redo } }: PayloadAction<StepAddRedoPayload>) => {
      state.redo = [...state.redo, redo];
    },

    clearHistory: (state) => {
      state.undo = [];
      state.redo = [];
      state.isAnotherPage = {
        isAnother: false,
        redirectPageId: '',
      };
    },

    setUndoHistoryStep: (state, { payload: { undo } }: PayloadAction<SetUndoHistoryStepPayload>) => {
      state.undo = undo;
    },

    setRedoHistoryStep: (state, { payload: { redo } }: PayloadAction<SetRedoHistoryStepPayload>) => {
      state.redo = redo;
    },

    setIsAnotherPage: (state, { payload: { value } }: PayloadAction<SetIsAnotherPageActionPayload>) => {
      state.isAnotherPage = value;
    },

    setSlice: setSliceFn,
  },
});

export const { addUndoHistoryStep, addRedoHistoryStep, setRedoHistoryStep, setUndoHistoryStep, setIsAnotherPage, setSlice } =
  historySlice.actions;
export default historySlice.reducer;
