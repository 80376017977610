import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import { PageIdInterface, ProjectIdWithType } from 'types/store';
import { AxiosRequestConfig } from 'axios/index';

export const loadLayersByPageId = (
  { projectId, pageId }: ProjectIdWithType<PageIdInterface>,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<FastBoard.API.LayersV3>> =>
  axiosClient.get(`/api/v3/project/${projectId}/page/${pageId}/layers`, config);
