import React, { useCallback, useState } from 'react';
import { Button, TextField } from 'modules/ui';
import { useAppDispatch } from 'store';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { getActiveGroup } from 'store/reducers/adminGroups/getters';
import { Form } from './styles';
import { createGroupUsersAccessAction, loadGroupAccessUsersAction } from 'store/reducers/adminGroups/actions';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { MultiSelect, MultiSelectType } from 'modules/ui/MultiSelect';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { UsersAccessFormType } from 'components/admin/groups/elements/Users/types';
import { ModalComponentType } from 'store/reducers/modals/types';
import { GroupAccessUserListInterface } from 'store/reducers/adminGroups/types';

interface UserAccessModalProps {
  multiSelectData: MultiSelectType<GroupAccessUserListInterface>[];
}

export const UsersAccessModal: ModalComponentType<UserAccessModalProps> = ({ onClose, multiSelectData }) => {
  const dispatch = useAppDispatch();

  const activeGroup = useSelector(getActiveGroup);
  const activeGroupId = activeGroup?.id;
  const activeGroupName = activeGroup?.title;

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<UsersAccessFormType>({
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<UsersAccessFormType> = useCallback(
    async ({ users }) => {
      try {
        if (!isDirty || !activeGroupId) {
          return null;
        }
        setLoading(true);

        const response = await dispatch(
          createGroupUsersAccessAction({
            users,
            groupId: activeGroupId,
          }),
        );

        if (response) {
          dispatch(loadGroupAccessUsersAction(activeGroupId));
        }

        onClose();
      } catch (e) {
        return;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeGroupId, isDirty, onClose],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={loading} />

      <TextField name="group" label="Группа" needBackground={false} value={activeGroupName} width="100%" disabled />

      <Controller
        name="users"
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange } }) => (
          <MultiSelect
            label="Пользователи"
            noOptionsText="Пользователи не найдены"
            options={multiSelectData}
            onChange={(item) => onChange(item.map((el) => el.value))}
          />
        )}
      />

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Добавить" iconSize="normal" heightSize="normal" type="submit" />
      </FlexContainer>
    </Form>
  );
};
