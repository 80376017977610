import { MOCK_JOIN_TYPE, MOCK_OPERATION_TYPE } from 'constants/Mock';
import { JoinTypeControlValueInterface, RelationControlValueInterface } from 'components/contents/Models/ModelItemControls/types';

export const defaultJoinTypeValue: JoinTypeControlValueInterface = {
  joinType: MOCK_JOIN_TYPE[0].value,
  alias: null,
};

export const defaultRelationValue: RelationControlValueInterface = {
  leftColumn: null,
  operator: MOCK_OPERATION_TYPE[0].value,
  rightColumn: null,
};
