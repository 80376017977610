import React, { FC } from 'react';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Tab } from 'modules/ui/tabs/types';
import { HeaderTitle } from 'modules/ui/title/HeaderTitle';
import { FlexContainer } from 'styles/FlexContainer';
import { NavigationsTabs } from 'modules/ui/tabs/NavigationsTabs';
import { useWindowDimensions } from 'utils/hooks/screenSizeHook';
import { startMobileSize } from 'constants/constants';
import { NoopValueType } from 'types/global';
import { SelectedType } from 'modules/ui/ManagerOrAdminComponentsUI/SectionMiddleUI/types';
import { IconType } from 'assets/icons/withContainer';

type SectionMiddleUIProps = {
  title: string;
  id: string;
  tabs: Tab[];
  onDeleteModal: NoopValueType<SelectedType>;
  onEditModal?: NoopValueType<SelectedType>;
  isViewer?: boolean;
  isAccessControl: boolean;
  disabledEdit?: boolean;
  disabledMobileVersion?: boolean;
  draftProjectId?: string;
  HeaderIcon: IconType;
};

export const SectionMiddleUI: FC<SectionMiddleUIProps> = ({
  title,
  id,
  isViewer = false,
  disabledEdit = false,
  isAccessControl,
  draftProjectId,
  onEditModal,
  onDeleteModal,
  tabs,
  HeaderIcon,
  disabledMobileVersion = false,
}) => {
  const { width } = useWindowDimensions();

  const isDraftAndIsViewer = id === draftProjectId || isViewer;
  const mobileVersion = !disabledMobileVersion ? width <= startMobileSize : false;

  return (
    <>
      <FlexContainer
        width="100%"
        flexDirection="column"
        height="100%"
        backgroundColor={`var(${ColorVarsEnum.Level_5_application})`}
      >
        {!mobileVersion && (
          <FlexContainer padding="20px 32px" height={id === draftProjectId ? '125px' : 'unset'}>
            <HeaderTitle
              onDelete={() => id && onDeleteModal({ id, title })}
              onEdit={() => id && onEditModal && onEditModal({ id, title })}
              title={title}
              Icon={HeaderIcon}
              disabledHover={isDraftAndIsViewer}
              disabledEdit={disabledEdit}
            />
          </FlexContainer>
        )}

        <NavigationsTabs
          disabledTabs={mobileVersion || isDraftAndIsViewer || !isAccessControl}
          tabs={tabs}
          width="100%"
          height="100%"
          overflowBodyY="none"
          bgNavigationsTabs={`var(${ColorVarsEnum.Level_5_application})`}
        />
      </FlexContainer>
    </>
  );
};
