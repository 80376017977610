import React, { useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button, TextField } from 'modules/ui';
import { ModalComponentType } from 'store/reducers/modals/types';
import { NoopValueType } from 'types/global';

interface CopyProjectModalProps {
  onClick: NoopValueType<string>;
  value?: string;
  buttonSaveText?: string;
}

export const NameModal: ModalComponentType<CopyProjectModalProps> = ({ onClose, value, onClick, buttonSaveText }) => {
  const [nameValue, setNameValue] = useState(value || '');

  return (
    <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" height="100%">
      <FlexContainer width="100%" flexDirection="column" height="100%" gap="32px">
        <TextField label="Название" onChange={(e) => setNameValue(e.target.value)} value={nameValue} name="rename" width="100%" />

        <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
          <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={false} onClick={onClose} />
          <Button
            text={buttonSaveText || 'Ок'}
            iconSize="normal"
            needBackground={true}
            heightSize="normal"
            onClick={() => onClick(nameValue.trim())}
          />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
