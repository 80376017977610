import { FlexContainer } from 'styles/FlexContainer';
import { Checkbox } from 'modules/ui';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React, { CSSProperties, ChangeEvent, memo } from 'react';
import { FiltersValuesType } from 'store/reducers/filters/types';
import { areEqual } from 'react-window';

interface RowItemProps {
  index: number;
  style: CSSProperties;
  data: {
    values: FiltersValuesType;
    onAddToSelectedData: (value: string) => (e: ChangeEvent<HTMLInputElement>) => void;
    isLocalChecked: (value: string) => boolean;
  };
}

export const MainValuesRow = memo(({ index, style, data }: RowItemProps) => {
  const { values, onAddToSelectedData, isLocalChecked } = data;
  const currentValue = values[index];
  const { count, value } = currentValue;

  const normalizedValue = value !== '' ? value : 'NULL';
  const key = `${value}_${count}`;

  return (
    <FlexContainer style={style} key={key} justifyContent="space-between" padding="0 5px" alignItems="center">
      <Checkbox
        value={normalizedValue}
        name={normalizedValue}
        id={normalizedValue}
        label={normalizedValue}
        onChange={onAddToSelectedData(value)}
        checked={isLocalChecked(value)}
        padding="8px"
      />
      <PrimaryTextSpan lineHeight="12px" marginBottom="4px">
        {count}
      </PrimaryTextSpan>
    </FlexContainer>
  );
}, areEqual);

MainValuesRow.displayName = 'MainValuesRow';
