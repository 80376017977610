import { DeleteIcon, IconType } from 'assets/icons/withContainer';
import { FlexContainer } from 'styles/FlexContainer';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { NoopType, ReactChildrenType } from 'types/global';
import React, { memo, MouseEventHandler, useLayoutEffect, useRef, useState } from 'react';
import {
  CommonItemWrapper,
  CommonItemWrapperProps,
  IconDeleteWrapper,
  PriorityChangerButtonsWrapper,
} from 'modules/ui/lists/MapList/item/CommonItem/styles';
import { getTitleTextWidth, iconContainerWidth } from 'modules/ui/lists/MapList/item/CommonItem/constants';
import { PriorityChangerButtons } from 'modules/settingsContainer/PriorityChangerButtons';

export interface CommonItemProps extends Partial<CommonItemWrapperProps> {
  title: string;
  titleType?: 'bold' | 'normal';
  Icon?: IconType;
  afterSlot: ReactChildrenType;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onDelete?: NoopType;
  onUp?: NoopType;
  onDow?: NoopType;
  isCheckboxLast?: boolean;
  isShowMoveTo?: boolean;
  activeSelected?: boolean;
}

export const CommonItemComponent = ({
  title,
  titleType = 'bold',
  Icon,
  afterSlot,
  withoutHover = false,
  onClick,
  disabled = false,
  isCheckboxLast = false,
  onDelete,
  isShowMoveTo = false,
  onUp,
  onDow,
  activeSelected,
}: CommonItemProps) => {
  const afterSlotRef = useRef<HTMLDivElement | null>(null);

  const [titleWidth, setTitleWidth] = useState<string>();

  useLayoutEffect(() => {
    setTitleWidth(getTitleTextWidth(afterSlotRef.current?.clientWidth || 0));
  }, [afterSlotRef]);

  return (
    <CommonItemWrapper
      justifyContent={isCheckboxLast ? 'flex-start' : 'space-between'}
      withoutHover={withoutHover}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled && onClick) {
          onClick(e);
        }
      }}
      disabled={disabled}
      activeSelected={activeSelected}
    >
      {Icon && (
        <FlexContainer height="100%" alignItems="center" width={iconContainerWidth}>
          <IconWrapper
            Icon={Icon}
            iconWidth="15px"
            iconHeight="15px"
            containerHeight={iconContainerWidth}
            containerWidth={iconContainerWidth}
            opacity={0}
            cursor={disabled ? 'not-allowed' : 'pointer'}
          />
        </FlexContainer>
      )}
      <FlexContainer order={isCheckboxLast ? 1 : undefined} title={title} alignItems="center" width={titleWidth}>
        <PrimaryTextSpan
          color={`var(${ColorVarsEnum.Level_1})`}
          fontSize="14px"
          fontWeight={titleType}
          lineHeight="17px"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {title}
        </PrimaryTextSpan>
      </FlexContainer>
      <FlexContainer height="100%" alignItems="center" ref={afterSlotRef}>
        {afterSlot}
      </FlexContainer>

      {onDelete && <IconDeleteWrapper onClick={onDelete} iconWidth="20px" iconHeight="20px" Icon={DeleteIcon} />}

      {isShowMoveTo && onUp && onDow && (
        <PriorityChangerButtonsWrapper>
          <PriorityChangerButtons onDownClick={onDow} onUpClick={onUp} />
        </PriorityChangerButtonsWrapper>
      )}
    </CommonItemWrapper>
  );
};

export const CommonItem = memo(CommonItemComponent);
