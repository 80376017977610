import { FastBoardColorIcon } from 'assets/icons/withoutContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ILoginInfo } from 'interface';
import { Button, TextField } from 'modules/ui';
import React, { FC, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { login } from 'store/reducers/auth/actions';
import styled from 'styled-components';
import { PrimaryTextSpan } from 'styles/TextsElements';

const LoginPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [hasError, setError] = useState(false);

  const { handleSubmit, control } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      login: '',
      password: '',
    },
  });

  const onSubmit = useCallback(
    (data: ILoginInfo) => {
      dispatch(login({ login: data.login, password: data.password, navigate, setError }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FastBoardColorIconSVG />
        <Controller
          render={({ field }) => <TextField width="100%" needBackground={true} placeholder="Login" error={hasError} {...field} />}
          name="login"
          control={control}
        />
        <Controller
          render={({ field }) => (
            <TextField needBackground={true} width="100%" type="password" placeholder="Password" error={hasError} {...field} />
          )}
          name="password"
          control={control}
        />
        {hasError && <PrimaryTextSpan color={`var(${ColorVarsEnum.Alert})`}>Неверный логин или пароль</PrimaryTextSpan>}
        <ButtonContainer>
          <Button text="Вход" type="submit" />
        </ButtonContainer>
      </form>
    </Container>
  );
};

export default LoginPage;

const Container = styled.div`
  display: flex;
  height: 100vh;
  background: ${`var(${ColorVarsEnum.Level_5_application})`};
  align-items: center;
  justify-content: center;

  form {
    width: 250px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    height: 600px;
  }
`;

const FastBoardColorIconSVG = styled(FastBoardColorIcon)`
  margin-bottom: 50px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
