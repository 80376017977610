import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import {
  colorSelectorBlockSize,
  colorSelectorSize,
  defaultColorGap,
  defaultColorSize,
  defaultMaxColorInGroup,
  positionCssMapper,
} from 'modules/settingsContainer/ColorPicker/constants';
import { ColorPickerTypesInterface, PositionIndexType } from 'modules/settingsContainer/ColorPicker/types';
import { defaultScrollbarMixin } from 'constants/styles';

export const ColorPickerWrapper = styled.div`
  position: relative;
`;

interface GroupWrapperProps {
  active?: boolean;
}

export const GroupsWrapper = styled.div<GroupWrapperProps>`
  cursor: pointer;
  display: flex;
  flex-direction: column;

  ${({ active }) =>
    active &&
    css`
      background-color: var(${ColorVarsEnum.Level_2_btn});
    `}
`;

interface GroupsProps {
  maxColorInGroup?: number;
  colorSize?: number;
  colorGap?: number;
}

export const Groups = styled.div<GroupsProps>`
  display: flex;
  flex-wrap: wrap;
  width: ${({ maxColorInGroup, colorSize, colorGap }) =>
    `${
      (maxColorInGroup || defaultMaxColorInGroup) * ((colorSize || defaultColorSize) + (colorGap || defaultColorGap)) -
      (colorGap || defaultColorGap)
    }px;`};
  gap: ${({ colorGap }) => `${colorGap || defaultColorGap}px;`};
  padding: 8px;
`;

interface ColorProps {
  color: string;
  active?: boolean;
  colorSize?: number;
}

export const Color = styled.div<ColorProps>`
  height: ${({ colorSize }) => `${colorSize || defaultColorSize}px;`};
  width: ${({ colorSize }) => `${colorSize || defaultColorSize}px;`};
  cursor: pointer;
  background-color: ${({ color }) => color || 'unset'};
  transition: 0.2s;
  border: 2px solid transparent;

  ${({ active }) =>
    active &&
    css`
      border: 2px solid var(${ColorVarsEnum.Level_1});
      transform: scale(1.1);
    `}
`;

export const Palette = styled.div<ColorPickerTypesInterface>`
  display: flex;
  flex-direction: column;
  z-index: 100;
  background-color: var(${ColorVarsEnum.Level_3_menu});
  border: 1px solid var(${ColorVarsEnum.Level_4});
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 5px;
  gap: 5px;
  max-height: 400px;
  overflow: auto;
  ${defaultScrollbarMixin};

  ${({ type }) =>
    type === 'color' &&
    css`
      ${Color} {
        :hover {
          transform: scale(1.3);
        }
      }
    `}

  ${({ type }) =>
    type === 'palette' &&
    css`
      ${GroupsWrapper} {
        :hover {
          background-color: var(${ColorVarsEnum.Level_2_btn});
        }
      }
    `}
`;

interface ColorBlockProps {
  color: string;
  positionIndex: PositionIndexType;
}

export const ColorBlock = styled.div<ColorBlockProps>`
  height: ${colorSelectorBlockSize}px;
  width: ${colorSelectorBlockSize}px;
  position: absolute;
  background-color: ${({ color }) => color || 'unset'};
  ${({ positionIndex }) => positionIndex && positionCssMapper[positionIndex]}
`;

export const ColorSelector = styled.div`
  position: relative;
  overflow: hidden;
  height: ${colorSelectorSize}px;
  width: ${colorSelectorSize}px;
  background-color: unset;
  border: 1px solid ${`var(${ColorVarsEnum.Level_3})`};
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};
`;

export const groupNameTextStyleMixin = css`
  font-weight: normal;
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  transition: all 200ms;
  font-size: 12px;
  line-height: 14px;
  color: var(${ColorVarsEnum.Level_2});
  padding: 2px 4px;
  border: 1px solid transparent;
`;

export const GroupNameTextSpan = styled.span`
  ${groupNameTextStyleMixin};
`;
