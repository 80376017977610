import { ItemsInterface } from 'modules/ui/menu/Menu/types';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import React from 'react';
import { HeaderItem, MenuWrapper } from './styles';

export interface MenuProps<T> {
  activeItemId: T;
  items: ItemsInterface<T>[];
  onItemClick: (key: T) => void;
  disabled?: boolean;
  height?: string;
  fontSize?: string;
}

export const Menu = <T extends string>({
  activeItemId,
  items,
  onItemClick,
  height,
  fontSize,
  disabled = false,
}: MenuProps<T>) => {
  return (
    <MenuWrapper height={height}>
      {!disabled &&
        items.map((item) => {
          const { id, content, type } = item,
            Content = content;
          return (
            <HeaderItem isActive={activeItemId === id} onClick={() => onItemClick(id)} key={id} fontSize={fontSize}>
              {type === 'text' && content}
              {type === 'icon' && (
                <IconWrapper containerHeight="18px" containerWidth="20px" iconWidth="20px" iconHeight="20px" Icon={content} />
              )}
              {type === 'component' && <Content />}
            </HeaderItem>
          );
        })}
    </MenuWrapper>
  );
};
