import { axiosClient } from './ApiClient';

class AuthStorage {
  get token(): string {
    return localStorage.getItem('token') as string;
  }

  get isAuthorized(): boolean {
    return !!this.token;
  }

  signIn = (token: string) => {
    localStorage.setItem('token', token);
    axiosClient.defaults.headers.common['X-Auth-Token'] = token;
  };

  signOut = () => {
    localStorage.removeItem('token');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    axiosClient.defaults.headers.common['X-Auth-Token'] = null;
  };
}

export const authService = new AuthStorage();
