import styled from 'styled-components';
import { defaultScrollbarMixin } from 'constants/styles';

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow-y: auto;

  ${defaultScrollbarMixin};
`;
