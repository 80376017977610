import { AdminSettingsSliceStateInterface } from 'store/reducers/adminSettings/types';

export const initialAdminSettingsStoreState: AdminSettingsSliceStateInterface = {
  versionStrategy: {
    versionStrategyData: null,
    loading: false,
  },
};

/* Action Types */
export enum AdminSettingsActionsTypes {
  LOAD_VERSION_STRATEGY = 'LOAD_VERSION_STRATEGY',
  UPDATE_VERSION_STRATEGY = 'UPDATE_VERSION_STRATEGY',
  SET_VERSION_STRATEGY = 'SET_VERSION_STRATEGY',
}
