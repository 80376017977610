import styled from 'styled-components';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const TooltipModded = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `var(${ColorVarsEnum.Level_4_widget})`,
    border: `1px solid var(${ColorVarsEnum.Level_5})`,
    padding: '8px 12px',
  },
}));
