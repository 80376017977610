import { useEffect, useRef, useState } from 'react';
import { FlowProjectHistoryVersion, FlowProjectInfoInterface } from 'store/reducers/projectManager/types';
import { useAppDispatch } from 'store';
import { unwrapResult } from '@reduxjs/toolkit';
import { loadUserDraftProjectHistoryVersionAction, loadUserDraftProjectInfoAction } from 'store/reducers/adminUsers/actions';

export const useUserDraftProjectInfo = (projectId: string) => {
  const dispatch = useAppDispatch();
  const prevProjectIdRef = useRef<string | null>(null);

  const [infoProject, setInfoProject] = useState<FlowProjectInfoInterface | null>(null);
  const [loadingInfoProject, setInfoProjectLoading] = useState(true);
  const [historyVersionProject, setHistoryVersionProject] = useState<FlowProjectHistoryVersion[]>([]);
  const [loadingHistoryVersionProject, setLoadingHistoryVersionProject] = useState(true);

  useEffect(() => {
    if (prevProjectIdRef.current !== projectId) {
      prevProjectIdRef.current = projectId; // Обновляем хранимое предыдущее значение

      const getInfoProjectData = async () => {
        setInfoProjectLoading(true);
        try {
          const actionResult = await dispatch(loadUserDraftProjectInfoAction({ projectId }));

          const unwrappedResult = unwrapResult(actionResult);
          setInfoProject(unwrappedResult);
        } catch (error) {
          console.error(error);
        } finally {
          setInfoProjectLoading(false);
        }
      };

      if (projectId) {
        void getInfoProjectData();
      }
    }
  }, [projectId, dispatch]);

  useEffect(() => {
    if (prevProjectIdRef.current !== projectId) {
      prevProjectIdRef.current = projectId; // Обновляем хранимое предыдущее значение

      const getHistoryVersionProjectData = async () => {
        setLoadingHistoryVersionProject(true);
        try {
          const actionResult = await dispatch(loadUserDraftProjectHistoryVersionAction({ projectId }));
          const unwrappedResult = unwrapResult(actionResult);
          setHistoryVersionProject(unwrappedResult);
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingHistoryVersionProject(false);
        }
      };

      if (projectId) {
        getHistoryVersionProjectData();
      }
    }
  }, [projectId, dispatch]);

  const changeProjectAvatar = (file: File | null) => {
    if (!infoProject) return;

    const avatar = file ? URL.createObjectURL(file) : null;

    setInfoProject({
      ...infoProject,
      avatar: avatar
        ? {
            type: 'avatar',
            value: avatar,
          }
        : undefined,
    });
  };

  return {
    infoProject,
    loadingInfoProject,
    historyVersionProject,
    loadingHistoryVersionProject,
    changeProjectAvatar,
  };
};
