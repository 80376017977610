import React, { useCallback, useState } from 'react';
import { Button, Select } from 'modules/ui';
import { getActiveFlowId } from 'store/reducers/projectManager/getters';
import {
  changeGroupFlowAccessTypeAction,
  changeUserFlowAccessTypeAction,
  updateFlowAccessByIdAction,
} from 'store/reducers/projectManager/actions';
import { useAppDispatch } from 'store';
import { Text, AccessName } from './styles';
import { useSelector } from 'react-redux';
import { FlexContainer } from 'styles/FlexContainer';
import { accessEditSelect, accessItems } from '../constants';
import { ModalComponentType } from 'store/reducers/modals/types';
import { GroupType } from 'types/store';
import { FlowAccessInterface } from 'store/reducers/projectManager/types';

interface EditAccessModalProps {
  flowAccess: FlowAccessInterface;
}

export const EditAccessModal: ModalComponentType<EditAccessModalProps> = ({ flowAccess, onClose }) => {
  const dispatch = useAppDispatch();

  const { id: flowId } = useSelector(getActiveFlowId);

  const [type, setType] = useState<GroupType>(flowAccess.type);

  const onEdit = useCallback(
    async () => {
      try {
        const response =
          flowAccess.entity === 'group'
            ? await dispatch(changeGroupFlowAccessTypeAction({ flowId, type, groupId: flowAccess.id })).unwrap()
            : await dispatch(changeUserFlowAccessTypeAction({ flowId, type, userId: flowAccess.id })).unwrap();

        if (response) {
          dispatch(updateFlowAccessByIdAction({ flowAccess: { ...flowAccess, type } }));
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      } finally {
        onClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowAccess, flowId, type, onClose],
  );

  return (
    <FlexContainer width="100%" flexDirection="column" padding="4px" gap="16px" height="100%">
      <FlexContainer flexDirection="column">
        <Text>Группа/пользователь</Text>
        <AccessName>{flowAccess.name}</AccessName>
      </FlexContainer>

      <FlexContainer flexDirection="column">
        <Text>Права</Text>
        <Select
          needBackground={false}
          width="100%"
          name={accessEditSelect}
          value={type}
          onChange={setType}
          options={accessItems}
        />
      </FlexContainer>

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Изменить" iconSize="normal" heightSize="normal" onClick={onEdit} />
      </FlexContainer>
    </FlexContainer>
  );
};
