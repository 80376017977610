import styled, { css } from 'styled-components';
import { IsActiveInterface } from 'store/reducers/visualisations/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const HeaderMenuWrapper = styled.div`
  display: flex;
  height: 100%;
  gap: 30px;
`;

export const HeaderItem = styled.h2<IsActiveInterface>`
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  padding: 0 12px;
  color: var(${ColorVarsEnum.Level_2});
  border-bottom: 2px solid transparent;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      color: var(${ColorVarsEnum.Accent});
      border-bottom: 2px solid var(${ColorVarsEnum.Accent});
    `};
`;
