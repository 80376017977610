import {
  DefaultIncisionType,
  IncisionRadioSelector,
  IncisionRadioSelectorProps,
} from 'modules/visualisations/components/IncisionRadioSelector';
import { FlexContainer } from 'styles/FlexContainer';
import { ReactChildrenType } from 'types/global';
import { IncisionSelectorPositionInterface } from 'store/reducers/visualisations/types';

interface SingleIncisionLayoutProps<Incisions extends DefaultIncisionType>
  extends IncisionRadioSelectorProps<Incisions>,
    IncisionSelectorPositionInterface {
  children: ReactChildrenType;
  isDrillDown?: boolean;
}

export const SingleIncisionLayout = <Incisions extends DefaultIncisionType>({
  children,
  incisions,
  isDrillDown,
  isVisible,
  ...incisionRadioProps
}: SingleIncisionLayoutProps<Incisions>) => (
  <FlexContainer gap="10px" flexDirection="column" width="100%" height="100%">
    {(isVisible || isVisible === undefined) && (
      <IncisionRadioSelector incisions={incisions} {...incisionRadioProps} isDrillDown={isDrillDown} />
    )}
    {children}
  </FlexContainer>
);
