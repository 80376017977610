import React, { useState } from 'react';
import { KeysLocalStorage } from 'constants/LocalStorage';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useSelector } from 'react-redux';
import { logout } from 'store/reducers/auth/actions';
import { ClickAwayListener } from '@mui/material';
import { setActiveThemeAction } from 'store/reducers/themes/actions';
import { defaultThemes } from 'store/reducers/themes/constants';
import { getActiveThemeId } from 'store/reducers/themes/getters';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { useAppDispatch } from 'store';
import { useNavigate } from 'react-router-dom';
import { RoutesURL } from 'constants/Routes';
import { AdminWrapper } from 'shared/accessWrapper';
import { ArrowIcon, DownSvg, FastBoardBigLogoIcon, FastboardLogo, HeaderPreview, MenuElem, SelectedIcon } from './styles';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { useRole } from 'utils/hooks/useRole';
import env from 'config/settings';

export const HeaderProjectList = () => {
  const { isAdmin } = useRole();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const activeThemeId = useSelector(getActiveThemeId);

  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const [themeMenuOpen, setThemeMenuOpen] = useState(false);

  const handleClickMainMenu = () => setMainMenuOpen((prev) => !prev);
  const handleCloseMainMenu = () => {
    setMainMenuOpen(false);
    handleCloseThemeMenu();
  };

  const handleOpenThemeMenu = () => setThemeMenuOpen(true);
  const handleCloseThemeMenu = () => setThemeMenuOpen(false);

  const handleChangeCommonActiveTheme = (themeId: string) => {
    localStorage.setItem(KeysLocalStorage.activeCommonThemeId, themeId);
    dispatch(setActiveThemeAction(themeId));
    handleCloseMainMenu();
  };

  return (
    <HeaderPreview>
      <FastboardLogo onClick={() => {}} />
      <FlexContainer flexDirection="row" gap="10px" alignItems="center">
        <FastBoardBigLogoIcon />
        <PrimaryTextSpan fontSize="12px" color={`var(${ColorVarsEnum.Level_2})`}>
          {`v ${env.VERSION}`}
        </PrimaryTextSpan>
      </FlexContainer>
      <ClickAwayListener onClickAway={handleCloseMainMenu}>
        <FlexContainer position="relative">
          <IconWrapper
            hoverColorVar={ColorVarsEnum.Level_3}
            colorVar={mainMenuOpen ? ColorVarsEnum.Level_3 : ColorVarsEnum.Level_3}
            containerWidth="50px"
            containerHeight="49px"
            iconHeight="24px"
            iconWidth="24px"
            Icon={DownSvg}
            onClick={handleClickMainMenu}
          />

          {mainMenuOpen && (
            <>
              <FlexContainer
                position="absolute"
                backgroundColor={`var(${ColorVarsEnum.Level_3_menu})`}
                top="50px"
                right="0px"
                padding="4px 0"
                flexDirection="column"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                zIndex="1000"
              >
                <MenuElem onMouseEnter={handleOpenThemeMenu} onMouseLeave={handleCloseThemeMenu}>
                  <PrimaryTextSpan fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                    Тема интерфейса
                  </PrimaryTextSpan>
                  <ArrowIcon />

                  {themeMenuOpen && (
                    <FlexContainer
                      position="absolute"
                      backgroundColor={`var(${ColorVarsEnum.Level_3_menu})`}
                      top="0"
                      right="100%"
                      padding="4px 0"
                      flexDirection="column"
                      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                      zIndex="1000"
                    >
                      {defaultThemes.map(({ id, name }) => (
                        <MenuElem key={`theme-${id}`} onClick={() => handleChangeCommonActiveTheme(id)}>
                          {(activeThemeId != null ? activeThemeId === id : defaultThemes[0].id === id) && <SelectedIcon />}

                          <PrimaryTextSpan fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                            {name}
                          </PrimaryTextSpan>
                        </MenuElem>
                      ))}
                    </FlexContainer>
                  )}
                </MenuElem>
                {isAdmin && (
                  <AdminWrapper>
                    <MenuElem onClick={() => navigate('/' + RoutesURL.adminUser)}>
                      <PrimaryTextSpan fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                        Панель администратора
                      </PrimaryTextSpan>
                    </MenuElem>
                  </AdminWrapper>
                )}
                <MenuElem onClick={() => dispatch(logout())}>
                  <PrimaryTextSpan fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                    Выход
                  </PrimaryTextSpan>
                </MenuElem>
              </FlexContainer>
            </>
          )}
        </FlexContainer>
      </ClickAwayListener>
    </HeaderPreview>
  );
};
