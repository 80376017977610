import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import styled, { css } from 'styled-components';

interface RadioItemProps {
  selected: boolean;
  disabled?: boolean;
}

interface ItemProps {
  isActive: boolean;
}

export const Item = styled.div<ItemProps>`
  align-items: center;
  display: flex;

  svg,
  path,
  rect {
    ${({ isActive }) =>
      css`
        stroke: var(${isActive ? ColorVarsEnum.Accent : ColorVarsEnum.Level_3});
      `};
  }
`;
export const TextItem = styled.p<RadioItemProps>`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: var(${ColorVarsEnum.Level_1});
  margin: 0;
  cursor: pointer;
  transition: color 100ms ease-in-out;

  ${({ selected }) =>
    selected &&
    css`
      color: var(${ColorVarsEnum.Accent});
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(${ColorVarsEnum.Level_3});
      cursor: not-allowed;
    `};
`;
