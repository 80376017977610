import React, { useMemo } from 'react';
import { SettingsLayoutWrapper } from 'modules/settingsContainer/SettingsLayout/styles';
import { SettingsTabs } from 'modules/ui/tabs/SettingsTabs';
import { FC, ReactFCNoop } from 'types/global';
import { Tab } from 'modules/ui/tabs/types';
import { EventsComponent } from 'modules/visualisations/components/EventsComponent';
import { ACTIVE_BOARD_AREA_LABEL } from 'modules/workspace/constans';
import { ErrorWrapper } from 'modules/visualisations/components/ErrorWrapper';
import { BackgroundIcon } from 'assets/visualisationsSettings';
import { BodyWrapper } from 'modules/ui/tabs/SettingsTabs/styles';

interface SettingsLayoutProps {
  ViewComponent?: ReactFCNoop;
  DataComponent?: ReactFCNoop;
  BackgroundComponent?: ReactFCNoop;
}

export const SettingsLayout: FC<SettingsLayoutProps> = ({ DataComponent, ViewComponent, BackgroundComponent, children }) => {
  const tabs: Tab[] = useMemo(
    () =>
      [
        {
          content: 'Данные',
          prompt: 'Данные',
          type: 'text',
          id: 'data',
          Component: DataComponent,
        },
        {
          content: 'Вид',
          prompt: 'Вид',
          type: 'text',
          id: 'view',
          Component: ViewComponent,
        },
        {
          content: BackgroundIcon,
          prompt: 'Фон',
          type: 'icon',
          id: 'background',

          Component: BackgroundComponent,
        },
        {
          content: 'События',
          prompt: 'События',
          type: 'text',
          id: 'events',
          Component: EventsComponent,
        },
      ].filter(({ Component }) => Component) as Tab[],
    [BackgroundComponent, DataComponent, ViewComponent],
  );

  return (
    <SettingsLayoutWrapper id={ACTIVE_BOARD_AREA_LABEL}>
      {children ? (
        <ErrorWrapper>
          <BodyWrapper>{children}</BodyWrapper>
        </ErrorWrapper>
      ) : (
        <SettingsTabs tabs={tabs} />
      )}
    </SettingsLayoutWrapper>
  );
};
