import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { defaultZoom } from 'constants/defaults';
import { serverErrorText } from 'constants/ServerCode';
import { Model } from 'models/model/Model';
import { ModelItem } from 'models/model/ModelItem';
import { Relation } from 'models/model/Relation';
import Snackbar from 'services/Snackbar';
import { TState } from 'store/index';
import {
  createModelRule,
  deleteModelRule,
  importingUsersAndGroupsRule,
  loadAllModelRules,
  loadExportingUsersAndGroups,
  loadMetaModels,
  loadModelRule,
  loadModels,
  loadModelsFromData,
  loadTableFields,
  loadTablePreview,
  loadUsersNoGroup,
  updateModelRuleName,
  updateRuleModel,
  uploadMetaModels,
  uploadModels,
} from 'store/reducers/models/api';
import { defaultTabName, getNewTab, initialModelsStoreState } from 'store/reducers/models/constants';
import {
  getActiveTab,
  getActiveTabId,
  getAllModelRules,
  getModelRuleGroups,
  getModelRuleUsers,
  getTablePreviewByName,
  getTablesAsArray,
  getTabsAsArray,
} from 'store/reducers/models/getters';
import {
  addModelRule,
  addNewTab,
  changeActiveRuleGroupId,
  changeActiveRuleUserId,
  clearRuleModel,
  createRuleGroups,
  createRuleUsers,
  deleteByIdModelRule,
  deleteByIdRuleGroup,
  deleteByIdRuleUser,
  removeTabById,
  setActiveModelItemAlias,
  setActiveTab,
  setSlice,
  updateAllVariables,
  updateModelRule,
  updateModelRuleById,
  updateRuleGroups,
  updateRuleMoveToGroups,
  updateRuleUsers,
  updateTabById,
} from 'store/reducers/models/index';
import {
  ActiveModelItemAliasInterface,
  AllModelRulesListInterface,
  AllModelRulesPayload,
  AllVariableValuesInterface,
  CreateModelRulePayload,
  ExcludeGroupsAndUsersPayload,
  ImportingUsersAndGroupsRulePayload,
  LoadExportingUsersAndGroupsPayload,
  LoadTablePreviewPayloads,
  MetaModelPayloadInterface,
  ModelFromItemType,
  ModelPayloadInterface,
  ModelRuleDataInterface,
  ModelRulePayload,
  ModelRuleUserAndGroupInterface,
  ModelsActionsTypes,
  ModelTabInterface,
  TableFieldResponse,
  TablePreviewType,
  TabsType,
  UpdateModelRuleByIdPayload,
  UpdateModelRuleNamePayload,
  UpdateRulePayload,
  UpdateTabByIdPayload,
} from 'store/reducers/models/types';
import { findNextIndex, getMapObject } from 'utils/utils';
import { v4 } from 'uuid';

/* TODO: Adding types and interface for Error Messages  */

const validateError = (err: AxiosError, rejectWithValue?: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');
  return rejectWithValue?.(errorMessage);
};

export const loadTablePreviewAction = createAsyncThunk<TablePreviewType, LoadTablePreviewPayloads, { rejectValue: null }>(
  ModelsActionsTypes.LOAD_TABLE_PREVIEW,
  async (tableInfo, { rejectWithValue }) => {
    try {
      const response = await loadTablePreview(tableInfo);
      return response.data;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue(null);
    }
  },
);

export const loadAllModelRulesAction = createAsyncThunk<
  FastBoard.API.ShortModelRuleDTO[],
  AllModelRulesPayload,
  { rejectValue: null }
>(ModelsActionsTypes.LOAD_ALL_MODEL_RULES, async ({ projectId, modelId }, { rejectWithValue }) => {
  try {
    const response = await loadAllModelRules({ modelId, projectId });
    return response.data.apiShortModelRules;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    rejectWithValue(null);
    return [] as FastBoard.API.ShortModelRuleDTO[];
  }
});

export const loadModelRuleAction = createAsyncThunk<
  FastBoard.API.ModelRuleWithUsersAndGroupsNamesDTO,
  ModelRulePayload,
  { rejectValue: null }
>(ModelsActionsTypes.MODEL_RULE, async ({ ruleId, projectId }, { rejectWithValue }) => {
  try {
    const response = await loadModelRule({ ruleId, projectId });

    return response.data.apiModelRule;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    rejectWithValue(null);
    return {} as FastBoard.API.ModelRuleWithUsersAndGroupsNamesDTO;
  }
});

export const updateModelRuleAction = createAsyncThunk<void, ModelRuleDataInterface | null>(
  ModelsActionsTypes.UPDATE_MODEL_RULE,
  (ruleData, { dispatch }) => {
    dispatch(updateModelRule(ruleData));
  },
);

export const updateAllVariablesAction = createAsyncThunk<void, AllVariableValuesInterface[]>(
  ModelsActionsTypes.UPDATE_ALL_VARIABLES,
  (ruleData, { dispatch }) => {
    dispatch(updateAllVariables(ruleData));
  },
);

export const deleteModelRuleAction = createAsyncThunk<boolean, ModelRulePayload, { rejectValue: null }>(
  ModelsActionsTypes.DELETE_MODEL_RULE,
  async ({ ruleId, projectId }, { rejectWithValue }) => {
    try {
      const response = await deleteModelRule({ ruleId, projectId });
      Snackbar.show('Права удален', 'success');

      return response;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      validateError(err, rejectWithValue);
      return rejectWithValue(null);
    }
  },
);

export const deleteByIdModelRuleAction = createAsyncThunk(
  ModelsActionsTypes.DELETE_BY_ID_MODEL_RULE,
  (ruleId: string, { dispatch }) => {
    dispatch(deleteByIdModelRule({ id: ruleId }));
  },
);

export const updateModelRuleByIdAction = createAsyncThunk<void, UpdateModelRuleByIdPayload>(
  ModelsActionsTypes.UPDATE_MODEL_RULE_BY_ID,
  ({ modelRule }, { dispatch, getState }) => {
    const allModelRules = getAllModelRules(getState() as TState).allModelRulesList.map((value) =>
      value?.id === modelRule?.id ? { ...value, ...modelRule } : value,
    );

    dispatch(updateModelRuleById(allModelRules));
  },
);

export const createModelRuleAction = createAsyncThunk<
  FastBoard.API.CreateAndUpdateRuleDTO,
  CreateModelRulePayload,
  {
    rejectValue: null;
  }
>(ModelsActionsTypes.CREATE_MODEL_RULE, async ({ name, modelId, projectId, file }, { rejectWithValue }) => {
  try {
    const response = await createModelRule({
      name,
      modelId,
      projectId,
      file,
    });
    Snackbar.show('Правило успешно создано', 'success');

    return response.data.apiModelRule;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return rejectWithValue(null);
  }
});

export const addModelRuleAction = createAsyncThunk<void, AllModelRulesListInterface>(
  ModelsActionsTypes.ADD_MODEL_RULE,
  (data, { dispatch }) => {
    dispatch(addModelRule(data));
  },
);

export const deleteByIdRuleUserAction = createAsyncThunk(
  ModelsActionsTypes.DELETE_BY_ID_RULE_USER,
  (id: string, { dispatch }) => {
    dispatch(deleteByIdRuleUser({ id }));
  },
);

export const deleteByIdRuleGroupAction = createAsyncThunk(
  ModelsActionsTypes.DELETE_BY_ID_RULE_GROUP,
  (id: string, { dispatch }) => {
    dispatch(deleteByIdRuleGroup({ id }));
  },
);

export const loadUserModelAction = createAsyncThunk<
  FastBoard.API.UserGroupListItemResponseDTO[],
  ExcludeGroupsAndUsersPayload,
  { rejectValue: null }
>(ModelsActionsTypes.LOAD_USERS_RULE_MODEL, async ({ excludeUsers, excludeGroups }, { rejectWithValue }) => {
  try {
    const response = await loadUsersNoGroup({ excludeUsers, excludeGroups });

    return response.data.userGroupList;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as FastBoard.API.UserGroupListItemResponseDTO[];
  }
});

export const loadGroupAction = createAsyncThunk<
  FastBoard.API.UserGroupListItemResponseDTO[],
  ExcludeGroupsAndUsersPayload,
  { rejectValue: null }
>(ModelsActionsTypes.LOAD_GROUPS_RULE_MODEL, async ({ excludeUsers, excludeGroups }, { rejectWithValue }) => {
  try {
    const response = await loadUsersNoGroup({ excludeUsers, excludeGroups });

    return response.data.userGroupList;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as FastBoard.API.UserGroupListItemResponseDTO[];
  }
});

export const updateModelRuleNameAction = createAsyncThunk<
  FastBoard.API.ModelRuleWithUsersAndGroupsNamesDTO,
  UpdateModelRuleNamePayload
>(ModelsActionsTypes.UPDATE_MODEL_RULE_NAME, async ({ name, id, projectId }, { rejectWithValue }) => {
  try {
    const response = await updateModelRuleName({ name, id, projectId });
    Snackbar.show('Название успешно изменено', 'success');

    return response.data.apiModelRule;
  } catch (err: any) {
    return validateError(err, rejectWithValue);
  }
});

export const updateRuleModelAction = createAsyncThunk<FastBoard.API.ModelRuleWithUsersAndGroupsNamesDTO, UpdateRulePayload>(
  ModelsActionsTypes.UPDATE_RULE_MODEL,
  async ({ projectId, data }, { rejectWithValue }) => {
    try {
      const response = await updateRuleModel({ projectId, data });
      Snackbar.show('Правило успешно сохранено', 'success');

      return response.data.apiModelRule;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateRuleUsersAction = createAsyncThunk<void, ModelRuleUserAndGroupInterface[]>(
  ModelsActionsTypes.UPDATE_RULE_USERS,
  (users, { dispatch }) => {
    dispatch(updateRuleUsers(users));
  },
);

export const updateRuleGroupsAction = createAsyncThunk<void, ModelRuleUserAndGroupInterface[]>(
  ModelsActionsTypes.UPDATE_RULE_USERS,
  (users, { dispatch }) => {
    dispatch(updateRuleGroups(users));
  },
);

export const createRuleUsersAction = createAsyncThunk<void, ModelRuleUserAndGroupInterface[]>(
  ModelsActionsTypes.CREATE_RULE_USERS,
  (newUsers, { dispatch, getState }) => {
    const users = getModelRuleUsers(getState() as TState);

    dispatch(createRuleUsers([...newUsers, ...users]));
  },
);

export const createRuleGroupsAction = createAsyncThunk<void, ModelRuleUserAndGroupInterface[]>(
  ModelsActionsTypes.CREATE_RULE_GROUPS,
  (newGroups, { dispatch, getState }) => {
    const groups = getModelRuleGroups(getState() as TState);

    dispatch(createRuleGroups([...newGroups, ...groups]));
  },
);

export const updateRuleMoveToGroupsAction = createAsyncThunk<void, ModelRuleUserAndGroupInterface[]>(
  ModelsActionsTypes.UPDATE_RULE_MOVE_TO_GROUPS,
  (newGroups, { dispatch }) => {
    dispatch(updateRuleMoveToGroups(newGroups));
  },
);

export const changeActiveRuleUserIdAction = createAsyncThunk<void, ModelRuleUserAndGroupInterface | null>(
  ModelsActionsTypes.ACTIVE_RULE_MOVE_USER_TO_ID,
  (activeRuleUser, { dispatch }) => {
    dispatch(changeActiveRuleUserId(activeRuleUser));
  },
);

export const changeActiveRuleGroupIdAction = createAsyncThunk<void, ModelRuleUserAndGroupInterface | null>(
  ModelsActionsTypes.ACTIVE_RULE_MOVE_GROUP_TO_ID,
  (activeRuleGroup, { dispatch }) => {
    dispatch(changeActiveRuleGroupId(activeRuleGroup));
  },
);

export const loadExportingUsersAndGroupsAction = createAsyncThunk<void, LoadExportingUsersAndGroupsPayload>(
  ModelsActionsTypes.LOAD_EXPORTING_USERS_AND_GROUPS,
  async ({ ruleId, projectId, typeFile }, { rejectWithValue }) => {
    try {
      const { data } = await loadExportingUsersAndGroups({ ruleId, projectId, typeFile });

      if (data) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
        link.download = `${ruleId}.${typeFile}`;
        link.click();
        window.URL.revokeObjectURL(link.href);
      }

      Snackbar.show('Файл успешно загружен.', 'success');
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue({ visualisationsByPages: {}, visualisations: {} });
    }
  },
);

export const importingUsersAndGroupsRuleAction = createAsyncThunk<
  FastBoard.API.ModelRuleWithUsersAndGroupsNamesDTO,
  ImportingUsersAndGroupsRulePayload,
  { rejectValue: null }
>(ModelsActionsTypes.IMPORTING_USERS_AND_GROUPS, async ({ ruleId, file, projectId }, { rejectWithValue }) => {
  try {
    const response = await importingUsersAndGroupsRule({ ruleId, file, projectId });
    Snackbar.show('Файл успешно загружен.', 'success');

    return response.data.apiModelRule;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return rejectWithValue(null);
  }
});

export const loadTablePreviewByNameAction = createAsyncThunk(
  ModelsActionsTypes.LOAD_TABLE_PREVIEW_BY_NAME,
  async (tableInfo: LoadTablePreviewPayloads, { dispatch, getState }) => {
    const tablePreview = getTablePreviewByName(tableInfo.table)(getState() as TState);

    if (!tablePreview) {
      dispatch(loadTablePreviewAction(tableInfo));
    }
  },
);

export const uploadModelsDataAction = createAsyncThunk(
  ModelsActionsTypes.UPLOAD_MODELS,
  async (projectId: string, { rejectWithValue, getState }) => {
    try {
      const tabs = getTabsAsArray(getState() as TState);

      const models: ModelPayloadInterface[] = tabs.map(({ id, name, models }) => {
        if (models.length === 1) {
          const [model] = models;

          return { id, name, modelItems: model.modelData };
        }

        return { id, name, modelItems: [] };
      });

      const response = await uploadModels({ projectId, payload: { models } });
      return response.data;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadModelsDataAction = createAsyncThunk<
  ModelPayloadInterface[],
  string,
  {
    rejectValue: ModelPayloadInterface[];
  }
>(ModelsActionsTypes.LOAD_MODELS, async (projectId: string, { rejectWithValue }) => {
  try {
    const response = await loadModels(projectId);
    return response.data.models as ModelPayloadInterface[];
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return rejectWithValue([]);
  }
});

export const uploadMetaModelsDataAction = createAsyncThunk(
  ModelsActionsTypes.UPLOAD_META_MODELS,
  async (projectId: string, { rejectWithValue, getState }) => {
    try {
      const tabs = getTabsAsArray(getState() as TState);

      const models: MetaModelPayloadInterface[] = tabs.map(({ id, zoom, models }) => {
        if (models.length === 1) {
          const [model] = models;

          return { id, zoom, modelItems: model.metaModelData };
        }

        return { id, zoom, modelItems: [] };
      });

      const response = await uploadMetaModels({ projectId, payload: { models } });
      return response.data;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadMetaModelsDataAction = createAsyncThunk<
  MetaModelPayloadInterface[],
  string,
  {
    rejectValue: MetaModelPayloadInterface[];
  }
>(ModelsActionsTypes.LOAD_META_MODELS, async (projectId: string, { rejectWithValue }) => {
  try {
    const response = await loadMetaModels(projectId);
    return response.data.models;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return rejectWithValue([]);
  }
});

export const loadModelsFromDataAction = createAsyncThunk<
  ModelFromItemType[],
  string,
  {
    rejectValue: ModelFromItemType[];
  }
>(ModelsActionsTypes.LOAD_MODELS_FROM_DATA, async (projectId: string, { rejectWithValue }) => {
  try {
    const response = await loadModelsFromData(projectId);

    return response.data.modelsFrom;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return rejectWithValue([]);
  }
});

export const loadTables = createAsyncThunk<
  TableFieldResponse,
  string,
  {
    rejectValue: TableFieldResponse;
  }
>(ModelsActionsTypes.LOAD_TABLES, async (projectId: string, { rejectWithValue }) => {
  try {
    const response = await loadTableFields(projectId);
    return response.data;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return rejectWithValue({});
  }
});

export const uploadTabsAction = createAsyncThunk(ModelsActionsTypes.UPLOAD_TABS, async (projectId: string, { dispatch }) => {
  try {
    dispatch(uploadModelsDataAction(projectId));
    dispatch(uploadMetaModelsDataAction(projectId));
  } catch {
    Snackbar.show('Ошибка при загрузке моделей', 'error');
  }
});

export const loadTabsAction = createAsyncThunk<
  TabsType,
  string,
  {
    rejectValue: TabsType;
  }
>(ModelsActionsTypes.LOAD_TABS, async (projectId: string, { rejectWithValue, dispatch }) => {
  try {
    const { payload: tables } = await dispatch(loadTables(projectId));
    const { payload: modelsData } = await dispatch(loadModelsDataAction(projectId));
    const { payload: metaModelsData } = await dispatch(loadMetaModelsDataAction(projectId));

    const mapMetaModels = getMapObject(metaModelsData, 'id');

    const tabs: ModelTabInterface[] = (modelsData || []).map(({ name, id, modelItems: modelValues }) => {
      const { zoom, modelItems: metaModel } = mapMetaModels[id];

      const mapMetaModel = getMapObject(metaModel, 'alias');

      const modelItems: ModelItem[] = modelValues.map(({ relations: relationValues, ...modelItemValues }) => {
        const { table, alias } = modelItemValues;
        const relations: Relation[] | null = relationValues && relationValues.map((relation) => new Relation(relation));
        const columns = tables?.[table] || {};
        const { config } = mapMetaModel[alias];

        return new ModelItem({ ...modelItemValues, columns, relations, config });
      });

      let models: Model[] = [];

      if (modelItems.length) {
        models = [new Model(modelItems)];
      }

      return { id, name, zoom, models };
    });

    if (tabs.length) {
      dispatch(setActiveTab(tabs[0].id));
    }

    return getMapObject(tabs, 'id');
  } catch {
    Snackbar.show('Ошибка в полученной модели', 'error');
    return rejectWithValue({});
  }
});

export const addNewTabAction = createAsyncThunk(ModelsActionsTypes.ADD_TAB, (_, { getState, dispatch }) => {
  const tabs = getTabsAsArray(getState() as TState);

  const nextIndex = findNextIndex(
      tabs.map(({ name }) => name),
      defaultTabName,
    ),
    tabName = defaultTabName + nextIndex;

  const newTab = getNewTab({ name: tabName, id: v4() });

  dispatch(addNewTab(newTab));
});

export const setActiveTabAction = createAsyncThunk(ModelsActionsTypes.SET_ACTIVE_TAB_ID, (id: string | null, { dispatch }) => {
  dispatch(setActiveTab(id));
});

export const setActiveModelItemAliasAction = createAsyncThunk(
  ModelsActionsTypes.SET_ACTIVE_MODEL_ITEM_ALIAS,
  (alias: ActiveModelItemAliasInterface | null, { dispatch }) => {
    dispatch(setActiveModelItemAlias(alias));
  },
);

export const addTableToActiveTabAction = createAsyncThunk(
  ModelsActionsTypes.ADD_TABLE_TO_ACTIVE_TAB,
  (tableName: string, { dispatch, getState }) => {
    const state = getState() as TState,
      tables = getTablesAsArray(state),
      activeTab = getActiveTab(state);

    const table = tables.find(({ name }) => name === tableName);

    if (activeTab && table) {
      const alreadyExistedAlias: Record<string, boolean> = {};

      activeTab.models.forEach((model) =>
        model.modelItems.forEach(({ alias }) => {
          alreadyExistedAlias[alias] = true;
        }),
      );

      let newAliasName = tableName;

      while (alreadyExistedAlias[newAliasName]) {
        newAliasName = newAliasName + '_copy';
      }

      const modelItem = new ModelItem({
        alias: newAliasName,
        table: tableName,
        db: null,
        columns: table.fields || {},
        isHead: true,
      });

      const model = new Model([modelItem]);

      dispatch(updateTabById({ id: activeTab.id, data: { models: [...activeTab.models, model] } }));
    }
  },
);

export const updateTabByIdAction = createAsyncThunk<void, UpdateTabByIdPayload>(
  ModelsActionsTypes.UPDATE_TAB,
  (payload, { dispatch }) => {
    dispatch(updateTabById(payload));
  },
);

export const removeTabByIdAction = createAsyncThunk<void, string>(ModelsActionsTypes.REMOVE_TAB, (id, { dispatch, getState }) => {
  const state = getState() as TState,
    tabs = getTabsAsArray(state),
    activeTabId = getActiveTabId(state);

  if (activeTabId === id) {
    const newActiveTabId = tabs.filter((tab) => tab.id !== id)[0]?.id || null;
    dispatch(setActiveTabAction(newActiveTabId));
  }

  dispatch(removeTabById(id));
});

export const updateZoomAction = createAsyncThunk(
  ModelsActionsTypes.UPDATE_ZOOM,
  (isDecrease: boolean | undefined, { dispatch, getState }) => {
    const state = getState() as TState,
      activeTab = getActiveTab(state);

    if (activeTab) {
      const { zoom } = activeTab,
        minZoom = 0.3,
        newZoom = isDecrease ? zoom - 0.1 : zoom + 0.1,
        zoomValue = newZoom >= defaultZoom ? defaultZoom : newZoom <= minZoom ? minZoom : newZoom;

      dispatch(updateTabById({ id: activeTab.id, data: { zoom: zoomValue } }));
    }
  },
);

export const clearModelsStore = createAsyncThunk(ModelsActionsTypes.CLEAR_MODEL_STORE, (_, { dispatch }) => {
  dispatch(setSlice(initialModelsStoreState));
});

export const clearRuleModelAction = createAsyncThunk(ModelsActionsTypes.CLEAR_RULE_MODEL, (_, { dispatch }) => {
  dispatch(clearRuleModel(initialModelsStoreState.modelRuleDataUsersAndGroups));
});
