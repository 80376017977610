import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useFilterQuery } from 'modules/filters/hooks/filter';
import { SearchInput } from 'modules/ui/inputs/SearchInput';
import { ActiveObjectFilters } from 'store/reducers/visualisations/types';
import Button from 'modules/ui/Button';
import { MultipleFilterInterface } from 'store/reducers/filters/types';
import { VirtualizedList } from 'shared/ui/VirtualizedList';
import { FilterValuesRow } from 'modules/filters/Multiple/view/Filter/FilterValuesRow';
import { SelectedItemCounter } from 'components/shared/SelectedItemCounter';
import { SortingPanel } from 'components/shared/SortingPanel';

interface FilterValue {
  value: string;
}

interface MultipleFilterProps {
  data: MultipleFilterInterface;
  elementId: string;
  selectedValues: string[];
  whereQuery: string;
  onUpdateFilter: (elementId: string, payload: Partial<ActiveObjectFilters>) => void;
}

const MultipleFilter = ({ data, elementId, selectedValues, whereQuery, onUpdateFilter }: MultipleFilterProps) => {
  const [selectedFilterValues, setSelectedFilterValues] = useState<string[]>([]);
  const [sortingStatus, setSortingStatus] = useState<string | null>(null);
  const [searchString, setSearchString] = useState('');

  const { nameSettings, isRealData, fictionalData, id, modelId, sqlData } = data;

  const isSelectedFilterValue = useCallback((value: string) => selectedFilterValues.includes(value), [selectedFilterValues]);

  const onSelectFilterValue: (value: string) => () => void = useCallback(
    (value: string) => () => {
      const newSelectedData = !isSelectedFilterValue(value)
        ? [...selectedFilterValues, value]
        : selectedFilterValues.filter((selectedValue) => selectedValue !== value);

      setSelectedFilterValues(newSelectedData);
      onUpdateFilter(elementId, { selectedValues: newSelectedData });
    },
    [isSelectedFilterValue, selectedFilterValues, setSelectedFilterValues, elementId, onUpdateFilter],
  );

  const onResetFilterValues = () => {
    setSelectedFilterValues([]);
    onUpdateFilter(elementId, { selectedValues: [] });
  };

  const { filterValues, isValuesOverLimit } = useFilterQuery({
    nameSettings,
    id,
    modelId,
    searchString,
    whereQuery,
    sqlData,
    isFetchRequiredWhenDataExists: true,
    sortingStatus,
  });

  const normalizedFilterValues = useMemo<FilterValue[]>(
    () =>
      isRealData
        ? filterValues
        : fictionalData.map((value: string) => ({
            value,
          })),
    [isRealData, fictionalData, filterValues],
  );

  const values = useMemo(() => normalizedFilterValues.map(({ value }) => value), [normalizedFilterValues]);

  const onSelectAll = useCallback(() => {
    setSelectedFilterValues(values.map((value) => value));
  }, [values, setSelectedFilterValues]);

  const onUnselectAll = useCallback(() => setSelectedFilterValues([]), [setSelectedFilterValues]);

  const onInvertSelection = useCallback(() => {
    const localValuesSet = new Set(selectedFilterValues);
    const newSelectedValues = [];

    for (const value of values) {
      if (!localValuesSet.has(value)) {
        newSelectedValues.push(value);
      }
    }

    setSelectedFilterValues(newSelectedValues);
  }, [values, selectedFilterValues, setSelectedFilterValues]);

  const totalCount = useMemo(() => {
    if (searchString) {
      const filteredValues = normalizedFilterValues.map(({ value }) => value);
      const uniqueFilteredValues = filteredValues.filter((value) => !selectedFilterValues.includes(value));
      return selectedFilterValues.length + uniqueFilteredValues.length;
    }
    return values.length;
  }, [values, selectedFilterValues, searchString, normalizedFilterValues]);

  useEffect(() => {
    if (!selectedValues) return setSelectedFilterValues([]);

    const filteredValues = selectedValues.filter((value) => values.includes(value));
    setSelectedFilterValues(filteredValues);
  }, [selectedValues, values]);

  const createVirtualizedProps = ({
    values,
    isSelectedFilterValue,
    onSelectFilterValue,
  }: {
    values: string[];
    isSelectedFilterValue: (value: string) => boolean;
    onSelectFilterValue: (value: string) => () => void;
  }) => ({
    values,
    isSelectedFilterValue,
    onSelectFilterValue,
  });

  const listProps = createVirtualizedProps({ values, isSelectedFilterValue, onSelectFilterValue });

  return (
    <FlexContainer display="flex" flexDirection="column" gap="4px" width="100%" marginTop="8px">
      <FlexContainer width="100%">
        <SearchInput
          useDebounce
          name="filter"
          needBackground={false}
          type="text"
          width="100%"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
      </FlexContainer>
      <FlexContainer width="100%" justifyContent="space-between">
        <SelectedItemCounter
          selectedCounter={selectedFilterValues.length}
          listCounter={totalCount}
          isOverLimit={isValuesOverLimit}
        />
        <SortingPanel
          onSelectAllClick={onSelectAll}
          onSelectNoneClick={onUnselectAll}
          onSelectInvertClick={onInvertSelection}
          onSortingClick={setSortingStatus}
          selectedSorting={sortingStatus}
        />
      </FlexContainer>
      <FlexContainer display="flex" flexDirection="column" maxHeight="320px" overflowY="auto">
        {values.length > 0 ? (
          <VirtualizedList data={listProps}>{FilterValuesRow}</VirtualizedList>
        ) : (
          <FlexContainer height="100%" width="100%" justifyContent="center" flex="1" flexDirection="column" alignItems="center">
            <PrimaryTextSpan textAlign="center">Нет подходящих данных, либо они уже выбраны</PrimaryTextSpan>
          </FlexContainer>
        )}
      </FlexContainer>
      <FlexContainer maxWidth="70px">
        <Button text="Сбросить" width="auto" heightSize="small" onClick={onResetFilterValues} />
      </FlexContainer>
    </FlexContainer>
  );
};

export const MultipleFilterActivation = memo(MultipleFilter);
