import {
  MinAndMaxMetricValuesInterface,
  SqlSettingsChangesInterface,
} from 'modules/settingsContainer/common/data/SqlSettings/types';
import { SortingInterface } from 'modules/visualisations/Table/visualisation/types';
import { store } from 'store';
import {
  updateDataSettingsAction,
  updateDataSettingsWithSqlAction,
  updateSqlSettingsAction,
  updateTableDataSettingsAction,
  updateTableDataSettingsById,
} from 'store/reducers/visualisations/actions';
import {
  defaultImageName,
  defaultIncisionInHeaderName,
  defaultIncisionName,
  defaultIndicatorName,
  getIncisionFunctionByType,
  getIndicatorFunctionByType,
  getTableIncision,
} from 'store/reducers/visualisations/constants';
import {
  DefaultDataSettingsInterface,
  DefaultDataSettingsWithActiveIncisionIdInterface,
  DefaultIncisionInterface,
  DefaultIndicatorInterface,
  FormattingInterface,
  IndicatorEmptyValuesInterface,
  LimitSettingInterface,
  TableDataSettings,
  VisualisationOperationTypesType,
  VisualisationType,
  VisualisationTypeType,
} from 'store/reducers/visualisations/types';
import { Changing } from 'types/changing';
import { findNextIndex } from 'utils/utils';
import { v4 } from 'uuid';
import { OnChangeValueIncisions } from '../Table/settings/DataTab/types';
import { validateData } from './constants';
import { defaultVisualisationWithActiveIncisionId } from '../../workspace/components/WorkAreaSpace/constants';

export type OnChangeValue<T> = (dataSettings: DefaultDataSettingsInterface, value: T, id: string) => void;

const dispatch = store.dispatch;

export const getNewIncisions = ({
  dataSettings,
  name,
  type,
  defaultIncisionName,
}: {
  dataSettings: DefaultDataSettingsInterface;
  name?: string | null;
  type: VisualisationTypeType;
  defaultIncisionName: string;
}) => {
  const nextIndex = findNextIndex(
      dataSettings.incisions.map(({ name }) => name),
      defaultIncisionName,
    ),
    incisionName = defaultIncisionName + nextIndex;

  return getIncisionFunctionByType[type]({ name: name || incisionName, id: v4() });
};

export const getNewIndicator = ({
  dataSettings,
  name,
  type,
}: {
  dataSettings: DefaultDataSettingsInterface;
  name?: string | null;
  type: VisualisationType;
}) => {
  const nextIndex = findNextIndex(
      dataSettings.indicators.map(({ name }) => name),
      defaultIndicatorName,
    ),
    incisionName = defaultIndicatorName + nextIndex;

  const stackNumber = type === 'lineAndBar' && {
    stackNumber: nextIndex + 1,
  };

  return getIndicatorFunctionByType[type]({ name: name || incisionName, id: v4(), ...stackNumber }) || '';
};

const incisionsActions: Record<
  Changing.IncisionActionType['type'],
  (params: {
    changes: Changing.IncisionActionType;
    dataSettings: DefaultDataSettingsInterface;
    index: number;
    type: VisualisationType;
  }) => DefaultIncisionInterface | void
> = {
  change: ({ changes, index, dataSettings: { incisions } }) => {
    const { customRequest, order, fieldName } = changes as Changing.ChangeIncision;

    let incision = incisions[typeof order?.from === 'number' ? order?.from : index];

    if (fieldName) {
      incision = { ...incision, fieldName: fieldName.to };
    }

    if (customRequest) {
      incision = { ...incision, settings: { ...incision.settings, customRequest: customRequest.to } };
    }

    return incision;
  },
  create: ({ changes, dataSettings, type }) => {
    const { customRequest, fieldName, alias: name } = changes as Changing.CreateIncision;

    const incision = getNewIncisions({
      dataSettings,
      name,
      type,
      defaultIncisionName,
    });

    return { ...incision, fieldName, settings: { ...incision.settings, customRequest } };
  },
  pass: () => {},
  delete: () => {},
};

const indicatorsActions: Record<
  Changing.IndicatorActionType['type'],
  (params: {
    changes: Changing.IndicatorActionType;
    dataSettings: DefaultDataSettingsInterface;
    index: number;
    type: VisualisationType;
  }) => DefaultIndicatorInterface | void
> = {
  change: ({ changes, index, dataSettings: { indicators } }) => {
    const { customRequest, order, fieldName, operationType } = changes as Changing.ChangeIndicator;

    let indicator = indicators[typeof order?.from === 'number' ? order?.from : index];

    if (fieldName) {
      indicator = { ...indicator, fieldName: fieldName.to };
    }

    if (customRequest) {
      indicator = { ...indicator, customRequest: customRequest.to };
    }

    if (operationType) {
      indicator = { ...indicator, operationType: operationType.to };
    }

    return indicator;
  },
  create: ({ changes, dataSettings, type }) => {
    const { customRequest, fieldName, alias: name, operationType } = changes as Changing.CreateIndicator;

    const indicator = getNewIndicator({ dataSettings, name, type });

    return { ...indicator, fieldName, operationType, customRequest };
  },
  pass: () => {},
  delete: () => {},
};

export const onSqlSettingsSave = (
  dataSettings: DefaultDataSettingsWithActiveIncisionIdInterface,
  sqlSettingsChanges: SqlSettingsChangesInterface,
  type: VisualisationTypeType,
  minMetricValues: MinAndMaxMetricValuesInterface = {
    incision: { minValue: 1 },
    indicator: { minValue: 1 },
  },
  validatedIndicatorsCallback?: (
    validatedIndicators: DefaultIndicatorInterface[],
    indicators: DefaultIndicatorInterface[],
  ) => DefaultIndicatorInterface[],
) => {
  const { incisionsChanges, indicatorsChanges, filterAndGroupRequest } = sqlSettingsChanges;

  const {
    incision: { minValue: incisionMinValue, maxValue: incisionMaxValue },
    indicator: { minValue: indicatorMinValue, maxValue: indicatorMaxValue },
  } = minMetricValues;

  const incisions = incisionsChanges.reduce<DefaultIncisionInterface[]>((result, changes, index) => {
    const incision = incisionsActions[changes.type]({ changes, dataSettings, index, type: type as VisualisationType });

    if (incision) {
      return [...result, incision];
    }

    return result;
  }, []);

  const indicators = indicatorsChanges.reduce<DefaultIndicatorInterface[]>((result, changes, index) => {
    const indicator = indicatorsActions[changes.type]({ changes, dataSettings, index, type: type as VisualisationType });

    if (indicator) {
      return [...result, indicator];
    }

    return result;
  }, []);

  let newActiveIncisionId: null | string | undefined;

  if (dataSettings?.activeIncisionId !== undefined) {
    const hasActiveIncision = incisions.some(({ id }) => id === dataSettings.activeIncisionId);

    newActiveIncisionId = hasActiveIncision ? dataSettings.activeIncisionId : incisions?.[0]?.id || null;
  }

  const validatedIncisions = validateData(incisions, incisionMinValue, incisionMaxValue) || dataSettings.incisions;
  const validatedIndicators = validateData(indicators, indicatorMinValue, indicatorMaxValue) || dataSettings.indicators;

  const validatedIndicatorsResult = validatedIndicatorsCallback
    ? validatedIndicatorsCallback(validatedIndicators, indicators)
    : validatedIndicators;

  dispatch(
    updateDataSettingsWithSqlAction({
      ...dataSettings,
      incisions: validatedIncisions,
      indicators: validatedIndicatorsResult,
      activeIncisionId: defaultVisualisationWithActiveIncisionId.includes(type) ? newActiveIncisionId : undefined,
    }),
  );

  dispatch(updateSqlSettingsAction({ filterAndGroupRequest }));
};

export const onLimitChange = (limit: LimitSettingInterface) =>
  dispatch(
    updateDataSettingsAction({
      limit,
    }),
  );

export const onLimitGroupingChange = (limitGrouping: LimitSettingInterface) =>
  dispatch(
    updateTableDataSettingsAction({
      limitGrouping,
    }),
  );

export const onModelIdChange = (modelId: string | null) =>
  dispatch(
    updateDataSettingsAction({
      modelId,
    }),
  );

/* Incision change */

export const onChangeEmptyValue: OnChangeValue<IndicatorEmptyValuesInterface> = (dataSettings, emptyValues, id) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, emptyValues } } : incision,
      ),
    }),
  );

export const onChangeGroupingTableRows: OnChangeValueIncisions<boolean> = (dataSettings, isGroup, id, incisionsKey) => {
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      [incisionsKey]: dataSettings[incisionsKey].map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, isGroup } } : incision,
      ),
    }),
  );
};

export const onChangeGroupingAllIncisions = (dataSettings: TableDataSettings, hasAllGroupIncision: boolean) => {
  dispatch(updateTableDataSettingsAction({ hasAllGroupIncision }));
};

export const onChangeOrderById = (dataSettings: TableDataSettings, orderBy: SortingInterface[], id: string) => {
  dispatch(updateTableDataSettingsById({ id, dataSettings: { orderBy } }));
};
export const onChangeOrderBy = (dataSettings: TableDataSettings, orderBy: SortingInterface[]) => {
  dispatch(updateTableDataSettingsAction({ orderBy }));
};

export const onChangeIncisionNameFromDataBase: OnChangeValue<boolean> = (dataSettings, nameFromDatabase, id) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, nameFromDatabase } } : incision,
      ),
    }),
  );

export const onChangeIncisionName: OnChangeValue<string> = (dataSettings, name, id) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) => (id === incision.id ? { ...incision, name } : incision)),
    }),
  );

export const onChangeFictionalData: OnChangeValue<string[]> = (dataSettings, fictionalData, id) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) => (id === incision.id ? { ...incision, fictionalData } : incision)),
    }),
  );

export const onChangeIncisionFieldName: OnChangeValue<string> = (dataSettings, fieldName, id) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) => (id === incision.id ? { ...incision, fieldName } : incision)),
    }),
  );

export const onDeleteIncision = (dataSettings: DefaultDataSettingsWithActiveIncisionIdInterface, incisionId: string) => {
  const newIncisions = dataSettings.incisions.filter(({ id }) => id !== incisionId);

  let newActiveIncisionId: null | string | undefined;

  if (dataSettings?.activeIncisionId !== undefined) {
    newActiveIncisionId =
      dataSettings?.activeIncisionId === incisionId ? newIncisions?.[0]?.id || null : dataSettings?.activeIncisionId;
  }

  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      incisions: newIncisions,
      activeIncisionId: newActiveIncisionId,
    }),
  );
};

export const onAddNewIncision = (dataSettings: DefaultDataSettingsWithActiveIncisionIdInterface, type: VisualisationTypeType) => {
  const incision = getNewIncisions({
    dataSettings,
    type,
    defaultIncisionName: type === 'text' ? defaultImageName : defaultIncisionName,
  });

  let newActiveIncisionId: null | string | undefined;

  if (dataSettings?.activeIncisionId !== undefined) {
    newActiveIncisionId = dataSettings?.activeIncisionId === null ? incision.id : dataSettings?.activeIncisionId;
  }

  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      incisions: [...dataSettings.incisions, incision],
      activeIncisionId: defaultVisualisationWithActiveIncisionId.includes(type) ? newActiveIncisionId : undefined,
    }),
  );
};

export const onAddNewIncisionInHeader = (dataSettings: TableDataSettings) => {
  const nextIndex = findNextIndex(
    dataSettings.incisionsInHeader.map(({ name }) => name),
    defaultIncisionInHeaderName,
  );
  const incision = getTableIncision({ id: v4(), name: `${defaultIncisionInHeaderName}${nextIndex}` });

  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      incisionsInHeader: [...dataSettings.incisionsInHeader, incision],
      activeIncisionId: undefined,
    }),
  );
};

/* Incision in header change */

export const onDeleteIncisionInHeader = (dataSettings: TableDataSettings, incisionId: string) => {
  const newIncisions = dataSettings.incisionsInHeader.filter(({ id }) => id !== incisionId);

  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      incisionsInHeader: newIncisions,
      activeIncisionId: undefined,
    }),
  );
};

export const onChangeIncisionCustomRequest: OnChangeValue<string | null> = (dataSettings, customRequest, id) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, customRequest } } : incision,
      ),
    }),
  );

/* Indicator change */

export const onChangeIndicatorNameFromDataBase: OnChangeValue<boolean> = (dataSettings, nameFromDatabase, id) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, nameFromDatabase } } : indicator,
      ),
    }),
  );

export const onChangeIndicatorEmptyValue: OnChangeValue<IndicatorEmptyValuesInterface> = (dataSettings, emptyValues, id) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) => (id === indicator.id ? { ...indicator, emptyValues } : indicator)),
    }),
  );

export const onChangeIndicatorName: OnChangeValue<string> = (dataSettings, name, id) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) => (id === indicator.id ? { ...indicator, name } : indicator)),
    }),
  );

export const onChangeIndicatorFieldName: OnChangeValue<string> = (dataSettings, fieldName, id) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) => (id === indicator.id ? { ...indicator, fieldName } : indicator)),
    }),
  );

export const onChangeIndicatorCustomRequest: OnChangeValue<string | null> = (dataSettings, customRequest, id) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, customRequest, operationType: 'other' } : indicator,
      ),
    }),
  );

export const onChangeOperationType: OnChangeValue<VisualisationOperationTypesType> = (dataSettings, operationType, id) => {
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id
          ? { ...indicator, operationType, customRequest: operationType !== 'other' ? null : indicator.customRequest }
          : indicator,
      ),
    }),
  );
};

export const onChangeIndicatorFormatting: OnChangeValue<FormattingInterface> = (dataSettings, formatting, id) =>
  dispatch(
    updateDataSettingsAction({
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, formatting } } : indicator,
      ),
    }),
  );

export const onDeleteIndicator = (dataSettings: DefaultDataSettingsInterface, indicatorId: string) => {
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.filter(({ id }) => id !== indicatorId),
    }),
  );
};

export const onAddNewIndicator = (dataSettings: DefaultDataSettingsInterface, type: VisualisationType) => {
  const indicator = getNewIndicator({ dataSettings, type });

  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      indicators: [...dataSettings.indicators, indicator],
    }),
  );
};

export const formatResult = ({ value, label }: { value: string; label: number }) => `${label}${value}`;
