import { Formatting } from 'store/reducers/visualisations/types';
import { DrobIcon, ExpIcon, MoneyIcon, NumberIcon, PercentIcon } from 'assets/icons/editor';
import { IconType } from 'assets/icons/withContainer';

export const dataFormattingMethods: Record<Formatting, string> = {
  numerical: 'Число',
  percent: 'Процент',
  money: 'Валюта',
  /* TODO: There will be formatting by date */
  // date: 'Дата',
  fraction: 'Дробное',
  exponential: 'Экспоненциальный',
};

export const formatIcons: Record<Formatting, IconType> = {
  numerical: NumberIcon,
  percent: PercentIcon,
  money: MoneyIcon,
  fraction: DrobIcon,
  exponential: ExpIcon,
};
