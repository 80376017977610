import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ColorPickerTypesInterface } from 'modules/settingsContainer/ColorPicker/types';
import { defaultScrollbarMixin } from 'constants/styles';

export const ImagePickerWrapper = styled.div`
  position: relative;
`;

export const ImageBlock = styled.div<ColorPickerTypesInterface>`
  display: flex;
  flex-direction: column;
  z-index: 100;
  background-color: var(${ColorVarsEnum.Level_3_menu});
  border: 1px solid var(${ColorVarsEnum.Level_4});
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 0;
`;

interface ImageActivatorContainerProps {
  width?: string;
  height?: string;
  backgroundImage?: string | undefined | null;
}
export const ImageActivatorContainer = styled.div<ImageActivatorContainerProps>`
  display: flex;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-size: cover;
  background-color: var(${ColorVarsEnum.Level_5});
  cursor: pointer;
  border-radius: 50%;
  ${defaultScrollbarMixin};
`;
