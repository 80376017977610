import {
  AliasInterface,
  ColorByConditionInterface,
  ColorByValueInterface,
  DefaultByInterface,
  DefaultIndicatorInterface,
  VisualisationDataInterface,
} from 'store/reducers/visualisations/types';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { getGradientInterpolator, getMaxAndMinFromArray, isColor } from 'utils/utils';
import { useCallback, useMemo } from 'react';
import { getVisualisationFieldName } from 'store/reducers/visualisations/constants';

interface GetColorByValueParams extends AliasInterface {
  indicatorName: string;
  value?: number | string | null;
}

type GetColorByValueType = (params: GetColorByValueParams) => string;

interface ColorByInterface extends DefaultByInterface<string> {
  byCondition: ColorByConditionInterface;
  byValue?: ColorByValueInterface;
}

export interface ColorByParams extends VisualisationDataInterface {
  colorsBy?: ColorByInterface[];
  indicators?: DefaultIndicatorInterface[];
}

export const useColorBy = ({ visualisationValues, colorsBy = [], indicators }: ColorByParams) => {
  const { getColorValues, defaultColor } = useColorValues();

  const colorsByConditionMapper = useMemo(() => {
    return colorsBy?.reduce<Record<string, Record<string, string>>>(
      (result, { byCondition: { alias, colors } }) => ({
        ...result,
        [alias]: colors.reduce((result, { value }, index) => ({ ...result, [index + 1]: getColorValues(value) }), {}),
      }),
      {},
    );
  }, [colorsBy, getColorValues]);

  const colorByValue: (ColorByInterface['byValue'] | undefined)[] = useMemo(
    () => colorsBy.map((color) => color?.byValue),
    [colorsBy],
  );

  const colorByIndicatorsMapper = useMemo<Record<string, Record<string, ReturnType<typeof getGradientInterpolator>>>>(
    () =>
      (indicators || [])?.reduce((result, { id, name, fieldName, settings: { nameFromDatabase } }) => {
        const indicatorName = getVisualisationFieldName({ fieldName, name, nameFromDatabase }),
          indicatorData = visualisationValues?.[indicatorName],
          { max, min } = getMaxAndMinFromArray(indicatorData);

        const colors = colorByValue.map((color) => {
          return (
            color?.colors?.[id]?.map(({ value }) => {
              return { alias: color?.alias, color: getColorValues(value) || defaultColor };
            }) || []
          );
        });

        const indicatorColors = colors?.reduce((result, value) => {
          return {
            ...result,
            [value[0]?.alias]: getGradientInterpolator({
              colors: value.map((value) => value?.color),
              minValue: min,
              maxValue: max,
            }),
          };
        }, {});

        return {
          ...result,
          [indicatorName]: indicatorColors,
        };
      }, {}),
    [indicators, visualisationValues, colorByValue, defaultColor, getColorValues],
  );

  const getColorValueByCondition = useCallback(
    (alias: string, value?: number | string | null) => {
      if (isColor(value)) {
        return value as string;
      }

      return colorsByConditionMapper[alias]?.[value || ''] || defaultColor;
    },
    [colorsByConditionMapper, defaultColor],
  );

  const getColorValueByValue = useCallback<GetColorByValueType>(
    ({ indicatorName, value, alias }) => {
      if (!value || typeof value === 'string') return defaultColor;

      return colorByIndicatorsMapper[indicatorName][alias]?.(value) || defaultColor;
    },
    [colorByIndicatorsMapper, defaultColor],
  );

  const isColorByCondition = useCallback(
    (aliasByCondition: string) =>
      colorsBy.some(({ byCondition: { alias }, type }) => alias === aliasByCondition && type === 'condition'),
    [colorsBy],
  );

  const getColorByValue = useCallback<GetColorByValueType>(
    ({ indicatorName, value, alias }) =>
      isColorByCondition(alias) ? getColorValueByCondition(alias, value) : getColorValueByValue({ indicatorName, value, alias }),
    [isColorByCondition, getColorValueByCondition, getColorValueByValue],
  );

  const getVisualisationColorsAndImagesData = (alias: string) => visualisationValues?.[alias] || [];

  return {
    getColorByValue,
    getColorValueByCondition,
    getVisualisationColorsAndImagesData,
    getColorValueByValue,
    isColorByCondition,
  };
};
