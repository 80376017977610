import { useCallback } from 'react';
import { openConfirmationModalAction } from 'store/reducers/modals/actions';
import { useAppDispatch } from 'store';
import { changeActiveUserAction, deleteByIdUserAction, deleteUserAction } from 'store/reducers/adminUsers/actions';
import { useSelector } from 'react-redux';
import { getUsers } from 'store/reducers/adminUsers/getters';

export const useDeleteUser = () => {
  const dispatch = useAppDispatch();
  const { usersList } = useSelector(getUsers);

  const onDelete = useCallback(
    async (userId: string) => {
      try {
        const action = await dispatch(deleteUserAction(userId)).unwrap();
        if (action) {
          await dispatch(deleteByIdUserAction(action));
          await dispatch(changeActiveUserAction({ id: usersList[0]?.id, login: usersList[0]?.login }));
        }
      } catch (e) {
        console.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onDeleteModal = useCallback(
    ({ id, title }: { id: string; title: string }) =>
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          subTitleText: `Действительно удалить пользователя ${title}?`,
          onConfirm: () => onDelete(id),
          titleText: 'Удалиение пользователя',
          width: '320px',
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  return { onDelete, onDeleteModal };
};
