import React, { FC } from 'react';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { CircularProgress } from '@mui/material';
import { FlexContainer } from 'styles/FlexContainer';

type CustomCircularProgress = {
  value: number;
  size?: number;
};
export const CustomCircularProgress: FC<CustomCircularProgress> = ({ value, size = 36 }) => {
  return (
    <FlexContainer position="relative" display="inline-flex" height="36px">
      <CircularProgress
        variant="determinate"
        value={100}
        size={size}
        thickness={4}
        style={{
          color: `var(${ColorVarsEnum.Level_5})`,
        }}
      />
      <CircularProgress
        variant="determinate"
        value={value}
        size={size}
        thickness={4}
        style={{
          color: `var(${ColorVarsEnum.Accent})`,
          position: 'absolute',
          left: 0,
        }}
      />
    </FlexContainer>
  );
};
