import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DatabaseForm } from 'components/console/elements/sideBar/Forms/DatabaseForm';
import { RenderFooterProps } from 'components/console/elements/sideBar/Forms/DatabaseForm/types';
import { Button } from 'modules/ui';
import { ModalComponentType } from 'store/reducers/modals/types';
import { UpdateConnection } from 'store/reducers/sources/types';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { useSourceEditData } from 'components/console/elements/hooks/useSourceData';
import { DeleteIcon } from 'assets/icons/withContainer';

interface SourceEditModalProps {
  id: string;
  projectId: string;
}

export const SourceDataBaseEditModal: ModalComponentType<SourceEditModalProps> = ({ onClose, id, projectId }) => {
  const { loading, editSource, onDeleteModal } = useSourceEditData<UpdateConnection<'database'>>(projectId || '', id);

  // TODO: We need to make a separate component for rendering loaves
  const renderFooter = ({ onClose }: RenderFooterProps): JSX.Element => (
    <FlexContainer width="100%" justifyContent="space-between">
      <Button text="Удалить подключение" heightSize="normal" leftIcon={<DeleteIcon />} onClick={() => onDeleteModal(id)} />

      <FlexContainer gap="10px">
        <Button text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Готово" iconSize="normal" heightSize="normal" type="submit" />
      </FlexContainer>
    </FlexContainer>
  );

  return (
    <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" padding="12px" height="100%">
      <LoadingOverlay loading={loading} />

      <DatabaseForm mode="edit" onClose={onClose} data={editSource} renderFooter={renderFooter} />
    </FlexContainer>
  );
};
