import { ElementContainerSettings, GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { Button, TextField } from 'modules/ui';
import { DeleteIcon } from 'assets/icons/withContainer';
import React from 'react';
import { PaletteItemInterface } from 'store/reducers/palettes/types';
import { NoopType, NoopValueType } from 'types/global';

interface PaletteControlSettingsProps {
  palette: PaletteItemInterface;
  onAddPalette: NoopType;
  onDeletePalette: NoopType;
  onCopyPalette: NoopType;
  onPaletteNameChange: NoopValueType<string>;
  disableDeletePalette: boolean;
}

export const PaletteControlSettings = ({
  palette,
  onAddPalette,
  onCopyPalette,
  onPaletteNameChange,
  onDeletePalette,
  disableDeletePalette,
}: PaletteControlSettingsProps) => {
  return (
    <MainContainerSettings isOpenDefault>
      <GroupContainerSettings>
        <FlexContainer flexDirection="column" width="100%" gap="15px">
          <PrimaryTextSpan lineHeight="10px">Создать палитру</PrimaryTextSpan>
          <FlexContainer gap="10px" justifyContent="space-between" marginBottom="20px">
            <Button text="На основе текущей" width="100%" heightSize="small" onClick={onCopyPalette} />
            <Button text="Новая палитра" width="100%" heightSize="small" onClick={onAddPalette} />
          </FlexContainer>
        </FlexContainer>
        <ElementContainerSettings>
          <FlexContainer width="100%" gap="15px" flexDirection="column">
            <PrimaryTextSpan lineHeight="10px">Переименовать палитру</PrimaryTextSpan>
            <FlexContainer width="100%">
              <TextField
                onChange={(e) => onPaletteNameChange(e.target.value)}
                useDebounce
                value={palette.name}
                name="palette_name"
                needBackground={false}
                needBorderBottom={false}
              />
            </FlexContainer>
            <FlexContainer width="100%" justifyContent="flex-end">
              <Button
                onClick={onDeletePalette}
                disabled={disableDeletePalette}
                text="Удалить палитру"
                width="132px"
                heightSize="small"
                leftIcon={<DeleteIcon />}
              />
            </FlexContainer>
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
