import { HexColorEditor } from 'modules/ui/colors/HexColorEditor';
import { HlsColorEditor, HlsColorEditorProps } from 'modules/ui/colors/HlsColorEditor';
import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorEditType, HexAndHLSColorInterface } from 'types/store';
import { hsl } from 'd3';

interface HexAndHlsColorEditorProps extends Pick<HlsColorEditorProps, 'disableHls'> {
  value: HexAndHLSColorInterface;
  onChange: (color: HexAndHLSColorInterface, type: ColorEditType) => void;
}

export const HexAndHlsColorEditor = ({ value, onChange, disableHls }: HexAndHlsColorEditorProps) => {
  const onHexColorChange = (hex: string) => {
    let h = 0,
      s = 0,
      l = 0;

    const hlsColor = hsl(hex);

    if (!isNaN(hlsColor.h)) {
      h = hlsColor.h;
    }

    if (!isNaN(hlsColor.s)) {
      s = hlsColor.s * 100;
    }

    if (!isNaN(hlsColor.l)) {
      l = hlsColor.l * 100;
    }

    onChange({ h: Math.floor(h), s: Math.floor(s || 0), l: Math.floor(l || 0), hex }, 'hex');
  };

  return (
    <FlexContainer flexDirection="column" width="100%">
      <HexColorEditor value={value.hex} onChange={onHexColorChange} />
      <HlsColorEditor disableHls={disableHls} value={value} onChange={(color, type) => onChange({ ...value, ...color }, type)} />
    </FlexContainer>
  );
};
