import { CSSProperties, memo } from 'react';
import { FilterValueItem } from './styles';
import { areEqual } from 'react-window';
import { StyledTooltip } from 'modules/ui/StyledTooltip';

interface RowItemProps {
  index: number;
  style: CSSProperties;
  data: {
    values: string[];
    isSelectedFilterValue: (value: string) => boolean;
    onSelectFilterValue: (value: string) => () => void;
  };
}

export const FilterValuesRow = memo(({ index, style, data }: RowItemProps) => {
  const { values, isSelectedFilterValue, onSelectFilterValue } = data;
  const value = values[index];

  const normalizedValue = value !== '' ? value : 'NULL';

  return (
    <StyledTooltip placement="left" title={normalizedValue}>
      <FilterValueItem style={style} selected={isSelectedFilterValue(value)} onClick={onSelectFilterValue(value)}>
        {normalizedValue}
      </FilterValueItem>
    </StyledTooltip>
  );
}, areEqual);

FilterValuesRow.displayName = 'FilterValuesRow';
