import {
  copyFlowProjectVersionAnother,
  createFlow,
  createFlowAccess,
  createFlowProjectVersionAnother,
  deleteFlow,
  deleteFlowGroupAccess,
  deleteFlowProject,
  deleteFlowUserAccess,
  loadFlowAccess,
  loadFlowProjectHistoryVersion,
  loadFlowProjectInfo,
  loadFlowProjects,
  loadFlows,
  loadNoFlowAccess,
  loadShortFlowProject,
  moveFlowProject,
  protectFlowProject,
  renameFlowProject,
  restoreHistoryFlowProject,
  updateFlow,
  updateFlowGroupAccess,
  updateFlowUserAccess,
} from 'store/reducers/adminFlows/api';
import { getFlowAccess, getFlowProjects, getFlows } from 'store/reducers/adminFlows/getters';
import {
  addFlow,
  changeActiveFlow,
  deleteByIdFlow,
  deleteByIdFlowAccess,
  deleteByIdFlowProject,
  updateFlowAccess,
  updateFlowProjects,
  updateFlows,
} from 'store/reducers/adminFlows/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import {
  CreateFlowAccessPayload,
  CreateFlowPayload,
  DeleteFlowGroupAccessPayload,
  DeleteFlowPayload,
  DeleteFlowUserAccessPayload,
  FlowAccessListInterface,
  FlowsActionsTypes,
  FlowsListInterface,
  LoadFlowAccessPayload,
  LoadFlowProjectsPayload,
  UpdateFlowGroupAccessPayload,
  UpdateFlowPayload,
  UpdateFlowUserAccessPayload,
} from 'store/reducers/adminFlows/types';
import { TState } from 'store/index';
import { UpdateUserDraftProjectByIdPayload, UserDeleteDraftProjectsPayload } from 'store/reducers/adminUsers/types';
import {
  CopyProjectVersionAnotherPayload,
  CreateProjectVersionAnotherPayload,
  FlowListInterface,
  FlowProjectHistoryVersion,
  FlowProjectInfoInterface,
  FlowShortListInterface,
  MoveProjectPayload,
  ProtectProjectPayload,
  RenameProjectPayload,
  RestoreHistoryProjectPayload,
} from 'store/reducers/projectManager/types';
import Snackbar from 'services/Snackbar';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');

  return rejectWithValue(errorMessage);
};

export const changeActiveFlowAction = createAsyncThunk<void, FlowsListInterface>(
  FlowsActionsTypes.ACTIVE_FLOW_ID,
  (props, { dispatch }) => {
    dispatch(changeActiveFlow(props));
  },
);

export const loadFlowsAction = createAsyncThunk<FlowsListInterface[], void, { rejectValue: null }>(
  FlowsActionsTypes.LOAD_FLOWS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await loadFlows();

      return response.data.adminFlowsList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FlowsListInterface[];
    }
  },
);

export const createFlowAction = createAsyncThunk<FlowsListInterface, CreateFlowPayload, { rejectValue: null }>(
  FlowsActionsTypes.CREATE_FLOW,
  async (params, { rejectWithValue }) => {
    try {
      const response = await createFlow(params);
      Snackbar.show('Поток создана', 'success');

      return response.data.adminFlow;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const addFlowAction = createAsyncThunk<void, FlowsListInterface>(FlowsActionsTypes.ADD_FLOW, (data, { dispatch }) => {
  dispatch(addFlow(data));
});

export const updateFlowAction = createAsyncThunk<FlowsListInterface, UpdateFlowPayload>(
  FlowsActionsTypes.UPDATE_FLOW,
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateFlow(params);
      Snackbar.show('Изменение успешно сохранены', 'success');

      return response.data.adminFlow;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateFlowsAction = createAsyncThunk<void, { flow: FlowsListInterface }>(
  FlowsActionsTypes.UPDATE_FLOWS,
  ({ flow }, { dispatch, getState }) => {
    const flows = getFlows(getState() as TState).flowsList.map((value) => (value.id === flow.id ? { ...value, ...flow } : value));

    dispatch(updateFlows(flows));
  },
);

export const deleteFlowAction = createAsyncThunk<string, DeleteFlowPayload, { rejectValue: null }>(
  FlowsActionsTypes.DELETE_FLOW,
  async (params, { rejectWithValue }) => {
    try {
      const response = await deleteFlow(params);
      Snackbar.show('Удалено', 'success');

      return response.data.adminFlowDeleteMessage;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const deleteByIdFlowAction = createAsyncThunk(FlowsActionsTypes.DELETE_BY_ID_FLOW, (flowId: string, { dispatch }) => {
  dispatch(deleteByIdFlow({ id: flowId }));
});

/* Flow access */

export const loadFlowAccessAction = createAsyncThunk<FlowAccessListInterface[], LoadFlowAccessPayload, { rejectValue: null }>(
  FlowsActionsTypes.LOAD_FLOW_ACCESS,
  async (params, { rejectWithValue }) => {
    try {
      const response = await loadFlowAccess(params);

      return response.data.adminFlowUsersAndGroupsList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FlowAccessListInterface[];
    }
  },
);

export const loadNoFlowAccessAction = createAsyncThunk<
  FastBoard.API.ApiAdminUserGroupListItemResponseDTO[],
  LoadFlowAccessPayload,
  { rejectValue: null }
>(FlowsActionsTypes.LOAD_NO_FLOW_ACCESS, async (params, { rejectWithValue }) => {
  try {
    const response = await loadNoFlowAccess(params);

    return response.data.adminUserGroupList;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as FastBoard.API.ApiAdminUserGroupListItemResponseDTO[];
  }
});

export const createFlowAccessAction = createAsyncThunk<string, CreateFlowAccessPayload, { rejectValue: null }>(
  FlowsActionsTypes.CREATE_FLOW_ACCESS,
  async (params, { rejectWithValue }) => {
    try {
      const response = await createFlowAccess(params);
      Snackbar.show('Доступ добавлен', 'success');

      return response.data.adminFlowAddGroupsAndUsersMessage;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateFlowUserAccessAction = createAsyncThunk<FlowAccessListInterface, UpdateFlowUserAccessPayload>(
  FlowsActionsTypes.UPDATE_FLOW_USER_ACCESS,
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateFlowUserAccess(params);
      Snackbar.show('Изменение успешно сохранены', 'success');

      return response.data.adminFlowUser;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateFlowGroupAccessAction = createAsyncThunk<FlowAccessListInterface, UpdateFlowGroupAccessPayload>(
  FlowsActionsTypes.UPDATE_FLOW_GROUP_ACCESS,
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateFlowGroupAccess(params);
      Snackbar.show('Изменение успешно сохранены', 'success');

      return response.data.adminFlowGroup;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateFlowsAccessAction = createAsyncThunk<void, { access: FlowAccessListInterface }>(
  FlowsActionsTypes.UPDATE_FLOWS_ACCESS,
  ({ access }, { dispatch, getState }) => {
    const accesses = getFlowAccess(getState() as TState).flowAccessList.map((value) =>
      value.id === access.id ? { ...value, ...access } : value,
    );

    dispatch(updateFlowAccess(accesses));
  },
);

export const deleteFlowUserAccessAction = createAsyncThunk<string, DeleteFlowUserAccessPayload, { rejectValue: null }>(
  FlowsActionsTypes.DELETE_FLOW_USER_ACCESS,
  async (params, { rejectWithValue }) => {
    try {
      const response = await deleteFlowUserAccess(params);
      Snackbar.show('Удалено', 'success');

      return response.data.adminFlowUser;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const deleteFlowGroupAccessAction = createAsyncThunk<string, DeleteFlowGroupAccessPayload, { rejectValue: null }>(
  FlowsActionsTypes.DELETE_FLOW_GROUP_ACCESS,
  async (params, { rejectWithValue }) => {
    try {
      const response = await deleteFlowGroupAccess(params);
      Snackbar.show('Удалено', 'success');

      return response.data.adminFlowGroup;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const deleteByIdFlowAccessAction = createAsyncThunk(
  FlowsActionsTypes.DELETE_BY_ID_FLOW_ACCESS,
  (accessId: string, { dispatch }) => {
    dispatch(deleteByIdFlowAccess({ id: accessId }));
  },
);

/* Flow projects */

export const loadFlowProjectsAction = createAsyncThunk<FlowListInterface[], LoadFlowProjectsPayload>(
  FlowsActionsTypes.LOAD_FLOW_PROJECTS,
  async (params, { rejectWithValue }) => {
    try {
      const response = await loadFlowProjects(params);

      return response.data.adminProjectList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FlowListInterface[];
    }
  },
);
export const loadMoveFlowProjectsAction = createAsyncThunk<FlowListInterface[], LoadFlowProjectsPayload>(
  FlowsActionsTypes.LOAD_MOVE_FLOW_PROJECTS,
  async (params, { rejectWithValue }) => {
    try {
      const response = await loadFlowProjects(params);

      return response.data.adminProjectList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FlowListInterface[];
    }
  },
);

export const loadFlowProjectInfoAction = createAsyncThunk<FlowProjectInfoInterface, UserDeleteDraftProjectsPayload>(
  FlowsActionsTypes.LOAD_FLOW_PROJECT_INFO,
  async ({ projectId }, { rejectWithValue }) => {
    try {
      const response = await loadFlowProjectInfo({ projectId });

      const { updated, created, ...props } = response.data.adminProjectMeta;

      return {
        updated: { dateAt: updated?.updatedAt, userName: updated?.user?.name },
        created: { dateAt: created?.createdAt, userName: created?.user?.name },
        ...props,
      } as FlowProjectInfoInterface;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return {} as FlowProjectInfoInterface;
    }
  },
);

export const loadFlowProjectHistoryVersionAction = createAsyncThunk<FlowProjectHistoryVersion[], UserDeleteDraftProjectsPayload>(
  FlowsActionsTypes.LOAD_FLOW_PROJECT_HISTORY_VERSION,
  async (params, { rejectWithValue }) => {
    try {
      const response = await loadFlowProjectHistoryVersion(params);

      const res = response.data.adminProjectVersionHistory;
      return res.map(({ id, ts, user, method }) => ({
        id,
        dateTimeHistory: ts,
        userName: user?.login,
        storageMethod: method,
      })) as FlowProjectHistoryVersion[];
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FlowProjectHistoryVersion[];
    }
  },
);

export const restoreHistoryFlowProjectAction = createAsyncThunk<
  FastBoard.API.IncomingWriteResponseDTO[],
  RestoreHistoryProjectPayload,
  { rejectValue: null }
>(FlowsActionsTypes.RESTORE_HISTORY_FLOW_PROJECT, async (params, { rejectWithValue }) => {
  try {
    const response = await restoreHistoryFlowProject(params);
    Snackbar.show('Версия успешно применена', 'success');

    return response.data.adminIncomings;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as FastBoard.API.IncomingWriteResponseDTO[];
  }
});

export const createFlowProjectVersionAnotherAction = createAsyncThunk<
  string,
  CreateProjectVersionAnotherPayload,
  { rejectValue: null }
>(FlowsActionsTypes.CREATE_FLOW_PROJECT_VERSION_ANOTHER, async (params, { rejectWithValue }) => {
  try {
    const response = await createFlowProjectVersionAnother(params);
    Snackbar.show('Копия успешно создана', 'success');

    return response.data.adminProjectVersionCreateFromMessage;
  } catch (err: any) {
    return validateError(err, rejectWithValue);
  }
});

export const copyFlowProjectVersionAnotherAction = createAsyncThunk<
  FastBoard.API.IncomingWriteResponseDTO[],
  CopyProjectVersionAnotherPayload,
  { rejectValue: null }
>(FlowsActionsTypes.COPY_FLOW_PROJECT_VERSION_ANOTHER, async (params, { rejectWithValue }) => {
  try {
    const response = await copyFlowProjectVersionAnother(params);
    Snackbar.show('Проект успешно заменен', 'success');

    return response.data.adminIncomings;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as FastBoard.API.IncomingWriteResponseDTO[];
  }
});

export const moveFlowProjectAction = createAsyncThunk<string, MoveProjectPayload, { rejectValue: null }>(
  FlowsActionsTypes.MOVE_FLOW_PROJECT,
  async (params, { rejectWithValue }) => {
    try {
      const response = await moveFlowProject(params);
      Snackbar.show('Проект успешно перемещен', 'success');

      return response.data.adminProjectTransferToFlowMessage;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const deleteFlowProjectProjects = createAsyncThunk<string, UserDeleteDraftProjectsPayload, { rejectValue: null }>(
  FlowsActionsTypes.DELETE_FLOW_PROJECT,
  async ({ projectId }, { rejectWithValue }) => {
    try {
      const response = await deleteFlowProject({ projectId });
      Snackbar.show('Удалено', 'success');

      return response;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const deleteByIdFlowProjectAction = createAsyncThunk(
  FlowsActionsTypes.DELETE_BY_ID_FLOW_PROJECT,
  (id: string, { dispatch }) => {
    dispatch(deleteByIdFlowProject({ id }));
  },
);

export const protectFlowProjectAction = createAsyncThunk<string, ProtectProjectPayload>(
  FlowsActionsTypes.FLOW_PROTECT_PROJECT,
  async ({ projectId, isProtected }, { rejectWithValue }) => {
    try {
      const response = await protectFlowProject({ projectId, isProtected });
      Snackbar.show(isProtected ? 'Защита включена' : 'Защита выключена', 'success');

      return response.data.adminProjectUpdateProtectMessage;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateFlowProjectByIdAction = createAsyncThunk<void, UpdateUserDraftProjectByIdPayload>(
  FlowsActionsTypes.UPDATE_FLOW_PROJECT_BY_ID,
  ({ userDraftProject }, { dispatch, getState }) => {
    const flowProjects = getFlowProjects(getState() as TState).flowProjectsList.map((value) =>
      value?.id === userDraftProject?.id ? { ...value, ...userDraftProject } : value,
    );

    dispatch(updateFlowProjects(flowProjects));
  },
);

export const renameFlowProjectAction = createAsyncThunk<FastBoard.API.ProjectListItemDTO, RenameProjectPayload>(
  FlowsActionsTypes.RENAME_FLOW_PROJECT,
  async (params, { rejectWithValue }) => {
    try {
      const response = await renameFlowProject(params);
      Snackbar.show('Проект переименован', 'success');

      return response.data.adminProject;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadShortFlowProjectAction = createAsyncThunk<FlowShortListInterface[], string>(
  FlowsActionsTypes.LOAD_SHORT_FLOW_PROJECTS,
  async (id, { rejectWithValue }) => {
    try {
      const response = await loadShortFlowProject(id);

      return response.data.adminProjectShortList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FlowShortListInterface[];
    }
  },
);
