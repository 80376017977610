import { KeysLocalStorage } from 'constants/LocalStorage';
import { ThemeItemInterface, ThemesStateInterface } from 'store/reducers/themes/types';
import { v4 as uuidv4 } from 'uuid';
import { IdInterface, NameInterface } from 'types/store';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PayloadAction } from '@reduxjs/toolkit';

const getCommonActiveThemeIdFromLocal = (): string | null => localStorage.getItem(KeysLocalStorage.activeCommonThemeId) || null;

export const initialThemesStoreState: ThemesStateInterface = {
  themes: {},
  serverStateOfThemes: null,
  themesLoading: false,
  activeThemeId: getCommonActiveThemeIdFromLocal(),
  serverStateOfActiveThemeId: null,
  activeThemeLoading: false,
  paletteLinks: [],
  serverStateOfPaletteLinks: null,
  paletteLinksLoading: false,
};

export const getThemeItem: (
  params: Partial<IdInterface> & Partial<NameInterface> & Omit<ThemeItemInterface, 'id' | 'name'>,
) => ThemeItemInterface = ({ id, name, elementColors, backgroundColors, subBackgroundColors, subElementColors }) => ({
  id: id || uuidv4(),
  name: name || 'Новая тема',
  codeEditorTheme: 'dark',
  elementColors,
  backgroundColors,
  subBackgroundColors,
  subElementColors,
});

export const defaultThemes: ThemeItemInterface[] = [
  {
    id: 'b5b1af92-df8e-4cd1-ae17-710b4161f971',
    name: 'Светлая тема',
    codeEditorTheme: 'light',
    elementColors: [
      {
        name: 'Level 1',
        hex: '#4E575E',
        h: 206,
        s: 9,
        l: 34,
        cssVariable: ColorVarsEnum.Level_1,
      },
      {
        name: 'Level 2',
        hex: '#7F869A',
        h: 224,
        s: 12,
        l: 55,
        cssVariable: ColorVarsEnum.Level_2,
      },
      {
        name: 'Level 3',
        hex: '#8F96A3',
        h: 219,
        s: 10,
        l: 60,
        cssVariable: ColorVarsEnum.Level_3,
      },
      {
        name: 'Level 4',
        hex: '#C9CDD3',
        h: 216,
        s: 10,
        l: 81,
        cssVariable: ColorVarsEnum.Level_4,
      },
      {
        name: 'Level 5',
        hex: '#DDDFE3',
        h: 220,
        s: 10,
        l: 88,
        cssVariable: ColorVarsEnum.Level_5,
      },
      {
        name: 'Accent',
        hex: '#26A5ED',
        h: 202,
        s: 85,
        l: 54,
        cssVariable: ColorVarsEnum.Accent,
      },
      {
        name: 'Alert',
        hex: '#D95050',
        h: 0,
        s: 64,
        l: 58,
        cssVariable: ColorVarsEnum.Alert,
      },
    ],
    backgroundColors: [
      {
        name: 'L1 (ховер кн)',
        hex: '#E3E5E8',
        h: 216,
        s: 10,
        l: 90,
        cssVariable: ColorVarsEnum.Level_1_hover_btn,
      },
      {
        name: 'L2 (кнопка)',
        hex: '#EBECEF',
        h: 225,
        s: 11,
        l: 93,
        cssVariable: ColorVarsEnum.Level_2_btn,
      },
      {
        name: 'L3 (меню)',
        hex: '#F6F7F8',
        h: 210,
        s: 12,
        l: 97,
        cssVariable: ColorVarsEnum.Level_3_menu,
      },
      {
        name: 'L4 (виджет)',
        hex: '#F8F8F9',
        h: 240,
        s: 8,
        l: 97,
        cssVariable: ColorVarsEnum.Level_4_widget,
      },
      {
        name: 'L5 (приложение)',
        hex: '#F1F2F4',
        h: 220,
        s: 12,
        l: 95,
        cssVariable: ColorVarsEnum.Level_5_application,
      },
    ],
    subElementColors: [
      {
        name: 'Элем. 1',
        hex: '#fff',
        h: 0,
        s: 0,
        l: 100,
        cssVariable: ColorVarsEnum.Sub_Level_1,
      },
      {
        name: 'Элем. 2',
        hex: '#b9bdc6',
        h: 222,
        s: 10,
        l: 75,
        cssVariable: ColorVarsEnum.Sub_Level_2,
      },
      {
        name: 'Элем. 3',
        hex: '#8f93a3',
        h: 228,
        s: 9,
        l: 60,
        cssVariable: ColorVarsEnum.Sub_Level_3,
      },
    ],
    subBackgroundColors: [
      {
        name: 'Фон 1',
        hex: '#4e525e',
        h: 225,
        s: 9,
        l: 34,
        cssVariable: ColorVarsEnum.Sub_Level_bg_1,
      },
      {
        name: 'Фон 2',
        hex: '#7f869a',
        h: 224,
        s: 12,
        l: 55,
        cssVariable: ColorVarsEnum.Sub_Level_bg_2,
      },
      {
        name: 'Фон 3',
        hex: '#292831',
        h: 226,
        s: 10,
        l: 75,
        cssVariable: ColorVarsEnum.Sub_Level_bg_3,
      },
    ],
  },
  {
    id: 'a046d0bb-278e-4773-a4dd-ea81862104e3',
    name: 'Темная тема',
    codeEditorTheme: 'dark',
    elementColors: [
      {
        name: 'Level 1',
        hex: '#E2E1EA',
        h: 247,
        s: 18,
        l: 90,
        cssVariable: ColorVarsEnum.Level_1,
      },
      {
        name: 'Level 2',
        hex: '#95939F',
        h: 250,
        s: 6,
        l: 60,
        cssVariable: ColorVarsEnum.Level_2,
      },
      {
        name: 'Level 3',
        hex: '#7B7986',
        h: 249,
        s: 5,
        l: 50,
        cssVariable: ColorVarsEnum.Level_3,
      },
      {
        name: 'Level 4',
        hex: '#63616B',
        h: 252,
        s: 5,
        l: 40,
        cssVariable: ColorVarsEnum.Level_4,
      },
      {
        name: 'Level 5',
        hex: '#44424C',
        h: 252,
        s: 7,
        l: 28,
        cssVariable: ColorVarsEnum.Level_5,
      },
      {
        name: 'Accent',
        hex: '#0DC2FF',
        h: 195,
        s: 100,
        l: 53,
        cssVariable: ColorVarsEnum.Accent,
      },
      {
        name: 'Alert',
        hex: '#D95050',
        h: 0,
        s: 64,
        l: 58,
        cssVariable: ColorVarsEnum.Alert,
      },
    ],
    backgroundColors: [
      {
        name: 'L1 (ховер кн)',
        hex: '#494851',
        h: 247,
        s: 6,
        l: 30,
        cssVariable: ColorVarsEnum.Level_1_hover_btn,
      },
      {
        name: 'L2 (кнопка)',
        hex: '#413F4A',
        h: 251,
        s: 8,
        l: 27,
        cssVariable: ColorVarsEnum.Level_2_btn,
      },
      {
        name: 'L3 (меню)',
        hex: '#363540',
        h: 245,
        s: 9,
        l: 23,
        cssVariable: ColorVarsEnum.Level_3_menu,
      },
      {
        name: 'L4 (виджет)',
        hex: '#2F2E38',
        h: 246,
        s: 10,
        l: 20,
        cssVariable: ColorVarsEnum.Level_4_widget,
      },
      {
        name: 'L5 (приложение)',
        hex: '#292831',
        h: 247,
        s: 10,
        l: 17,
        cssVariable: ColorVarsEnum.Level_5_application,
      },
    ],
    subElementColors: [
      {
        name: 'Элем. 1',
        hex: '#E2E1EA',
        h: 247,
        s: 18,
        l: 90,
        cssVariable: ColorVarsEnum.Sub_Level_1,
      },
      {
        name: 'Элем. 2',
        hex: '#B9BDC6',
        h: 222,
        s: 10,
        l: 75,
        cssVariable: ColorVarsEnum.Sub_Level_2,
      },
      {
        name: 'Элем. 3',
        hex: '#8F93A3',
        h: 228,
        s: 9,
        l: 60,
        cssVariable: ColorVarsEnum.Sub_Level_3,
      },
    ],
    subBackgroundColors: [
      {
        name: 'Фон 1',
        hex: '#5b586a',
        h: 250,
        s: 9,
        l: 38,
        cssVariable: ColorVarsEnum.Sub_Level_bg_1,
      },
      {
        name: 'Фон 2',
        hex: '#484653',
        h: 224,
        s: 12,
        l: 55,
        cssVariable: ColorVarsEnum.Sub_Level_bg_2,
      },
      {
        name: 'Фон 3',
        hex: '#3E3C48',
        h: 250,
        s: 9,
        l: 26,
        cssVariable: ColorVarsEnum.Sub_Level_bg_3,
      },
    ],
  },
];

/* Light Theme */
export const defaultTheme = defaultThemes[0];

export const getCommonThemeById = (activeThemeId: string | null): ThemeItemInterface | null =>
  defaultThemes?.find(({ id }) => activeThemeId === id) || null;

export const removeThemeByIdFunc = (state: ThemesStateInterface, { payload: { id } }: PayloadAction<IdInterface>) => {
  if (state.themes[id]) {
    delete state.themes[id];
  }
};

export const updateThemeByIdFunc = (
  state: ThemesStateInterface,
  { payload: { id, ...data } }: PayloadAction<ThemeItemInterface>,
) => {
  const theme = state.themes[id];

  if (theme) {
    state.themes[id] = { ...theme, ...data };
  }
};
