import { OnValueChange } from 'types/global';
import { ColumnWidthSettings as ColumnWidthSettingsInterface } from 'store/reducers/visualisations/types';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import React from 'react';

type ColumnWidthSettingsProps = OnValueChange<ColumnWidthSettingsInterface>;

export const ColumnWidthSettings = ({ onChange, value }: ColumnWidthSettingsProps) => {
  const { isActive, width } = value;

  return (
    <GroupContainerSettings
      titleText="Фиксированная ширина"
      switcherState={isActive}
      switcherChange={() => onChange({ ...value, isActive: !isActive })}
      countUnit={width}
      thicknessBorderChange={(width) => onChange({ ...value, width })}
    />
  );
};
