import { format } from 'sql-formatter';
import { Parser, Select } from 'node-sql-parser';
import { SqlDataRequestInterface } from 'types/store';

export const formatSql = (sql?: string) => (sql && sql !== '' ? format(sql, { language: 'db2' }) : undefined);

export const combineSqlStrings = (
  originSql: string,
  sqlData: SqlDataRequestInterface,
  sort: string | null | undefined,
  isDataFilter: boolean,
) => {
  const { incisionRequest, filterAndGroupRequest } = sqlData;

  if (!filterAndGroupRequest && !incisionRequest) {
    return originSql;
  }

  try {
    const sqlParser = new Parser();

    const originAst: any = sqlParser.astify(originSql);
    const filterAndGroupAst: any = filterAndGroupRequest.length && sqlParser.astify(`SELECT * ${filterAndGroupRequest}`);
    const incisionsAst: any =
      incisionRequest.length &&
      (isDataFilter
        ? sqlParser.astify(
            `SELECT '"' || toString(toDate(Min(${incisionRequest}))) || '", "' || toString(toDate(Max(${incisionRequest}))) || '"' as value`,
          )
        : sqlParser.astify(`SELECT ${incisionRequest} as value, COUNT(*) as count`));

    const removeNulls = (array: any[]) => array.filter((item) => item !== null);

    const combineConditions = (firstCondition: any, secondCondition: any) => {
      if (firstCondition && secondCondition) {
        return {
          type: 'binary_expr',
          operator: 'AND',
          left: firstCondition,
          right: secondCondition,
        };
      }
      return firstCondition || secondCondition || null;
    };

    const combineLimits = (firstLimit: any, secondLimit: any) => {
      if (firstLimit && secondLimit) {
        return {
          separator: '',
          value: [Math.min(firstLimit.value[0], secondLimit.value[0])],
        };
      }
      return firstLimit || secondLimit || null;
    };

    const orderStrategy = sort
      ? originAst.orderby || filterAndGroupAst.orderby || []
      : filterAndGroupAst.orderby || originAst.orderby || [];

    const finalColumns = incisionsAst.columns?.length
      ? incisionsAst.columns
      : removeNulls((originAst.columns || []).concat(filterAndGroupAst.columns || []).filter((col: string) => col !== '*'));

    const combinedAST: Select = {
      type: 'select',
      options: null,
      distinct: null,
      columns: finalColumns,
      from: removeNulls((originAst.from || []).concat(filterAndGroupAst.from || [])),
      where: combineConditions(originAst.where, filterAndGroupAst.where),
      groupby: removeNulls((originAst.groupby || []).concat(filterAndGroupAst.groupby || [])),
      having: combineConditions(originAst.having, filterAndGroupAst.having),
      orderby: removeNulls(orderStrategy),
      limit: combineLimits(originAst.limit, filterAndGroupAst.limit),
      with: null,
    };

    let sqlString = sqlParser.sqlify(combinedAST);
    sqlString = sqlString.replace(/`/g, '');

    return sqlString;
  } catch (error) {
    console.error('Error combining SQL:', error);
    return originSql;
  }
};
