import { useParams } from 'react-router-dom';
import { ProjectIdParam } from 'constants/Routes';
import { useAppDispatch } from 'store';
import React, { useEffect, useMemo } from 'react';
import { LoadLayout } from 'components/layouts/LoadLayout';
import { ModelsBody, ModelsWrapper, TableListWrapper } from 'pages/Models/styles';
import { TableList } from 'modules/ui/lists/TableList';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { useSelector } from 'react-redux';
import { getActiveTab, getTablesAsArray, getTablesLoading } from 'store/reducers/models/getters';
import CircularProgress from '@mui/material/CircularProgress';
import { FlexContainer } from 'styles/FlexContainer';
import { addTableToActiveTabAction, clearModelsStore, loadTabsAction } from 'store/reducers/models/actions';
import { ModelsBoard } from 'components/contents/Models/ModelsBoard';
import { menuItemsConsole } from 'components/grid/headers/LoadHeader/constants';

export const ModelsPage = () => {
  const { projectId } = useParams<ProjectIdParam>();
  const dispatch = useAppDispatch();

  const tables = useSelector(getTablesAsArray);
  const tablesLoading = useSelector(getTablesLoading);
  const activeTab = useSelector(getActiveTab);

  const tableList = useMemo(
    () =>
      tables.map(({ name }) => ({
        key: name,
        name,
        alreadyAdded: activeTab?.models.some((model) => model.modelItems.some(({ table }) => table === name)),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(tables), JSON.stringify(activeTab?.models)],
  );

  const onTableClick = (key: string) => {
    dispatch(addTableToActiveTabAction(key));
  };

  useEffect(() => {
    dispatch(loadTabsAction(projectId || ''));

    return () => {
      dispatch(clearModelsStore());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <LoadLayout menuItems={menuItemsConsole}>
      <ModelsBody>
        <TableListWrapper>
          <PrimaryTextParagraph fontSize="10px" marginBottom="5px">
            Таблицы
          </PrimaryTextParagraph>
          {tablesLoading ? (
            <FlexContainer width="100%" alignItems="center" justifyContent="center">
              <CircularProgress size="24px" />
            </FlexContainer>
          ) : (
            <TableList list={tableList} onItemClick={onTableClick} />
          )}
        </TableListWrapper>
        <ModelsWrapper>
          <ModelsBoard projectId={projectId || ''} />
        </ModelsWrapper>
      </ModelsBody>
    </LoadLayout>
  );
};
