import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import styled from 'styled-components';

export interface SelectListStyledPropsInterface {
  needBackground: boolean;
  width?: number;
}

export const CustomSelectStyled = styled.div<SelectListStyledPropsInterface>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  position: relative;
  background: ${({ needBackground }) =>
    needBackground ? `var(${ColorVarsEnum.Level_5_application})` : `var(${ColorVarsEnum.Level_3_menu})`};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  color: var(${ColorVarsEnum.Level_1});
  border: 1px solid var(${ColorVarsEnum.Level_4});
  border-radius: 3px;
  padding: 0 8px;
  margin: 0 auto;
`;

export const SelectList = styled.ul`
  position: absolute;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  top: 110%;
  left: 0;
  max-height: 150px;
  border-radius: 3px;
  overflow-y: scroll;
  z-index: 10;
  background-color: var(${ColorVarsEnum.Level_3_menu});

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    transform: rotate(90deg);
    top: 20px;
    right: 20px;
    position: absolute;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: var(${ColorVarsEnum.Level_5});
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(${ColorVarsEnum.Level_4});
  }
`;

export type SelectItemListProps = {
  disabled?: boolean;
};

export const SelectItemList = styled.li<SelectItemListProps>`
  position: relative;
  color: ${({ disabled }) => (disabled ? `var(${ColorVarsEnum.Level_3})` : `var(${ColorVarsEnum.Level_1})`)};
  transition: all 200ms;
  padding: 4px 8px;
  background: var(${ColorVarsEnum.Level_3_menu});
  display: flex;
  white-space: nowrap;

  span {
    z-index: 2;
  }

  &:hover {
    background-color: ${`var(${ColorVarsEnum.Level_3_menu})`} !important;

    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: ${`var(${ColorVarsEnum.Level_5})`};
      z-index: 1;
    }
  }
`;

export const SelectTitle = styled.div`
  padding: 2px 0;
`;

export const IconStyled = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
  }
`;
