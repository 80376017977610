import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { FlexContainer } from 'styles/FlexContainer';
import { ButtonsWrapper, SourcesItemContainer } from './styles';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ErrorWarningIcon, IconType } from 'assets/icons/withContainer';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { NoopType } from 'types/global';
import { SourcesItemType } from 'modules/ui/SourceList/types';
import { truncateFilename } from 'utils/utils';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { maxLengthFileName } from './constants';
import { MenuListComponent } from 'components/shared/ui/menu/MenuIconList';
import { Option } from 'components/shared/ui/menu/MenuIconList/types';

interface SourcesItemProps<T extends SourcesItemType> {
  name: string;
  fileName: T extends 'file' ? string : never;
  loading: boolean;
  onConnection: NoopType;
  disabledOnDelete?: boolean;
  disabledEditButton?: boolean;
  disabledFooter?: boolean;
  isValid: boolean;
  optionsSourceMenu: () => Option[];
  Icon: IconType;
  isActiveColor: boolean;
}

export const SourcesItem = <T extends SourcesItemType>({
  name,
  fileName,
  onConnection,
  loading,
  disabledEditButton = false,
  isValid,
  disabledFooter = false,
  Icon,
  optionsSourceMenu,
  disabledOnDelete = false,
  isActiveColor,
}: SourcesItemProps<T>) => {
  return (
    <SourcesItemContainer
      flexDirection="column"
      position="relative"
      cursor="pointer"
      padding="12px 8px 12px 12px"
      onClick={onConnection}
    >
      <FlexContainer justifyContent="space-between" alignItems="center" gap="8px">
        <FlexContainer alignItems="center" overflow="hidden">
          <IconWrapper
            Icon={Icon}
            colorVar={isActiveColor ? ColorVarsEnum.Accent : !isValid ? ColorVarsEnum.Alert : ColorVarsEnum.Level_2}
            iconHeight="20px"
            iconWidth="20px"
            opacity={0}
          />
          <PrimaryTextSpan
            maxWidth="200px"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            lineHeight="14px"
            cursor="pointer"
            marginLeft="8px"
            overflow="hidden"
            fontSize="14px"
            color={`var(${isActiveColor ? ColorVarsEnum.Accent : !isValid ? ColorVarsEnum.Alert : ColorVarsEnum.Level_1})`}
          >
            {name}
          </PrimaryTextSpan>
        </FlexContainer>
        <FlexContainer gap={!disabledEditButton && !loading && !disabledOnDelete ? '8px' : '0px'}>
          {loading && <CircularProgress sx={{ marginRight: 0, marginLeft: 0 }} size="20px" thickness={7} />}
          {!isValid && <ErrorWarningIcon />}
          <ButtonsWrapper alignItems="center" gap="8px">
            <MenuListComponent options={optionsSourceMenu()} />
          </ButtonsWrapper>
        </FlexContainer>
      </FlexContainer>
      {!disabledFooter && (
        <FlexContainer marginLeft="24px" min-height="20px" marginTop="8px" justifyContent="space-between" alignItems="center">
          <PrimaryTextSpan
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            lineHeight="20px"
            width="160px"
            color={`var(${ColorVarsEnum.Level_2})`}
          >
            {fileName.length <= maxLengthFileName ? (
              fileName
            ) : (
              <StyledTooltip title={fileName} placement="right">
                <span>{truncateFilename(fileName, maxLengthFileName)}</span>
              </StyledTooltip>
            )}
          </PrimaryTextSpan>
        </FlexContainer>
      )}
    </SourcesItemContainer>
  );
};
