import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import { TState } from 'store/index';
import {
  createTask,
  deleteTask,
  loadAdminFlows,
  loadAdminShortProject,
  loadAdminTasks,
  updateTask,
} from 'store/reducers/adminTasks/api';
import { getAdminTasksData } from 'store/reducers/adminTasks/getters';
import { AdminTasksActionsTypes, TaskInterface } from 'store/reducers/adminTasks/types';
import { IdInterface } from 'types/store';
import { addInfo, addTask, deleteTaskById, updateTasks } from '.';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');

  return rejectWithValue(errorMessage);
};

export const loadAdminTasksAction = createAsyncThunk<FastBoard.API.ApiCronJobListItemWithIdDTO[], void, { rejectValue: null }>(
  AdminTasksActionsTypes.LOAD_ADMIN_TASKS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await loadAdminTasks();
      return response.data.cronjobWithIdList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return {} as FastBoard.API.ApiCronJobListItemWithIdDTO[];
    }
  },
);

export const createTaskAction = createAsyncThunk<
  FastBoard.API.ApiCronJobListItemWithIdDTO,
  FastBoard.API.CronJobCreateDTO & IdInterface,
  { rejectValue: null }
>(AdminTasksActionsTypes.CREATE_TASK, async ({ isActive, id, cron }, { rejectWithValue }) => {
  try {
    const response = await createTask({ isActive, id, cron });
    Snackbar.show('Задание создан', 'success');

    return response.data.cronjobItemWithId;
  } catch (err: any) {
    return validateError(err, rejectWithValue);
  }
});

export const addTaskAction = createAsyncThunk<void, TaskInterface>(AdminTasksActionsTypes.ADD_TASK, (data, { dispatch }) => {
  dispatch(addTask(data));
});

export const addTaskInfoAction = createAsyncThunk<void, TaskInterface | null>(
  AdminTasksActionsTypes.ADD_TASK_INFO,
  (data, { dispatch }) => {
    dispatch(addInfo(data));
  },
);

export const updateTaskAction = createAsyncThunk<
  FastBoard.API.ApiCronJobListItemWithIdDTO,
  FastBoard.API.CronJobCreateDTO & IdInterface
>(AdminTasksActionsTypes.UPDATE_TASK, async ({ isActive, cron, id }, { rejectWithValue }) => {
  try {
    const response = await updateTask({ isActive, cron, id });
    Snackbar.show('Задание изменен', 'success');

    return response.data.cronjobItemWithId;
  } catch (err: any) {
    return validateError(err, rejectWithValue);
  }
});

export const updateTasksAction = createAsyncThunk<void, { task: TaskInterface }>(
  AdminTasksActionsTypes.UPDATE_TASKS,
  ({ task }, { dispatch, getState }) => {
    const tasks = getAdminTasksData(getState() as TState).tasks.map((value) =>
      value.id === task.id ? { ...value, ...task } : value,
    );

    dispatch(updateTasks(tasks));
  },
);

export const deleteTaskAction = createAsyncThunk<string, string, { rejectValue: null }>(
  AdminTasksActionsTypes.DELETE_TASK,
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteTask(id);
      Snackbar.show('Удалено', 'success');

      return response;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const deleteTaskByIdAction = createAsyncThunk(AdminTasksActionsTypes.DELETE_TASK_BY_ID, (id: string, { dispatch }) => {
  dispatch(deleteTaskById({ id }));
});

export const loadAdminFlowsAction = createAsyncThunk<FastBoard.API.ApiAdminFlowDTO[], void, { rejectValue: null }>(
  AdminTasksActionsTypes.LOAD_ADMIN_FLOWS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await loadAdminFlows();

      return response.data.adminFlowsList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FastBoard.API.ApiAdminFlowDTO[];
    }
  },
);

export const loadAdminShortProjectAction = createAsyncThunk<FastBoard.API.ApiAdminProjectShortListItemDTO[], string>(
  AdminTasksActionsTypes.LOAD_ADMIN_SHORT_FLOW,
  async (id, { rejectWithValue }) => {
    try {
      const response = await loadAdminShortProject(id);

      return response.data.adminProjectShortList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FastBoard.API.ApiAdminProjectShortListItemDTO[];
    }
  },
);
