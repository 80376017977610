import { AddAndDeleteButtons, AddAndDeleteButtonsProps } from 'modules/settingsContainer/AddAndDeleteButtons';
import { MetricWrapper } from 'modules/settingsContainer/common/data/MetricSettings/styles';
import {
  ControlsInterface,
  FieldSettingsRenderType,
  MetricsRenderType,
} from 'modules/settingsContainer/common/data/MetricSettings/types';
import { FieldSettingsArea } from 'modules/settingsContainer/FieldSettingsArea';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React, { useEffect, useState } from 'react';
import { VisualisationIdInterface } from 'store/reducers/visualisations/types';
import { FlexContainer } from 'styles/FlexContainer';

interface IncisionSettingsProps<Metric extends VisualisationIdInterface>
  extends Pick<AddAndDeleteButtonsProps, 'onAdd' | 'addButtonText'> {
  titleText?: string;
  metrics: Metric[];
  metricRender: MetricsRenderType<Metric>;
  fieldSettingsRender?: FieldSettingsRenderType<Metric>;
  disableAddingMetric?: boolean;
  disableDeleteButton?: boolean;
  onDelete?: (id: string | null) => void;
  controls?: ControlsInterface;
  maxMetricListHeight?: string;
}

export const MetricSettings = <Metric extends VisualisationIdInterface>({
  metrics,
  metricRender,
  fieldSettingsRender,
  onDelete,
  onAdd,
  disableAddingMetric,
  titleText,
  addButtonText,
  controls,
  disableDeleteButton = metrics.length === 1,
  maxMetricListHeight,
}: IncisionSettingsProps<Metric>) => {
  const externalActiveMetricId = controls?.activeMetricId?.value;
  const externalSelectedMetricId = controls?.selectedMetricId?.value;

  const [activeMetricId, setActiveMetricId] = useState<string | null>(externalActiveMetricId || null);
  const [selectedMetricId, setSelectedMetricId] = useState<string | null>(externalSelectedMetricId || null);

  const onCloseFieldSettings = () => {
    setActiveMetricId(null);
    controls?.activeMetricId?.onChange && controls?.activeMetricId?.onChange(null);
  };
  const onUnselectMetric = () => {
    setSelectedMetricId(null);
    controls?.selectedMetricId?.onChange && controls?.selectedMetricId?.onChange(null);
  };

  const activeMetric = metrics?.find(({ id }) => id === activeMetricId);
  const selectMetric = metrics?.find(({ id }) => id === selectedMetricId);

  const onLocalDelete = () => {
    onUnselectMetric();
    onCloseFieldSettings();
    onDelete && onDelete(selectedMetricId);
  };

  const onDropDownClick = () => {
    selectMetric && onUnselectMetric();
    activeMetric && onCloseFieldSettings();
  };

  useEffect(() => {
    setActiveMetricId(externalActiveMetricId || null);
  }, [externalActiveMetricId]);

  useEffect(() => {
    setSelectedMetricId(externalSelectedMetricId || null);
  }, [externalSelectedMetricId]);

  return (
    <MainContainerSettings
      onDropDownClick={titleText ? onDropDownClick : undefined}
      titleText={titleText || ''}
      customPadding="12px 8px 12px"
      isOpenDefault={true}
    >
      <FlexContainer flexDirection="column" gap="12px">
        <MetricWrapper maxHeight={maxMetricListHeight}>
          {metricRender({
            metrics: metrics,
            activeMetricId,
            setActiveMetricId,
            selectedMetricId,
            setSelectedMetricId,
          })}
        </MetricWrapper>
        {activeMetric && fieldSettingsRender && (
          <FieldSettingsArea onClose={onCloseFieldSettings}>
            {fieldSettingsRender({
              metric: activeMetric,
              metrics,
            })}
          </FieldSettingsArea>
        )}
        {!disableAddingMetric && (
          <AddAndDeleteButtons
            addButtonText={addButtonText}
            disable={{ deleteButton: !selectedMetricId || disableDeleteButton }}
            onDelete={onLocalDelete}
            onAdd={onAdd}
          />
        )}
      </FlexContainer>
    </MainContainerSettings>
  );
};
