import { SnackbarMessage, useSnackbar, VariantType, WithSnackbarProps } from 'notistack';
import { FC } from 'react';

let snackbarRef: WithSnackbarProps;

export const SnackbarUtilsConfigurator: FC = () => {
  snackbarRef = useSnackbar();
  return null;
};

export default {
  show(msg: SnackbarMessage, variant: VariantType = 'default'): void {
    snackbarRef.enqueueSnackbar(msg, { variant });
  },
};
