import React from 'react';
import { columnTypeIcon, columnTypes } from 'components/contents/Models/TablePreview/constants';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { TablePreviewType } from 'store/reducers/models/types';
import { Table, TableCell, TableContainer, TableTD, TableTH } from './styles';

interface TablePreviewProps {
  data: TablePreviewType;
}

export const TablePreview = ({ data: { meta, data } }: TablePreviewProps) => {
  return (
    <TableContainer>
      <TableCell>
        <Table cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              {meta.map(({ name, type }) => {
                const columnType = columnTypes.find((key) => type.includes(key)) || 'default',
                  Icon = columnTypeIcon[columnType];

                return (
                  <th key={`${name}_${type}`}>
                    <TableTH>
                      <IconWrapper
                        cursor="default"
                        opacity={0}
                        Icon={Icon}
                        containerWidth="20px"
                        iconWidth="12px"
                        iconHeight="12px"
                      />
                      {name}
                    </TableTH>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((el, indexRow) => (
              <tr key={String(indexRow)}>
                {meta.map(({ name }, indexColumn) => (
                  <TableTD key={el[name] + String(indexRow) + String(indexColumn)}>{String(el[name])}</TableTD>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </TableCell>
    </TableContainer>
  );
};
