import { useSelector } from 'react-redux';
import { getVisualisationDataById } from 'store/reducers/visualisations/getters';
import { useMemo } from 'react';
import {
  EventsSettingsInterface,
  MinAndMaxInterface,
  TableDataSettings,
  VisualisationIdInterface,
  VisualisationValuesInterface,
} from 'store/reducers/visualisations/types';
import { defaultFictionalData, getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import { getRandomInt, replaceEmptyValues } from 'utils/utils';
import { getEnabledFiltersDataDependency } from 'store/reducers/filters/getters';
import isUndefined from 'lodash/isUndefined';

export interface VisualisationNormalizedValuesParams
  extends VisualisationIdInterface,
    Pick<EventsSettingsInterface, 'isReactingToFilter'> {
  dataSettings: TableDataSettings;
  intervalRandomData?: MinAndMaxInterface;
  activeIncisionId?: string | null;
}

export const useVisualisationNormalizedValuesTable = ({
  id,
  dataSettings,
  intervalRandomData,
  isReactingToFilter,
  activeIncisionId,
}: VisualisationNormalizedValuesParams) => {
  const visualisationData = useSelector(getVisualisationDataById(id)),
    visualisationValues = useMemo(() => visualisationData?.visualisationValues || {}, [visualisationData?.visualisationValues]),
    enabledFiltersDataDependency = useSelector(getEnabledFiltersDataDependency);

  const incisions = dataSettings.incisions;

  const activeIncisionFictionalData = incisions.find((inc) => inc.id === activeIncisionId)?.fictionalData;

  const visualisationNormalizedValues = useMemo<VisualisationValuesInterface>(() => {
    if (dataSettings.isRealData) {
      const emptyValuesMap: { [key: string]: string } = {};

      dataSettings.incisionsInHeader.forEach((incision) => {
        const {
          name,
          fieldName,
          settings: {
            emptyValues: { isEmptyValue, value },
            nameFromDatabase,
          },
        } = incision;
        const nameKey = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

        if (isEmptyValue) {
          emptyValuesMap[nameKey] = value;
        }
      });

      dataSettings.incisions.forEach((incision) => {
        const {
          name,
          fieldName,
          settings: {
            emptyValues: { isEmptyValue, value },
            nameFromDatabase,
          },
        } = incision;
        const nameKey = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

        if (isEmptyValue) {
          emptyValuesMap[nameKey] = value;
        }
      });

      dataSettings.indicators.forEach((indicator) => {
        const {
          name,
          fieldName,
          emptyValues: { isEmptyValue, value },
          settings: { nameFromDatabase },
        } = indicator;
        const nameKey = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

        if (isEmptyValue) {
          emptyValuesMap[nameKey] = value;
        }
      });

      const normalizedValues = Object.entries(visualisationValues).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
          acc[key] = value?.map((val: string | number | null) =>
            !isUndefined(emptyValuesMap[key]) ? replaceEmptyValues(val, emptyValuesMap[key]) : val,
          );
        }
        return acc;
      }, {} as VisualisationValuesInterface);

      return normalizedValues;
    }

    const firstIncision = dataSettings.incisions?.[0];
    const fictionalData = (activeIncisionId ? activeIncisionFictionalData : firstIncision?.fictionalData) || defaultFictionalData;
    const incisionValues = firstIncision &&
      (dataSettings.hasAllGroupIncision || firstIncision.settings.isGroup) && {
        [getVisualisationFieldName({
          fieldName: firstIncision.fieldName,
          name: firstIncision.name,
          nameFromDatabase: firstIncision.settings.nameFromDatabase,
        })]: fictionalData,
      };

    const incisionsValues =
      incisionValues ||
      dataSettings.incisions.reduce((values, { fieldName, name, fictionalData, settings: { nameFromDatabase } }) => {
        const incisionName = getVisualisationFieldName({ fieldName, name, nameFromDatabase });

        return { ...values, [incisionName]: fictionalData };
      }, {});

    const incisionsInHeaderValues = dataSettings.incisionsInHeader.reduce(
      (values, { fieldName, name, fictionalData, settings: { nameFromDatabase } }) => {
        const incisionName = getVisualisationFieldName({ fieldName, name, nameFromDatabase });

        return { ...values, [incisionName]: fictionalData };
      },
      {},
    );

    const indicatorsValues = dataSettings.indicators.reduce((values, { fieldName, name, settings: { nameFromDatabase } }) => {
      const indicatorName = getVisualisationFieldName({ fieldName, name, nameFromDatabase });

      return {
        ...values,
        [indicatorName]: fictionalData.map(() => getRandomInt(intervalRandomData?.min || 0, intervalRandomData?.max || 100)),
      };
    }, {});

    return { ...incisionsValues, ...incisionsInHeaderValues, ...indicatorsValues };
  }, [
    dataSettings.isRealData,
    dataSettings.incisions,
    dataSettings.incisionsInHeader,
    dataSettings.indicators,
    visualisationValues,
    activeIncisionId,
    activeIncisionFictionalData,
    intervalRandomData?.min,
    intervalRandomData?.max,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isReactingToFilter ? JSON.stringify(enabledFiltersDataDependency) : undefined,
    dataSettings.hasAllGroupIncision,
  ]);

  return { visualisationNormalizedValues };
};
