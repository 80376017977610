import { useEffect } from 'react';
import { EChartsType } from 'echarts';

interface ManualResizeParams {
  eChartRef: EChartsType | null | undefined;
  deps: Array<any>;
}

export const useManualResize = ({ eChartRef, deps }: ManualResizeParams) => {
  /* Manual Resize */
  useEffect(() => {
    setTimeout(() => {
      eChartRef?.resize();
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
};
