import { useSelector } from 'react-redux';
import { getActiveVisualisationSettings, getCodeEditorDataById } from 'store/reducers/visualisations/getters';
import {
  DefaultBackgroundImagesSettingsType,
  DefaultDataSettingsType,
  DefaultSqlDataInterface,
  DefaultViewSettingsInterface,
} from 'store/reducers/visualisations/types';
import {
  defaultBackgroundImagesSettings,
  defaultDataSettings,
  defaultSqlDataSettings,
  defaultViewSettings,
  initialEventsSettings,
  initialPositionConfig,
} from 'store/reducers/visualisations/constants';
import { getAstOfVisualisationById } from 'store/reducers/ast/getters';

interface DefaultDataParams<
  VisualisationDataSettings extends DefaultDataSettingsType,
  VisualisationViewSettings extends DefaultViewSettingsInterface,
  VisualisationBackgroundImagesSettings extends DefaultBackgroundImagesSettingsType,
  VisualisationSqlDataSettings extends DefaultSqlDataInterface,
> {
  defaultData?: VisualisationDataSettings;
  defaultView?: VisualisationViewSettings;
  defaultBackgroundImages?: VisualisationBackgroundImagesSettings;
  defaultSqlData?: VisualisationSqlDataSettings;
}

export const useGetActiveVisualisationSettings = <
  VisualisationDataSettings extends DefaultDataSettingsType,
  VisualisationViewSettings extends DefaultViewSettingsInterface,
  VisualisationBackgroundImagesSettings extends DefaultBackgroundImagesSettingsType,
  VisualisationSqlDataSettings extends DefaultSqlDataInterface,
>(
  params?: DefaultDataParams<
    VisualisationDataSettings,
    VisualisationViewSettings,
    VisualisationBackgroundImagesSettings,
    VisualisationSqlDataSettings
  >,
) => {
  const { defaultData, defaultView, defaultBackgroundImages, defaultSqlData } = params || {};

  const { viewSettings, dataSettings, sqlData, backgroundImagesSettings, events, id, positionConfig } = useSelector(
    getActiveVisualisationSettings,
  ) || {
    dataSettings: defaultData,
    viewSettings: defaultView,
    backgroundSettings: defaultBackgroundImages,
    sqlData: defaultSqlData,
    events: initialEventsSettings,
    positionConfig: initialPositionConfig,
  };

  const codeEditorData = useSelector(getCodeEditorDataById(id || ''));
  const astOfVisualisation = useSelector(getAstOfVisualisationById(id || ''));

  /* TODO: Refactor this code   */

  const dataSettingsValue = defaultData !== null ? { ...(defaultData || defaultDataSettings), ...dataSettings } : null,
    sqlDataValue = defaultSqlData !== null ? { ...(defaultSqlData || defaultSqlDataSettings), ...sqlData } : null,
    backgroundImagesValue =
      defaultBackgroundImages !== null
        ? { ...(defaultBackgroundImages || defaultBackgroundImagesSettings), ...backgroundImagesSettings }
        : null;

  return {
    dataSettings: dataSettingsValue as VisualisationDataSettings,
    viewSettings: { ...(defaultView || defaultViewSettings), ...viewSettings } as VisualisationViewSettings,
    backgroundImagesSettings: backgroundImagesValue as VisualisationBackgroundImagesSettings,
    sqlData: sqlDataValue as VisualisationSqlDataSettings,
    events: { ...initialEventsSettings, ...events },
    codeEditorData,
    astOfVisualisation,
    positionConfig,
    id: id || '',
  };
};
