import { RefObject, useCallback, useLayoutEffect, useState } from 'react';

interface ResizeObserverParams<T> {
  ref: RefObject<T>;
  callback?: (entry: DOMRectReadOnly) => void;
}

export const useResizeObserver = <T extends Element>({ ref, callback }: ResizeObserverParams<T>) => {
  const [{ width, height }, setSizes] = useState({ width: 0, height: 0 });

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const entry = entries[0];
      setSizes({ width: entry.contentRect.width, height: entry.contentRect.height });

      if (callback) {
        callback(entry.contentRect);
      }
    },
    [callback],
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    let RO: ResizeObserver | null = new ResizeObserver((entries) => handleResize(entries));
    RO.observe(ref.current);

    return () => {
      RO?.disconnect();
      RO = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, handleResize]);

  return [width, height];
};
