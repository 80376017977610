import React from 'react';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import { defaultHeatmapDataSettings, defaultHeatmapViewSettings } from 'store/reducers/visualisations/constants';
import { DefaultViewSettings } from 'modules/settingsContainer/common/view/DefaultViewSettings';
import { BooleanTipsSettings } from 'modules/settingsContainer/common/view/BooleanTipsSettings';
import { AxisSettings } from 'modules/settingsContainer/common/view/AxisSettings';
import {
  onChangeHorizontalZoomChange,
  onChangePaddingVisualisation,
  onChangeVerticalZoomChange,
  onIncisionSelectorPositionChange,
  onShowTipsChange,
  onVisibleIncisionSelectorChange,
  onVisualMapSettingsChange,
  onXAxisIncisionChange,
  onYAxisIncisionChange,
} from 'modules/visualisations/Heatmap/settings/ViewTab/constants';
import { ExtendedSettings } from 'modules/settingsContainer/common/view/ExtendedSettings';
import { IncisionsSelectorPositionSettingsGroup } from 'modules/settingsContainer/common/IncisionsSelectorPositionSettingsGroup';
import { PaddingVisualisationSettings } from 'modules/settingsContainer/common/data/PaddingVisualisationSettings';
import { ZoomSettings } from 'modules/settingsContainer/common/view/VerticalZoomSettings';
import { showLegendTitle } from 'modules/settingsContainer/common/view/LegendSettings/constants';
import SubSection from 'shared/ui/subSection';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
export const ViewTab = () => {
  const { viewSettings, positionConfig } = useGetActiveVisualisationSettings({
    defaultView: defaultHeatmapViewSettings,
    defaultData: defaultHeatmapDataSettings,
  });

  return (
    <>
      <DefaultViewSettings
        SecondSlot={
          <>
            <ExtendedSettings
              title={showLegendTitle}
              value={viewSettings.visualMapSettings}
              onChange={onVisualMapSettingsChange}
            />
          </>
        }
        SectionSlot={
          <>
            <IncisionsSelectorPositionSettingsGroup
              value={viewSettings.incisionSelectorPosition}
              onChange={onIncisionSelectorPositionChange}
              onChangeVisible={onVisibleIncisionSelectorChange}
              isVisible={viewSettings.isVisible}
            />
          </>
        }
        ThirdSlot={
          <>
            <BooleanTipsSettings value={viewSettings.showTips} onChange={onShowTipsChange} />
            <AxisSettings
              isRotated={false}
              value={viewSettings.axisXIncisionSettings}
              onChange={onXAxisIncisionChange}
              titleText="Ось разрезов X"
              type="incision"
              isAxisX
            />
            <AxisSettings
              isRotated={false}
              value={viewSettings.axisYIncisionSettings}
              onChange={onYAxisIncisionChange}
              titleText="Ось разрезов Y"
              type="incision"
              disabled={{ positionAxis: true }}
            />
            <MainContainerSettings titleText="Приближение">
              <SubSection>
                <ZoomSettings
                  title="Приближение по вертикали"
                  value={viewSettings.verticalZoom}
                  onChange={onChangeVerticalZoomChange}
                />
              </SubSection>
              <SubSection>
                <ZoomSettings
                  title="Приближение по горизонтали"
                  value={viewSettings.horizontalZoom}
                  onChange={onChangeHorizontalZoomChange}
                />
              </SubSection>
            </MainContainerSettings>
            <PaddingVisualisationSettings value={viewSettings.visualisationPaddings} onChange={onChangePaddingVisualisation} />
          </>
        }
        viewSettings={viewSettings}
        positionConfig={positionConfig}
      />
    </>
  );
};
