import { ReactFCNoop } from 'types/global';

export type VisualSettingsComponentType = ReactFCNoop;

/* Store SLice Type */

export interface VisualSettingsStateInterface {
  SettingComponent: VisualSettingsComponentType;
}

/* Action Types */

export enum VisualSettingsActionsTypes {
  SET_SETTINGS_COMPONENT = 'SET_SETTINGS_COMPONENT',
  SET_DEFAULT_SETTINGS_COMPONENT = 'SET_DEFAULT_SETTINGS_COMPONENT',
}

/* Payload Interfaces */

export type SetSettingsComponentPayload = Pick<VisualSettingsStateInterface, 'SettingComponent'>;
