import { createSelector } from 'reselect';
import { getHasChangesOfPages, getPages } from 'store/reducers/projectPages/getters';
import { getHasChangesOfVisualisations, getWidgetsByPages } from 'store/reducers/visualisations/getters';
import {
  getEnabledFilterByPages,
  getFilterByPages,
  getGlobalEnabledFilters,
  getHasChangesOfEnabledFilters,
  getHasChangesOfFilters,
} from 'store/reducers/filters/getters';
import { getHasChangesOfPagesLayers, getLayersByPages } from 'store/reducers/board/getters';
import {
  getActiveThemeId,
  getHasChangesOfActiveThemeId,
  getHasChangesOfPaletteLinks,
  getHasChangesOfThemes,
  getPaletteLinks,
  getThemesAsArray,
} from 'store/reducers/themes/getters';
import { getHasChangesOfPalettes, getPalettesAsArray } from 'store/reducers/palettes/getters';
import {
  getDefaultModelId,
  getHasChangesOfDefaultModelId,
  getHasChangesOfProjectSettings,
  getProjectSettings,
} from 'store/reducers/projectSettings/getters';
import { MapRecordType } from 'types/global';
import { formatISO, parseISO } from 'utils/dates';
import { getUserInfo } from 'store/reducers/auth';

/* This file needed for resolve circular dependency between getWidgetsByPages and getPageIds */

export const getSettingsSnapshot = createSelector(
  [
    getPages,
    getWidgetsByPages,
    getFilterByPages,
    getEnabledFilterByPages,
    getLayersByPages,
    getThemesAsArray,
    getActiveThemeId,
    getPaletteLinks,
    getPalettesAsArray,
    getProjectSettings,
    getDefaultModelId,
    getGlobalEnabledFilters,
  ],
  (
    pages,
    visualisations,
    filters,
    enabledFilters,
    layers,
    themes,
    activeThemeId,
    paletteLinks,
    palettes,
    settings,
    defaultModelId,
    globalEnabledFilters,
  ) => ({
    pages,
    visualisations,
    filters,
    enabledFilters,
    layers,
    themes,
    activeThemeId: activeThemeId as string,
    paletteLinks,
    palettes,
    settings,
    defaultModelId: defaultModelId as string,
    globalEnabledFilters,
  }),
);

export const getHasChangesOfSnapshot = createSelector(
  [
    getHasChangesOfPages,
    getHasChangesOfVisualisations,
    getHasChangesOfFilters,
    getHasChangesOfEnabledFilters,
    getHasChangesOfPagesLayers,
    getHasChangesOfThemes,
    getHasChangesOfActiveThemeId,
    getHasChangesOfPaletteLinks,
    getHasChangesOfPalettes,
    getHasChangesOfProjectSettings,
    getHasChangesOfDefaultModelId,
  ],
  (...changes) => [...changes].some((change) => change),
);

export type SettingsSnapshotType = ReturnType<typeof getSettingsSnapshot>;

export interface ProjectSnapshotInterface {
  timestamp: string;
  snapshot: SettingsSnapshotType;
}

export type SnapshotsInLocalStorageType = MapRecordType<MapRecordType<ProjectSnapshotInterface>>;

export class SettingsSnapshots {
  private static localStorageName = 'settingsSnapshots';

  private static get snapshots(): SnapshotsInLocalStorageType {
    const snapshotsFormLS = localStorage.getItem(this.localStorageName);
    return snapshotsFormLS ? JSON.parse(snapshotsFormLS) : {};
  }

  private static getSnapshotData({ projectId, login }: { projectId: string; login: string }) {
    const snapshots = this.snapshots,
      usersSnapshots = snapshots[login] || {};

    return { snapshots, usersSnapshots, snapshotData: (usersSnapshots[projectId] || {}) as Partial<ProjectSnapshotInterface> };
  }

  static saveToLocalStorage({
    snapshot,
    projectId,
    login,
  }: {
    snapshot: SettingsSnapshotType;
    projectId: string;
    login: string;
  }) {
    const { snapshots, usersSnapshots } = this.getSnapshotData({ projectId, login });

    const newSnapshots: SnapshotsInLocalStorageType = {
      ...snapshots,
      [login]: { ...usersSnapshots, [projectId]: { snapshot, timestamp: formatISO(new Date()) } },
    };

    localStorage.setItem(this.localStorageName, JSON.stringify(newSnapshots));
  }

  static deleteSnapshot({ projectId, login }: { projectId: string; login: string }) {
    const { snapshots, usersSnapshots } = this.getSnapshotData({ projectId, login });

    delete usersSnapshots[projectId];

    const newSnapshots: SnapshotsInLocalStorageType = {
      ...snapshots,
      [login]: { ...usersSnapshots },
    };

    localStorage.setItem(this.localStorageName, JSON.stringify(newSnapshots));
  }

  static getSnapshot({ projectId, login }: { projectId: string; login: string }): Partial<ProjectSnapshotInterface> {
    const { snapshotData } = this.getSnapshotData({ projectId, login });

    return snapshotData;
  }
}

export const getSettingsSnapshotFromLS = (projectId: string) =>
  createSelector(getUserInfo, ({ login }) => SettingsSnapshots.getSnapshot({ projectId, login: login || '' }));

export const getTimestampSnapshotFromLS = (projectId: string) =>
  createSelector(getSettingsSnapshotFromLS(projectId), ({ timestamp }) => (timestamp ? parseISO(timestamp) : null));
