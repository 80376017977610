import React, { useEffect } from 'react';
import {
  addFlowAction,
  changeActiveFlowAction,
  createFlowAction,
  updateFlowAction,
  updateFlowsAction,
} from 'store/reducers/adminFlows/actions';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, TextField } from 'modules/ui';
import { getActiveFlow, getFlows } from 'store/reducers/adminFlows/getters';
import { Form } from './styles';
import { loadUsersAction } from 'store/reducers/adminUsers/actions';
import { useAppDispatch } from 'store';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { ModalComponentType } from 'store/reducers/modals/types';
import { NoopType } from 'types/global';
import { ModeForm } from 'types/store';
import { FlowFormType } from 'components/admin/flows/types';
import Snackbar from 'services/Snackbar';

interface FlowModalProps {
  mode: ModeForm;
  onClose?: NoopType;
}

export const FlowModal: ModalComponentType<FlowModalProps> = ({ mode, onClose }) => {
  const dispatch = useAppDispatch();
  const isAdd = mode === 'add';

  const { flowsList } = useSelector(getFlows);
  const activeFlow = useSelector(getActiveFlow);

  const activeFlowId = activeFlow?.id;
  const activeFlowName = activeFlow?.name;

  useEffect(() => {
    dispatch(loadUsersAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<FlowFormType>({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (activeFlowName && !isAdd) {
      setValue('name', activeFlowName);
    }
  }, [activeFlowName, setValue, isAdd]);

  const hasName = (name: string) => flowsList.find((obj) => obj.name === name);

  const onSubmit: SubmitHandler<FlowFormType> = async ({ name }) => {
    if (!isDirty || isSubmitting) {
      return null;
    }

    if (!name) {
      return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
    }

    if (hasName(name)) {
      return Snackbar.show('Поток с таким названием уже существует', 'error');
    }

    let actionUserRes;

    try {
      if (isAdd) {
        actionUserRes = await dispatch(createFlowAction({ name })).unwrap();

        if (actionUserRes) {
          dispatch(addFlowAction(actionUserRes));
        }
      }

      if (!isAdd && activeFlowId) {
        actionUserRes = await dispatch(updateFlowAction({ flowId: activeFlowId, name })).unwrap();

        if (actionUserRes) {
          dispatch(updateFlowsAction({ flow: actionUserRes }));
        }
      }

      if (actionUserRes) {
        dispatch(changeActiveFlowAction(actionUserRes));
      }

      if (onClose) {
        onClose();
      }
    } catch (e) {
      return;
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={isSubmitting} />

      <Controller
        name="name"
        rules={{ required: true }}
        control={control}
        render={({ field }) => <TextField error={!!errors.name} label="Название" width="100%" {...field} />}
      />

      <FlexContainer width="100%" justifyContent="flex-end" gap="10px" marginTop="32px" flexDirection="row">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Готово" iconSize="normal" heightSize="normal" type="submit" />
      </FlexContainer>
    </Form>
  );
};
