import styled from 'styled-components';

export const WrapperECharts = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

interface EChartsLoadingProps {
  backgroundColor: string;
}

export const EChartsLoading = styled.div<EChartsLoadingProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
