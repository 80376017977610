import { TextField } from 'modules/ui';
import { TextFieldActivatorWrapper } from 'modules/settingsContainer/common/ConditionEditorField/styles';
import { FlexContainer } from 'styles/FlexContainer';
import { MouseEventHandler } from 'react';
import { IProps } from 'modules/ui/TextField';
import { FunctionIcon } from 'assets/icons/forDelete';

interface TextFieldActivatorProps extends Pick<IProps, 'value'> {
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const TextFieldActivator = ({ onClick, value }: TextFieldActivatorProps) => (
  <TextFieldActivatorWrapper onClick={onClick}>
    <TextField disabled value={value} name="width" width="100%" />
    <FlexContainer position="absolute" top="0" right="5px" height="100%" alignItems="center" justifyContent="center">
      <FunctionIcon />
    </FlexContainer>
  </TextFieldActivatorWrapper>
);

interface ConditionEditorFieldProps extends Pick<TextFieldActivatorProps, 'value'> {
  onOpenChange: (isOpen: boolean) => void;
}

export const ConditionEditorField = ({ onOpenChange, value }: ConditionEditorFieldProps) => {
  return (
    <>
      <TextFieldActivator onClick={() => onOpenChange(true)} value={value} />
    </>
  );
};
