import { PrimaryTextParagraph } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FlexContainer } from 'styles/FlexContainer';
import { Combobox, ComboboxProps } from 'modules/ui/Combobox';

interface TitledSelector extends ComboboxProps {
  title: string;
}

export const TitledCombobox = ({ title, width, ...comboboxProps }: TitledSelector) => {
  return (
    <FlexContainer flexDirection="column" gap="3px" width={width}>
      <PrimaryTextParagraph fontSize="10px" color={`var(${ColorVarsEnum.Level_2})`}>
        {title}
      </PrimaryTextParagraph>
      <Combobox {...comboboxProps} width="100%" />
    </FlexContainer>
  );
};
