import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OnValueChange } from 'types/global';

type LocalValuesParams<T> = OnValueChange<T>;

export const useLocalValues = <T>({ value, onChange }: LocalValuesParams<T>) => {
  const [localValues, setLocalValues] = useState<T>(value);

  const hasChanges = useMemo(() => !isEqual(localValues, value), [localValues, value]);

  const onSave = useCallback(() => onChange(localValues), [localValues, onChange]);
  const onCancel = useCallback(() => setLocalValues(value), [value]);

  useEffect(() => {
    setLocalValues(value);
  }, [value]);

  return { localValues, setLocalValues, hasChanges, onSave, onCancel };
};
