import { RadioSelectedItem } from 'components/RadioSelected/types';
import { ColorValueType, PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { ExportType, SQLRequestInterface } from 'modules/settingsContainer/ContextMenuWrapper/types';
import { SortingInterface } from 'modules/visualisations/Table/visualisation/types';
import { Limit } from 'node-sql-parser';
import { DateFilterDataInterface } from 'store/reducers/filters/types';
import { MapRecordType, StrictKeys } from 'types/global';
import {
  AutoEndManualType,
  BoardPositionConfigInterface,
  ConnectedCellPaddingSettings,
  ContainerSettingsInterface,
  DesignOptionInterface,
  FictionalDataInterface,
  FieldNameInterface,
  IdInterface,
  IsRealDataInterface,
  ModelIdInterface,
  NameFromDatabaseInterface,
  PaddingsVisualisationInterface,
  PageIdInterface,
  PositionConfigSettingsInterface,
  PositionSettingType,
  ProportionsImageSettingType,
  SqlDataRequestInterface,
  SqlLimitInterface,
  StartAndEndInterface,
} from 'types/store';
import { AbsolutePositionType, RightAndLeftType, TextStylesInterface, TopAndBottomType } from 'types/styles';
import { RLSInterface } from 'types/types';

export type VisualisationValuesInterface<T = null> = Record<string, Array<number | T | null> | Array<string | null> | undefined>;

export interface VisualisationDataInterface {
  visualisationValues?: VisualisationValuesInterface;
}

export type VisualisationDataMapInterface = MapRecordType<VisualisationDataInterface>;

export interface IsShowInterface {
  isShow: boolean;
}

export interface IsActiveInterface {
  isActive: boolean;
}

export interface AutoOrManualInterface {
  type: AutoEndManualType;
  value: number;
}

export type OrientationType = 'horizontal' | 'vertical';

export interface OrientationInterface {
  orientation: OrientationType;
}

export interface ShowTipsInterface {
  showTips: boolean;
}

export interface TextSettingInterface extends IsShowInterface {
  text: string;
}

export interface AlignmentInterface {
  horizontal: PositionSettingType;
  vertical: PositionSettingType;
}

export interface DefaultPropertiesInterface extends TextStylesInterface {
  isActiveStyle: boolean;
  isActiveFontColor: boolean;
  isActiveBackgroundColor: boolean;
  opacity: number;
  padding: ConnectedCellPaddingSettings;
  fontColorBy: ColorByInterface;
  backgroundColorBy: ColorByInterface;
  changeFillColor: boolean;
  alignment: AlignmentInterface;
}

export type Formatting = 'numerical' | 'percent' | 'money' | 'fraction' | 'exponential';
export type Currency = 'RUB' | 'EUR' | 'USD';
export type ColorValuesByThemeType = MapRecordType<ColorValueType>;

export type MetaFormattingType = {
  money: Currency;
};

export interface FormattingItem {
  formattingType: Formatting;
  meta: MetaFormattingType;
}

interface FormattingEditText extends IsActiveInterface {
  text: string;
}

export interface FormatsInterface {
  formattingItem: FormattingItem;
  editText: FormattingEditText;
  numberOfZeros: number;
  numeric: boolean;
}

export interface FormattingInterface extends IsShowInterface {
  formats: FormatsInterface;
}

export interface MakeHyperLinksInterface {
  makeHyperLinks: boolean;
}

export interface AliasInterface {
  alias: string;
}

export type ColorAndImageByInterfaceType = 'default' | 'condition' | 'value';

export enum ColorAndImageByInterfaceEnum {
  Default = 'default',
  Condition = 'condition',
  Value = 'value',
}

export type GradientByInterfaceType = 'default' | 'valueGradient' | 'valueSteps' | 'condition';

export interface DefaultByInterface<Type extends string> {
  type: Type;
}

export interface ColorByItem {
  value: ColorValuesByThemeType | null;
}

export interface ColorByConditionInterface extends AliasInterface {
  colors: ColorByItem[];
  sqlCondition: string | null;
}

export interface ColorByValueInterface extends AliasInterface {
  colors: MapRecordType<ColorByItem[]> | null;
}

export interface ColorByInterface extends DefaultByInterface<ColorAndImageByInterfaceType> {
  byCondition: ColorByConditionInterface;
  byValue: ColorByValueInterface;
}

export interface GradientColorByInterface extends DefaultByInterface<GradientByInterfaceType> {
  byCondition: ColorByConditionInterface;
  byValueGradient: ColorByValueInterface;
  byValueSteps: ColorByValueInterface;
}

interface GradientBackgroundByValueSettingsInterface {
  gradientBackgroundByValueSettings: GradientColorByInterface;
}

interface GradientColorByValueSettingsInterface {
  gradientColorByValueSettings: ColorByInterface;
}

export interface ColorBySettingsInterface {
  colorBySettings: ColorByInterface;
}

export interface ColorByValueSettingsInterface {
  colorByValueSettings: ColorByInterface;
}

export interface BackgroundByValueSettingsInterface {
  backgroundByValueSettings: ColorByInterface;
}

export interface ImageByValueInterface {
  images: ImageByItem[];
}

export type ImageValues = { linkImage: string };

export interface ImageByItem {
  value: ImageValues | null;
}

export interface ImageByConditionInterface extends AliasInterface {
  images: ImageByItem[];
  sqlCondition: string | null;
}

export interface ImageByInterface extends DefaultByInterface<ColorAndImageByInterfaceType> {
  byCondition: ImageByConditionInterface;
  byValue: ImageByValueInterface;
}

export interface SelectItemInterface extends AliasInterface {
  selectSql: string;
}

export interface ServiceSelectValuesInterface {
  serviceSelectValues: SelectItemInterface[];
}

export interface VariablesSelectValuesInterface {
  variablesSelectValues: SelectItemInterface[];
}

export interface ImagesSelectValuesInterface {
  imagesSelectValues: SelectItemInterface[];
}

export interface LimitSettingInterface extends IsActiveInterface {
  value: number;
}

export interface OverflowPositionInterface {
  label: string;
  value: OverflowValueEnum | string;
}

export enum OverflowValueEnum {
  SEQUENTIALLY = 'sequentially',
  HORIZONTALLY = 'horizontally',
  VERTICALLY = 'vertically',
}

export type SortingStatusType = 'ASC' | 'DESC' | null;

export type TextType = 'text';
export type VisualisationTypeWithActiveIncisionId = 'tree' | 'pie' | 'lineAndBar' | 'waterfall' | 'bubble';
export type VisualisationType = 'heatmap' | 'table' | VisualisationTypeWithActiveIncisionId;
export type VisualisationTypeType = VisualisationType | TextType;

export enum VisualisationTypeEnum {
  TREE = 'tree',
  PIE = 'pie',
  LINEANDBAR = 'lineAndBar',
  WATERFALL = 'waterfall',
  BUBBLE = 'bubble',
  HEATMAP = 'heatmap',
  TABLE = 'table',
  TEXT = 'text',
}

export interface DefaultViewSettingsInterface extends ContainerSettingsInterface {
  header?: TextSettingInterface;
  description?: TextSettingInterface;
  hint?: TextSettingInterface;
  headerPosition?: PositionSettingType;
}

export interface DefaultBackgroundImagesSettingsInterface {
  images?: string;
}

export type DefaultBackgroundImagesSettingsType = DefaultBackgroundImagesSettingsInterface | null;

export type StrictDefaultViewSettingsInterface = Required<DefaultViewSettingsInterface>;

export interface DefaultSqlDataInterface {
  filterAndGroupRequest: string;
}

export interface IsGroupRowInterface {
  isGroup?: boolean;
}

export interface DefaultIncisionSettingsInterface extends NameFromDatabaseInterface, CustomRequestInterface, IsGroupRowInterface {
  emptyValues: IndicatorEmptyValuesInterface;
}

export interface DefaultIncisionInterface
  extends VisualisationIdInterface,
    VisualisationFieldNameInterface,
    FictionalDataInterface {
  settings: DefaultIncisionSettingsInterface;
}

export interface DefaultIncisionWithSqlDataInterface extends DefaultIncisionInterface {
  colors?: PaletteValuesByThemeType | null;
  emptyValues?: IndicatorEmptyValuesInterface;
}

export interface DefaultIndicatorSettingsInterface extends NameFromDatabaseInterface {
  formatting: FormattingInterface;
}

export interface DefaultIndicatorInterface
  extends VisualisationIdInterface,
    VisualisationFieldNameInterface,
    VisualisationOperationTypeInterface {
  settings: DefaultIndicatorSettingsInterface;
  emptyValues: IndicatorEmptyValuesInterface;
}

export interface IndicatorEmptyValuesInterface {
  isEmptyValue: boolean;
  value: string;
}

export interface IsDrillDownInterface {
  isDrillDown?: boolean;
}

export interface DefaultDataSettingsInterface
  extends IsRealDataInterface,
    ModelIdInterface,
    ActiveIncisionIdInterface,
    IsDrillDownInterface {
  incisions: DefaultIncisionWithSqlDataInterface[];
  indicators: DefaultIndicatorWithSqlDataInterface[];
  limit: LimitSettingInterface;
  hasAllGroupIncision?: boolean;
}

export interface DefaultIndicatorWithSqlDataInterface extends DefaultIndicatorInterface {
  color?: ColorValuesByThemeType | null;
}

export type DefaultDataSettingsWithActiveIncisionIdInterface = DefaultDataSettingsInterface;

export type DefaultDataSettingsType = DefaultDataSettingsInterface;

export type VisualisationOperationTypesType = 'sum' | 'count' | 'avg' | 'min' | 'max' | 'other';

export enum VisualisationOperationTypesEnum {
  SUM = 'sum',
  COUNT = 'count',
  AVG = 'avg',
  MIN = 'min',
  MAX = 'max',
  OTHER = 'other',
}

export type VisualisationFieldNameInterface = FieldNameInterface;

export interface CustomRequestInterface {
  customRequest: string | null;
}

export interface VisualisationOperationTypeInterface extends CustomRequestInterface {
  operationType: VisualisationOperationTypesType;
}

export type VisualisationIdInterface = IdInterface;

export interface IsShowValueInterface {
  isShowValue: boolean;
}

export interface ActiveObjectFilters extends IsActiveInterface {
  id: string;
  selectedValues: string[] | DateFilterDataInterface | null;
  elementId: string;
}

export interface ActivateObjectInterface extends IsActiveInterface {
  filters: ActiveObjectFilters[] | null;
  isActive: boolean;
}

type EventObjectType = 'page' | 'visualisation';

interface VisualisationEventObjectInterface extends VisualisationIdInterface {
  type: EventObjectType;
}

interface GoToEventInterface<To> extends IsActiveInterface {
  to: To | null;
}

export type OpenWindowType = 'self' | 'blank';

type GoToHrefEventInterface = GoToEventInterface<string> & {
  openWindowType: OpenWindowType;
};

interface ActivateToEventInterface extends IsActiveInterface {
  to: VisualisationEventObjectInterface[] | null;
}

export interface EventsSettingsInterface {
  goTo: GoToEventInterface<VisualisationEventObjectInterface>;
  goToHref: GoToHrefEventInterface;
  activateTo: ActivateToEventInterface;
  activateObject: ActivateObjectInterface;
  isReactingToFilter: boolean;
  filterSettings: FilterSettingsInterface;
}

export interface FilterSettingsInterface {
  isFiltering: boolean;
  applyToAllPages: boolean;
  moreThanOne: boolean;
  isInfluenceItself: boolean;
  filterInfluences: MapRecordType<boolean> | null;
  responseToExternalDrillDown: boolean;
}

export interface CodeEditorDataInterface {
  indicatorSqlString?: string;
  incisionSqlString?: string;
  filterAndGroupSqlString?: string;
}

export type GroupByType = 'day' | 'dayOfWeek' | 'month' | 'quarter' | 'year';
export type GroupByMaskOfYearType = '%Y' | '%y';
export type GroupByMaskOfOtherType = 'long' | 'short';
export type GroupByMaskOfDayType = '%d.%m.%Y' | '%m/%d/%Y' | '%Y.%m.%d';

export type GroupByTypeGeneric<Type extends GroupByType = 'day'> = Type extends 'day'
  ? 'day'
  : Type extends 'year'
  ? 'year'
  : Type extends 'dayOfWeek' | 'month' | 'quarter'
  ? 'dayOfWeek' | 'month' | 'quarter'
  : never;

export type GroupByMaskGeneric<Type extends GroupByType = 'day'> = Type extends 'day'
  ? GroupByMaskOfDayType
  : Type extends 'year'
  ? GroupByMaskOfYearType
  : Type extends 'dayOfWeek' | 'month' | 'quarter'
  ? GroupByMaskOfOtherType
  : never;

export interface GroupByDateInterface<Type extends GroupByType = 'day'> extends IsShowInterface {
  groupByType: GroupByTypeGeneric<Type>;
  groupByMask: GroupByMaskGeneric<Type>;
}

export interface GroupByDateSettingsInterface {
  groupByDateSettings: GroupByDateInterface<GroupByType>;
}

export interface ActiveIncisionIdInterface {
  activeIncisionId: string | null;
}

export interface IsVisibleInterface {
  isVisible?: boolean;
}

export interface IncisionSelectorPositionInterface extends IsVisibleInterface {
  incisionSelectorPosition: PositionSettingType;
}

export interface VisualisationOptionsInterface<
  VisualisationDataSettings extends DefaultDataSettingsType,
  VisualisationViewSettings extends DefaultViewSettingsInterface,
  VisualisationBackgroundImagesSettings extends DefaultBackgroundImagesSettingsType,
  VisualisationSqlDataSettings extends DefaultSqlDataInterface,
  VisualisationType extends VisualisationTypeType,
> extends VisualisationIdInterface,
    PageIdInterface,
    PositionConfigSettingsInterface {
  dataSettings: VisualisationDataSettings;
  viewSettings: VisualisationViewSettings;
  backgroundImagesSettings: VisualisationBackgroundImagesSettings;
  sqlData: VisualisationSqlDataSettings;
  visualisationType: VisualisationType;
  events: EventsSettingsInterface;
}

export type DefaultVisualisationOptionsType = VisualisationOptionsInterface<
  DefaultDataSettingsType,
  DefaultViewSettingsInterface,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  VisualisationTypeType
>;

export type VisualisationsMapInterface = MapRecordType<DefaultVisualisationOptionsType>;

export type VisualisationIdsByPageInterface = MapRecordType<Set<string>>;

/* Store SLice Type */

export interface VisualisationStateInterface {
  visualisationData: VisualisationDataMapInterface;
  visualisations: VisualisationsMapInterface;
  serverStateOfVisualisations: VisualisationsMapInterface | null;
  visualisationsByPages: VisualisationIdsByPageInterface;
  visualisationsLoading: boolean;
  visualisationsLoadingList: MapRecordType<boolean>;
  visualisationsErrorsList: MapRecordType<string | null>;
  alreadyLoadedContent: {
    visualisations: Set<string>;
  };
}

/* Action Types */

export enum VisualisationActionsTypes {
  ADD_VISUALISATION = 'ADD_VISUALISATION',
  ADD_VISUALISATION_BY_DATA = 'ADD_VISUALISATION_BY_DATA',
  REMOVE_VISUALISATION_BY_ID = 'REMOVE_VISUALISATION_BY_ID',
  UPDATE_VIEW_SETTINGS = 'UPDATE_VIEW_SETTINGS',
  UPDATE_DATA_SETTINGS = 'UPDATE_DATA_SETTINGS',
  UPDATE_BACKGROUND_DATA_SETTINGS = 'UPDATE_BACKGROUND_DATA_SETTINGS',
  UPDATE_SQL_SETTINGS = 'UPDATE_SQL_SETTINGS',
  UPDATE_SQL_SETTINGS_BY_ID = 'UPDATE_SQL_SETTINGS_BY_ID',
  UPDATE_POSITION_CONFIG_BY_ID = 'UPDATE_POSITION_CONFIG_BY_ID',
  UPDATE_POSITION_CONFIG = 'UPDATE_POSITION_CONFIG',
  RETURN_POSITION_CONFIG_BY_ID = 'RETURN_POSITION_CONFIG_BY_ID',
  UPDATE_EVENT_SETTINGS = 'UPDATE_EVENT_SETTINGS',
  UPDATE_VISUALISATION_VALUES = 'UPDATE_VISUALISATION_VALUES',
  REMOVE_VISUALISATION_VALUES = 'REMOVE_VISUALISATION_VALUES',
  REMOVE_FROM_VISUALISATION_LOADING_LIST = 'REMOVE_FROM_VISUALISATION_LOADING_LIST',
  LOAD_VISUALISATION_VALUES = 'LOAD_VISUALISATION_VALUES',
  GET_VISUALISATION_VALUES = 'GET_VISUALISATION_VALUES',
  LOAD_VISUALISATIONS = 'LOAD_VISUALISATIONS',
  LOAD_VISUALISATIONS_BY_PAGE_ID = 'LOAD_VISUALISATIONS_BY_PAGE_ID',
  LOAD_VISUALISATIONS_FROM_SNAPSHOT = 'LOAD_VISUALISATIONS_FROM_SNAPSHOT',
  CLEAR_VISUALISATIONS_STORE = 'CLEAR_VISUALISATIONS_STORE',
  EXPORT_XLSX_BY_ID = 'EXPORT_XLSX_BY_ID',
  UPDATE_DATA_SETTINGS_SQL_DATA = 'UPDATE_DATA_SETTINGS_SQL_DATA',
}

/* Creation Functions Types */

export type DataInitialVisualisationsType<
  VisualisationDataSettings extends DefaultDataSettingsType,
  VisualisationViewSettings extends DefaultViewSettingsInterface,
  VisualisationBackgroundImagesSettings extends DefaultBackgroundImagesSettingsType,
  VisualisationSqlDataSettings extends DefaultSqlDataInterface,
  VisualisationType extends VisualisationTypeType,
> = Pick<
  VisualisationOptionsInterface<
    VisualisationDataSettings,
    VisualisationViewSettings,
    VisualisationBackgroundImagesSettings,
    VisualisationSqlDataSettings,
    VisualisationType
  >,
  'pageId' | 'id'
>;

export type InitialVisualisationsFnType<
  VisualisationDataSettings extends DefaultDataSettingsType,
  VisualisationViewSettings extends DefaultViewSettingsInterface,
  VisualisationBackgroundImagesSettings extends DefaultBackgroundImagesSettingsType,
  VisualisationSqlDataSettings extends DefaultSqlDataInterface,
  VisualisationType extends VisualisationTypeType,
> = (
  data: DataInitialVisualisationsType<
    VisualisationDataSettings,
    VisualisationViewSettings,
    VisualisationBackgroundImagesSettings,
    VisualisationSqlDataSettings,
    VisualisationType
  >,
) => VisualisationOptionsInterface<
  VisualisationDataSettings,
  VisualisationViewSettings,
  VisualisationBackgroundImagesSettings,
  VisualisationSqlDataSettings,
  VisualisationType
>;

export type InitialVisualisationsFnsType = Record<
  VisualisationTypeType,
  InitialVisualisationsFnType<
    DefaultDataSettingsType,
    DefaultViewSettingsInterface,
    DefaultBackgroundImagesSettingsType,
    DefaultSqlDataInterface,
    VisualisationTypeType
  >
>;

export interface InitialVisualisationProps {
  visualisationType: VisualisationTypeType;
  data: DataInitialVisualisationsType<
    DefaultDataSettingsType,
    DefaultViewSettingsInterface,
    DefaultBackgroundImagesSettingsType,
    DefaultSqlDataInterface,
    VisualisationTypeType
  >;
}

/* Payload Interfaces */

export type AddNewVisualisationPayload = InitialVisualisationProps;

export type AddNewVisualisationByDataPayload = DefaultVisualisationOptionsType;

export interface VisualisationValuesPayload extends VisualisationIdInterface {
  data: VisualisationValuesInterface;
}

export interface UpdatePositionConfigPayload extends VisualisationIdInterface {
  positionConfig: Partial<BoardPositionConfigInterface>;
}

export interface UpdateSqlDataPayload extends VisualisationIdInterface {
  data: Partial<DefaultSqlDataInterface>;
}

export interface UpdateVisualisationsLoadingList extends VisualisationIdInterface {
  id: string;
}

export interface UpdateVisualisationsErrorList extends VisualisationIdInterface {
  id: string;
  text?: string | null;
}

export interface UpdateEventsDataPayload extends VisualisationIdInterface {
  events: Partial<EventsSettingsInterface>;
}

export interface UpdateViewSettingsPayload extends VisualisationIdInterface {
  viewSettings: Partial<DefaultViewSettingsInterface>;
}

export interface UpdateBackgroundSettingsPayload extends VisualisationIdInterface {
  backgroundSettings: Partial<DefaultBackgroundImagesSettingsInterface>;
}

export interface UpdateDataSettingsPayload<
  VisualisationDataSettings extends DefaultDataSettingsInterface = DefaultDataSettingsInterface,
> extends VisualisationIdInterface {
  dataSettings: Partial<VisualisationDataSettings>;
}

export interface LoadVisualisationValuesPayload extends StrictKeys<ModelIdInterface> {
  visualisationId: string;
  projectId: string;
  sqlRequest: string;
  rls?: RLSInterface;
}

export interface IncisionSelectItemInterface extends SelectItemInterface, IdInterface, CustomRequestInterface {}

export interface IndicatorSelectItemInterface extends SelectItemInterface, IdInterface, VisualisationOperationTypeInterface {}

export interface SqlDependencyInterface
  extends IsRealDataInterface,
    Partial<ActiveIncisionIdInterface>,
    ServiceSelectValuesInterface,
    VariablesSelectValuesInterface,
    ImagesSelectValuesInterface,
    IdInterface,
    DefaultSqlDataInterface {
  incisions: IncisionSelectItemInterface[];
  indicators: IndicatorSelectItemInterface[];
  limit?: SqlLimitInterface;
}

export interface SqlDependencyTableInterface
  extends IsRealDataInterface,
    Partial<ActiveIncisionIdInterface>,
    ServiceSelectValuesInterface,
    VariablesSelectValuesInterface,
    ImagesSelectValuesInterface,
    IdInterface,
    DefaultSqlDataInterface {
  incisions: IncisionSelectItemInterface[];
  incisionsInHeader: IncisionSelectItemInterface[];
  indicators: IndicatorSelectItemInterface[];
  limit?: SqlLimitInterface;
}

export type LoadVisualisationsOutput = Pick<VisualisationStateInterface, 'visualisationsByPages' | 'visualisations'>;

/* Visualisation Interfaces */

/* Tree */
export interface SignaturesInterface extends IsShowInterface {
  position: PositionSettingType;
  location: PositionSettingType;
}

export interface TreeIncisionInterface extends DefaultIncisionInterface {
  colors: PaletteValuesByThemeType | null;
  propertiesIncisionName: DefaultPropertiesInterface;
  propertiesIncisionValue: DefaultPropertiesInterface;
  propertiesIncisionNameValue: DefaultPropertiesInterface;
}

export interface TreeIndicatorSettingsInterface extends DefaultIndicatorSettingsInterface, IsShowValueInterface {}

export interface TreeIndicatorInterface extends DefaultIndicatorInterface {
  settings: TreeIndicatorSettingsInterface;
}

export interface TreeDataSettings extends DefaultDataSettingsInterface {
  incisions: TreeIncisionInterface[];
  indicators: TreeIndicatorInterface[];
}

export interface TreeViewSettings extends StrictDefaultViewSettingsInterface, ShowTipsInterface {
  showLegend: boolean;
  showNameIncision: boolean;
  signatures: SignaturesInterface;
}

export type TreeVisualisationType = VisualisationOptionsInterface<
  TreeDataSettings,
  TreeViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'tree'
>;

/* Text */
export type TextImageType = 'link' | 'fromDatabase';

export interface ColorSettingsStateInterface extends IsShowInterface {
  color: ColorValuesByThemeType | null;
}

export interface TextPositionInterface {
  positionHorizontal: PositionSettingType;
  positionVertically: PositionSettingType;
}

export interface BackgroundSettingsInterface extends IsShowInterface {
  colorSettings: {
    background: ColorSettingsStateInterface;
    hover: ColorSettingsStateInterface;
    active: ColorSettingsStateInterface;
    backgroundColorBy: ColorByInterface;
  };
}

export interface TextCarouselAutoPlayInterface extends IsActiveInterface {
  value: number;
}

export interface TextPropertiesSettingsInterface extends DefaultPropertiesInterface {
  text: TextSettingInterface;
}

export interface TextVariablesSettingsInterface {
  formatting: FormattingInterface;
  textPropertiesSettings: TextPropertiesSettingsInterface;
}

export interface TextVariablesInterface
  extends CustomRequestInterface,
    VisualisationIdInterface,
    Pick<VisualisationFieldNameInterface, 'name'> {
  settings: TextVariablesSettingsInterface;
  emptyValues: IndicatorEmptyValuesInterface;
}

export interface ImagesInterfaceTextImagesInterface
  extends CustomRequestInterface,
    VisualisationIdInterface,
    VisualisationFieldNameInterface,
    FictionalDataInterface,
    VisualisationFieldNameInterface {}

export interface TextPropertiesInterface extends DefaultPropertiesInterface {
  lineHeight: number;
  color: ColorValuesByThemeType | null;
  opacity: number;
  padding: ConnectedCellPaddingSettings;
  fontColorBy: ColorByInterface;
}

export interface ScrollSettingsInterface {
  showHorizontalScroll: boolean;
  showVerticalScroll: boolean;
}

export interface TextViewSettings extends DefaultViewSettingsInterface, ScrollSettingsInterface {
  text: TextSettingInterface;
  position: TextPositionInterface;
  textProperties: DefaultPropertiesInterface;
  backgroundSettings: BackgroundSettingsInterface;
}

export interface BackgroundImagesSettingsInterface {
  imagesSettings: {
    backgroundImagesBy: ImageByInterface;
  };
}

export interface BackgroundImageSelectorProportionsInterface {
  proportionsImageType: ProportionsImageSettingType;
}

export interface TextBackgroundSettings
  extends DefaultBackgroundImagesSettingsInterface,
    BackgroundImageSelectorProportionsInterface {
  backgroundImages: BackgroundImagesSettingsInterface;
  imagesLinkType: TextImageType;
  carouselAutoPlay: TextCarouselAutoPlayInterface;
}

export interface TextDataSettings extends DefaultDataSettingsInterface {
  incisions: DefaultIncisionInterface[];
  variables: TextVariablesInterface[];
}

export type TextVisualisationType = VisualisationOptionsInterface<
  TextDataSettings,
  TextViewSettings,
  TextBackgroundSettings,
  DefaultSqlDataInterface,
  'text'
>;

/* Pie */

export type PieIndicatorValueFormatType = 'absolute' | 'percent';
export type PieIndicatorLabelFormatType = 'value' | 'name';

interface PieIncisionSettingsInterface extends DefaultIncisionSettingsInterface, GroupByDateSettingsInterface {}

export interface PieIncisionInterface extends DefaultIncisionInterface {
  colors: PaletteValuesByThemeType | null;
  settings: PieIncisionSettingsInterface;
}

interface PieIndicatorsSettingsInterface extends DefaultIndicatorSettingsInterface {
  styleSettings: PieIndicatorDesignInterface;
  labelFormat: PieIndicatorLabelFormatType[];
  properties: DefaultPropertiesInterface;
  valueFormat: PieIndicatorValueFormatType;
}

export interface CenterYCoordinatesInterface extends IsActiveInterface {
  centerXCoordinates: DesignOptionInterface;
  centerYCoordinates: DesignOptionInterface;
}

export interface PieIndicatorDesignInterface {
  pieAnimationElement: boolean;
  borderRadius: number;
  borderWidth: number;
  outerRadius: DesignOptionInterface;
  innerRadius: DesignOptionInterface;
  centerCoordinates: CenterYCoordinatesInterface;
  arcStartAngle: number;
  arcEndAngle: number;
}

export interface PieIndicatorInterface extends DefaultIndicatorInterface {
  settings: PieIndicatorsSettingsInterface;
}

export type PieRoseTypeTypes = 'pie' | 'area';

export interface PieDataSettings
  extends DefaultDataSettingsInterface,
    ActiveIncisionIdInterface,
    ColorBySettingsInterface,
    ColorByValueSettingsInterface {
  incisions: PieIncisionInterface[];
  indicators: PieIndicatorInterface[];
  roseType: PieRoseTypeTypes;
}

export interface PieViewSettings
  extends StrictDefaultViewSettingsInterface,
    ShowTipsInterface,
    IncisionSelectorPositionInterface {
  legendSettings: LegendSettingsInterface;
}

export type PieVisualisationType = VisualisationOptionsInterface<
  PieDataSettings,
  PieViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'pie'
>;

/* Bar */

export type BarType = 'group' | 'pile' | 'nominated';

export interface BarTypeInterface {
  type: BarType;
  activePile: boolean;
}

export interface BarDataSettings extends DefaultDataSettingsInterface {
  showSum: ShowSumInterface;
  minAndMax: MinAndMaxInterface;
}

export interface BarIncisionInterface extends DefaultIncisionInterface {
  colors: PaletteValuesByThemeType | null;
  emptyValues: IndicatorEmptyValuesInterface;
}

export interface BarViewSettings
  extends StrictDefaultViewSettingsInterface,
    ShowTipsInterface,
    IncisionSelectorPositionInterface {
  legendSettings: LegendSettingsInterface;
  horizontalZoom: ZoomSettingsInterface;
  verticalZoom: ZoomSettingsInterface;
  dataZoomHorizontalStartAndEnd: StartAndEndInterface;
  dataZoomVerticalStartAndEnd: StartAndEndInterface;
  visualisationPaddings: PaddingVisualisationInterface;
}

export interface BarIndicatorSettingsInterface extends DefaultIndicatorSettingsInterface {
  showValue: ShowValueSettingsInterface;
}

export interface BarIndicatorInterface extends DefaultIndicatorInterface {
  settings: BarIndicatorSettingsInterface;
  color: ColorValuesByThemeType | null;
}

/* Bubble */

export type BubbleSizeMode = 'default' | 'byIndicator';

export type BubbleSizeModeMinAndMax = Omit<MinAndMaxInterface, 'isShow'>;

export interface BubbleSizeModeSettingsInterface {
  type: BubbleSizeMode;
  parameters: {
    diameter: number;
    minAndMax: BubbleSizeModeMinAndMax;
  };
}

export interface BubbleIndicatorSettingsInterface extends DefaultIndicatorSettingsInterface {
  minAndMax: MinAndMaxInterface;
  sizeSettings: BubbleSizeModeSettingsInterface;
}

export interface BubbleIndicatorInterface extends DefaultIndicatorInterface {
  settings: BubbleIndicatorSettingsInterface;
}

export interface BubbleIncisionSettingsInterface extends DefaultIncisionSettingsInterface {
  showValue: ShowValueSettingsInterface;
}

export interface BubbleIncisionInterface extends DefaultIncisionInterface {
  colors: PaletteValuesByThemeType | null;
  settings: BubbleIncisionSettingsInterface;
}

export interface BubbleDataSettings
  extends Omit<BarDataSettings, 'minAndMax'>,
    GradientColorByValueSettingsInterface,
    GradientBackgroundByValueSettingsInterface,
    ActiveIncisionIdInterface {
  rangeIndicatorId: string;
  type: BarType;
  incisions: BubbleIncisionInterface[];
  indicators: BubbleIndicatorInterface[];
}

export interface BubbleViewSettings extends BarViewSettings {
  dataVisualMapSelectedMinAndMax: StartAndEndInterface;
  axisXIncisionSettings: AxisSettingsInterface;
  axisYIncisionSettings: AxisSettingsInterface;
  visualMapSettings: VisualBubbleMapSettingsInterface;
}

export type BubbleVisualisationType = VisualisationOptionsInterface<
  BubbleDataSettings,
  BubbleViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'bubble'
>;

export type VisualBubbleMapSettingsInterface = ExtendedSettingsInterface;

/* Heatmap */

export type HeatmapIndicatorInterface = BarIndicatorInterface;

export type HeatmapIncisionInterface = BarIncisionInterface;

export interface HeatmapDataSettings extends BarDataSettings, GradientBackgroundByValueSettingsInterface {
  type: BarType;
  incisions: HeatmapIncisionInterface[];
  verticalIncisions: HeatmapIncisionInterface[];
  indicators: HeatmapIndicatorInterface[];
  activeVerticalIncisionId: string | null;
  activeHorizontalIncisionId: string | null;
}

export interface HeatmapViewSettings extends BarViewSettings {
  dataVisualMapSelectedMinAndMax: StartAndEndInterface;
  axisXIncisionSettings: AxisSettingsInterface;
  axisYIncisionSettings: AxisSettingsInterface;
  visualMapSettings: VisualMapSettingsInterface;
}

export type HeatmapVisualisationType = VisualisationOptionsInterface<
  HeatmapDataSettings,
  HeatmapViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'heatmap'
>;

export type VisualMapSettingsInterface = ExtendedSettingsInterface;

/* Waterfall */

export interface WaterfallIndicatorTypeInterface {
  type: 'plan' | 'fact' | 'negative' | 'positive' | 'phantom' | 'default';
}

export interface WaterfallIndicatorInterface extends DefaultIndicatorInterface, WaterfallIndicatorTypeInterface {
  settings: WaterfallIndicatorSettingsInterface;
  color: ColorValuesByThemeType | null;
}

export interface WaterfallViewSettings extends BarViewSettings {
  axisIncisionSettings: AxisSettingsInterface;
  axisIndicatorSettings: AxisSettingsInterface<'indicator'>;
}

export interface WaterfallDataSettings extends BarDataSettings, ActiveIncisionIdInterface {
  type: BarType;
  phantomIndicators: WaterfallIndicatorInterface[];
  incisions: WaterfallIncisionInterface[];
  indicators: WaterfallIndicatorInterface[];
  positiveAndNegativeIndicators: WaterfallIndicatorInterface[];
}

export interface WaterfallIndicatorInterface extends WaterfallIndicatorTypeInterface, BarIndicatorInterface {}

export type WaterfallIncisionInterface = BarIncisionInterface;

export type WaterfallVisualisationType = VisualisationOptionsInterface<
  WaterfallDataSettings,
  WaterfallViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'waterfall'
>;

export type WaterfallIndicatorSettingsInterface = BarIndicatorSettingsInterface;

/* LineAndBar */

export interface ShowSumInterface extends IsShowInterface, OrientationInterface {}

export type LineAndBarIncisionInterface = BarIncisionInterface;

export type ShowValuePositionGeneric<IndicatorType extends LineAndBarIndicatorType = 'bar'> = IndicatorType extends 'bar'
  ? BarValuePositionType
  : IndicatorType extends 'line'
  ? TopAndBottomType
  : never;

export interface ShowValueSettingsInterface<IndicatorType extends LineAndBarIndicatorType = 'bar'>
  extends IsShowInterface,
    OrientationInterface {
  position: ShowValuePositionGeneric<IndicatorType>;
  properties: DefaultPropertiesInterface;
}

export interface ElementSettingsInterface {
  type: LineAndBarIndicatorType;
  parameters: {
    lineWidth: number;
    dotWidth: number;
    areaOpacity: number;
    isDotted: boolean;
    lineType: LineType;
  };
  parametersBar: {
    barWidth: DesignOptionInterface;
    barMaxWidth: DesignOptionInterface;
    barMinWidth: DesignOptionInterface;
    stackNumber: number;
    stackOffset: string;
  };
}

export type LineAndBarIndicatorType = 'bar' | 'line';

export type LineType = 'broken' | 'smooth' | 'stepByStep';

export type BarValuePositionType = PositionSettingType | 'outside';

export interface LineAndBarIndicatorSettingsInterface extends BarIndicatorSettingsInterface {
  additionalIndicators: boolean;
  elementSettings: ElementSettingsInterface;
}

export interface LineAndBarIndicatorInterface extends DefaultIndicatorInterface {
  settings: LineAndBarIndicatorSettingsInterface;
  color: ColorValuesByThemeType | null;
}

export interface MinAndMaxInterface extends IsShowInterface {
  min: number;
  max: number;
}

export interface LineAndBarDataSettings
  extends BarDataSettings,
    ActiveIncisionIdInterface,
    ColorBySettingsInterface,
    ColorByValueSettingsInterface {
  rotateTo90: boolean;
  type: BarType;
  barType: BarTypeInterface;
  indicators: LineAndBarIndicatorInterface[];
  incisions: LineAndBarIncisionInterface[];
  minAndMaxAdditional: MinAndMaxInterface;
}

export type LabelsOrientationType = OrientationType | 'incline';
export type LabelOrderValuesType = 'downUp' | 'topDown';

export type AxisType = 'incision' | 'indicator';

export type CenterOrEdgePositionType = 'center' | 'edge';

export interface TickSettingInterface extends IsShowInterface {
  position: CenterOrEdgePositionType;
}

export type AxisSettingsNameType<Type extends AxisType = 'incision'> = (Type extends 'incision'
  ? IsShowInterface
  : TextSettingInterface) & { type: AutoEndManualType; position: PositionValueType };

export type AxisSettingsInterface<Type extends AxisType = 'incision'> = IsShowInterface & {
  showAxis: boolean;
  position: Type extends 'incision' ? AbsolutePositionType : AbsolutePositionType;
  name: AxisSettingsNameType<Type>;
  label: LabelSettingInterface;
  stepSize: AutoOrManualInterface;
  showGrid: boolean;
} & (Type extends 'incision'
    ? {
        labelOrientation: LabelsOrientationType;
        labelOrderValues: LabelOrderValuesType;
        labelSize: AutoOrManualInterface;
        tickLabel: TickSettingInterface;
      }
    : // eslint-disable-next-line @typescript-eslint/ban-types
      {});

export interface LabelSettingInterface extends IsActiveInterface {
  value: number;
  properties: DefaultPropertiesInterface;
}

export interface ExtendedSettingsInterface extends IsShowInterface {
  type: AutoEndManualType;
  location: LocationValueType;
  position: PositionValueType;
  width: number;
}

export type LegendSettingsInterface = ExtendedSettingsInterface;

export type LocationValueType = {
  type: AbsolutePositionType;
  value: number;
};

export type PositionValueType = {
  type: PositionSettingType;
  value: number;
};

export interface ZoomSettingsInterface extends IsShowInterface {
  padding: AutoOrManualInterface;
}

export interface PaddingVisualisationInterface extends IsShowInterface {
  paddingsVisualisation: PaddingsVisualisationInterface;
}

export interface LineAndBarViewSettings extends BarViewSettings {
  axisAdditionalIndicatorSettings: AxisSettingsInterface<'indicator'>;
  axisIncisionSettings: AxisSettingsInterface;
  axisIndicatorSettings: AxisSettingsInterface<'indicator'>;
}

export type LineAndBarVisualisationType = VisualisationOptionsInterface<
  LineAndBarDataSettings,
  LineAndBarViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'lineAndBar'
>;

/* Table */

export interface ColumnWidthSettings extends IsActiveInterface {
  width: number;
}

export interface ColumnWidthSettingsInterface {
  columnWidthSettings: ColumnWidthSettings;
}

export interface SortSettings extends IsActiveInterface {
  type: 'desc' | 'asc';
}

export interface SortSettingsInterface {
  sortSettings: SortSettings;
}

export interface TableIncisionSettingsInterface
  extends DefaultIncisionSettingsInterface,
    ColumnWidthSettingsInterface,
    SortSettingsInterface {
  properties: DefaultPropertiesInterface;
  indentation: IndentationInterface;
  hasIndentation: boolean;
  isSort: boolean;
}

export interface TableIncisionInterface extends DefaultIncisionInterface {
  settings: TableIncisionSettingsInterface;
  color: ColorValuesByThemeType | null;
}

export interface IsAutoAggregationInterface {
  isAutoAggregation: boolean;
  formatting: FormattingInterface;
}

export interface TotalSettings extends IsShowInterface, VisualisationOperationTypeInterface, IsAutoAggregationInterface {}

/* TODO: Make dynamic markers as SQL query  */
export type DynamicsMarkerSymbolType = 'rect' | 'rhombus' | 'circle' | 'star' | 'cross' | 'arrowUp' | 'arrowDown';

export interface DynamicsMarkerSettings extends IsShowInterface {
  position: RightAndLeftType;
}

export interface TableIndicatorSettingsInterface
  extends DefaultIndicatorSettingsInterface,
    ColumnWidthSettingsInterface,
    MakeHyperLinksInterface,
    SortSettingsInterface {
  totalSettings: TotalSettings;
  properties: DefaultPropertiesInterface;
  dynamicsMarkerSettings: DynamicsMarkerSettings;
  indentation: IndentationInterface;
  hasIndentation: boolean;
  isSort: boolean;
}

export interface TableIndicatorInterface extends DefaultIndicatorInterface {
  settings: TableIndicatorSettingsInterface;
  color: ColorValuesByThemeType | null;
}

export interface TableDataSettings
  extends DefaultDataSettingsInterface,
    ColorByValueSettingsInterface,
    BackgroundByValueSettingsInterface {
  incisionsInHeader: TableIncisionInterface[];
  incisions: TableIncisionInterface[];
  indicators: TableIndicatorInterface[];
  orderBy: SortingInterface[];
  limitGrouping: LimitSettingInterface;
  hasAllGroupIncision: boolean;
}

export type BeatType = 'background' | 'line';

export interface TotalLocationSettingsInterface {
  isShow: boolean;
  position: RadioSelectedItem<TopAndBottomType>;
}

export interface IndentationInterface {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export interface TotalRowSettingsInterface {
  isShow: boolean;
  location: TotalLocationSettingsInterface;
  properties: DefaultPropertiesInterface;
  overfill: boolean;
  hasIndentation: boolean;
  indentation: IndentationInterface;
}

export interface IsBeatInterface {
  isBeat: boolean;
  color: PaletteValuesByThemeType | ColorValuesByThemeType | null;
  countUnit: number;
}

export interface TableHeaderSettingsInterface extends TotalRowSettingsInterface {
  headerBeatLine: IsBeatInterface;
}

export interface TableBodySettingsInterface {
  propertiesIncisions: DefaultPropertiesInterface;
  propertiesIndicators: DefaultPropertiesInterface;
  verticalLines: IsBeatInterface;
  horizontalLines: IsBeatInterface;
  externalBeat: IsBeatInterface;
  rowsBeat: IsBeatInterface;
  indentation: IndentationInterface;
}

export interface TableViewSettings extends StrictDefaultViewSettingsInterface {
  showVerticalLine: boolean;
  showHorizontalLine: boolean;
  isAdaptive: boolean;
  incisionBeat: BeatType[];
  headerBeat: BeatType[];
  totalBeat: BeatType[];
  headerSettings: TableHeaderSettingsInterface;
  bodySettings: TableBodySettingsInterface;
  subtotalsSettings: TotalRowSettingsInterface;
  totalRowSettings: TotalRowSettingsInterface;
}

export type TableVisualisationType = VisualisationOptionsInterface<
  TableDataSettings,
  TableViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'table'
>;

export interface ExportDataInterface {
  visualizationId: string;
  data: SQLRequestInterface;
  exportType: ExportType;
}

export interface SortConditionColorInterface {
  dataSettings: DefaultDataSettingsInterface;
  alias: 'colorsValueBy' | 'backgroundByValueAlias';
  valueCondition: 'fontColorBy' | 'backgroundColorBy';
}

export interface getSqlRequestForGroupingRowTableInterface {
  id: string;
  columnNextIndex?: number;
  parentsChain?: string[];
  limitData?: Limit;
  incisionsInHeaderNames: string[];
  isPivotTable?: boolean;
}
