import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { mainHeaderHeight } from 'components/layouts/HeaderLayout/constants';

export const MainLayoutWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: var(${ColorVarsEnum.Level_5_application});
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  height: calc(100% - ${mainHeaderHeight});
`;
