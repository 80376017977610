import React from 'react';
import { StyledSubSection } from 'styles/Subsection';

interface SubSectionProps {
  children: React.ReactNode;
  width?: string;
  offsetLeft?: string;
}

const SubSection: React.FC<SubSectionProps> = ({ children, width = '340px', offsetLeft = '-12px' }) => {
  return (
    <StyledSubSection width={width} offsetLeft={offsetLeft}>
      {children}
    </StyledSubSection>
  );
};

export default SubSection;
