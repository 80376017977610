import { SignaturesInterface } from 'store/reducers/visualisations/types';
import { updateTreeViewSettingsAction } from 'store/reducers/visualisations/actions';
import { store } from 'store';

const dispatch = store.dispatch;

export const onChangeSignatures = (signatures: SignaturesInterface) =>
  dispatch(
    updateTreeViewSettingsAction({
      signatures,
    }),
  );

export const onShowTipsChange = (showTips: boolean) =>
  dispatch(
    updateTreeViewSettingsAction({
      showTips,
    }),
  );

export const onShowNameIncisionChange = (showNameIncision: boolean) =>
  dispatch(
    updateTreeViewSettingsAction({
      showNameIncision,
    }),
  );
