import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan, PrimaryTextType } from 'styles/TextsElements';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { ListBlockItemProps } from '..';
import { InfoIcon } from 'assets/icons/withContainer';
import { FlexContainer } from 'styles/FlexContainer';
import { ContentIconWrapper } from './styles';
import { NoopType } from 'types/global';
import { CONTENT_ITEM_TITLE_ID } from 'modules/ui/lists/BlockList/ListBlockItem/ContentItem/constants';
import { useWindowDimensions } from 'utils/hooks/screenSizeHook';
import { startMobileSize } from 'constants/constants';

interface ContentItemComponentProps {
  title: ListBlockItemProps['title'];
  selectedInfo: ListBlockItemProps['selectedInfo'];
  onOpenInformationModal?: NoopType;
  disabledInfo?: boolean;
}

const ContentItemComponent = ({ title, onOpenInformationModal, disabledInfo, selectedInfo }: ContentItemComponentProps) => {
  const textRef = useRef(null);
  const [isEllipsis, setIsEllipsis] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, clientWidth } = textRef.current;
      const ellipsisApplied = scrollWidth > clientWidth;
      setIsEllipsis(ellipsisApplied);
    }
  }, [title]);

  const ellipsis: PrimaryTextType = useMemo(() => {
    if (width > startMobileSize) {
      return {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '160px',
      };
    }
    return {};
  }, [width]);

  return (
    <FlexContainer flexDirection="row" justifyContent="space-between">
      <StyledTooltip followCursor disableHoverListener={!isEllipsis} title={title} placement="top-start">
        <PrimaryTextSpan
          ref={textRef}
          id={CONTENT_ITEM_TITLE_ID}
          color={`var(${ColorVarsEnum.Level_1})`}
          fontSize="12px"
          lineHeight="24px"
          width="100%"
          {...ellipsis}
        >
          {title}
        </PrimaryTextSpan>
      </StyledTooltip>
      {onOpenInformationModal && !disabledInfo && (
        <ContentIconWrapper
          Icon={InfoIcon}
          colorVar={selectedInfo ? ColorVarsEnum.Accent : ColorVarsEnum.Level_1}
          hoverColorVar={selectedInfo ? ColorVarsEnum.Accent : ColorVarsEnum.Level_2}
          iconHeight="24px"
          iconWidth="24px"
          opacity={0}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();

            if (!!onOpenInformationModal && !selectedInfo) {
              onOpenInformationModal();
            }
          }}
        />
      )}
    </FlexContainer>
  );
};

export const ContentItem = memo(ContentItemComponent);
