import { AdminSourcesSliceStateInterface } from 'store/reducers/adminSources/types';

export const initialAdminSourcesStoreState: AdminSourcesSliceStateInterface = {
  sources: {
    sourcesList: [],
    loading: false,
  },
  sourcesUsersAndGroups: {
    sourcesUsersAndGroupsList: [],
    loading: false,
  },
  usersGroupsList: {
    usersGroupsList: [],
    loading: false,
  },
  activeSource: null,
};
