import { TState } from 'store/index';
import {
  DefaultBackgroundImagesSettingsInterface,
  DefaultBackgroundImagesSettingsType,
  DefaultDataSettingsInterface,
  DefaultSqlDataInterface,
  DefaultViewSettingsInterface,
  DefaultVisualisationOptionsType,
  EventsSettingsInterface,
} from 'store/reducers/visualisations/types';
import { BoardPositionConfigInterface, IdInterface } from 'types/store';
import { FilterDataType } from 'store/reducers/filters/types';
import { ProjectSettingsInterface } from 'store/reducers/projectSettings/types';
import { PaletteItemInterface } from 'store/reducers/palettes/types';
import { ThemeItemInterface } from 'store/reducers/themes/types';
import { AsyncThunk } from '@reduxjs/toolkit/src/createAsyncThunk';
import { Dispatch } from 'redux';

export enum HistoryActionsTypes {
  UNDO_HISTORY_STEP_ACTION = 'UNDO_HISTORY_STEP_ACTION',
  REDO_HISTORY_STEP_ACTION = 'REDO_HISTORY_STEP_ACTION',
  CLEAR_HISTORY_ACTION = 'CLEAR_HISTORY_ACTION',
  SET_PAST_HISTORY_STEP_ACTION = 'SET_PAST_HISTORY_STEP_ACTION',
  SET_IS_ANOTHER_PAGE_ACTION = 'SET_IS_ANOTHER_PAGE_ACTION',
}

export interface HistoryStoreStateType {
  // История назад
  undo: StepInterface[];
  // История вперед
  redo: StepInterface[];
  // Лимит действий истории
  limitOfHistory: number;
  isAnotherPage: AnotherPageInterface;
}

export interface AnotherPageInterface {
  isAnother: boolean;
  redirectPageId: string;
}

export interface TrackingPathsInterface {
  type: TrackingType;
  paths: (state: TState, id?: string) => StepDataType;
  counterAction: AsyncThunk<void, any, AsyncThunkConfig>;
  repeatAction?: AsyncThunk<void, any, AsyncThunkConfig>;
  exceptions?: (action: any) => boolean;
}

type AsyncThunkConfig = {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

type CommonDataTypes =
  | BoardPositionConfigInterface
  | PaletteItemInterface
  | DefaultVisualisationOptionsType
  | DefaultDataSettingsInterface
  | DefaultViewSettingsInterface
  | DefaultSqlDataInterface
  | EventsSettingsInterface
  | DefaultBackgroundImagesSettingsType
  | ProjectSettingsInterface
  | ThemeItemInterface;

export type TrackingType = 'common' | 'selfRepeating';

export type TrackingPathsTypes =
  | 'visualisations/updatePositionConfig'
  | 'visualisations/updateDataSettingsData'
  | 'visualisations/updateViewSettingsData'
  | 'visualisations/updateEventsData'
  | 'projectSettings/updateProjectSettings'
  | 'filters/updateFilter'
  | 'visualisations/updateBackgroundSettingsData'
  | 'palettes/updatePaletteById'
  | 'themes/updateThemeById'
  | 'visualisations/updateSqlData'
  | DeletePathsTypes
  | AddPathsTypes;

export type DeletePathsTypes =
  | 'board/deleteFromLayer'
  | 'filters/removeFilterById'
  | 'palettes/removePaletteById'
  | 'themes/removeThemeById';

export const exceptionPaths: DeletePathsTypes[] = [
  'board/deleteFromLayer',
  'filters/removeFilterById',
  'palettes/removePaletteById',
  'themes/removeThemeById',
];

export type AddPathsTypes = 'board/addToLayer' | 'filters/addNewFilter' | 'palettes/addNewPalette' | 'themes/addNewTheme';

export const addPaths: AddPathsTypes[] = [
  'board/addToLayer',
  'filters/addNewFilter',
  'palettes/addNewPalette',
  'themes/addNewTheme',
];

export interface DataInterface {
  step: StepInterface;
}

type StepDataType = '' | CommonDataTypes | undefined;

export interface StepInterface {
  data: StepDataType;
  type: TrackingPathsTypes;
  id?: string;
  pageId: string;
  contextLayers?: string[];
}

export enum ReturnHistoryAction {
  RETURN_POSITION_CONFIG_BY_ID = 'RETURN_POSITION_CONFIG_BY_ID',
  RETURN_REMOVE_VISUALISATION_BY_ID = 'RETURN_REMOVE_VISUALISATION_BY_ID',
  RETURN_DATA_SETTINGS = 'RETURN_DATA_SETTINGS',
  RETURN_VIEW_SETTINGS = 'RETURN_VIEW_SETTINGS',
  RETURN_EVENTS_SETTINGS = 'RETURN_EVENTS_SETTINGS',
  RETURN_PROJECT_SETTINGS = 'RETURN_PROJECT_SETTINGS',
  COUNTERACTION_ADD_TO_LAYER = 'COUNTERACTION_ADD_TO_LAYER',
  REPEAT_ADD_TO_LAYER = 'REPEAT_ADD_TO_LAYER',
  RETURN_FILTER_ID = 'RETURN_FILTER_ID',
  RETURN_FILTER_SETTINGS = 'RETURN_FILTER_SETTINGS',
  RETURN_BACKGROUND_SETTINGS = 'RETURN_BACKGROUND_SETTINGS',
  REPEAT_FILTER_FROM_LAYER = 'REPEAT_FILTER_FROM_LAYER',
  RETURN_PALETTE_SETTINGS = 'RETURN_PALETTE_SETTINGS',
  COUNTERACTION_PALETTE = 'COUNTERACTION_PALETTE',
  REPEAT_PALETTE = 'REPEAT_PALETTE',
  COUNTERACTION_FILTER_FROM_LAYER = 'COUNTERACTION_FILTER_FROM_LAYER',
  RETURN_THEME_SETTINGS = 'RETURN_THEME_SETTINGS',
  REPEAT_THEME = 'REPEAT_THEME',
  COUNTERACTION_THEME = 'COUNTERACTION_THEME',
}

// --------------------------------ACTIONS--------------------------------
export interface ReturnPositionConfigByIdActionInterface extends IdInterface {
  data: BoardPositionConfigInterface;
}

export interface ReturnDataSettingsByIdTypedActionInterface extends IdInterface {
  data: DefaultDataSettingsInterface;
}

export interface ReturnFilterSettingsActionInterface extends IdInterface {
  data: FilterDataType;
}

export interface ReturnViewSettingsActionInterface extends IdInterface {
  data: DefaultViewSettingsInterface;
}

export interface ReturnEventsSettingsActionInterface extends IdInterface {
  data: EventsSettingsInterface;
}

export interface ReturnBackgroundSettingsActionInterface extends IdInterface {
  data: DefaultBackgroundImagesSettingsInterface;
}

export interface CounteractionAddToLayerActionInterface extends IdInterface {
  data: ReturnRemoveVisualisationByIdActionInterface;
}

export interface ReturnProjectSettingsActionInterface {
  data: ProjectSettingsInterface;
}

export interface ReturnVisualisationActionInterface extends ReturnRemoveVisualisationByIdActionInterface {
  data: DefaultVisualisationOptionsType;
}

export interface ReturnRemoveVisualisationByIdActionInterface extends IdInterface {
  pageId: string;
}

export interface ReturnFilterInterface extends IdInterface {
  pageId: string;
  data: FilterDataType;
}

// --------------------------------PAYLOAD--------------------------------

export interface StepAddUndoPayload {
  undo: StepInterface;
}

export interface StepAddRedoPayload {
  redo: StepInterface;
}

export interface SetUndoHistoryStepPayload {
  undo: StepInterface[];
}

export interface SetRedoHistoryStepPayload {
  redo: StepInterface[];
}

export interface SetIsAnotherPageActionPayload {
  value: AnotherPageInterface;
}
