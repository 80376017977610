export const showPaddingVisualisationTitle = 'Ручные отступы графика';

export type PaddingSettingsType = {
  title: string;
  key: string;
};

export const topSettings: PaddingSettingsType[] = [
  { title: 'Название оси', key: 'axisNamePadding' },
  { title: 'Шкала приближения', key: 'dataZoomPadding' },
  { title: 'Легенда', key: 'legendPadding' },
  { title: 'Внешний', key: 'externalPadding' },
];
