import { Menu, MenuItem } from '@mui/material';
import { PointsMenuIcon } from 'assets/icons/menu';
import { IconType, SuccessIcon } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import React, { useEffect, useRef, useState } from 'react';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { NoopValueType } from 'types/global';
import { Option } from 'components/shared/ui/menu/MenuIconList/types';
import { FlexContainer } from 'styles/FlexContainer';
import { MENU_LIST_COMPONENT } from 'modules/workspace/constans';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { WrapperContainer } from './styles';

type MenuListComponentProps<T extends string = string> = {
  options: Option<T>[];
  onSelect?: NoopValueType<T>;
  selected?: T | null;
  Icon?: IconType;
  allowToggle?: boolean;
};

export const MenuListComponent = <T extends string>({
  options,
  selected,
  onSelect,
  Icon = PointsMenuIcon,
  allowToggle,
}: MenuListComponentProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const anchorElRef = useRef<HTMLElement | null>(null);

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);

    event.stopPropagation();
  };

  const onSelectValue = (value: T) => {
    if (onSelect) {
      const selectValue = allowToggle && selected === value ? ('' as T) : value;

      onSelect(selectValue);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const onClickAway = (event: MouseEvent) => {
      if (anchorElRef.current && event.target instanceof Node && !anchorElRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', onClickAway);
    return () => {
      document.removeEventListener('mousedown', onClickAway);
    };
  }, []);

  useEffect(() => {
    anchorElRef.current = anchorEl;
  }, [anchorEl]);

  return (
    <div>
      {allowToggle ? (
        <StyledTooltip placement="top" title="Сортировка">
          <div>
            <WrapperContainer>
              <IconWrapper Icon={Icon} onClick={onClick} disableDefaultStyles />
            </WrapperContainer>
          </div>
        </StyledTooltip>
      ) : (
        <IconWrapper
          hoverColorVar={ColorVarsEnum.Level_3}
          colorVar={ColorVarsEnum.Level_3}
          containerWidth="20px"
          containerHeight="20px"
          iconHeight="20px"
          iconWidth="20px"
          Icon={Icon}
          onClick={onClick}
        />
      )}
      <Menu
        id={MENU_LIST_COMPONENT}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '25ch',
            zIndex: 125,
          },
        }}
      >
        {options.map(({ value, name, onClick, disabled, Icon }) => (
          <MenuItem
            hidden={disabled}
            style={{ backgroundColor: value === selected ? `var(${ColorVarsEnum.Level_3_menu})` : undefined }}
            key={`${name}${value}`}
            selected={value === selected}
            onClick={(e) => {
              onSelect ? onSelectValue(value) : onClick && onClick();
              e.stopPropagation();
            }}
          >
            <FlexContainer flexDirection="row" alignItems="center" gap="8px">
              {Icon && <IconWrapper iconWidth="20px" iconHeight="20px" Icon={Icon} />}
              <div style={{ opacity: value === selected ? 1 : 0 }}>
                <IconWrapper iconWidth="10px" iconHeight="10px" Icon={SuccessIcon} />
              </div>
              <PrimaryTextParagraph
                lineHeight="14px"
                fontSize="14px"
                position="relative"
                color={selected ? `var(${ColorVarsEnum.Level_1})` : `var(${ColorVarsEnum.Level_2})`}
              >
                {name}
              </PrimaryTextParagraph>
            </FlexContainer>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
