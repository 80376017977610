import React, { FC, useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { StatisticsProgress } from 'modules/ui/StatisticsProgress/StatisticsProgress';
import { calculateUsagePercentage } from 'utils/utils';
import { AdminLicenseMetaInterface } from 'store/reducers/adminLicenses/types';
import { roleInfos } from './constants';
import { NoopValueType } from 'types/global';
import { RoleKeyType } from './types';

type FilterRoleStatisticsProps = {
  adminLicenseMeta?: AdminLicenseMetaInterface[];
  onStatisticsProgressClick: NoopValueType<RoleKeyType>;
};

export const FilterRoleStatistics: FC<FilterRoleStatisticsProps> = ({ adminLicenseMeta, onStatisticsProgressClick }) => {
  const [activeRole, setActiveRole] = useState<RoleKeyType>('general');

  const getRoleCounts = (roleKey: string): [number, number] => {
    if (roleKey === 'general') {
      const total = adminLicenseMeta?.reduce(
        (acc, { count, usedCount }) => {
          acc.count += count;
          acc.usedCount += usedCount;
          return acc;
        },
        { count: 0, usedCount: 0 },
      );

      return [total?.count || 0, total?.usedCount || 0];
    } else {
      const roleData = adminLicenseMeta?.find(({ role }) => role === roleKey);
      return [roleData?.count || 0, roleData?.usedCount || 0];
    }
  };

  return (
    <FlexContainer flexDirection="row" gap="48px">
      {roleInfos.map(({ title, roleKey }) => {
        const [count, usedCount] = getRoleCounts(roleKey);
        const percentage = calculateUsagePercentage(usedCount, count);
        const isActive = activeRole === roleKey;

        return (
          <StatisticsProgress
            key={roleKey}
            title={title}
            active={isActive}
            countProgress={count}
            fullCountProgress={usedCount}
            valueProgress={percentage}
            onClick={() => {
              setActiveRole(roleKey);
              onStatisticsProgressClick(roleKey);
            }}
          />
        );
      })}
    </FlexContainer>
  );
};
