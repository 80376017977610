import { SourceDriverType } from 'components/console/elements/sideBar/Forms/DatabaseForm/types';

export const driverItems: Array<{ name: string; value: SourceDriverType }> = [
  { name: 'ClickHouse', value: 'clickHouse' },
  { name: 'PostgreSQL', value: 'postgres' },
  { name: 'MSSQL', value: 'ms' },
  { name: 'MYSQL', value: 'my' },
  { name: 'ORACLE', value: 'oracle' },
  { name: 'HIVE', value: 'hive' },
];

export const driverIdMap: Record<SourceDriverType, string> = {
  clickHouse: '',
  postgres: '',
  ms: '',
  my: '',
  oracle: '',
  hive: '',
};

export const driverIdValidation = Object.keys(driverIdMap) as SourceDriverType[];
