import { ModelContent, ModelsBoardWrapper } from 'components/contents/Models/ModelsBoard/styles';
import { DropDownBlock } from 'modules/ui/blocks/DropDownBlock';
import React from 'react';
import { Board } from 'components/contents/Models/Board';
import { ACTIVE_MODEL_TABLE_AREA_CLASS } from 'modules/workspace/constans';
import { ProjectIdInterface } from 'types/store';
import { ModelItemControls } from 'components/contents/Models/ModelItemControls';
import { useSelector } from 'react-redux';
import { getActiveModelItemAlias } from 'store/reducers/models/getters';

type ModelsBoardProps = ProjectIdInterface;

export const ModelsBoard = ({ projectId }: ModelsBoardProps) => {
  const activeModelItemAlias = useSelector(getActiveModelItemAlias);

  return (
    <ModelsBoardWrapper>
      <DropDownBlock
        title={activeModelItemAlias?.alias}
        description="Предварительный просмотр"
        dropDownId={ACTIVE_MODEL_TABLE_AREA_CLASS}
        content={<ModelItemControls projectId={projectId} />}
      >
        <ModelContent>
          <Board projectId={projectId} />
        </ModelContent>
      </DropDownBlock>
    </ModelsBoardWrapper>
  );
};
