import { store } from 'store';
import { updateHeatmapDataSettingsAction } from 'store/reducers/visualisations/actions';
import {
  FormattingInterface,
  GradientColorByInterface,
  HeatmapDataSettings,
  IndicatorEmptyValuesInterface,
  MinAndMaxInterface,
  ShowValueSettingsInterface,
} from 'store/reducers/visualisations/types';
import { OnChangeValue } from 'modules/visualisations/Heatmap/settings/DataTab/types';
import { ColorValuesByThemeType, PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { defaultVerticalIncisionName, getBarIncision } from 'store/reducers/visualisations/constants';
import { findNextIndex, moveArrayItem, MoveToType } from 'utils/utils';
import { v4 } from 'uuid';
import { KeysIncisionsHeatmap } from 'modules/visualisations/Heatmap/visualisation/types';

const dispatch = store.dispatch;

export const onMinAndMaxChange = (minAndMax: MinAndMaxInterface) => dispatch(updateHeatmapDataSettingsAction({ minAndMax }));

export const onMoveIncisionHeatmap = (
  dataSettings: HeatmapDataSettings,
  incisionId: string,
  moveTo: MoveToType,
  keysIncision: KeysIncisionsHeatmap,
) => {
  const incisions = dataSettings[keysIncision],
    indexOfIncision = incisions.findIndex(({ id }) => id === incisionId),
    { newArray } = moveArrayItem(incisions, indexOfIncision, moveTo);
  const newIncisions = [...newArray];

  dispatch(
    updateHeatmapDataSettingsAction({
      [keysIncision]: newIncisions,
    }),
  );
};

/* Incision change */

export const onColorIncisionChange: OnChangeValue<PaletteValuesByThemeType | null> = (dataSettings, colors, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) => (id === incision.id ? { ...incision, colors } : incision)),
      indicators: dataSettings.indicators.map((incision) => ({ ...incision, color: null })),
    }),
  );

/* Vertical incision change */

export const onColorVerticalIncisionChange: OnChangeValue<PaletteValuesByThemeType | null> = (dataSettings, colors, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      verticalIncisions: dataSettings.verticalIncisions.map((incision) =>
        id === incision.id
          ? {
              ...incision,
              colors,
            }
          : incision,
      ),
      indicators: dataSettings.indicators.map((incision) => ({ ...incision, color: null })),
    }),
  );

export const onChangeVerticalIncisionName: OnChangeValue<string> = (dataSettings, name, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      verticalIncisions: dataSettings.verticalIncisions.map((incision) =>
        id === incision.id ? { ...incision, name } : incision,
      ),
    }),
  );

export const onChangeVerticalIncisionCustomRequest: OnChangeValue<string | null> = (dataSettings, customRequest, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      verticalIncisions: dataSettings.verticalIncisions.map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, customRequest } } : incision,
      ),
    }),
  );

export const onChangeVerticalIncisionFieldName: OnChangeValue<string> = (dataSettings, fieldName, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      verticalIncisions: dataSettings.verticalIncisions.map((incision) =>
        id === incision.id
          ? {
              ...incision,
              fieldName,
            }
          : incision,
      ),
    }),
  );

export const onChangeFictionalDataVerticalIncision: OnChangeValue<string[]> = (dataSettings, fictionalData, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      verticalIncisions: dataSettings.verticalIncisions.map((incision) =>
        id === incision.id
          ? {
              ...incision,
              fictionalData,
            }
          : incision,
      ),
    }),
  );

export const onChangeEmptyValueVerticalIncision: OnChangeValue<IndicatorEmptyValuesInterface> = (dataSettings, emptyValues, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      verticalIncisions: dataSettings.verticalIncisions.map((incision) =>
        id === incision.id ? { ...incision, emptyValues } : incision,
      ),
    }),
  );

export const onChangeVerticalIncisionNameFromDataBase: OnChangeValue<boolean> = (dataSettings, nameFromDatabase, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      verticalIncisions: dataSettings.incisions.map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, nameFromDatabase } } : incision,
      ),
    }),
  );

export const getNewVerticalIncisions = ({
  dataSettings,
  name,
  defaultIncisionName,
}: {
  dataSettings: HeatmapDataSettings;
  name?: string | null;
  defaultIncisionName: string;
}) => {
  const nextIndex = findNextIndex(
      dataSettings.verticalIncisions.map(({ name }) => name),
      defaultIncisionName,
    ),
    incisionName = defaultIncisionName + nextIndex;

  return getBarIncision({ id: v4(), name: name || incisionName });
};

export const onAddNewVerticalIncision = (dataSettings: HeatmapDataSettings) => {
  const incision = getNewVerticalIncisions({
    dataSettings,
    defaultIncisionName: defaultVerticalIncisionName,
  });

  let newActiveIncisionId: null | string | undefined;

  if (dataSettings?.activeVerticalIncisionId !== undefined) {
    newActiveIncisionId = dataSettings?.activeVerticalIncisionId === null ? incision.id : dataSettings?.activeVerticalIncisionId;
  }

  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      verticalIncisions: [...dataSettings.verticalIncisions, incision],
      activeVerticalIncisionId: newActiveIncisionId,
    }),
  );
};

export const onDeleteVerticalIncision = (dataSettings: HeatmapDataSettings, incisionId: string) => {
  const newIncisions = dataSettings.verticalIncisions.filter(({ id }) => id !== incisionId);

  let newActiveIncisionId: null | string | undefined;

  if (dataSettings?.activeVerticalIncisionId !== undefined) {
    newActiveIncisionId =
      dataSettings?.activeVerticalIncisionId === incisionId
        ? newIncisions?.[0]?.id || null
        : dataSettings?.activeVerticalIncisionId;
  }

  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      verticalIncisions: newIncisions,
      activeVerticalIncisionId: newActiveIncisionId,
    }),
  );
};

/* Indicator change */

export const onChangeShowValue: OnChangeValue<ShowValueSettingsInterface> = (dataSettings, showValue, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, showValue } } : indicator,
      ),
    }),
  );

export const onColorIndicatorChange: OnChangeValue<ColorValuesByThemeType | null> = (dataSettings, color, id) => {
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((incision) => (id === incision.id ? { ...incision, color } : incision)),
      incisions: dataSettings.incisions.map((incision) => ({ ...incision, colors: null })),
    }),
  );
};

export const onChangeIndicatorFormatting: OnChangeValue<FormattingInterface> = (dataSettings, formatting, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, formatting } } : indicator,
      ),
    }),
  );

export const onChangeIndicatorNameFromDataBase: OnChangeValue<boolean> = (dataSettings, nameFromDatabase, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, nameFromDatabase } } : indicator,
      ),
    }),
  );

export const onHeatmapBackgroundBySettingsChange = (gradientBackgroundByValueSettings: GradientColorByInterface) =>
  dispatch(updateHeatmapDataSettingsAction({ gradientBackgroundByValueSettings }));
