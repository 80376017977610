import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { ThemeItemInterface } from 'store/reducers/themes/types';

export const codeEditorThemeOptions: SettingsRadioItem<ThemeItemInterface['codeEditorTheme']>[] = [
  {
    value: 'dark',
    label: 'Темная',
  },
  {
    value: 'light',
    label: 'Светлая',
  },
];
