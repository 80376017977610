import { CopyFlowProjectModal } from 'components/admin/flows/elements/Projects/Modals/CopyFlowProjectModal';
import { InfoFlowProjectModal } from 'components/admin/flows/elements/Projects/Modals/InfoFlowProjectModal';
import { MoveFlowProjectModal } from 'components/admin/flows/elements/Projects/Modals/MoveFlowProjectModal';
import { RollBackFlowProjectModal } from 'components/admin/flows/elements/Projects/Modals/RollBackFlowProjectModal';
import { ModalNameType } from 'components/admin/flows/elements/Projects/types';
import { ModalInfo } from 'store/reducers/modals/types';
import { ModalProps } from 'utils/hooks/useModalActions';

export const SORT_FLOW_PROJECT_SETTINGS = 'sortFlowProjectSettings';

export const modalConfigs: Record<ModalNameType, Omit<ModalInfo<ModalProps>, 'name' | 'componentProps'>> = {
  rollBackFlowProject: {
    Component: RollBackFlowProjectModal,
    modalSettings: {
      position: 'static',
      width: '380px',
      maxHeight: '500px',
      headerText: 'Откат проекта',
    },
  },
  copyingFlowProject: {
    Component: CopyFlowProjectModal,
    modalSettings: {
      position: 'static',
      width: '380px',
      maxHeight: '500px',
      headerText: 'Копирование проекта',
    },
  },
  moveFlowProject: {
    Component: MoveFlowProjectModal,
    modalSettings: {
      position: 'static',
      width: '380px',
      maxHeight: '500px',
      headerText: 'Перемещение проекта',
    },
  },
  infoFlowProject: {
    Component: InfoFlowProjectModal,
    modalSettings: {
      position: 'static',
      width: '320px',
      headerText: 'О проекте',
    },
  },
};
