import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { LoaderBlock, LoaderBlockProps } from './LoaderBlock';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React from 'react';
import { getSizeByType } from './constants';
import { LoaderVisualizationBlock } from './LoaderVisualizationBlock';

export enum LoaderTypeEnum {
  DEFAULT = 'default',
  VISUALIZATION = 'visualization',
}

interface LoaderProps extends LoaderBlockProps {
  backgroundColor?: ColorVarsEnum;
  title?: string;
  direction?: 'row' | 'column';
  type?: LoaderTypeEnum;
}

export const Loader = ({
  backgroundColor = ColorVarsEnum.Level_3_menu,
  size,
  title,
  direction = 'row',
  type = LoaderTypeEnum.DEFAULT,
}: LoaderProps) => {
  return (
    <>
      {type === LoaderTypeEnum.VISUALIZATION && (
        <FlexContainer
          zIndex="20"
          position="absolute"
          left={`calc(50% - ${getSizeByType['small'] / 2}px)`}
          top={`calc(50% - ${getSizeByType['small'] / 2}px)`}
        >
          <LoaderVisualizationBlock />
        </FlexContainer>
      )}
      {type === LoaderTypeEnum.DEFAULT && (
        <FlexContainer backgroundColor={`var(${backgroundColor})`} width="100%" height="100%" alignItems="center">
          <FlexContainer
            gap="10px"
            width="100%"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            flexDirection={direction}
          >
            <FlexContainer>
              <LoaderBlock size={size} />
            </FlexContainer>

            {title && (
              <FlexContainer alignItems="center">
                <PrimaryTextSpan fontSize="14px" lineHeight="14px" color={`var(${ColorVarsEnum.Level_2})`}>
                  {title}
                </PrimaryTextSpan>
              </FlexContainer>
            )}
          </FlexContainer>
        </FlexContainer>
      )}
    </>
  );
};
