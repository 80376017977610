import { ListInterface } from 'modules/settingsContainer/List/types';
import { SourceTablesInterface } from 'store/reducers/loadingScript/types';
import { GetFlatFunctionType, GetOriginListFromFuseResultFunctionType } from 'types/global';
import { FlatModelFromList } from './types';

export const getFlatList: GetFlatFunctionType<FlatModelFromList, ListInterface> = (data) => {
  const flatList: FlatModelFromList[] = [];

  data.forEach(({ items, title: table }, indexTable) => {
    if (items.length > 1) {
      return items?.forEach(({ title: name }, indexColumn) => {
        const list = { table, name, indexTable, indexColumn };

        flatList.push(list);
      });
    }

    return flatList.push({ table, name: table, indexColumn: indexTable });
  });

  return flatList;
};

export const getOriginListFromResult: GetOriginListFromFuseResultFunctionType<FlatModelFromList, ListInterface> = (
  result,
  originData,
) => {
  const listResult: Record<number, SourceTablesInterface['items']> = {};

  result.forEach(({ item }) => {
    if (typeof item.indexTable !== 'undefined' && typeof item.indexColumn !== 'undefined') {
      const column = originData[item.indexTable]?.items[item.indexColumn];
      const currentTableColumns = listResult[item.indexTable] || [];
      listResult[item.indexTable] = [...currentTableColumns, column].filter(Boolean);
    }
  });

  return originData
    .filter(({ title }) => result.some(({ item }) => item.table === title))
    .map((data) => {
      const indexTable = result.find(({ item }) => item.table === data.title)?.item.indexTable;

      if (indexTable !== undefined && listResult[indexTable]) {
        return { ...data, items: listResult[indexTable] };
      }

      return data;
    });
};
