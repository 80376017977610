import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FC } from 'react';
import { AdminSettingsItemType } from 'components/AdminSettingsList/types';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';

interface AdminSettingsListProps {
  settingsList: AdminSettingsItemType[];
}

export const AdminSettingsList: FC<AdminSettingsListProps> = ({ settingsList }) => {
  return (
    <FlexContainer flexDirection="column" width="100%" height="100%">
      {settingsList.map(({ title, description, component }, i) => (
        <FlexContainer
          key={`setting-item-${i}`}
          padding="40px 16px"
          flexDirection="column"
          borderBottom={settingsList.length - 1 !== i ? `1px solid var(${ColorVarsEnum.Level_5})` : ''}
        >
          <FlexContainer marginBottom="24px" flexDirection="column">
            <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_1})`} fontSize="24px" fontWeight={700}>
              {title}
            </PrimaryTextSpan>
            {description && (
              <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_2})`} fontSize="12px" marginTop="16px">
                {description}
              </PrimaryTextSpan>
            )}
          </FlexContainer>

          {component}
        </FlexContainer>
      ))}
    </FlexContainer>
  );
};
