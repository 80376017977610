import { Button } from 'modules/ui/index';
import React from 'react';
import { NoopType } from 'types/global';

interface SaveCancelButtonsProps {
  hasChanges?: boolean;
  onCancel: NoopType;
  onSave: NoopType;
}

export const SaveCancelButtons = ({ onCancel, onSave, hasChanges = true }: SaveCancelButtonsProps) => (
  <>
    <Button disabled={!hasChanges} text="Отменить" needBackground={false} heightSize="small" width="80px" onClick={onCancel} />
    <Button disabled={!hasChanges} text="Сохранить" needBackground={false} heightSize="small" width="80px" onClick={onSave} />
  </>
);
