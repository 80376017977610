import { defaultScrollbarMixin } from 'constants/styles';
import styled from 'styled-components';

interface TextVisualisationContainerProps {
  overflowY?: string;
  overflowX?: string;
}

export const TextVisualisationContainer = styled.div<TextVisualisationContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: all 200ms;
  overflow-y: ${({ overflowY }) => overflowY};
  overflow-x: ${({ overflowX }) => overflowX};
  ${defaultScrollbarMixin};
`;

interface TextFlexContainerProps {
  width?: number;
  padding?: string;
  justifyContent?: string;
}

export const TextFlexContainer = styled.div<TextFlexContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${({ justifyContent }) => justifyContent};
  padding: ${({ padding }) => padding};
  ${defaultScrollbarMixin};
`;
