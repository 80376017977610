import { WizardsStepDataInterface } from 'types/global';
import { DatabaseStep } from 'components/console/elements/sideBar/WizardSourceModalType/steps/DatabaseStep';
import { WizardSourcesType } from 'components/console/elements/sideBar/WizardSourceModalType/types';
import { FileStep } from 'components/console/elements/sideBar/WizardSourceModalType/steps/FileStep';
import { SourcesTypeStep } from 'components/console/elements/sideBar/WizardSourceModalType/steps/SourcesTypeStep';

export const stepDataList: WizardsStepDataInterface<WizardSourcesType> = {
  sources: {
    title: 'Новое подключение',
    Component: SourcesTypeStep,
  },
  database: {
    title: 'Новое подключение',
    Component: DatabaseStep,
  },
  file: {
    title: 'Импорт из файла',
    Component: FileStep,
  },
  /* TODO:use a layout for rest here */
  rest: {
    title: 'Импорт из файла',
    Component: undefined,
  },
};
