import { FlexContainer } from 'styles/FlexContainer';
import { SearchInput } from 'modules/ui/inputs/SearchInput';
import React, { useDeferredValue, useState } from 'react';
import { SortFunctions, useSearchEngine } from 'utils/hooks/searchEngine';
import { FlowList, FlowListProps } from 'modules/settingsContainer/FlowList';
import { ListItemType } from 'modules/settingsContainer/FlowList/types';
import { SortOptions } from 'modules/settingsContainer/SearchList/ListFlowSearch/SortOptions';
import { getFlatList, getOriginListFromResult } from '../constants';
import { SearchContainer } from './styles';
import { NoopValueType } from 'types/global';
import { ShortProjectInterface } from 'modules/ui/ManagerOrAdminComponentsUI/ProjectsSearchUI/types';

export type ListFlowSearchProps = FlowListProps & {
  onChangeShortProject: NoopValueType<ShortProjectInterface>;
  shortProject: ShortProjectInterface;
};

export const ListFlowSearch = ({
  data,
  activeFlowProject,
  openFlowProject,
  onActiveFlow,
  selectedInfoId,
  onOpenInformationModal,
  disabledAddCard,
  isViewer,
  onAddItem,
  onChangeShortProject,
  shortProject,
}: ListFlowSearchProps) => {
  const [searchText, setSearchText] = useState('');
  const deferredSearchText = useDeferredValue(searchText);
  const { sortType, sortOrder } = shortProject;
  const sortFunctions: SortFunctions<ListItemType> = {
    lastModified: (a, b, sortOrder) => {
      const dateA = new Date(String(a.createdAt)).getTime();
      const dateB = new Date(String(b.createdAt)).getTime();
      return sortOrder === 'ascending' ? dateA - dateB : dateB - dateA;
    },
    alphabet: (a, b, sortOrder) => (sortOrder === 'ascending' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title)),
  };

  const { filteredList } = useSearchEngine({
    data: data,
    searchString: deferredSearchText,
    getOriginListFromResult,
    getFlatList,
    sortFunctions,
    sortOrder,
    sortType,
    options: { keys: ['name'], threshold: 0.3, findAllMatches: true, shouldSort: true },
  });

  return (
    <FlexContainer width="100%" flex="1 1 0" gap="24px" flexDirection="column">
      <SearchContainer>
        <SearchInput
          name="search_field"
          placeholder="Поиск"
          needBackground={false}
          width="360px"
          widthContainer="unset"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onClear={() => setSearchText('')}
          useDebounce
        />
        <SortOptions short={shortProject} onChangeShort={onChangeShortProject} />
      </SearchContainer>
      <FlowList
        data={filteredList}
        activeFlowProject={activeFlowProject}
        onActiveFlow={onActiveFlow}
        openFlowProject={openFlowProject}
        onOpenInformationModal={onOpenInformationModal}
        selectedInfoId={selectedInfoId}
        disabledAddCard={disabledAddCard}
        isViewer={isViewer}
        onAddItem={onAddItem}
      />
    </FlexContainer>
  );
};
