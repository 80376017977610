import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ContentIconWrapper } from 'modules/ui/lists/BlockList/ListBlockItem/ContentItem/styles';
import { CardIconContainer, FlowCardIconContainer } from './ImageItem/styles';

const hoverAndActiveStyles = css`
  border: 1px solid var(${ColorVarsEnum.Level_4});
  transition: all 450ms;

  ${ContentIconWrapper} {
    display: flex;
    @media (max-width: 1024px) {
      display: none;
    }
  }

  ${FlowCardIconContainer} {
    svg g rect {
      opacity: 0.1;
      transition: all 450ms;
    }
  }
`;

type ListBlockItemWrapperProps = {
  activeItem?: boolean;
  selectedInfo?: boolean;
};

export const ListBlockItemWrapper = styled.div<ListBlockItemWrapperProps>`
  display: flex;
  flex-direction: column;
  min-width: 214px;
  height: 181px;
  border: 1px solid var(${ColorVarsEnum.Level_5});
  padding: 12px;
  justify-content: space-between;
  cursor: pointer;
  gap: 8px;

  :hover ${CardIconContainer} {
    rect {
      opacity: 0.1;
    }
  }

  @media (min-width: 1024px) {
    &:hover {
      ${hoverAndActiveStyles}
    }
  }

  ${({ activeItem }) => activeItem && hoverAndActiveStyles};
  ${({ selectedInfo }) =>
    selectedInfo &&
    css`
      ${ContentIconWrapper} {
        display: flex;
        @media (max-width: 1024px) {
          display: none;
        }
      }
    `}};


  @media (max-width: 1024px) {
    flex-direction: row;
    align-items: center;
    min-width: 328px;
    justify-content: flex-start;
    border: none;
    height: 68px;
    gap: 8px;
  }

  @media (max-width: 720px) {
    min-width: 100%;
  }
`;
