import React from 'react';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { TextPositionInterface } from 'store/reducers/visualisations/types';
import {
  signaturesPositionOptions,
  signaturesPositionVerticalOptions,
} from 'modules/settingsContainer/common/data/SignaturesSettings/constants';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { OnValueChange } from 'types/global';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { FlexContainer } from 'styles/FlexContainer';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { PositionSettingType } from 'types/store';

type PositionSettingsProps = OnValueChange<PositionSettingType>;

export const PositionSettings = ({ value, onChange }: PositionSettingsProps) => {
  const activePositionValue = getActiveRadioValue(signaturesPositionOptions, value);

  return (
    <MainContainerSettings titleText="Позиционирование">
      <SettingsRadio
        onChange={({ value: position }) => onChange(position as PositionSettingType)}
        activeValue={activePositionValue}
        options={signaturesPositionOptions}
      />
    </MainContainerSettings>
  );
};

type TextPositionSettingsProps = OnValueChange<TextPositionInterface>;

export const TextPositionSettings = ({ value: position, onChange }: TextPositionSettingsProps) => {
  const { positionHorizontal, positionVertically } = position;

  const activePositionHorizontalValue = getActiveRadioValue(signaturesPositionOptions, positionHorizontal);
  const activePositionVerticallyValue = getActiveRadioValue(signaturesPositionOptions, positionVertically);

  return (
    <MainContainerSettings titleText="Позиционирование контента">
      <GroupContainerSettings titleText="По горизонтали">
        <ElementContainerSettings>
          <FlexContainer alignItems="center" flexDirection="row" justifyContent="space-between" width="100%">
            <SettingsRadio
              onChange={({ value: positionHorizontal }) => {
                onChange({
                  positionHorizontal,
                  positionVertically,
                });
              }}
              activeValue={activePositionHorizontalValue}
              options={signaturesPositionOptions}
            />
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
      <GroupContainerSettings titleText="По вертикали">
        <ElementContainerSettings>
          <FlexContainer alignItems="center" flexDirection="row" justifyContent="space-between" width="100%">
            <SettingsRadio
              onChange={({ value: positionVertically }) => {
                onChange({
                  positionVertically,
                  positionHorizontal,
                });
              }}
              activeValue={activePositionVerticallyValue}
              options={signaturesPositionVerticalOptions}
            />
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
