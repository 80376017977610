import React from 'react';
import { GlobalModals } from 'components/AppModals/GlobalModals';
import { ConfirmationModal } from 'components/AppModals/ConfirmationModal';
import { WizardModal } from 'components/AppModals/WizardModal';

export const AppModals = () => {
  return (
    <>
      <GlobalModals />
      <ConfirmationModal />
      <WizardModal />
    </>
  );
};
