import { ContainerSettingsInterface } from 'types/store';

export const setSliceFn = <Slice extends object>(state: Slice, { payload: slice }: { payload: Slice }) => {
  (Object.keys(slice) as Array<keyof Slice>).forEach((key) => {
    (state[key] as Slice[keyof Slice]) = slice[key];
  });
};

export const defaultContainerSettings: ContainerSettingsInterface = {
  disableDragging: false,
  borderSettings: {
    isActive: false,
    radius: 8,
    width: 1,
    style: 'solid',
    color: null,
  },
  shadowSettings: {
    isActive: false,
    blurRadius: 10,
    offsetX: 0,
    offsetY: 5,
    shadowColorSettings: {
      shadowColor: {
        isShow: false,
        color: null,
      },
      hover: {
        isShow: false,
        color: null,
      },
      shadowColorBy: {
        type: 'default',
        byCondition: {
          colors: [],
          sqlCondition: null,
          alias: 'shadowColor',
        },
        byValue: {
          alias: 'shadowColor',
          colors: null,
        },
      },
    },
  },
};
