import { ProjectsTab } from 'components/admin/flows/elements/Projects';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
  changeActiveFlowAction,
  deleteByIdFlowAction,
  deleteFlowAction,
  loadFlowsAction,
} from 'store/reducers/adminFlows/actions';
import { FlowModal } from 'components/admin/flows/Modal/FlowModal';
import { AdminFlowsAccessTab } from 'components/admin/flows/elements/Access';
import { LoadLayoutAdmin } from 'components/layouts/LoadLayoutAdmin';
import { UniversalLeftBarList } from 'modules/settingsContainer/UniversalLeftBarList';
import { SectionMiddleUI } from 'modules/ui/ManagerOrAdminComponentsUI/SectionMiddleUI';
import { useAppDispatch } from 'store';
import { getActiveFlow, getFlows } from 'store/reducers/adminFlows/getters';
import { closeModalAction, openConfirmationModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { useRole } from 'utils/hooks/useRole';
import { createFlow, updateFlow } from 'components/admin/flows/constants';
import { UsersIcon } from 'assets/icons/navigations';
import { useSelector } from 'react-redux';
import { SelectedUniversalLeftBar } from 'modules/settingsContainer/UniversalLeftBarList/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Tab } from 'modules/ui/tabs/types';
import styled from 'styled-components';

export const AdminFlows: FC = () => {
  const dispatch = useAppDispatch();
  const { isViewer } = useRole();

  const { loading, flowsList } = useSelector(getFlows);
  const activeFlow = useSelector(getActiveFlow);

  const activeFlowId = activeFlow?.id;
  const activeFlowName = activeFlow?.name;

  useEffect(() => {
    dispatch(loadFlowsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!activeFlowId && flowsList.length > 0) void onSelectedItem({ id: flowsList[0]?.id, title: flowsList[0]?.name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewer, flowsList]);

  const modelMetaDataList = flowsList?.map(({ id, name }) => ({
    items: [],
    id: id,
    title: name,
    type: name,
  }));

  const tabs: Tab[] = useMemo(
    () =>
      [
        {
          content: 'Доступы',
          prompt: 'Доступы',
          type: 'text',
          id: 'access',
          Component: AdminFlowsAccessTab,
        },
        {
          content: 'Проекты',
          prompt: 'Проекты',
          type: 'text',
          id: 'projects',
          Component: ProjectsTab,
        },
      ].filter(({ Component }) => Component) as Tab[],
    [],
  );

  const onSelectedItem = useCallback(
    async ({ id, title }: SelectedUniversalLeftBar) => {
      dispatch(changeActiveFlowAction({ id, name: title }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onCloseCreateFlowModal = () => dispatch(closeModalAction(createFlow));

  const onCreateFlowModal = useCallback(() => {
    dispatch(
      openModalTypedAction({
        Component: FlowModal,
        componentProps: {
          onClose: onCloseCreateFlowModal,
          mode: 'add',
        },
        modalSettings: {
          position: 'static',
          width: '320px',
          padding: '24px 16px',
          headerText: 'Создание потока',
        },
        name: createFlow,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCloseCreateFlowModal]);

  const onCloseUpdateFlowModal = () => dispatch(closeModalAction(updateFlow));

  const onUpdateFlowModal = useCallback(() => {
    dispatch(
      openModalTypedAction({
        Component: FlowModal,
        componentProps: {
          onClose: onCloseUpdateFlowModal,
          mode: 'edit',
        },
        modalSettings: {
          position: 'static',
          width: '320px',
          padding: '24px 16px',
          headerText: 'Редактирование потока',
        },
        name: updateFlow,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCloseUpdateFlowModal]);

  const onDelete = useCallback(
    async (flowId: string) => {
      try {
        const action = await dispatch(deleteFlowAction({ flowId })).unwrap();
        if (action) {
          await dispatch(deleteByIdFlowAction(flowId));
          await dispatch(changeActiveFlowAction({ id: flowsList[0]?.id, name: flowsList[0]?.name }));
        }
      } catch (e) {
        console.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowsList],
  );

  const onDeleteModal = useCallback(
    ({ id, title }: { id: string; title: string }) =>
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          subTitleText: `Действительно удалить поток «${title}»?`,
          onConfirm: () => onDelete(id),
          titleText: 'Удаление потока',
          width: '320px',
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  return (
    <LoadLayoutAdmin>
      <Body>
        <UniversalLeftBarList
          buttonTitle="Создать"
          disabledMobileVersion
          data={modelMetaDataList}
          activeTitle={activeFlowName}
          activeId={activeFlowId}
          loadingList={loading}
          disabledAdditionalField
          onSelectedItem={onSelectedItem}
          onDeleteItem={onDeleteModal}
          onCreateItem={onCreateFlowModal}
        />

        {activeFlowName && activeFlowId && (
          <SectionMiddleUI
            tabs={tabs}
            title={activeFlowName}
            id={activeFlowId}
            onEditModal={onUpdateFlowModal}
            onDeleteModal={onDeleteModal}
            isAccessControl
            HeaderIcon={UsersIcon}
            disabledMobileVersion
          />
        )}
      </Body>
    </LoadLayoutAdmin>
  );
};

const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background: ${`var(${ColorVarsEnum.Level_5_application})`};
  flex-wrap: nowrap;
`;
