import { Navigate } from 'react-router-dom';
import { RoutesURL } from 'constants/Routes';
import { useEffect } from 'react';
import { getRolesListEnum } from 'store/reducers/auth/actions';
import { useAppDispatch } from 'store';
import { useRole } from 'utils/hooks/useRole';

export const AdminRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isAdmin } = useRole();

  useEffect(() => {
    dispatch(getRolesListEnum());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAdmin) {
    return <Navigate to={RoutesURL.projectsList} replace />;
  }

  return children;
};
