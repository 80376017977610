import styled from '@emotion/styled';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const AvatarInput = styled.input`
  display: none;
`;

export const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const AvatarButton = styled.div`
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 2px;
  background: var(${ColorVarsEnum.Level_2_btn});
  color: var(${ColorVarsEnum.Level_1});
  transition: all 200ms;
  font-weight: 400;

  &:hover {
    background: var(${ColorVarsEnum.Level_1_hover_btn});
  }
`;
