export enum ColorVarsEnum {
  Level_1 = '--color-level-1',
  Level_2 = '--color-level-2',
  Level_3 = '--color-level-3',
  Level_4 = '--color-level-4',
  Level_5 = '--color-level-5',
  Accent = '--color-accent',
  Alert = '--color-alert',
  Level_1_hover_btn = '--color-l_1_hover_btn',
  Level_2_btn = '--color-l_2_btn',
  Level_3_menu = '--color-l_3_menu',
  Level_4_widget = '--color-l_4_widget',
  Level_5_application = '--color-l_5_application',
  Sub_Level_1 = '--color-sub-level-1',
  Sub_Level_2 = '--color-sub-level-2',
  Sub_Level_3 = '--color-sub-level-3',
  Sub_Level_bg_1 = '--color-sub-level-bg-1',
  Sub_Level_bg_2 = '--color-sub-level-bg-2',
  Sub_Level_bg_3 = '--color-sub-level-bg-3',
}
