import { FilterContainerWrapper } from 'modules/workspace/components/FilterContainer/styles';
import React from 'react';
import { FC, NoopType } from 'types/global';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { ClearIcon } from 'assets/icons/withContainer';

export interface FilterContainerProps {
  onDelete: NoopType;
}

export const FilterContainer: FC<FilterContainerProps> = ({ children, onDelete }) => {
  return (
    <FilterContainerWrapper>
      {children}
      <IconWrapper
        Icon={ClearIcon}
        iconHeight="14px"
        iconWidth="14px"
        containerWidth="20px"
        containerHeight="20px"
        onClick={onDelete}
      />
    </FilterContainerWrapper>
  );
};
