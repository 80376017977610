import { ModalInfo } from 'store/reducers/modals/types';
import { RollBackProjectModal } from 'components/projectsManager/Modals/RollBackProjectModal ';
import { CopyProjectModal } from 'components/projectsManager/Modals/CopyProjectModal';
import { MoveProjectModal } from 'components/projectsManager/Modals/MoveProjectModal';
import { InfoProjectModal } from 'components/projectsManager/Modals/InfoProjectModal';
import { ModalProps } from 'utils/hooks/useModalActions';

export const SHORT_KEY = 'sortProjectManagerSettings';

export const modalConfigs: Record<string, Omit<ModalInfo<ModalProps>, 'name' | 'componentProps'>> = {
  rollBackProject: {
    Component: RollBackProjectModal,
    modalSettings: {
      position: 'static',
      width: '380px',
      maxHeight: '500px',
      headerText: 'Откат проекта',
    },
  },
  copyingProject: {
    Component: CopyProjectModal,
    modalSettings: {
      position: 'static',
      width: '380px',
      maxHeight: '500px',
      headerText: 'Копирование проекта',
    },
  },
  moveProject: {
    Component: MoveProjectModal,
    modalSettings: {
      position: 'static',
      width: '380px',
      maxHeight: '500px',
      headerText: 'Перемещение проекта',
    },
  },
  infoProject: {
    Component: InfoProjectModal,
    modalSettings: {
      position: 'static',
      width: '320px',
      maxHeight: '500px',
      headerText: 'О проекте',
    },
  },
};
