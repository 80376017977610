import { useSelector } from 'react-redux';
import { getConfirmationModals } from 'store/reducers/modals/getters';
import { ModalUniversal } from 'modules/ui/ModalUniversal';
import React, { useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Button, TextField } from 'modules/ui';
import { useAppDispatch } from 'store';
import { closeConfirmationModalAction } from 'store/reducers/modals/actions';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';

export const ConfirmationModal = () => {
  const dispatch = useAppDispatch();

  const {
    isOpen,
    cancelButtonText,
    width,
    subTitleText,
    maxHeight,
    onCancel,
    confirmationButtonText,
    description,
    onConfirm,
    titleText,
    valueInput,
    renamableTitle = 'Название',
    isRenamable = false,
    disableModalPortal,
  } = useSelector(getConfirmationModals);
  const [renameValue, setRenameValue] = useState(valueInput);
  const [loading, setLoading] = useState(false);

  const closeTheModal = () => {
    dispatch(closeConfirmationModalAction());
  };

  const onConfirmClick = async () => {
    try {
      setLoading(true);

      if (isRenamable && renameValue) {
        await onConfirm(renameValue);
      } else {
        await onConfirm();
      }
      closeTheModal();
    } finally {
      setLoading(false);
    }
  };

  const onCancelClick = () => {
    onCancel && onCancel();
    closeTheModal();
  };

  return (
    <ModalUniversal
      headerText={titleText}
      onClose={closeTheModal}
      width={width || '500px'}
      maxHeight={maxHeight || '90vh'}
      height="auto"
      open={isOpen}
      disablePortal={disableModalPortal}
    >
      <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" padding="12px">
        <FlexContainer flexDirection="column" alignItems="center" width="100%" marginBottom="30px">
          {subTitleText && (
            <PrimaryTextSpan
              color={`var(${ColorVarsEnum.Level_1})`}
              fontSize="14px"
              fontWeight="bold"
              textAlign="center"
              marginBottom="8px"
              lineHeight="18px"
            >
              {subTitleText}
            </PrimaryTextSpan>
          )}
          {description && (
            <PrimaryTextSpan
              lineHeight="18px"
              color={`var(${ColorVarsEnum.Level_1})`}
              fontSize="14px"
              fontWeight="normal"
              textAlign="center"
            >
              {description}
            </PrimaryTextSpan>
          )}
          {isRenamable && (
            <TextField
              label={renamableTitle}
              onChange={(e) => setRenameValue(e.target.value)}
              value={valueInput}
              name="rename"
              width="100%"
            />
          )}
        </FlexContainer>
        <FlexContainer gap="16px" justifyContent="flex-end" width="100%">
          <Button text={cancelButtonText || 'Отмена'} needBackground={false} onClick={onCancelClick} />
          <FlexContainer position="relative" alignItems="center" justifyContent="center">
            <LoadingOverlay loading={loading} />
            <Button text={confirmationButtonText} needBackground={true} onClick={onConfirmClick} />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </ModalUniversal>
  );
};
