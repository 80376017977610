import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { cssVar, lighten } from 'polished';

import ColorTheme from 'constants/ColorTheme';
import { pathCircleMixin } from 'constants/styles';

export const IconWrapper = styled.div`
  cursor: pointer;
  height: 14px;
  width: 14px;

  svg {
    height: 14px;
    width: 14px;

    ${pathCircleMixin(`var(${ColorVarsEnum.Level_2})`)}
  }

  :hover {
    svg {
      path,
      circle {
        stroke: ${lighten(0.2, cssVar(ColorVarsEnum.Level_2, ColorTheme.default.level_2) as string)};
      }
    }
  }
`;
