import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { DateIcon } from 'assets/icons/editor';
import React, { useMemo } from 'react';
import { ModalUniversal } from 'modules/ui/ModalUniversal';
import { useModalState } from 'utils/hooks/modalState';
import { useLocalValues } from 'utils/hooks/localValues';
import Snackbar from 'services/Snackbar';
import { formatSql } from 'utils/SQL/formatSQL';
import { LocalValuesInterface, SqlFilterSettingsProps } from './types';
import { SqlField, SqlSection } from '../SqlSettings';
import { useFullSize } from 'utils/hooks/fullSize';
import { FilterSqlModal } from '../FilterSqlModal';
import { ErrorMessageInterface } from 'types/global';

export const SqlFilterSettings = ({ sqlData, modelMetaData, onSave }: SqlFilterSettingsProps) => {
  const { isOpen, onOpen, onClose } = useModalState();
  const { incisionRequest, filterAndGroupRequest } = sqlData;
  const { currentFullSize, onResizeChange } = useFullSize();

  const defaultRequests = useMemo(
    () => ({
      incisionRequest: incisionRequest,
      filterAndGroupRequest: filterAndGroupRequest,
    }),
    [incisionRequest, filterAndGroupRequest],
  );

  const formatSqlValues = () => {
    const keysToFormat: Array<keyof LocalValuesInterface> = ['incisionRequest', 'filterAndGroupRequest'];
    const formattedValues: LocalValuesInterface = {};

    Object.entries(localValues).forEach(([key, value]) => {
      if (keysToFormat.includes(key as keyof LocalValuesInterface) && value) {
        formattedValues[key as keyof LocalValuesInterface] = formatSql(value);
      }
    });

    return formattedValues;
  };

  const onFormatClick = () => {
    const { incisionRequest, filterAndGroupRequest } = formatSqlValues();

    setLocalValues({
      incisionRequest: incisionRequest || '',
      filterAndGroupRequest: filterAndGroupRequest || '',
    });
  };

  const onChange = ({ incisionRequest, filterAndGroupRequest }: typeof defaultRequests) => {
    try {
      onSave({ incisionRequest, filterAndGroupRequest });
    } catch (error) {
      const message = (error as ErrorMessageInterface)?.message || '';
      Snackbar.show(message, 'error');
    }
  };

  const {
    localValues,
    setLocalValues,
    onSave: onLocalSave,
    onCancel,
    hasChanges,
  } = useLocalValues({
    value: defaultRequests,
    onChange,
  });

  const onCloseEditor = () => {
    onClose();
    onCancel();
  };

  const onSaveEditor = () => {
    const { incisionRequest: localIncisionRequest, filterAndGroupRequest: localFilterRequest } = localValues;

    if (localIncisionRequest && localFilterRequest) {
      const formattedLocalIncisionRequest = formatSql(localIncisionRequest);
      const formattedLocalFilterRequest = formatSql(localFilterRequest);

      formattedLocalIncisionRequest &&
        formattedLocalFilterRequest &&
        onChange({ incisionRequest: formattedLocalIncisionRequest, filterAndGroupRequest: formattedLocalFilterRequest });
    }

    onLocalSave();
    onClose();
  };

  return (
    <>
      <MainContainerSettings titleText="SQL" ButtonIcon={DateIcon} onClickButtonIcon={onOpen} />
      <FilterSqlModal
        isOpen={isOpen}
        onCloseEditor={onCloseEditor}
        localValues={localValues}
        setLocalValues={setLocalValues}
        currentFullSize={currentFullSize}
        onResizeChange={onResizeChange}
        onFormatClick={onFormatClick}
        hasChanges={hasChanges}
        modelMetaData={modelMetaData}
        onSaveEditor={onSaveEditor}
      />
    </>
  );
};
