import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { PaddingsElementSettings } from 'modules/settingsContainer/common/PaddingsElementSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import React from 'react';
import { OnValueChange } from 'types/global';
import { ConnectedCellPaddingSettings } from 'types/store';

type InternalPaddingSettingsProps = OnValueChange<ConnectedCellPaddingSettings>;

export const InternalPaddingSettings = ({ value, onChange }: InternalPaddingSettingsProps) => {
  return (
    <MainContainerSettings titleText="Внутренние отступы">
      <GroupContainerSettings>
        <PaddingsElementSettings value={value} onChange={onChange} />
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
