import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useAppDispatch } from 'store';
import { addVisualisationAction } from 'store/reducers/visualisations/actions';
import { BoardMenuItem, BoardMenuItemProps } from 'modules/workspace/components/BoardMenuItem';
import { HeaderSettingsAreaLayout } from 'modules/settingsContainer/FieldSettingsArea';
import { filters, visualisations } from './constants';
import { VisualisationTypeType } from 'store/reducers/visualisations/types';
import { NoopType } from 'types/global';
import { IconContainer } from 'modules/workspace/components/leftBar/styles';
import { FilterType } from 'store/reducers/filters/types';
import { addFilterAction } from 'store/reducers/filters/actions';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { Diagram, Filter } from 'assets/icons/forDelete';

const CreateVisualisationModal: BoardMenuItemProps['ModalData'] = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const handleCreateVisualisation = (visualisationType: VisualisationTypeType, onClose: NoopType) => () => {
    dispatch(addVisualisationAction(visualisationType));
    onClose();
  };

  return (
    <HeaderSettingsAreaLayout headerText="Визуализации" onClose={onClose}>
      <FlexContainer padding="12px">
        <FlexContainer flexDirection="column" gap="12px">
          <PrimaryTextParagraph fontSize="12px" lineHeight="12px" color={`var(${ColorVarsEnum.Level_2})`} fontWeight={400}>
            Базовые
          </PrimaryTextParagraph>
          <FlexContainer gap="10px">
            {visualisations.map(({ visualisationType, Icon, title }) => (
              <StyledTooltip key={title} title={title} placement="top">
                <IconContainer key={visualisationType} onClick={handleCreateVisualisation(visualisationType, onClose)}>
                  <Icon />
                </IconContainer>
              </StyledTooltip>
            ))}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </HeaderSettingsAreaLayout>
  );
};

const CreateFilterModal: BoardMenuItemProps['ModalData'] = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const handleCreateFilter = (type: FilterType, onClose: NoopType) => () => {
    dispatch(addFilterAction(type));
    onClose();
  };

  return (
    <HeaderSettingsAreaLayout headerText="Фильтры" onClose={onClose}>
      <FlexContainer margin="10px" gap="10px">
        {filters.map(({ type, Icon, title }) => (
          <StyledTooltip key={title} title={title} placement="top">
            <IconContainer onClick={handleCreateFilter(type, onClose)}>
              <Icon />
            </IconContainer>
          </StyledTooltip>
        ))}
      </FlexContainer>
    </HeaderSettingsAreaLayout>
  );
};

export const LeftBar: FC = () => (
  <FlexContainer
    flexDirection="column"
    maxWidth="60px"
    backgroundColor={`var(${ColorVarsEnum.Level_5_application})`}
    zIndex="2"
    alignItems="center"
    flex="1"
  >
    <BoardMenuItem Icon={Diagram} ModalData={CreateVisualisationModal} />
    <BoardMenuItem Icon={Filter} ModalData={CreateFilterModal} />
  </FlexContainer>
);
