import { createSelector } from 'reselect';
import { getState } from 'store/utils';

export const getVisualSettingsStore = createSelector(getState, (state) => state.visualSettings);

export const getVisualSettings = createSelector(getVisualSettingsStore, (visualSettingsStore) => {
  const { SettingComponent } = visualSettingsStore;

  return { SettingComponent };
});
