import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import { loadVersionStrategy, updateVersionStrategy } from 'store/reducers/adminSettings/api';
import { AdminSettingsActionsTypes } from 'store/reducers/adminSettings/constants';
import { setVersionStrategy } from 'store/reducers/adminSettings/index';
import { VersionStrategy } from 'store/reducers/adminSettings/types';
import Snackbar from 'services/Snackbar';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');

  return rejectWithValue(errorMessage);
};

export const loadVersionStrategyAction = createAsyncThunk<VersionStrategy, void, { rejectValue: null }>(
  AdminSettingsActionsTypes.LOAD_VERSION_STRATEGY,
  async (_, { rejectWithValue }) => {
    try {
      const response = await loadVersionStrategy();
      const strategy = response.data.settingDeleteOldIncoming;

      return {
        type: strategy.type,
        ...(strategy.type === 'time' && { ageVersion: strategy.ageVersion }),
        ...(strategy.type === 'items' && { count: strategy.count }),
      };
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return {} as VersionStrategy;
    }
  },
);

export const updateVersionStrategyAction = createAsyncThunk<VersionStrategy, VersionStrategy>(
  AdminSettingsActionsTypes.UPDATE_VERSION_STRATEGY,
  async (props, { rejectWithValue }) => {
    try {
      const updateParams = {
        type: props.type,
        ageVersion: props.ageVersion || 0,
        count: props.count || 0,
      };

      const response = await updateVersionStrategy(updateParams);
      const strategy = response.data.settingDeleteOldIncoming;
      Snackbar.show('Изменение успешно сохранены', 'success');

      return {
        type: strategy.type,
        ...(strategy.type === 'time' && { ageVersion: strategy.ageVersion }),
        ...(strategy.type === 'items' && { count: strategy.count }),
      };
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const setVersionStrategyAction = createAsyncThunk<void, { strategy: VersionStrategy }>(
  AdminSettingsActionsTypes.SET_VERSION_STRATEGY,
  ({ strategy }, { dispatch }) => {
    dispatch(setVersionStrategy(strategy));
  },
);
