import { useSelector } from 'react-redux';
import { getAllSQLRequestsByIdForExport, getSqlRequestById } from 'store/reducers/visualisations/getters';
import { getSqlFilterStringById } from 'store/reducers/filters/getters';

export const useSqlRequest = () => {
  const getSqlRequest = useSelector(getSqlRequestById);
  const getSqlFilter = useSelector(getSqlFilterStringById);
  const getAllSQLForExport = useSelector(getAllSQLRequestsByIdForExport);

  return {
    getSqlRequest,
    getSqlFilter,
    getAllSQLForExport,
  };
};
