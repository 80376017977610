import React from 'react';
import { InfoProjectComponent } from 'components/admin/flows/elements/Projects/InfoProject';
import { Button } from 'modules/ui';
import { FlexContainer } from 'styles/FlexContainer';
import { ModalComponentType } from 'store/reducers/modals/types';
import { NoopValueType } from 'types/global';
import { AvatarProjectInterface } from 'types/types';

interface InfoFlowProjectModalProps {
  projectId: string;
  onChangeAvatar?: NoopValueType<AvatarProjectInterface>;
}

export const InfoFlowProjectModal: ModalComponentType<InfoFlowProjectModalProps> = ({ onClose, projectId, onChangeAvatar }) => {
  return (
    <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" padding="12px">
      <InfoProjectComponent padding="0" activeProjectId={projectId} onChangeAvatar={onChangeAvatar} />

      <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
        <Button text="Закрыть" iconSize="normal" heightSize="normal" onClick={onClose} />
      </FlexContainer>
    </FlexContainer>
  );
};
