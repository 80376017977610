export type TaskRadioVariantsType = 'hour' | 'day' | 'week' | 'month' | 'cron';

export type ScheduleType = {
  minutes: number;
  hours: number;
  days: number | number[];
  schedule?: string;
};

export enum CronTypeEnum {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  CRON = 'cron',
}
