import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import { bottomLineStyleMixin } from 'constants/styles';

export const StyledMenuItem = styled(MenuItem)`
  min-width: 177px;
`;

export const BottomLine = styled.div`
  ${bottomLineStyleMixin};
`;
