import { FilterElementModeEnum } from 'types/store';
import { Option } from 'components/shared/ui/menu/MenuIconList/types';
import { SortingValueEnum } from 'components/shared/SortingPanel/types';

export const filterElementModeValues = [
  { label: 'Вручную', value: FilterElementModeEnum.MANUAL },
  { label: 'Динамически', value: FilterElementModeEnum.DYNAMICALLY },
];

export const sortingOptions: Option<string>[] = [
  { name: 'По возрастанию', value: SortingValueEnum.ASC },
  { name: 'По убыванию', value: SortingValueEnum.DESC },
  { name: 'Вручную', value: SortingValueEnum.MANUALLY },
];
