import { Box, Fade } from '@mui/material';
import { ApplyIcon } from 'assets/icons/FilterInfluenceIcons/Apply';
import { DenyIcon } from 'assets/icons/FilterInfluenceIcons/Deny';
import ColorTheme from 'constants/ColorTheme';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useEffect, useState } from 'react';
import { MapRecordType } from 'types/global';
import { handleInfluenceStatus } from 'utils/utils';

interface FilterInfluencePopupProps {
  isActive: boolean;
  id: string;
  isInfluenceEditing: boolean;
  handleFilterInfluence: (payload: boolean) => () => void;
  filterInfluences: MapRecordType<boolean> | null;
}

export const FilterInfluencePopup = ({
  isActive,
  id,
  isInfluenceEditing,
  handleFilterInfluence,
  filterInfluences,
}: FilterInfluencePopupProps) => {
  const [currentInfluenceStatus, setCurrentInfluenceStatus] = useState<boolean | undefined>(true);

  useEffect(() => {
    const status = handleInfluenceStatus(filterInfluences, id);
    setCurrentInfluenceStatus(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInfluences]);

  return (
    <Fade in={!!isInfluenceEditing && !isActive} timeout={300}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          position: 'absolute',
          marginTop: '-16px',
          left: '50%',
          transform: 'translate(-50%, 0%)',
          border: `1px solid ${ColorTheme.default.accent}`,
          backgroundColor: `var(${ColorVarsEnum.Level_5_application})`,
          borderRadius: '20px',
          padding: '4px 8px',
          zIndex: 100,
        }}
      >
        <ApplyIcon isActive={currentInfluenceStatus} onClick={handleFilterInfluence(true)} />
        <DenyIcon isActive={!currentInfluenceStatus} onClick={handleFilterInfluence(false)} />
      </Box>
    </Fade>
  );
};
