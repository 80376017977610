import React from 'react';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import {
  defaultSqlDataSettings,
  defaultTextBackgroundImagesSettings,
  defaultTextDataSettings,
  defaultTextViewSettings,
} from 'store/reducers/visualisations/constants';
import { SqlSettings } from 'modules/settingsContainer/common/data/SqlSettings';
import { SettingsFieldEntry } from 'modules/settingsContainer/SettingsFieldEntry';
import { MetricsRenderType } from 'modules/settingsContainer/common/data/MetricSettings/types';
import { DefaultIncisionInterface } from 'store/reducers/visualisations/types';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import { MetricSettings } from 'modules/settingsContainer/common/data/MetricSettings';
import { getIncisionAndIndicatorsCompletions } from 'utils/sqlSettings';
import { FictionalMetricField } from 'modules/settingsContainer/common/FictionalMetricField';
import {
  onAddNewIncision,
  onChangeFictionalData,
  onChangeIncisionCustomRequest,
  onChangeIncisionFieldName,
  onChangeIncisionName,
  onDeleteIncision,
  onSqlSettingsSave,
} from 'modules/visualisations/common/onChangeFunctions';
import { TypeOfImageSettings } from 'modules/settingsContainer/common/data/TypeOfImageSettings';
import {
  onChangeBackgroundImage,
  onChangeBackgroundTypeLink,
  onChangeCarouselSettings,
  onChangeProportionsType,
} from 'modules/visualisations/Text/settings/BackgroundTab/constants';
import { TextBackgroundImagesSettings } from 'modules/settingsContainer/common/view/TextBackgroundImagesSettings';
import { ProportionsImageSettings } from 'modules/settingsContainer/common/ProportionsImageSettings';
import { CarouselSettings } from 'modules/settingsContainer/common/data/CarouselSettings';

export const BackgroundTab = () => {
  const { codeEditorData, backgroundImagesSettings, dataSettings, astOfVisualisation } = useGetActiveVisualisationSettings({
    defaultData: defaultTextDataSettings,
    defaultView: defaultTextViewSettings,
    defaultBackgroundImages: defaultTextBackgroundImagesSettings,
    defaultSqlData: defaultSqlDataSettings,
  });

  const { controls, onMetricClick, tableFields, onSetupClick, setupIsOpen, metricIsSelected, modelMetaData } =
    useDataSettingsMetric(dataSettings.modelId);

  const { imagesLinkType, proportionsImageType, backgroundImages, carouselAutoPlay } = backgroundImagesSettings;

  const imagesRender: MetricsRenderType<DefaultIncisionInterface> = ({ metrics }) => (
    <>
      {metrics.map(({ name, id, fieldName, fictionalData, settings: { customRequest } }) => (
        <SettingsFieldEntry
          disableChangePriory
          disabledViewColorPicker
          disabledViewSetup
          setupIsOpen={setupIsOpen(id)}
          onSetupClick={onSetupClick(id)}
          onClick={onMetricClick(id)}
          isSelected={metricIsSelected(id)}
          fieldValue={name}
          onFieldChange={(name) => onChangeIncisionName(dataSettings, name, id)}
          key={id}
        >
          <FictionalMetricField
            onCustomRequestChange={(customRequest) => onChangeIncisionCustomRequest(dataSettings, customRequest, id)}
            customRequest={customRequest || ''}
            isRealData={true}
            options={tableFields}
            disabled={!!customRequest}
            value={{ fictionalData, fieldName }}
            onChange={({ fictionalData, fieldName }) => {
              fieldName && onChangeIncisionFieldName(dataSettings, fieldName || '', id);
              fictionalData && onChangeFictionalData(dataSettings, fictionalData, id);
            }}
            modelMetaData={modelMetaData}
          />
        </SettingsFieldEntry>
      ))}
    </>
  );

  return (
    <>
      <TypeOfImageSettings value={imagesLinkType} onChange={(imagesLinkType) => onChangeBackgroundTypeLink(imagesLinkType)} />
      {backgroundImagesSettings.imagesLinkType === 'fromDatabase' && (
        <>
          <MetricSettings
            titleText="Изображения"
            addButtonText="Добавить изображения"
            metricRender={imagesRender}
            metrics={dataSettings.incisions}
            onAdd={() => onAddNewIncision(dataSettings, 'text')}
            onDelete={(id) => id && onDeleteIncision(dataSettings, id)}
            controls={controls}
          />
          <SqlSettings
            astData={astOfVisualisation}
            sqlData={codeEditorData}
            serviceTitle="Изображении"
            adviceEditorIncision={getIncisionAndIndicatorsCompletions(dataSettings.incisions)}
            onSave={(sqlSettingsChanges) => onSqlSettingsSave(dataSettings, sqlSettingsChanges, 'text')}
            modelMetaData={modelMetaData}
          />
        </>
      )}
      {backgroundImagesSettings.imagesLinkType === 'link' && (
        <TextBackgroundImagesSettings value={backgroundImages} onChange={onChangeBackgroundImage} />
      )}

      <ProportionsImageSettings
        value={proportionsImageType}
        onChange={(proportionsImageType) => onChangeProportionsType(proportionsImageType)}
      />
      <CarouselSettings value={carouselAutoPlay} onChange={(carouselSettings) => onChangeCarouselSettings(carouselSettings)} />
    </>
  );
};
