import { FlexContainer } from 'styles/FlexContainer';
import { memo, useMemo, useState } from 'react';
import { useFilterQuery } from 'modules/filters/hooks/filter';
import { SearchInput } from 'modules/ui/inputs/SearchInput';
import { ActiveObjectFilters } from 'store/reducers/visualisations/types';
import Button from 'modules/ui/Button';
import { SingleFilterInterface } from 'store/reducers/filters/types';
import { FilterValueItem } from 'modules/filters/Multiple/view/Filter/styles';

interface SingleFilterProps {
  data: SingleFilterInterface;
  elementId: string;
  selectedValues: string;
  whereQuery: string;
  onUpdateFilter: (elementId: string, payload: Partial<ActiveObjectFilters>) => void;
}

export const SingleFilter = ({ data, elementId, selectedValues, whereQuery, onUpdateFilter }: SingleFilterProps) => {
  const { selectedData, isRealData, fictionalData, id, nameSettings, modelId, sqlData } = data;
  const [searchString, setSearchString] = useState('');

  const { filterValues } = useFilterQuery({
    nameSettings,
    id,
    modelId,
    searchString,
    whereQuery,
    sqlData,
    isFetchRequiredWhenDataExists: true,
  });

  const selectValue = selectedValues && selectedValues[0];

  const [activeValue, setActiveValue] = useState<null | string>(selectValue || null);

  const filterOptions = useMemo(() => {
    const data: string[] = isRealData ? selectedData : fictionalData;

    if (searchString) {
      return filterValues.map(({ value }) => value);
    }

    return data?.reduce<string[]>((result, value) => {
      if (value) {
        return [...result, value];
      }

      return result;
    }, []);
  }, [selectedData, isRealData, fictionalData, filterValues, searchString]);

  const onResetFilterValues = () => {
    setActiveValue(null);
    onUpdateFilter(elementId, { selectedValues: [] });
  };

  const handleChange = (value: string) => () => {
    setActiveValue(value);
    onUpdateFilter(elementId, { selectedValues: [value] });
  };

  return (
    <FlexContainer display="flex" flexDirection="column" marginTop="8px" gap="4px" width="100%">
      <FlexContainer width="100%">
        <SearchInput
          useDebounce
          name="filter"
          needBackground={false}
          type="text"
          width="100%"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
      </FlexContainer>
      <FlexContainer flexDirection="column" maxHeight="320px" overflowY="auto">
        {filterOptions.map((value) => (
          <FilterValueItem key={value} selected={value === activeValue} onClick={handleChange(value)}>
            {value}
          </FilterValueItem>
        ))}
      </FlexContainer>
      <FlexContainer maxWidth="70px">
        <Button text="Сбросить" width="auto" heightSize="small" onClick={onResetFilterValues} />
      </FlexContainer>
    </FlexContainer>
  );
};

export const SingleFilterActivation = memo(SingleFilter);
