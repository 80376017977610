import { ReactChildrenType } from 'types/global';
import { HeaderContent, HeaderLayoutWrapper } from 'components/layouts/HeaderLayout/styles';

export interface HeaderLayoutProps {
  LeftSide?: ReactChildrenType;
  MiddleSide?: ReactChildrenType;
  RightSide?: ReactChildrenType;
}

export const HeaderLayout = ({ LeftSide, MiddleSide, RightSide }: HeaderLayoutProps) => {
  return (
    <HeaderLayoutWrapper>
      <HeaderContent>{LeftSide}</HeaderContent>
      <HeaderContent>{MiddleSide}</HeaderContent>
      <HeaderContent>{RightSide}</HeaderContent>
    </HeaderLayoutWrapper>
  );
};
