import { AddIcon, DeleteIcon } from 'assets/icons/withContainer';
import { ElementContainerSettings } from 'modules/settingsContainer';
import { ApplyToAllPagesElementSettings } from 'modules/settingsContainer/common/ApplyToAllPagesSettings';
import { BooleanElementSettings } from 'modules/settingsContainer/common/BooleanElementSettings';
import {
  addButtonMarginLeft,
  deleteButtonMarginRight,
  openWidowTypeOptions,
} from 'modules/settingsContainer/common/EventsSettings/constants';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { Button, TextArea } from 'modules/ui';
import Select, { ISelectItem } from 'modules/ui/Select';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { toggleInfluenceFiltersMode } from 'store/reducers/board';
import { getActiveBoardElement, getInfluenceFiltersMode } from 'store/reducers/board/getters';
import { getFilterByProject, getFilters } from 'store/reducers/filters/getters';
import { getPagesAtSelectItems } from 'store/reducers/projectPages/getters';
import { updateEventSettingsAction } from 'store/reducers/visualisations/actions';
import { getVisualisations } from 'store/reducers/visualisations/getters';
import { ActivateObjectInterface, EventsSettingsInterface } from 'store/reducers/visualisations/types';
import { FlexContainer } from 'styles/FlexContainer';
import { OnValueChange } from 'types/global';
import { applyToAllInfluences } from 'utils/utils';
import { FilterActivation } from '../FilterActivation';
import { FilterInfluenceElementSettings } from '../FilterInfluenceSettings';
import SubSection from 'shared/ui/subSection';

interface ObjectToSelectorProps {
  value?: string;
  options: ISelectItem[];
  onChange: (value: string) => void;
  onDelete: () => void;
}

const ObjectToSelector = ({ value, options, onChange, onDelete }: ObjectToSelectorProps) => {
  return (
    <FlexContainer width="100%">
      <FlexContainer marginRight={deleteButtonMarginRight}>
        <Button needBackground={false} leftIcon={<DeleteIcon />} onClick={onDelete} />
      </FlexContainer>
      <Select
        needBackground
        placeholder="Выбрать объект"
        name="goToObject"
        options={options}
        width="100%"
        value={value}
        onChange={onChange}
      />
    </FlexContainer>
  );
};

type EventsSettingsProps = OnValueChange<EventsSettingsInterface>;

export const EventsSettings = ({ value: events, onChange }: EventsSettingsProps) => {
  const dispatch = useAppDispatch();
  const influenceFiltersMode = useSelector(getInfluenceFiltersMode);
  const visualisations = useSelector(getVisualisations);
  const filters = useSelector(getFilters);
  const activeElementId = useSelector(getActiveBoardElement);
  const projectFilters = useSelector(getFilterByProject);

  const { activateTo, activateObject, goTo, goToHref, filterSettings, isReactingToFilter } = events,
    { applyToAllPages, isFiltering, moreThanOne, isInfluenceItself, filterInfluences, responseToExternalDrillDown } =
      filterSettings,
    { isActive } = activateObject,
    activateToValues = activateTo.to;

  const pages = useSelector(getPagesAtSelectItems);
  const [isActiveNewObjectTo, setIsActiveNewObjectTo] = useState(false);

  const onActivateToAdd = (id: string) =>
    onChange({
      ...events,
      activateTo: { ...activateTo, to: [...(activateToValues || []), { type: 'page', id }] },
    });

  const onActivateToChange = (id: string, index: number) => {
    if (activateToValues?.length) {
      const newActiveToArray = [...activateToValues];

      newActiveToArray[index] = { id, type: 'page' };

      onChange({
        ...events,
        activateTo: { ...activateTo, to: newActiveToArray },
      });
    }
  };

  const onActivateToDelete = (index: number) => {
    if (activateToValues?.length) {
      onChange({
        ...events,
        activateTo: { ...activateTo, to: activateToValues.filter((_, indexTo) => indexTo !== index) },
      });
    }
  };

  const onApplyToAllFilterInfluences = (payload: boolean) => () => {
    if (!influenceFiltersMode) {
      toggleInfluenceFilterMode();
    }

    if (activeElementId) {
      const influenceData = applyToAllInfluences({ ...visualisations, ...filters }, payload, activeElementId);

      dispatch(
        updateEventSettingsAction({
          ...events,
          filterSettings: { ...filterSettings, filterInfluences: { ...filterInfluences, ...influenceData } },
        }),
      );
    }
  };

  const onActivateObjectChange = (payload: ActivateObjectInterface) => {
    onChange({ ...events, activateObject: payload });
  };

  const toggleActivateObjectStatus = () => {
    onChange({
      ...events,
      activateObject: {
        ...activateObject,
        isActive: !isActive,
      },
    });
  };

  const toggleInfluenceFilterMode = () => {
    dispatch(toggleInfluenceFiltersMode());
  };

  return (
    <>
      <MainContainerSettings
        titleText="Переход к объекту"
        switcherState={goTo.isActive}
        switcherChange={() => onChange({ ...events, goTo: { ...goTo, isActive: !goTo.isActive } })}
      >
        <Select
          needBackground
          placeholder="Выбрать объект"
          name="goToObject"
          options={pages}
          width="100%"
          value={goTo.to?.id || pages[0]}
          onChange={(id) => onChange({ ...events, goTo: { ...goTo, to: { type: 'page', id } } })}
        />
      </MainContainerSettings>
      <MainContainerSettings
        titleText="Переход по гиперссылке"
        switcherState={goToHref.isActive}
        switcherChange={() => onChange({ ...events, goToHref: { ...goToHref, isActive: !goToHref.isActive } })}
      >
        <GroupContainerSettings>
          <ElementContainerSettings>
            <SettingsRadio
              onChange={({ value: openWindowType }) => onChange({ ...events, goToHref: { ...goToHref, openWindowType } })}
              activeValue={getActiveRadioValue(openWidowTypeOptions, goToHref.openWindowType)}
              options={openWidowTypeOptions}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
        <GroupContainerSettings>
          <ElementContainerSettings>
            <TextArea
              name="href_to"
              needBackground={true}
              value={goToHref.to || ''}
              onChange={(e) => onChange({ ...events, goToHref: { ...goToHref, to: e.target.value } })}
              width="100%"
              placeholder="https://"
              rows={3}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      </MainContainerSettings>
      <MainContainerSettings
        titleText="Активация объекта"
        switcherState={activateTo.isActive}
        switcherChange={() => onChange({ ...events, activateTo: { ...activateTo, isActive: !activateTo.isActive } })}
      >
        <FlexContainer gap="10px" width="100%" flexDirection="column">
          {activateToValues?.map(({ id }, index) => (
            <ObjectToSelector
              key={`${id}_${index}`}
              options={pages}
              value={id}
              onDelete={() => onActivateToDelete(index)}
              onChange={(id) => onActivateToChange(id, index)}
            />
          ))}
          {isActiveNewObjectTo && (
            <ObjectToSelector
              options={pages}
              onDelete={() => setIsActiveNewObjectTo(false)}
              onChange={(id) => {
                onActivateToAdd(id);
                setIsActiveNewObjectTo(false);
              }}
            />
          )}
          <FlexContainer padding={`0 0 0 ${addButtonMarginLeft}`}>
            <Button
              disabled={isActiveNewObjectTo}
              text="Добавить объект"
              leftIcon={<AddIcon />}
              onClick={() => setIsActiveNewObjectTo(true)}
            />
          </FlexContainer>
        </FlexContainer>
      </MainContainerSettings>
      <MainContainerSettings
        titleText="Реагировать на фильтры"
        switcherState={isReactingToFilter}
        switcherChange={() => onChange({ ...events, isReactingToFilter: !isReactingToFilter })}
      />
      <MainContainerSettings titleText="Работать как фильтр">
        <SubSection>
          <GroupContainerSettings>
            <BooleanElementSettings
              titleText="Инициировать фильтрацию"
              value={isFiltering}
              onChange={(isFiltering) => onChange({ ...events, filterSettings: { ...filterSettings, isFiltering } })}
            />

            <ApplyToAllPagesElementSettings
              value={applyToAllPages}
              onChange={(applyToAllPages) => onChange({ ...events, filterSettings: { ...filterSettings, applyToAllPages } })}
            />

            <BooleanElementSettings
              titleText="Добавлять несколько фильтров"
              value={moreThanOne}
              onChange={(moreThanOne) => onChange({ ...events, filterSettings: { ...filterSettings, moreThanOne } })}
            />

            <BooleanElementSettings
              titleText="Реагировать на собственные фильтры"
              value={isInfluenceItself}
              onChange={(isInfluenceItself) =>
                onChange({
                  ...events,
                  filterSettings: { ...filterSettings, isInfluenceItself },
                })
              }
            />

            <BooleanElementSettings
              titleText="Реагировать на drilldown в других виджетах"
              value={responseToExternalDrillDown}
              onChange={(responseToExternalDrillDown) =>
                onChange({
                  ...events,
                  filterSettings: { ...filterSettings, responseToExternalDrillDown },
                })
              }
            />
          </GroupContainerSettings>
        </SubSection>
        <SubSection>
          <GroupContainerSettings>
            <FilterInfluenceElementSettings
              onToggleInfluenceMode={toggleInfluenceFilterMode}
              influenceModeValue={influenceFiltersMode}
              onApplyToAllFilterInfluences={onApplyToAllFilterInfluences}
            />
          </GroupContainerSettings>
        </SubSection>
      </MainContainerSettings>
      <MainContainerSettings
        titleText="Активировать фильтры"
        switcherState={isActive}
        switcherChange={toggleActivateObjectStatus}
      >
        <FilterActivation data={projectFilters} onChange={onActivateObjectChange} />
      </MainContainerSettings>
    </>
  );
};
