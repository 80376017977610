import { FC, OnValueChange } from 'types/global';
import React, { useMemo, useRef } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button } from 'modules/ui';
import { DefaultDataSettingsInterface, GradientColorByInterface } from 'store/reducers/visualisations/types';
import { WrapperBySettings, WrapperByTabsType } from 'modules/settingsContainer/common/WrapperBySettings';
import { ConditionEditorField } from 'modules/settingsContainer/common/ConditionEditorField';
import { FixedAreaSettings } from 'modules/settingsContainer/common/FixedAreaSettings';
import { useModalState } from 'utils/hooks/modalState';
import { ColorByValue } from '../ColorByValue';
import { getHeatmapConditionOptions } from './constants';
import { ColorByCondition } from '../ColorByCondition';

export interface GradientColorBySettingsProps
  extends Partial<Pick<DefaultDataSettingsInterface, 'indicators'>>,
    OnValueChange<GradientColorByInterface> {
  isMainContainerSettings?: boolean;
  disabledComponentByCondition?: boolean;
  title?: string;
  titleMainContainerText?: string;
}

export const GradientColorBySettings: FC<GradientColorBySettingsProps> = ({
  disabledComponentByCondition,
  value,
  onChange,
  children,
  indicators,
  isMainContainerSettings = false,
  title,
  titleMainContainerText,
}) => {
  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  const { isOpen, onOpen, onClose } = useModalState();

  const usingByCondition = useMemo(() => !disabledComponentByCondition, [disabledComponentByCondition]);
  const conditionOptions = useMemo(() => getHeatmapConditionOptions(usingByCondition), [usingByCondition]);

  const colorByTabs: WrapperByTabsType = {
    condition: {
      Activator: () => <ConditionEditorField value={value.byCondition.sqlCondition || ''} onOpenChange={onOpen} />,
      Settings: () => (
        <FixedAreaSettings
          onClose={onClose}
          open={isOpen}
          headerText={settingsText || ''}
          width={width}
          minWidth={minWidth}
          anchorEl={mainContainerRef.current}
        >
          <ColorByCondition metric={value.byCondition} onChange={(byCondition) => onChange({ ...value, byCondition })} />
        </FixedAreaSettings>
      ),
      settingsText: 'Изменить условие',
      width: '565px',
    },
    valueGradient: {
      Activator: () => (
        <FlexContainer width="100%" alignItems="center" justifyContent="center">
          <Button text="Настроить" onClick={onOpen} />
        </FlexContainer>
      ),
      Settings: () => (
        <FixedAreaSettings
          onClose={onClose}
          open={isOpen}
          headerText={settingsText || ''}
          width={width}
          minWidth={minWidth}
          anchorEl={mainContainerRef.current}
        >
          <ColorByValue
            metric={value.byValueGradient}
            onChange={(byValueGradient) => onChange({ ...value, byValueGradient })}
            indicators={indicators}
          />
        </FixedAreaSettings>
      ),
      settingsText: 'Изменить диапазон',
      minWidth: '180px',
    },
    valueSteps: {
      Activator: () => (
        <FlexContainer width="100%" alignItems="center" justifyContent="center">
          <Button text="Настроить" onClick={onOpen} />
        </FlexContainer>
      ),
      Settings: () => (
        <FixedAreaSettings
          onClose={onClose}
          open={isOpen}
          headerText={settingsText || ''}
          width={width}
          minWidth={minWidth}
          anchorEl={mainContainerRef.current}
        >
          <ColorByValue
            metric={value.byValueSteps}
            onChange={(byValueSteps) => onChange({ ...value, byValueSteps })}
            indicators={indicators}
          />
        </FixedAreaSettings>
      ),
      settingsText: 'Изменить диапазон',
      minWidth: '180px',
    },
    default: {
      Activator: () => <>{children}</>,
    },
  };

  const { width, minWidth, settingsText } = colorByTabs[value.type];

  return (
    <WrapperBySettings
      mainContainerRef={mainContainerRef}
      colorByTabs={colorByTabs}
      conditionOptions={conditionOptions}
      value={value}
      isMainContainerSettings={isMainContainerSettings}
      title={title}
      titleMainContainerText={titleMainContainerText}
      onChange={onChange}
    />
  );
};
