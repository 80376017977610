import styled from 'styled-components';
import { FlexContainer } from 'styles/FlexContainer';

export const IconsBlock = styled(FlexContainer)`
  display: none;
`;

export const HeaderTitleWrapper = styled(FlexContainer)`
  :hover {
    ${IconsBlock} {
      display: flex;
      flex-direction: row;
    }
  }
`;
