import { FC, NoopType, OnValueChange } from 'types/global';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer';
import React, { useEffect, useRef } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { DeleteIcon } from 'assets/icons/withContainer';
import { Button, SqlAutocomplete } from 'modules/ui';
import { DefaultDataSettingsInterface, ImageByInterface, ImageValues } from 'store/reducers/visualisations/types';
import { SaveCancelButtons } from 'modules/ui/blocks/SaveCancelButtons';
import { useLocalValues } from 'utils/hooks/localValues';
import { colorNameTitle } from 'constants/global';
import { WrapperBySettings, WrapperByTabsType } from 'modules/settingsContainer/common/WrapperBySettings';
import { TRenderByConditionType, TRenderByValueType } from 'modules/settingsContainer/common/WrapperBySettings/types';
import { ImagePicker, ImagePickerProps } from 'modules/settingsContainer/ImagePicker';
import { useModalState } from 'utils/hooks/modalState';
import { getConditionOptions } from 'modules/settingsContainer/common/ImageBySettings/constants';
import { ConditionEditorField } from 'modules/settingsContainer/common/ConditionEditorField';
import { FixedAreaSettings } from 'modules/settingsContainer/common/FixedAreaSettings';
import { ImageDeleteButtonWrapper, ImagePickerItemWrapper } from 'modules/settingsContainer/common/ImageBySettings/styles';
import { AddColorButton } from 'modules/ui/buttons/AddColorButton';

interface ImageByPickerProps extends Pick<ImagePickerProps, 'value' | 'onChange'> {
  name?: string | number;
  onDelete: NoopType;
}

const ImageByPicker = ({ value, onChange, name, onDelete }: ImageByPickerProps) => (
  <ImagePickerItemWrapper>
    <ImagePicker onChange={onChange} closeOnClick value={value} />
    {name && (
      <PrimaryTextSpan lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
        {name}
      </PrimaryTextSpan>
    )}
    <ImageDeleteButtonWrapper onClick={onDelete}>
      <IconWrapper
        hoverColorVar={ColorVarsEnum.Level_3}
        colorVar={ColorVarsEnum.Level_3}
        iconWidth="15px"
        iconHeight="13px"
        Icon={DeleteIcon}
      />
    </ImageDeleteButtonWrapper>
  </ImagePickerItemWrapper>
);

const ImagesByConditionRender: TRenderByConditionType<ImageByInterface> = ({ metric: imagesByCondition, onChange }) => {
  const { localValues, setLocalValues, onSave, onCancel, hasChanges } = useLocalValues({
    value: imagesByCondition,
    onChange: onChange,
  });

  const {
    byCondition: { images, sqlCondition },
  } = localValues;

  useEffect(() => {
    setLocalValues(imagesByCondition);
  }, [imagesByCondition, setLocalValues]);

  const onAddImage = () =>
    setLocalValues((localValues) => ({
      ...localValues,
      byCondition: {
        ...localValues.byCondition,
        images: [
          ...localValues.byCondition.images,
          {
            value: null,
          },
        ],
      },
    }));

  const onDeleteColor = (deletedIndex: number) => () =>
    setLocalValues((localValues) => ({
      ...localValues,
      byCondition: {
        ...localValues.byCondition,
        images: images.filter((_, index) => index !== deletedIndex),
      },
    }));

  const onChangeImage = (imageIndex: number) => (imageValue: ImageValues | null) => {
    setLocalValues((localValues) => ({
      ...localValues,
      byCondition: {
        ...localValues.byCondition,
        images: images.map((color, index) => (index === imageIndex ? { ...color, value: imageValue } : color)),
      },
    }));
  };

  const onSqlChange = (sql: string) =>
    setLocalValues((localValues) => ({
      ...localValues,
      byCondition: {
        ...localValues.byCondition,
        sqlCondition: sql || null,
      },
    }));

  return (
    <>
      <GroupContainerSettings>
        <ElementContainerSettings>
          <FlexContainer padding="24px 8px" gap="20px" width="100%" flexWrap="wrap">
            {images?.map(({ value }, index) => (
              <ImageByPicker
                value={value}
                onChange={onChangeImage(index)}
                name={index + 1}
                onDelete={onDeleteColor(index)}
                key={index}
              />
            ))}
            <AddColorButton onAdd={onAddImage} />
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
      <GroupContainerSettings>
        <ElementContainerSettings>
          <FlexContainer padding="0 10px 10px 2px" flexDirection="column" width="100%">
            <SqlAutocomplete height="270px" handleChange={onSqlChange} value={sqlCondition || ''} />
            <FlexContainer justifyContent="flex-end" margin="10px 8px 0 0">
              <SaveCancelButtons onCancel={onCancel} onSave={onSave} hasChanges={hasChanges} />
            </FlexContainer>
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
    </>
  );
};

const ImageByValue: TRenderByValueType<ImageByInterface> = ({ metric: imagesByValue, onChange }) => {
  const { localValues, setLocalValues, onSave, onCancel, hasChanges } = useLocalValues({ value: imagesByValue, onChange });

  const {
    byValue: { images },
  } = localValues;

  const onAddImage = () =>
    setLocalValues((localValues) => ({
      ...localValues,
      byValue: {
        ...localValues.byValue,
        images: [
          ...localValues.byValue.images,
          {
            value: null,
          },
        ],
      },
    }));

  const onDeleteColor = (deletedIndex: number) => () =>
    setLocalValues((localValues) => ({
      ...localValues,
      byValue: {
        ...localValues.byValue,
        images: images.filter((_, index) => index !== deletedIndex),
      },
    }));

  const onChangeImage = (imageIndex: number) => (imageValue: ImageValues | null) => {
    setLocalValues((localValues) => ({
      ...localValues,
      byValue: {
        ...localValues.byValue,
        images: images.map((image, index) => (index === imageIndex ? { ...image, value: imageValue } : image)),
      },
    }));
  };

  return (
    <>
      <GroupContainerSettings>
        <ElementContainerSettings>
          <FlexContainer padding="24px 8px" gap="20px" width="100%" flexWrap="wrap">
            {images?.map(({ value }, index) => (
              <ImageByPicker
                value={value}
                onChange={onChangeImage(index)}
                name={index + 1}
                onDelete={onDeleteColor(index)}
                key={index}
              />
            ))}
            <AddColorButton onAdd={onAddImage} />
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
      <GroupContainerSettings>
        <ElementContainerSettings>
          <FlexContainer padding="0 10px 10px 2px" flexDirection="column" width="100%">
            <FlexContainer justifyContent="flex-end" margin="10px 8px 0 0">
              <SaveCancelButtons onCancel={onCancel} onSave={onSave} hasChanges={hasChanges} />
            </FlexContainer>
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
    </>
  );
};

export interface ImageBySettingsProps
  extends Partial<Pick<DefaultDataSettingsInterface, 'indicators'>>,
    OnValueChange<ImageByInterface> {
  isMainContainerSettings?: boolean;
  title?: string;
  titleMainContainerText?: string;
}

export const ImageBySettings: FC<ImageBySettingsProps> = ({
  value,
  onChange,
  indicators,
  isMainContainerSettings = false,
  title = colorNameTitle,
  titleMainContainerText = colorNameTitle,
}) => {
  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  const { isOpen, onOpen, onClose } = useModalState();

  const colorByTabs: WrapperByTabsType = {
    condition: {
      Activator: () => <ConditionEditorField value={value.byCondition.sqlCondition || ''} onOpenChange={onOpen} />,
      Settings: () => (
        <FixedAreaSettings
          onClose={onClose}
          open={isOpen}
          headerText={settingsText || ''}
          width={width}
          minWidth={minWidth}
          anchorEl={mainContainerRef.current}
        >
          {ImagesByConditionRender({ metric: value, onChange })}
        </FixedAreaSettings>
      ),
      settingsText: 'Изменить условие',
      width: '565px',
    },
    value: {
      Activator: () => (
        <FlexContainer width="100%" alignItems="center" justifyContent="center">
          <Button text="Настроить" onClick={onOpen} />
        </FlexContainer>
      ),
      Settings: () => (
        <FixedAreaSettings
          onClose={onClose}
          open={isOpen}
          headerText={settingsText || ''}
          width={width}
          minWidth={minWidth}
          anchorEl={mainContainerRef.current}
        >
          {ImageByValue({ metric: value, onChange, indicators })}
        </FixedAreaSettings>
      ),
      settingsText: 'Изменить диапазон',
      minWidth: '180px',
    },
  };

  const { width, minWidth, settingsText } = colorByTabs[value.type];

  return (
    <WrapperBySettings
      mainContainerRef={mainContainerRef}
      value={value}
      colorByTabs={colorByTabs}
      conditionOptions={getConditionOptions()}
      isMainContainerSettings={isMainContainerSettings}
      title={title}
      titleMainContainerText={titleMainContainerText}
      onChange={onChange}
    />
  );
};
