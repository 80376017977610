import styled from '@emotion/styled';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const Text = styled.div`
  margin-bottom: 4px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: var(${ColorVarsEnum.Level_2});
`;
