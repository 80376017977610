import { AbsolutePositionType } from 'types/styles';
import { PositionConfigInterface } from 'types/store';
import { BezierSettingItem } from 'modules/ui/blocks/BezierBoard/types';

export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const endXY = (el: Element, container: HTMLDivElement, direction: AbsolutePositionType, indent = 0) => {
  const rect = el.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const offsetX = +indent >= 0 ? +indent : rect.width / 2;
  const offsetY = +indent >= 0 ? +indent : rect.height / 2;

  const xy = {
    x: rect.x - containerRect.x,
    y: rect.y - containerRect.y,
  };

  const result: Record<AbsolutePositionType, PositionConfigInterface> = {
    top: {
      x: xy.x + offsetX,
      y: xy.y,
    },
    bottom: {
      x: xy.x + offsetX,
      y: xy.y + rect.height,
    },
    left: {
      x: xy.x,
      y: xy.y + offsetY,
    },
    right: {
      x: xy.x + rect.width,
      y: xy.y + offsetY,
    },
  };

  return result[direction];
};

export const getLine = (setting: BezierSettingItem, container: HTMLDivElement | null, scale: number) => {
  const from = container?.querySelector(`#${setting.from.id}`),
    to = container?.querySelector(`#${setting.to.id}`);

  const result = {
    cord0: { x: 0, y: 0 },
    cord1: { x: 0, y: 0 },
    cord2: { x: 0, y: 0 },
    cord3: { x: 0, y: 0 },
    style: setting.style,
  };

  if (!container || !from || !to) {
    return result;
  }

  result.cord0 = endXY(from, container, setting.positions.start.side, setting.positions.start.indent);
  result.cord3 = endXY(to, container, setting.positions.end.side, setting.positions.end.indent);

  switch (setting.positions.start.side) {
    case 'top':
    case 'bottom': {
      result.cord1.x = result.cord0.x;
      result.cord2.x = result.cord3.x;
      break;
    }
    case 'left':
    case 'right': {
      result.cord1.y = result.cord0.y;
      result.cord2.y = result.cord3.y;
      break;
    }

    default:
      break;
  }
  const halfX = Math.abs(result.cord3.x - result.cord0.x) / 2;
  const halfY = Math.abs(result.cord3.y - result.cord0.y) / 2;
  const clampX = clamp(halfX, 30, 100);
  const clampY = clamp(halfY, 30, 100);

  switch (setting.positions.end.side) {
    case 'top': {
      switch (setting.positions.start.side) {
        case 'top':
          result.cord1.y = result.cord0.y - clampY;
          result.cord2.y = result.cord3.y - clampY;
          break;
        case 'bottom':
          result.cord1.y = result.cord0.y + clampY;
          result.cord2.y = result.cord3.y - clampY;
          break;
        case 'left':
          result.cord1.x = result.cord0.x - clampY;
          result.cord2.x = result.cord3.x;
          result.cord2.y = result.cord3.y - clampY;
          break;
        case 'right':
          result.cord1.x = result.cord0.x + clampX;
          result.cord2.x = result.cord3.x;
          result.cord2.y = result.cord3.y - clampY;
          break;
        default:
          break;
      }
      break;
    }

    case 'bottom': {
      switch (setting.positions.start.side) {
        case 'bottom':
          result.cord1.y = result.cord0.y + clampY;
          result.cord2.y = result.cord3.y + clampY;
          break;
        case 'top':
          result.cord1.y = result.cord0.y - clampY;
          result.cord2.y = result.cord3.y + clampY;
          break;
        case 'left':
          result.cord1.x = result.cord0.x - clampX;
          result.cord2.x = result.cord3.x;
          result.cord2.y = result.cord3.y + clampY;
          break;
        case 'right':
          result.cord1.x = result.cord0.x + clampX;
          result.cord2.x = result.cord3.x;
          result.cord2.y = result.cord3.y + clampY;
          break;
        default:
          break;
      }
      break;
    }

    case 'left': {
      switch (setting.positions.start.side) {
        case 'left':
          result.cord1.x = result.cord0.x - clampX;
          result.cord2.x = result.cord3.x - clampX;
          break;
        case 'right':
          result.cord1.x = result.cord0.x + clampX;
          result.cord2.x = result.cord3.x - clampX;
          break;
        case 'top':
          result.cord1.y = result.cord0.y - clampY;
          result.cord2.x = result.cord3.x - clampX;
          result.cord2.y = result.cord3.y;
          break;
        case 'bottom':
          result.cord1.y = result.cord0.y + clampY;
          result.cord2.y = result.cord3.y;
          result.cord2.x = result.cord3.x - clampX;
          break;
        default:
          break;
      }
      break;
    }

    case 'right': {
      switch (setting.positions.start.side) {
        case 'right':
          result.cord2.x = result.cord3.x + clampX;
          result.cord1.x = result.cord0.x + clampX;
          break;
        case 'left':
          result.cord2.x = result.cord3.x + clampX;
          result.cord1.x = result.cord0.x - clampX;
          break;
        case 'top':
          result.cord1.y = result.cord0.y - clampY;
          result.cord2.x = result.cord3.x + clampX;
          result.cord2.y = result.cord3.y;
          break;
        case 'bottom':
          result.cord1.y = result.cord0.y + clampY;
          result.cord2.y = result.cord3.y;
          result.cord2.x = result.cord3.x + clampX;
          break;
        default:
          break;
      }
      break;
    }
    default:
      break;
  }

  const transformToScale = (cord: { x: number; y: number }) => ({ x: cord.x / scale, y: cord.y / scale });

  return {
    ...result,
    cord0: transformToScale(result.cord0),
    cord1: transformToScale(result.cord1),
    cord2: transformToScale(result.cord2),
    cord3: transformToScale(result.cord3),
  };
};
