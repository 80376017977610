import { addToLayer, deleteFromLayer } from 'store/reducers/board';
import { addNewFilter, removeFilterById, updateFilter } from 'store/reducers/filters';
import {
  counteractionAddToLayerAction,
  counteractionFilterFromLayerAction,
  counteractionPaletteAction,
  counteractionThemeAction,
  repeatAddToLayerAction,
  repeatFilterFromLayerAction,
  repeatPaletteAction,
  repeatThemeAction,
  returnBackgroundSettingsAction,
  returnDataSettingsByIdTypedAction,
  returnEventsSettingsAction,
  returnFilterSettingsAction,
  returnPaletteSettingsAction,
  returnPositionConfigByIdAction,
  returnProjectSettingsAction,
  returnThemeSettingsAction,
  returnViewSettingsAction,
} from 'store/reducers/history/actions';
import { HistoryStoreStateType, TrackingPathsInterface, TrackingPathsTypes } from 'store/reducers/history/types';
import { addNewPalette, removePaletteById, updatePaletteById } from 'store/reducers/palettes';
import { updateProjectSettings } from 'store/reducers/projectSettings';
import { addNewTheme, removeThemeById, updateThemeById } from 'store/reducers/themes';
import {
  updateBackgroundSettingsData,
  updateDataSettingsData,
  updateEventsData,
  updatePositionConfig,
  updateSqlData,
  updateViewSettingsData,
} from 'store/reducers/visualisations';
import { updateSqlSettingsActionById } from 'store/reducers/visualisations/actions';

export const initialHistoryStoreState: HistoryStoreStateType = {
  undo: [],
  redo: [],
  //TODO Лимит перенести в другой стор и оттуда его подтягивать
  limitOfHistory: 20,
  isAnotherPage: {
    isAnother: false,
    redirectPageId: '',
  },
};

export const trackingPaths: Record<TrackingPathsTypes, TrackingPathsInterface> = {
  [updatePositionConfig.type]: {
    type: 'common',
    paths: (state, id) => id && state.visualisations.visualisations[id]?.positionConfig,
    counterAction: returnPositionConfigByIdAction,
  },
  [addToLayer.type]: {
    type: 'selfRepeating',
    paths: (state, id) => id && state.visualisations.visualisations[id],
    counterAction: counteractionAddToLayerAction,
    repeatAction: repeatAddToLayerAction,
  },
  [deleteFromLayer.type]: {
    type: 'selfRepeating',
    paths: (state, id) => id && state.visualisations.visualisations[id],
    counterAction: repeatAddToLayerAction,
    repeatAction: counteractionAddToLayerAction,
  },
  [updateDataSettingsData.type]: {
    type: 'common',
    paths: (state, id) => id && state.visualisations.visualisations[id]?.dataSettings,
    counterAction: returnDataSettingsByIdTypedAction,
  },
  [updateViewSettingsData.type]: {
    type: 'common',
    paths: (state, id) => id && state.visualisations.visualisations[id]?.viewSettings,
    counterAction: returnViewSettingsAction,
    exceptions: (action) =>
      !!action.payload.viewSettings.dataZoomVerticalStartAndEnd || !!action.payload.viewSettings.dataZoomHorizontalStartAndEnd,
  },
  [updateEventsData.type]: {
    type: 'common',
    paths: (state, id) => id && state.visualisations.visualisations[id]?.events,
    counterAction: returnEventsSettingsAction,
  },
  [updateBackgroundSettingsData.type]: {
    type: 'common',
    paths: (state, id) => id && state.visualisations.visualisations[id]?.backgroundImagesSettings,
    counterAction: returnBackgroundSettingsAction,
  },
  [updateSqlData.type]: {
    type: 'common',
    paths: (state, id) => id && state.visualisations.visualisations[id]?.sqlData,
    counterAction: updateSqlSettingsActionById,
  },
  [updateProjectSettings.type]: {
    type: 'common',
    paths: (state) => state.projectSettings.projectSettings,
    counterAction: returnProjectSettingsAction,
    exceptions: (action) => !!action.payload.isViewMode,
  },
  [updateFilter.type]: {
    type: 'common',
    paths: (state, id) => id && state.filters.filters[id],
    counterAction: returnFilterSettingsAction,
  },
  [addNewFilter.type]: {
    type: 'selfRepeating',
    paths: (state, id) => id && state.filters.filters[id],
    counterAction: counteractionFilterFromLayerAction,
    repeatAction: repeatFilterFromLayerAction,
  },
  [removeFilterById.type]: {
    type: 'selfRepeating',
    paths: (state, id) => id && state.filters.filters[id],
    counterAction: repeatFilterFromLayerAction,
    repeatAction: counteractionFilterFromLayerAction,
  },
  [addNewPalette.type]: {
    type: 'selfRepeating',
    paths: (state, id) => id && state.palettes.palettes[id],
    counterAction: counteractionPaletteAction,
    repeatAction: repeatPaletteAction,
  },
  [removePaletteById.type]: {
    type: 'selfRepeating',
    paths: (state, id) => id && state.palettes.palettes[id],
    counterAction: repeatPaletteAction,
    repeatAction: counteractionPaletteAction,
  },
  [updatePaletteById.type]: {
    type: 'common',
    paths: (state, id) => id && state.palettes.palettes[id],
    counterAction: returnPaletteSettingsAction,
  },
  [addNewTheme.type]: {
    type: 'selfRepeating',
    paths: (state, id) => id && state.themes.themes[id],
    counterAction: counteractionThemeAction,
    repeatAction: repeatThemeAction,
  },
  [removeThemeById.type]: {
    type: 'selfRepeating',
    paths: (state, id) => id && state.themes.themes[id],
    counterAction: repeatThemeAction,
    repeatAction: counteractionThemeAction,
  },
  [updateThemeById.type]: {
    type: 'common',
    paths: (state, id) => id && state.themes.themes[id],
    counterAction: returnThemeSettingsAction,
  },
};

export const keysTrackingTypes = Object.keys(trackingPaths);
