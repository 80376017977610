import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch } from 'store';
import { clearSourceTablesStore } from 'store/reducers/loadingScript/actions';
import {
  addFlowAction,
  changeActiveFlowIdAction,
  clearFlowsStore,
  createFlowAction,
  loadFlowDraftInfoAction,
  loadFlowsAction,
  updateFlowByIdAction,
  updateFlowNameAction,
} from 'store/reducers/projectManager/actions';
import { SelectedFlowInterface } from 'components/projectsManager/types';
import { getActiveFlowId, getFlowDraftInfoId, getFlows } from 'store/reducers/projectManager/getters';
import { useSelector } from 'react-redux';
import { UniversalLeftBarList } from 'modules/settingsContainer/UniversalLeftBarList';
import {
  closeConfirmationModalAction,
  closeModalAction,
  openConfirmationModalAction,
  openModalTypedAction,
} from 'store/reducers/modals/actions';
import Snackbar from 'services/Snackbar';
import { NameModal } from 'components/projectsManager/Modals/NameModal';
import { useDeleteFlow } from 'components/projectsManager/hook/useDeleteFlow';
import { Tab } from 'modules/ui/tabs/types';
import { ProjectsSearchList } from 'components/projectsManager/elements/Projects/ProjectsSearchList';
import { useRole } from 'utils/hooks/useRole';
import { FlowsListInterface } from 'store/reducers/projectManager/types';
import { DraftFlowIcon, FlowIcon } from 'assets/icons/navigations';
import { LoadLayoutManager } from 'components/layouts/LoadLayoutManager';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FlexContainer } from 'styles/FlexContainer';
import { startMobileSize } from 'constants/constants';
import { useWindowDimensions } from 'utils/hooks/screenSizeHook';
import { SectionMiddleUI } from 'modules/ui/ManagerOrAdminComponentsUI/SectionMiddleUI';
import { Access } from './elements/Projects/Access';

export const ProjectsManager: FC = () => {
  const dispatch = useAppDispatch();
  const { isViewer } = useRole();
  const { loading, flowsList } = useSelector(getFlows);
  const { id, title, groupType } = useSelector(getActiveFlowId);
  const flowDraftInfoId = useSelector(getFlowDraftInfoId);
  const { onDeleteModal } = useDeleteFlow();
  const { width } = useWindowDimensions();

  useEffect(() => {
    dispatch(clearSourceTablesStore());

    return () => {
      dispatch(clearFlowsStore());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectedItem = useCallback(
    async ({ id, title, groupType }: SelectedFlowInterface) => {
      dispatch(changeActiveFlowIdAction({ flowId: id, title, groupType: groupType as FlowsListInterface['groupType'] }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    dispatch(loadFlowsAction());
    dispatch(loadFlowDraftInfoAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (flowDraftInfoId && !id) {
      dispatch(
        changeActiveFlowIdAction(
          isViewer
            ? { flowId: flowsList[0]?.id, title: flowsList[0]?.name, groupType: flowsList[0]?.groupType }
            : { flowId: flowDraftInfoId, title: 'Черновики' },
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowDraftInfoId, flowsList, isViewer]);

  const modelMetaDataList = flowsList?.map(({ name, id, groupType }) => ({
    items: [],
    id: id,
    title: name,
    type: groupType || '',
  }));

  const onCloseCreateFlowModal = () => dispatch(closeModalAction('createFlow'));

  const hasName = useCallback((nameFlow: string) => flowsList.find(({ name }) => name === nameFlow), [flowsList]);

  const onCreateFlow = useCallback(
    async (title?: string) => {
      try {
        if (!title) {
          return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
        }

        if (hasName(title)) {
          return Snackbar.show('Поток с таким именем уже существует', 'error');
        }

        const resProtect = await dispatch(createFlowAction(title)).unwrap();

        if (resProtect) {
          await dispatch(addFlowAction(resProtect));
          await onSelectedItem({ id: resProtect.id, title: resProtect.name, groupType: resProtect.groupType });

          onCloseCreateFlowModal();
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasName, onCloseCreateFlowModal],
  );

  const onCreateFlowModal = useCallback(
    () => {
      dispatch(
        openModalTypedAction({
          Component: NameModal,
          componentProps: {
            onClose: onCloseCreateFlowModal,
            onClick: onCreateFlow,
          },
          modalSettings: {
            position: 'static',
            width: '320px',
            maxHeight: '168px',
            headerText: 'Создание потока',
          },
          name: 'createFlow',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseCreateFlowModal, onCreateFlow],
  );

  const tabs: Tab[] = useMemo(() => {
    const baseTabs: Tab[] = [
      {
        content: 'Проекты',
        prompt: 'Проекты',
        type: 'text',
        id: 'flow',
        Component: ProjectsSearchList,
      },
    ];

    if (id !== flowDraftInfoId) {
      baseTabs.push({
        content: 'Доступ',
        prompt: 'Доступ',
        type: 'text',
        id: 'access',
        Component: Access,
      });
    }

    // Фильтруем вкладки, чтобы убедиться, что у всех есть компоненты
    return baseTabs.filter(({ Component }) => Component) as Tab[];
  }, [flowDraftInfoId, id]);

  const onEdit = useCallback(
    async (id: string, name: string) => {
      try {
        if (!name) {
          return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
        }

        if (hasName(name)) {
          return Snackbar.show('Поток с таким именем уже существует', 'error');
        }

        const actionRes = await dispatch(updateFlowNameAction({ flowId: id, name })).unwrap();
        if (actionRes) {
          await dispatch(updateFlowByIdAction({ flow: actionRes }));
          dispatch(changeActiveFlowIdAction({ flowId: actionRes.id, title: actionRes.name, groupType: actionRes?.groupType }));
          dispatch(closeConfirmationModalAction());
        }
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasName],
  );

  const onEditModal = useCallback(
    async ({ id, title }: { id: string; title: string }) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Готово',
          onConfirm: (title) => onEdit(id, title?.trim() || ''),
          valueInput: title,
          isRenamable: true,
          titleText: 'Переименовать поток',
          width: '320px',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onEdit],
  );
  const activeIconTitle = useMemo(() => (id === flowDraftInfoId ? DraftFlowIcon : FlowIcon), [flowDraftInfoId, id]);

  const isAccessControl = groupType === 'owner';

  const isEnabled = isViewer ? flowsList.length > 1 : true;

  return (
    <>
      <LoadLayoutManager>
        <FlexContainer
          flexDirection={width > startMobileSize ? 'row' : 'column'}
          position="relative"
          width="100%"
          backgroundColor={`var(${ColorVarsEnum.Level_5_application})`}
          flex="1 1 0%"
          flexWrap="nowrap"
        >
          {isEnabled && (
            <UniversalLeftBarList
              data={modelMetaDataList}
              activeTitle={title}
              activeId={id}
              additionalFieldId={flowDraftInfoId}
              loadingList={loading}
              onSelectedItem={onSelectedItem}
              onDeleteItem={onDeleteModal}
              onCreateItem={onCreateFlowModal}
              isViewer={isViewer}
              IconTitle={activeIconTitle}
            />
          )}

          <SectionMiddleUI
            tabs={tabs}
            title={title}
            id={id}
            draftProjectId={flowDraftInfoId || ''}
            onEditModal={onEditModal}
            onDeleteModal={onDeleteModal}
            isViewer={isViewer}
            isAccessControl={isAccessControl}
            HeaderIcon={id === flowDraftInfoId ? DraftFlowIcon : FlowIcon}
          />
        </FlexContainer>
      </LoadLayoutManager>
    </>
  );
};
