import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSliceFn } from 'constants/store';
import { initialAdminTasksStoreState } from './constants';
import { loadAdminTasksAction } from './actions';
import { IdInterface } from 'types/store';
import { TaskInterface } from 'store/reducers/adminTasks/types';

export const adminTasksSlice = createSlice({
  name: 'adminTasks',
  initialState: initialAdminTasksStoreState,
  reducers: {
    addTask: (state, { payload: data }: PayloadAction<TaskInterface>) => {
      state.adminTasksData.tasks = [data, ...state.adminTasksData.tasks];
    },
    addInfo: (state, { payload: taskInfo }: PayloadAction<TaskInterface | null>) => {
      state.adminTasksData.taskInfo = taskInfo;
    },
    updateTasks: (state, { payload: task }: PayloadAction<TaskInterface[]>) => {
      state.adminTasksData.tasks = task;
    },
    deleteTaskById: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.adminTasksData.tasks = state.adminTasksData.tasks.filter((task) => task?.id !== id);
    },
    setSlice: setSliceFn,
  },
  extraReducers: (builder) => {
    builder.addCase(loadAdminTasksAction.pending, (state) => {
      state.adminTasksData.loading = true;
      state.adminTasksData.tasks = [];
    });
    builder.addCase(loadAdminTasksAction.fulfilled, (state, { payload }) => {
      state.adminTasksData.loading = false;
      state.adminTasksData.tasks = payload;
    });
    builder.addCase(loadAdminTasksAction.rejected, (state) => {
      state.adminTasksData.loading = false;
      state.adminTasksData.tasks = [];
    });
  },
});

export const { setSlice, deleteTaskById, addTask, updateTasks, addInfo } = adminTasksSlice.actions;

export default adminTasksSlice.reducer;
