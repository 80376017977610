import React from 'react';
import { LoadingOverlayPercent } from 'modules/ui/Loading/LoadingOverlayPercent';
import { ModalUniversal } from 'modules/ui/ModalUniversal';
import { PrimaryTextSpan } from 'styles/TextsElements';

interface LoadingModalProps {
  loading: boolean;
  progress: number;
  onClose: () => void;
  isOpen: boolean;
}

export const LoadingModal: React.FC<LoadingModalProps> = ({ loading, progress, onClose, isOpen }) => (
  <>
    {loading && (
      <ModalUniversal headerText="Загрузка" onClose={onClose} width="500px" maxHeight="90vh" height="200px" open={isOpen}>
        <PrimaryTextSpan whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" lineHeight="20px" width="200px">
          {progress}
        </PrimaryTextSpan>
        <LoadingOverlayPercent loading={true} progress={progress} />
      </ModalUniversal>
    )}
  </>
);
