import React, { useCallback, useMemo } from 'react';
import {
  deleteByIdFlowProjectAction,
  loadMoveFlowProjectsAction,
  moveFlowProjectAction,
} from 'store/reducers/adminFlows/actions';
import { getFlowsAndDraft } from 'store/reducers/adminFlows/getters';
import { closeConfirmationModalAction, openConfirmationModalAction } from 'store/reducers/modals/actions';
import { useAppDispatch } from 'store';
import { MoveProjectModalUI } from 'modules/ui/ManagerOrAdminComponentsUI/ModalsUI/MoveProjectModalUI';
import { useSelector } from 'react-redux';
import { ModalComponentType } from 'store/reducers/modals/types';
import { FlowType } from './types';
import Snackbar from 'services/Snackbar';

interface MoveFlowProjectModalProps {
  projectId: string;
  projectName: string;
  activeFlowId: string;
}

export const MoveFlowProjectModal: ModalComponentType<MoveFlowProjectModalProps> = ({
  onClose,
  projectId,
  activeFlowId,
  projectName,
}) => {
  const dispatch = useAppDispatch();
  const flowsAndDraft = useSelector(getFlowsAndDraft);

  const flowsItems = useMemo(() => {
    return flowsAndDraft?.reduce<FlowType[]>((acc, flow) => {
      if (flow?.id !== activeFlowId) {
        acc.push({
          name: flow?.id || '',
          value: flow?.name || '',
        });
      }
      return acc;
    }, []);
  }, [flowsAndDraft, activeFlowId]);

  const onMoveProject = useCallback(
    async (selectFlowsName: string) => {
      try {
        const moveProjectRes = await dispatch(moveFlowProjectAction({ projectId, flowId: selectFlowsName })).unwrap();

        if (moveProjectRes) {
          dispatch(deleteByIdFlowProjectAction(projectId));
          dispatch(closeConfirmationModalAction());
          onClose();
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, onClose],
  );

  const onMoveProjectModal = useCallback(
    async ({ value, name }: FlowType) => {
      if (!name) {
        return Snackbar.show('Поле «Поток» обязательно для заполнения', 'error');
      }

      const moveFLowActions = await dispatch(loadMoveFlowProjectsAction({ flowId: name })).unwrap();
      const hasName = moveFLowActions?.find(({ title }) => title === projectName);

      if (hasName) {
        return Snackbar.show('В потоке уже есть проект с таким названием. Измените название и повторите попытку', 'error');
      }

      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Да',
          cancelButtonText: 'Нет',
          subTitleText: `Действительно переместить проект «${projectName}» в поток «${value}»?`,
          onConfirm: () => onMoveProject(name),
          titleText: 'Перемещение проекта',
          width: '320px',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onMoveProject, projectName],
  );

  return <MoveProjectModalUI onMoveProjectModal={onMoveProjectModal} onClose={onClose} flowsItems={flowsItems} />;
};
