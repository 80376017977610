import { DrillDownIcon } from 'assets/icons/editor';
import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { RadioButtonWrapper, RadioItem } from 'modules/ui/RadioButtonSelector/styles';
import { OverflowValueEnum } from 'store/reducers/visualisations/types';
import { RadioButtonSizesType } from '../RadioButtonSelector/types';

interface SingleFilterRadioSelectorProps {
  options: SettingsRadioItem<string>[];
  isDrillDown?: boolean;
  size: RadioButtonSizesType;
  onChange: (value: SettingsRadioItem<string>) => void;
  overflowStyles?: OverflowValueEnum | string;
  position?: string;
  gapStyles?: string;
  activeValues: { value: string; label: string }[];
}

export const SingleFilterRadioSelector = ({
  activeValues = [],
  options,
  onChange,
  size = 'normal',
  isDrillDown = false,
  overflowStyles,
  position,
  gapStyles,
}: SingleFilterRadioSelectorProps) => {
  return (
    <RadioButtonWrapper gap={gapStyles} position={position} overflowStyles={overflowStyles} isDrillDown={isDrillDown}>
      {options.map((option, index) => {
        const { value, label, disabled } = option;
        const isSelected = activeValues.some((activeValue) => activeValue.value === value);

        return (
          <>
            <RadioItem
              size={size}
              disabled={disabled}
              selected={isSelected}
              onClick={() => !disabled && onChange(option)}
              style={{ maxHeight: '35px', width: 'fit-content' }}
              key={value}
            >
              {label}
            </RadioItem>
            {isDrillDown && index < options.length - 1 && <DrillDownIcon />}
          </>
        );
      })}
    </RadioButtonWrapper>
  );
};
