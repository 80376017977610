import { PieRoseTypeTypes } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { pieTitleTypesOptions } from 'modules/settingsContainer/common/data/TypeOfPieSettings/constants';
import { MainContainerSettings } from 'modules/settingsContainer';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';

type TypeOfPieSettingsProps = OnValueChange<PieRoseTypeTypes>;
export const TypeOfPieSettings = ({ value, onChange }: TypeOfPieSettingsProps) => (
  <MainContainerSettings titleText="Тип">
    <SettingsRadio
      onChange={({ value }) => onChange(value)}
      activeValue={getActiveRadioValue(pieTitleTypesOptions, value)}
      options={pieTitleTypesOptions}
    />
  </MainContainerSettings>
);
