import { DefaultPropertiesInterface } from 'store/reducers/visualisations/types';
import { useMemo } from 'react';

export type PropertiesRecordType = Record<string, DefaultPropertiesInterface>;

export const useProperties = (data: Record<string, DefaultPropertiesInterface>) =>
  useMemo(
    () =>
      Object.entries(data).reduce<PropertiesRecordType>(
        (data, [fieldName, properties]) => ({
          ...data,
          [fieldName]: properties,
        }),
        {},
      ),
    [data],
  );
