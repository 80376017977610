import { ContainerSettings, ContainerSettingsProps } from 'modules/settingsContainer/common/view/ContainerSettings';
import { DescriptionSettings } from 'modules/settingsContainer/common/view/DescriptionSettings';
import { TextHintSettings } from 'modules/settingsContainer/common/view/TextHintSettings';
import { TitlePositionSettings } from 'modules/settingsContainer/common/view/TitlePositionSettings';
import { TitleSettings } from 'modules/settingsContainer/common/view/TitleSettings';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { DefaultViewSettingsInterface } from 'store/reducers/visualisations/types';
import { ReactChildrenType } from 'types/global';
import SubSection from 'shared/ui/subSection';

interface DefaultViewSettingsProps extends Pick<ContainerSettingsProps, 'positionConfig' | 'ExternalBackgroundSettings'> {
  viewSettings: DefaultViewSettingsInterface;
  FirstSlot?: ReactChildrenType;
  SecondSlot?: ReactChildrenType;
  ThirdSlot?: ReactChildrenType;
  SectionSlot?: ReactChildrenType;
}

export const DefaultViewSettings = ({
  viewSettings,
  FirstSlot,
  SecondSlot,
  SectionSlot,
  ThirdSlot,
  positionConfig,
  ExternalBackgroundSettings,
}: DefaultViewSettingsProps) => (
  <>
    <ContainerSettings
      positionConfig={positionConfig}
      disableDragging={viewSettings.disableDragging}
      showBackground={viewSettings?.showBackground}
      borderSettings={viewSettings.borderSettings}
      shadowSettings={viewSettings.shadowSettings}
      ExternalBackgroundSettings={ExternalBackgroundSettings}
    />
    {FirstSlot}
    {viewSettings?.header || viewSettings?.description || viewSettings?.headerPosition || SectionSlot ? (
      <MainContainerSettings titleText="Заголовок">
        <SubSection>{viewSettings?.header && <TitleSettings value={viewSettings.header} />}</SubSection>
        <SubSection>{viewSettings?.description && <DescriptionSettings value={viewSettings.description} />}</SubSection>
        <SubSection>{viewSettings?.headerPosition && <TitlePositionSettings value={viewSettings.headerPosition} />}</SubSection>
      </MainContainerSettings>
    ) : null}
    {SecondSlot}
    {viewSettings?.hint && <TextHintSettings value={viewSettings.hint} />}
    {ThirdSlot}
  </>
);
