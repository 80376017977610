import React, { useMemo } from 'react';
import { NoopValueType } from 'types/global';
import { getMapSourcesData } from './constants';
import { SourcesListData } from './types';
import { SourcesList as SourcesListUi } from 'modules/ui/SourceList';

interface SourcesListProps {
  title: string;
  data: SourcesListData[];
  onConnection: NoopValueType<{ sourceId: string; name: string }>;
  onDelete?: NoopValueType<string>;
  onUpdate: NoopValueType<string>;
  onAccessSourceModal?: NoopValueType<string>;
  onEdit: (id: string, type: string) => void;
  loading: boolean;
  disabledOnDelete?: boolean;
  activeSourceId?: string | null;
}

export const SourcesList = ({
  data,
  onConnection,
  onDelete,
  onEdit,
  onUpdate,
  onAccessSourceModal,
  title,
  disabledOnDelete,
  loading,
  activeSourceId,
}: SourcesListProps) => {
  const processedData = useMemo(() => getMapSourcesData(data, disabledOnDelete), [data, disabledOnDelete]);

  return (
    <SourcesListUi
      data={processedData}
      title={title}
      loading={loading}
      onConnection={onConnection}
      onUpdate={onUpdate}
      onEdit={onEdit}
      onAccessSourceModal={onAccessSourceModal}
      onDelete={onDelete}
      activeSourceId={activeSourceId}
    />
  );
};
