import { MainLayout } from 'components/layouts/MainLayout';
import { FC } from 'types/global';
import { LoadHeader } from 'components/grid/headers/LoadHeader';
import { HeaderItemInterface } from 'modules/ui/menu/HeaderMenu/types';
import { LoadRoute } from 'constants/Routes';

type LoadLayoutProps = {
  menuItems: HeaderItemInterface<LoadRoute>[];
};

export const LoadLayout: FC<LoadLayoutProps> = ({ children, menuItems }) => {
  return <MainLayout Header={() => <LoadHeader menuItems={menuItems} />}>{children}</MainLayout>;
};
