import { SettingsCheckboxItem } from 'modules/settingsContainer/SettingsCheckbox/types';

export const isActiveCheckboxValue: <ValueType extends string>(
  activeValues: SettingsCheckboxItem<ValueType>[],
  currentValue: SettingsCheckboxItem<ValueType>,
) => boolean = (activeValues, currentValue) => !!activeValues.find(({ value }) => value === currentValue.value);

export const getNewStateValues: <ValueType extends string>(
  activeValues: SettingsCheckboxItem<ValueType>[],
  currentValue: SettingsCheckboxItem<ValueType>,
) => SettingsCheckboxItem<ValueType>[] = (activeValues, currentValue) => {
  const isExist = isActiveCheckboxValue(activeValues, currentValue);

  return isExist ? activeValues.filter(({ value }) => value !== currentValue.value) : [...activeValues, currentValue];
};

export const getActiveRadioValue: <ValueType extends string>(
  options: SettingsCheckboxItem<ValueType>[],
  value: ValueType[],
) => SettingsCheckboxItem<ValueType>[] = (options, value) => options.filter((option) => value.includes(option.value));
