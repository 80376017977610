import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import React from 'react';
import { OverflowPositionInterface } from 'store/reducers/visualisations/types';
import { NoopValueType } from 'types/global';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { overflowPositionOptions } from './constants';

interface OverflowPositionSettingsProps {
  value: OverflowPositionInterface;
  onChange: NoopValueType<OverflowPositionInterface>;
}

export const OverflowPositionSettings = ({ value, onChange }: OverflowPositionSettingsProps) => {
  return (
    <MainContainerSettings titleText="Расположение элементов">
      <GroupContainerSettings>
        <SettingsRadio
          onChange={({ label, value: overflow }) => onChange({ label, value: overflow })}
          activeValue={value}
          options={overflowPositionOptions}
        />
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
