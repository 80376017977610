import { Button } from 'modules/ui';
import { AddIcon, DeleteIcon } from 'assets/icons/withContainer';
import { FlexContainer } from 'styles/FlexContainer';
import React from 'react';
import { NoopType } from 'types/global';

export interface AddAndDeleteButtonsProps {
  addButtonText?: string;
  onAdd?: NoopType;
  onDelete?: NoopType;
  disable?: {
    addButton?: boolean;
    deleteButton?: boolean;
  };
}

export const AddAndDeleteButtons = ({ addButtonText, onDelete, onAdd, disable }: AddAndDeleteButtonsProps) => (
  <FlexContainer justifyContent="space-between" padding="0 4px">
    <Button disabled={disable?.addButton} text={addButtonText} leftIcon={<AddIcon />} onClick={onAdd} />
    <Button needBackground={false} leftIcon={<DeleteIcon />} disabled={disable?.deleteButton} onClick={onDelete} />
  </FlexContainer>
);
