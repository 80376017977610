import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { OnValueChange } from 'types/global';
import Select from 'modules/ui/Select';
import { FlexContainer } from 'styles/FlexContainer';
import { BubbleIndicatorInterface } from 'store/reducers/visualisations/types';

interface IndicatorSelectorSettingsProps extends OnValueChange<string> {
  indicators: BubbleIndicatorInterface[];
  title?: string;
}

export const IndicatorSelectorSettings = ({
  title = 'Показатели',
  indicators,
  value,
  onChange,
}: IndicatorSelectorSettingsProps) => {
  return (
    <MainContainerSettings titleText={title}>
      <GroupContainerSettings>
        <FlexContainer width="100%" justifyContent="flex-start">
          <Select
            needBackground={false}
            placeholder="Выбрать показатель"
            name="indicator_selector"
            options={indicators.map(({ id, name }) => ({
              value: id,
              name,
            }))}
            width="60%"
            value={value}
            onChange={onChange}
          />
        </FlexContainer>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
