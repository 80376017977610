import { SourcesStateInterface } from 'store/reducers/sources/types';

export const initialSourcesStoreState: SourcesStateInterface = {
  sourcesData: {
    sourcesList: [],
    loading: false,
  },
  usersGroupsList: {
    usersGroupsList: [],
    loading: false,
  },
  sourcesUsersAndGroups: {
    sourcesUsersAndGroupsList: [],
    loading: false,
  },
  sourcesActiveLoading: [],
  showSidebar: true,
  activeSourceId: null,
};
