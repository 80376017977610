import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { OnValueChange } from 'types/global';

export interface BooleanSettingsProps extends OnValueChange<boolean> {
  titleText: string;
}

export const BooleanSettings = ({ value, titleText, onChange }: BooleanSettingsProps) => (
  <MainContainerSettings titleText={titleText} switcherState={value} switcherChange={() => onChange(!value)} />
);
