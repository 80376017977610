import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const TreemapDefault: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fill = `var(${ColorVarsEnum.Accent})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <rect x="10" y="12" width="28" height="26" fill={fill} />
        <rect opacity="0.35" x="10" y="41" width="28" height="17" fill={fillDefault} />
        <rect opacity="0.75" x="41" y="49" width="20" height="9" fill={fillDefault} />
        <rect opacity="0.75" x="41" y="12" width="20" height="17" fill={fillDefault} />
        <rect opacity="0.75" x="41" y="32" width="20" height="14" fill={fillDefault} />
      </svg>
    </FlexContainer>
  );
};
