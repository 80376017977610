import { useState, useCallback, useEffect } from 'react';
import { deleteByIdSourceAction, deleteSourceByIdAction, loadSourceByIdAction } from 'store/reducers/sources/actions';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'store';
import { closeConfirmationModalAction, closeModalAction, openConfirmationModalAction } from 'store/reducers/modals/actions';
import { loadSQLScriptAction } from 'store/reducers/loadingScript/actions';
import { sourceDataBaseEdit } from 'components/console/elements/SourcesConnection/SourceDataBaseEditModal/constants';
import { sourceFileEdit } from 'components/console/elements/SourcesConnection/SourceFileEditModal/constants';

export const useSourceEditData = <T>(
  projectId: string,
  id: string,
): {
  editSource: T | null;
  loading: boolean;
  onDeleteModal: (connectionId: string) => Promise<void>;
} => {
  const dispatch = useAppDispatch();
  const onCloseDataBaseEditModal = () => dispatch(closeModalAction(sourceDataBaseEdit));
  const onCloseFileEditModal = () => dispatch(closeModalAction(sourceFileEdit));

  const [editSource, setEditSource] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);

  const getSourceData = useCallback(async () => {
    setLoading(true);
    try {
      const actionResult = await dispatch(loadSourceByIdAction(id));

      const unwrappedResult = unwrapResult(actionResult) as T;

      setEditSource(unwrappedResult);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    void getSourceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onDelete = useCallback(
    async (connectionId: string) => {
      try {
        if (projectId) {
          const action = await dispatch(deleteSourceByIdAction(connectionId)).unwrap();
          if (action) {
            await dispatch(deleteByIdSourceAction(connectionId));

            dispatch(closeConfirmationModalAction());
            dispatch(loadSQLScriptAction(projectId));
            onCloseDataBaseEditModal();
            onCloseFileEditModal();
          }
        }
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, onCloseDataBaseEditModal, onCloseFileEditModal],
  );

  const onDeleteModal = useCallback(
    async (id: string) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          subTitleText: 'Действительно удалить соединение?',
          onConfirm: () => onDelete(id),
          titleText: 'Удалить соединение',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  return { editSource, loading, onDeleteModal };
};
