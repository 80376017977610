import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const FilterMultipleDefault: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fill = `var(${ColorVarsEnum.Accent})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <path
          d="M18.583 40.2354C17.5423 40.2354 16.5952 40.0519 15.7417 39.6851C14.8882 39.3182 14.2106 38.7866 13.709 38.0903C13.2148 37.3866 12.979 36.5368 13.0015 35.541L13.0239 35.4736H16.2021C16.2021 35.9079 16.2995 36.2935 16.4941 36.6304C16.6888 36.9673 16.9621 37.2331 17.314 37.4277C17.6733 37.6224 18.0964 37.7197 18.583 37.7197C19.3392 37.7197 19.9344 37.5063 20.3687 37.0796C20.8104 36.6528 21.0312 36.0651 21.0312 35.3164C21.0312 34.5527 20.8141 33.9575 20.3799 33.5308C19.9456 33.104 19.2905 32.8906 18.4146 32.8906H16.6401V30.4312H18.4819C19.006 30.4312 19.4365 30.3413 19.7734 30.1616C20.1104 29.9744 20.3612 29.7124 20.5259 29.3755C20.6906 29.0386 20.7729 28.6418 20.7729 28.1851C20.7729 27.4963 20.5858 26.9497 20.2114 26.5454C19.8371 26.1411 19.2943 25.939 18.583 25.939C18.1562 25.939 17.7782 26.0251 17.4487 26.1973C17.1193 26.362 16.861 26.5978 16.6738 26.9048C16.4941 27.2118 16.4043 27.5674 16.4043 27.9717H13.2261L13.2036 27.9043C13.1812 27.0358 13.3945 26.2646 13.8438 25.5908C14.293 24.9095 14.9256 24.3779 15.7417 23.9961C16.5653 23.6068 17.5049 23.4121 18.5605 23.4121C20.2152 23.4121 21.5441 23.8164 22.5474 24.625C23.5506 25.4261 24.0522 26.5641 24.0522 28.0391C24.0522 28.8027 23.8389 29.4915 23.4121 30.1055C22.9928 30.7194 22.3639 31.221 21.5254 31.6104C22.4313 31.9323 23.1201 32.4264 23.5918 33.0928C24.071 33.7591 24.3105 34.534 24.3105 35.4175C24.3105 36.4132 24.0635 37.2705 23.5693 37.9893C23.0752 38.708 22.3939 39.262 21.5254 39.6514C20.6644 40.0407 19.6836 40.2354 18.583 40.2354Z"
          fill={fillDefault}
        />
        <path
          d="M31.2734 40.2354C30.1055 40.2354 29.0947 39.9733 28.2412 39.4492C27.3952 38.9251 26.7438 38.2026 26.2871 37.2817C25.8304 36.3534 25.6021 35.2939 25.6021 34.1035V33.7666C25.6021 32.5687 25.8341 31.5093 26.2983 30.5884C26.7625 29.6675 27.4176 28.945 28.2637 28.4209C29.1097 27.8893 30.1055 27.6235 31.251 27.6235C32.3441 27.6235 33.2837 27.7957 34.0698 28.1401C34.8634 28.4771 35.5073 28.8701 36.0015 29.3193L35.979 32.8008H33.5083L33.0703 30.6333C32.8906 30.4536 32.6623 30.3076 32.3853 30.1953C32.1082 30.083 31.8275 30.0269 31.543 30.0269C30.944 30.0269 30.4461 30.1766 30.0493 30.4761C29.66 30.7756 29.368 31.2023 29.1733 31.7563C28.9787 32.3104 28.8813 32.9805 28.8813 33.7666V34.1035C28.8813 34.9346 28.9899 35.6196 29.207 36.1587C29.4242 36.6903 29.7124 37.0833 30.0718 37.3379C30.4386 37.5924 30.8467 37.7197 31.2959 37.7197C31.8724 37.7197 32.3328 37.57 32.6772 37.2705C33.0291 36.9635 33.2687 36.533 33.396 35.979H36.2036L36.2373 36.0464C36.11 36.9074 35.8555 37.6523 35.4736 38.2812C35.0993 38.9102 34.5677 39.3931 33.8789 39.73C33.1976 40.0669 32.3291 40.2354 31.2734 40.2354Z"
          fill={fillDefault}
        />
        <path d="M42.0001 31.0722L44.1214 28.9509L51.1925 36.0219L49.0712 38.1432L42.0001 31.0722Z" fill={fill} />
        <path d="M54.021 28.9511L56.1423 31.0724L49.0712 38.1432L46.9499 36.0222L54.021 28.9511Z" fill={fill} />
      </svg>
    </FlexContainer>
  );
};
