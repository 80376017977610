import { useCallback } from 'react';
import { closeConfirmationModalAction, openConfirmationModalAction } from 'store/reducers/modals/actions';
import { changeActiveFlowIdAction, deleteByIdFlowAction, deleteFlowAction } from 'store/reducers/projectManager/actions';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { getFlow, getFlowDraftInfo } from 'store/reducers/projectManager/getters';
import { setActiveFlowProjectId } from 'store/reducers/projectManager';

export const useDeleteFlow = () => {
  const dispatch = useAppDispatch();
  const { flowProjectsList, loading } = useSelector(getFlow);
  const draftInfo = useSelector(getFlowDraftInfo);

  const isFlowPopulated = flowProjectsList?.length > 0;

  const onDelete = useCallback(
    async (flowId: string) => {
      try {
        const action = await dispatch(deleteFlowAction(flowId)).unwrap();
        if (action) {
          await dispatch(deleteByIdFlowAction(flowId));
          dispatch(setActiveFlowProjectId(null));
          dispatch(changeActiveFlowIdAction({ flowId: draftInfo?.id || '', title: draftInfo?.name || '' }));
          dispatch(closeConfirmationModalAction());
        }
      } catch (e) {
        console.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, draftInfo?.id, draftInfo?.name],
  );

  const onDeleteModal = useCallback(
    ({ id, title }: { id: string; title: string }) => {
      const subTitleText = isFlowPopulated
        ? `Внимание! Поток «${title}»? содежит проекты. Если вы продолжите удаление, они также будут удалены. Хотите продолжить?`
        : `Действительно удалить поток «${title}»?`;
      if (!loading) {
        dispatch(
          openConfirmationModalAction({
            confirmationButtonText: 'Удалить',
            subTitleText,
            onConfirm: () => onDelete(id),
            titleText: 'Удаление потока',
            width: '320px',
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFlowPopulated, loading, onDelete],
  );

  return { onDelete, onDeleteModal };
};
