import { ModalInfo } from 'store/reducers/modals/types';
import { ModalProps } from 'utils/hooks/useModalActions';
import { CopyUserDraftProjectModal } from './Modals/CopyUserDraftProjectModal';
import { InfoUserDraftProjectModal } from 'components/admin/usersPage/elemets/DraftProjectsSearchList/Modals/InfoUserDraftProjectModal';
import { RollBackUserDraftProjectModal } from 'components/admin/usersPage/elemets/DraftProjectsSearchList/Modals/RollBackUserDraftProjectModal';
import { ModalNameType } from 'components/admin/usersPage/elemets/DraftProjectsSearchList/types';
import { MoveDraftUserProjectModal } from 'components/admin/usersPage/elemets/DraftProjectsSearchList/Modals/MoveDraftUserProjectModal';

export const SHORT_USER_DRAFT_PROJECT_SETTINGS = 'sortUserDraftProjectSettings';

export const modalConfigs: Record<ModalNameType, Omit<ModalInfo<ModalProps>, 'name' | 'componentProps'>> = {
  rollBackUserProjectProject: {
    Component: RollBackUserDraftProjectModal,
    modalSettings: {
      position: 'static',
      width: '380px',
      maxHeight: '500px',
      headerText: 'Откат проекта',
    },
  },
  copyingUserDraftProject: {
    Component: CopyUserDraftProjectModal,
    modalSettings: {
      position: 'static',
      width: '380px',
      maxHeight: '500px',
      headerText: 'Копирование проекта',
    },
  },
  moveDraftUserProject: {
    Component: MoveDraftUserProjectModal,
    modalSettings: {
      position: 'static',
      width: '380px',
      maxHeight: '500px',
      headerText: 'Перемещение проекта',
    },
  },
  infoUserDraftProject: {
    Component: InfoUserDraftProjectModal,
    modalSettings: {
      position: 'static',
      width: '320px',
      maxHeight: '500px',
      headerText: 'О проекте',
    },
  },
};
