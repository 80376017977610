import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ModalComponentType } from 'store/reducers/modals/types';
import { HistoryVersionUserDraftProject } from 'components/admin/usersPage/elemets/DraftProjectsSearchList/HistoryVersionUserDraftProject';

interface RollBackProjectModalProps {
  projectId: string;
  projectName?: string;
}

export const RollBackUserDraftProjectModal: ModalComponentType<RollBackProjectModalProps> = ({
  projectId,
  projectName,
  onClose,
}) => {
  return (
    <FlexContainer width="100%" height="100%" overflowY="auto">
      <FlexContainer width="100%" flexDirection="column" height="calc(-120px + 420px)" overflowY="auto" marginBottom="60px">
        <HistoryVersionUserDraftProject
          padding="0"
          footerPosition="flex-end"
          projectId={projectId}
          projectName={projectName}
          onClose={onClose}
        />
      </FlexContainer>
    </FlexContainer>
  );
};
