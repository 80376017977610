import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSliceFn } from 'constants/store';
import {
  loadFlowAccessAction,
  loadFlowProjectHistoryVersionAction,
  loadFlowProjectsAction,
  loadFlowsAction,
} from 'store/reducers/adminFlows/actions';
import { FlowListInterface } from 'store/reducers/projectManager/types';
import { initialAdminFlowsStoreState } from './constants';
import { FlowAccessListInterface, FlowsListInterface } from 'store/reducers/adminFlows/types';
import { IdInterface } from 'types/store';

export const adminFlowsSlice = createSlice({
  name: 'adminFlowsSlice',
  initialState: initialAdminFlowsStoreState,
  reducers: {
    setSlice: setSliceFn,
    addFlow: (state, { payload: data }: PayloadAction<FlowsListInterface>) => {
      state.flows.flowsList = [...state.flows.flowsList, data];
    },
    changeActiveFlow: (state, { payload }: PayloadAction<FlowsListInterface>) => {
      state.activeFlow = payload;
    },
    deleteByIdFlow: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.flows.flowsList = state.flows.flowsList.filter((page) => page.id !== id);
    },
    updateFlows: (state, { payload: flows }: PayloadAction<FlowsListInterface[]>) => {
      state.flows.flowsList = flows;
    },

    addFlowAccess: (state, { payload: data }: PayloadAction<FlowAccessListInterface>) => {
      state.flowAccess.flowAccessList = [...state.flowAccess.flowAccessList, data];
    },
    deleteByIdFlowAccess: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.flowAccess.flowAccessList = state.flowAccess.flowAccessList.filter((page) => page.id !== id);
    },
    updateFlowAccess: (state, { payload: flows }: PayloadAction<FlowAccessListInterface[]>) => {
      state.flowAccess.flowAccessList = flows;
    },

    setActiveFlowProjectId: (state, { payload: activeFlowProjectId }: PayloadAction<string | null>) => {
      state.activeFlowProjectId = activeFlowProjectId;
    },
    deleteByIdFlowProject: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.flowProjects.flowProjectsList = state.flowProjects.flowProjectsList.filter((project) => project.id !== id);
    },
    updateFlowProjects: (state, { payload: draftProjects }: PayloadAction<FlowListInterface[]>) => {
      state.flowProjects.flowProjectsList = draftProjects;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadFlowsAction.pending, (state) => {
      state.flows = { ...state.flows, loading: true };
      state.flows.flowsList = [];
    });
    builder.addCase(loadFlowsAction.fulfilled, (state, { payload }) => {
      state.flows = { ...state.flows, loading: false };
      state.flows.flowsList = payload;
    });
    builder.addCase(loadFlowsAction.rejected, (state) => {
      state.flows = { ...state.flows, loading: false };
      state.flows.flowsList = [];
    });

    builder.addCase(loadFlowAccessAction.pending, (state) => {
      state.flowAccess = { ...state.flowAccess, loading: true };
      state.flowAccess.flowAccessList = [];
    });
    builder.addCase(loadFlowAccessAction.fulfilled, (state, { payload }) => {
      state.flowAccess = { ...state.flowAccess, loading: false };
      state.flowAccess.flowAccessList = payload;
    });
    builder.addCase(loadFlowAccessAction.rejected, (state) => {
      state.flowAccess = { ...state.flowAccess, loading: false };
      state.flowAccess.flowAccessList = [];
    });

    builder.addCase(loadFlowProjectsAction.pending, (state) => {
      state.flowProjects = { ...state.flowProjects, loading: true };
      state.flowProjects.flowProjectsList = [];
    });
    builder.addCase(loadFlowProjectsAction.fulfilled, (state, { payload }) => {
      state.flowProjects = { ...state.flowProjects, loading: false };
      state.flowProjects.flowProjectsList = payload;
    });
    builder.addCase(loadFlowProjectsAction.rejected, (state) => {
      state.flowProjects = { ...state.flowProjects, loading: false };
      state.flowProjects.flowProjectsList = [];
    });

    builder.addCase(loadFlowProjectHistoryVersionAction.pending, (state) => {
      state.flowProjectHistoryVersion = { ...state.flowProjectHistoryVersion, loading: true };
      state.flowProjectHistoryVersion.flowProjectHistoryVersionList = [];
    });
    builder.addCase(loadFlowProjectHistoryVersionAction.fulfilled, (state, { payload }) => {
      state.flowProjectHistoryVersion = { ...state.flowProjectHistoryVersion, loading: false };
      state.flowProjectHistoryVersion.flowProjectHistoryVersionList = payload;
    });
    builder.addCase(loadFlowProjectHistoryVersionAction.rejected, (state) => {
      state.flowProjectHistoryVersion = { ...state.flowProjectHistoryVersion, loading: false };
      state.flowProjectHistoryVersion.flowProjectHistoryVersionList = [];
    });
  },
});

export const {
  setSlice,
  addFlow,
  deleteByIdFlow,
  changeActiveFlow,
  updateFlows,
  deleteByIdFlowAccess,
  updateFlowAccess,
  addFlowAccess,
  setActiveFlowProjectId,
  deleteByIdFlowProject,
  updateFlowProjects,
} = adminFlowsSlice.actions;

export default adminFlowsSlice.reducer;
