import { FC, ReactChildrenType } from 'types/global';
import { useToggle } from 'utils/hooks/toggle';
import {
  BodyDropDown,
  ContentWrapper,
  DropDownBlockWrapper,
  DropDownWrapper,
  HeaderDropDown,
} from 'modules/ui/blocks/DropDownBlock/styles';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ArrowIcon } from 'modules/ui/icons/ArrowIcon/styles';

interface DropDownBlockProps {
  openHeight?: string;
  title?: string;
  description?: string;
  content?: ReactChildrenType;
  dropDownId?: string;
}

export const DropDownBlock: FC<DropDownBlockProps> = ({
  children,
  content,
  openHeight = '50%',
  description,
  title,
  dropDownId,
}) => {
  const [open, toggleOpen] = useToggle(true);

  return (
    <DropDownWrapper>
      <ContentWrapper height={open ? `calc(100% - ${openHeight})` : '100%'}>{children}</ContentWrapper>
      <DropDownBlockWrapper id={dropDownId} height={open ? openHeight : 'auto'}>
        <HeaderDropDown onClick={toggleOpen}>
          <div>
            {title && (
              <PrimaryTextParagraph fontSize="14px" lineHeight="14px" color={`var(${ColorVarsEnum.Level_1})`} fontWeight="bold">
                {title}
              </PrimaryTextParagraph>
            )}
          </div>
          <FlexContainer gap="8px" alignItems="center">
            <IconWrapper Icon={() => <ArrowIcon isActive={open} />} />
            {description && (
              <PrimaryTextParagraph fontSize="12px" lineHeight="12px" color={`var(${ColorVarsEnum.Level_1})`}>
                {description}
              </PrimaryTextParagraph>
            )}
          </FlexContainer>
        </HeaderDropDown>
        {open && <BodyDropDown>{content}</BodyDropDown>}
      </DropDownBlockWrapper>
    </DropDownWrapper>
  );
};
