import styled from 'styled-components';
import { Rnd } from 'react-rnd';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ACTIVE_MODEL_TABLE_AREA_CLASS } from 'modules/workspace/constans';

export const StyledBoardAreaContainer = styled(Rnd)`
  background: ${`var(${ColorVarsEnum.Level_4_widget})`};
  border-radius: 3px;
  border: 1px solid var(${ColorVarsEnum.Level_4_widget});

  .point {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &.${ACTIVE_MODEL_TABLE_AREA_CLASS} {
    border: 1px solid var(${ColorVarsEnum.Accent});
    z-index: 10;

    .point {
      display: block;
      width: 5px !important;
      height: 5px !important;
      background: ${`var(${ColorVarsEnum.Accent})`};

      &:first-child,
      &:nth-child(3) {
        left: calc(50% - 2.5px) !important;
      }

      &:nth-child(2),
      &:nth-child(4) {
        top: calc(50% - 2.5px) !important;
      }

      &:first-child {
        top: -3px !important;
      }

      &:nth-child(2) {
        right: -3px !important;
      }

      &:nth-child(3) {
        bottom: -3px !important;
      }

      &:nth-child(4) {
        left: -3px !important;
      }

      &:nth-child(5) {
        top: -3px !important;
        right: -3px !important;
      }

      &:nth-child(6) {
        bottom: -3px !important;
        right: -3px !important;
      }

      &:nth-child(7) {
        bottom: -3px !important;
        left: -3px !important;
      }

      &:nth-child(8) {
        top: -3px !important;
        left: -3px !important;
      }
    }
  }
`;
