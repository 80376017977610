import { IdInterface, LoadingInterface } from 'types/store';
import { SourcesInterface } from 'store/reducers/sources/types';
import { AccessInterface } from 'types/types';
import { UsersGroupsListInterface } from 'store/reducers/projectManager/types';

export interface ActiveSourceInterface extends IdInterface {
  title: string;
}

export interface SourcesUsersAndGroupsInterface extends LoadingInterface {
  sourcesUsersAndGroupsList: AccessInterface[];
}

export interface AdminSourcesSliceStateInterface {
  sources: SourcesInterface;
  sourcesUsersAndGroups: SourcesUsersAndGroupsInterface;
  activeSource: ActiveSourceInterface | null;
  usersGroupsList: UsersGroupsListInterface;
}

/* Action Types */
export enum AdminSourcesActionsTypes {
  LOAD_ADMIN_SOURCES = 'LOAD_ADMIN_SOURCES',
  LOAD_ADMIN_SOURCES_USER_AND_GROUP = 'LOAD_ADMIN_SOURCES_USER_AND_GROUP',
  ACTIVE_SOURCE_ID = 'ACTIVE_SOURCE_ID',
  DELETE_SOURCE = 'DELETE_SOURCE',
  DELETE_BY_ID_SOURCE = 'DELETE_BY_ID_SOURCE',
  DELETE_BY_ID_SOURCE_ACCESS = 'DELETE_BY_ID_SOURCE_ACCESS',
  DELETE_GROUP_SOURCE_ACCESS_TYPE = 'DELETE_GROUP_SOURCE_ACCESS_TYPE',
  DELETE_USER_SOURCE_ACCESS_TYPE = 'DELETE_USER_SOURCE_ACCESS_TYPE',
  ADD_USER_GROUP_SOURCE_ACCESS = 'ADD_USER_GROUP_SOURCE_ACCESS',
  CHANGE_USER_SOURCE_ACCESS_TYPE = 'CHANGE_USER_SOURCE_ACCESS_TYPE',
  CHANGE_GROUP_SOURCE_ACCESS_TYPE = 'CHANGE_GROUP_SOURCE_ACCESS_TYPE',
  UPDATE_SOURCE_ACCESS = 'UPDATE_SOURCE_ACCESS',
  ADD_ADMIN_SOURCE_ACCESS = 'ADD_ADMIN_SOURCE_ACCESS',
}

/* Payload Interfaces */
export interface DeleteGroupSourceAccessByIdPayload {
  sourceId: string;
  groupId: string;
}

export interface DeleteUserSourceAccessByIdPayload {
  sourceId: string;
  userId: string;
}
