import { patternsForReplacement } from 'constants/global';
import isEqual from 'lodash/isEqual';
import { visualisationTypes } from 'modules/workspace/components/WorkAreaSpace/constants';
import { ColumnRef, OrderBy } from 'node-sql-parser';
import { createSelector } from 'reselect';
import { getAstOfSourceById, getAstOfVisualisationById } from 'store/reducers/ast/getters';
import { getActiveBoardElement } from 'store/reducers/board/getters';
import { getAstEnabledFiltersByParams } from 'store/reducers/filters/getters';
import { getPageIds } from 'store/reducers/projectPages/getters';
import { getDefaultModelId } from 'store/reducers/projectSettings/getters';
import {
  CodeEditorDataInterface,
  DefaultVisualisationOptionsType,
  getSqlRequestForGroupingRowTableInterface,
  SortConditionColorInterface,
  TableDataSettings,
  TableVisualisationType,
  TextVisualisationType,
  VisualisationType,
} from 'store/reducers/visualisations/types';
import { getState } from 'store/utils';
import { AST } from 'types/ast';
import { formatSql } from 'utils/SQL/formatSQL';
import {
  defaultSelectAST,
  generateUnionWhereIn,
  getColumnsWithoutSelect,
  getWhereString,
  sqlParser,
} from 'utils/SQL/genereteAst';
import { sortByYCoordinateFn } from 'utils/utils';
import { VisualisationAstInterface } from '../ast/types';
import { backgroundByValueAlias, colorValueByAlias } from './constants';

export const getVisualisationsStore = createSelector(getState, (state) => state.visualisations);

export const getAlreadyLoadedContent = createSelector(getVisualisationsStore, (state) => state.alreadyLoadedContent);

export const getVisualisations = createSelector(getVisualisationsStore, (state) => state.visualisations);

export const getServerStateOfVisualisations = createSelector(
  getVisualisationsStore,
  (state) => state.serverStateOfVisualisations,
);
export const getHasChangesOfVisualisations = createSelector(
  [getServerStateOfVisualisations, getVisualisations],
  (serverState, currentState) => (serverState === null ? false : !isEqual(serverState, currentState)),
);

export const getVisualisationIdsByPage = createSelector(getVisualisationsStore, (state) => state.visualisationsByPages);

export const getArrayVisualisations = createSelector(
  getVisualisations,
  (visualisations) => (Object.values(visualisations) as DefaultVisualisationOptionsType[]) || [],
);

export const getVisualisationById = (id: string) => createSelector(getVisualisations, (visualisations) => visualisations[id]);

export const getVisualisationIdsByPageId = (pageId: string) =>
  createSelector(getVisualisationIdsByPage, (pages) => pages[pageId]);

export const getVisualisationIdsByPageAsArray = (pageId?: string) =>
  createSelector(getState, (state) => Array.from(getVisualisationIdsByPageId(pageId || '')(state) || []));

export const getVisualisationsAlreadyLoaded = (pageId: string) =>
  createSelector(getAlreadyLoadedContent, (alreadyLoadedContent) => alreadyLoadedContent.visualisations.has(pageId));

export const getWidgetsByPageId = (pageId?: string) =>
  createSelector([getState, getVisualisationIdsByPageAsArray(pageId)], (state, visualisationIds) =>
    visualisationIds
      .reduce<DefaultVisualisationOptionsType[]>((result, id) => {
        const visualisation = getVisualisationById(id)(state);

        if (visualisation) {
          return [...result, visualisation];
        }

        return result;
      }, [])
      .sort(sortByYCoordinateFn),
  );

export const getActiveMediaBlocks = (pageId?: string) =>
  createSelector(getState, (state) => {
    const widgetsArray = getWidgetsByPageId(pageId)(state);

    return widgetsArray.filter(({ visualisationType }) => visualisationType === 'text') as TextVisualisationType[];
  });

export const getActiveVisualisations = (pageId?: string) =>
  createSelector(getState, (state) => {
    const visualisationsArray = getWidgetsByPageId(pageId)(state);

    return visualisationsArray.filter(({ visualisationType }) => visualisationType !== 'text');
  });

export const getVisualisationsLoadingList = createSelector(getVisualisationsStore, (state) => state.visualisationsLoadingList);
export const getVisualisationsErrorList = createSelector(getVisualisationsStore, (state) => state.visualisationsErrorsList);

export const isVisualisationLoading = (id: string) =>
  createSelector(getVisualisationsLoadingList, (visualisationsLoadingList) => !!visualisationsLoadingList[id]);

export const isVisualisationsLoading = createSelector(getVisualisationsStore, (state) => state.visualisationsLoading);

export const getActiveVisualisationSettings = createSelector([getState, getActiveBoardElement], (state, activeBoardElement) => {
  const activeVisualisation = activeBoardElement && getVisualisations(state)[activeBoardElement];

  if (activeVisualisation) {
    const { viewSettings, backgroundImagesSettings, dataSettings, sqlData, events, id, visualisationType, positionConfig } =
      activeVisualisation;

    return { viewSettings, backgroundImagesSettings, dataSettings, sqlData, events, id, visualisationType, positionConfig };
  }
});

export const getVisualisationData = createSelector(getVisualisationsStore, (state) => state.visualisationData);

export const getVisualisationDataById = (id: string) =>
  createSelector(getVisualisationData, (visualisationData) => visualisationData[id]);

export const getWidgetsByPages = createSelector([getState, getPageIds], (state, pageIds) =>
  pageIds.reduce((results, pageId) => {
    if (getVisualisationsAlreadyLoaded(pageId)(state)) {
      return [...results, { pageId, visualisations: getWidgetsByPageId(pageId)(state) }];
    }

    return results;
  }, [] as Array<{ pageId: string; visualisations: DefaultVisualisationOptionsType[] }>),
);

const orderByColumns = ({
  visualisation,
  astParts,
}: {
  visualisation?: DefaultVisualisationOptionsType;
  astParts: VisualisationAstInterface;
}) => {
  if (!visualisation) {
    return null;
  }
  const visual = visualisation as TableVisualisationType;

  const orderByColumnsTableIds = visual?.dataSettings?.orderBy.map((el) => el.columnName);
  const orderByColumnsTable: OrderBy[] = [...astParts.incisions, ...astParts.indicators]
    .filter((column) => column.as && orderByColumnsTableIds.includes(column.as))
    .map((column) => ({
      type: visual?.dataSettings?.orderBy.filter((el) => el.columnName === column.as)[0]?.type,
      expr: column.expr,
    }));

  return orderByColumnsTable;
};

const sortConditionColor = ({ dataSettings, alias, valueCondition }: SortConditionColorInterface) => {
  const tableDataSettings = dataSettings as TableDataSettings;
  let hasCondition = false;

  const allColumns = [...tableDataSettings.incisions, ...tableDataSettings.indicators],
    customWhere = allColumns.reduce<string>((total, column, index) => {
      const sqlCondition = column.settings?.properties?.[valueCondition]?.byCondition?.sqlCondition;
      if (sqlCondition) {
        hasCondition = true;
      }
      return total + `${sqlCondition || null}` + (index !== allColumns.length - 1 ? ', ' : '');
    }, '');

  return hasCondition
    ? [
        {
          as: alias,
          expr: {
            value: `array(${customWhere})`,
          },
        },
      ]
    : [];
};

/* AST */

export const getAstForSqlGenerationQueryById = (id: string) =>
  createSelector([getState, getDefaultModelId], (state, defaultModelId) => {
    const visualisation = getVisualisationById(id)(state),
      pageId = visualisation?.pageId,
      visualizationId = visualisation?.id || '',
      usingFilter = visualisation?.events?.isReactingToFilter,
      isInfluenceItself = visualisation?.events?.filterSettings?.isInfluenceItself,
      excludeVisualisationIds = isInfluenceItself ? [] : [visualisation?.id || ''],
      modelId = visualisation?.dataSettings?.modelId,
      modelIdValue = modelId || defaultModelId || '',
      isTableVisualisation = visualisation?.visualisationType === 'table',
      conditionBackgroundColor = isTableVisualisation
        ? sortConditionColor({
            dataSettings: visualisation.dataSettings,
            alias: backgroundByValueAlias,
            valueCondition: 'backgroundColorBy',
          })
        : [],
      conditionFontColor = isTableVisualisation
        ? sortConditionColor({
            dataSettings: visualisation.dataSettings,
            alias: colorValueByAlias,
            valueCondition: 'fontColorBy',
          })
        : [],
      astParts = getAstOfVisualisationById(id)(state),
      orderByColumnsTable = isTableVisualisation ? orderByColumns({ visualisation, astParts }) : null,
      whereAstData = usingFilter
        ? getAstEnabledFiltersByParams(pageId || '', modelIdValue, excludeVisualisationIds, visualizationId)(state)
        : [],
      from = getAstOfSourceById(modelIdValue)(state)?.ast || null;

    return {
      ...astParts,
      whereAstData: generateUnionWhereIn(whereAstData),
      from,
      modelIdValue,
      orderByColumnsTable,
      conditionBackgroundColor,
      conditionFontColor,
    };
  });

/* SQL String */

export const getSqlRequestById = createSelector(getState, (state) => (id: string) => {
  const {
    groupBy,
    indicators,
    incisions,
    whereAstData,
    serviceValues,
    variables,
    images,
    limit,
    from,
    orderByColumnsTable,
    activeIncisionIndex,
    conditionBackgroundColor,
    conditionFontColor,
    filtersAndGroups: { groupby, where, limit: filtersAndGroupLimit, orderby, having },
  } = getAstForSqlGenerationQueryById(id)(state);

  const incisionsColumns = activeIncisionIndex !== null ? [incisions[activeIncisionIndex]] : incisions;
  const columns = [...incisionsColumns, ...indicators, ...variables, ...images, ...serviceValues];
  const totalColumns = [...columns, ...conditionFontColor, ...conditionBackgroundColor];

  let sqlRequest: undefined | string;

  try {
    if (from !== null && columns.length) {
      const unionGroupBy = Array.isArray(groupby) ? [...groupBy, ...groupby] : groupBy,
        unionWhere = where ? generateUnionWhereIn([where, whereAstData].filter((where) => !!where)) : whereAstData,
        allOrderBy = Array.isArray(orderby) && Array.isArray(orderByColumnsTable) ? [...orderByColumnsTable, ...orderby] : null;

      sqlRequest = sqlParser.sqlify({
        ...defaultSelectAST,
        columns: totalColumns,
        groupby: unionGroupBy,
        where: unionWhere,
        limit: filtersAndGroupLimit || limit,
        from,
        orderby: allOrderBy || orderby || orderByColumnsTable,
        having,
      });

      sqlRequest = sqlRequest
        ? Object.entries(patternsForReplacement).reduce<string>(
            (value, [replacement, pattern]) => value.replace(pattern, replacement),
            sqlRequest,
          )
        : undefined;

      return sqlRequest;
    }
  } finally {
    return sqlRequest;
  }
});
export const getSqlRequestForGroupingRowTable = createSelector(
  getState,
  (state) =>
    ({
      id,
      columnNextIndex,
      parentsChain,
      limitData,
      incisionsInHeaderNames,
      isPivotTable,
    }: getSqlRequestForGroupingRowTableInterface) => {
      const {
        groupBy,
        indicators,
        incisions,
        whereAstData,
        serviceValues,
        variables,
        images,
        limit,
        from,
        orderByColumnsTable,
        conditionBackgroundColor,
        conditionFontColor,
        filtersAndGroups: { groupby, where, limit: filtersAndGroupLimit, orderby, having },
      } = getAstForSqlGenerationQueryById(id)(state);

      if (!incisions.length) {
        return;
      }

      const incisionsInColumns = incisions.filter((incision) => incision.as && !incisionsInHeaderNames.includes(incision.as));
      const incisionsInHeaders = !isPivotTable
        ? incisions.filter((incision) => incision.as && incisionsInHeaderNames.includes(incision.as))
        : [];
      const groupingIncisions = columnNextIndex ? incisionsInColumns.slice(0, columnNextIndex) : incisionsInColumns;
      groupingIncisions.push(...incisionsInHeaders);
      const GroupByForGroupTable: ColumnRef[] = columnNextIndex
        ? (groupingIncisions.map((incision) => incision.expr) as ColumnRef[])
        : groupBy;
      const indicatorsValues = !isPivotTable ? indicators : [];
      const columns = [...groupingIncisions, ...indicatorsValues, ...variables, ...images, ...serviceValues];
      const totalColumns = [...columns, ...conditionFontColor, ...conditionBackgroundColor];
      const orderByColumnsTableForGroup =
        orderByColumnsTable &&
        groupingIncisions &&
        orderByColumnsTable.filter((el) => {
          const columnsOrderBy = columns as unknown as OrderBy[];
          return columnsOrderBy
            .filter((incision) => !!incision.expr.column)
            .map((incision) => incision.expr.column)
            .includes(el.expr.column);
        });
      const customWhere =
        groupingIncisions && parentsChain
          ? generateUnionWhereIn(
              groupingIncisions.reduce<AST.WhereLike[]>((acc, incision, index) => {
                if (index < groupingIncisions.length - 1 && parentsChain[index]) {
                  const value = parentsChain[index];
                  acc.push({
                    type: 'binary_expr',
                    operator: '=',
                    left: incision.expr as ColumnRef,
                    right: { type: 'string', value: value ? parentsChain[index] : '' },
                  } as AST.WhereLike);
                }
                return acc;
              }, []),
            )
          : null;

      let sqlRequest: undefined | string;

      if (from !== null && columns.length) {
        const unionGroupBy = Array.isArray(groupby) ? [...GroupByForGroupTable, ...groupby] : GroupByForGroupTable,
          unionWhere = generateUnionWhereIn([where, whereAstData, customWhere].filter((where) => !!where)),
          allOrderBy =
            Array.isArray(orderby) && Array.isArray(orderByColumnsTableForGroup)
              ? [...orderByColumnsTableForGroup, ...orderby]
              : null;

        sqlRequest = sqlParser.sqlify({
          ...defaultSelectAST,
          columns: totalColumns,
          groupby: unionGroupBy,
          where: unionWhere,
          limit: limitData || filtersAndGroupLimit || limit,
          from,
          orderby: allOrderBy || orderByColumnsTableForGroup || orderby,
          having,
        });

        sqlRequest = sqlRequest
          ? Object.entries(patternsForReplacement).reduce<string>(
              (value, [replacement, pattern]) => value.replace(pattern, replacement),
              sqlRequest,
            )
          : undefined;

        return sqlRequest;
      }
    },
);

export const getSqlRequestForHeaderTable = createSelector(
  getState,
  (state) =>
    ({ id, incisionsInHeaderNames, limitData }: getSqlRequestForGroupingRowTableInterface) => {
      const {
        incisions,
        whereAstData,
        limit,
        orderByColumnsTable,
        from,
        filtersAndGroups: { groupby, where, limit: filtersAndGroupLimit, orderby, having },
      } = getAstForSqlGenerationQueryById(id)(state);

      const incisionsInHeaders = incisions.filter((incision) => incision.as && incisionsInHeaderNames.includes(incision.as));

      if (!incisionsInHeaders.length) {
        return;
      }
      const GroupByIncisionsInHeader = incisionsInHeaders.map((incision) => incision.expr) as ColumnRef[];
      const columns = incisionsInHeaders;
      const orderByColumnsTableForGroup =
        orderByColumnsTable &&
        orderByColumnsTable.filter((el) => {
          const columnsOrderBy = columns as unknown as OrderBy[];
          return columnsOrderBy
            .filter((incision) => !!incision.expr.column)
            .map((incision) => incision.expr.column)
            .includes(el.expr.column);
        });

      let sqlRequest: undefined | string;

      if (from !== null && columns.length) {
        const unionGroupBy = Array.isArray(groupby) ? [...GroupByIncisionsInHeader, ...groupby] : GroupByIncisionsInHeader;
        const unionWhere = generateUnionWhereIn([where, whereAstData].filter((where) => !!where));
        const allOrderBy =
          Array.isArray(orderby) && Array.isArray(orderByColumnsTableForGroup)
            ? [...orderByColumnsTableForGroup, ...orderby]
            : null;

        sqlRequest = sqlParser.sqlify({
          ...defaultSelectAST,
          columns,
          groupby: unionGroupBy,
          where: unionWhere,
          limit: limitData || filtersAndGroupLimit || null,
          from,
          orderby: allOrderBy || orderByColumnsTableForGroup || orderby,
          having,
        });

        sqlRequest = sqlRequest
          ? Object.entries(patternsForReplacement).reduce<string>(
              (value, [replacement, pattern]) => value.replace(pattern, replacement),
              sqlRequest,
            )
          : undefined;

        return sqlRequest;
      }
    },
);

export const getAllSQLRequestsByIdForExport = createSelector(getState, (state) => (id: string) => {
  const visualisation = id && getVisualisationById(id)(state);

  if (!visualisation) {
    return;
  }

  const { viewSettings, dataSettings, visualisationType } = visualisation;

  const nameFile = viewSettings?.header?.isShow ? viewSettings?.header?.text : undefined;

  const { isRealData } = dataSettings;

  const isWidgetTableData = visualisationType ? visualisationTypes.includes(visualisationType as VisualisationType) : false;

  if (!isWidgetTableData || !isRealData) {
    return;
  }

  const {
    indicators,
    incisions,
    whereAstData,
    limit,
    from,
    modelIdValue,
    filtersAndGroups: { where, limit: filtersAndGroupLimit, orderby, having },
  } = getAstForSqlGenerationQueryById(id)(state);

  const columns = [...incisions, ...indicators];

  const unionWhere = where ? generateUnionWhereIn([where, whereAstData].filter((where) => !!where)) : whereAstData;

  if (from !== null && columns.length > 0) {
    let sqlRequest: string = sqlParser.sqlify({
      ...defaultSelectAST,
      columns,
      groupby: incisions.map((incision) => incision.expr) as ColumnRef[],
      where: unionWhere,
      limit: filtersAndGroupLimit || limit,
      from,
      orderby,
      having,
    });

    sqlRequest = Object.entries(patternsForReplacement).reduce<string>(
      (value, [replacement, pattern]) => value.replace(pattern, replacement),
      sqlRequest,
    );

    return { queries: { name: 'data', query: sqlRequest, modelId: modelIdValue }, nameFile };
  }
});

export const getWhereQueryById = createSelector(getState, (state) => (id: string) => {
  const { whereAstData } = getAstForSqlGenerationQueryById(id)(state);

  let whereQuery: undefined | string;

  try {
    whereQuery = getWhereString(whereAstData);
  } finally {
    return whereQuery;
  }
});

export const getCodeEditorDataById = (id: string) =>
  createSelector(getState, (state) => {
    const { indicators, incisions } = getAstForSqlGenerationQueryById(id)(state),
      visualisation = getVisualisationById(id)(state);

    const filterAndGroupSqlString = visualisation ? visualisation?.sqlData?.filterAndGroupRequest : undefined;

    let indicatorSqlString: string | undefined, incisionSqlString: string | undefined;

    try {
      indicatorSqlString = getColumnsWithoutSelect(indicators);
      incisionSqlString = getColumnsWithoutSelect(incisions);
    } finally {
      return {
        indicatorSqlString: formatSql(indicatorSqlString),
        incisionSqlString: formatSql(incisionSqlString),
        filterAndGroupSqlString: formatSql(filterAndGroupSqlString),
      } as CodeEditorDataInterface;
    }
  });
