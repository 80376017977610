import { FilterContainer } from 'modules/workspace/components/FilterContainer';
import { DateEnabledFilterInterface } from 'store/reducers/filters/types';
import React from 'react';
import { EnabledFilterViewInterface } from 'modules/workspace/components/FilterRow/types';
import { getDateByFormat, getStringDateByFormat } from 'utils/dates';
import { byTypeFormat, defaultDateFormat } from 'constants/dates';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';

export const DateEnabledFilter: EnabledFilterViewInterface<DateEnabledFilterInterface> = ({
  onDelete,
  selectedValues: { startDate, endDate, byType },
}) => {
  const start = getDateByFormat(startDate, defaultDateFormat),
    end = getDateByFormat(endDate, defaultDateFormat),
    format = byTypeFormat[byType];

  return (
    <FilterContainer onDelete={onDelete}>
      <PrimaryTextSpan
        cursor="default"
        lineHeight="14px"
        fontSize="14px"
        color={`var(${ColorVarsEnum.Level_1})`}
        whiteSpace="nowrap"
      >
        {byType === 'byToday'
          ? getStringDateByFormat(new Date(), format)
          : `${getStringDateByFormat(start, format)} — ${getStringDateByFormat(end, format)}`}
      </PrimaryTextSpan>
    </FilterContainer>
  );
};
