import styled, { css } from 'styled-components';
import { TransitionStatus } from 'react-transition-group';

interface LoaderWrapperProps {
  transitionDuration: number;
  transitionStatus: TransitionStatus;
}

const transitionStyles: Record<TransitionStatus, ReturnType<typeof css>> = {
  entering: css`
    opacity: 1;
  `,
  entered: css`
    opacity: 1;
  `,
  exiting: css`
    opacity: 0;
  `,
  exited: css`
    opacity: 0;
  `,
  unmounted: css`
    opacity: 0;
  `,
};

export const LoaderWrapper = styled.div<LoaderWrapperProps>`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1000;
  transition: ${({ transitionDuration }) => `opacity ${transitionDuration}ms ease-in-out;`};

  ${({ transitionStatus }) => transitionStyles[transitionStatus]}
`;
