import { useSelector } from 'react-redux';
import { MouseEventHandler, useCallback, useState } from 'react';
import { ControlsInterface } from 'modules/settingsContainer/common/data/MetricSettings/types';
import { getModelMetaById, getTableColumnsByModelId } from 'store/reducers/models/getters';
import { useModelIdValue } from 'utils/hooks/visualisation/modelIdValue';

export const useDataSettingsMetric = (modelId?: string | null) => {
  const modelIdValue = useModelIdValue(modelId || '');

  const tableFields = useSelector(getTableColumnsByModelId(modelIdValue));
  const modelMetaData = useSelector(getModelMetaById(modelIdValue));

  const [activeMetricId, setActiveMetricId] = useState<string | null>(null);
  const [selectedMetricId, setSelectedMetricId] = useState<string | null>(null);

  const controls: ControlsInterface = {
    activeMetricId: { value: activeMetricId, onChange: setActiveMetricId },
    selectedMetricId: { value: selectedMetricId, onChange: setSelectedMetricId },
  };

  const onSetupClick: (id: string) => MouseEventHandler<HTMLButtonElement> = useCallback(
    (id) => (e) => {
      e.stopPropagation();
      setActiveMetricId(activeMetricId !== id ? id : null);
    },
    [activeMetricId],
  );

  const onMetricClick = useCallback(
    (id: string) => () => {
      setSelectedMetricId(id);
      activeMetricId && setActiveMetricId(id);
    },
    [activeMetricId],
  );

  const setupIsOpen = useCallback((id: string) => id === activeMetricId, [activeMetricId]);
  const metricIsSelected = useCallback((id: string) => id === selectedMetricId, [selectedMetricId]);

  return {
    tableFields,
    controls,
    activeMetricId,
    selectedMetricId,
    onSetupClick,
    onMetricClick,
    setupIsOpen,
    metricIsSelected,
    modelMetaData,
  };
};
