import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialVisualSettingsStoreState } from 'store/reducers/visualSettings/constants';
import { VisualSettingsComponentType } from 'store/reducers/visualSettings/types';

export const visualSettingsSlice = createSlice({
  name: 'visualSettings',
  initialState: initialVisualSettingsStoreState,
  reducers: {
    setSettingComponent: (state, { payload: SettingComponent }: PayloadAction<VisualSettingsComponentType>) => {
      state.SettingComponent = SettingComponent;
    },
  },
});

export const { setSettingComponent } = visualSettingsSlice.actions;

export default visualSettingsSlice.reducer;
