import { OnValueChange } from 'types/global';
import { FlexContainer } from 'styles/FlexContainer';
import React from 'react';
import { Color } from 'modules/settingsContainer/ColorPicker/styles';
import TextField from 'modules/ui/TextField';

interface HexColorEditorProps extends OnValueChange<string> {
  colorSize?: number;
}

export const HexColorEditor = ({ value, onChange, colorSize = 25 }: HexColorEditorProps) => (
  <FlexContainer width="100%" alignItems="center" gap="10px">
    <Color colorSize={colorSize} color={value} />
    <TextField
      needBackground={false}
      needBorderBottom={false}
      width="auto"
      name="hex_color"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      useDebounce
    />
  </FlexContainer>
);
