import styled from 'styled-components';

interface BoxModalProps {
  width?: string;
  maxHeight?: string;
  height?: string;
}

export const BoxModal = styled.div<BoxModalProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  width: ${({ width }) => width || '90vw'};
  height: ${({ height }) => height || '100%'};
  max-height: ${({ maxHeight }) => maxHeight || '90vw'};
`;
