import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import { TState } from 'store/index';
import { addActiveDirector, addActiveDirectorInfo, deleteActiveDirectorById, updateActiveDirectories } from '.';
import { IdInterface } from 'types/store';
import {
  createActiveDirector,
  deleteActiveDirector,
  loadActiveDirector,
  loadActiveDirectories,
  loadActiveDirectorInfo,
  loadConnectorUsersGroups,
  synchronize,
  updateActiveDirector,
} from 'store/reducers/adminActiveDirectories/api';
import { AdminActiveDirectoriesInterface, AdminActiveDirectoriesTypes } from 'store/reducers/adminActiveDirectories/types';
import { getAdminActiveDirectoriesData } from 'store/reducers/adminActiveDirectories/getters';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');

  return rejectWithValue(errorMessage);
};

export const loadActiveDirectoriesAction = createAsyncThunk<
  FastBoard.API.ApiCronJobListItemWithIdDTO[],
  void,
  { rejectValue: null }
>(AdminActiveDirectoriesTypes.LOAD_ADMIN_DIRECTORIES, async (_, { rejectWithValue }) => {
  try {
    const response = await loadActiveDirectories();
    return response.data.cronjobWithIdList;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return {} as FastBoard.API.ApiCronJobListItemWithIdDTO[];
  }
});

export const loadConnectorUsersGroupsAction = createAsyncThunk<
  FastBoard.API.ApiAdminUserGroupListItemResponseDTO[],
  IdInterface,
  { rejectValue: null }
>(AdminActiveDirectoriesTypes.LOAD_CONNECTOR_USERS_GROUP, async ({ id }, { rejectWithValue }) => {
  try {
    const response = await loadConnectorUsersGroups(id);
    return response.data.adminUserGroupList;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as FastBoard.API.ApiAdminUserGroupListItemResponseDTO[];
  }
});

export const loadActiveDirectorAction = createAsyncThunk<FastBoard.API.AdDTO, IdInterface, { rejectValue: null }>(
  AdminActiveDirectoriesTypes.LOAD_ACTIVE_DIRECTOR,
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await loadActiveDirector(id);
      return response.data.adminAd;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return {} as FastBoard.API.AdDTO;
    }
  },
);

export const loadActiveDirectorInfoAction = createAsyncThunk<
  FastBoard.API.ApiCronJobListItemWithIdDTO,
  IdInterface,
  { rejectValue: null }
>(AdminActiveDirectoriesTypes.LOAD_ADMIN_DIRECTOR_INFO, async ({ id }, { rejectWithValue }) => {
  try {
    const response = await loadActiveDirectorInfo(id);
    return response.data.cronjobItemWithId;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return {} as FastBoard.API.ApiCronJobListItemWithIdDTO;
  }
});

export const synchronizeAction = createAsyncThunk<string, IdInterface>(
  AdminActiveDirectoriesTypes.SYNCHRONIZE,
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await synchronize(id);
      Snackbar.show('Начало синхронизации', 'success');

      return response.data.adminAdSync;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const createActiveDirectorAction = createAsyncThunk<
  FastBoard.API.AdDTO,
  FastBoard.API.ApiAdminAdCreateDTO,
  { rejectValue: null }
>(
  AdminActiveDirectoriesTypes.CREATE_ACTIVE_DIRECTOR,
  async ({ isActive, name, cron, login, url, filterUsers, filterGroups, baseDN, newUsers, password }, { rejectWithValue }) => {
    try {
      const response = await createActiveDirector({
        isActive,
        name,
        cron,
        login,
        url,
        filterUsers,
        filterGroups,
        baseDN,
        newUsers,
        password,
      });
      Snackbar.show('Активная директория создан', 'success');

      return response.data.adminAd;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const addActiveDirectorAction = createAsyncThunk<void, AdminActiveDirectoriesInterface>(
  AdminActiveDirectoriesTypes.ADD_ADMIN_DIRECTOR,
  (data, { dispatch }) => {
    dispatch(addActiveDirector(data));
  },
);

export const addActiveDirectorInfoAction = createAsyncThunk<void, AdminActiveDirectoriesInterface | null>(
  AdminActiveDirectoriesTypes.ADD_ADMIN_DIRECTOR_INFO,
  (data, { dispatch }) => {
    dispatch(addActiveDirectorInfo(data));
  },
);

export const updateActiveDirectorAction = createAsyncThunk<FastBoard.API.AdDTO, FastBoard.API.ApiAdminAdUpdateDTO & IdInterface>(
  AdminActiveDirectoriesTypes.UPDATE_ADMIN_DIRECTOR,
  async (
    { id, isActive, baseDN, cron, filterGroups, filterUsers, newUsers, login, name, url, password },
    { rejectWithValue },
  ) => {
    try {
      const response = await updateActiveDirector({
        id,
        isActive,
        baseDN,
        cron,
        filterGroups,
        filterUsers,
        newUsers,
        login,
        name,
        url,
        password,
      });
      Snackbar.show('Активная директория изменен', 'success');

      return response.data.adminAd;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateActiveDirectoriesAction = createAsyncThunk<void, { activeDirector: AdminActiveDirectoriesInterface }>(
  AdminActiveDirectoriesTypes.UPDATE_ADMIN_DIRECTORIES,
  ({ activeDirector }, { dispatch, getState }) => {
    const activeDirectories = getAdminActiveDirectoriesData(getState() as TState).adminActiveDirectoriesList.map((value) =>
      value.id === activeDirector.id ? { ...value, ...activeDirector } : value,
    );

    dispatch(updateActiveDirectories(activeDirectories));
  },
);

export const deleteActiveDirectorAction = createAsyncThunk<string, string, { rejectValue: null }>(
  AdminActiveDirectoriesTypes.DELETE_ADMIN_DIRECTOR,
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteActiveDirector(id);
      Snackbar.show('Удалено', 'success');

      return response;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const deleteActiveDirectorByIdAction = createAsyncThunk(
  AdminActiveDirectoriesTypes.DELETE_ADMIN_DIRECTOR_BY_ID,
  (id: string, { dispatch }) => {
    dispatch(deleteActiveDirectorById({ id }));
  },
);
