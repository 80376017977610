import { useMemo } from 'react';
import { getActiveIncisionById, getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import { ActiveIncisionIdInterface, DefaultIncisionInterface } from 'store/reducers/visualisations/types';

interface ActiveIncisionProps<Incision extends DefaultIncisionInterface> extends ActiveIncisionIdInterface {
  defaultIncision: Incision;
  incisions: Incision[];
}

export const useActiveIncision = <Incision extends DefaultIncisionInterface>({
  incisions,
  activeIncisionId,
  defaultIncision,
}: ActiveIncisionProps<Incision>) => {
  return useMemo(() => {
    const activeIncision = getActiveIncisionById(activeIncisionId, incisions) || defaultIncision;

    const {
      name,
      fieldName,
      settings: { nameFromDatabase },
    } = activeIncision;

    return { fieldName: getVisualisationFieldName({ name, fieldName, nameFromDatabase }), data: activeIncision as Incision };
  }, [activeIncisionId, defaultIncision, incisions]);
};
