import React, { useCallback, JSX, useMemo } from 'react';
import { FC, NoopValueType } from 'types/global';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { FictionalMetricField } from 'modules/settingsContainer/common/FictionalMetricField';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FunctionIcon } from 'assets/icons/forDelete';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { useModalState } from 'utils/hooks/modalState';
import { useLocalValues } from 'utils/hooks/localValues';
import { formatSql } from 'utils/SQL/formatSQL';
import { ModalCustomRequest } from '../common/data/ModalCustomRequest';
import { ISelectField } from 'interface';
import { DateFilterInterface, SingleFilterInterface } from 'store/reducers/filters/types';
import { FieldNameInterface, FilterElementMode, NameFromDatabaseInterface } from 'types/store';
import { ModelFromMetaType } from 'store/reducers/models/types';
import { FlexContainer } from 'styles/FlexContainer';
import { FilterSqlModal } from '../common/data/FilterSqlModal';
import { useFullSize } from 'utils/hooks/fullSize';
import { LocalValuesInterface } from '../common/data/SqlFilterSettings/types';
import { FilterElementsInterface } from './types';

interface FilterIncisionsSettingsProps {
  options: ISelectField[];
  value: { fictionalData: string[]; nameSettings: NameFromDatabaseInterface & FieldNameInterface & FilterElementMode };
  onChange: NoopValueType<FilterElementsInterface>;
  onChangeSqlData: NoopValueType<{
    incisionRequest: string;
    filterAndGroupRequest: string;
  }>;
  disabled?: boolean;
  isRealData: boolean;
  modelMetaData: ModelFromMetaType[];
  data: SingleFilterInterface | DateFilterInterface;
  children?: JSX.Element;
}

export const FilterIncisionsSettings: FC<FilterIncisionsSettingsProps> = ({
  isRealData,
  value,
  onChange,
  options,
  data,
  onChangeSqlData,
  modelMetaData,
  children,
}) => {
  const {
    fictionalData,
    nameSettings,
    sqlData: { incisionRequest, filterAndGroupRequest },
  } = data;
  const { fieldName } = nameSettings;
  const { isOpen, onOpen, onClose } = useModalState();
  const { currentFullSize, onResizeChange } = useFullSize();

  const defaultRequests = useMemo(
    () => ({
      incisionRequest,
      filterAndGroupRequest,
    }),
    [incisionRequest, filterAndGroupRequest],
  );

  const { localValues, setLocalValues, onSave, onCancel, hasChanges } = useLocalValues({
    value: defaultRequests,
    onChange: onChangeSqlData,
  });

  const isSqlExpression = !!incisionRequest.length;

  const formatSqlValues = () => {
    const keysToFormat: Array<keyof LocalValuesInterface> = ['incisionRequest', 'filterAndGroupRequest'];
    const formattedValues: LocalValuesInterface = {};

    Object.entries(localValues).forEach(([key, value]) => {
      if (keysToFormat.includes(key as keyof LocalValuesInterface) && value) {
        formattedValues[key as keyof LocalValuesInterface] = formatSql(value);
      }
    });

    return formattedValues;
  };

  const onFormatClick = () => {
    const { incisionRequest, filterAndGroupRequest } = formatSqlValues();

    setLocalValues({
      incisionRequest: incisionRequest || '',
      filterAndGroupRequest: filterAndGroupRequest || '',
    });
  };

  const incisionActiveValue = isSqlExpression
    ? { fictionalData, fieldName: 'Выражение' }
    : { fictionalData, fieldName: fieldName };

  const onSaveEditor = () => {
    const { incisionRequest: localIncisionRequest, filterAndGroupRequest: localFilterRequest } = localValues;

    if (localIncisionRequest && localFilterRequest) {
      const formattedLocalIncisionRequest = formatSql(localIncisionRequest);
      const formattedLocalFilterRequest = formatSql(localFilterRequest);

      formattedLocalIncisionRequest &&
        formattedLocalFilterRequest &&
        onChangeSqlData({ incisionRequest: formattedLocalIncisionRequest, filterAndGroupRequest: formattedLocalFilterRequest });
    }

    onSave();
    onClose();
  };

  const onOpenEditor = () => {
    fieldName && onOpen();
  };

  const onCloseEditor = useCallback(() => {
    onCancel();
    onClose();
  }, [onCancel, onClose]);

  return (
    <MainContainerSettings titleText="Элементы фильтра">
      <FlexContainer alignItems="center" gap="4px">
        <IconWrapper
          hoverColorVar={ColorVarsEnum.Level_1}
          colorVar={
            localValues.filterAndGroupRequest || localValues.incisionRequest ? ColorVarsEnum.Accent : ColorVarsEnum.Level_3
          }
          containerWidth="20px"
          containerHeight="20px"
          iconHeight="16px"
          iconWidth="16px"
          onClick={onOpenEditor}
          Icon={FunctionIcon}
        />
        <FictionalMetricField
          onChange={({ fictionalData, fieldName }) => {
            fictionalData && onChange({ ...value, fictionalData });
            fieldName && onChange({ ...value, nameSettings: { ...nameSettings, fieldName } });
          }}
          value={incisionActiveValue}
          isRealData={isRealData}
          options={options}
          disabled={isSqlExpression}
          isFunctionButtonHide
        />
      </FlexContainer>
      {children && isRealData && <FlexContainer width="100%">{children}</FlexContainer>}
      <FilterSqlModal
        isOpen={isOpen}
        onCloseEditor={onCloseEditor}
        localValues={localValues}
        setLocalValues={setLocalValues}
        currentFullSize={currentFullSize}
        onResizeChange={onResizeChange}
        onFormatClick={onFormatClick}
        hasChanges={hasChanges}
        modelMetaData={modelMetaData}
        onSaveEditor={onSaveEditor}
      />
    </MainContainerSettings>
  );
};
