import { LegendPositionAtGridType, PieNode } from 'modules/visualisations/Pie/visualisation/types';
import {
  ActiveIncisionIdInterface,
  PieDataSettings,
  PieIndicatorLabelFormatType,
  PieIndicatorValueFormatType,
  VisualisationValuesInterface,
} from 'store/reducers/visualisations/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { getActiveIncisionById, getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import {
  DICTIONARY_OF_MONTH_LONG,
  DICTIONARY_OF_MONTH_SHORT,
  DICTIONARY_OF_QUARTER_LONG,
  DICTIONARY_OF_QUARTER_SHORT,
  DICTIONARY_OF_WEEK_LONG,
  DICTIONARY_OF_WEEK_SHORT,
} from 'constants/Mock';
import { GROUP_BY_LONG_OR_SHORT } from 'modules/settingsContainer/common/data/GroupByDateSettings/constants';
import { updatePieDataSettingsById } from 'store/reducers/visualisations/actions';
import { store } from 'store';
import { IdInterface } from 'types/store';
import { ThemeColorsInterface } from 'store/reducers/themes/types';

export const getPieMapData: (dataSettings: PieDataSettings, visualisationValues: VisualisationValuesInterface) => PieNode[] = (
  dataSettings,
  visualisationValues,
) => {
  const { isRealData, activeIncisionId, incisions } = dataSettings;

  const activeIncision = getActiveIncisionById(activeIncisionId, incisions);
  const mainIndicator = dataSettings.indicators?.[0];

  if (activeIncision === null || !mainIndicator) return [];

  const {
    name,
    fieldName,
    settings: {
      nameFromDatabase,
      emptyValues: { isEmptyValue },
    },
  } = activeIncision;

  const incisionFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

  const indicatorFieldName = getVisualisationFieldName({
    nameFromDatabase: mainIndicator.settings.nameFromDatabase,
    name: mainIndicator.name,
    fieldName: mainIndicator.fieldName,
  });

  const incisionData = visualisationValues[incisionFieldName] || [],
    indicatorData = visualisationValues[indicatorFieldName] || [];

  const pairedData = incisionData.map((value, index) => {
    return { incisionValue: value, indicatorValue: indicatorData[index] || 0 };
  });

  const filteredPairedData = pairedData.filter(({ incisionValue }) => {
    if (isEmptyValue) {
      return true;
    }
    return incisionValue !== null && incisionValue !== '';
  });

  if (isRealData && !(incisionData.length && indicatorData.length)) {
    return [];
  }

  return filteredPairedData.map(({ incisionValue, indicatorValue }) => {
    const value = typeof indicatorValue === 'string' ? 1 : indicatorValue;

    return {
      name: String(incisionValue),
      value,
      tableColumn: fieldName,
    };
  });
};

export const getPieLegendData: (dataSettings: PieDataSettings, visualisationValues: VisualisationValuesInterface) => string[] = (
  dataSettings,
  visualisationValues,
) => {
  const { isRealData, activeIncisionId, incisions } = dataSettings;

  const activeIncision = getActiveIncisionById(activeIncisionId, incisions);

  if (activeIncision == null) {
    return [];
  }

  const {
    name,
    fieldName,
    settings: {
      nameFromDatabase,
      emptyValues: { isEmptyValue },
      groupByDateSettings: { isShow, groupByType, groupByMask },
    },
  } = activeIncision;

  const indicatorFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

  const valuesData = ((visualisationValues[indicatorFieldName] || []) as string[]).map((el) => String(el));

  const incisionDataFilter = (isEmptyValue ? (valuesData as string[])?.filter((value) => !!value) : valuesData) as string[];

  if (isShow && isRealData) {
    if (groupByType === 'dayOfWeek') {
      if (groupByMask === 'long' || !GROUP_BY_LONG_OR_SHORT.find((el) => el.value === groupByMask)) {
        return incisionDataFilter.map((el: string) => DICTIONARY_OF_WEEK_LONG[parseInt(el)]);
      } else {
        return incisionDataFilter.map((el: string) => DICTIONARY_OF_WEEK_SHORT[parseInt(el)]);
      }
    }

    if (groupByType === 'month') {
      if (groupByMask === 'long' || !GROUP_BY_LONG_OR_SHORT.find((el) => el.value === groupByMask)) {
        return incisionDataFilter.map((el: string) => DICTIONARY_OF_MONTH_LONG[parseInt(el)]);
      } else {
        return incisionDataFilter.map((el: string) => DICTIONARY_OF_MONTH_SHORT[parseInt(el)]);
      }
    }

    if (groupByType === 'quarter') {
      if (groupByMask === 'long' || !GROUP_BY_LONG_OR_SHORT.find((el) => el.value === groupByMask)) {
        return incisionDataFilter.map((el: string) => DICTIONARY_OF_QUARTER_LONG[parseInt(el)]);
      } else {
        return incisionDataFilter.map((el: string) => DICTIONARY_OF_QUARTER_SHORT[parseInt(el)]);
      }
    }
  }

  return incisionDataFilter;
};

export const defaultColors = (activeThemeSchema: ThemeColorsInterface) => [
  activeThemeSchema[ColorVarsEnum.Level_3],
  activeThemeSchema[ColorVarsEnum.Level_4],
  activeThemeSchema[ColorVarsEnum.Level_5],
];

export const legendPositionAtGrid: LegendPositionAtGridType = {
  top: ['50%', '55%'],
  bottom: ['50%', '45%'],
  left: ['65%', '50%'],
  right: ['35%', '50%'],
  default: ['50%', '50%'],
};

export const getFormatSeries = (labelFormat: PieIndicatorLabelFormatType[], valueFormat: PieIndicatorValueFormatType) => {
  let format = '';

  if (labelFormat.includes('name')) {
    format = '{b}';
  }

  if (labelFormat.includes('value')) {
    if (labelFormat.includes('name')) {
      format += ' - ';
    }

    format += valueFormat === 'absolute' ? '{c}' : '{d}%';
  }

  return format;
};

const dispatch = store.dispatch;

export const onActiveIncisionIdChange = ({ id, activeIncisionId }: ActiveIncisionIdInterface & IdInterface) =>
  dispatch(updatePieDataSettingsById({ dataSettings: { activeIncisionId }, id }));
