import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import { defaultPieViewSettings } from 'store/reducers/visualisations/constants';
import { DefaultViewSettings } from 'modules/settingsContainer/common/view/DefaultViewSettings';
import { BooleanTipsSettings } from 'modules/settingsContainer/common/view/BooleanTipsSettings';
import { ExtendedSettings } from 'modules/settingsContainer/common/view/ExtendedSettings';
import { onIncisionSelectorPositionChange, onLegendSettingsChange, onShowTipsChange } from './constants';
import { IncisionsSelectorPositionSettings } from 'modules/settingsContainer/common/IncisionsSelectorPositionSettings';
import { showLegendTitle } from 'modules/settingsContainer/common/view/LegendSettings/constants';
import { onVisibleIncisionSelectorChange } from '../../../Heatmap/settings/ViewTab/constants';

export const ViewTab = () => {
  const { viewSettings, positionConfig } = useGetActiveVisualisationSettings({
    defaultView: defaultPieViewSettings,
  });

  return (
    <>
      <DefaultViewSettings
        positionConfig={positionConfig}
        SecondSlot={
          <>
            <IncisionsSelectorPositionSettings
              value={viewSettings.incisionSelectorPosition}
              onChange={onIncisionSelectorPositionChange}
              onChangeVisible={onVisibleIncisionSelectorChange}
              isVisible={viewSettings.isVisible}
            />
            <ExtendedSettings title={showLegendTitle} value={viewSettings.legendSettings} onChange={onLegendSettingsChange} />
          </>
        }
        ThirdSlot={<BooleanTipsSettings value={viewSettings.showTips} onChange={onShowTipsChange} />}
        viewSettings={viewSettings}
      />
    </>
  );
};
