import {
  ContentWrapper,
  DefaultViewSettingsWrapper,
  VisualisationContainer,
} from 'modules/visualisations/components/VisualisationLayout/styles';
import { FC } from 'types/global';
import { DefaultVisualisationOptionsType } from 'store/reducers/visualisations/types';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import React, { useMemo, useRef } from 'react';
import { useVisualisationEvents } from 'modules/visualisations/hooks/visualisationEvents';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { InfoIcon } from 'assets/icons/withContainer';
import { useResizeObserver } from 'utils/hooks/resizeObserver';
import { defaultViewWrapperMarginBottom } from 'modules/visualisations/components/VisualisationLayout/constants';

interface VisualisationLayoutProps {
  data: DefaultVisualisationOptionsType;
}

export const VisualisationLayout: FC<VisualisationLayoutProps> = ({
  children,
  data: {
    viewSettings: { header, description, headerPosition, hint },
    events,
  },
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const viewSettingsRef = useRef<HTMLDivElement | null>(null);

  const [, containerHeight] = useResizeObserver({
    ref: containerRef,
  });
  const [, viewSettingsHeight] = useResizeObserver({
    ref: viewSettingsRef,
  });

  const { onClick } = useVisualisationEvents(events);

  const isDefaultViewSettings = header && description && headerPosition && hint;

  const isShowHeader = !!header?.isShow,
    isShowDescription = !!description?.isShow,
    isShowHint = !!hint?.isShow;

  const isDefaultViewVisible = isShowHeader || isShowDescription || isShowHint;

  const contentHeight = useMemo(() => {
    const height = containerHeight - viewSettingsHeight - (isDefaultViewVisible ? parseInt(defaultViewWrapperMarginBottom) : 0);

    return height > 0 ? height : 0;
  }, [containerHeight, viewSettingsHeight, isDefaultViewVisible]);

  if (!isDefaultViewSettings) {
    return (
      <VisualisationContainer onClick={onClick} ref={containerRef}>
        <ContentWrapper height={contentHeight}>{children}</ContentWrapper>
      </VisualisationContainer>
    );
  }

  return (
    <VisualisationContainer ref={containerRef} onClick={onClick} padding="16px">
      <DefaultViewSettingsWrapper isVisible={isDefaultViewVisible} ref={viewSettingsRef}>
        <FlexContainer overflow="hidden" flexDirection="column" alignItems={headerPosition} flex="1 1 0">
          {isShowHeader && (
            <FlexContainer width="fit-content">
              <PrimaryTextSpan fontSize="16px" lineHeight="21px" color={`var(${ColorVarsEnum.Level_1})`} fontWeight="bold">
                {header.text}
              </PrimaryTextSpan>
            </FlexContainer>
          )}
          {isShowDescription && (
            <FlexContainer width="fit-content">
              <PrimaryTextSpan fontSize="14px" lineHeight="24px" color={`var(${ColorVarsEnum.Level_2})`}>
                {description.text}
              </PrimaryTextSpan>
            </FlexContainer>
          )}
        </FlexContainer>
        <FlexContainer>
          {isShowHint && (
            <FlexContainer>
              <StyledTooltip title={hint.text}>
                <InfoIcon />
              </StyledTooltip>
            </FlexContainer>
          )}
        </FlexContainer>
      </DefaultViewSettingsWrapper>
      <ContentWrapper height={contentHeight}>{children}</ContentWrapper>
    </VisualisationContainer>
  );
};
