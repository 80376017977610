import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button } from 'modules/ui';
import { ModalComponentType } from 'store/reducers/modals/types';
import { loadFilesPathAction } from 'store/reducers/sources/actions';
import { useAppDispatch } from 'store';
import { unwrapResult } from '@reduxjs/toolkit';
import Snackbar from 'services/Snackbar';
import { FolderElement } from 'components/console/elements/SourcesConnection/FileFoldersModal/Folder';
import { NoopValueType, TimerIdType } from 'types/global';
import { HeaderFolders } from 'components/console/elements/SourcesConnection/FileFoldersModal/HeaderFolders';
import { FolderElementData } from 'components/console/elements/SourcesConnection/FileFoldersModal/types';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';

interface FileFolderModalProps {
  onClick: NoopValueType<string>;
  isManyFiles?: boolean;
}

export const FileFoldersModal: ModalComponentType<FileFolderModalProps> = ({ onClose, onClick, isManyFiles = false }) => {
  const dispatch = useAppDispatch();

  const [currentFolderData, setCurrentFolderData] = useState<FolderElementData[] | null>(null);
  const [folderStack, setFolderStack] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  const clickTimer = useRef<TimerIdType>(null);

  const getFilesPathAction = useCallback(
    async (name?: string) => {
      setLoading(true);

      try {
        const actionResult = await dispatch(loadFilesPathAction(name || ''));
        const unwrappedResult = unwrapResult(actionResult);

        const folderFiles = unwrappedResult.filter((item) => !item.isFile);
        const files = unwrappedResult.filter((item) => item.isFile);

        const sortedArr = [...folderFiles, ...(!isManyFiles ? files : [])];

        setCurrentFolderData(sortedArr);
      } catch (error) {
        Snackbar.show('Ошибка', 'error');
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setLoading],
  );

  const onClickPathName = () => {
    onClick(fullPath);
    onClose();
  };

  const onBack = useCallback(() => {
    const updatedHistoryStep = folderStack.slice(0, -1);
    const previousStep = updatedHistoryStep.join('/');

    setSelectedFile(null);
    setFolderStack(updatedHistoryStep);
    getFilesPathAction(previousStep);
  }, [folderStack, getFilesPathAction]);

  const fullPath = useMemo(() => {
    const normalizedPath = folderStack.join('/');
    const pathOptions = {
      fileSelected: selectedFile,
      manyFiles: isManyFiles,
      basePath: normalizedPath,
      fullPathWithFile: `${normalizedPath}/${selectedFile}`,
      fullPathBase: `/${normalizedPath}`,
    };

    if (pathOptions.fileSelected) {
      return pathOptions.fullPathWithFile;
    }

    return pathOptions.manyFiles ? pathOptions.fullPathBase : pathOptions.basePath;
  }, [folderStack, selectedFile, isManyFiles]);

  const onClickFile = useCallback(
    (isFile: boolean, name: string) => {
      if (clickTimer.current) {
        clearTimeout(clickTimer.current);
        clickTimer.current = null;
        if (!isFile || isManyFiles) {
          const normalizedPath = folderStack.join('/');

          void getFilesPathAction(`${normalizedPath}/${name}`);
          setSelectedFile('');
          setFolderStack((historyStep) => [...historyStep, name]);
        }
      } else {
        clickTimer.current = setTimeout(() => {
          if (isFile || isManyFiles) {
            setSelectedFile(name);
          }
          clickTimer.current = null;
        }, 200);
      }
    },
    [getFilesPathAction, folderStack, isManyFiles],
  );

  useEffect(() => {
    return () => {
      if (clickTimer.current) {
        clearTimeout(clickTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    getFilesPathAction();
  }, [getFilesPathAction]);

  return (
    <FlexContainer flexDirection="column" padding="12px" width="100%">
      <HeaderFolders path={fullPath} onBack={onBack} disabledOnBack={folderStack.length < 1} />
      <FlexContainer
        maxHeight="60vh"
        width="100%"
        minHeight="400px"
        flexDirection="column"
        overflowY="auto"
        position="relative"
        marginBottom="32px"
      >
        <LoadingOverlay loading={loading} />
        {currentFolderData?.map(({ name, isFile }) => (
          <FolderElement
            key={name}
            isSelected={selectedFile === name}
            name={name}
            isFile={isFile}
            onClick={() => onClickFile(isFile, name)}
          />
        ))}
      </FlexContainer>
      <FlexContainer width="100%" justifyContent="flex-end">
        <FlexContainer gap="10px">
          <Button text="Отмена" iconSize="normal" heightSize="small" needBackground={true} onClick={onClose} />
          <Button
            text="Подтвердить"
            disabled={!selectedFile && !isManyFiles}
            iconSize="normal"
            heightSize="small"
            needBackground={true}
            onClick={onClickPathName}
          />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
