import { OnValueChange } from 'types/global';
import { BooleanElementSettings } from 'modules/settingsContainer/common/BooleanElementSettings';
import React from 'react';
import { applyToAllPagesText } from 'modules/settingsContainer/common/ApplyToAllPagesSettings/constants';
import { BooleanSettings } from 'modules/settingsContainer/common/BooleanSettings';

type ApplyToAllPagesSettingsProps = OnValueChange<boolean>;

export const ApplyToAllPagesElementSettings = ({ value, onChange }: ApplyToAllPagesSettingsProps) => (
  <BooleanElementSettings titleText={applyToAllPagesText} value={value} onChange={onChange} />
);

export const ApplyToAllPagesSettings = ({ value, onChange }: ApplyToAllPagesSettingsProps) => (
  <BooleanSettings titleText={applyToAllPagesText} value={value} onChange={onChange} />
);
