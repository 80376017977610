import styled, { css } from 'styled-components';
import { settingsTabHeight } from 'modules/ui/tabs/SettingsTabs/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { bottomLineMixin, defaultScrollbarMixin } from 'constants/styles';
import { ContainerChildren } from 'modules/settingsContainer/SettingsLayout/styles';
import { FlexContainerProps } from 'styles/FlexContainer';

interface SettingsTabsWrapperProps {
  width?: string;
  height?: string;
  bgNavigationsTabs?: string;
}

export const SettingsTabsWrapper = styled.div<SettingsTabsWrapperProps>`
  display: flex;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  flex-direction: column;
  background-color: ${({ bgNavigationsTabs }) => bgNavigationsTabs || `var(${ColorVarsEnum.Level_3_menu})`};
`;

interface TabsWrapperProps {
  justifyContent?: FlexContainerProps['justifyContent'];
}

export const TabsWrapper = styled(ContainerChildren)<TabsWrapperProps>`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  gap: 10px;
  height: ${settingsTabHeight};

  :after {
    ${bottomLineMixin}
  }
`;

interface TabItemProps {
  isActive: boolean;
}

export const TabItem = styled.div<TabItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 15px;
  cursor: pointer;

  span {
    line-height: 100%;
    color: var(${ColorVarsEnum.Level_1});
  }

  ${({ isActive }) =>
    isActive &&
    css`
      span {
        color: var(${ColorVarsEnum.Accent});
      }

      :after {
        content: '';
        position: absolute;
        bottom: 0;
        background-color: var(${ColorVarsEnum.Accent});
        height: 3px;
        width: 100%;
        z-index: 1;
      }
    `}
`;

interface BodyWrapperProps {
  overflowY?: string;
  maxHeight?: string;
  isOpen?: boolean;
}

export const BodyWrapper = styled(ContainerChildren)<BodyWrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: ${({ overflowY }) => overflowY || 'auto'};
  max-height: ${({ maxHeight }) => maxHeight || 'calc(100vh - 111px)'};
  position: relative;
  opacity: 1;
  transition: opacity 0.5s ease, max-height 0.5s ease;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      transition: opacity 0.5s, max-height 0.5s ease-in-out;
      overflow: hidden;
      opacity: 0; /* начальное состояние невидимости */
      max-height: 0; /* компонент полностью свернут */
    `}

  ${defaultScrollbarMixin};
`;
