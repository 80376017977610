export const tableViewWidth = '180px';
export const tableHeaderHeight = '34px';
export const tableColumnHeight = '32px';
export const aliasWidth = '95px';

export const getMiddleOfColumnByIndex = (index: number, scale = 1) => {
  const headerHeight = parseInt(tableHeaderHeight),
    columnHeight = parseInt(tableColumnHeight);

  return (headerHeight + columnHeight * (index - 1) + columnHeight / 2) * scale;
};
