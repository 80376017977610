import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { PrimaryTextParagraph, PrimaryTextSpan } from 'styles/TextsElements';
import { SourcesTypeItemContainer } from 'components/console/elements/sideBar/WizardSourceModalType/steps/SourcesTypeStep/styles';
import { Button } from 'modules/ui';
import { NoopType, WizardStepComponentInterface } from 'types/global';
import { WizardSourcesType } from 'components/console/elements/sideBar/WizardSourceModalType/types';
import { sourcesTypeList } from 'components/console/elements/sideBar/WizardSourceModalType/steps/SourcesTypeStep/constants';
import { SourcesTypeListInterface } from 'components/console/elements/sideBar/WizardSourceModalType/steps/SourcesTypeStep/types';

interface SourcesTypeItemInterface extends Omit<SourcesTypeListInterface, 'type'> {
  onClick: NoopType;
}

export const SourcesTypeItem = ({ onClick, disabled, Icon, name }: SourcesTypeItemInterface) => (
  <SourcesTypeItemContainer
    cursor={disabled ? 'no-drop' : 'pointer'}
    width="100%"
    position="relative"
    onClick={() => (!disabled ? onClick() : undefined)}
    gap="16px"
    height="100px"
    border={`1px solid var(${ColorVarsEnum.Level_4})`}
  >
    <FlexContainer borderRight={`1px solid var(${ColorVarsEnum.Level_4})`} padding="25px">
      <IconWrapper Icon={Icon} colorVar={ColorVarsEnum.Level_3} iconHeight="50px" iconWidth="50px" opacity={0} />
    </FlexContainer>
    <FlexContainer width="100%" padding="24px" alignItems="center">
      <PrimaryTextSpan
        fontWeight={700}
        lineHeight="24px"
        fontSize="18px"
        color={!disabled ? `var(${ColorVarsEnum.Level_1})` : `var(${ColorVarsEnum.Level_3})`}
      >
        {name}
      </PrimaryTextSpan>
    </FlexContainer>
  </SourcesTypeItemContainer>
);

export const SourcesTypeStep = ({ onNext, onClose }: WizardStepComponentInterface<WizardSourcesType>) => (
  <>
    <FlexContainer width="100%" flexDirection="column" marginBottom="40px">
      <FlexContainer width="100%" marginBottom="48px">
        <PrimaryTextParagraph fontWeight={700} lineHeight="24px" fontSize="20px" color={`var(${ColorVarsEnum.Level_1})`}>
          Выберите тип источника
        </PrimaryTextParagraph>
      </FlexContainer>

      <FlexContainer width="100%" flexDirection="column" gap="16px">
        {sourcesTypeList.map(({ type, name, Icon, disabled }, index) => (
          <SourcesTypeItem key={index} onClick={() => onNext && onNext(type)} Icon={Icon} disabled={disabled} name={name} />
        ))}
      </FlexContainer>
    </FlexContainer>

    <FlexContainer width="100%" justifyContent="flex-end">
      <Button text="Отмена" iconSize="normal" heightSize="small" needBackground={true} onClick={onClose} />
    </FlexContainer>
  </>
);
