import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import { IdInterface } from 'types/store';

export const loadActiveDirectories = (): Promise<AxiosResponse<FastBoard.API.ApiCronJobWithIdListResponseDTO>> =>
  axiosClient.get(`/api/v1/cronjobs/ads`);

export const loadConnectorUsersGroups = (id: string): Promise<AxiosResponse<FastBoard.API.ApiAdminUserGroupListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/ad/${id}/users-and-groups`);

export const loadActiveDirector = (id: string): Promise<AxiosResponse<FastBoard.API.ApiAdminAdResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/ad/${id}`);

export const loadActiveDirectorInfo = (id: string): Promise<AxiosResponse<FastBoard.API.ApiCronJobItemWithIdResponseDTO>> =>
  axiosClient.get(`/api/v1/cronjob/${id}/ad`);

export const createActiveDirector = ({
  isActive,
  baseDN,
  cron,
  filterGroups,
  filterUsers,
  newUsers,
  login,
  name,
  url,
  password,
}: FastBoard.API.ApiAdminAdCreateDTO) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ApiAdminAdResponseDTO>, FastBoard.API.ApiAdminAdCreateDTO>(
    `/api/v2/admin/ad`,
    {
      isActive,
      baseDN,
      cron,
      filterGroups,
      filterUsers,
      newUsers,
      login,
      name,
      url,
      password,
    },
  );

export const updateActiveDirector = ({
  id,
  isActive,
  baseDN,
  cron,
  filterGroups,
  filterUsers,
  newUsers,
  login,
  name,
  url,
  password,
}: FastBoard.API.ApiAdminAdUpdateDTO & IdInterface) =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ApiAdminAdResponseDTO>, FastBoard.API.ApiAdminAdUpdateDTO>(
    `/api/v2/admin/ad/${id}`,
    {
      isActive,
      baseDN,
      cron,
      filterGroups,
      filterUsers,
      newUsers,
      login,
      name,
      url,
      password,
    },
  );

export const synchronize = (id: string): Promise<AxiosResponse<FastBoard.API.ApiAdminAdSyncResponseDTO>> =>
  axiosClient.put(`/api/v2/admin/ad/sync/${id}`);

export const deleteActiveDirector = (id: string): Promise<string> =>
  axiosClient.delete(`/api/v2/admin/ad/${id}
`);

export const loadAdminFlows = (): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowsListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/flows`);

export const loadAdminShortProject = (
  flowId: string,
): Promise<AxiosResponse<FastBoard.API.ApiAdminProjectShortListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/projects/for-flow/${flowId}/short`);
