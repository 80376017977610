import React, { memo, useEffect, useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button, Checkbox, TextField } from 'modules/ui/index';
import { Combobox } from 'modules/ui/Combobox';
import { NoopType, NoopValueType } from 'types/global';
import { RestoreVersionCallbackProps, SelectType } from 'modules/ui/ManagerOrAdminComponentsUI/ModalsUI/CopyProjectModalUI/types';

interface CopyProjectModalComponentUIProps {
  projectName: string;
  flowsItems: SelectType[];
  projectsItems: SelectType[];
  onClose: NoopType;
  onSelectFlows: NoopValueType<SelectType>;
  flows: SelectType;
  onRestoreVersionModal: NoopValueType<RestoreVersionCallbackProps>;
  titleFlow: string;
}

export const CopyProjectModalComponentUIComponent = ({
  onClose,
  flowsItems,
  projectsItems,
  projectName,
  onSelectFlows,
  flows,
  onRestoreVersionModal,
  titleFlow,
}: CopyProjectModalComponentUIProps) => {
  const [renameValue, setRenameValue] = useState(projectName);
  const [selectProject, setSelectProject] = useState<SelectType | null>(projectsItems[0]);

  const [replaceExistingChecked, setReplaceExistingChecked] = useState(false);
  const [runImportChecked, setRunImportChecked] = useState(false);

  useEffect(() => {
    setSelectProject(projectsItems?.[0] || null);
  }, [projectsItems]);

  return (
    <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" padding="12px" height="100%">
      <FlexContainer width="100%" flexDirection="column" height="100%" gap="32px">
        <Combobox
          title={titleFlow}
          name="flows"
          placeholder="Выберите поток"
          options={flowsItems}
          tooltipDisableHoverListener
          width="100%"
          value={flows}
          onChange={(value) => onSelectFlows(value)}
        />

        {replaceExistingChecked && (
          <Combobox
            title="Заменить проект"
            options={projectsItems}
            width="100%"
            placeholder="Выберите проект"
            tooltipDisableHoverListener
            value={selectProject}
            name="flows"
            onChange={(value) => setSelectProject(value)}
          />
        )}

        {!replaceExistingChecked && (
          <TextField
            label="Название проекта"
            onChange={(e) => setRenameValue(e.target.value)}
            value={renameValue}
            name="rename"
            width="100%"
          />
        )}
      </FlexContainer>

      <Checkbox
        name="replaceExistingChecked"
        padding="8px 0 0 0"
        checked={replaceExistingChecked}
        label="Заменить существующий проект"
        id="manyFiles_flag"
        onChange={(e) => setReplaceExistingChecked(e.target.checked)}
      />
      <Checkbox
        name="runImportChecked"
        padding="8px 0 0 0"
        checked={runImportChecked}
        label="Запустить импорт после создания"
        id="manyFiles_flag"
        onChange={(e) => setRunImportChecked(e.target.checked)}
      />
      <FlexContainer width="100%" justifyContent="flex-end" gap="8px" marginTop="32px">
        <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={false} onClick={onClose} />
        <Button
          text={!replaceExistingChecked ? 'Копировать' : 'Заменить'}
          iconSize="normal"
          needBackground={true}
          heightSize="normal"
          disabled={replaceExistingChecked && !projectsItems?.length}
          onClick={() =>
            onRestoreVersionModal({
              renameValue,
              flowsName: flows?.name,
              replaceExistingChecked,
              selectProject,
              runImport: runImportChecked,
            })
          }
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export const CopyProjectModalComponentUI = memo(CopyProjectModalComponentUIComponent);
