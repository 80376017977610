import { OnValueChange } from 'types/global';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { Switcher } from 'modules/ui';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import React, { useId } from 'react';

interface BooleanElementSettingsProps extends OnValueChange<boolean> {
  titleText: string;
}

export const BooleanElementSettings = ({ value, onChange, titleText }: BooleanElementSettingsProps) => {
  const id = useId();

  return (
    <ElementContainerSettings>
      <FlexContainer alignItems="center" justifyContent="space-between" width="100%">
        <PrimaryTextSpan lineHeight="12px">{titleText}</PrimaryTextSpan>
        <Switcher id={id} value={value} onClick={() => onChange(!value)} />
      </FlexContainer>
    </ElementContainerSettings>
  );
};
