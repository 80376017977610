import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSliceFn } from 'constants/store';
import { initialAdminSourcesStoreState } from './constants';
import { loadAdminSourcesAction, loadAdminSourceUsersAndGroupsAction } from 'store/reducers/adminSources/actions';
import { ActiveSourceInterface } from 'store/reducers/adminSources/types';
import { IdInterface } from 'types/store';
import { AccessInterface } from 'types/types';
import { loadUsersGroupsAction } from 'store/reducers/projectManager/actions';

export const adminSourcesSlice = createSlice({
  name: 'adminSources',
  initialState: initialAdminSourcesStoreState,
  reducers: {
    changeActiveSource: (state, { payload }: PayloadAction<ActiveSourceInterface>) => {
      state.activeSource = payload;
    },
    deleteByIdAdminSource: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.sources.sourcesList = state.sources.sourcesList.filter((source) => source.id !== id);
    },
    deleteByIdAdminSourceAccess: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.sourcesUsersAndGroups.sourcesUsersAndGroupsList = state.sourcesUsersAndGroups.sourcesUsersAndGroupsList.filter(
        (usersAndGroups) => usersAndGroups.id !== id,
      );
    },
    updateSourceAccess: (state, { payload: access }: PayloadAction<AccessInterface[]>) => {
      state.sourcesUsersAndGroups.sourcesUsersAndGroupsList = access;
    },
    setSlice: setSliceFn,
  },
  extraReducers: (builder) => {
    builder.addCase(loadAdminSourcesAction.pending, (state) => {
      state.sources = { ...state.sources, loading: true };
      state.sources.sourcesList = [];
    });
    builder.addCase(loadAdminSourcesAction.fulfilled, (state, { payload }) => {
      state.sources = { ...state.sources, loading: false };
      state.sources.sourcesList = payload;
    });
    builder.addCase(loadAdminSourcesAction.rejected, (state) => {
      state.sources = { ...state.sources, loading: false };
      state.sources.sourcesList = [];
    });

    builder.addCase(loadAdminSourceUsersAndGroupsAction.pending, (state) => {
      state.sourcesUsersAndGroups = { ...state.sourcesUsersAndGroups, loading: true };
      state.sourcesUsersAndGroups.sourcesUsersAndGroupsList = [];
    });
    builder.addCase(loadAdminSourceUsersAndGroupsAction.fulfilled, (state, { payload }) => {
      state.sourcesUsersAndGroups = { ...state.sourcesUsersAndGroups, loading: false };
      state.sourcesUsersAndGroups.sourcesUsersAndGroupsList = payload;
    });
    builder.addCase(loadAdminSourceUsersAndGroupsAction.rejected, (state) => {
      state.sourcesUsersAndGroups = { ...state.sourcesUsersAndGroups, loading: false };
      state.sourcesUsersAndGroups.sourcesUsersAndGroupsList = [];
    });

    builder.addCase(loadUsersGroupsAction.pending, (state) => {
      state.usersGroupsList.loading = true;
      state.usersGroupsList.usersGroupsList = [];
    });
    builder.addCase(loadUsersGroupsAction.fulfilled, (state, { payload }) => {
      state.usersGroupsList.loading = false;
      state.usersGroupsList.usersGroupsList = payload;
    });
    builder.addCase(loadUsersGroupsAction.rejected, (state) => {
      state.usersGroupsList.loading = false;
      state.usersGroupsList.usersGroupsList = [];
    });
  },
});

export const { setSlice, changeActiveSource, updateSourceAccess, deleteByIdAdminSourceAccess, deleteByIdAdminSource } =
  adminSourcesSlice.actions;

export default adminSourcesSlice.reducer;
