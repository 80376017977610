import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete } from '@mui/material';
import { Checkbox } from 'modules/ui/index';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { PrimaryTextParagraph, PrimaryTextSpan } from 'styles/TextsElements';
import { IconType } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import {
  RemoveIcon,
  ArrowIcon,
  CustomChip,
  CheckBoxWrapper,
  MenuItem,
  CustomTextField,
  AutocompleteWrapper,
  StyledPaper,
} from './styles';

export type MultiSelectType<D, T extends string = string> = {
  title: string;
  value: T;
  meta?: string;
  icon?: IconType;
  data?: D;
};

export interface IMultiSelect<T> {
  options: MultiSelectType<T>[];
  valueOption?: MultiSelectType<T>[];
  onChange?: (value: MultiSelectType<T>[]) => void;
  placeholder?: string;
  label?: string;
  disabledFirst?: boolean;
  noOptionsText?: string;
}

export const MultiSelect = <T,>({
  onChange,
  placeholder,
  options,
  label,
  valueOption,
  disabledFirst = false,
  noOptionsText = 'Не найдено',
  ...props
}: IMultiSelect<T>) => {
  const [filteredOptions, setFilteredOptions] = useState<MultiSelectType<T>[]>(options || []);
  const [selectedValues, setSelectedValues] = useState<MultiSelectType<T>[]>(valueOption || []);

  const handleFilterOptions = useCallback(
    (text: string) => {
      const lowerText = text.toLowerCase();
      const filteredOptions = options.filter((option) => option.title.toLowerCase().includes(lowerText));

      setFilteredOptions(filteredOptions);

      return filteredOptions;
    },
    [options],
  );

  const handleChangeSelectedValue = useCallback(
    (value: MultiSelectType<T>[]) => {
      onChange && onChange(value);
      setSelectedValues(value);
    },
    [onChange],
  );

  const handleSelectAll = useCallback(() => {
    let valueToSave: MultiSelectType<T>[] = [];
    if (filteredOptions.length !== selectedValues.length) {
      valueToSave = filteredOptions;
    }

    handleChangeSelectedValue(valueToSave);
  }, [filteredOptions, handleChangeSelectedValue, selectedValues]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const renderCheckBox = (id: string, name: string, checked: boolean) => (
    <CheckBoxWrapper>
      <Checkbox padding="0" id={id} name={name} checked={checked} label="" />
    </CheckBoxWrapper>
  );

  const renderText = (text: string, selected: boolean) => (
    <PrimaryTextParagraph
      lineHeight="14px"
      fontSize="14px"
      position="relative"
      color={selected ? `var(${ColorVarsEnum.Level_1})` : `var(${ColorVarsEnum.Level_2})`}
    >
      {text}
    </PrimaryTextParagraph>
  );

  return (
    <AutocompleteWrapper>
      {label && (
        <PrimaryTextParagraph marginBottom="4px" fontSize="10px" color={`var(${ColorVarsEnum.Level_2})`}>
          {label}
        </PrimaryTextParagraph>
      )}
      <Autocomplete
        {...props}
        fullWidth
        multiple
        PaperComponent={StyledPaper}
        noOptionsText={noOptionsText}
        value={selectedValues}
        options={options}
        limitTags={2}
        onInputChange={(_, value) => handleFilterOptions(value)}
        disableCloseOnSelect
        clearIcon={<RemoveIcon />}
        popupIcon={<ArrowIcon />}
        renderInput={(params) => <CustomTextField {...params} placeholder={placeholder} />}
        getOptionLabel={(option: MultiSelectType<T>) => option.title}
        isOptionEqualToValue={(option: MultiSelectType<T>, value: MultiSelectType<T>) => option.value === value.value}
        {...(onChange && {
          onChange: (_, newVal: MultiSelectType<T>[]) => handleChangeSelectedValue(newVal),
        })}
        renderOption={(props, option: MultiSelectType<T>, state) => {
          const icon = option.icon;
          const value = option.value;
          const selected = state.selected;

          const isFirst = value === filteredOptions?.[0]?.value;
          const isSelectedAll = selectedValues.length === filteredOptions.length;

          return (
            <>
              {isFirst && !disabledFirst && (
                <MenuItem onClick={handleSelectAll} underLine>
                  {renderText('Выбрать все', isSelectedAll)}
                  {renderCheckBox('select-all', 'select-all', isSelectedAll)}
                </MenuItem>
              )}
              <MenuItem {...props}>
                {icon && (
                  <IconWrapper
                    hoverColorVar={ColorVarsEnum.Level_3}
                    colorVar={ColorVarsEnum.Level_3}
                    iconWidth="20px"
                    iconHeight="20px"
                    Icon={icon}
                  />
                )}
                {renderText(option.title, selected)}
                {renderCheckBox(value, value, selected)}
              </MenuItem>
            </>
          );
        }}
        renderTags={(value: MultiSelectType<T>[], getTagProps) => {
          if (value.length === 0) {
            return <em>Placeholder</em>;
          }

          return (
            <>
              {value.map((option, index) => {
                if (index < 10) {
                  return (
                    <CustomChip
                      {...getTagProps({ index })}
                      deleteIcon={<RemoveIcon small />}
                      label={option.title}
                      key={`select-tag-${index}`}
                    />
                  );
                }
                if (index === 11) {
                  return (
                    <PrimaryTextSpan fontSize="14px" key={`select-tag-${index}`}>
                      ...
                    </PrimaryTextSpan>
                  );
                }
              })}
            </>
          );
        }}
      />
    </AutocompleteWrapper>
  );
};
