import { BoardStateInterface, UpdateLayerPayload } from 'store/reducers/board/types';
import { PayloadAction } from '@reduxjs/toolkit';

export const initialBoardStoreState: BoardStateInterface = {
  buffer: null,
  activeBoardElement: null,
  activeBoardElementInViewMode: null,
  isMouseInside: null,
  pagesLayers: {},
  serverStateOfPagesLayers: null,
  layersLoading: false,
  editInfluenceFiltersMode: false,
  alreadyLoadedContent: {
    layers: new Set<string>(),
  },
};

export const deleteFromLayerFunc = (
  state: BoardStateInterface,
  { payload: { id, pageId } }: PayloadAction<UpdateLayerPayload>,
) => {
  const pageLayers = state.pagesLayers[pageId];

  if (pageLayers) {
    state.pagesLayers[pageId] = pageLayers.filter((layerId) => layerId !== id);
  }
};
