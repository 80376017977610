import { createSlice } from '@reduxjs/toolkit';
import { initialLoadingScriptStoreState } from 'store/reducers/loadingScript/constants';
import { loadSQLScriptAction } from 'store/reducers/loadingScript/actions';
import { setSliceFn } from 'constants/store';
import { sourcesSlice } from 'store/reducers/sources';

export const loadingScriptSlice = createSlice({
  name: 'loadingScript',
  initialState: initialLoadingScriptStoreState,
  reducers: {
    setSlice: setSliceFn,
  },
  extraReducers: (builder) => {
    builder.addCase(loadSQLScriptAction.pending, (state) => {
      state.SQLScript = '';
    });
    builder.addCase(loadSQLScriptAction.fulfilled, (state, { payload }) => {
      state.SQLScript = payload;
    });
    builder.addCase(loadSQLScriptAction.rejected, (state) => {
      state.SQLScript = '';
    });
  },
});

export const { setSlice } = sourcesSlice.actions;

export default loadingScriptSlice.reducer;
