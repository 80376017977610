import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import React from 'react';
import { CircularProgressWithLabel } from 'modules/ui/LoaderWithPercent';

type LoadingOverlayProps = {
  loading: boolean;
  progress?: number;
};

export const LoadingOverlayPercent: React.FC<LoadingOverlayProps> = ({ loading, progress = 0 }) => {
  if (!loading) {
    return null;
  }

  return (
    <FlexContainer
      position="absolute"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      backgroundColor={`var(${ColorVarsEnum.Level_3_menu})`}
      zIndex="1"
      left="0px"
      top="0px"
    >
      <CircularProgressWithLabel value={isNaN(Number(progress)) ? 0 : progress} />
    </FlexContainer>
  );
};
