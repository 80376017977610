import { GroupsListInterface } from 'store/reducers/adminGroups/types';
import { EntityType, GroupType, IdInterface, LoadingInterface } from 'types/store';

export interface AccessInterface extends IdInterface {
  name: string;
  entity: EntityType;
  type: GroupType;
}

export interface AddUsersGroupsSourceAccessPayload {
  sourceId: string;
  groups?: string[];
  users?: string[];
  type: 'owner' | 'reader';
}

interface UpdateSourceAccess {
  sourceId: string;
  type: 'owner' | 'reader';
}

export interface UpdateGroupSourceAccessByIdPayload extends UpdateSourceAccess {
  groupId: string;
}

export interface UpdateUserSourceAccessByIdPayload extends UpdateSourceAccess {
  userId: string;
}

export interface AvatarProjectInterface {
  avatar: File | null;
  projectId: string;
}

export type GetUsersAccessNoGroupPayload = {
  groupId: string;
};

export interface GroupsInterface extends LoadingInterface {
  groupsList: GroupsListInterface[];
}

export enum AstOperatorEnum {
  IN = 'IN',
  BETWEEN = 'BETWEEN',
}

export type CronHour = FastBoard.API.CronHour;
export type CronDay = FastBoard.API.CronDay;
export type CronWeek = FastBoard.API.CronWeek;
export type CronMonth = FastBoard.API.CronMonth;
export type CronSchedule = FastBoard.API.CronSchedule;

export type CronDateTypes = CronHour | CronDay | CronWeek | CronMonth | CronSchedule;

export interface RLSInterface {
  type: EntityType;
  entityId: string;
}
