import { PrimaryTextSpan } from 'styles/TextsElements';

interface SelectedItemCounterProps {
  selectedCounter: number;
  listCounter: number;
  isOverLimit?: boolean;
}

export const SelectedItemCounter = ({ selectedCounter, listCounter, isOverLimit }: SelectedItemCounterProps) => {
  return (
    <PrimaryTextSpan textAlign="center">
      Выбрано {selectedCounter} из {listCounter}
      {isOverLimit && '+'}
    </PrimaryTextSpan>
  );
};
