import React, { memo } from 'react';
import {
  CardIconContainer,
  FlowCardIconContainer,
  Image,
  ProtectIconIconContainer,
  ProtectIconIconWrapper,
} from 'modules/ui/lists/BlockList/ListBlockItem/ImageItem/styles';
import { FlowCardIcon, ProtectIcon } from 'assets/icons/flow';
import { IconType } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ListBlockItemProps } from '..';

interface ImageItemProps {
  image: ListBlockItemProps['image'];
  CardIcon?: IconType;
  disabledProtect?: boolean;
}

const ImageItemComponent = ({ image, disabledProtect = false, CardIcon = FlowCardIcon }: ImageItemProps) => {
  return (
    <>
      <FlowCardIconContainer>
        <CardIconContainer>{image ? <Image src={`data:image/bmp;base64,${image}`} /> : <CardIcon />}</CardIconContainer>
        {!disabledProtect && (
          <ProtectIconIconContainer>
            <ProtectIconIconWrapper
              Icon={ProtectIcon}
              colorVar={ColorVarsEnum.Level_3}
              hoverColorVar={ColorVarsEnum.Level_1}
              iconWidth="24px"
              iconHeight="24px"
            />
          </ProtectIconIconContainer>
        )}
      </FlowCardIconContainer>
    </>
  );
};

export const ImageItem = memo(ImageItemComponent);
