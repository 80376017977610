import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import TextField from 'modules/ui/TextField';
import React, { useState } from 'react';
import { OnValueChange } from 'types/global';
import Select from 'modules/ui/Select';

export type UnitOption<T extends string> = {
  label: number;
  value: T;
};

interface NumberPropertiesSelectBlockProps<T extends string> extends OnValueChange<UnitOption<T>> {
  name: string;
  properties?: string | JSX.Element;
  width?: string;
  unitOptions: { label: string; value: T }[];
  heightSize?: 'small' | 'normal';
  debounced?: boolean;
}

export const NumberPropertiesSelectBlock = <T extends string>({
  properties,
  name,
  unitOptions,
  width = '54px',
  value: data,
  onChange,
  heightSize,
  debounced = false,
}: NumberPropertiesSelectBlockProps<T>) => {
  const { value, label } = data;
  const [selectedUnit, setSelectedUnit] = useState<T>(value);

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ value: selectedUnit, label: Number(e.target.value) });
  };

  const onUnitChange = (newLabel: string) => {
    const selectedOption = unitOptions.find((option) => option.value === newLabel);
    if (selectedOption) {
      const newUnit: T = selectedOption.value;
      setSelectedUnit(newUnit);
      onChange({ value: newUnit, label });
    }
  };

  return (
    <FlexContainer alignItems="center" gap="5px">
      {properties &&
        (typeof properties === 'string' ? (
          <PrimaryTextSpan lineHeight="12px" color={`var(${ColorVarsEnum.Level_3})`}>
            {properties}
          </PrimaryTextSpan>
        ) : (
          properties
        ))}
      <FlexContainer width={width}>
        <TextField
          onChange={onValueChange}
          value={label}
          type="number"
          name={name}
          needBackground={false}
          needBorderBottom={false}
          width={width}
          heightSize={heightSize}
          useDebounce={debounced}
        />
      </FlexContainer>
      <FlexContainer width="80px" height={heightSize === 'small' ? '24px' : '30px'}>
        <Select
          needBackground={false}
          placeholder="Выбрать модель"
          name="model"
          options={unitOptions.map((option) => ({ value: option.value, name: option.label }))}
          heightSize="small"
          value={String(selectedUnit)}
          onChange={onUnitChange}
          disabledTooltip
        />
      </FlexContainer>
    </FlexContainer>
  );
};
