import { axiosClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import {
  CreateFlowAccessPayload,
  CreateFlowPayload,
  DeleteFlowGroupAccessPayload,
  DeleteFlowPayload,
  DeleteFlowUserAccessPayload,
  LoadFlowAccessPayload,
  LoadFlowProjectsPayload,
  UpdateFlowGroupAccessPayload,
  UpdateFlowPayload,
  UpdateFlowUserAccessPayload,
} from 'store/reducers/adminFlows/types';
import { UserDeleteDraftProjectsPayload } from 'store/reducers/adminUsers/types';
import {
  CopyProjectVersionAnotherPayload,
  CreateProjectVersionAnotherPayload,
  MoveProjectPayload,
  ProtectProjectPayload,
  RenameProjectPayload,
  RestoreHistoryProjectPayload,
} from 'store/reducers/projectManager/types';

export const loadFlows = (): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowsListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/flows`);

export const createFlow = ({ name }: CreateFlowPayload) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ApiAdminFlowResponseDTO>, FastBoard.API.ApiAdminFlowCreateDTO>(
    `/api/v2/admin/flow/create`,
    {
      name,
    },
  );

export const updateFlow = ({ name, flowId }: UpdateFlowPayload) =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ApiAdminFlowResponseDTO>, FastBoard.API.ApiAdminFlowRenameDTO>(
    `/api/v2/admin/flow/${flowId}/rename`,
    {
      name,
    },
  );

export const deleteFlow = ({ flowId }: DeleteFlowPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowDeleteResponseDTO>> =>
  axiosClient.delete(`/api/v2/admin/flow/${flowId}`);

/* Flow access */

export const loadFlowAccess = ({
  flowId,
}: LoadFlowAccessPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowUsersAndGroupsListItemResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/flows/${flowId}/groups-and-users`);

export const loadNoFlowAccess = ({
  flowId,
}: LoadFlowAccessPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminUserGroupListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/user-groups`, { params: { flowId } });

export const createFlowAccess = ({ flowId, ...params }: CreateFlowAccessPayload) =>
  axiosClient.post<
    string,
    AxiosResponse<FastBoard.API.ApiAdminFlowAddGroupsAndUsersResponseDTO>,
    FastBoard.API.ApiAdminFlowAddGroupsAndUsersDTO
  >(`/api/v2/admin/flows/${flowId}/groups-and-users/add`, {
    ...params,
  });

export const updateFlowUserAccess = ({ flowId, userId, type }: UpdateFlowUserAccessPayload) =>
  axiosClient.put<
    string,
    AxiosResponse<FastBoard.API.ApiAdminFlowUserResponseDTO>,
    FastBoard.API.ApiAdminFlowUpdateTypeFromUserDTO
  >(`/api/v2/admin/flows/${flowId}/user/${userId}/type`, {
    type,
  });

export const updateFlowGroupAccess = ({ flowId, groupId, type }: UpdateFlowGroupAccessPayload) =>
  axiosClient.put<
    string,
    AxiosResponse<FastBoard.API.ApiAdminFlowGroupResponseDTO>,
    FastBoard.API.ApiAdminFlowUpdateTypeFromGroupDTO
  >(`/api/v2/admin/flows/${flowId}/group/${groupId}/type`, {
    type,
  });

export const deleteFlowUserAccess = ({
  flowId,
  userId,
}: DeleteFlowUserAccessPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowUserResponseDTO>> =>
  axiosClient.delete(`/api/v2/admin/flows/${flowId}/user/${userId}`);

export const deleteFlowGroupAccess = ({
  flowId,
  groupId,
}: DeleteFlowGroupAccessPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowGroupResponseDTO>> =>
  axiosClient.delete(`/api/v2/admin/flows/${flowId}/group/${groupId}`);

/* Flow projects */

export const loadFlowProjects = ({
  flowId,
}: LoadFlowProjectsPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminProjectListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/projects/for-flow/${flowId}`);

export const loadFlowProjectInfo = ({
  projectId,
}: UserDeleteDraftProjectsPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminProjectMetaResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/project/${projectId}/meta`);

export const loadFlowProjectHistoryVersion = ({
  projectId,
}: UserDeleteDraftProjectsPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminProjectVersionHistoryResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/project/${projectId}/history/50/0`);

export const restoreHistoryFlowProject = ({ projectId, dateTimeHistory }: RestoreHistoryProjectPayload) =>
  axiosClient.post<
    string,
    AxiosResponse<FastBoard.API.ApiAdminIncomingsResponseDTO>,
    FastBoard.API.ApiAdminProjectVersionRollbackDTO
  >(`/api/v1/project/${projectId}/rollback`, {
    ts: dateTimeHistory,
  });

export const createFlowProjectVersionAnother = ({
  projectId,
  dateTimeHistory,
  name,
  flowId,
  runImport,
}: CreateProjectVersionAnotherPayload) =>
  axiosClient.post<
    string,
    AxiosResponse<FastBoard.API.ApiAdminProjectVersionCreateFromResponseDTO>,
    FastBoard.API.ApiAdminProjectVersionCreateFromDTO
  >(`/api/v2/admin/ops/project/create`, {
    from: { ts: dateTimeHistory, projectId },
    to: { name, flowId },
    runImport,
  });

export const copyFlowProjectVersionAnother = ({ projectToId, projectFromId, runImport }: CopyProjectVersionAnotherPayload) =>
  axiosClient.post<
    string,
    AxiosResponse<FastBoard.API.ApiAdminIncomingsResponseDTO>,
    FastBoard.API.ApiAdminProjectVersionCopyDTO
  >(`/api/v2/admin/ops/project/copy`, {
    from: { projectId: projectFromId },
    to: { projectId: projectToId },
    runImport,
  });

export const moveFlowProject = ({ projectId, flowId }: MoveProjectPayload) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ApiAdminProjectTransferToFlowResponseDTO>>(
    `/api/v2/admin/projects-flows/flow/${flowId}/project/${projectId}/transfer`,
  );

export const protectFlowProject = ({
  projectId,
  isProtected,
}: ProtectProjectPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminProjectUpdateProtectResponseDTO>> =>
  axiosClient.put(`/api/v2/admin/project/${projectId}/protect`, { isProtected });

export const renameFlowProject = ({
  id,
  name,
}: RenameProjectPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminProjectResponseDTO>> =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ApiAdminProjectResponseDTO>, FastBoard.API.ApiAdminProjectRenameDTO>(
    `/api/v2/admin/project/${id}/rename`,
    { name },
  );

export const loadShortFlowProject = (flowId: string): Promise<AxiosResponse<FastBoard.API.ApiAdminProjectShortListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/projects/for-flow/${flowId}/short`);

export const deleteFlowProject = ({ projectId }: UserDeleteDraftProjectsPayload) =>
  axiosClient.delete<string, AxiosResponse<string>, string>(`/api/v2/admin/project/${projectId}`);
