import React, { memo } from 'react';
import { InfoContainer } from 'modules/ui/ManagerOrAdminComponentsUI/InformationBarUI/styles';
import { NavigationsTabs } from 'modules/ui/tabs/NavigationsTabs';
import { Tab } from 'modules/ui/tabs/types';
import { NoopType } from 'types/global';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

interface InformationBarProps {
  open: boolean;
  onClose: NoopType;
  tabs: Tab[];
}

const InformationBarComponent = ({ open, onClose, tabs }: InformationBarProps) => {
  return (
    <>
      {open && (
        <InfoContainer>
          <NavigationsTabs
            typeMenu="small"
            disabledTabs={false}
            tabs={tabs}
            width="100%"
            onCloseMenu={onClose}
            maxBodyHeight="calc(100vh - 260px)"
            bgNavigationsTabs={`var(${ColorVarsEnum.Level_5_application})`}
          />
        </InfoContainer>
      )}
    </>
  );
};

export const InformationBar = memo(InformationBarComponent);
