import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import TextField from 'modules/ui/TextField';
import React from 'react';
import { OnValueChange } from 'types/global';

interface NumberPropertiesBlockProps extends OnValueChange<number> {
  name: string;
  properties?: string | JSX.Element;
  unit?: string;
  width?: string;
  disabled?: boolean;
  debounced?: boolean;
  heightSize?: 'small' | 'normal';
  allowZero?: boolean;
}

export const NumberPropertiesBlock = ({
  properties,
  name,
  unit,
  width = '54px',
  value,
  onChange,
  disabled = false,
  debounced = false,
  heightSize,
  allowZero = true,
}: NumberPropertiesBlockProps) => {
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    if (!allowZero) {
      newValue = newValue.replace(/^0+/, '');

      if (newValue === '' || newValue === '0') {
        onChange(1);
        return;
      }
    }

    if (newValue === '') {
      onChange(0);
    } else {
      onChange(Number(newValue));
    }
  };

  return (
    <FlexContainer alignItems="center" gap="5px">
      {properties &&
        (typeof properties === 'string' ? (
          <PrimaryTextSpan lineHeight="12px" color={`var(${ColorVarsEnum.Level_3})`}>
            {properties}
          </PrimaryTextSpan>
        ) : (
          <>{properties}</>
        ))}
      <FlexContainer width={width}>
        <TextField
          onChange={onChangeText}
          value={value}
          type="number"
          name={name}
          disabled={disabled}
          needBackground={false}
          needBorderBottom={false}
          width={width}
          heightSize={heightSize}
          useDebounce={debounced}
        />
      </FlexContainer>
      {unit && (
        <PrimaryTextSpan lineHeight="10px" color={`var(${ColorVarsEnum.Level_3})`}>
          {unit}
        </PrimaryTextSpan>
      )}
    </FlexContainer>
  );
};
