import React, { memo } from 'react';

import { InfoProjectUI } from 'modules/ui/ManagerOrAdminComponentsUI/InfoProjectUI';
import { useProjectInfo } from 'components/projectsManager/hook/useProjectInfo';
import { NoopValueType } from 'types/global';
import { AvatarProjectInterface } from 'types/types';
import { useRole } from 'utils/hooks/useRole';

interface InfoProjectProps {
  activeProjectId: string;
  padding?: string;
  onChangeAvatar?: NoopValueType<AvatarProjectInterface>;
}

export const InfoProjectComponent = ({ activeProjectId, padding, onChangeAvatar }: InfoProjectProps) => {
  const { isViewer } = useRole();
  const { loadingInfoProject, infoProject: infoProjectData, changeProjectAvatar } = useProjectInfo(activeProjectId || '');

  const handleChangeAvatar = ({ avatar, projectId }: AvatarProjectInterface) => {
    changeProjectAvatar(avatar);
    onChangeAvatar && onChangeAvatar({ avatar, projectId });
  };

  return (
    <InfoProjectUI
      id={infoProjectData?.id}
      isViewer={isViewer}
      title={infoProjectData?.title}
      isProtected={infoProjectData?.isProtected}
      createdAt={infoProjectData?.createdAt}
      created={infoProjectData?.created}
      updated={infoProjectData?.updated}
      updatedDataAt={infoProjectData?.updatedDataAt}
      avatar={infoProjectData?.avatar}
      isLoading={loadingInfoProject}
      padding={padding}
      onChangeAvatar={handleChangeAvatar}
    />
  );
};

export const InfoProject = memo(InfoProjectComponent);
