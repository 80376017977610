import { defaultScrollbarMixin } from 'constants/styles';
import styled from 'styled-components';

interface SingleFilterWrapperProps {
  position: string;
}

export const SingleFilterWrapper = styled.div<SingleFilterWrapperProps>`
  padding: 16px;
  position: relative;
  overflow: auto;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;

  justify-content: ${({ position }) => position};
  &:hover {
    overflow: auto;
  }

  ${defaultScrollbarMixin};
`;
