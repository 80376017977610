import styled, { css } from 'styled-components';
import { IsActiveInterface } from 'store/reducers/visualisations/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

type MenuWrapperProps = {
  height?: string;
};

export const MenuWrapper = styled.div<MenuWrapperProps>`
  display: flex;
  height: ${({ height }) => height || '35px'};
  gap: 16px;
`;

interface HeaderItemInterface extends IsActiveInterface {
  fontSize?: string;
}

export const HeaderItem = styled.h2<HeaderItemInterface>`
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize || '14px'};
  line-height: 100%;
  padding: 4px 12px;
  color: var(${ColorVarsEnum.Level_2});
  border-bottom: 2px solid transparent;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      color: var(${ColorVarsEnum.Accent});
      border-bottom: 2px solid var(${ColorVarsEnum.Accent});
    `};
`;
