import { HeaderLayout } from 'components/layouts/HeaderLayout';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { HeaderMenu } from 'modules/ui/menu/HeaderMenu';
import { LoadAdminRoute, RoutesURL } from 'constants/Routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLastPath } from 'utils/utils';
import { menuItemsAdmin } from './constants';
import React, { useState } from 'react';
import { BackIcon } from 'assets/icons/withContainer';
import { FlexContainer } from 'styles/FlexContainer';
import { ArrowIcon, DownSvg, MenuElem, SelectedIcon } from 'components/grid/headers/LoadHeaderMangerProject/styles';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { logout } from 'store/reducers/auth/actions';
import { useAppDispatch } from 'store';
import { ClickAwayListener } from '@mui/material';
import { defaultThemes } from 'store/reducers/themes/constants';
import { KeysLocalStorage } from 'constants/LocalStorage';
import { setActiveThemeAction } from 'store/reducers/themes/actions';
import { useSelector } from 'react-redux';
import { getActiveThemeId } from 'store/reducers/themes/getters';

export const LoadHeaderAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const activeThemeId = useSelector(getActiveThemeId);

  const [mainMenuOpen, setMainMenuOpen] = useState(false);

  const [themeMenuOpen, setThemeMenuOpen] = useState(false);

  const activeKey = Object.entries(RoutesURL).reduce<string>((result, [key, value]) => {
    return value === getLastPath(pathname) ? key : result;
  }, '') as LoadAdminRoute;

  const onMenuClick = (key: LoadAdminRoute) => {
    navigate(`/${RoutesURL[key]}`);
  };

  const handleClickMainMenu = () => setMainMenuOpen((prev) => !prev);
  const handleCloseMainMenu = () => {
    setMainMenuOpen(false);
  };

  const handleOpenThemeMenu = () => setThemeMenuOpen(true);
  const handleCloseThemeMenu = () => setThemeMenuOpen(false);

  const handleChangeCommonActiveTheme = (themeId: string) => {
    localStorage.setItem(KeysLocalStorage.activeCommonThemeId, themeId);
    dispatch(setActiveThemeAction(themeId));
    handleCloseMainMenu();
  };

  return (
    <HeaderLayout
      LeftSide={
        <IconWrapper
          hoverColorVar={ColorVarsEnum.Level_2}
          colorVar={ColorVarsEnum.Level_3}
          iconHeight="24px"
          iconWidth="24px"
          Icon={BackIcon}
          onClick={() => navigate(RoutesURL.projectsList)}
        />
      }
      MiddleSide={<HeaderMenu items={menuItemsAdmin} activeItemKey={activeKey} onItemClick={onMenuClick} />}
      RightSide={
        <ClickAwayListener onClickAway={handleCloseMainMenu}>
          <FlexContainer position="relative">
            <IconWrapper
              hoverColorVar={ColorVarsEnum.Level_3}
              colorVar={mainMenuOpen ? ColorVarsEnum.Level_3 : ColorVarsEnum.Level_3}
              containerWidth="50px"
              containerHeight="49px"
              iconHeight="24px"
              iconWidth="24px"
              Icon={DownSvg}
              onClick={handleClickMainMenu}
            />

            {mainMenuOpen && (
              <>
                <FlexContainer
                  position="absolute"
                  backgroundColor={`var(${ColorVarsEnum.Level_3_menu})`}
                  top="50px"
                  right="0px"
                  padding="4px 0"
                  flexDirection="column"
                  boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                  zIndex="1000"
                >
                  <MenuElem onMouseEnter={handleOpenThemeMenu} onMouseLeave={handleCloseThemeMenu}>
                    <PrimaryTextSpan fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                      Тема интерфейса
                    </PrimaryTextSpan>
                    <ArrowIcon />

                    {themeMenuOpen && (
                      <FlexContainer
                        position="absolute"
                        backgroundColor={`var(${ColorVarsEnum.Level_3_menu})`}
                        top="0"
                        right="100%"
                        padding="4px 0"
                        flexDirection="column"
                        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                        zIndex="1000"
                      >
                        {defaultThemes.map(({ id, name }) => (
                          <MenuElem key={id} onClick={() => handleChangeCommonActiveTheme(id)}>
                            {(activeThemeId != null ? activeThemeId === id : defaultThemes[0].id === id) && <SelectedIcon />}

                            <PrimaryTextSpan fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                              {name}
                            </PrimaryTextSpan>
                          </MenuElem>
                        ))}
                      </FlexContainer>
                    )}
                  </MenuElem>
                  <MenuElem onClick={() => navigate(RoutesURL.projectsList)}>
                    <PrimaryTextSpan fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                      Менеджер проектов
                    </PrimaryTextSpan>
                  </MenuElem>
                  <MenuElem onClick={() => dispatch(logout())}>
                    <PrimaryTextSpan fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                      Выход
                    </PrimaryTextSpan>
                  </MenuElem>
                </FlexContainer>
              </>
            )}
          </FlexContainer>
        </ClickAwayListener>
      }
    />
  );
};
