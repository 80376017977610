import { useState, useEffect } from 'react';

export const useHoverVisible = (isError: boolean) => {
  const [isVisibleError, setIsVisibleError] = useState<boolean>(true);
  const [currentElementHover, setCurrentElementHover] = useState<boolean>(false);

  const onMouseElementEnter = () => {
    setIsVisibleError(true);
    setCurrentElementHover(true);
  };

  const onMouseElementLeave = () => {
    setIsVisibleError(false);
    setCurrentElementHover(false);
  };

  useEffect(() => {
    if (isError) {
      if (currentElementHover) {
        return;
      }

      const timer = setTimeout(() => {
        setIsVisibleError(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isError, currentElementHover]);

  return { isVisibleError, onMouseElementEnter, onMouseElementLeave, setIsVisibleError };
};
