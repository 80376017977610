import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button } from 'modules/ui';
import { ModalComponentType } from 'store/reducers/modals/types';
import { InfoProject } from 'components/projectsManager/elements/Projects/ProjectsSearchList/Info';
import { NoopValueType } from 'types/global';
import { AvatarProjectInterface } from 'types/types';

interface InfoProjectModalProps {
  projectId: string;
  onChangeAvatar?: NoopValueType<AvatarProjectInterface>;
}

export const InfoProjectModal: ModalComponentType<InfoProjectModalProps> = ({ onClose, projectId, onChangeAvatar }) => {
  return (
    <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" padding="12px">
      <InfoProject onChangeAvatar={onChangeAvatar} padding="0" activeProjectId={projectId} />

      <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
        <Button text="Закрыть" iconSize="normal" heightSize="normal" onClick={onClose} />
      </FlexContainer>
    </FlexContainer>
  );
};
