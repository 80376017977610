import React from 'react';
import { NoopType } from 'types/global';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ByFileIcon } from 'assets/icons/source';
import { FolderIcon } from 'assets/icons/withContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { FolderElementContainer } from 'components/console/elements/SourcesConnection/FileFoldersModal/Folder/styles';
import { FolderElementData } from 'components/console/elements/SourcesConnection/FileFoldersModal/types';

interface FolderElementProps extends FolderElementData {
  onClick: NoopType;
  isSelected: boolean;
}

export const FolderElement = ({ name, isFile, onClick, isSelected }: FolderElementProps) => {
  return (
    <FolderElementContainer
      isSelected={isSelected}
      cursor="pointer"
      padding="8px"
      flex="0 0 32px"
      alignItems="center"
      onClick={onClick}
      onDoubleClick={(e) => e.preventDefault()}
      width="100%"
      borderBottom={`1px solid var(${ColorVarsEnum.Level_5})`}
    >
      <IconWrapper
        Icon={isFile ? ByFileIcon : FolderIcon}
        colorVar={ColorVarsEnum.Level_3}
        iconHeight="16px"
        iconWidth="16px"
        opacity={0}
      />
      <PrimaryTextSpan
        marginLeft="8px"
        color={isFile ? `var(${ColorVarsEnum.Level_2})` : `var(${ColorVarsEnum.Level_1})`}
        fontSize="14px"
      >
        {name}
      </PrimaryTextSpan>
    </FolderElementContainer>
  );
};
