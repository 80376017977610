import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Snackbar from 'services/Snackbar';
import {
  deleteByIdFlowProjectAction,
  deleteFlowProjectProjects,
  protectFlowProjectAction,
  renameFlowProjectAction,
  updateFlowProjectByIdAction,
} from 'store/reducers/adminFlows/actions';
import { InformationBar } from 'modules/ui/ManagerOrAdminComponentsUI/InformationBarUI';
import { goToBoardUrl } from 'constants/Routes';
import { useFlowProjectsList } from 'components/admin/flows/elements/Projects/hooks/useFlowProjectsList';
import { ProjectsSearchUI } from 'modules/ui/ManagerOrAdminComponentsUI/ProjectsSearchUI';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { setActiveFlowProjectId } from 'store/reducers/adminFlows';
import { getActiveFlow, getActiveFlowProjectId, getSortFlowProject } from 'store/reducers/adminFlows/getters';
import { openConfirmationModalAction } from 'store/reducers/modals/actions';
import { uploadProjectAvatarAction } from 'store/reducers/projectManager/actions';
import { useModalState } from 'utils/hooks/modalState';
import { useModalActions } from 'utils/hooks/useModalActions';
import { useRole } from 'utils/hooks/useRole';
import { HistoryVersionFlowProject } from 'components/admin/flows/elements/Projects/HistoryVersionFlowProject';
import { InfoProject } from 'components/admin/flows/elements/Projects/InfoProject';
import { loadSortSettings, saveSortSettings } from 'utils/SortSettingsStorage';
import { SORT_FLOW_PROJECT_SETTINGS, modalConfigs } from 'components/admin/flows/elements/Projects/constants';
import { FlexContainer } from 'styles/FlexContainer';
import { copyingFlowProject } from 'components/admin/flows/elements/Projects/Modals/CopyFlowProjectModal/constants';
import { moveFlowProject } from 'components/admin/flows/elements/Projects/Modals/MoveFlowProjectModal/constants';
import { infoFlowProject } from 'components/admin/flows/elements/Projects/Modals/InfoFlowProjectModal/constants';
import { rollBackFlowProject } from 'components/admin/flows/elements/Projects/Modals/RollBackFlowProjectModal/constants';
import { useSelector } from 'react-redux';
import { ModalNameType } from 'components/admin/flows/elements/Projects/types';
import { MenuItemInterface } from 'modules/ui/menu/ContextMenu/types';
import { SortProject } from 'store/reducers/projectManager/types';
import { Tab } from 'modules/ui/tabs/types';
import { AvatarProjectInterface } from 'types/types';

export const ProjectsTab: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const activeFlow = useSelector(getActiveFlow);
  const activeFlowProjectId = useSelector(getActiveFlowProjectId);
  const sortFlowProject = useSelector(getSortFlowProject);
  const { isViewer } = useRole();

  const activeFlowId = activeFlow?.id || '';

  const [sortSettings, setSortSettings] = useState<SortProject>(sortFlowProject);
  const [informationProject, setInformation] = useState<{ id: string; name: string } | null>(null);

  const { isOpen, onOpen, onClose } = useModalState();
  const { flowProjectsList, loading } = useFlowProjectsList({ id: activeFlowId, onClose });
  const { openModal } = useModalActions<ModalNameType>(modalConfigs);

  const activeProject = useMemo(
    () => flowProjectsList?.find(({ id }) => id === activeFlowProjectId),
    [flowProjectsList, activeFlowProjectId],
  );
  const activeFlowProjectName = activeProject?.title;
  const isProtected = !!activeProject?.isProtected;

  const onBoardAreaClick = (id: string | null) => {
    dispatch(setActiveFlowProjectId(id));
  };

  const onCloseMenu = () => {
    dispatch(setActiveFlowProjectId(null));
  };

  const openFlowProject = useCallback(
    ({ projectId, pageId }: { projectId: string; pageId: string | null }) => navigate(goToBoardUrl(projectId, pageId || '0')),
    [navigate],
  );

  const onOpenInformationModal = ({ id, name }: { id: string; name: string }) => {
    setInformation({ id, name });
    !isOpen && onOpen();
  };

  const onCloseInformationModal = () => {
    setInformation(null);
    onClose();
  };

  const onChangeSortProject = useCallback(
    ({ sortOrder, sortType }: SortProject) => {
      setSortSettings({ sortOrder, sortType });

      saveSortSettings({
        SHORT_KEY: SORT_FLOW_PROJECT_SETTINGS,
        streamId: activeFlowId,
        settings: { sortOrder, sortType },
      });
    },
    [activeFlowId],
  );

  useEffect(() => {
    const settings = loadSortSettings({ streamId: activeFlowId, SHORT_KEY: SORT_FLOW_PROJECT_SETTINGS });

    if (settings) {
      setSortSettings(settings);
    }
  }, [activeFlowId]);

  const onProtectProject = useCallback(
    async () => {
      try {
        if (activeFlowProjectId) {
          const resProtect = await dispatch(
            protectFlowProjectAction({ projectId: activeFlowProjectId, isProtected: !isProtected }),
          ).unwrap();

          if (resProtect && activeProject) {
            dispatch(updateFlowProjectByIdAction({ userDraftProject: { ...activeProject, isProtected: !isProtected } }));
          }
        }
      } catch (error) {
        console.error('Error ProtectProject:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFlowProjectId, isProtected, activeProject],
  );

  const hasName = (name: string) => flowProjectsList.find(({ title }) => title === name);

  const onRenameProject = useCallback(
    async (title: string) => {
      if (!title) {
        return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
      }

      if (hasName(title)) {
        return Snackbar.show('В потоке уже есть проект с таким названием. Измените название и повторите попытку', 'error');
      }

      try {
        if (activeFlowProjectName && activeFlowProjectId) {
          const resProtect = await dispatch(renameFlowProjectAction({ id: activeFlowProjectId, name: title })).unwrap();

          if (resProtect && activeProject) {
            dispatch(updateFlowProjectByIdAction({ userDraftProject: resProtect }));
          }
        }
      } catch (error) {
        console.error('Error onRenameProject', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFlowProjectName, activeFlowProjectId, activeProject, isProtected],
  );

  const onRenameProjectModal = useCallback(
    async () => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Переименовать',
          onConfirm: (title) => onRenameProject(title?.trim() || ''),
          valueInput: activeFlowProjectName,
          isRenamable: true,
          renamableTitle: 'Новое название проекта',
          titleText: 'Переименование проекта',
          width: '320px',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFlowProjectName, onRenameProject],
  );

  const onChangeAvatar = useCallback(
    async ({ avatar, projectId }: AvatarProjectInterface) => {
      if (!projectId) return;

      const image = new FormData();
      image.append('avatar', avatar || '');

      const response = await dispatch(uploadProjectAvatarAction({ projectId, avatar: image })).unwrap();

      if (!response) return;

      dispatch(updateFlowProjectByIdAction({ userDraftProject: response }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleOpenModal = useCallback(
    (modalName: ModalNameType) => {
      openModal(modalName, {
        projectId: activeFlowProjectId || '',
        projectName: activeFlowProjectName || '',
        activeProject,
        activeFlowId: activeFlowId || '',
        flowId: activeFlowId || '',
        isProtected,
        onChangeAvatar,
      });
    },
    [activeFlowId, onChangeAvatar, activeFlowProjectId, activeFlowProjectName, activeProject, isProtected, openModal],
  );

  const onDeleteProject = useCallback(
    async () => {
      try {
        if (activeFlowProjectId && activeFlowProjectName) {
          const resProtect = await dispatch(deleteFlowProjectProjects({ projectId: activeFlowProjectId })).unwrap();

          if (resProtect) {
            dispatch(deleteByIdFlowProjectAction(activeFlowProjectId));

            onClose();
          }
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFlowProjectId, onClose, activeFlowProjectName],
  );

  const onDeleteProjectModal = useCallback(
    async () => {
      dispatch(
        openConfirmationModalAction({
          titleText: 'Удаление проекта',
          onConfirm: onDeleteProject,
          subTitleText: `Действительно удалить проект «${activeFlowProjectName}»?`,
          confirmationButtonText: 'Удалить',
          width: '320px',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, onDeleteProject, activeFlowProjectName],
  );

  const modelMetaDataList = flowProjectsList.map(({ id, firstPage, avatar, ...props }) => ({
    ...props,
    items: [],
    type: id,
    meta: firstPage,
    image: avatar,
  }));

  const menuList: MenuItemInterface[] = useMemo(() => {
    if (isViewer) {
      return [
        {
          title: 'О проекте',
          onClick: () => handleOpenModal(infoFlowProject),
        },
      ];
    }
    return [
      {
        title: 'Копировать',
        onClick: () => handleOpenModal(copyingFlowProject),
      },
      {
        title: 'Переместить',
        onClick: () => handleOpenModal(moveFlowProject),
      },
      {
        title: 'Откатить',
        onClick: () => handleOpenModal(rollBackFlowProject),
      },
      {
        title: isProtected ? 'Снять защиту' : 'Защитить',
        onClick: onProtectProject,
      },
      {
        title: 'Переименовать',
        onClick: onRenameProjectModal,
      },
      {
        title: 'О проекте',
        onClick: () => handleOpenModal(infoFlowProject),
      },
      {
        title: 'Удалить',
        onClick: onDeleteProjectModal,
      },
    ];
  }, [isViewer, isProtected, onProtectProject, onRenameProjectModal, onDeleteProjectModal, handleOpenModal]);

  const tabs: Tab[] = useMemo(
    () =>
      [
        {
          content: 'Инфо',
          prompt: 'Инфо',
          type: 'text',
          id: 'info',
          Component: () => <InfoProject activeProjectId={informationProject?.id || ''} onChangeAvatar={onChangeAvatar} />,
        },

        {
          content: 'Версии',
          prompt: 'Версии',
          type: 'text',
          id: 'version',
          Component: () => (
            <HistoryVersionFlowProject
              projectId={informationProject?.id || ''}
              projectName={informationProject?.name}
              padding="0"
            />
          ),
        },
      ].filter(({ Component }) => Component) as Tab[],
    [informationProject, onChangeAvatar],
  );

  return (
    <FlexContainer flexDirection="row" width="100%" height="100Vh">
      <ProjectsSearchUI
        modelMetaDataList={modelMetaDataList || []}
        projectsLoading={loading}
        shortProject={sortSettings}
        isViewer={isViewer}
        isOpenInformationBar={isOpen}
        disabledAddCard={false}
        menuList={menuList}
        onOpenFlowProject={openFlowProject}
        onBoardAreaClick={onBoardAreaClick}
        onCloseMenu={onCloseMenu}
        activeProjectId={activeFlowProjectId || ''}
        onOpenInformationModal={onOpenInformationModal}
        onCloseInformationModal={onCloseInformationModal}
        informationOpenId={informationProject?.id || ''}
        onChangeShortProject={onChangeSortProject}
        onAddItem={() => {}}
      />
      <InformationBar open={isOpen} onClose={onCloseInformationModal} tabs={tabs} />
    </FlexContainer>
  );
};
