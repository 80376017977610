import styled, { css } from 'styled-components';
import { settingsLayoutMaxHeight, settingsLayoutWidth } from 'modules/settingsContainer/SettingsLayout/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { MainContainer } from 'modules/settingsContainer/MainContainerSettings';
import { bottomLineMixin } from 'constants/styles';

export const settingsLayoutMixin = css`
  display: flex;
  flex-direction: column;
  width: ${settingsLayoutWidth};
  max-height: ${settingsLayoutMaxHeight};
  background-color: var(${ColorVarsEnum.Level_3_menu});
  box-shadow: 0 2px 70px rgba(0, 0, 0, 0.35);
  z-index: 1;
`;

export const SettingsLayoutWrapper = styled.div`
  position: absolute;
  ${settingsLayoutMixin};
`;

export const ContainerChildren = styled.div`
  ${MainContainer}:not(:last-child) {
    :after {
      ${bottomLineMixin}
    }
  }
`;
