import { ChangeEvent, FC } from 'react';
import { RadioGroup, FormControlLabelProps, useRadioGroup } from '@mui/material';
import { StyledFormControlLabel, StyledRadio } from 'shared/ui/RadioButton/styles';
import { RadioButtonOptionsType } from 'shared/ui/RadioButton/types';
import { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup';

interface RadioButtonProps extends RadioGroupProps {
  name: string;
  defaultValue?: string;
  options: RadioButtonOptionsType[];
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

const MyFormControlLabel = (props: FormControlLabelProps) => {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
};

export const RadioButton: FC<RadioButtonProps> = ({ options, ...props }) => {
  return (
    <RadioGroup {...props}>
      {options.map(({ value, label, component }) => (
        <>
          <MyFormControlLabel key={`radio-item-${value}`} value={value} control={<StyledRadio disableRipple />} label={label} />
          {component && component}
        </>
      ))}
    </RadioGroup>
  );
};
