import { CSSProperties, memo } from 'react';
import { areEqual } from 'react-window';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FlexContainer } from 'styles/FlexContainer';
import { FiltersValuesType } from 'store/reducers/filters/types';

interface RowItemProps {
  index: number;
  style: CSSProperties;
  data: {
    values: FiltersValuesType;
  };
}

export const DynamicValuesRow = memo(({ index, style, data }: RowItemProps) => {
  const { values } = data;
  const currentValue = values[index];
  const { count, value } = currentValue;

  const key = `${value}_${count}`;

  return (
    <FlexContainer style={style} key={key} justifyContent="space-between" padding="5px" alignItems="center">
      <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_1})`}>{value}</PrimaryTextSpan>
      <PrimaryTextSpan lineHeight="12px">{count}</PrimaryTextSpan>
    </FlexContainer>
  );
}, areEqual);

DynamicValuesRow.displayName = 'DynamicValuesRow';
