import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCell } from 'modules/ui/Table/TableCell';
import { SourceModal } from 'components/admin/groups/elements/Sources/Modal/SourceModal';
import { useAppDispatch } from 'store';
import { getActiveGroup, getGroupSources } from 'store/reducers/adminGroups/getters';
import {
  deleteByIdGroupSourceAction,
  deleteGroupSourceAction,
  loadGroupSourcesAction,
  loadNoGroupSourcesAction,
} from 'store/reducers/adminGroups/actions';
import { TableWithSearch } from 'modules/ui/TableWithSearch';
import {
  closeConfirmationModalAction,
  closeModalAction,
  openConfirmationModalAction,
  openModalTypedAction,
} from 'store/reducers/modals/actions';
import { accessText, addGroupSource, editGroupSource } from 'components/admin/groups/constants';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { GroupSourcesListInterface } from 'store/reducers/adminGroups/types';

export const AdminGroupsSourcesTab: FC = () => {
  const dispatch = useAppDispatch();

  const activeGroup = useSelector(getActiveGroup);
  const activeGroupId = activeGroup?.id;

  const { loading, groupSourcesList } = useSelector(getGroupSources);

  useEffect(() => {
    if (activeGroupId) {
      dispatch(loadGroupSourcesAction(activeGroupId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGroup]);

  const onCloseAddGroupSourceModal = () => dispatch(closeModalAction(addGroupSource));

  const onAddModal = useCallback(
    async () => {
      try {
        if (!activeGroupId) {
          return;
        }

        const noGroupSources = await dispatch(loadNoGroupSourcesAction(activeGroupId)).unwrap();
        const selectData = noGroupSources.map((source) => ({ title: source.name, value: source.id }));

        dispatch(
          openModalTypedAction({
            Component: SourceModal,
            componentProps: {
              onClose: onCloseAddGroupSourceModal,
              selectData,
              mode: 'add',
            },
            modalSettings: {
              headerText: 'Добавление доступа',
            },
            name: addGroupSource,
          }),
        );
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeGroupId, onCloseAddGroupSourceModal],
  );

  const onCloseEditGroupSourceModal = () => dispatch(closeModalAction(editGroupSource));

  const onEditModal = useCallback(
    (source: GroupSourcesListInterface) =>
      dispatch(
        openModalTypedAction({
          Component: SourceModal,
          componentProps: {
            onClose: onCloseEditGroupSourceModal,
            source,
            mode: 'edit',
          },
          modalSettings: {
            headerText: 'Изменения прав доступа',
            width: '320px',
          },
          name: editGroupSource,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseEditGroupSourceModal],
  );

  const onDelete = useCallback(
    async (source: GroupSourcesListInterface) => {
      try {
        if (!activeGroupId) {
          return;
        }

        const sourceId = source.id;

        const response = await dispatch(deleteGroupSourceAction({ sourceId, groupId: activeGroupId })).unwrap();

        if (response) {
          dispatch(deleteByIdGroupSourceAction(sourceId));
          dispatch(closeConfirmationModalAction());
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeGroupId],
  );

  const onDeleteModal = useCallback(
    async (source: GroupSourcesListInterface) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          description: `Действительно удалить доступ группы к подключению «${source.name}»?`,
          onConfirm: () => onDelete(source),
          titleText: 'Удаление доступа',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  const columnHelper = createColumnHelper<GroupSourcesListInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Источник',
        cell: (info) => (
          <TableCell
            text={info.getValue()}
            actions={{
              onRemove: async () => {
                await onDeleteModal(info.row.original);
              },
            }}
          />
        ),
      }),
      columnHelper.accessor('sourceGroupType', {
        header: 'Права',
        cell: (info) => {
          const rowData = info.row.original;

          return (
            <TableCell
              text={accessText[rowData.sourceGroupType]}
              actions={{
                onEdit: async () => {
                  await onEditModal(rowData);
                },
              }}
            />
          );
        },
      }),
    ],
    [columnHelper, onDeleteModal, onEditModal],
  );

  return (
    <FlexContainer position="relative" width="100%" padding="24px" maxWidth="800px">
      <TableWithSearch
        filterColumnName="name"
        columns={columns}
        rows={groupSourcesList}
        onAddModal={onAddModal}
        loading={loading}
      />
    </FlexContainer>
  );
};
