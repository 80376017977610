import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, TextField } from 'modules/ui';
import { getActiveGroup, getGroups } from 'store/reducers/adminGroups/getters';
import {
  addGroupAction,
  changeActiveGroupAction,
  createGroupAction,
  updateGroupAction,
  updateGroupsAction,
} from 'store/reducers/adminGroups/actions';
import { Form } from './styles';
import { loadUsersAction } from 'store/reducers/adminUsers/actions';
import { useAppDispatch } from 'store';
import { getUsers } from 'store/reducers/adminUsers/getters';
import { MultiSelect } from 'modules/ui/MultiSelect';
import { NoopType } from 'types/global';
import { ModalComponentType } from 'store/reducers/modals/types';
import { GroupFormType } from 'components/admin/groups/types';
import { ModeForm } from 'types/store';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { AccessUserIcon } from 'assets/icons/access';
import Snackbar from 'services/Snackbar';

interface GroupModalProps {
  mode: ModeForm;
  onClose?: NoopType;
}

export const GroupModal: ModalComponentType<GroupModalProps> = ({ mode, onClose }) => {
  const dispatch = useAppDispatch();
  const isAdd = mode === 'add';

  const { groupsList } = useSelector(getGroups);
  const { usersList } = useSelector(getUsers);
  const activeGroup = useSelector(getActiveGroup);
  const activeGroupId = activeGroup?.id;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(loadUsersAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm<GroupFormType>({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (activeGroup && !isAdd) {
      setValue('title', activeGroup.title);
    }
  }, [activeGroup, setValue, isAdd]);

  const hasName = useCallback((title: string) => groupsList.find((obj) => obj.title === title), [groupsList]);

  const onSubmit: SubmitHandler<GroupFormType> = useCallback(
    async ({ title, users }) => {
      const titleTrim = title.trim();
      let actionUserRes;

      if (!isDirty) {
        return null;
      }

      setLoading(true);

      try {
        if (isAdd) {
          if (hasName(title)) {
            setLoading(false);

            return Snackbar.show('Группа с таким названием уже существует', 'error');
          }

          actionUserRes = await dispatch(createGroupAction({ title: titleTrim, users })).unwrap();

          if (actionUserRes) {
            dispatch(addGroupAction(actionUserRes));
            dispatch(changeActiveGroupAction(actionUserRes));
          }
        }

        if (!isAdd && activeGroupId) {
          actionUserRes = await dispatch(updateGroupAction({ id: activeGroupId, title: titleTrim })).unwrap();
          if (actionUserRes) {
            const { id, title } = actionUserRes;
            dispatch(updateGroupsAction({ group: { id, title } }));
            dispatch(changeActiveGroupAction({ id, title }));
          }
        }

        if (onClose) {
          onClose();
        }
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDirty, isAdd, activeGroupId, onClose, hasName],
  );

  const options = useMemo(
    () => usersList.map((el) => ({ title: el.login, value: el.id, icon: AccessUserIcon, data: el })),
    [usersList],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={loading} />

      <FlexContainer width="100%" flexDirection="column" gap="16px">
        <Controller
          name="title"
          rules={{ required: true }}
          control={control}
          render={({ field }) => <TextField error={!!errors.title} label="Название" width="100%" {...field} />}
        />

        {isAdd && (
          <Controller
            name="users"
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange } }) => (
              <MultiSelect
                label="Пользователи"
                noOptionsText="Пользователи не найдены"
                onChange={(item) => onChange(item.map((el) => el.value))}
                options={options}
              />
            )}
          />
        )}
      </FlexContainer>

      <FlexContainer width="100%" justifyContent="flex-end" gap="10px" marginTop="32px" flexDirection="row">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Сохранить" iconSize="normal" heightSize="normal" type="submit" />
      </FlexContainer>
    </Form>
  );
};
