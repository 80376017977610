import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const PieDefault: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fill = `var(${ColorVarsEnum.Accent})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <path
          opacity="0.35"
          d="M15.9187 49.2774C18.4922 52.8653 22.1354 55.5464 26.3262 56.9365C30.5171 58.3266 35.0404 58.3544 39.248 57.0158C43.4556 55.6772 47.1314 53.041 49.7487 49.4849C52.366 45.9289 53.7904 41.6356 53.8178 37.2203L40.1933 37.1359C40.1838 38.6741 39.6875 40.1698 38.7757 41.4086C37.8639 42.6475 36.5833 43.5659 35.1175 44.0323C33.6516 44.4986 32.0758 44.4889 30.6157 44.0046C29.1557 43.5204 27.8865 42.5863 26.9899 41.3364L15.9187 49.2774Z"
          fill={fillDefault}
        />
        <path
          opacity="0.75"
          d="M32.9091 16.1816C29.0541 16.1816 25.2741 17.2474 21.9869 19.2611C18.6996 21.2748 16.0331 24.1581 14.2819 27.5924C12.5307 31.0267 11.763 34.8783 12.0637 38.7215C12.3644 42.5648 13.7217 46.2501 15.9857 49.3703L27.0133 41.3687C26.2245 40.2817 25.7517 38.9978 25.6469 37.6589C25.5422 36.32 25.8096 34.9781 26.4197 33.7817C27.0298 32.5853 27.9588 31.5808 29.104 30.8792C30.2492 30.1777 31.5661 29.8064 32.9091 29.8064V16.1816Z"
          fill={fillDefault}
        />
        <path
          d="M58.0001 32.9091C58.0001 30.1633 57.4592 27.4443 56.4085 24.9075C55.3577 22.3707 53.8175 20.0657 51.8759 18.1241C49.9343 16.1825 47.6293 14.6424 45.0925 13.5916C42.5557 12.5408 39.8368 12 37.091 12V25.6247C38.0476 25.6247 38.9948 25.8132 39.8786 26.1792C40.7624 26.5453 41.5654 27.0819 42.2418 27.7583C42.9182 28.4347 43.4548 29.2377 43.8208 30.1215C44.1869 31.0053 44.3753 31.9525 44.3753 32.9091H58.0001Z"
          fill={fill}
        />
      </svg>
    </FlexContainer>
  );
};
