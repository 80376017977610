import { GroupModal } from 'components/admin/groups/Modal/GroupModal';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { LoadLayoutAdmin } from 'components/layouts/LoadLayoutAdmin';
import { UniversalLeftBarList } from 'modules/settingsContainer/UniversalLeftBarList';
import { getActiveGroup, getGroups } from 'store/reducers/adminGroups/getters';
import { SectionMiddleUI } from 'modules/ui/ManagerOrAdminComponentsUI/SectionMiddleUI';
import { AdminGroupsUserTab, AdminGroupsSourcesTab, AdminGroupsFlowsTab } from './elements';
import { changeActiveGroupAction, loadGroupsAction } from 'store/reducers/adminGroups/actions';
import { closeModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { useDeleteGroup } from './hook/useDeleteGroup';
import { useAppDispatch } from 'store';
import { useRole } from 'utils/hooks/useRole';
import { useSelector } from 'react-redux';
import { createGroup, editGroup } from './constants';
import { UsersIcon } from 'assets/icons/navigations';
import { GroupsListInterface } from 'store/reducers/adminGroups/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Tab } from 'modules/ui/tabs/types';
import styled from 'styled-components';

export const AdminGroups: FC = () => {
  const dispatch = useAppDispatch();
  const { isViewer } = useRole();

  const { loading, groupsList } = useSelector(getGroups);
  const activeGroup = useSelector(getActiveGroup);
  const activeGroupId = activeGroup?.id;
  const activeGroupTitle = activeGroup?.title;

  const { onDeleteModal } = useDeleteGroup();

  useEffect(() => {
    dispatch(loadGroupsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!activeGroupId && groupsList.length > 0) void onSelectedItem({ id: groupsList[0]?.id, title: groupsList[0]?.title });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewer, groupsList]);

  const modelMetaDataList = groupsList?.map(({ id, title }) => ({
    items: [],
    id: id,
    title,
    type: title,
  }));

  const tabs: Tab[] = useMemo(
    () =>
      [
        {
          content: 'Пользователи',
          prompt: 'Пользователи',
          type: 'text',
          id: 'users',
          Component: AdminGroupsUserTab,
        },
        {
          content: 'Потоки',
          prompt: 'Потоки',
          type: 'text',
          id: 'flows',
          Component: AdminGroupsFlowsTab,
        },
        {
          content: 'Подключения',
          prompt: 'Подключения',
          type: 'text',
          id: 'sources',
          Component: AdminGroupsSourcesTab,
        },
      ].filter(({ Component }) => Component) as Tab[],
    [],
  );

  const onSelectedItem = useCallback(
    async ({ id, title }: GroupsListInterface) => {
      dispatch(changeActiveGroupAction({ id, title }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onCloseCreateGroupModal = () => dispatch(closeModalAction(createGroup));

  const onCreateGroupModal = useCallback(() => {
    dispatch(
      openModalTypedAction({
        Component: GroupModal,
        componentProps: {
          onClose: onCloseCreateGroupModal,
          mode: 'add',
        },
        modalSettings: {
          position: 'static',
          width: '320px',
          padding: '24px 16px',
          headerText: 'Создание группы пользователей',
        },
        name: createGroup,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCloseCreateGroupModal]);

  const onCloseEditGroupModal = () => dispatch(closeModalAction(editGroup));

  const onEditGroupModal = useCallback(() => {
    dispatch(
      openModalTypedAction({
        Component: GroupModal,
        componentProps: {
          onClose: onCloseEditGroupModal,
          mode: 'edit',
        },
        modalSettings: {
          position: 'static',
          width: '320px',
          padding: '24px 16px',
          headerText: 'Изменение группы пользователей',
        },
        name: editGroup,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCloseEditGroupModal]);

  return (
    <LoadLayoutAdmin>
      <Body>
        <UniversalLeftBarList
          buttonTitle="Создать группу"
          disabledMobileVersion
          data={modelMetaDataList}
          activeTitle={activeGroupTitle}
          activeId={activeGroupId}
          loadingList={loading}
          disabledAdditionalField
          onSelectedItem={onSelectedItem}
          onDeleteItem={onDeleteModal}
          onCreateItem={onCreateGroupModal}
        />

        {activeGroupTitle && activeGroupId && (
          <SectionMiddleUI
            tabs={tabs}
            title={activeGroupTitle}
            id={activeGroupId}
            onEditModal={onEditGroupModal}
            onDeleteModal={onDeleteModal}
            isAccessControl
            HeaderIcon={UsersIcon}
            disabledMobileVersion
          />
        )}
      </Body>
    </LoadLayoutAdmin>
  );
};

const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background: ${`var(${ColorVarsEnum.Level_5_application})`};
  flex-wrap: nowrap;
`;
