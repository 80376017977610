import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ModalComponentType } from 'store/reducers/modals/types';
import { closeConfirmationModalAction, openConfirmationModalAction } from 'store/reducers/modals/actions';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { CopyProjectModalComponentUI } from 'modules/ui/ManagerOrAdminComponentsUI/ModalsUI/CopyProjectModalUI';
import Snackbar from 'services/Snackbar';
import { RestoreVersionCallbackProps } from 'modules/ui/ManagerOrAdminComponentsUI/ModalsUI/CopyProjectModalUI/types';
import { getDraftsOfAllUsers } from 'store/reducers/adminUsers/getters';
import {
  copyUserDraftProjectVersionAnotherAction,
  createUserDraftProjectVersionAnotherAction,
  loadDraftProjectsAction,
  loadShortDraftUserProjectAction,
} from 'store/reducers/adminUsers/actions';
import { FlowType } from './types';

interface CopyUserDraftProjectModalProps {
  projectId: string;
  flowId: string;
  projectName: string;
}

export const CopyUserDraftProjectModal: ModalComponentType<CopyUserDraftProjectModalProps> = ({
  onClose,
  projectId,
  projectName,
  flowId,
}) => {
  const dispatch = useAppDispatch();

  const { draftsOfAllUsersList } = useSelector(getDraftsOfAllUsers);

  const flowsItems = useMemo(
    () => draftsOfAllUsersList?.map((flow) => ({ name: flow?.id || '', value: flow?.name || '' })),
    [draftsOfAllUsersList],
  );

  const [selectFlows, setSelectFlows] = useState<FlowType>(flowsItems[0]);

  const [userDraftProjectsList, setUserDraftProjectsList] = useState<FlowType[]>([]);
  const selectFlowsMame = selectFlows?.name;
  const selectFlowName = selectFlows?.name;

  const getFlowData = useCallback(async () => {
    if (!selectFlowName) return;

    try {
      const actionResult = await dispatch(loadShortDraftUserProjectAction(selectFlowName));
      const unwrappedResult = unwrapResult(actionResult);
      const formattedFlowList = unwrappedResult.map((flow) => ({ name: flow.id, value: flow.title }));

      setUserDraftProjectsList(formattedFlowList);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFlowName]);

  useEffect(() => {
    if (selectFlowName) {
      void getFlowData();
    }
  }, [getFlowData, projectId, selectFlowName]);

  const onCopyProjectVersionAnother = useCallback(
    async ({
      replaceExistingChecked,
      selectProjectId,
      renameValue,
      runImport,
    }: {
      replaceExistingChecked: boolean;
      selectProjectId?: string;
      renameValue: string;
      runImport: boolean;
    }) => {
      try {
        let actionCopyProjectVersion;
        let actionCreateProjectVersion;

        if (!replaceExistingChecked && selectFlowsMame) {
          actionCreateProjectVersion = await dispatch(
            createUserDraftProjectVersionAnotherAction({ projectId, name: renameValue, flowId: selectFlowsMame, runImport }),
          ).unwrap();
        }

        if (replaceExistingChecked && selectProjectId) {
          actionCopyProjectVersion = await dispatch(
            copyUserDraftProjectVersionAnotherAction({ projectFromId: projectId, projectToId: selectProjectId, runImport }),
          ).unwrap();
        }

        if (actionCreateProjectVersion || actionCopyProjectVersion) {
          await dispatch(loadDraftProjectsAction({ userId: flowId }));
          dispatch(closeConfirmationModalAction());
          onClose();
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectFlowsMame, projectId, flowId, onClose],
  );

  const hasName = useCallback(
    (renameValue: string) => userDraftProjectsList.find(({ value }) => value === renameValue),
    [userDraftProjectsList],
  );

  const onRestoreVersionModal = useCallback(
    async ({ renameValue, replaceExistingChecked, selectProject, flowsName, runImport }: RestoreVersionCallbackProps) => {
      if (!flowsName) {
        return Snackbar.show('Поле «Поток» обязательно для заполнения', 'error');
      }

      if (replaceExistingChecked && !selectProject) {
        return Snackbar.show('Поле «Проекты» обязательно для заполнения', 'error');
      }

      if (!replaceExistingChecked && !renameValue) {
        return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
      }

      if (hasName(renameValue)) {
        return Snackbar.show('Проект с таким именем уже существует', 'error');
      }

      if (replaceExistingChecked) {
        dispatch(
          openConfirmationModalAction({
            confirmationButtonText: 'Да',
            cancelButtonText: 'Нет',
            subTitleText: `Действительно перезаписать проект «${renameValue}»?`,
            onConfirm: () =>
              onCopyProjectVersionAnother({
                selectProjectId: selectProject?.name,
                renameValue,
                replaceExistingChecked,
                runImport,
              }),
            titleText: 'Перезапись проекта',
            width: '320px',
            onCancel: () => {},
          }),
        );
      }

      if (!replaceExistingChecked) {
        void (await onCopyProjectVersionAnother({
          selectProjectId: selectProject?.name,
          renameValue,
          replaceExistingChecked,
          runImport,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasName, onCopyProjectVersionAnother],
  );

  return (
    <CopyProjectModalComponentUI
      titleFlow="В черновики пользователя"
      projectName={projectName}
      onClose={onClose}
      flowsItems={flowsItems}
      projectsItems={userDraftProjectsList}
      onSelectFlows={setSelectFlows}
      flows={selectFlows}
      onRestoreVersionModal={onRestoreVersionModal}
    />
  );
};
