import { useState, useCallback } from 'react';

export const useFullSize = () => {
  const [currentFullSize, setCurrentFullSize] = useState<string | null>(null);
  const [isResizeVisible, setIsResizeVisible] = useState(false);

  const onResizeChange = useCallback(
    (value: string) => () => {
      setCurrentFullSize((prev) => (value === prev ? null : value));
    },
    [],
  );

  const onMouseEnter = useCallback(() => setIsResizeVisible(true), []);
  const onMouseLeave = useCallback(() => setIsResizeVisible(false), []);

  return {
    currentFullSize,
    onResizeChange,
    isResizeVisible,
    onMouseEnter,
    onMouseLeave,
  };
};
