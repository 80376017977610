import { SortProject } from 'store/reducers/projectManager/types';
import { defaultShortProject } from 'store/reducers/projectManager/constants';

type SortSettings = Record<string, SortProject>;

type SortSettingsType = {
  SHORT_KEY: string;
  streamId: string;
  settings: SortProject;
};

export const saveSortSettings = ({ SHORT_KEY, streamId, settings }: SortSettingsType): void => {
  const allSettings = loadAllSortSettings(SHORT_KEY);
  allSettings[streamId] = settings;
  localStorage.setItem(SHORT_KEY, JSON.stringify(allSettings));
};

export const loadSortSettings = ({ streamId, SHORT_KEY }: Omit<SortSettingsType, 'settings'>): SortProject | null => {
  const allSettings = loadAllSortSettings(SHORT_KEY);
  return allSettings[streamId] || defaultShortProject;
};

const loadAllSortSettings = (SHORT_KEY: string): SortSettings => {
  const settings = localStorage.getItem(SHORT_KEY);
  return settings ? JSON.parse(settings) : {};
};
