import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import {
  AddUsersGroupsSourceAccessPayload,
  UpdateGroupSourceAccessByIdPayload,
  UpdateUserSourceAccessByIdPayload,
} from 'types/types';
import { CheckingConnectionDataBaseData, SourceConnection, SourceConnectionKind, UpdateConnection } from './types';
import { DeleteGroupSourceAccessByIdPayload, DeleteUserSourceAccessByIdPayload } from 'store/reducers/adminSources/types';

const connectionUrl = '/api/v1/sources';
const connectionUrlV2 = '/api/v2';

export const createConnection = <SourceConnectionType extends SourceConnectionKind>(
  connectionData: SourceConnection<SourceConnectionType>,
): Promise<AxiosResponse<FastBoard.API.SourceCreateResponseDTO>> =>
  axiosClient.post<
    FastBoard.API.SourceCreateResponseDTO,
    AxiosResponse<FastBoard.API.SourceCreateResponseDTO>,
    FastBoard.API.SourcesCreateDTO
  >(`${connectionUrlV2}/source`, {
    name: connectionData.name,
    driver: connectionData.driverId,
    credentials: connectionData.credentials,
    isAutoUpdate: connectionData?.autoUpdate,
  });

export const updateConnection = <SourceConnectionType extends SourceConnectionKind>({
  id,
  sourceData,
}: UpdateConnection<SourceConnectionType>) =>
  axiosClient.put<
    FastBoard.API.SourcesCreateAndUpdateResponseDTO,
    AxiosResponse<FastBoard.API.SourceUpdateResponseDTO>,
    FastBoard.API.SourceUpdateDTO
  >(`${connectionUrlV2}/source/${id}`, {
    name: sourceData.name,
    driver: sourceData.driverId,
    credentials: sourceData.credentials,
    isAutoUpdate: sourceData.autoUpdate,
  });

export const loadSources = (projectId: string): Promise<AxiosResponse<FastBoard.API.SourcesListItemDTO[]>> =>
  axiosClient.get(`${connectionUrl}/list/${projectId}`);

export const loadSourcesActiveLoading = (): Promise<AxiosResponse<FastBoard.API.LoaderLogsActiveForSourcesResponse[]>> =>
  axiosClient.get(`/api/v1/loader-logs/active/for/sources`);

export const loadSourceFileStatus = (loadingId: string): Promise<AxiosResponse<FastBoard.API.ProjectsLoadingStatusResponseDTO>> =>
  axiosClient.get(`/api/v1/projects/loading_status`, { params: { loading_id: loadingId } });

export const deleteSource = (sourceId: string) =>
  axiosClient.post<string, AxiosResponse<string>, FastBoard.API.SourcesDeleteDTO>(`${connectionUrl}/delete`, {
    id: sourceId,
  });

export const deleteScriptSource = (projectId: string, sourceId: string): Promise<string> =>
  axiosClient.delete(`/api/v1/script/${projectId}/source/${sourceId}/tables`);

export const updateSourceFile = (sourceId: string) =>
  axiosClient.put<FastBoard.API.SourcesCreateAndUpdateResponseDTO>(`${connectionUrl}/${sourceId}/update/data`, {
    id: sourceId,
  });

export const uploadSourceFile = (file: File) => {
  const newFormData = new FormData();
  newFormData.append('file', file);
  return axiosClient.post<string, AxiosResponse<string>, FormData>(`/api/v1/folders/upload/file`, newFormData);
};

export const loadFilesPath = (path: string) =>
  axiosClient.post<
    FastBoard.API.GetFoldersAndFilesResponse[],
    AxiosResponse<FastBoard.API.GetFoldersAndFilesResponse[]>,
    FastBoard.API.GetFoldersAndFilesDTO
  >(`/api/v1/folders`, {
    path,
  });

export const loadCheckingConnection = (source: CheckingConnectionDataBaseData) =>
  axiosClient.post<{ sourceTest: string }, AxiosResponse<FastBoard.API.SourceTestResponseDTO>, CheckingConnectionDataBaseData>(
    `${connectionUrlV2}/source/test`,
    {
      driver: source.driver,
      credentials: source.credentials,
    },
  );

export const loadSourceById = (sourceId: string) =>
  axiosClient.get<{ name: string }, AxiosResponse<FastBoard.API.SourceReadResponseDTO>, { sourceId: string }>(
    `${connectionUrlV2}/source/${sourceId}`,
  );

/* Access */

export const loadSourceUsersAndGroups = (
  sourceId: string,
): Promise<AxiosResponse<FastBoard.API.SourceUsersAndGroupsListResponseDTO>> =>
  axiosClient.get(`/api/v2/source/${sourceId}/groups-and-users`);

export const addSourceUsersGroupsAccess = ({ sourceId, groups, users, type }: AddUsersGroupsSourceAccessPayload) =>
  axiosClient.post<
    string,
    AxiosResponse<FastBoard.API.SourceAddUsersAndGroupsResponseDTO>,
    FastBoard.API.SourceAddGroupsAndUsersDTO
  >(`/api/v2/source/${sourceId}/groups-and-users/add`, {
    groups: groups || [],
    users: users || [],
    type,
  });

export const deleteSourceAccessGroup = ({ sourceId, groupId }: DeleteGroupSourceAccessByIdPayload) =>
  axiosClient.delete<string, AxiosResponse<string>, string>(`/api/v2/source/${sourceId}/group/${groupId}/delete`);

export const deleteSourceAccessUser = ({ sourceId, userId }: DeleteUserSourceAccessByIdPayload) =>
  axiosClient.delete<string, AxiosResponse<string>, string>(`/api/v2/source/${sourceId}/user/${userId}/delete`);

export const updateSourceAccessGroup = ({ sourceId, type, groupId }: UpdateGroupSourceAccessByIdPayload) =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.SourceGroupResponseDTO>, FastBoard.API.SourceUpdateTypeFromGroupDTO>(
    `/api/v2/source/${sourceId}/group/${groupId}/type`,
    { type },
  );

export const updateSourceAccessUser = ({ sourceId, type, userId }: UpdateUserSourceAccessByIdPayload) =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.SourceUserResponseDTO>, FastBoard.API.SourceUpdateTypeFromUserDTO>(
    `/api/v2/source/${sourceId}/user/${userId}/type`,
    { type },
  );
