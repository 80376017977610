export type VariablesStyle = Record<string, Record<string, string | number | undefined>>;

export type TextVariablesType = 'text' | 'variable' | 'link' | 'linkVariables';

export enum TextVariablesEnum {
  TEXT = 'text',
  VARIABLE = 'variable',
  LINK = 'link',
  lINK_VARIABLES = 'linkVariables',
}

export interface TransformationTextVariablesElementInterface {
  type: TextVariablesType;
  text: string;
  description?: string;
  style?: Record<string, string | number | undefined>;
  origin?: string;
}

export type TransformationTextVariablesInterface = Array<TransformationTextVariablesElementInterface | null>;
