import {
  SeriesAdditionalDataType,
  SeriesAdditionalValuesType,
  SeriesDataMapType,
  SeriesDataType,
  SeriesValuesType,
} from 'modules/visualisations/Bubble/visualisation/types';
import { defaultBubbleDataSettings, getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import {
  ActiveIncisionIdInterface,
  BubbleDataSettings,
  BubbleIncisionInterface,
  ExtendedSettingsInterface,
  GradientByInterfaceType,
  PositionValueType,
  VisualisationValuesInterface,
  VisualMapSettingsInterface,
} from 'store/reducers/visualisations/types';
import { BarEChartsOption, ConfigWithGridDimensionsInterface, GridDimensionsInterface } from 'types/echarts';
import { IdInterface, PositionSettingType, StartAndEndInterface } from 'types/store';
import { initialDimensions } from 'utils/generateConfigGraphic';
import { AbsolutePositionType } from 'types/styles';
import { store } from 'store';
import { updateBubbleDataSettingsById } from 'store/reducers/visualisations/actions';

const dispatch = store.dispatch;

export const onActiveIncisionIdChange = ({ id, activeIncisionId }: ActiveIncisionIdInterface & IdInterface) =>
  dispatch(updateBubbleDataSettingsById({ dataSettings: { activeIncisionId }, id }));

const updateValue = (currentValue: number, newValue: number, isMax: boolean): number => {
  if (isMax) {
    return currentValue > newValue ? currentValue : Number(newValue);
  } else {
    return currentValue < newValue ? currentValue : Number(newValue);
  }
};

export const getBubbleData = (
  { indicators }: Pick<BubbleDataSettings, 'indicators'>,
  visualisationValues: VisualisationValuesInterface,
  incision: BubbleIncisionInterface,
) => {
  /* TODO: The logic for adding a second cut is not yet used and needs to be fixed */
  // const [incision, additionalIncision] = inisions;
  // if (additionalIncision) {
  //   const {
  //     name: nameAdditionalIncision,
  //     fieldName: fieldNameAdditionalIncision,
  //     settings: { nameFromDatabase: nameFromDatabaseAdditionalIncision },
  //   } = additionalIncision;
  //
  //   const additionalIncisionFieldName = getVisualisationFieldName({
  //     name: nameAdditionalIncision,
  //     fieldName: fieldNameAdditionalIncision,
  //     nameFromDatabase: nameFromDatabaseAdditionalIncision,
  //   });
  //   additionalIncisionValues = visualisationValues[additionalIncisionFieldName] || [];
  // }
  let additionalIncisionValues;

  const [indicatorsXAxis, indicatorsYAxis, indicatorSize] = indicators;

  if (!indicatorsXAxis || !indicatorsYAxis || !incision) {
    return { data: {}, maxIndicatorSum: {}, minAndMaxIndicatorSize: {} };
  }

  const {
      id: idIndicatorsXAxis,
      name: nameIndicatorXAxis,
      fieldName: fieldNameIndicatorXAxis,
      settings: { nameFromDatabase: nameFromDatabaseIndicatorXAxis },
    } = indicatorsXAxis,
    {
      id: idIndicatorsYAxis,
      name: nameIndicatorsYAxis,
      fieldName: fieldNameIndicatorsYAxis,
      settings: { nameFromDatabase: nameFromDatabaseIndicatorsYAxis },
    } = indicatorsYAxis,
    {
      name: nameIncision,
      fieldName: fieldNameIncision,
      settings: { nameFromDatabase: nameFromDatabaseIncision },
    } = incision,
    {
      id: idIndicatorSize,
      name: nameIndicatorSize,
      fieldName: fieldNameIndicatorSize,
      settings: { nameFromDatabase: nameFromDatabaseIndicatorSize },
    } = indicatorSize || defaultBubbleDataSettings.indicators[2];

  const indicatorsXAxisFieldName = getVisualisationFieldName({
      name: nameIndicatorXAxis,
      fieldName: fieldNameIndicatorXAxis,
      nameFromDatabase: nameFromDatabaseIndicatorXAxis,
    }),
    indicatorsYAxisFieldName = getVisualisationFieldName({
      name: nameIndicatorsYAxis,
      fieldName: fieldNameIndicatorsYAxis,
      nameFromDatabase: nameFromDatabaseIndicatorsYAxis,
    }),
    incisionFieldName = getVisualisationFieldName({
      name: nameIncision,
      fieldName: fieldNameIncision,
      nameFromDatabase: nameFromDatabaseIncision,
    }),
    indicatorSizeFieldName = getVisualisationFieldName({
      name: nameIndicatorSize,
      fieldName: fieldNameIndicatorSize,
      nameFromDatabase: nameFromDatabaseIndicatorSize,
    });

  const indicatorsXAxisValues = visualisationValues[indicatorsXAxisFieldName] || [],
    indicatorsYAxisValues = visualisationValues[indicatorsYAxisFieldName] || [],
    incisionValues = visualisationValues[incisionFieldName] || [],
    indicatorsSizeValues = visualisationValues[indicatorSizeFieldName] || [];

  const uniqueIncisionValues = [...new Set(incisionValues as (string | number)[])];

  const incisionValue: VisualisationValuesInterface = {
    [incisionFieldName]: uniqueIncisionValues as string[] | number[],
  };

  const bubbleData: SeriesDataType = [];
  const bubbleAdditionalIncisionData: SeriesAdditionalDataType = [];
  const dataMap: SeriesDataMapType = {};

  let maxIndicatorXAxisSumValue = 0,
    maxIndicatorYAxisSumValue = 0,
    maxIndicatorSizeSumValue = 0,
    maxIndicatorSizeValue = 0,
    minIndicatorSizeValue = Number.MAX_VALUE;

  for (let i = 0; i < incisionValues.length; i++) {
    const xValue = incisionValues[i] as string | number;
    const yValue = additionalIncisionValues && (additionalIncisionValues[i] as string | number);
    const indicatorXValue = indicatorsXAxisValues[i] as number;
    const indicatorYValue = indicatorsYAxisValues[i] as number;
    const indicatorSizeValue = indicatorsSizeValues[i] as number;

    maxIndicatorXAxisSumValue = updateValue(maxIndicatorXAxisSumValue, indicatorXValue, true);
    maxIndicatorYAxisSumValue = updateValue(maxIndicatorYAxisSumValue, indicatorYValue, true);
    maxIndicatorSizeSumValue = updateValue(maxIndicatorSizeSumValue, indicatorSizeValue, true);
    maxIndicatorSizeValue = updateValue(maxIndicatorSizeValue, indicatorSizeValue, true);
    minIndicatorSizeValue = updateValue(minIndicatorSizeValue, indicatorSizeValue, false);

    /* TODO: The logic for adding a second cut is not yet used and needs to be fixed */
    if (yValue && xValue) {
      const preparedData: SeriesAdditionalValuesType = [indicatorXValue, indicatorYValue, indicatorSizeValue, yValue, xValue];

      if (xValue in dataMap) {
        dataMap[xValue].push(preparedData);
      } else {
        dataMap[xValue] = [preparedData];
      }

      for (const key in dataMap) {
        const dataMapValues = dataMap[key];
        bubbleAdditionalIncisionData.push(dataMapValues);
      }
    }

    const data: SeriesValuesType = [indicatorXValue, indicatorYValue, indicatorSizeValue, xValue];

    bubbleData.push(data);
  }

  const indicatorsValue: Record<string, SeriesDataType> = {
    [indicatorsXAxisFieldName]: bubbleData,
  };

  const maxIndicatorSum: Record<string, number> = {
    [idIndicatorsXAxis]: maxIndicatorXAxisSumValue,
    [idIndicatorsYAxis]: maxIndicatorYAxisSumValue,
    [idIndicatorSize]: maxIndicatorSizeSumValue,
  };

  const minAndMaxIndicatorSize: Record<string, Array<number>> = {
    [indicatorSizeFieldName]: [minIndicatorSizeValue, maxIndicatorSizeValue],
  };

  return { data: { ...incisionValue, ...indicatorsValue }, maxIndicatorSum, minAndMaxIndicatorSize };
};

export const getBarGraphGridDimensions = (incision: BubbleIncisionInterface[]) => {
  let isActiveGrid = false;

  incision.forEach(
    ({
      settings: {
        showValue: { isShow, position },
      },
    }) => {
      if (!isActiveGrid) {
        isActiveGrid = isShow && position === 'outside';
      }
    },
  );

  const shiftPosition = 'top';

  return isActiveGrid ? { ...initialDimensions, [shiftPosition]: 20 } : initialDimensions;
};

export const defaultGridDimension: GridDimensionsInterface = {
  left: 70,
  right: 40,
  top: 20,
  bottom: 20,
};

interface MaxAndMinValueInterface {
  maxValue: number;
  minValue: number;
}

interface GetLegendConfigParams {
  visualMapSettings: VisualMapSettingsInterface;
  defaultColor: string;
  typeLegend: GradientByInterfaceType;
  startAndEnd: StartAndEndInterface;
  dimension: number;
  colors: string[];
  maxAndMinValue: MaxAndMinValueInterface;
}

export const getLegendConfigWithGridDimensions: (params: {
  typeLegend: GradientByInterfaceType;
  defaultColor: string;
  maxAndMinValue: MaxAndMinValueInterface;
  startAndEnd: StartAndEndInterface;
  dimension: number;
  colors: string[];
  visualMapSettings: ExtendedSettingsInterface;
}) => ConfigWithGridDimensionsInterface<BarEChartsOption['visualMap']> = ({
  defaultColor,
  maxAndMinValue,
  colors,
  startAndEnd,
  typeLegend,
  visualMapSettings,
  dimension,
}) => ({
  config: getLegendConfig({ visualMapSettings, maxAndMinValue, startAndEnd, typeLegend, colors, defaultColor, dimension }),
  gridDimensions: getLegendGridDimension(visualMapSettings),
});

export const getLegendConfig: (params: GetLegendConfigParams) => BarEChartsOption['visualMap'] = ({
  visualMapSettings: { type, isShow, location, position, width },
  maxAndMinValue,
  colors,
  startAndEnd,
  typeLegend,
  defaultColor,
  dimension,
}) => {
  const legendIsAuto = type === 'auto';
  const isHorizontalLocation = location.type === 'right' || location.type === 'left';

  const { maxValue, minValue } = maxAndMinValue,
    { start, end } = startAndEnd;

  return {
    min: minValue || 0,
    dimension: dimension,
    seriesIndex: [0, 1],
    inRange: {
      color: colors,
    },
    range: [start, end],
    max: maxValue || 1000,
    type: typeLegend === 'valueSteps' ? 'piecewise' : 'continuous',
    splitNumber: colors?.length,
    calculable: true,
    show: isShow,
    textStyle: {
      color: defaultColor,
      fontSize: '14px',
      overflow: 'breakAll',
      width: isHorizontalLocation ? width - 32 : undefined,
    },
    orient: isHorizontalLocation ? 'vertical' : 'horizontal',
    ...getLegendLocation(legendIsAuto, location.value)[location.type],
    ...(isHorizontalLocation
      ? getVerticalLegendPosition(legendIsAuto, position)
      : getHorizontalLegendPosition(legendIsAuto, position))[position.type],
  };
};

export const getLegendGridDimension: (legendSettings: VisualMapSettingsInterface) => GridDimensionsInterface = ({
  location,
  width,
  isShow,
}) => {
  const dimensionByLocation = { left: width, right: width, bottom: 40, top: 40 };

  return { ...initialDimensions, ...(isShow ? { [location.type]: dimensionByLocation[location.type] } : {}) };
};

export const getLegendLocation: (
  legendIsAuto: boolean,
  location: number,
) => Record<AbsolutePositionType, BarEChartsOption['visualMap']> = (legendIsAuto, location) => ({
  top: {
    top: legendIsAuto ? 'top' : location,
  },
  bottom: {
    bottom: legendIsAuto ? 'bottom' : location,
  },
  right: {
    right: legendIsAuto ? 'right' : location,
  },
  left: {
    left: legendIsAuto ? 'left' : location,
  },
});

export const getVerticalLegendPosition: (
  legendIsAuto: boolean,
  position: PositionValueType,
) => Record<PositionSettingType, BarEChartsOption['visualMap']> = (legendIsAuto, position) =>
  legendIsAuto
    ? {
        'flex-start': {
          top: 'top',
        },
        center: {
          top: 'center',
        },
        'flex-end': {
          top: 'bottom',
        },
      }
    : {
        'flex-start': {
          top: position.type === 'flex-start' ? position.value : undefined,
        },
        center: {
          top: 'center',
        },
        'flex-end': {
          bottom: position.type === 'flex-end' ? position.value : undefined,
        },
      };

export const getHorizontalLegendPosition: (
  legendIsAuto: boolean,
  position: PositionValueType,
) => Record<PositionSettingType, BarEChartsOption['visualMap']> = (legendIsAuto, position) =>
  legendIsAuto
    ? {
        'flex-start': {
          left: 'left',
        },
        center: {
          left: 'center',
        },
        'flex-end': {
          left: 'right',
        },
      }
    : {
        'flex-start': {
          left: position.type === 'flex-start' ? position.value : undefined,
        },
        center: {
          left: 'center',
        },
        'flex-end': {
          right: position.type === 'flex-end' ? position.value : undefined,
        },
      };

export const calculateMinMaxValue = (valueXAxis: number, valueYAxis: number, type: 'min' | 'max') => {
  if (valueXAxis === 0 && valueYAxis !== 0) {
    return valueYAxis;
  } else if (valueYAxis === 0 && valueXAxis !== 0) {
    return valueXAxis;
  } else {
    return type === 'min' ? Math.min(valueXAxis, valueYAxis) : Math.max(valueXAxis, valueYAxis);
  }
};

export const getAxisMinAndMaxValue = (isShowAxis: boolean, axisValue: number) => {
  return isShowAxis ? axisValue : 0;
};
