import { useMemo } from 'react';
import { getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import { DefaultIndicatorInterface } from 'store/reducers/visualisations/types';

interface DefaultIndicatorProps<Indicator extends DefaultIndicatorInterface> {
  indicators: Indicator[];
  defaultIndicator: Indicator;
}

export const useDefaultIndicator = <Indicator extends DefaultIndicatorInterface>({
  indicators,
  defaultIndicator,
}: DefaultIndicatorProps<Indicator>) => {
  return useMemo(() => {
    const indicator = indicators?.[0] || defaultIndicator,
      {
        fieldName,
        name,
        settings: { nameFromDatabase },
      } = indicator;

    return {
      data: indicator,
      fieldName: getVisualisationFieldName({ name, fieldName, nameFromDatabase }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicators?.[0], defaultIndicator]);
};
