import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const BubbleDefault: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fill = `var(${ColorVarsEnum.Accent})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <circle opacity="0.75" cx="31" cy="44" r="4" fill={fillDefault} />
        <circle cx="18" cy="52" r="4" fill={fill} />
        <circle opacity="0.75" cx="57" cy="21" r="4" fill={fillDefault} />
        <circle opacity="0.75" cx="27" cy="20" r="4" fill={fillDefault} />
        <circle cx="40" cy="47" r="4" fill={fill} />
        <circle opacity="0.75" cx="51" cy="39" r="4" fill={fillDefault} />
        <circle cx="35" cy="32" r="4" fill={fill} />
        <path opacity="0.15" fillRule="evenodd" clipRule="evenodd" d="M8 59V62H11H62V59H11L11 8H8L8 59Z" fill={fillDefault} />
      </svg>
    </FlexContainer>
  );
};
