import { TextImageType } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import React from 'react';
import { typeOptions } from './constants';

type TypeOfLineAndBarSettingsProps = OnValueChange<TextImageType>;

export const TypeOfImageSettings = ({ value, onChange }: TypeOfLineAndBarSettingsProps) => {
  return (
    <MainContainerSettings titleText="Источник" isOpenDefault={true}>
      <SettingsRadio
        onChange={({ value }) => onChange(value)}
        activeValue={getActiveRadioValue(typeOptions, value)}
        options={typeOptions}
      />
    </MainContainerSettings>
  );
};
