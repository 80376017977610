import { Box, Fade } from '@mui/material';
import { ApplyIcon } from 'assets/icons/FilterInfluenceIcons/Apply';
import { DenyIcon } from 'assets/icons/FilterInfluenceIcons/Deny';
import { WrapperIconEnum } from 'assets/icons/FilterInfluenceIcons/FilterInfluenceIconWrapper';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { onFilterInfluencesChange } from 'modules/settingsContainer/common/data/DefaultFilterDataSettings/constants';
import { Button } from 'modules/ui';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { getActiveBoardElement } from 'store/reducers/board/getters';
import { getActiveFilter, getFilters } from 'store/reducers/filters/getters';
import { updateEventSettingsAction } from 'store/reducers/visualisations/actions';
import { getVisualisations } from 'store/reducers/visualisations/getters';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import { applyToAllInfluences } from 'utils/utils';
import Draggable from 'react-draggable';

interface FilterInfluenceElementSettingsProps {
  isInfluenceFiltersMode: boolean;
  unsetInfluenceFiltersMode: () => void;
}

export const FilterInfluenceSettingsPopup = ({
  isInfluenceFiltersMode,
  unsetInfluenceFiltersMode,
}: FilterInfluenceElementSettingsProps) => {
  const dispatch = useAppDispatch();
  const { events, id } = useGetActiveVisualisationSettings();
  const filterData = useSelector(getActiveFilter);
  const visualisations = useSelector(getVisualisations);
  const filters = useSelector(getFilters);
  const activeElementId = useSelector(getActiveBoardElement);

  const containerWidth = window.innerWidth;
  const elementWidth = 339;

  const initialX = (containerWidth - elementWidth) / 2;

  const onApplyToAllFilterInfluences = (payload: boolean) => () => {
    if (activeElementId) {
      if (id) {
        const filterSettings = events?.filterSettings;
        const filterInfluences = filterSettings?.filterInfluences;
        const influenceData = applyToAllInfluences({ ...visualisations, ...filters }, payload, activeElementId);

        dispatch(
          updateEventSettingsAction({
            ...events,
            filterSettings: { ...filterSettings, filterInfluences: { ...filterInfluences, ...influenceData } },
          }),
        );
      } else {
        const filterInfluences = filterData?.filterInfluences;
        const influenceData = applyToAllInfluences({ ...visualisations, ...filters }, payload, id);

        onFilterInfluencesChange({ ...filterInfluences, ...influenceData });
      }
    }
  };

  return (
    <Draggable bounds="parent" defaultPosition={{ x: initialX, y: 16 }}>
      <Fade in={isInfluenceFiltersMode} timeout={300}>
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: '12px',
            zIndex: 400,
            maxWidth: `${elementWidth}px`,
            backgroundColor: `var(${ColorVarsEnum.Level_5_application})`,
            boxShadow: '0px 2px 30px 0px rgba(19, 17, 30, 0.2)',
            cursor: 'move',
          }}
        >
          <Button onClick={unsetInfluenceFiltersMode} text="Закончить настройку" heightSize="small" />
          <FlexContainer display="flex" alignItems="center" gap="4px">
            <PrimaryTextSpan lineHeight="12px">Применить ко всем</PrimaryTextSpan>
            <ApplyIcon onClick={onApplyToAllFilterInfluences(true)} type={WrapperIconEnum.WRAP} />
            <DenyIcon onClick={onApplyToAllFilterInfluences(false)} type={WrapperIconEnum.WRAP} />
          </FlexContainer>
        </Box>
      </Fade>
    </Draggable>
  );
};
