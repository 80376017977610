import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import {
  CreateFlowPayload,
  CreateSourcePayload,
  CreateUsersAccessPayload,
  DeleteFlowPayload,
  DeleteSourcePayload,
  DeleteUserAccessPayload,
  GetFlowsPayload,
  GroupPayload,
  GroupsListInterface,
  UpdateFlowPayload,
  UpdateSourcePayload,
} from './types';
import { GetUsersAccessNoGroupPayload } from 'types/types';

export const loadGroups = (): Promise<AxiosResponse<FastBoard.API.ApiAdminGroupListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/groups`);

export const deleteGroup = (groupId: string): Promise<AxiosResponse<FastBoard.API.ApiAdminGroupResponseDTO>> =>
  axiosClient.delete(`/api/v2/admin/groups/${groupId}`);

export const createGroup = ({ title, users }: GroupPayload) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ApiAdminGroupResponseDTO>, FastBoard.API.ApiAdminGroupCreateDTO>(
    `/api/v2/admin/groups`,
    {
      name: title,
      users,
    },
  );

export const updateGroup = ({ id, title }: GroupsListInterface) =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ApiAdminGroupResponseDTO>, FastBoard.API.ApiAdminGroupRenameDTO>(
    `/api/v2/admin/groups/rename`,
    {
      id,
      name: title,
    },
  );

/* Group users */

export const loadGroupAccessUsers = (
  groupId: string,
): Promise<AxiosResponse<FastBoard.API.ApiAdminGroupsAccessListResponseDTO>> =>
  axiosClient.get(`/api/v1/groups/access/${groupId}`);

export const deleteGroupUserAccess = ({
  userId,
  groupId,
}: DeleteUserAccessPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminGroupsAccessRemoveResponseDTO>> =>
  axiosClient.delete(`/api/v1/groups/access/${groupId}/${userId}`);

export const createGroupUsersAccess = ({ users, groupId }: CreateUsersAccessPayload) =>
  axiosClient.post<
    string,
    AxiosResponse<FastBoard.API.ApiAdminGroupsAddManyUsersResponseDTO>,
    FastBoard.API.ApiAdminGroupsAddManyUserDTO
  >(`/api/v1/groups/access/${groupId}/add/many`, {
    users,
  });

export const loadUsersNoGroup = ({
  groupId,
}: GetUsersAccessNoGroupPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminUserGroupListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/user-groups`, { params: { groupId, excludeGroups: true } });

/* Group flows */

export const loadNoGroupsFlows = (params: GetFlowsPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowsListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/flows`, { params });

export const loadGroupFlows = (groupId: string): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowsWithTypeListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/flows/for-group/${groupId}`);

export const updateGroupFlow = ({
  groupId,
  flowId,
  type,
}: UpdateFlowPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowGroupResponseDTO>> =>
  axiosClient.put(`/api/v2/admin/flows/${flowId}/group/${groupId}/type`, { type });

export const deleteFlows = ({
  flowId,
  groupId,
}: DeleteFlowPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowGroupResponseDTO>> =>
  axiosClient.delete(`/api/v2/admin/flows/${flowId}/group/${groupId}/`);

export const createFlow = ({ flows, groupId, type }: CreateFlowPayload) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ApiAdminGroupAddFlowsResponseDTO>, FastBoard.API.ApiAdminGroupAddFlowsDTO>(
    `/api/v2/admin/group/${groupId}/flow/add/many`,
    {
      flows,
      type,
    },
  );

/* Group sources */

export const loadGroupSources = (groupId: string): Promise<AxiosResponse<FastBoard.API.ApiAdminSourceGroupListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/sources/for-group/${groupId}`);

export const loadNoGroupSources = (groupId: string): Promise<AxiosResponse<FastBoard.API.ApiAdminSourcesListShortResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/sources/short`, { params: { groupId } });

export const updateGroupSource = ({
  groupId,
  sourceId,
  type,
}: UpdateSourcePayload): Promise<AxiosResponse<FastBoard.API.ApiAdminSourceGroupResponseDTO>> =>
  axiosClient.put(`/api/v2/admin/source/${sourceId}/group/${groupId}/type`, { type });

export const deleteSources = ({
  sourceId,
  groupId,
}: DeleteSourcePayload): Promise<AxiosResponse<FastBoard.API.ApiAdminSourceDeleteGroupResponseDTO>> =>
  axiosClient.delete(`/api/v2/admin/source/${sourceId}/group/${groupId}/delete`);

export const createSource = ({ type, sources, groupId }: CreateSourcePayload) =>
  axiosClient.post<
    string,
    AxiosResponse<FastBoard.API.ApiAdminGroupAddSourcesResponseDTO>,
    FastBoard.API.ApiAdminGroupAddSourcesDTO
  >(`/api/v2/admin/group/${groupId}/source/add/many`, {
    type,
    sources,
  });
