import { FilterContainer } from 'modules/workspace/components/FilterContainer';
import { MultipleEnabledFilterInterface } from 'store/reducers/filters/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React, { useCallback, useMemo, useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { TooltipModded } from './styles';
import { getFilterFieldName } from 'store/reducers/filters/constants';
import { EnabledFilterViewInterface } from 'modules/workspace/components/FilterRow/types';

export const MultipleEnabledFilter: EnabledFilterViewInterface<MultipleEnabledFilterInterface> = ({
  onDelete,
  selectedValues,
  nameSettings,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const showedName = useMemo(() => getFilterFieldName(nameSettings), [nameSettings]);

  const onMouseEnter = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onMouseLeave = useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <FilterContainer onDelete={onDelete}>
      <TooltipModded
        title={
          <FlexContainer flexDirection="column" gap="8px" overflow="hidden">
            {selectedValues?.map((value, index) => (
              <PrimaryTextSpan
                key={`${value}_${index}`}
                lineHeight="14px"
                fontSize="14px"
                color={`var(${ColorVarsEnum.Level_1})`}
                whiteSpace="nowrap"
              >
                {value}
              </PrimaryTextSpan>
            ))}
          </FlexContainer>
        }
        open={isOpen}
        placement="bottom"
      >
        <PrimaryTextSpan
          lineHeight="14px"
          fontSize="14px"
          color={`var(${ColorVarsEnum.Level_1})`}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          cursor="default"
          whiteSpace="nowrap"
        >
          {`${showedName}: ${selectedValues.length}`}
        </PrimaryTextSpan>
      </TooltipModded>
    </FilterContainer>
  );
};
