import { FlexContainer } from 'styles/FlexContainer';
import { SearchInput } from 'modules/ui/inputs/SearchInput';
import { List, ListProps } from 'modules/settingsContainer/List';
import React, { useCallback, useDeferredValue, useState } from 'react';
import { flushSync } from 'react-dom';
import { useSearchEngine } from 'utils/hooks/searchEngine';
import { SearchEngineLifecycleFunctionType } from 'types/global';
import { SourceTablesInterface } from 'store/reducers/loadingScript/types';
import { getFlatList, getOriginListFromResult } from '../constants';

export type ListWithSearchProps = Omit<ListProps, 'onOpenList' | 'openedGroups'>;

export const ListWithSearch = ({
  modelMetaData,
  onDeleteItem,
  onUpClick,
  onDownClick,
  onSelectItem,
  selectedOptions,
  onChecked,
  renderItem,
}: ListWithSearchProps) => {
  const [searchText, setSearchText] = useState('');
  const deferredSearchText = useDeferredValue(searchText);

  const [openedGroups, setOpenedGroups] = useState<{ current: string[]; beforeSearch: string[] }>({
    current: [],
    beforeSearch: [],
  });

  const onOpenList = useCallback((value: string[]) => {
    setOpenedGroups({ current: value, beforeSearch: value });
  }, []);

  const onAfterSearch: SearchEngineLifecycleFunctionType<SourceTablesInterface> = useCallback(
    (result, searchString) => {
      let newOpenedGroups = openedGroups.beforeSearch;
      if (searchString && result.length) {
        newOpenedGroups = result.map(({ title }) => title);
      }

      setTimeout(() => {
        flushSync(() => {
          setOpenedGroups((oldState) => ({ ...oldState, current: newOpenedGroups }));
        });
      });
    },
    [openedGroups.beforeSearch],
  );

  const { filteredList } = useSearchEngine({
    data: modelMetaData,
    searchString: deferredSearchText,
    getOriginListFromResult,
    getFlatList,
    options: { keys: ['name'], threshold: 0.3, findAllMatches: true, shouldSort: true },
    lifecycles: {
      afterSearch: onAfterSearch,
    },
  });

  return (
    <FlexContainer width="100%" flex="1 1 0" gap="20px" flexDirection="column">
      <FlexContainer width="100%" padding="0 12px" marginTop="12px">
        <SearchInput
          name="search_field"
          placeholder="Поиск"
          needBackground={false}
          width="100%"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onClear={() => setSearchText('')}
          useDebounce
        />
      </FlexContainer>
      <List
        modelMetaData={filteredList}
        onOpenList={onOpenList}
        onChecked={onChecked}
        onDownClick={onDownClick}
        onUpClick={onUpClick}
        onDeleteItem={onDeleteItem}
        openedGroups={openedGroups.current}
        selectedOptions={selectedOptions}
        renderItem={renderItem}
        onSelectItem={onSelectItem}
      />
    </FlexContainer>
  );
};
