import styled, { css } from 'styled-components';
import { IsActiveInterface } from 'store/reducers/visualisations/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const TableListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

interface ListItemProps extends IsActiveInterface {
  activeBackgroundColor: string;
}

export const ListItem = styled.div<ListItemProps>`
  height: 24px;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(${ColorVarsEnum.Level_5});
  border-radius: 3px;
  background-color: var(${ColorVarsEnum.Level_3_menu});
  ${({ onClick }) => onClick && 'cursor: pointer;'};

  ${({ isActive, activeBackgroundColor }) =>
    isActive &&
    css`
      background-color: ${activeBackgroundColor};
      border: 1px solid transparent;
    `}
`;
