import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { mainHeaderHeight } from 'components/layouts/HeaderLayout/constants';

export const HeaderLayoutWrapper = styled.header`
  width: 100vw;
  height: ${mainHeaderHeight};
  background-color: var(${ColorVarsEnum.Level_5_application});
  border-bottom: 1px solid var(${ColorVarsEnum.Level_5});
  padding: 0 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
