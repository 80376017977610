import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSettingComponent } from 'store/reducers/visualSettings/index';
import { SetSettingsComponentPayload, VisualSettingsActionsTypes } from 'store/reducers/visualSettings/types';
import { initialVisualSettingsStoreState } from 'store/reducers/visualSettings/constants';

export const setSettingsComponentAction = createAsyncThunk(
  VisualSettingsActionsTypes.SET_SETTINGS_COMPONENT,
  ({ SettingComponent }: SetSettingsComponentPayload, { dispatch }) => {
    dispatch(setSettingComponent(SettingComponent));
  },
);

export const setDefaultSettingsComponentAction = createAsyncThunk(
  VisualSettingsActionsTypes.SET_DEFAULT_SETTINGS_COMPONENT,
  (_, { dispatch }) => {
    const { SettingComponent } = initialVisualSettingsStoreState;

    dispatch(setSettingsComponentAction({ SettingComponent: SettingComponent }));
  },
);
