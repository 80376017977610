import React, { MouseEventHandler, useEffect, useMemo } from 'react';
import { MenuItemInterface } from 'modules/ui/menu/ContextMenu/types';
import { PositionConfigInterface } from 'types/store';
import { NoopType } from 'types/global';
import { BottomLine } from 'modules/ui/menu/ContextMenu/styles';
import { isField } from 'utils/utils';
import { Menu } from '@mui/material';
import { CONTEXT_MENU } from 'modules/workspace/constans';
import { MenuItem } from './ContextMenuItem';

export type MenuItemProps = MenuItemInterface & { open?: boolean };

interface ContextMenuProps {
  additionalMenuList?: MenuItemInterface[];
  menuList?: MenuItemInterface[];
  menuStepInHistoryList?: MenuItemInterface[];
  adminMenuList?: MenuItemInterface[];
  exportMenu?: MenuItemInterface[];
  menuCoordinate: PositionConfigInterface | null;
  isOpen: boolean;
  onClose: NoopType;
  onContextMenu: MouseEventHandler<HTMLDivElement>;
  disabled: boolean;
}

export const ContextMenu = ({
  menuList = [],
  additionalMenuList = [],
  menuCoordinate,
  menuStepInHistoryList = [],
  adminMenuList = [],
  exportMenu,
  isOpen,
  onClose,
  onContextMenu,
  disabled = false,
}: ContextMenuProps) => {
  const hotKeyCallbacks = useMemo(() => {
    return [...additionalMenuList, ...menuList, ...menuStepInHistoryList].reduce<Array<(e: KeyboardEvent) => void>>(
      (callbacks, { hotKeyParams }) => {
        if (hotKeyParams && !hotKeyParams?.disabled && !disabled) {
          const { hotKeyEvent, onHandle } = hotKeyParams;

          const callback = (e: KeyboardEvent) => {
            const eTagName = (e.target as Element)?.tagName;

            if (hotKeyEvent(e) && !isField(eTagName)) {
              e.preventDefault();
              onHandle();
              onClose();
            }
          };

          return [...callbacks, callback];
        }

        return callbacks;
      },
      [],
    );
  }, [additionalMenuList, menuList, disabled, onClose, menuStepInHistoryList]);

  useEffect(() => {
    hotKeyCallbacks.forEach((callback) => {
      document.addEventListener('keydown', callback);
    });

    return () => {
      hotKeyCallbacks.forEach((callback) => {
        document.removeEventListener('keydown', callback);
      });
    };
  }, [hotKeyCallbacks]);

  /* TODO: use ClickAwayListener instead of */
  useEffect(() => {
    document.addEventListener('mousedown', () => {
      onClose();
    });

    return () => {
      document.removeEventListener('mousedown', () => {
        onClose();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuCoordinate, onClose]);

  return (
    <Menu
      open={!disabled && isOpen}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={menuCoordinate ? { top: menuCoordinate.y, left: menuCoordinate.x } : undefined}
      PaperProps={{ style: { minWidth: 260 } }}
      className={CONTEXT_MENU}
      transitionDuration={0}
      onContextMenu={(e) => {
        onClose();
        onContextMenu(e);
      }}
    >
      {additionalMenuList?.map((item, index) => (
        <MenuItem key={index} {...item} />
      ))}
      {additionalMenuList?.length > 0 && <BottomLine />}
      {menuList.map((item, index) => (
        <MenuItem key={index} {...item} open={!disabled && isOpen} />
      ))}
      {menuList?.length > 0 && <BottomLine />}
      {menuStepInHistoryList?.map((item, index) => (
        <MenuItem key={index} {...item} />
      ))}
      {menuStepInHistoryList?.length > 0 && <BottomLine />}
      {exportMenu?.map((item, index) => (
        <MenuItem key={index} {...item} />
      ))}
      {adminMenuList?.map((item, index) => (
        <MenuItem key={index} {...item} />
      ))}
    </Menu>
  );
};
