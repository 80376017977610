import React, { FC, useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ListItemComponent } from 'modules/ui/lists/MapList/item/ListItem';
import { SelectedUniversalLeftBar, UniversalLeftBarListInterface } from 'modules/settingsContainer/UniversalLeftBarList/types';
import { startMobileSize } from 'constants/constants';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { Button } from 'modules/ui/index';
import { ClearIcon, IconType, MenuIcon } from 'assets/icons/withContainer';
import { useWindowDimensions } from 'utils/hooks/screenSizeHook';
import { MenuContainer } from './styles';
import { NoopValueType } from 'types/global';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';

interface UniversalLeftBarListProps {
  data: UniversalLeftBarListInterface[];
  activeTitle?: string;
  activeId?: string;
  onSelectedItem: NoopValueType<SelectedUniversalLeftBar>;
  IconTitle?: IconType;
}

export const UniversalLeftBarMobileMenu: FC<UniversalLeftBarListProps> = ({
  data,
  onSelectedItem,
  activeTitle,
  activeId,
  IconTitle,
}) => {
  const { width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <>
      {width < startMobileSize && (
        <FlexContainer position="relative">
          <FlexContainer width="100%" flexDirection="column">
            <FlexContainer width="100%" padding="16px" flexDirection="column" gap="30px">
              <FlexContainer width="100%" justifyContent="space-between">
                <FlexContainer flexDirection="row" gap="7px" alignItems="center">
                  {IconTitle && <IconWrapper Icon={IconTitle} iconWidth="32px" iconHeight="32px" />}

                  <PrimaryTextSpan fontSize="18px" color={`var(${ColorVarsEnum.Level_1})`} cursor="pointer" fontWeight="bold">
                    {activeTitle}
                  </PrimaryTextSpan>
                </FlexContainer>
                <Button
                  needBackground={false}
                  heightSize="small"
                  iconSize="big"
                  leftIcon={isMenuOpen ? <ClearIcon /> : <MenuIcon />}
                  onClick={toggleMenu}
                />
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
          <MenuContainer isOpen={isMenuOpen}>
            {data.map(({ id, title }) => (
              <ListItemComponent
                onChecked={() => {
                  id && onSelectedItem({ id: id, title: title });
                  setIsMenuOpen(false);
                }}
                key={id}
                title={title}
                disabledCheckbox
                activeSelected={activeId == id}
              />
            ))}
          </MenuContainer>
        </FlexContainer>
      )}
    </>
  );
};
