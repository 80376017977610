import { createSelector } from 'reselect';
import { getState } from 'store/utils';

export const getAdminSourcesStore = createSelector(getState, (state) => state.adminSources);

export const getAdminSources = createSelector(getAdminSourcesStore, (state) => state.sources);

export const getSourceUsersAndGroups = createSelector(getAdminSourcesStore, (state) => state.sourcesUsersAndGroups);

export const getAdminActiveSource = createSelector(getAdminSourcesStore, (state) => state.activeSource);
