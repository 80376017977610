import { CallbackDataParams } from 'echarts/types/dist/shared';
import { useMemo } from 'react';
import { LineAndBarIncisionInterface, WaterfallIncisionInterface } from 'store/reducers/visualisations/types';
import { getArrayItemByCountlessIndex } from 'utils/utils';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';

export const useIncisionColors = (
  activeIncisionSum: Record<string, number>,
  activeIncision: { fieldName: string; data: WaterfallIncisionInterface | LineAndBarIncisionInterface },
): ((params: CallbackDataParams) => string) | undefined => {
  const { getColorValues, defaultColor } = useColorValues();

  const incisionValues = useMemo(() => Object.keys(activeIncisionSum), [activeIncisionSum]);

  const colorByIncisionMapper = useMemo(() => {
    const colors = getColorValues(activeIncision.data.colors) || [];

    return incisionValues.reduce<Record<string, string>>(
      (result, incisionValue, index) => ({
        ...result,
        [incisionValue]: getArrayItemByCountlessIndex(colors, index) || defaultColor,
      }),
      {},
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(incisionValues), activeIncision.data.colors, getColorValues, defaultColor]);

  const getIncisionColor = useMemo(
    () =>
      activeIncision.data.colors ? (params: CallbackDataParams) => colorByIncisionMapper[params.name] || defaultColor : undefined,
    [colorByIncisionMapper, defaultColor, activeIncision.data.colors],
  );

  return getIncisionColor;
};
