import React, { useCallback, useState } from 'react';
import { FilterContainer } from 'modules/workspace/components/FilterContainer';
import { SingleEnabledFilterInterface } from 'store/reducers/filters/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { EnabledFilterViewInterface } from 'modules/workspace/components/FilterRow/types';
import { TooltipModded } from 'modules/filters/Multiple/view/EnabledFilter/styles';
import { FlexContainer } from 'styles/FlexContainer';

export const SingleEnabledFilter: EnabledFilterViewInterface<SingleEnabledFilterInterface> = ({ onDelete, selectedValues }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onMouseEnter = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onMouseLeave = useCallback(() => setIsOpen(false), [setIsOpen]);

  const valuesString = selectedValues.join(', ');

  return (
    <FilterContainer onDelete={onDelete}>
      {selectedValues.length > 1 ? (
        <TooltipModded
          title={
            <FlexContainer flexDirection="column" gap="8px" overflow="hidden">
              {selectedValues.map((value, index) => (
                <PrimaryTextSpan
                  key={`${value}_${index}`}
                  lineHeight="14px"
                  fontSize="14px"
                  color={`var(${ColorVarsEnum.Level_1})`}
                  whiteSpace="nowrap"
                >
                  {value}
                </PrimaryTextSpan>
              ))}
            </FlexContainer>
          }
          open={isOpen}
          placement="bottom"
        >
          <PrimaryTextSpan
            lineHeight="14px"
            fontSize="14px"
            color={`var(${ColorVarsEnum.Level_1})`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            cursor="default"
            whiteSpace="nowrap"
            maxWidth="170px"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {valuesString}
          </PrimaryTextSpan>
        </TooltipModded>
      ) : (
        <PrimaryTextSpan
          lineHeight="14px"
          fontSize="14px"
          color={`var(${ColorVarsEnum.Level_1})`}
          cursor="default"
          whiteSpace="nowrap"
          maxWidth="170px"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {selectedValues[0]}
        </PrimaryTextSpan>
      )}
    </FilterContainer>
  );
};
