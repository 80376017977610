import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { DownIcon, FastBoardLogoIcon, FBLogoIcon, SuccessIcon } from 'assets/icons/withContainer';
import { cssVar, lighten } from 'polished';
import ColorTheme from 'constants/ColorTheme';

export const HeaderPreview = styled.div`
  min-height: 50px;
  background: ${`var(${ColorVarsEnum.Level_5_application})`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 18px;
  border-bottom: 1px solid ${`var(${ColorVarsEnum.Level_5})`};
  width: 100%;
`;

export const FastboardLogo = styled(FBLogoIcon)`
  cursor: pointer;
`;

export const FastBoardBigLogoIcon = styled(FastBoardLogoIcon)`
  margin-left: 18px;
  cursor: pointer;
`;

export const DownSvg = styled(DownIcon)`
  cursor: pointer;

  &:hover {
    path {
      stroke: ${lighten(0.1, cssVar(ColorVarsEnum.Level_3, ColorTheme.default.level_3) as string)}};
  }
}
`;

interface ISelected {
  selected?: boolean;
}

export const MenuElem = styled.div<ISelected>`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0 0 28px;
  flex-wrap: nowrap;
  width: 221px;
  background: ${(props) => (props.selected ? `var(${ColorVarsEnum.Level_1_hover_btn})` : 'transparent')};
  height: 36px;

  :hover {
    background: ${`var(${ColorVarsEnum.Level_1_hover_btn})`};
    path {
      stroke: ${lighten(0.1, cssVar(ColorVarsEnum.Level_3, ColorTheme.default.level_3) as string)};
    }
  }
`;

export const ArrowIcon = styled(DownIcon)`
  height: 14px;
  width: 14px;
  transform: rotate(-90deg);
  margin-right: 12px;
  margin-left: auto;
`;
export const SelectedIcon = styled(SuccessIcon)`
  height: 14px;
  width: 14px;
  position: absolute;
  left: 6px;

  path {
    stroke: var(${ColorVarsEnum.Level_3});
  }
`;
