import { OnValueChange } from 'types/global';
import { GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import { PieIndicatorLabelFormatType } from 'store/reducers/visualisations/types';
import { SettingsCheckbox } from 'modules/settingsContainer/SettingsCheckbox';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsCheckbox/constants';
import { pieLabelOptions } from 'modules/settingsContainer/common/data/PieLabelFormatSettings/constants';

type PieLabelFormatSettingsProps = OnValueChange<PieIndicatorLabelFormatType[]>;

export const PieLabelFormatSettings = ({ value: labelFormatProperties, onChange }: PieLabelFormatSettingsProps) => {
  return (
    <MainContainerSettings titleText="Формат подписи">
      <GroupContainerSettings>
        <SettingsCheckbox
          onChange={(value) => onChange(value.map(({ value }) => value))}
          options={pieLabelOptions}
          activeValue={getActiveRadioValue(pieLabelOptions, labelFormatProperties)}
        />
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
