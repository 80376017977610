import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Select } from 'modules/ui';
import { sortDescending, sortLastModified } from 'modules/settingsContainer/SearchList/ListFlowSearch/SortOptions/constants';
import { NoopValueType } from 'types/global';
import { ShortProjectInterface } from 'modules/ui/ManagerOrAdminComponentsUI/ProjectsSearchUI/types';

interface SortOptionsProps {
  onChangeShort: NoopValueType<ShortProjectInterface>;
  short: ShortProjectInterface;
}

export const SortOptions: React.FC<SortOptionsProps> = ({ onChangeShort, short }) => (
  <FlexContainer flexDirection="row" gap="16px" flexWrap="wrap">
    <Select
      name="descending"
      options={sortDescending}
      width="200px"
      value={short.sortOrder}
      onChange={(sortOrder) => onChangeShort({ sortOrder, sortType: short.sortType })}
    />
    <Select
      heightSize="normal"
      name="alphabet"
      options={sortLastModified}
      width="200px"
      value={short.sortType}
      onChange={(sortType) => onChangeShort({ sortOrder: short.sortOrder, sortType })}
    />
  </FlexContainer>
);
