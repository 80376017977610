import React from 'react';
import { useAppDispatch } from 'store';
import { updateViewSettingsAction } from 'store/reducers/visualisations/actions';
import { BooleanSettingsControlInterface } from 'modules/settingsContainer/common/BooleanSettings/types';
import { BooleanElementSettings } from 'modules/settingsContainer/common/BooleanElementSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';

type BackgroundSettingsProps = BooleanSettingsControlInterface;

export const BackgroundSettings = ({ value, onChange: onExternalChange }: BackgroundSettingsProps) => {
  const dispatch = useAppDispatch();

  const onLocalChange = (showBackground: boolean) => {
    dispatch(updateViewSettingsAction({ showBackground }));
  };

  const onChange = onExternalChange || onLocalChange;

  return (
    <GroupContainerSettings showBottomLine>
      <BooleanElementSettings titleText="Фон" value={value} onChange={onChange} />
    </GroupContainerSettings>
  );
};
