import { getActiveRadio } from 'components/RadioSelected/constants';
import { RadioSelected } from 'components/RadioSelected/RadioSelected';
import { backgroundValueNameTitle, colorNameTitle, percentUnit, pxUnit } from 'constants/global';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { CircleActivator, ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { ColorValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { ColorBySettings } from 'modules/settingsContainer/common/ColorBySettings';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { PaddingsElementSettings } from 'modules/settingsContainer/common/PaddingsElementSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import Button from 'modules/ui/Button';
import { propertiesSettingsTitle } from 'modules/visualisations/Text/settings/ViewTab/constants';
import React from 'react';
import { DefaultDataSettingsInterface, DefaultPropertiesInterface } from 'store/reducers/visualisations/types';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { NoopType, OnValueChange } from 'types/global';
import { alignmentHorizontalOptions, alignmentVerticalOptions, fontStyleIcons } from './constants';

interface ColorPickerItemProps {
  title: string;
  onChangeColors: (value: ColorValuesByThemeType | null) => void;
  colorsValue: ColorValuesByThemeType | null;
  defaultActivatorBackgroundColor: string;
}

export const ColorPickerItem = ({
  title,
  onChangeColors,
  colorsValue,
  defaultActivatorBackgroundColor,
}: ColorPickerItemProps) => {
  return (
    <>
      <FlexContainer alignItems="center" gap="8px">
        <PrimaryTextSpan lineHeight="12px" color={`var(${ColorVarsEnum.Level_3})`}>
          {title}
        </PrimaryTextSpan>
        <FlexContainer gap="4px">
          <ColorPicker
            defaultActivatorBackgroundColor={defaultActivatorBackgroundColor}
            Activator={CircleActivator}
            closeOnClick
            type="color"
            onChange={onChangeColors}
            value={colorsValue}
          />
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

interface PropertiesProps<T extends DefaultPropertiesInterface>
  extends OnValueChange<T>,
    Partial<Pick<DefaultDataSettingsInterface, 'indicators'>> {
  title?: string;
  titleColorMainContainerText?: string;
  isMainContainerColorSettings?: boolean;
  isMainContainerSettings?: boolean;
  disabledComponentColorByValue?: boolean;
  disabledLineHeight?: boolean;
  disabledPadding?: boolean;
  disabledOpacity?: boolean;
  disabledFontColorBy?: boolean;
  disabledBackgroundColorBy?: boolean;
  disabledUnderline?: boolean;
  disabledLetterSpacing?: boolean;
  activeAlignmentVertical?: boolean;
  activeAlignmentHorizontal?: boolean;
  disabledFontColorByBlock?: boolean;
  rightPositionModal?: string;
  switcherColorBy?: {
    switcherChangeColorBy?: NoopType;
    switcherStateColorBy?: boolean;
  };
  switcherStyle?: {
    switcherChangeStyle?: NoopType;
    switcherStateStyle?: boolean;
  };
}

export const PropertiesSettings = <T extends DefaultPropertiesInterface>({
  value: properties,
  onChange,
  isMainContainerColorSettings,
  isMainContainerSettings,
  disabledComponentColorByValue,
  indicators,
  switcherColorBy,
  switcherStyle,
  title = propertiesSettingsTitle,
  disabledFontColorBy = false,
  disabledFontColorByBlock = false,
  disabledPadding = false,
  disabledOpacity = false,
  disabledBackgroundColorBy = false,
  disabledLineHeight = false,
  disabledUnderline = false,
  disabledLetterSpacing = false,
  activeAlignmentVertical = false,
  activeAlignmentHorizontal = false,
  titleColorMainContainerText,
}: PropertiesProps<T>) => {
  const { defaultThemePalette, activeThemeSchema } = useColorValues();
  const { alignment, lineHeight, letterSpacing, opacity, backgroundColor, backgroundOpacity, fontColor, padding } = properties;

  const activePositionHorizontalValue = alignment && getActiveRadio(alignmentHorizontalOptions, alignment.horizontal);
  const activePositionVerticallyValue = alignment && getActiveRadio(alignmentVerticalOptions, alignment.vertical);

  const propertiesTop = () => (
    <>
      <ElementContainerSettings>
        <FlexContainer alignItems="center" gap="8px">
          <PrimaryTextSpan lineHeight="12px" color={`var(${ColorVarsEnum.Level_3})`}>
            Начертание
          </PrimaryTextSpan>
          <FlexContainer gap="4px">
            {fontStyleIcons(disabledUnderline).map(({ Icon, type }) => {
              const fontStyleValue = type && properties?.fontStyle[type];

              return (
                <Button
                  key={type}
                  needBackground={false}
                  iconSize="big"
                  heightSize="small"
                  onClick={() => onChange({ ...properties, fontStyle: { ...properties?.fontStyle, [type]: !fontStyleValue } })}
                  leftIcon={<Icon />}
                  selected={fontStyleValue}
                />
              );
            })}
          </FlexContainer>
        </FlexContainer>
      </ElementContainerSettings>
      <ElementContainerSettings>
        <NumberPropertiesBlock
          properties="Размер"
          name="fontSize"
          unit={pxUnit}
          value={properties?.fontSize}
          onChange={(fontSize) => onChange({ ...properties, fontSize })}
          heightSize="small"
        />
      </ElementContainerSettings>
      {!disabledLineHeight && (
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Межстрочный интервал"
            name="lineHeight"
            unit={percentUnit}
            value={lineHeight}
            onChange={(lineHeight) => onChange({ ...properties, lineHeight })}
            heightSize="small"
          />
        </ElementContainerSettings>
      )}
      {!disabledLetterSpacing && (
        <ElementContainerSettings>
          <NumberPropertiesBlock
            name="letterSpacing"
            properties="Межбуквенный интервал"
            unit={pxUnit}
            value={letterSpacing}
            onChange={(letterSpacing) => onChange({ ...properties, letterSpacing })}
            heightSize="small"
          />
        </ElementContainerSettings>
      )}
      {!disabledOpacity && (
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Прозрачность"
            name="opacity"
            unit="%"
            value={opacity}
            onChange={(opacity) => onChange({ ...properties, opacity })}
            heightSize="small"
          />
        </ElementContainerSettings>
      )}
      {alignment &&
        (activeAlignmentVertical || activeAlignmentHorizontal) &&
        activePositionVerticallyValue &&
        activePositionHorizontalValue && (
          <ElementContainerSettings>
            <FlexContainer alignItems="center" gap="4px">
              <PrimaryTextSpan lineHeight="12px" color={`var(${ColorVarsEnum.Level_3})`}>
                Выравнивание
              </PrimaryTextSpan>
              <FlexContainer gap="4px">
                {activeAlignmentHorizontal && (
                  <ElementContainerSettings>
                    <RadioSelected
                      onChange={(option) =>
                        onChange({
                          ...properties,
                          alignment: {
                            horizontal: option.value,
                            vertical: alignment.vertical,
                          },
                        })
                      }
                      activeValue={activePositionHorizontalValue}
                      options={alignmentHorizontalOptions}
                      gap="4px"
                    />
                  </ElementContainerSettings>
                )}
                {activeAlignmentVertical && (
                  <ElementContainerSettings>
                    <RadioSelected
                      onChange={(option) =>
                        onChange({
                          ...properties,
                          alignment: {
                            horizontal: alignment.horizontal,
                            vertical: option.value,
                          },
                        })
                      }
                      activeValue={activePositionVerticallyValue}
                      options={alignmentVerticalOptions}
                      gap="4px"
                    />
                  </ElementContainerSettings>
                )}
              </FlexContainer>
            </FlexContainer>
          </ElementContainerSettings>
        )}
      <FlexContainer display="flex" gap="16px" width="100%">
        {!disabledFontColorBy && (
          <ColorPickerItem
            title={colorNameTitle}
            defaultActivatorBackgroundColor={activeThemeSchema[ColorVarsEnum.Level_4_widget]}
            colorsValue={fontColor}
            onChangeColors={(fontColor) => onChange({ ...properties, fontColor })}
          />
        )}
        {!disabledBackgroundColorBy && (
          <FlexContainer gap="4px">
            <ColorPickerItem
              title={backgroundValueNameTitle}
              defaultActivatorBackgroundColor={activeThemeSchema[ColorVarsEnum.Level_4_widget]}
              colorsValue={backgroundColor}
              onChangeColors={(backgroundColor) => onChange({ ...properties, backgroundColor })}
            />
            <NumberPropertiesBlock
              name="countUnit"
              unit={percentUnit}
              width="40px"
              value={backgroundOpacity || 0}
              onChange={(backgroundOpacity) =>
                onChange({
                  ...properties,
                  backgroundOpacity: Math.max(0, Math.min(backgroundOpacity, 100)),
                })
              }
              heightSize="small"
            />
          </FlexContainer>
        )}
      </FlexContainer>
    </>
  );

  const propertiesCenter = () => (
    <>
      {!disabledPadding && (
        <PaddingsElementSettings value={padding} onChange={(padding) => onChange({ ...properties, padding })} />
      )}
    </>
  );

  const propertiesBottomColor = () => (
    <>
      {!disabledFontColorByBlock && (
        <ColorBySettings
          titleMainContainerText={titleColorMainContainerText}
          disabledComponentByValue={disabledComponentColorByValue}
          isMainContainerSettings={isMainContainerColorSettings}
          switcherColorBy={switcherColorBy}
          value={properties?.fontColorBy}
          indicators={indicators}
          onChange={(fontColorBy) => onChange({ ...properties, fontColorBy })}
        >
          <ColorPicker
            Activator={CircleActivator}
            closeOnClick
            type="color"
            onChange={(fontColor) => onChange({ ...properties, fontColor })}
            value={fontColor}
            defaultActivatorBackgroundColor={defaultThemePalette[0]}
          />
        </ColorBySettings>
      )}
    </>
  );

  return (
    <>
      {!isMainContainerSettings ? (
        <>
          <MainContainerSettings titleText={title}>
            <GroupContainerSettings
              switcherState={switcherStyle?.switcherStateStyle}
              switcherChange={switcherStyle?.switcherChangeStyle}
            >
              {propertiesTop()}
            </GroupContainerSettings>
            {!disabledPadding && <GroupContainerSettings>{propertiesCenter()}</GroupContainerSettings>}
            {propertiesBottomColor()}
          </MainContainerSettings>
        </>
      ) : (
        <>
          <GroupContainerSettings
            titleText={title}
            switcherState={switcherStyle?.switcherStateStyle}
            switcherChange={switcherStyle?.switcherChangeStyle}
          >
            {propertiesTop()}
            {propertiesCenter()}
          </GroupContainerSettings>
          {propertiesBottomColor()}
        </>
      )}
    </>
  );
};
