import { startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getSomeArrays } from 'utils/utils';
import { usePrevious } from 'utils/hooks/previous';
import { useArrayHaveBeenChanged } from 'utils/hooks/arrayHaveBeenChanged';

interface RequestAnimationParams<T, Spy, IsUseIntelligence extends boolean | undefined = true> {
  value: T[];
  useIntelligence?: IsUseIntelligence;
  pieceLength?: IsUseIntelligence extends false ? number | undefined : never;
  useTransition?: boolean;
  clearDependsOn: Spy;
  delay?: number;
}

export const useRequestAnimation = <T, Spy, IsUseIntelligence extends boolean = true>({
  value,
  useIntelligence,
  useTransition = true,
  pieceLength,
  clearDependsOn,
  delay = 0,
}: RequestAnimationParams<T, Spy, IsUseIntelligence>): [T[], null | boolean] => {
  const previousClearDependsOn = usePrevious(clearDependsOn);
  const clearDependsOnHaveBeenChanged = previousClearDependsOn !== clearDependsOn;
  const valueHaveBeenChanged = useArrayHaveBeenChanged(value);

  const defaultUseIntelligence = useMemo(() => (useIntelligence === undefined ? true : useIntelligence), [useIntelligence]);

  const [nonBlockedValue, setNonBlockedValue] = useState<T[]>([]);

  const isAlreadyRendered = useRef<null | boolean>(null);
  const requestRef = useRef<number>(0);
  const timer = useRef<number>(0);

  const setValue = useCallback(
    () =>
      setNonBlockedValue((currentValue) => {
        if (currentValue.length === value.length) {
          cancelAnimationFrame(requestRef.current);
          isAlreadyRendered.current = true;
          return currentValue;
        }

        return getSomeArrays(currentValue, value, pieceLength || 1);
      }),
    [pieceLength, value],
  );

  const setValueFn = useMemo(() => (useTransition ? () => startTransition(setValue) : setValue), [setValue, useTransition]);

  const intelligenceSetValueFn = useMemo(
    () =>
      defaultUseIntelligence
        ? (currentTimer: number) => {
            if (currentTimer - timer.current < 50) {
              setValueFn();
            }
          }
        : setValueFn,
    [setValueFn, defaultUseIntelligence],
  );

  const animate = useCallback(
    (currentTimer: number) => {
      intelligenceSetValueFn(currentTimer);

      requestRef.current = requestAnimationFrame(animate);
      if (defaultUseIntelligence) {
        timer.current = currentTimer;
      }
    },
    [intelligenceSetValueFn, defaultUseIntelligence],
  );

  const startAnimation = useCallback(() => {
    if (value.length) {
      isAlreadyRendered.current = false;
      setTimeout(() => {
        requestAnimationFrame(animate);
      }, delay);
    }
  }, [animate, delay, value.length]);

  useEffect(() => {
    if (isAlreadyRendered.current === null) {
      startAnimation();
    }

    return () => cancelAnimationFrame(requestRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps,@typescript-eslint/no-unused-vars
  }, [JSON.stringify(value)]);

  const clear = useCallback(() => {
    timer.current = 0;
    requestRef.current = 0;
    isAlreadyRendered.current = null;
    setNonBlockedValue([]);

    startAnimation();
  }, [startAnimation]);

  useEffect(() => {
    clearDependsOnHaveBeenChanged && valueHaveBeenChanged && clear();
  }, [clearDependsOnHaveBeenChanged, valueHaveBeenChanged, clear]);

  return [isAlreadyRendered.current && !clearDependsOnHaveBeenChanged ? value : nonBlockedValue, isAlreadyRendered.current];
};
