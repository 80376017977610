import { Combobox } from 'modules/ui/Combobox';
import { onChangeIndicatorFieldName, onChangeOperationType } from 'modules/visualisations/common/onChangeFunctions';
import {
  DefaultDataSettingsInterface,
  VisualisationFieldNameInterface,
  VisualisationIdInterface,
  VisualisationOperationTypeInterface,
} from 'store/reducers/visualisations/types';
import { ISelectField } from 'interface';
import { FlexContainer } from 'styles/FlexContainer';
import { Select } from 'modules/ui';
import { MOCK_GRAPHIC_OPERATION_TYPE_EXTENDED } from 'constants/Mock';
import React from 'react';
import { CustomRequestSettings, CustomRequestSettingsProps } from 'modules/settingsContainer/common/data/CustomRequestSettings';

interface IndicatorMetricFieldProps
  extends Pick<DefaultDataSettingsInterface, 'isRealData'>,
    Pick<VisualisationFieldNameInterface, 'fieldName'>,
    VisualisationIdInterface,
    Pick<VisualisationOperationTypeInterface, 'operationType'>,
    Pick<CustomRequestSettingsProps, 'modelMetaData'> {
  options: ISelectField[];
  dataSettings: DefaultDataSettingsInterface;
  onCustomRequestChange: CustomRequestSettingsProps['onChange'];
  customRequest: CustomRequestSettingsProps['value'];
}

export const IndicatorMetricField = ({
  isRealData,
  options,
  operationType,
  fieldName,
  dataSettings,
  id,
  onCustomRequestChange,
  customRequest,
  modelMetaData,
}: IndicatorMetricFieldProps) =>
  isRealData ? (
    <FlexContainer gap="10px" flex="1 1 0" alignItems="center">
      <FlexContainer flex="1 1 0">
        <CustomRequestSettings onChange={onCustomRequestChange} value={customRequest} modelMetaData={modelMetaData} />
      </FlexContainer>
      <Combobox
        needBackground={false}
        disabled={operationType === 'other'}
        placeholder="Table_name"
        name="indicatorFieldName"
        options={options}
        width="100%"
        disableClearable
        minWidth="300px"
        value={
          fieldName && {
            name: fieldName,
            value: fieldName,
          }
        }
        onChange={({ value: fieldName }) => onChangeIndicatorFieldName(dataSettings, fieldName, id)}
      />
      <Select
        needBackground={false}
        placeholder="Operation_type"
        name="indicatorOperationType"
        options={MOCK_GRAPHIC_OPERATION_TYPE_EXTENDED}
        width="100%"
        value={operationType}
        onChange={(operationType) => onChangeOperationType(dataSettings, operationType, id)}
      />
    </FlexContainer>
  ) : null;
