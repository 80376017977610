import { FlowsSliceStateInterface } from './types';

export const initialAdminFlowsStoreState: FlowsSliceStateInterface = {
  flows: {
    flowsList: [],
    loading: false,
  },
  flowAccess: {
    flowAccessList: [],
    loading: false,
  },
  flowProjects: {
    flowProjectsList: [],
    loading: false,
  },
  sortFlowProject: {
    sortOrder: 'ascending',
    sortType: 'lastModified',
  },
  flowProjectHistoryVersion: {
    flowProjectHistoryVersionList: [],
    loading: false,
  },
  activeFlowProjectId: null,
  activeFlow: null,
};
