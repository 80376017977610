import { createSlice, isAllOf, PayloadAction } from '@reduxjs/toolkit';
import { setSliceFn } from 'constants/store';
import { getThemeItem, initialThemesStoreState, removeThemeByIdFunc, updateThemeByIdFunc } from 'store/reducers/themes/constants';
import { AddNewThemePayload, PaletteLinkInterface, ReturnNewThemeInterface } from 'store/reducers/themes/types';
import {
  loadActiveThemeAction,
  loadActiveThemeFromSnapshotAction,
  loadPaletteLinksAction,
  loadPaletteLinksFromSnapshotAction,
  loadThemesAction,
  loadThemesFromSnapshotAction,
} from 'store/reducers/themes/actions';
import { getMapObject } from 'utils/utils';
import { uploadDashboardSettingsAction } from 'store/reducers/projectSettings/actions';

export const themesSlice = createSlice({
  name: 'themes',
  initialState: initialThemesStoreState,
  reducers: {
    addNewTheme: (state, { payload: { id, ...data } }: PayloadAction<AddNewThemePayload>) => {
      state.themes = {
        ...state.themes,
        [id]: getThemeItem({ id, ...data }),
      };
    },

    removeThemeById: removeThemeByIdFunc,

    updateThemeById: updateThemeByIdFunc,

    setActiveThemeId: (state, { payload: id }: PayloadAction<string | null>) => {
      state.activeThemeId = id;
    },

    addNewPaletteLink: (state, { payload: paletteLink }: PayloadAction<PaletteLinkInterface>) => {
      state.paletteLinks = [...state.paletteLinks, paletteLink];
    },

    removePaletteLinkByThemeId: (state, { payload: id }: PayloadAction<string>) => {
      state.paletteLinks = state.paletteLinks.filter((paletteLink) => paletteLink.themeId !== id);
    },

    updatePaletteLinkByThemeId: (state, { payload: { themeId, paletteId } }: PayloadAction<PaletteLinkInterface>) => {
      state.paletteLinks = state.paletteLinks.map((paletteLink) =>
        paletteLink.themeId === themeId ? { themeId, paletteId } : paletteLink,
      );
    },

    //----------------------RETURN FOR HISTORY----------------------

    returnNewTheme: (state, { payload: { id, data } }: PayloadAction<ReturnNewThemeInterface>) => {
      state.themes = {
        ...state.themes,
        [id]: data,
      };
    },

    returnRemoveThemeById: removeThemeByIdFunc,

    returnThemeSettingsById: updateThemeByIdFunc,

    setSlice: setSliceFn,
  },
  extraReducers: (builder) => {
    builder.addCase(loadThemesAction.pending, (state) => {
      state.themes = {};
      state.themesLoading = true;
    });

    builder.addCase(loadThemesAction.fulfilled, (state, { payload: themes }) => {
      state.themes = getMapObject(themes, 'id');
      state.serverStateOfThemes = getMapObject(themes, 'id');
      state.themesLoading = false;
    });

    builder.addCase(loadThemesAction.rejected, (state) => {
      state.themes = {};
      state.themesLoading = false;
    });

    builder.addCase(loadThemesFromSnapshotAction.fulfilled, (state, { payload: themes }) => {
      state.themes = getMapObject(themes, 'id');
    });

    builder.addCase(loadActiveThemeAction.pending, (state) => {
      state.activeThemeId = null;
      state.activeThemeLoading = true;
    });

    builder.addCase(loadActiveThemeAction.fulfilled, (state, { payload: activeThemeId }) => {
      state.activeThemeId = activeThemeId;
      state.serverStateOfActiveThemeId = activeThemeId;
      state.activeThemeLoading = false;
    });

    builder.addCase(loadActiveThemeAction.rejected, (state) => {
      state.activeThemeId = null;
      state.activeThemeLoading = false;
    });

    builder.addCase(loadActiveThemeFromSnapshotAction.fulfilled, (state, { payload: activeThemeId }) => {
      state.activeThemeId = activeThemeId;
    });

    builder.addCase(loadPaletteLinksAction.pending, (state) => {
      state.paletteLinks = [];
      state.paletteLinksLoading = true;
    });

    builder.addCase(loadPaletteLinksAction.fulfilled, (state, { payload: paletteLinks }) => {
      state.paletteLinks = paletteLinks;
      state.serverStateOfPaletteLinks = paletteLinks;
      state.paletteLinksLoading = false;
    });

    builder.addCase(loadPaletteLinksAction.rejected, (state) => {
      state.paletteLinks = [];
      state.paletteLinksLoading = false;
    });

    builder.addCase(loadPaletteLinksFromSnapshotAction.fulfilled, (state, { payload: paletteLinks }) => {
      state.paletteLinks = paletteLinks;
    });

    builder.addMatcher(isAllOf(uploadDashboardSettingsAction.fulfilled), (state) => {
      if (state.serverStateOfThemes) {
        state.serverStateOfThemes = state.themes;
      }
      if (state.serverStateOfPaletteLinks) {
        state.serverStateOfPaletteLinks = state.paletteLinks;
      }
      state.serverStateOfActiveThemeId = state.activeThemeId;
    });
  },
});

export const {
  addNewTheme,
  addNewPaletteLink,
  removePaletteLinkByThemeId,
  updatePaletteLinkByThemeId,
  removeThemeById,
  updateThemeById,
  setActiveThemeId,
  setSlice,
  returnNewTheme,
  returnRemoveThemeById,
  returnThemeSettingsById,
} = themesSlice.actions;

export default themesSlice.reducer;
