import React, { useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button, Select } from 'modules/ui';
import { ModalComponentType } from 'store/reducers/modals/types';
import { NoopValueType } from 'types/global';
import { RoleType } from 'types/store';
import { roleItems } from 'constants/constants';

interface RenameRoleModalProps {
  onClick: NoopValueType<string>;
}

export const RenameRoleModal: ModalComponentType<RenameRoleModalProps> = ({ onClose, onClick }) => {
  const [select, setSelect] = useState<RoleType>('admin');

  return (
    <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" height="100%">
      <FlexContainer width="100%" flexDirection="column" height="100%" gap="32px">
        <Select
          title="Роль"
          needBackground={false}
          width="100%"
          name="renameRole"
          value={select}
          onChange={setSelect}
          options={roleItems}
        />

        <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
          <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={false} onClick={onClose} />
          <Button text="Изменить" iconSize="normal" needBackground={true} heightSize="normal" onClick={() => onClick(select)} />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
