import { AxisPositionItem } from 'modules/settingsContainer/common/view/AxisSettings/types';
import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { CenterOrEdgePositionType, LabelOrderValuesType, LabelsOrientationType } from 'store/reducers/visualisations/types';
import { PositionSettingType } from 'types/store';

export const axisPositionOptions: (isIncision: boolean) => AxisPositionItem[] = (isIncision) =>
  isIncision
    ? [
        { value: 'bottom', label: 'Внизу' },
        { value: 'top', label: 'Вверху' },
      ]
    : [
        { value: 'left', label: 'Слева' },
        { value: 'right', label: 'Справа' },
      ];

export const axisRotatedPositionOptions: (isIncision: boolean) => AxisPositionItem[] = (isIncision) =>
  isIncision
    ? [{ value: 'top', label: 'Слева' }]
    : [
        { value: 'bottom', label: 'Внизу' },
        { value: 'top', label: 'Вверху' },
      ];

export const labelsOrientationOptions: SettingsRadioItem<LabelsOrientationType>[] = [
  { value: 'horizontal', label: 'Горизонтально' },
  { value: 'vertical', label: 'Вертикально' },
  { value: 'incline', label: 'Наклон' },
];

export const labelsOrderOptions: (isAxisX: boolean) => SettingsRadioItem<LabelOrderValuesType>[] = (isAxisX) => [
  { value: 'downUp', label: isAxisX ? 'Слева направо' : 'Снизу вверх' },
  { value: 'topDown', label: isAxisX ? 'Справа налево' : 'Сверху вниз' },
];

export const tickLabelOrientationOption: SettingsRadioItem<CenterOrEdgePositionType>[] = [
  { value: 'center', label: 'В центре' },
  { value: 'edge', label: 'По краям' },
];

export const namePositionOptions: (isHorizontal: boolean) => SettingsRadioItem<PositionSettingType>[] = (isHorizontal) => [
  { value: 'flex-start', label: isHorizontal ? 'Слева' : 'Внизу' },
  { value: 'center', label: 'В центре' },
  { value: 'flex-end', label: isHorizontal ? 'Справа' : 'Вверху' },
];
