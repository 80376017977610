import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import { PageIdInterface, ProjectIdWithType } from 'types/store';
import { ExportDataInterface } from './types';

export type VisualisationDataType = Record<string, Array<number | string | null>>;

/* TODO: replace to new EP */
/* TODO: Adding new API service with support API params and config (axiosClient) as arguments  */
export const loadDataBySql: <T = VisualisationDataType>(
  params: FastBoard.API.ModelsGetDataDTO,
  config?: AxiosRequestConfig,
) => Promise<AxiosResponse<T>> = (params, config) => axiosClient.post(`/api/v1/models/get_data`, params, config);

export const loadVisualisationsByPageId = (
  { projectId, pageId }: ProjectIdWithType<PageIdInterface>,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<FastBoard.API.VisualisationsV3>> =>
  axiosClient.get(`/api/v3/project/${projectId}/page/${pageId}/visualisations`, config);

export const exportXlsx = ({
  projectId,
  visualizationId,
  data,
}: ProjectIdWithType<ExportDataInterface>): Promise<AxiosResponse<string>> =>
  axiosClient.post(`/api/v3/project/${projectId}/visualization/${visualizationId}/xlsx`, data.queries, { responseType: 'blob' });

export const exportCsv = ({
  projectId,
  visualizationId,
  data,
}: ProjectIdWithType<ExportDataInterface>): Promise<AxiosResponse<string>> =>
  axiosClient.post(`/api/v3/project/${projectId}/visualization/${visualizationId}/csv`, data.queries, { responseType: 'blob' });
