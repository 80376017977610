import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { TableCell } from 'modules/ui/Table/TableCell';
import { createColumnHelper } from '@tanstack/react-table';
import { TableWithSearch } from 'modules/ui/TableWithSearch';
import {
  deleteByIdGroupUserAccessAction,
  deleteGroupUserAccessAction,
  loadGroupAccessUsersAction,
  loadUsersNoGroupAction,
} from 'store/reducers/adminGroups/actions';
import {
  closeConfirmationModalAction,
  closeModalAction,
  openConfirmationModalAction,
  openModalTypedAction,
} from 'store/reducers/modals/actions';
import { useAppDispatch } from 'store';
import { UsersAccessModal } from './Modal/UsersAccessModal';
import { getActiveGroup, getGroupAccessUsers } from 'store/reducers/adminGroups/getters';
import { addGroupUsersAccess } from 'components/admin/groups/constants';
import { useSelector } from 'react-redux';
import { FlexContainer } from 'styles/FlexContainer';
import { GroupAccessUserListInterface } from 'store/reducers/adminGroups/types';

export const AdminGroupsUserTab: FC = () => {
  const dispatch = useAppDispatch();

  const activeGroup = useSelector(getActiveGroup);
  const activeGroupId = activeGroup?.id;

  const { loading, groupAccessUsersList } = useSelector(getGroupAccessUsers);

  useEffect(() => {
    if (activeGroupId) {
      dispatch(loadGroupAccessUsersAction(activeGroupId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGroup]);

  const onDelete = useCallback(
    async (user: GroupAccessUserListInterface) => {
      try {
        if (!activeGroupId) {
          return;
        }

        const userId = user.id;

        const response = await dispatch(deleteGroupUserAccessAction({ userId, groupId: activeGroupId })).unwrap();

        if (response) {
          dispatch(deleteByIdGroupUserAccessAction(userId));
          dispatch(closeConfirmationModalAction());
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeGroupId],
  );

  const onDeleteModal = useCallback(
    async (user: GroupAccessUserListInterface) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          description: `Действительно удалить пользователя ${user.name} из группы?`,
          onConfirm: () => onDelete(user),
          titleText: 'Удаление пользователя из группы',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  const onCloseAddGroupAccessModal = () => dispatch(closeModalAction(addGroupUsersAccess));

  const onAddModal = useCallback(
    async () => {
      try {
        if (!activeGroupId) {
          return;
        }

        const noGroupUsers = await dispatch(loadUsersNoGroupAction({ groupId: activeGroupId })).unwrap();
        const multiSelectData = noGroupUsers.map((el) => ({ title: el.name, value: el.id }));

        dispatch(
          openModalTypedAction({
            Component: UsersAccessModal,
            componentProps: {
              onClose: onCloseAddGroupAccessModal,
              multiSelectData,
            },
            modalSettings: {
              headerText: 'Добавление пользователей в группу',
            },
            name: addGroupUsersAccess,
          }),
        );
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseAddGroupAccessModal],
  );

  const columnHelper = createColumnHelper<GroupAccessUserListInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Пользователь',
        cell: (info) => (
          <TableCell
            text={info.getValue()}
            actions={{
              onRemove: async () => {
                await onDeleteModal(info.row.original);
              },
            }}
          />
        ),
      }),
      columnHelper.accessor('role', {
        header: 'Роль',
        cell: (info) => <TableCell text={info.getValue()} />,
      }),
    ],
    [columnHelper, onDeleteModal],
  );

  return (
    <FlexContainer position="relative" width="100%" padding="24px" maxWidth="800px">
      <TableWithSearch
        filterColumnName="name"
        columns={columns}
        rows={groupAccessUsersList}
        onAddModal={onAddModal}
        loading={loading}
      />
    </FlexContainer>
  );
};
