import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSliceFn } from 'constants/store';
import { initialAdminLicensesStoreState } from './constants';
import { loadAdminLicenseAction } from './actions';
import { IdInterface } from 'types/store';
import { AdminLicenseUsersInterface } from 'store/reducers/adminLicenses/types';

export const adminTasksSlice = createSlice({
  name: 'adminTasks',
  initialState: initialAdminLicensesStoreState,
  reducers: {
    addNewLicenseUser: (state, { payload: data }: PayloadAction<AdminLicenseUsersInterface>) => {
      const adminLicense = state.adminLicense;
      if (adminLicense && adminLicense.adminLicenseData) {
        adminLicense.adminLicenseData.users = [...adminLicense.adminLicenseData.users, data];
      }
    },
    deleteLicenseByIdUser: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      const adminLicense = state.adminLicense;

      if (adminLicense && adminLicense.adminLicenseData) {
        adminLicense.adminLicenseData.users = adminLicense.adminLicenseData.users.filter((user) => user.id !== id);
      }
    },
    updateLicenseUsers: (state, { payload: users }: PayloadAction<AdminLicenseUsersInterface[] | undefined>) => {
      const adminLicense = state.adminLicense;

      if (adminLicense && adminLicense.adminLicenseData && users) {
        adminLicense.adminLicenseData.users = users;
      }
    },
    setSlice: setSliceFn,
  },
  extraReducers: (builder) => {
    builder.addCase(loadAdminLicenseAction.pending, (state) => {
      state.adminLicense = { ...state.adminLicense, loading: true };
      state.adminLicense.adminLicenseData = null;
    });
    builder.addCase(loadAdminLicenseAction.fulfilled, (state, { payload }) => {
      state.adminLicense = { ...state.adminLicense, loading: false };
      state.adminLicense.adminLicenseData = payload;
    });
    builder.addCase(loadAdminLicenseAction.rejected, (state) => {
      state.adminLicense = { ...state.adminLicense, loading: false };
      state.adminLicense.adminLicenseData = null;
    });
  },
});

export const { setSlice, deleteLicenseByIdUser, updateLicenseUsers, addNewLicenseUser } = adminTasksSlice.actions;

export default adminTasksSlice.reducer;
