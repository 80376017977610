import React from 'react';
import { WizardStepComponentInterface } from 'types/global';
import { FlexContainer } from 'styles/FlexContainer';
import { Button } from 'modules/ui';
import { WizardSourcesType } from 'components/console/elements/sideBar/WizardSourceModalType/types';
import { FileForm } from 'components/console/elements/sideBar/Forms/FileForm';

export const FileStep = ({ onBack, onClose, projectId }: WizardStepComponentInterface<WizardSourcesType>) => {
  const renderFooter = (): JSX.Element => (
    <FlexContainer width="100%" justifyContent="flex-end">
      <FlexContainer gap="10px">
        <Button text="Назад" iconSize="normal" heightSize="normal" onClick={onBack} />
        <Button text="Создать" iconSize="normal" heightSize="normal" type="submit" />
      </FlexContainer>
    </FlexContainer>
  );

  return <FileForm mode="add" onClose={onClose} renderFooter={renderFooter} projectId={projectId} />;
};
