import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MultiSelect, MultiSelectType } from 'modules/ui/MultiSelect';
import { Button, Select } from 'modules/ui';
import { getActiveFlowId, getUsersGroups } from 'store/reducers/projectManager/getters';
import {
  addUsersGroupsFlowAccessAction,
  loadFlowAccessAction,
  loadUsersGroupsAction,
} from 'store/reducers/projectManager/actions';
import { useAppDispatch } from 'store';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { FlexContainer } from 'styles/FlexContainer';
import { accessEditSelect, accessIcons, accessItems, defaultSelectedAccess } from '../constants';
import { useSelector } from 'react-redux';
import { ModalComponentType } from 'store/reducers/modals/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { AddUsersGroupsFlowAccessPayload, UsersGroupsInterface } from 'store/reducers/projectManager/types';
import { GroupType } from 'types/store';

export const AddAccessModal: ModalComponentType = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { id: flowId } = useSelector(getActiveFlowId);
  const { loading, usersGroupsList } = useSelector(getUsersGroups);

  const [selectedValue, setSelectedValue] = useState<MultiSelectType<UsersGroupsInterface>[]>([]);
  const [selectedAccess, setSelectedAccess] = useState<GroupType>(defaultSelectedAccess);

  const options = useMemo(
    () => usersGroupsList.map((el) => ({ title: el.name, value: el.id, icon: accessIcons[el.entity], data: el })),
    [usersGroupsList],
  );

  useEffect(() => {
    if (flowId) {
      dispatch(loadUsersGroupsAction({ flowId }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowId]);

  const onAdd = useCallback(async () => {
    if (!selectedValue.length) {
      onClose();
      return;
    }

    try {
      const params: AddUsersGroupsFlowAccessPayload = {
        type: selectedAccess,
        flowId,
        users: [],
        groups: [],
      };

      selectedValue.forEach((el) => {
        const userOrGroup = el.data as UsersGroupsInterface;

        const entityMapping = {
          group: params.groups,
          user: params.users,
        };

        entityMapping[userOrGroup.entity]?.push(userOrGroup.id);
      });

      const response = await dispatch(addUsersGroupsFlowAccessAction(params));

      if (response) {
        dispatch(loadFlowAccessAction(flowId));
      }
    } catch (error) {
      console.error('Error creating project version:', error);
    } finally {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, onClose, selectedAccess, flowId]);

  return (
    <FlexContainer position="relative" width="100%" flexDirection="column" padding="4px" gap="16px" height="100%">
      <LoadingOverlay loading={loading} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />

      <Select
        title="Права"
        needBackground={false}
        width="100%"
        name={accessEditSelect}
        value={selectedAccess}
        onChange={setSelectedAccess}
        options={accessItems}
      />

      <MultiSelect
        label="Пользователю/группе"
        noOptionsText="Пользователи/группы не найдены"
        options={options}
        onChange={setSelectedValue}
      />

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Добавить" iconSize="normal" heightSize="normal" onClick={onAdd} />
      </FlexContainer>
    </FlexContainer>
  );
};
