import React, { useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button, Checkbox, TextField } from 'modules/ui/index';
import { NoopPromiseValueType, NoopType } from 'types/global';

interface CreateCopyOfVersionModalUIProps {
  projectName: string;
  onRestoreVersionModal: NoopPromiseValueType<{ renameValue: string; runImport: boolean }>;
  onClose: NoopType;
}

export const CreateCopyOfVersionModalUI = ({ onClose, projectName, onRestoreVersionModal }: CreateCopyOfVersionModalUIProps) => {
  const [renameValue, setRenameValue] = useState(`${projectName} (копия)`);
  const [runImportChecked, setRunImportChecked] = useState(false);

  return (
    <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" padding="12px" height="100%">
      <TextField
        label="Название копии проекта"
        onChange={(e) => setRenameValue(e.target.value)}
        value={renameValue}
        name="rename"
        width="100%"
      />
      <Checkbox
        name="runImportChecked"
        padding="8px 0 0 0"
        checked={runImportChecked}
        label="Запустить импорт после создания"
        id="manyFiles_flag"
        onChange={(e) => setRunImportChecked(e.target.checked)}
      />

      <FlexContainer width="100%" justifyContent="flex-end" gap="8px" marginTop="32px">
        <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={false} onClick={onClose} />
        <Button
          text="Создать"
          iconSize="normal"
          needBackground={true}
          heightSize="normal"
          onClick={() => onRestoreVersionModal({ renameValue, runImport: runImportChecked })}
        />
      </FlexContainer>
    </FlexContainer>
  );
};
