import isEqual from 'lodash/isEqual';
import { ModelItem } from 'models/model/ModelItem';
import { Relation } from 'models/model/Relation';
import { MetaModelItemType, ModelItemType } from 'store/reducers/models/types';

interface SeparatedRelationInterface {
  first: Relation[];
  notFirst: Relation[];
  self: Relation[];
}

export class Model {
  private _modelItems: ModelItem[];

  constructor(modelItems: ModelItem[]) {
    this._modelItems = this.getVerifiedModelItems(modelItems);
  }

  get modelItems(): ModelItem[] {
    return this._modelItems;
  }

  set modelItems(modelItems) {
    this._modelItems = this.getVerifiedModelItems(modelItems);
  }

  get modelData(): ModelItemType[] {
    return this.modelItems.map(({ alias, table, isHead, relations, db }) => ({ alias, table, isHead, relations, db }));
  }

  get metaModelData(): MetaModelItemType[] {
    return this.modelItems.map(({ alias, table, config }) => ({ alias, table, config }));
  }

  get allRelationsWithMeta() {
    return this.modelItems.reduce<
      Array<{ relation: Relation; meta: { leftColumnNumber: number; rightColumnNumber: number; fromLeftToRight: boolean } }>
    >((result, { relations }) => {
      const relationsWithMeta = [...(relations || [])].map((relation) => {
        const {
          link: { left, right },
        } = relation;

        const leftModelItem = this.getModelItemByAlias(left.table),
          leftColumnNumber = leftModelItem?.columnsAsArray?.findIndex((value) => value === left.column) || 0,
          rightModelItem = this.getModelItemByAlias(right.table),
          rightColumnNumber = rightModelItem?.columnsAsArray?.findIndex((value) => value === right.column) || 0;

        return {
          relation,
          meta: {
            leftColumnNumber: leftColumnNumber + 1,
            rightColumnNumber: rightColumnNumber + 1,
            fromLeftToRight: (leftModelItem?.config?.x || 0) > (rightModelItem?.config?.x || 0),
          },
        };
      });

      return [...result, ...relationsWithMeta];
    }, []);
  }

  private hasHead(modelItems: ModelItem[]): boolean {
    return modelItems.reduce((acc, { isHead }) => (isHead ? ++acc : acc), 0) === 1;
  }

  private isLinkedModel(modelItems: ModelItem[]): boolean {
    if (this.hasHead(modelItems)) {
      return modelItems.every(({ relations, alias }) =>
        (relations || []).every((relation) => {
          let result = false;

          if (relation.isSelf) {
            result = true;
          }

          const outerLink = relation.getOuterLinkByAlias(alias);

          if (outerLink) {
            for (const modelItem of modelItems) {
              if (modelItem.alias === outerLink.table) {
                result = true;
              }
            }
          }

          return result;
        }),
      );
    }

    return false;
  }

  private getVerifiedModelItems(modelItems: ModelItem[]) {
    if (this.isLinkedModel(modelItems)) {
      return modelItems;
    }

    throw Error('Incorrect models');
  }

  getModelItemByAlias(alias: string) {
    return this.modelItems.find((modelItem) => modelItem.alias === alias) || null;
  }

  getRelationsByAlias(alias: string) {
    const aliasRelations: Relation[] = [];

    for (const modelItem of this.modelItems) {
      for (const relation of modelItem.relations || []) {
        const { left, right } = relation.link,
          isLinkedByAlias = [left, right].some(({ table }) => table === alias);

        isLinkedByAlias && aliasRelations.push(relation);
      }
    }

    return aliasRelations;
  }

  getRelativeColumnsByAlias(alias: string) {
    return this.getRelationsByAlias(alias).reduce<Set<string>>((result, { link: { left, right } }) => {
      left.table === alias && result.add(left.column);
      right.table === alias && result.add(right.column);

      return result;
    }, new Set<string>());
  }

  addRelationByAlias({ alias, relation }: { alias: string; relation: Relation }) {
    const modelItem = this.getModelItemByAlias(alias);

    if (modelItem) {
      modelItem.addRelation(relation);
    }
  }

  get modelHead() {
    return this._modelItems.find(({ isHead }) => isHead);
  }

  unionWith({ model, relation }: { model: Model; relation: Relation }): Model {
    const aliasModelHead = model.modelHead?.alias || '',
      modelHead = model.getModelItemByAlias(aliasModelHead);

    if (modelHead) {
      modelHead.isHead = false;
      modelHead.addRelation(relation);

      this.modelItems = [...this.modelItems, ...model.modelItems];
    }

    return this;
  }

  alreadyAddedRelation(relation: Relation): boolean {
    return this.allRelationsWithMeta.some((value) => isEqual(relation, value.relation));
  }

  private getSeparatedRelations(alias: string): SeparatedRelationInterface {
    const relations = this.getRelationsByAlias(alias);

    const first: Relation[] = [],
      self: Relation[] = [];

    const usedAlias = new Set<string>(),
      secondaryUsedAlias = new Set<string>();

    relations.forEach((relation) => {
      if (relation.isSelf) {
        self.push(relation);
        return;
      }

      const outerAlias = relation.getOuterLinkByAlias(alias)?.table || '',
        alreadyUsed = usedAlias.has(outerAlias);

      usedAlias.add(outerAlias);

      if (alreadyUsed) {
        secondaryUsedAlias.add(outerAlias);
        return;
      }

      first.push(relation);
    });

    const notFirstAliases = Array.from(secondaryUsedAlias.keys());

    const notFirst = relations.reduce<Relation[]>((result, relation) => {
      const outerAlias = relation.getOuterLinkByAlias(alias)?.table || '';

      if (notFirstAliases.includes(outerAlias)) {
        return [...result, relation];
      }

      return result;
    }, []);

    return { notFirst, first, self };
  }

  getNotFirstRelations(alias: string): Relation[] {
    return this.getSeparatedRelations(alias).notFirst;
  }

  getFirstRelations(alias: string): Relation[] {
    return this.getSeparatedRelations(alias).first;
  }

  private hasModelItem(alias: string): boolean {
    return this.modelItems.some((modelItem) => modelItem.alias === alias);
  }

  rebuildModelByHead: (param: { headAlias: string; linkedRelation?: Relation; withoutRelations?: Relation[] }) => Model = ({
    headAlias,
    linkedRelation,
    withoutRelations,
  }) => {
    const headModelItem =
      this.getModelItemByAlias(headAlias) || new ModelItem({ alias: headAlias, isHead: true, table: headAlias, columns: {} });

    const model = new Model([new ModelItem({ ...headModelItem, isHead: true, relations: null })]);

    const headRelations = this.getRelationsByAlias(headAlias);

    const outerRelations: Relation[] = [];

    headRelations.forEach((headRelation) => {
      if (headRelation.isSelf) {
        model.addRelationByAlias({ alias: headAlias, relation: headRelation });
        return;
      }

      const restrictedRelation =
        isEqual(headRelation, linkedRelation) ||
        withoutRelations?.some((withoutRelation) => isEqual(headRelation, withoutRelation)) ||
        linkedRelation?.getOuterLinkByAlias(headAlias)?.table === headRelation.getOuterLinkByAlias(headAlias)?.table;

      !restrictedRelation && outerRelations.push(headRelation);
    });

    outerRelations.forEach((outerRelation) => {
      const alias = outerRelation.getOuterLinkByAlias(headAlias)?.table || '';

      const alreadyAdded = model.hasModelItem(alias);

      if (!alreadyAdded) {
        const rightModelItem = this.rebuildModelByHead({ headAlias: alias, linkedRelation: outerRelation, withoutRelations });

        model.unionWith({ model: rightModelItem, relation: outerRelation });

        const notFirstRelations = this.getNotFirstRelations(alias);

        notFirstRelations.forEach((notFirstRelation) => {
          const restrictedRelation =
            isEqual(notFirstRelation, outerRelation) ||
            withoutRelations?.some((withoutRelation) => isEqual(notFirstRelation, withoutRelation)) ||
            model.alreadyAddedRelation(notFirstRelation);

          !restrictedRelation && model.addRelationByAlias({ alias, relation: notFirstRelation });
        });
      }
    });

    return model;
  };

  isOnEdge(alias: string): boolean {
    const relations = this.getRelationsByAlias(alias);

    const { isOnEdge } = relations.reduce<{ outerLink: null | string; isOnEdge: boolean }>(
      ({ outerLink, isOnEdge }, relation) => {
        const link = relation.getOuterLinkByAlias(alias);

        if (outerLink === null && link) {
          return { outerLink: link.table, isOnEdge: true };
        }

        if (link && outerLink !== link.table) {
          return { outerLink, isOnEdge: false };
        }

        return { outerLink, isOnEdge };
      },
      { outerLink: null, isOnEdge: false },
    );

    return isOnEdge;
  }

  isLastItem(alias: string) {
    return this.modelItems.length === 1 && this.modelItems[0]?.alias === alias;
  }

  private getHeadByDirection: (params: { fromAlias: string; direction: Relation }) => ModelItem | null = ({
    fromAlias,
    direction,
  }) => {
    const visitedAliases: string[] = [];

    const nextAlias = direction.getOuterLinkByAlias(fromAlias)?.table || '',
      outerItem = this.getModelItemByAlias(nextAlias);

    if (outerItem?.isHead) {
      return outerItem;
    }

    const getHead: (params: { fromAlias: string; direction: Relation }) => ModelItem | null = ({ fromAlias, direction }) => {
      visitedAliases.push(fromAlias);

      const nextAlias = direction.getOuterLinkByAlias(fromAlias)?.table || '';

      const relations = this.getFirstRelations(nextAlias);

      for (const relation of relations) {
        const outerAlias = relation.getOuterLinkByAlias(nextAlias)?.table || '',
          outerItem = this.getModelItemByAlias(outerAlias);

        if (outerItem?.isHead) {
          return outerItem;
        }

        if (!visitedAliases.includes(outerAlias)) {
          const head = getHead({ fromAlias: outerAlias, direction: relation });

          if (head) {
            return head;
          }
        }
      }

      return null;
    };

    return getHead({ fromAlias, direction });
  };

  deleteModelByAlias(alias: string): Model[] {
    const modelItem = this.getModelItemByAlias(alias);

    if (modelItem) {
      const relations = this.getFirstRelations(alias);

      if (this.isLastItem(alias)) {
        return [];
      }

      if (this.isOnEdge(alias)) {
        const modelItems = this.modelItems.filter((modelItem) => modelItem.alias !== alias);

        return [new Model(modelItems)];
      }

      if (relations.length > 0) {
        const newModels: Model[] = [];

        for (const deletedRelation of relations) {
          const outerLink = deletedRelation.getOuterLinkByAlias(alias);

          if (outerLink) {
            const headAlias = this.getHeadByDirection({ fromAlias: alias, direction: deletedRelation })?.alias || outerLink.table;

            const notFirstRelations = this.getNotFirstRelations(alias).reduce<null | Relation[]>((result, notFirstRelation) => {
              if (notFirstRelation.getOuterLinkByAlias(alias)?.table === outerLink?.table) {
                return [...(result || []), notFirstRelation];
              }

              return result;
            }, null);

            const newModel = this.rebuildModelByHead({ headAlias, withoutRelations: notFirstRelations || [deletedRelation] });

            newModels.push(newModel);
          }
        }

        return newModels;
      }
    }

    return [new Model([...this._modelItems])];
  }

  deleteRelation(relation: Relation): Model[] {
    const {
      left: { table: leftAlias },
      right: { table: rightAlias },
    } = relation.link;

    const modelItem = this.getModelItemByAlias(leftAlias);

    if (relation.isSelf && modelItem) {
      modelItem.deleteRelationByLink(relation.link);

      return [this];
    }

    const leftModel = this.rebuildModelByHead({ headAlias: this.modelHead?.alias || '', withoutRelations: [relation] });

    if (leftModel.hasModelItem(leftAlias) && leftModel.hasModelItem(rightAlias)) {
      return [leftModel];
    }

    let rightModel = this.rebuildModelByHead({ headAlias: rightAlias, withoutRelations: [relation] });

    /* TODO: Make test for delete inverse link */
    if (leftModel.modelHead?.alias === rightModel.modelHead?.alias) {
      rightModel = this.rebuildModelByHead({ headAlias: leftAlias, withoutRelations: [relation] });
    }

    return [leftModel, rightModel];
  }

  setHeadByAlias(alias: string): Model {
    return this.rebuildModelByHead({ headAlias: alias });
  }

  changeAlias({ fromAlias, toAlias }: { fromAlias: string; toAlias: string }) {
    this.modelItems.forEach((modelItem) => {
      if (modelItem.alias === fromAlias) {
        modelItem.alias = toAlias;
      }

      modelItem.relations?.forEach((relation) => {
        relation.changeAliasInLink({ fromAlias, toAlias });
      });
    });
  }
}
