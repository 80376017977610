import React from 'react';
import { Button, Select } from 'modules/ui';
import { useAppDispatch } from 'store';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  createUserFlowAction,
  loadUserSourcesAction,
  updateUserFlowAction,
  updateUsersFlowAction,
} from 'store/reducers/adminUsers/actions';
import { getActiveUser } from 'store/reducers/adminUsers/getters';
import { Form } from './styles';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { accessItems, defaultSelectedAccess } from 'components/admin/groups/constants';
import { UserFlowFormType } from 'components/admin/usersPage/elemets/Flows/types';
import { UserFlowsListInterface } from 'store/reducers/adminUsers/types';
import { ModalComponentType } from 'store/reducers/modals/types';
import { SelectItemInterface } from 'components/admin/groups/types';
import { ModeForm } from 'types/store';
import { MultiSelect, MultiSelectType } from 'modules/ui/MultiSelect';

interface UserFlowModalProps {
  selectData?: MultiSelectType<SelectItemInterface>[];
  data?: UserFlowsListInterface;
  mode: ModeForm;
}

export const UserFlowModal: ModalComponentType<UserFlowModalProps> = ({ onClose, selectData, data, mode }) => {
  const dispatch = useAppDispatch();

  const isAdd = mode === 'add';

  const activeUser = useSelector(getActiveUser);
  const activeUserId = activeUser?.id;

  const flowId = data?.id;
  const flowName = data?.name;
  const flowGroupName = data?.groupName || '';

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<UserFlowFormType>({
    defaultValues: {
      access: data?.flowGroupType || defaultSelectedAccess,
      ...(selectData && { flow: selectData[0].value }),
    },
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<UserFlowFormType> = async ({ access, flows }) => {
    try {
      if (!isDirty || !activeUserId || isSubmitting) {
        return null;
      }

      const params = {
        flowGroupType: access,
        groupName: flowGroupName,
      };

      if (isAdd) {
        const response = await dispatch(
          createUserFlowAction({
            flowsId: flows,
            type: access,
            userId: activeUserId,
          }),
        ).unwrap();

        if (response) {
          dispatch(loadUserSourcesAction({ userId: activeUserId }));
        }
      }

      if (!isAdd && flowId && flowName) {
        const response = await dispatch(
          updateUserFlowAction({
            flowId,
            userId: activeUserId,
            type: access,
          }),
        ).unwrap();

        if (response) {
          dispatch(updateUsersFlowAction({ flow: { id: flowId, name: flowName, ...params } }));
        }
      }

      onClose();
    } catch (e) {
      return;
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={isSubmitting} />

      <Controller
        render={({ field }) => <Select title="Права" options={accessItems} width="100%" {...field} value={field.value} />}
        name="access"
        control={control}
      />

      {isAdd && selectData && (
        <Controller
          name="flows"
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange } }) => (
            <MultiSelect
              label="К потоку"
              noOptionsText="Потоки не найдены"
              onChange={(item) => onChange(item.map((el) => el.value))}
              options={selectData}
            />
          )}
        />
      )}

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Добавить" iconSize="normal" heightSize="normal" type="submit" />
      </FlexContainer>
    </Form>
  );
};
