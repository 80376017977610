import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { DetailsDescriptionTextParagraph } from './styles';

interface DetailsItemProps {
  title: string;
  description?: string | boolean | null;
  color?: string;
}

export const DetailsItem: React.FC<DetailsItemProps> = ({ title, description, color }) => {
  return (
    <FlexContainer flexDirection="row" gap="16px">
      <PrimaryTextParagraph
        width="200px"
        fontWeight={700}
        lineHeight="14px"
        fontSize="14px"
        color={`var(${ColorVarsEnum.Level_1})`}
      >
        {title}
      </PrimaryTextParagraph>
      <DetailsDescriptionTextParagraph
        width="100%"
        fontWeight={400}
        maxHeight="200px"
        overflow="auto"
        lineHeight="20px"
        fontSize="14px"
        whiteSpace="pre"
        textWrap="pretty"
        color={color || `var(${ColorVarsEnum.Level_1})`}
      >
        {description || '-'}
      </DetailsDescriptionTextParagraph>
    </FlexContainer>
  );
};
