import React, { ChangeEvent, FC } from 'react';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { FileInput, FileInputLabel } from 'modules/ui/inputs/FileUploadInput/styles';
import { daysUntilDate } from 'utils/utils';
import { NoopValueType } from 'types/global';

export const ItemActivationKey = ({ title, description }: { title: string; description: string }) => (
  <FlexContainer flexDirection="row" gap="8px">
    <PrimaryTextSpan fontSize="14px" color={`var(${ColorVarsEnum.Level_2})`}>
      {title}:
    </PrimaryTextSpan>
    <PrimaryTextSpan fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
      {description}
    </PrimaryTextSpan>
  </FlexContainer>
);

type ActivationKeyProps = {
  data?: {
    licenseNum: string;
    expires: string;
    companyName: string;
    type: 'online' | 'offline';
  };
  onChangeLicense: NoopValueType<File>;
};

type ActivationType = 'online' | 'offline';

export const ActivationKey: FC<ActivationKeyProps> = ({ data, onChangeLicense }) => {
  if (!data) {
    return null;
  }
  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      onChangeLicense(file);
    }
  };

  const { licenseNum, expires, companyName, type } = data;
  const typeActivation: Record<ActivationType, string> = {
    offline: 'Offline',
    online: 'Online',
  };

  return (
    <FlexContainer width="225px" height="100%" flexDirection="column" alignItems="flex-start">
      <PrimaryTextSpan
        lineHeight="24px"
        fontSize="24px"
        color={`var(${ColorVarsEnum.Level_1})`}
        fontWeight="bold"
        marginBottom="24px"
      >
        {licenseNum}
      </PrimaryTextSpan>
      <FileInput type="file" accept=".lickey" id="fileUpload" onChange={onFileChange} />
      <FileInputLabel htmlFor="fileUpload">Активировать ключ</FileInputLabel>
      <FlexContainer flexDirection="column" gap="8px" marginTop="53px">
        <ItemActivationKey title="Осталось" description={daysUntilDate(new Date(expires))} />
        <ItemActivationKey title="Номер ключа" description={licenseNum} />
        <ItemActivationKey title="Компания" description={companyName} />
        <ItemActivationKey title="Тип активации" description={typeActivation[type]} />
      </FlexContainer>
    </FlexContainer>
  );
};
