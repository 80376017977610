const API = process.env.REACT_APP_API_URL || 'https://fastbord-back-dev.fb-dev.winsolutions.ru';
const VERSION = process.env.REACT_APP_VERSION || '1.0.0';

const API_HOST = `${API}`;

const env = {
  API,
  API_HOST,
  VERSION,
};

export default env;
