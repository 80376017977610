import { ColorValuesByThemeType, PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { OnChangeValue } from 'modules/visualisations/Waterfall/settings/DataTab/types';
import { store } from 'store';
import { updateWaterfallDataSettingsAction } from 'store/reducers/visualisations/actions';
import {
  FormattingInterface,
  MinAndMaxInterface,
  ShowValueSettingsInterface,
  WaterfallDataSettings,
} from 'store/reducers/visualisations/types';
import { moveArrayItem, MoveToType } from 'utils/utils';

const dispatch = store.dispatch;

export const onMinAndMaxChange = (minAndMax: MinAndMaxInterface) => dispatch(updateWaterfallDataSettingsAction({ minAndMax }));

/* Incision change */

export const onColorIncisionChange: OnChangeValue<PaletteValuesByThemeType | null> = (dataSettings, colors, id) =>
  dispatch(
    updateWaterfallDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) => (id === incision.id ? { ...incision, colors } : incision)),
      indicators: dataSettings.indicators.map((incision) => ({ ...incision, color: null })),
    }),
  );

export const onMoveIncision = (dataSettings: WaterfallDataSettings, incisionId: string, moveTo: MoveToType) => {
  const incisions = dataSettings.incisions,
    indexOfIncision = incisions.findIndex(({ id }) => id === incisionId),
    { newArray } = moveArrayItem(incisions, indexOfIncision, moveTo);
  const newIncisions = [...newArray];

  dispatch(
    updateWaterfallDataSettingsAction({
      incisions: newIncisions,
    }),
  );
};

/* Indicator change */

export const onChangeShowValue: OnChangeValue<ShowValueSettingsInterface> = (dataSettings, showValue, id) =>
  dispatch(
    updateWaterfallDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, showValue } } : indicator,
      ),
      positiveAndNegativeIndicators: dataSettings.positiveAndNegativeIndicators.map((positiveAndNegativeIndicators) =>
        id === positiveAndNegativeIndicators.id
          ? { ...positiveAndNegativeIndicators, settings: { ...positiveAndNegativeIndicators.settings, showValue } }
          : positiveAndNegativeIndicators,
      ),
    }),
  );

export const onColorIndicatorChange: OnChangeValue<ColorValuesByThemeType | null> = (dataSettings, color, id) => {
  dispatch(
    updateWaterfallDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((incision) => (id === incision.id ? { ...incision, color } : incision)),
      positiveAndNegativeIndicators: dataSettings.positiveAndNegativeIndicators.map((positiveAndNegativeIndicators) =>
        id === positiveAndNegativeIndicators.id ? { ...positiveAndNegativeIndicators, color } : positiveAndNegativeIndicators,
      ),
      incisions: dataSettings.incisions.map((incision) => ({ ...incision, colors: null })),
    }),
  );
};

export const onChangeIndicatorFormatting: OnChangeValue<FormattingInterface> = (dataSettings, formatting, id) =>
  dispatch(
    updateWaterfallDataSettingsAction({
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, formatting } } : indicator,
      ),
      positiveAndNegativeIndicators: dataSettings.positiveAndNegativeIndicators.map((positiveAndNegativeIndicator) =>
        id === positiveAndNegativeIndicator.id
          ? { ...positiveAndNegativeIndicator, settings: { ...positiveAndNegativeIndicator.settings, formatting } }
          : positiveAndNegativeIndicator,
      ),
    }),
  );

export const onChangeIndicatorNameFromDataBase: OnChangeValue<boolean> = (dataSettings, nameFromDatabase, id) =>
  dispatch(
    updateWaterfallDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, nameFromDatabase } } : indicator,
      ),
    }),
  );
