import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { defaultScrollbarMixin } from 'constants/styles';
import { FlexContainer } from 'styles/FlexContainer';

interface ButtonWrapperRightProps {
  left?: number;
  right?: number;
}

export const ButtonWrapper = styled.div<ButtonWrapperRightProps>`
  display: flex;
  width: 32px;
  z-index: 2;
  height: 32px;
  position: absolute;
  top: 50%;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  transform: translateY(-50%);
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(${ColorVarsEnum.Level_1});
  opacity: 0.6;
  ${defaultScrollbarMixin};
`;

export const CarouselButtonWrapper = styled.div`
  display: none;
`;

export const CarouselContainerWrapper = styled(FlexContainer)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  :hover ${CarouselButtonWrapper} {
    display: block;
  }
`;
