import TextField, { IProps } from 'modules/ui/TextField';
import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { SearchIcon } from 'assets/icons/withContainer';
import { NoopType } from 'types/global';

type SearchInputProps = IProps & { onClear?: NoopType };

export const SearchInput = ({ onClear, ...textFieldProps }: SearchInputProps) => {
  return (
    <TextField
      onClear={onClear}
      afterSlot={
        <FlexContainer height="100%" width="20px" alignItems="center">
          <SearchIcon />
        </FlexContainer>
      }
      {...textFieldProps}
    />
  );
};
