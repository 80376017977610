import { FlexContainer } from 'styles/FlexContainer';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { LinkIcon, SizeHIcon, SizeWIcon } from 'assets/icons/withContainer';
import { pxUnit } from 'constants/global';
import Button from 'modules/ui/Button';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import React from 'react';
import { OnValueChange } from 'types/global';
import { ConnectedCellPaddingSettings } from 'types/store';
import { SizeSVGWrapper } from '../data/PropertiesSettings/styles';

type PaddingsElementSettingsProps = OnValueChange<ConnectedCellPaddingSettings>;

export const PaddingsElementSettings = ({ value, onChange }: PaddingsElementSettingsProps) => {
  const { isConnection, horizontal, vertical } = value;

  const onHorizontalPaddingChange = (horizontal: number) => {
    if (isConnection) {
      onChange({ ...value, horizontal, vertical: horizontal });
    } else {
      onChange({ ...value, horizontal });
    }
  };

  const onVerticalPaddingChange = (vertical: number) => {
    if (isConnection) {
      onChange({ ...value, horizontal: vertical, vertical });
    } else {
      onChange({ ...value, vertical });
    }
  };

  return (
    <>
      <ElementContainerSettings>
        <FlexContainer alignItems="center" flexDirection="row" justifyContent="space-between" width="100%">
          <NumberPropertiesBlock
            properties={
              <SizeSVGWrapper>
                <SizeWIcon />
              </SizeSVGWrapper>
            }
            name="horizontal"
            unit={pxUnit}
            value={horizontal}
            onChange={onHorizontalPaddingChange}
          />
          <FlexContainer alignItems="center">
            <Button
              needBackground={false}
              iconSize="normal"
              onClick={() => onChange({ ...value, isConnection: !isConnection })}
              leftIcon={<LinkIcon />}
              selected={isConnection}
            />
          </FlexContainer>
          <NumberPropertiesBlock
            properties={
              <SizeSVGWrapper>
                <SizeHIcon />
              </SizeSVGWrapper>
            }
            name="vertical"
            unit={pxUnit}
            value={vertical}
            onChange={onVerticalPaddingChange}
          />
        </FlexContainer>
      </ElementContainerSettings>
    </>
  );
};
