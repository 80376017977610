import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSliceFn } from 'constants/store';
import { initialAdminActiveDirectoriesStoreState } from './constants';
import { loadActiveDirectoriesAction } from './actions';
import { IdInterface } from 'types/store';
import { AdminActiveDirectoriesInterface } from 'store/reducers/adminActiveDirectories/types';

export const adminActiveDirectoriesSlice = createSlice({
  name: 'adminActiveDirectories',
  initialState: initialAdminActiveDirectoriesStoreState,
  reducers: {
    addActiveDirector: (state, { payload: data }: PayloadAction<AdminActiveDirectoriesInterface>) => {
      state.activeDirectoriesData.adminActiveDirectoriesList = [data, ...state.activeDirectoriesData.adminActiveDirectoriesList];
    },
    addActiveDirectorInfo: (state, { payload: data }: PayloadAction<AdminActiveDirectoriesInterface | null>) => {
      state.activeDirectorInfo = data;
    },
    updateActiveDirectories: (state, { payload: task }: PayloadAction<AdminActiveDirectoriesInterface[]>) => {
      state.activeDirectoriesData.adminActiveDirectoriesList = task;
    },
    deleteActiveDirectorById: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.activeDirectoriesData.adminActiveDirectoriesList = state.activeDirectoriesData.adminActiveDirectoriesList.filter(
        (task) => task?.id !== id,
      );
    },
    setSlice: setSliceFn,
  },
  extraReducers: (builder) => {
    builder.addCase(loadActiveDirectoriesAction.pending, (state) => {
      state.activeDirectoriesData.loading = true;
      state.activeDirectoriesData.adminActiveDirectoriesList = [];
    });
    builder.addCase(loadActiveDirectoriesAction.fulfilled, (state, { payload }) => {
      state.activeDirectoriesData.loading = false;
      state.activeDirectoriesData.adminActiveDirectoriesList = payload;
    });
    builder.addCase(loadActiveDirectoriesAction.rejected, (state) => {
      state.activeDirectoriesData.loading = false;
      state.activeDirectoriesData.adminActiveDirectoriesList = [];
    });
  },
});

export const { setSlice, deleteActiveDirectorById, addActiveDirectorInfo, updateActiveDirectories, addActiveDirector } =
  adminActiveDirectoriesSlice.actions;

export default adminActiveDirectoriesSlice.reducer;
