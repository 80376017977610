import styled, { css } from 'styled-components';
import { IsActiveInterface } from 'store/reducers/visualisations/types';
import { aliasWidth, tableColumnHeight, tableHeaderHeight, tableViewWidth } from 'components/contents/Models/TableView/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ellipsisMixin } from 'constants/styles';

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: ${tableHeaderHeight};
  width: 100%;
  background-color: var(${ColorVarsEnum.Level_1_hover_btn});
`;

export const EditButtonsWrapper = styled.div`
  position: absolute;
  top: -40px;
  width: 100%;
  height: 120%;
  display: none;
  align-items: flex-end;
  justify-content: flex-start;
`;

const textStyleMixin = css`
  color: var(${ColorVarsEnum.Level_1});
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
`;

export const AliasWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    width: ${aliasWidth};
    ${textStyleMixin};
    ${ellipsisMixin};
  }
  :hover {
    ${EditButtonsWrapper} {
      display: flex;
    }
  }
`;

export const StyledTextInput = styled.input`
  border: none;
  background: none;
  outline: none;
  ${textStyleMixin};
  width: ${aliasWidth};
`;

export const ColumnItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${tableColumnHeight};
  width: 100%;
  padding: 0 10px;
  background-color: var(${ColorVarsEnum.Level_2_btn});
  border-bottom: 1px solid var(${ColorVarsEnum.Level_4});
`;

export const ColumnNameWrapper = styled.div`
  color: var(${ColorVarsEnum.Level_2});
  font-size: 13px;
  line-height: 13px;
  font-weight: 400;
  ${ellipsisMixin};
`;

export const TableViewWrapper = styled.div<IsActiveInterface>`
  display: flex;
  flex-direction: column;
  width: ${tableViewWidth};
  border-radius: 2px;
  border: 1px solid transparent;

  ${({ isActive }) =>
    isActive &&
    css`
      border-color: var(${ColorVarsEnum.Accent});

      ${TableHeader} {
        background-color: var(${ColorVarsEnum.Accent});
        color: var(${ColorVarsEnum.Level_5});
      }
    `}

  ${ColumnItem}:last-child {
    border-bottom: none;
  }
`;
