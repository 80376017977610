import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { ProportionsImageSettingType } from 'types/store';

export const titleProportionsOptions: SettingsRadioItem<ProportionsImageSettingType>[] = [
  {
    value: 'auto',
    label: 'По размеру',
  },
  {
    value: 'cover',
    label: 'Заполнить',
  },
  {
    value: 'contain',
    label: 'Вписать',
  },
  {
    value: 'stretch',
    label: 'Растянуть',
  },
];
