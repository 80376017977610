import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const LineAndBarDefault: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  stroke = `var(${ColorVarsEnum.Accent})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <rect opacity="0.75" x="17" y="38" width="6" height="13" fill={fillDefault} />
        <rect opacity="0.75" x="27" y="26" width="6" height="25" fill={fillDefault} />
        <rect opacity="0.75" x="47" y="22" width="6" height="29" fill={fillDefault} />
        <rect opacity="0.75" x="37" y="31" width="6" height="20" fill={fillDefault} />
        <path d="M16 24.5L28 16.5L40.5 22L53 13" stroke={stroke} strokeWidth="3" strokeLinecap="round" />
        <rect opacity="0.15" x="8" y="55" width="54" height="3" fill={fillDefault} />
      </svg>
    </FlexContainer>
  );
};
