import React, { ChangeEvent, useMemo } from 'react';
import { avatarInputAccept } from 'modules/ui/ManagerOrAdminComponentsUI/InfoProjectUI/constants';
import { AvatarButton, AvatarImg, AvatarInput } from 'modules/ui/ManagerOrAdminComponentsUI/InfoProjectUI/styles';
import { ProjectAvatarInterface } from 'store/reducers/projectManager/types';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { FlexContainer } from 'styles/FlexContainer';
import { getStringDateByFormat } from 'utils/dates';
import { defaultDateTimeFormat } from 'constants/dates';
import { DeleteIcon } from 'assets/icons/withContainer';
import { NoopValueType } from 'types/global';
import { AvatarProjectInterface } from 'types/types';

interface InfoRowProps {
  title: string;
  value: string | boolean | null;
  description?: string;
}

const InfoRow: React.FC<InfoRowProps> = ({ title, value, description }) => {
  const displayValue = typeof value === 'boolean' ? (value ? 'Да' : 'Нет') : value;
  return (
    <FlexContainer flexDirection="column" marginBottom="14px" gap="4px">
      <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_2})`} fontSize="10px" lineHeight="120%">
        {title}
      </PrimaryTextSpan>

      <FlexContainer flexDirection="row" gap="5px">
        <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_1})`} fontSize="14px" lineHeight="120%">
          {displayValue || '-'}
        </PrimaryTextSpan>
        {description && (
          <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_3})`} fontSize="14px" lineHeight="120%">
            {description}
          </PrimaryTextSpan>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export interface InfoProjectProps {
  isLoading: boolean;
  padding?: string;
  title?: string;
  id?: string;
  createdAt?: string;
  isProtected?: boolean;
  updatedDataAt?: string | null;
  updated?: {
    dateAt: string;
    userName: string;
  };
  created?: {
    dateAt: string;
    userName: string;
  };
  avatar?: ProjectAvatarInterface;
  onChangeAvatar?: NoopValueType<AvatarProjectInterface>;
  isViewer?: boolean;
}

export const InfoProjectUI = ({
  isProtected,
  updated,
  created,
  id,
  updatedDataAt,
  title,
  isLoading,
  padding = '24px',
  avatar,
  onChangeAvatar,
  isViewer,
}: InfoProjectProps) => {
  const isCustomAvatar = avatar?.type === 'avatar';

  const avatarSrc = useMemo(() => {
    if (!avatar) {
      return '';
    }

    const { value } = avatar;
    const b64 = 'data:image/bmp;base64';

    if (value.includes(b64) || value.includes('blob')) {
      return value;
    }

    return `${b64},${value}`;
  }, [avatar]);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.length) return;

    const image = e.target.files?.[0];

    if (!image) return;

    onChangeAvatar && id && onChangeAvatar({ avatar: image, projectId: id });
  };

  const handleRemoveAvatar = () => {
    onChangeAvatar && id && onChangeAvatar({ avatar: null, projectId: id });
  };

  return (
    <FlexContainer flexDirection="column" position="relative" height="100%" padding={padding} gap="14">
      <LoadingOverlay loading={isLoading} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />
      <InfoRow title="Название" value={title || '-'} />
      {!isViewer && <InfoRow title="ID" value={id || '-'} />}
      <InfoRow
        title="Создан"
        value={created?.dateAt ? getStringDateByFormat(new Date(created?.dateAt), defaultDateTimeFormat) : '-'}
        description={created?.userName}
      />
      <InfoRow
        title="Последнее изменение"
        value={updated?.dateAt ? getStringDateByFormat(new Date(updated?.dateAt), defaultDateTimeFormat) : '-'}
        description={updated?.userName}
      />
      <InfoRow
        title="Обновление данных"
        value={updatedDataAt ? getStringDateByFormat(new Date(updatedDataAt), defaultDateTimeFormat) : '-'}
      />
      {!isViewer && <InfoRow title="Защищен от записи" value={isProtected || false} />}

      <FlexContainer flexDirection="column" alignItems="center" padding="24px" gap="30px" marginTop="auto">
        {isCustomAvatar && (
          <FlexContainer width="190px" height="120px">
            <AvatarImg src={avatarSrc} />
          </FlexContainer>
        )}
        <FlexContainer gap="8px" alignItems="center">
          <label>
            <AvatarInput name="avatar" type="file" onChange={handleChange} accept={avatarInputAccept} />
            {!isViewer && <AvatarButton>Обложка</AvatarButton>}
          </label>
          {isCustomAvatar && !isViewer && (
            <FlexContainer width="30px" height="30px" alignItems="center" justifyContent="center" cursor="pointer">
              <DeleteIcon onClick={handleRemoveAvatar} />
            </FlexContainer>
          )}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
