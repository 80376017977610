import styled from 'styled-components';

export const BezierWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const BezierLine = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  width: 300px;
  height: 300px;
  overflow: visible;
  pointer-events: none;
  overflow: visible !important;
`;

export const Path = styled.path`
  stroke-width: 1.5px;
  fill: none;
  stroke: #999;
`;
