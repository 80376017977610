import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { backgroundOpacityMixin } from 'constants/styles';
import { FlexContainer } from 'styles/FlexContainer';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';

export const ButtonsWrapper = styled(FlexContainer)`
  display: none;
`;

export const UpdateIconWrapper = styled(IconWrapper)`
  display: none;
`;

export const SourcesItemContainer = styled(FlexContainer)`
  border-bottom: 1px solid var(${ColorVarsEnum.Level_5});

  :after {
    transition: opacity 1s ease;
  }

  :hover:after {
    ${backgroundOpacityMixin}
  }

  :hover {
    ${ButtonsWrapper}, ${UpdateIconWrapper} {
      display: flex;
    }
  }
`;
