import { HeatmapSeriesOption } from 'echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { RadioButtonSelector } from 'modules/ui/RadioButtonSelector';
import {
  FormatterDataParams,
  TooltipFormatterCallback,
  TooltipFormatterParams,
} from 'modules/visualisations/Bubble/visualisation/types';
import {
  createBarPropertiesData,
  getIncisionAxisConfigWithGridDimensions,
  getMinMaxValues,
  getPositionLabel,
  onActiveNextIncisionIdChange,
} from 'modules/visualisations/common/constants';
import { WrappedReactECharts } from 'modules/visualisations/components/WrappedReactECharts';
import {
  onDataVisualMapSelectedMinAndMaxSettingsChange,
  onDataZoomHorizontalStartAndEndSettingsChange,
  onDataZoomVerticalStartAndEndSettingsChange,
} from 'modules/visualisations/Heatmap/settings/ViewTab/constants';
import {
  defaultGridDimension,
  getBarGraphGridDimensions,
  getHeatmapData,
  getLegendConfigWithGridDimensions,
  onActiveHorizontalIncisionIdChange,
  onActiveNextVerticalIncisionIdChange,
  onActiveVerticalIncisionIdChange,
  onDeleteFiltersForDrillDownIncisionsHeatmap,
  useSqlVisualisationForHeatmap,
  useVisualisationNormalizedValuesForHeatmap,
} from 'modules/visualisations/Heatmap/visualisation/constants';
import { HeatmapEChartsOption, KeysIncisionsHeatmapEnum } from 'modules/visualisations/Heatmap/visualisation/types';
import { useManualResize } from 'modules/visualisations/hooks/manualResize';
import { useDataVisualMapSelected } from 'modules/visualisations/hooks/useDataVisualMapSelected';
import { useDataZoom } from 'modules/visualisations/hooks/useDataZoom';
import { useHeatmapIncisions } from 'modules/visualisations/hooks/useHeatmapIncisions';
import { useProperties } from 'modules/visualisations/hooks/useProperties';
import { useVisualisation } from 'modules/visualisations/hooks/visualisation';
import { VisualisationOriginInterface } from 'modules/workspace/components/VisualisationArea/types';
import React, { memo, useCallback, useMemo } from 'react';
import { defaultHeatmapDataSettings, getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import {
  BarValuePositionType,
  HeatmapVisualisationType,
  VisualisationValuesInterface,
} from 'store/reducers/visualisations/types';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { DefaultAxisInterface, GridDimensionsInterface } from 'types/echarts';
import { NoopType } from 'types/global';
import { TopAndBottomType } from 'types/styles';
import { calculateGridDimension, initialDimensions } from 'utils/generateConfigGraphic';
import { isPositiveNumber } from 'utils/utils';
import { BarTypesEnum } from '../../LineAndBar/visualisation/constants';

export const HeatmapVisualisationComponent: VisualisationOriginInterface<HeatmapVisualisationType> = ({ data, sqlRequest }) => {
  const {
    id,
    dataSettings,
    viewSettings: {
      isVisible,
      dataZoomVerticalStartAndEnd,
      dataVisualMapSelectedMinAndMax,
      dataZoomHorizontalStartAndEnd,
      description,
      header,
      axisXIncisionSettings,
      axisYIncisionSettings,
      horizontalZoom,
      verticalZoom,
      showTips,
      shadowSettings,
      visualMapSettings,
      visualisationPaddings: {
        isShow: visualisationPaddingsIsShow,
        paddingsVisualisation: { topPadding, rightPadding, leftPadding, bottomPadding },
      },
    },
    sqlData,
    events,
  } = data;

  const { activeVerticalIncisionId, activeHorizontalIncisionId, incisions, verticalIncisions, isDrillDown } = dataSettings;
  const activeHorizontalIncision = useMemo(
    () => incisions.find(({ id }) => id === activeHorizontalIncisionId) || defaultHeatmapDataSettings.incisions[0],
    [incisions, activeHorizontalIncisionId],
  );
  const activeVerticalIncision = useMemo(
    () => verticalIncisions.find(({ id }) => id === activeVerticalIncisionId) || defaultHeatmapDataSettings.verticalIncisions[0],
    [verticalIncisions, activeVerticalIncisionId],
  );

  const moreThanOne = events.filterSettings.moreThanOne;

  const propertiesData = useMemo(() => createBarPropertiesData(dataSettings.indicators), [dataSettings.indicators]);

  const properties = useProperties(propertiesData);

  const {
    shadowColorSettings: { shadowColorBy },
  } = shadowSettings;

  const {
    visualisationNormalizedValues,
    updateFilter,
    eChartRef,
    formattingParams: { formatting },
    enabledFilters,
  } = useVisualisation({
    sqlData,
    id,
    colorsBy: [shadowColorBy],
    dataSettings,
    events,
    limit: dataSettings.limit,
    intervalRandomData: dataSettings.minAndMax,
    customUseVisualisationNormalizedFunction: useVisualisationNormalizedValuesForHeatmap,
    customUseSqlVisualisationFunction: useSqlVisualisationForHeatmap,
    sqlRequest,
  });

  const hasFiltersToVerticalIncisions = useMemo(
    () =>
      verticalIncisions
        .map((incision) => incision.fieldName)
        .filter((fieldName) => enabledFilters?.find((filter) => filter.nameSettings.fieldName === fieldName)),
    [verticalIncisions, enabledFilters],
  );
  const hasFiltersToHorizontalIncisions = useMemo(
    () =>
      incisions
        .map((incision) => incision.fieldName)
        .filter((fieldName) => enabledFilters?.find((filter) => filter.nameSettings.fieldName === fieldName)),
    [incisions, enabledFilters],
  );

  const { xAxisIncisions, yAxisIncisions } = useHeatmapIncisions({
    activeHorizontalIncision,
    activeVerticalIncision,
  });

  const heatmapData = useMemo(
    () =>
      getHeatmapData(
        {
          indicators: dataSettings.indicators,
        },
        activeHorizontalIncision,
        activeVerticalIncision,
        visualisationNormalizedValues,
      ),
    [dataSettings.indicators, activeHorizontalIncision, activeVerticalIncision, visualisationNormalizedValues],
  );

  const { getColorValues, activeThemeSchema, defaultColor } = useColorValues();

  const backgroundByValueSettings = dataSettings.gradientBackgroundByValueSettings;

  const colorsGradient = useMemo(
    () => backgroundByValueSettings.byValueGradient.colors || {},
    [backgroundByValueSettings.byValueGradient.colors],
  );
  const colorsSteps = useMemo(
    () => backgroundByValueSettings.byValueSteps.colors || {},
    [backgroundByValueSettings.byValueSteps.colors],
  );
  const indicator = dataSettings.indicators[0];

  const colorsData = useMemo(
    () =>
      (backgroundByValueSettings.type === 'valueSteps' ? colorsSteps : colorsGradient)[indicator?.id]
        ?.map((color) => getColorValues(color.value))
        ?.reverse(),
    [backgroundByValueSettings, indicator, getColorValues, colorsSteps, colorsGradient],
  );

  useManualResize({ eChartRef: eChartRef.current, deps: [description, header, dataSettings.incisions.length] });

  const {
    settings: { nameFromDatabase: indicatorNameFromDatabase, showValue: indicatorShowValue },
    fieldName: indicatorFieldName,
    name: indicatorName,
  } = indicator;

  const {
    fieldName: incisionXAxisFieldName,
    name: incisionXAxisName,
    settings: { nameFromDatabase: incisionXAxisNameFromDatabase },
  } = activeHorizontalIncision;

  const fieldNameIndicator = getVisualisationFieldName({
    name: indicatorName,
    fieldName: indicatorFieldName,
    nameFromDatabase: indicatorNameFromDatabase,
  });

  const fieldNameIncisionXAxis = getVisualisationFieldName({
    name: incisionXAxisName,
    fieldName: incisionXAxisFieldName,
    nameFromDatabase: incisionXAxisNameFromDatabase,
  });

  const {
    fieldName: incisionYAxisFieldName,
    name: incisionYAxisName,
    settings: { nameFromDatabase: incisionYAxisNameFromDatabase },
  } = activeVerticalIncision;

  const fieldNameIncisionYAxis = getVisualisationFieldName({
    name: incisionYAxisName,
    fieldName: incisionYAxisFieldName,
    nameFromDatabase: incisionYAxisNameFromDatabase,
  });

  const heatmapDataResult = useCallback(
    <T extends BarValuePositionType & TopAndBottomType>(seriesName: string, position: T, isHorizontalOrientation: boolean) =>
      heatmapData?.data[seriesName]?.map((value) => {
        const color = getColorValues(properties[seriesName]?.fontColor) || activeThemeSchema[ColorVarsEnum.Level_1];

        const [yAxis, xAxis, valueAxis] = value as (string | number)[];

        return {
          value: value ? [xAxis, yAxis, valueAxis] : [],
          label: {
            ...getPositionLabel(isPositiveNumber(Number(value)), isHorizontalOrientation)[position],
            color,
          },
        };
      }),
    [heatmapData, activeThemeSchema, getColorValues, properties],
  );

  const indicatorSeriesName = useMemo(
    () =>
      getVisualisationFieldName({
        name: indicatorName,
        nameFromDatabase: indicatorNameFromDatabase,
        fieldName: indicatorFieldName,
      }),
    [indicatorFieldName, indicatorName, indicatorNameFromDatabase],
  );

  const formattingFunction = formatting[indicatorName];

  const series = useMemo<HeatmapSeriesOption>(() => {
    const sameOption = {
      markPoint: {
        symbol: 'circle',
        symbolSize: 200,
        symbolOffset: [13, 13],
        symbolRotate: 23,
      },
      data: heatmapDataResult(
        indicatorSeriesName,
        indicatorShowValue.position as BarValuePositionType & TopAndBottomType,
        indicatorShowValue.orientation === 'horizontal',
      ),
    };

    const labelOption = {
      fontSize: properties[indicatorName]?.fontSize,
      fontWeight: properties[indicatorName]?.fontStyle.bold ? 'bold' : 'normal',
      fontStyle: properties[indicatorName]?.fontStyle.italic ? 'italic' : 'normal',
    };

    return {
      name: indicatorSeriesName,
      type: 'heatmap',
      label: {
        ...labelOption,
        backgroundColor: 'transparent',
        show: indicatorShowValue.isShow,
        rotate: indicatorShowValue.orientation === 'horizontal' ? 0 : 90,
        formatter:
          formatting && formattingFunction
            ? (data) => {
                const value = data.value as Array<number | null | string>;
                if (value !== null) {
                  return formattingFunction?.(value[2]);
                }
              }
            : `{c}`,
      },

      ...sameOption,
    } as HeatmapSeriesOption;
  }, [
    heatmapDataResult,
    indicatorSeriesName,
    indicatorShowValue.position,
    indicatorShowValue.orientation,
    indicatorShowValue.isShow,
    properties,
    indicatorName,
    formatting,
    formattingFunction,
  ]);

  const minAndMaxValues = useMemo(
    () =>
      getMinMaxValues({
        settings: dataSettings.minAndMax,
        isNominated: dataSettings.type === BarTypesEnum.Nominated,
        activeIncisionSum: heatmapData.activeIncisionSum,
      }),
    [dataSettings.minAndMax, dataSettings.type, heatmapData.activeIncisionSum],
  );

  const xAxisValue = useMemo(
    () =>
      getIncisionAxisConfigWithGridDimensions({
        axisIncisionSettings: axisXIncisionSettings,
        fieldName: xAxisIncisions.fieldName,
        activeThemeSchema,
        data: heatmapData.data[xAxisIncisions.fieldName] as Array<string | null> | Array<number | null>,
        isTriggerEvent: true,
      }),
    [axisXIncisionSettings, xAxisIncisions.fieldName, activeThemeSchema, heatmapData.data],
  );

  const yAxisValue = useMemo(
    () =>
      getIncisionAxisConfigWithGridDimensions({
        axisIncisionSettings: axisYIncisionSettings,
        fieldName: yAxisIncisions.fieldName,
        activeThemeSchema,
        data: heatmapData.data[yAxisIncisions.fieldName] as Array<string | null> | Array<number | null>,
        isTriggerEvent: true,
      }),
    [activeThemeSchema, axisYIncisionSettings, heatmapData.data, yAxisIncisions.fieldName],
  );

  const axisValues = useMemo(
    () => ({ xAxis: xAxisValue.config, yAxis: yAxisValue.config }),
    [xAxisValue.config, yAxisValue.config],
  );

  const maxIndicatorValue = !dataSettings.minAndMax.isShow
    ? heatmapData?.maxIndicatorSum[indicatorSeriesName]
    : minAndMaxValues?.maxValue || 0;

  const minIndicatorValue = heatmapData.minIndicatorSum[indicatorSeriesName] || 0;
  const legendValue = useMemo(
    () =>
      getLegendConfigWithGridDimensions({
        startAndEnd: dataVisualMapSelectedMinAndMax,
        typeLegend: backgroundByValueSettings.type,
        maxAndMinValue: { maxValue: maxIndicatorValue || 0, minValue: minIndicatorValue },
        colors: [...((colorsData || [defaultColor]) as string[])],
        visualMapSettings,
        defaultColor,
      }),
    [
      dataVisualMapSelectedMinAndMax,
      backgroundByValueSettings.type,
      maxIndicatorValue,
      minIndicatorValue,
      colorsData,
      defaultColor,
      visualMapSettings,
    ],
  );

  const { onDataZoom, zoomValues } = useDataZoom({
    data: heatmapData.data as VisualisationValuesInterface,
    legendSettings: visualMapSettings,
    horizontalZoom,
    verticalZoom,
    dataZoomHorizontalStartAndEnd,
    dataZoomVerticalStartAndEnd,
    onDataZoomHorizontalStartAndEndSettingsChange,
    onDataZoomVerticalStartAndEndSettingsChange,
  });

  const { onDataVisualMapSelected } = useDataVisualMapSelected({
    dataVisualMapSelectedMinAndMax,
    onDataVisualMapSelectedMinAndMaxSettingsChange,
  });

  const { horizontalSliderXZoomValue, horizontalInsideYZoomValue, verticalInsideXZoomValue, verticalSliderYZoomValue } =
    zoomValues;

  const barGraphGridDimensions = useMemo<GridDimensionsInterface>(
    () => getBarGraphGridDimensions(dataSettings.indicators),
    [dataSettings.indicators],
  );

  const lineGraphGridDimensions = useMemo<GridDimensionsInterface>(() => {
    let isActiveGrid = false;

    dataSettings.indicators.forEach(
      ({
        settings: {
          showValue: { isShow, position },
        },
      }) => {
        if (!isActiveGrid) {
          isActiveGrid = isShow && (position as TopAndBottomType) === 'top';
        }
      },
    );

    const shiftPosition = 'top';

    return isActiveGrid ? { ...initialDimensions, [shiftPosition]: 20 } : initialDimensions;
  }, [dataSettings.indicators]);

  const gridValue = useMemo<HeatmapEChartsOption['grid']>(
    () =>
      calculateGridDimension([
        defaultGridDimension,
        legendValue.gridDimensions,
        horizontalInsideYZoomValue.gridDimensions,
        verticalInsideXZoomValue.gridDimensions,
        initialDimensions,
        lineGraphGridDimensions,
        barGraphGridDimensions,
        xAxisValue.gridDimensions,
        yAxisValue.gridDimensions,
      ]),
    [
      legendValue.gridDimensions,
      horizontalInsideYZoomValue.gridDimensions,
      verticalInsideXZoomValue.gridDimensions,
      lineGraphGridDimensions,
      barGraphGridDimensions,
      xAxisValue.gridDimensions,
      yAxisValue.gridDimensions,
    ],
  );

  const tooltipFormatter: TooltipFormatterCallback = useMemo(
    () => (params: TooltipFormatterParams) => {
      if (Array.isArray(params)) {
        return params[0].seriesName || '';
      }

      const data = params.data as FormatterDataParams;

      const incisionX = data.value[0],
        incisionY = data.value[1],
        indicator = data.value[2],
        indicatorMarker = params.marker;

      const indicatorFormatting = formatting && formattingFunction ? formattingFunction(indicator) : indicator;
      const stylesHTML = (text: number | string) => `<span style="font-weight:bold">${text}</span>`;

      return `${fieldNameIncisionYAxis} ${stylesHTML(incisionY)}<br>
              ${fieldNameIncisionXAxis} <span style="font-weight:bold">${stylesHTML(incisionX)}</span><br>
              ${indicatorMarker}${fieldNameIndicator}  <span style="font-weight:bold">${stylesHTML(indicatorFormatting)}</span>`;
    },
    [fieldNameIncisionXAxis, fieldNameIncisionYAxis, fieldNameIndicator, formatting, formattingFunction],
  );

  const tooltipValue = useMemo<HeatmapEChartsOption['tooltip']>(
    () => ({
      show: showTips,
      formatter: tooltipFormatter,
      axisPointer: {
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
        crossStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
    }),
    [showTips, tooltipFormatter, activeThemeSchema],
  );

  const option = useMemo<HeatmapEChartsOption>(
    () => ({
      textStyle: {
        color: defaultColor,
      },
      series: series as HeatmapEChartsOption['series'],
      visualMap: legendValue.config,
      tooltip: tooltipValue,
      grid: visualisationPaddingsIsShow
        ? {
            top: topPadding,
            bottom: bottomPadding,
            left: leftPadding,
            right: rightPadding,
          }
        : gridValue,
      dataZoom: [
        horizontalSliderXZoomValue.config,
        verticalSliderYZoomValue.config,
        horizontalInsideYZoomValue.config,
        verticalInsideXZoomValue.config,
      ],
      ...(axisValues as DefaultAxisInterface),
    }),
    [
      defaultColor,
      series,
      legendValue.config,
      tooltipValue,
      visualisationPaddingsIsShow,
      topPadding,
      bottomPadding,
      leftPadding,
      rightPadding,
      gridValue,
      horizontalSliderXZoomValue.config,
      verticalSliderYZoomValue.config,
      horizontalInsideYZoomValue.config,
      verticalInsideXZoomValue.config,
      axisValues,
    ],
  );

  const horizontalOptions: SettingsRadioItem<string>[] = dataSettings.incisions.map(
    ({ id, name, fieldName, settings: { nameFromDatabase } }) => ({
      value: id,
      label: getVisualisationFieldName({ nameFromDatabase, fieldName, name }),
    }),
  );

  const verticalOptions: SettingsRadioItem<string>[] = dataSettings.verticalIncisions.map(
    ({ id, name, fieldName, settings: { nameFromDatabase } }) => ({
      value: id,
      label: getVisualisationFieldName({ nameFromDatabase, fieldName, name }),
    }),
  );

  const onChangeActiveIncision = (activeIncisionId: string | null) => {
    if (enabledFilters && dataSettings.activeHorizontalIncisionId && activeIncisionId) {
      onDeleteFiltersForDrillDownIncisionsHeatmap({
        dataSettings,
        enabledFilters,
        lastActiveIncisionId: dataSettings.activeHorizontalIncisionId,
        nextActiveIncisionId: activeIncisionId,
        incisionKey: KeysIncisionsHeatmapEnum.INCISIONS,
      });
    }

    activeIncisionId && onActiveHorizontalIncisionIdChange({ id, activeIncisionId });
  };
  const onChangeActiveVerticalIncision = (activeIncisionId: string | null) => {
    if (enabledFilters && dataSettings.activeVerticalIncisionId && activeIncisionId) {
      onDeleteFiltersForDrillDownIncisionsHeatmap({
        dataSettings,
        enabledFilters,
        lastActiveIncisionId: dataSettings.activeVerticalIncisionId,
        nextActiveIncisionId: activeIncisionId,
        incisionKey: KeysIncisionsHeatmapEnum.VERTICAL_INCISIONS,
      });
    }

    activeIncisionId && onActiveVerticalIncisionIdChange({ id, activeIncisionId });
  };

  const onChartClick = useCallback(
    (params: CallbackDataParams) => {
      const actions: Record<string, NoopType> = {
        xAxis: () => {
          updateFilter({ selectedValue: params.value as string, fieldName: `${incisionXAxisFieldName}` });
          onActiveNextIncisionIdChange({
            id,
            dataSettings,
            events,
            activeIncisionId: activeHorizontalIncisionId,
            onChange: onActiveHorizontalIncisionIdChange,
          });
        },
        yAxis: () => {
          updateFilter({ selectedValue: params.value as string, fieldName: `${incisionYAxisFieldName}` });
          onActiveNextVerticalIncisionIdChange({
            id,
            dataSettings,
            events,
            activeIncisionId: activeVerticalIncisionId,
            onChange: onActiveVerticalIncisionIdChange,
          });
        },
        series: () => {
          if (moreThanOne) {
            updateFilter({ selectedValue: params.name, fieldName: `${incisionXAxisFieldName}` });
            updateFilter({
              selectedValue: (params.value as (string | null)[])[1],
              fieldName: `${incisionYAxisFieldName}`,
            });
            onActiveNextIncisionIdChange({
              id,
              dataSettings,
              events,
              activeIncisionId: activeHorizontalIncisionId,
              onChange: onActiveHorizontalIncisionIdChange,
            });
            onActiveNextVerticalIncisionIdChange({
              id,
              dataSettings,
              events,
              activeIncisionId: activeVerticalIncisionId,
              onChange: onActiveVerticalIncisionIdChange,
            });
          }
        },
      };

      const action = actions[params.componentType];
      if (action) action();
    },
    [
      updateFilter,
      incisionXAxisFieldName,
      incisionYAxisFieldName,
      moreThanOne,
      id,
      dataSettings,
      events,
      activeHorizontalIncisionId,
      activeVerticalIncisionId,
    ],
  );

  const onEvents = {
    dataZoom: onDataZoom,
    click: onChartClick,
    datarangeselected: onDataVisualMapSelected,
  };

  return (
    <FlexContainer flexDirection="column" width="100%" height="100%">
      {isVisible && (
        <FlexContainer flexDirection="row" justifyContent="space-between">
          <FlexContainer flexDirection="row" alignContent="center" gap="4px">
            <PrimaryTextSpan fontSize="14px">{'X:'}</PrimaryTextSpan>
            <RadioButtonSelector
              onChange={({ value }) => onChangeActiveIncision(value)}
              options={horizontalOptions}
              activeValue={getActiveRadioValue(horizontalOptions, String(activeHorizontalIncisionId))}
              isDrillDown={!!hasFiltersToHorizontalIncisions?.length}
            />
          </FlexContainer>
          <FlexContainer flexDirection="row" alignContent="center" gap="4px">
            <PrimaryTextSpan fontSize="14px">{'Y:'}</PrimaryTextSpan>
            <RadioButtonSelector
              onChange={({ value }) => onChangeActiveVerticalIncision(value)}
              options={verticalOptions}
              activeValue={getActiveRadioValue(verticalOptions, String(activeVerticalIncisionId))}
              isDrillDown={!!hasFiltersToVerticalIncisions?.length}
            />
          </FlexContainer>
        </FlexContainer>
      )}
      <WrappedReactECharts
        onEvents={onEvents}
        notMerge
        ref={(e) => {
          eChartRef.current = e?.getEchartsInstance();
        }}
        style={{ width: '100%', height: '100%' }}
        option={option}
      />
    </FlexContainer>
  );
};

export const HeatmapVisualisation = memo(HeatmapVisualisationComponent) as VisualisationOriginInterface<HeatmapVisualisationType>;
