import { HeaderItemInterface } from 'modules/ui/menu/HeaderMenu/types';
import { LoadAdminRoute } from 'constants/Routes';

export const menuItemsAdmin: HeaderItemInterface<LoadAdminRoute>[] = [
  {
    key: 'adminUser',
    name: 'Пользователи',
  },
  {
    key: 'adminGroups',
    name: 'Группы',
  },
  {
    key: 'adminActiveDirectories',
    name: 'Активные директории',
  },
  {
    key: 'adminSources',
    name: 'Подключения',
  },
  {
    key: 'adminTasks',
    name: 'Задания',
  },
  {
    key: 'adminLicenses',
    name: 'Лицензии',
  },
  {
    key: 'adminFlows',
    name: 'Потоки',
  },
  {
    key: 'adminSettings',
    name: 'Настройки',
  },
];
