export type LoadRoute = 'model' | 'console';
export type LoadAdminRoute =
  | 'adminUser'
  | 'adminGroups'
  | 'adminFlows'
  | 'adminLicenses'
  | 'adminTasks'
  | 'adminSources'
  | 'adminActiveDirectories'
  | 'adminSettings';

export type Route =
  | 'preview'
  | 'board'
  | 'console'
  | 'login'
  | 'adminUser'
  | 'adminTasks'
  | 'adminActiveDirectories'
  | 'adminLicenses'
  | 'adminFlows'
  | 'projectsList'
  | 'adminUsers'
  | 'adminGroups'
  | 'adminSources'
  | 'adminSettings'
  | LoadRoute;

export type ProjectIdParam = 'projectId';
export type PageIdParam = 'pageId';

export const RoutesURL: Record<Route, string> = {
  projectsList: '/',
  preview: 'preview',
  login: 'login',
  adminUsers: 'admin',
  adminUser: 'adminUser',
  adminFlows: 'adminFlows',
  adminLicenses: 'adminLicenses',
  adminGroups: 'adminGroups',
  adminTasks: 'adminTasks',
  adminActiveDirectories: 'adminActiveDirectories',
  adminSettings: 'adminSettings',
  model: 'model',
  console: 'console',
  board: 'board',
  adminSources: 'adminSources',
};

export const getProjectIdUrl = (url: string) => `:projectId/${url}`;

export const goToProjectUrl = (url: string, projectId?: string) => `/${projectId || ''}/${url}`;
export const goToBoardUrl = (projectId?: string, pageId?: string) =>
  `${goToProjectUrl(RoutesURL.board, projectId)}/${pageId !== undefined ? pageId : '0'}`;
export const goToConsolePageUrl = (projectId?: string) => goToProjectUrl(RoutesURL.console, projectId);
export const goToModelPageUrl = (projectId?: string) => goToProjectUrl(RoutesURL.model, projectId);
