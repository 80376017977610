import { FlexContainer } from 'styles/FlexContainer';
import React from 'react';
import { LoadingBlockSizeType } from './types';
import { getSizeByType } from './constants';
import { CircularProgress } from '@mui/material';

export interface LoaderBlockProps {
  size?: LoadingBlockSizeType;
}

export const LoaderBlock = ({ size = 'default' }: LoaderBlockProps) => (
  <FlexContainer width="100%" height="100%" alignItems="center" justifyContent="center">
    <CircularProgress size={getSizeByType[size]} />
  </FlexContainer>
);
