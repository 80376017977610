import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const MapListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GroupWrapper = styled.div`
  border-top: 1px solid var(${ColorVarsEnum.Level_5});
`;

export const ItemsWrapper = styled.div`
  display: block;
  margin-left: 8px;
`;
