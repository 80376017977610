import React, { FC, useState } from 'react';
import { CellGridItem, CellGridWrapper } from 'shared/ui/CellGrid/styles';
import { CellGridOptionType } from 'shared/ui/CellGrid/types';

interface CellGridProps {
  options: CellGridOptionType[];
  columns: number;
  defaultValue?: number[];
  onChange?: (value: number[]) => void;
}

export const CellGrid: FC<CellGridProps> = ({ options, columns, defaultValue, onChange }) => {
  const [active, setActive] = useState<number[]>(defaultValue || []);
  const handleChange = (activeValues: number[], value: number, isAdd?: boolean, isShift?: boolean) => {
    let result = isAdd ? [...activeValues, value] : [value];

    if (isShift) {
      const minNumber = +result[0] || 1;

      result = [];

      for (let i = minNumber; i <= +value; i++) {
        result.push(i);
      }
    }

    if (isAdd && activeValues.includes(value)) {
      result = result.filter((el) => el !== value);
    }

    setActive(result);
    onChange && onChange(result);
  };

  return (
    <CellGridWrapper columns={columns}>
      {options.map((option) => (
        <CellGridItem
          key={`cell-grid-item-${option.value}`}
          active={active.includes(option.value)}
          onClick={(event) => {
            if (event.ctrlKey || event.metaKey || event.shiftKey) {
              return handleChange(active, option.value, true, event.shiftKey);
            }
            handleChange(active, option.value);
          }}
        >
          {option.label}
        </CellGridItem>
      ))}
    </CellGridWrapper>
  );
};
