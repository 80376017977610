import { createSelector } from 'reselect';
import { getState } from 'store/utils';

export const getGroupsStore = createSelector(getState, (state) => state.adminGroups);

export const getGroups = createSelector(getGroupsStore, (state) => state.groups);

export const getGroupAccessUsers = createSelector(getGroupsStore, (state) => state.groupAccessUsers);

export const getGroupFlows = createSelector(getGroupsStore, (state) => state.groupFlows);

export const getGroupSources = createSelector(getGroupsStore, (state) => state.groupSources);

export const getActiveGroup = createSelector(getGroupsStore, (state) => state.activeGroup);
