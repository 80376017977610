import {
  AliasWrapper,
  ColumnItem,
  ColumnNameWrapper,
  EditButtonsWrapper,
  StyledTextInput,
  TableHeader,
  TableViewWrapper,
} from 'components/contents/Models/TableView/styles';
import { Alias, IsHeadInterface, Table } from 'store/reducers/models/types';
import { IsActiveInterface } from 'store/reducers/visualisations/types';
import { ColumnItemInterface } from 'components/contents/Models/TableView/types';
import { DeleteIcon, EditIcon, EyeIcon, KeyIcon } from 'assets/icons/withContainer';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import React, { ChangeEvent, KeyboardEventHandler, MouseEventHandler, useState } from 'react';
import { NoopType, NoopValueType } from 'types/global';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export interface TableViewProps extends Alias, Table, Partial<IsHeadInterface>, Partial<IsActiveInterface> {
  columns: ColumnItemInterface[];
  onDeleteClick: NoopType;
  makeHeadClick: NoopType;
  onChangeAlias: NoopValueType<string>;
  prohibitDelete?: boolean;
}

export const TableView = ({
  alias,
  isHead = false,
  columns,
  isActive = false,
  onDeleteClick,
  makeHeadClick,
  onChangeAlias,
  prohibitDelete = false,
}: TableViewProps) => {
  const [editAlias, setEditAlias] = useState<string | null>(null);

  const onEditClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    const newEditAlias = editAlias === alias ? null : alias;

    setEditAlias(newEditAlias);
  };

  const onEdit = (e: ChangeEvent<HTMLInputElement>) => {
    setEditAlias((value) => (value ? e.target.value : null));
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter' && !!editAlias) {
      onChangeAlias(editAlias);
      setEditAlias(null);
    }
  };

  return (
    <TableViewWrapper isActive={isActive}>
      <TableHeader>
        <AliasWrapper title={alias}>
          {editAlias ? (
            <StyledTextInput
              autoFocus
              name={`edit_alias`}
              width="100%"
              value={editAlias}
              onChange={onEdit}
              onKeyDown={onKeyDown}
            />
          ) : (
            <span>{alias}</span>
          )}
          <EditButtonsWrapper>
            <IconWrapper
              onClick={onEditClick}
              iconWidth="17px"
              iconHeight="17px"
              containerHeight="30px"
              containerWidth="30px"
              Icon={EditIcon}
            />
          </EditButtonsWrapper>
        </AliasWrapper>
        <FlexContainer>
          <IconWrapper
            onClick={isHead ? undefined : makeHeadClick}
            iconWidth="17px"
            iconHeight="17px"
            containerHeight="30px"
            containerWidth="30px"
            colorVar={isHead ? ColorVarsEnum.Level_1 : undefined}
            hoverColorVar={isHead ? ColorVarsEnum.Level_1 : undefined}
            Icon={EyeIcon}
          />
          {!prohibitDelete && (
            <IconWrapper
              onClick={onDeleteClick}
              iconWidth="17px"
              iconHeight="17px"
              containerHeight="30px"
              containerWidth="30px"
              Icon={DeleteIcon}
            />
          )}
        </FlexContainer>
      </TableHeader>
      {columns.map(({ key, name, isConnected }) => {
        return (
          <ColumnItem key={key}>
            <ColumnNameWrapper title={name}>{name}</ColumnNameWrapper>
            {isConnected && (
              <IconWrapper
                cursor="default"
                iconWidth="24px"
                iconHeight="24px"
                containerHeight="100%"
                containerWidth="30px"
                Icon={KeyIcon}
              />
            )}
          </ColumnItem>
        );
      })}
    </TableViewWrapper>
  );
};
