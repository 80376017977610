import { HintChildrenCron } from 'components/admin/wrappers/ActiveDirectoriesAndTasksWrapper/HintChildrenCron';
import {
  CronTypeEnum,
  formattingValueTime,
  getCronDate,
  getDefaultCronDate,
  monthPickerOptions,
  scheduleInit,
  ScheduleType,
  TaskRadioVariantsType,
  timeLineRadioOptions,
  weekPickerOptions,
} from 'components/TaskRadio/index';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import isArray from 'lodash/isArray';
import { TextField } from 'modules/ui';
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CellGrid, RadioButton } from 'shared/ui';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { CronDateTypes } from 'types/types';

interface TaskRadioProps {
  onChange: (...event: any[]) => void;
  value: CronDateTypes;
}

export const TaskRadio: FC<TaskRadioProps> = ({ onChange, value }) => {
  const [type, setType] = useState<TaskRadioVariantsType>(value.type || 'day');
  const [schedule, setSchedule] = useState<ScheduleType>(getDefaultCronDate(value));

  const onSetValue = useCallback(
    (value: CronDateTypes) => {
      onChange(value);
    },
    [onChange],
  );

  useEffect(() => {
    onSetValue(getCronDate[type](schedule));
  }, [onSetValue, type, schedule]);

  const onChangeRadio = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    setSchedule(scheduleInit);
    setType(value as TaskRadioVariantsType);
  };

  const onChangeTime = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = formattingValueTime(e.target.value);
    const [hoursInString, minutesInString] = (value || '').split(':');

    const hours = isNaN(+hoursInString) ? 0 : +hoursInString <= 23 ? +hoursInString : 23;
    const minutes = isNaN(+minutesInString) ? 0 : +minutesInString <= 59 ? +minutesInString : 59;

    setSchedule((prev) => ({ ...prev, minutes, hours }));
  }, []);

  const onChangeCellGrid = (days: number[]) => setSchedule((prev) => ({ ...prev, days }));

  const onChangeDay = (e: ChangeEvent<HTMLInputElement>) => setSchedule((prev) => ({ ...prev, days: +e.target.value }));
  const onChangeHour = (e: ChangeEvent<HTMLInputElement>) =>
    setSchedule((prev) => ({
      ...prev,
      hours: +e.target.value <= 23 ? +e.target.value : 23,
    }));

  const onChangeMinutes = (e: ChangeEvent<HTMLInputElement>) =>
    setSchedule((prev) => ({ ...prev, minutes: +e.target.value <= 59 ? +e.target.value : 59 }));

  /*TODO заменить TextField на компонент с двунаправленным состоянием, без использования useState внутри компонента*/
  const getTimeField = useCallback(
    () => (
      <TextField
        key={type}
        name="time"
        width="100%"
        label="Время"
        maxLength={5}
        defaultValue={`${schedule.hours || '00'}:${schedule.minutes || '00'}`}
        onChange={onChangeTime}
        formattingValue={formattingValueTime}
      />
    ),
    [schedule.hours, schedule.minutes, onChangeTime, type],
  );

  const getTitle = (title: string) => (
    <PrimaryTextParagraph lineHeight="12px" fontSize="12px" color={`var(${ColorVarsEnum.Level_2})`}>
      {title}
    </PrimaryTextParagraph>
  );

  const component = useMemo(
    () => ({
      [CronTypeEnum.HOUR]: (
        <>
          <TextField
            name="repeat"
            width="100%"
            label="Периодичность в часах, каждый"
            value={schedule.hours}
            onChange={onChangeHour}
            maxLength={2}
          />
          <TextField
            name="minute"
            width="100%"
            label="На минуте"
            value={schedule.minutes}
            onChange={onChangeMinutes}
            maxLength={2}
          />
        </>
      ),
      [CronTypeEnum.DAY]: (
        <>
          <TextField
            name="repeat"
            width="100%"
            label="Периодичность в днях, каждый"
            value={isArray(schedule.days) ? schedule.days[0] : schedule.days}
            onChange={onChangeDay}
          />
          {getTimeField()}
        </>
      ),
      [CronTypeEnum.WEEK]: (
        <>
          {getTitle('День недели')}
          <CellGrid
            key="День недели"
            defaultValue={isArray(schedule.days) ? schedule.days : []}
            columns={7}
            options={weekPickerOptions}
            onChange={onChangeCellGrid}
          />
          {getTimeField()}
        </>
      ),
      [CronTypeEnum.MONTH]: (
        <>
          {getTitle('День месяца')}
          <CellGrid
            key="День месяца"
            defaultValue={isArray(schedule.days) ? schedule.days : []}
            columns={7}
            options={monthPickerOptions}
            onChange={onChangeCellGrid}
          />
          {getTimeField()}
        </>
      ),
      [CronTypeEnum.CRON]: (
        <FlexContainer marginLeft="auto" width="100%">
          <TextField
            name={'value.schedule'}
            HintChildrenText={<HintChildrenCron />}
            widthContainer="430px"
            label="Задание в крон"
            width="100%"
            defaultValue={schedule.schedule}
            onChange={(event) => setSchedule((prev) => ({ ...prev, schedule: event.target.value }))}
          />
        </FlexContainer>
      ),
    }),
    [getTimeField, schedule],
  );

  return (
    <FlexContainer width="100%">
      <PrimaryTextParagraph lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
        График
      </PrimaryTextParagraph>

      <FlexContainer width="430px" marginLeft="auto">
        <FlexContainer>
          <RadioButton
            sx={{ gap: '16px' }}
            name="timeLine"
            defaultValue={type}
            options={timeLineRadioOptions}
            onChange={onChangeRadio}
          />
        </FlexContainer>

        <FlexContainer width="260px" flexDirection="column" gap="16px" marginLeft="auto">
          {component[type]}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
