import { GridDimensionsInterface } from 'types/echarts';
import { AbsolutePositionType } from 'types/styles';

export const initialDimensions: GridDimensionsInterface = { left: 0, top: 0, bottom: 0, right: 0 };
export const gridDimensionKeys = Object.keys(initialDimensions) as AbsolutePositionType[];

export const calculateGridDimension: (dimensions: GridDimensionsInterface[]) => GridDimensionsInterface = (dimensions) =>
  dimensions.reduce(
    (previousValue, currentValue) =>
      gridDimensionKeys.reduce(
        (result, dimension) => ({ ...result, [dimension]: previousValue[dimension] + currentValue[dimension] }),
        initialDimensions,
      ),
    initialDimensions,
  );

export const getLengthOfGraphicByLength = (length: number) => 4.5 * length;

export const getLengthOfGraphicString = (graphicString: string | number) => {
  const stringLength = typeof graphicString === 'number' ? graphicString.toLocaleString('ru').length : graphicString.length;

  return getLengthOfGraphicByLength(stringLength);
};
