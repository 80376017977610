import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import styled from 'styled-components';

export const ContentIconWrapper = styled(IconWrapper)`
  display: none;

  @media (max-width: 1024px) {
    display: none;
  }
`;
