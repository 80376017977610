import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const FilterSingleDefault: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fill = `var(${ColorVarsEnum.Accent})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <path
          d="M36.3486 40V37.855L41.6494 32.1611C42.1885 31.5547 42.6115 31.0269 42.9185 30.5776C43.2254 30.1284 43.4425 29.7204 43.5698 29.3535C43.7046 28.9792 43.772 28.6086 43.772 28.2417C43.772 27.6053 43.5885 27.0662 43.2217 26.6245C42.8623 26.1828 42.3307 25.9619 41.627 25.9619C40.8708 25.9619 40.298 26.209 39.9087 26.7031C39.5194 27.1898 39.3247 27.8636 39.3247 28.7246H36.1353L36.1128 28.6572C36.0903 27.6689 36.3 26.7817 36.7417 25.9956C37.1909 25.202 37.8348 24.5731 38.6733 24.1089C39.5194 23.6447 40.5151 23.4126 41.6606 23.4126C42.7837 23.4126 43.7458 23.6147 44.5469 24.019C45.3555 24.4159 45.9769 24.9736 46.4111 25.6924C46.8454 26.4111 47.0625 27.2422 47.0625 28.1855C47.0625 28.8369 46.9465 29.4508 46.7144 30.0273C46.4823 30.5964 46.1191 31.1991 45.625 31.8354C45.1309 32.4718 44.4945 33.2018 43.7158 34.0254L40.5376 37.4395L40.5601 37.4956H44.7939L44.9624 35.9346H47.5005V40H36.3486Z"
          fill={fill}
        />
        <path
          d="M56.0693 40.2358C55.3132 40.2358 54.6693 40.0898 54.1377 39.7979C53.6061 39.4984 53.1606 39.0679 52.8013 38.5063L52.5654 40H49.7354V24.7939L47.9946 24.457V22.4805H53.0034V29.1401C53.3553 28.6535 53.7858 28.2791 54.2949 28.0171C54.8115 27.755 55.4105 27.624 56.0918 27.624C57.0726 27.624 57.9036 27.8898 58.585 28.4214C59.2738 28.9455 59.7979 29.6867 60.1572 30.645C60.5166 31.6034 60.6963 32.7301 60.6963 34.0254V34.2612C60.6963 35.4666 60.5166 36.5186 60.1572 37.417C59.7979 38.3079 59.2738 39.0005 58.585 39.4946C57.8962 39.9888 57.0576 40.2358 56.0693 40.2358ZM55.0698 37.6865C55.9084 37.6865 56.5111 37.3908 56.8779 36.7993C57.2448 36.2004 57.4282 35.3543 57.4282 34.2612V34.0254C57.4282 33.2318 57.3459 32.5505 57.1812 31.9814C57.0239 31.4049 56.7731 30.9595 56.4287 30.645C56.0918 30.3306 55.6501 30.1733 55.1035 30.1733C54.6094 30.1733 54.1864 30.2894 53.8345 30.5215C53.4826 30.7536 53.2056 31.068 53.0034 31.4648V36.541C53.228 36.9154 53.5125 37.1999 53.8569 37.3945C54.2088 37.5892 54.6131 37.6865 55.0698 37.6865Z"
          fill={fill}
        />
        <path d="M35 46H61V49H35V46Z" fill={fill} />
        <path
          d="M9.1626 40V38.0347L11.9253 37.6978V26.6245H9.12891V24.749L15.1934 23.6484V37.6978L17.9561 38.0347V40H9.1626Z"
          fill={fillDefault}
        />
        <path
          d="M23.3804 40.2358C22.175 40.2358 21.2166 39.9139 20.5054 39.27C19.7941 38.6187 19.4385 37.7352 19.4385 36.6196C19.4385 35.856 19.6444 35.1859 20.0562 34.6094C20.4754 34.0329 21.0894 33.5799 21.8979 33.2505C22.714 32.9211 23.7173 32.7563 24.9077 32.7563H26.5137V31.8579C26.5137 31.2739 26.3415 30.8022 25.9971 30.4429C25.6602 30.076 25.151 29.8926 24.4697 29.8926C24.1178 29.8926 23.8034 29.9375 23.5264 30.0273C23.2493 30.1097 22.9948 30.2295 22.7627 30.3867L22.5044 31.9927H20.146L20.1011 28.8369C20.7375 28.4775 21.4263 28.1855 22.1675 27.9609C22.9087 27.7363 23.736 27.624 24.6494 27.624C26.2367 27.624 27.4907 27.9946 28.4116 28.7358C29.3325 29.4696 29.793 30.5215 29.793 31.8916V36.9004C29.793 37.0651 29.793 37.2298 29.793 37.3945C29.8005 37.5518 29.8154 37.7052 29.8379 37.855L31.1069 38.0347V40H27.0415C26.9591 39.7904 26.8768 39.5658 26.7944 39.3262C26.7121 39.0866 26.6522 38.847 26.6147 38.6074C26.2254 39.1016 25.765 39.4984 25.2334 39.7979C24.7093 40.0898 24.0916 40.2358 23.3804 40.2358ZM24.2563 37.8101C24.7131 37.8101 25.1473 37.709 25.5591 37.5068C25.9709 37.2972 26.2891 37.0239 26.5137 36.687V34.7666H24.8965C24.1777 34.7666 23.6312 34.9313 23.2568 35.2607C22.89 35.5902 22.7065 35.987 22.7065 36.4512C22.7065 36.8854 22.8413 37.2223 23.1108 37.4619C23.3879 37.694 23.7697 37.8101 24.2563 37.8101Z"
          fill={fillDefault}
        />
      </svg>
    </FlexContainer>
  );
};
