import { FlexContainer } from 'styles/FlexContainer';
import React from 'react';
import { DownIcon, UpIcon } from 'assets/icons/withContainer';
import { IconWrapper } from './styles';
import { NoopType } from 'types/global';

export interface PriorityChangerButtonsProps {
  onUpClick?: NoopType;
  onDownClick?: NoopType;
}

export const PriorityChangerButtons = ({ onDownClick, onUpClick }: PriorityChangerButtonsProps) => {
  return (
    <FlexContainer flexShrink="0" flexDirection="column">
      <IconWrapper onClick={onUpClick}>
        <UpIcon />
      </IconWrapper>
      <IconWrapper onClick={onDownClick}>
        <DownIcon />
      </IconWrapper>
    </FlexContainer>
  );
};
