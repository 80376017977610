import { css } from 'styled-components';

export const defaultColorSize = 20;
export const colorSelectorSize = 20;
export const colorSelectorBlockSize = (colorSelectorSize - 2) / 2;
export const defaultColorGap = 2;
export const defaultMaxColorInGroup = 10;

export const defaultThemePaletteLength = 5;

export const positionCssMapper = [
  css`
    left: 0;
    top: 0;
  `,
  css`
    right: 0;
    top: 0;
  `,
  css`
    left: 0;
    bottom: 0;
  `,
  css`
    right: 0;
    bottom: 0;
  `,
];

export const colorPickerClassName = 'color-picker';
