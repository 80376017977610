import { BoardAreaContainer, BoardAreaContainerProps } from 'modules/workspace/components/BoardAreaContainer';
import { FC, IsLoadingInterface } from 'types/global';
import React from 'react';
import { Loader, LoaderTypeEnum } from 'shared/ui/Loader';

interface BoardContainerProps extends BoardAreaContainerProps, IsLoadingInterface {
  isActiveInViewMode?: boolean;
}

export const BoardContainer: FC<BoardContainerProps> = (props) => {
  const { children, isLoading } = props;

  return (
    <BoardAreaContainer {...props}>
      {isLoading && <Loader type={LoaderTypeEnum.VISUALIZATION} />}
      {children}
    </BoardAreaContainer>
  );
};
