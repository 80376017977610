import { store } from 'store';
import { updateSingleFilterAction } from 'store/reducers/filters/actions';
import { LimitSettingInterface, OverflowPositionInterface } from 'store/reducers/visualisations/types';
import { ConnectedCellPaddingSettings } from 'types/store';

const dispatch = store.dispatch;

export const onPaddingChange = (paddings: ConnectedCellPaddingSettings) => dispatch(updateSingleFilterAction({ paddings }));

export const onMultipleModeChange = (isMultipleMode: boolean) => dispatch(updateSingleFilterAction({ isMultipleMode }));

export const onAlwaysOpenChange = (isAlwaysOpen: boolean) => dispatch(updateSingleFilterAction({ isAlwaysOpen }));

export const onLimitChange = (limit: LimitSettingInterface) => dispatch(updateSingleFilterAction({ limit }));

export const onSelectedDataChange = (selectedData: string[]) => dispatch(updateSingleFilterAction({ selectedData }));

export const onOverflowPositionChange = (overflowPosition: OverflowPositionInterface) =>
  dispatch(updateSingleFilterAction({ overflowPosition }));
