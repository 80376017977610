import { FlexContainer } from 'styles/FlexContainer';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import React from 'react';
import { SettingsCheckboxItem } from 'modules/settingsContainer/SettingsCheckbox/types';
import { Checkbox } from 'modules/ui';
import { getNewStateValues, isActiveCheckboxValue } from 'modules/settingsContainer/SettingsCheckbox/constants';

export interface SettingsCheckboxProps<ValueType extends string> {
  activeValue: SettingsCheckboxItem<ValueType>[];
  options: SettingsCheckboxItem<ValueType>[];
  onChange: (value: SettingsCheckboxItem<ValueType>[]) => void;
}

export const SettingsCheckbox = <ValueType extends string>({
  activeValue,
  options,
  onChange,
}: SettingsCheckboxProps<ValueType>) => {
  return (
    <ElementContainerSettings>
      <FlexContainer flexDirection="column">
        {options.map((option) => {
          const { label } = option;

          return (
            <Checkbox
              key={label}
              value={label}
              name={label}
              id={label}
              onChange={() => onChange(getNewStateValues(activeValue, option))}
              checked={isActiveCheckboxValue(activeValue, option)}
              label={label}
            />
          );
        })}
      </FlexContainer>
    </ElementContainerSettings>
  );
};
