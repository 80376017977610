import { OnValueChange } from 'types/global';
import {
  IncisionSelectorPositionInterface,
  VisualisationFieldNameInterface,
  VisualisationIdInterface,
} from 'store/reducers/visualisations/types';
import { RadioButtonSelector } from 'modules/ui/RadioButtonSelector';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import { FlexContainer } from 'styles/FlexContainer';
import { NameFromDatabaseInterface } from 'types/store';
import { useEffect, useMemo } from 'react';

/* TODO - go to src/store/reducers/visualisations/types.ts */

export type DefaultIncisionType = VisualisationIdInterface &
  VisualisationFieldNameInterface & { settings: NameFromDatabaseInterface };

export interface IncisionRadioSelectorProps<Incisions extends DefaultIncisionType>
  extends OnValueChange<string | null>,
    IncisionSelectorPositionInterface {
  incisions: Incisions[];
  isDrillDown?: boolean;
}

export const IncisionRadioSelector = <Incisions extends DefaultIncisionType>({
  value,
  onChange,
  incisions,
  incisionSelectorPosition,
  isDrillDown,
}: IncisionRadioSelectorProps<Incisions>) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultIncisionId = useMemo(() => incisions?.[0]?.id, [incisions?.[0]?.id]),
    activeValue = useMemo(() => value || defaultIncisionId, [defaultIncisionId, value]);

  const options: SettingsRadioItem<string>[] = incisions.map(({ id, name, fieldName, settings: { nameFromDatabase } }) => ({
    value: id,
    label: getVisualisationFieldName({ nameFromDatabase, fieldName, name }),
  }));

  /* Update activeIncisionId if current active incision has been deleted */
  useEffect(() => {
    const hasActive = incisions.some(({ id }) => id === activeValue);

    if (!hasActive && defaultIncisionId) {
      onChange(defaultIncisionId);
    }

    if (!defaultIncisionId) {
      onChange(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(incisions), activeValue, onChange]);

  if (incisions.length < 2) {
    return null;
  }

  return (
    <FlexContainer width="100%" justifyContent={incisionSelectorPosition}>
      <RadioButtonSelector
        onChange={({ value }) => onChange(value)}
        options={options}
        activeValue={getActiveRadioValue(options, activeValue)}
        isDrillDown={isDrillDown}
      />
    </FlexContainer>
  );
};
