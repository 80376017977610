import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { headerDropDownHeight } from 'modules/ui/blocks/DropDownBlock/constants';

export const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface DropDownContentProps {
  height?: string;
}

export const ContentWrapper = styled.div<DropDownContentProps>`
  width: 100%;
  overflow: hidden;
  ${({ height }) => height && `height: ${height};`};
`;

export const DropDownBlockWrapper = styled.div<DropDownContentProps>`
  display: flex;
  flex-direction: column;
  ${({ height }) => height && `height: ${height};`};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
`;

export const HeaderDropDown = styled.div`
  height: ${headerDropDownHeight};
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(${ColorVarsEnum.Level_3_menu});
  cursor: pointer;
  flex: 0 0 ${headerDropDownHeight};
`;

export const BodyDropDown = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100% - ${headerDropDownHeight});
`;
