import React, { useCallback, useState } from 'react';
import { MultiSelect, MultiSelectType } from 'modules/ui/MultiSelect';
import { Button, Select } from 'modules/ui';
import { useAppDispatch } from 'store';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { getActiveGroup } from 'store/reducers/adminGroups/getters';
import {
  createGroupSourceAction,
  loadGroupSourcesAction,
  updateGroupSourceAction,
  updateGroupSourcesAction,
} from 'store/reducers/adminGroups/actions';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Form } from './styles';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { accessItems, defaultSelectedAccess } from 'components/admin/groups/constants';
import { GroupSourcesListInterface } from 'store/reducers/adminGroups/types';
import { ModeForm } from 'types/store';
import { SelectItemInterface } from 'components/admin/groups/types';
import { ModalComponentType } from 'store/reducers/modals/types';
import { GroupSourceFormType } from 'components/admin/groups/elements/Sources/types';

interface AddSourceModalProps {
  selectData?: MultiSelectType<SelectItemInterface>[];
  source?: GroupSourcesListInterface;
  mode: ModeForm;
}

export const SourceModal: ModalComponentType<AddSourceModalProps> = ({ onClose, selectData, mode, source }) => {
  const dispatch = useAppDispatch();
  const isAdd = mode === 'add';

  const activeGroup = useSelector(getActiveGroup);
  const activeGroupId = activeGroup?.id;

  const sourceId = source?.id;
  const sourceName = source?.name;

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<GroupSourceFormType>({
    defaultValues: {
      access: source?.sourceGroupType || defaultSelectedAccess,
      ...(selectData && { source: selectData[0].value }),
    },
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<GroupSourceFormType> = useCallback(
    async ({ access, sources }) => {
      try {
        if (!isDirty || !activeGroupId) {
          return null;
        }

        setLoading(true);

        if (isAdd) {
          const response = await dispatch(
            createGroupSourceAction({
              sources,
              type: access,
              groupId: activeGroupId,
            }),
          );

          if (response) {
            dispatch(loadGroupSourcesAction(activeGroupId));
          }
        }

        if (!isAdd && sourceId && sourceName) {
          const response = await dispatch(
            updateGroupSourceAction({
              sourceId,
              groupId: activeGroupId,
              type: access,
            }),
          ).unwrap();

          if (response) {
            dispatch(updateGroupSourcesAction({ source: { id: sourceId, name: sourceName, sourceGroupType: access } }));
          }
        }

        onClose();
      } catch (e) {
        return;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDirty, activeGroupId, isAdd, onClose, sourceId, sourceName],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={loading} />

      <Controller
        render={({ field }) => <Select title="Права" options={accessItems} width="100%" {...field} value={field.value} />}
        name="access"
        control={control}
      />

      {isAdd && selectData && (
        <Controller
          name="sources"
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange } }) => (
            <MultiSelect
              label="К подключению"
              noOptionsText="Подключения не найдены"
              onChange={(item) => onChange(item.map((el) => el.value))}
              options={selectData}
            />
          )}
        />
      )}

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text={isAdd ? 'Добавить' : 'Изменить'} iconSize="normal" heightSize="normal" type="submit" />
      </FlexContainer>
    </Form>
  );
};
