import { useCallback, useState } from 'react';
import { CallbackDataRangeSelectedParams } from 'types/echarts';
import { StartAndEndInterface } from 'types/store';
import { useSettingsChange } from 'utils/hooks/visualisation/useSettingsChange';
import { NoopValueType } from 'types/global';
import { startAndEnd } from 'constants/global';

export interface DataZoomProps {
  dataVisualMapSelectedMinAndMax: StartAndEndInterface;
  onDataVisualMapSelectedMinAndMaxSettingsChange: NoopValueType<StartAndEndInterface>;
}

export const useDataVisualMapSelected = ({
  dataVisualMapSelectedMinAndMax,
  onDataVisualMapSelectedMinAndMaxSettingsChange,
}: DataZoomProps) => {
  const [minAndMax, setMinAndMax] = useState<StartAndEndInterface>(dataVisualMapSelectedMinAndMax || startAndEnd);

  const onDataVisualMapSelected = useCallback((params: CallbackDataRangeSelectedParams) => {
    const { selected } = params;
    const [start, end] = selected;

    setMinAndMax({ start, end });
  }, []);

  useSettingsChange({ value: minAndMax, onChange: onDataVisualMapSelectedMinAndMaxSettingsChange });

  return { onDataVisualMapSelected };
};
