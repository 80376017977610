import { FlexContainer } from 'styles/FlexContainer';
import Button from 'modules/ui/Button';
import React, { MouseEventHandler } from 'react';
import TextField from 'modules/ui/TextField';
import { PriorityChangerButtons, PriorityChangerButtonsProps } from 'modules/settingsContainer/PriorityChangerButtons';
import { NoopType, ReactChildrenType } from 'types/global';
import { ClosedEyeIcon, CopyIcon, EyeIcon as DefaultEyeIcon } from 'assets/icons/withContainer';
import { SettingsFieldWrapper, SettingsFieldWrapperProps } from 'modules/ui/wrappers/SettingsFieldWrapper/styles';

interface SettingsFieldEntryProps extends PriorityChangerButtonsProps, SettingsFieldWrapperProps {
  children?: ReactChildrenType;
  fieldValue: string;
  onFieldChange: (fieldValue: string) => void;
  onClickHidden: NoopType;
  onSetupClick?: MouseEventHandler<HTMLButtonElement>;
  setupIsOpen?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onCopyClick?: NoopType;
  isHidden?: boolean;
  EyeIcon?: JSX.Element;
  disableCopy?: boolean;
  disablePriority?: boolean;
}

export const SettingsPagesFieldEntry = ({
  fieldValue,
  onFieldChange,
  onClickHidden,
  isHidden,
  onUpClick,
  onDownClick,
  isSelected,
  isActive,
  onClick,
  children,
  onCopyClick,
  EyeIcon,
  disablePriority,
  disableCopy,
}: SettingsFieldEntryProps) => {
  return (
    <SettingsFieldWrapper onClick={onClick} isSelected={isSelected} isActive={isActive}>
      <FlexContainer alignItems="center" gap="4px">
        <TextField
          name="xAxisName"
          width="100%"
          needBackground={false}
          needBorderBottom={false}
          value={fieldValue}
          useDebounce
          onChange={(e) => onFieldChange(e.target.value)}
        />

        {isSelected && !disableCopy && <Button leftIcon={<CopyIcon />} needBackground={false} onClick={onCopyClick} />}
        {isSelected && !disablePriority && <PriorityChangerButtons onDownClick={onDownClick} onUpClick={onUpClick} />}
        <Button
          heightSize="small"
          needBackground={false}
          leftIcon={isHidden ? <ClosedEyeIcon /> : EyeIcon || <DefaultEyeIcon />}
          iconSize="big"
          onClick={onClickHidden}
        />
      </FlexContainer>

      {isSelected && children && (
        <FlexContainer alignItems="center" gap="10px">
          <FlexContainer flex="1 1 0">{children}</FlexContainer>
        </FlexContainer>
      )}
    </SettingsFieldWrapper>
  );
};
