import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const WaterfallDefault: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fill = `var(${ColorVarsEnum.Accent})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <rect opacity="0.35" x="12" y="24" width="8" height="27" fill={fillDefault} />
        <rect opacity="0.75" x="24" y="24" width="8" height="13" fill={fillDefault} />
        <rect opacity="0.35" x="48" y="12" width="8" height="39" fill={fillDefault} />
        <rect x="36" y="12" width="8" height="25" fill={fill} />
        <rect opacity="0.15" x="8" y="55" width="54" height="3" fill={fillDefault} />
      </svg>
    </FlexContainer>
  );
};
