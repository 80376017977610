import { FlexContainer } from 'styles/FlexContainer';
import { Checkbox } from 'modules/ui';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React, { CSSProperties, ChangeEvent, memo } from 'react';
import { FiltersValuesType } from 'store/reducers/filters/types';
import { areEqual } from 'react-window';
import { SortingValueEnum } from 'components/shared/SortingPanel/types';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { DownIcon, UpIcon } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

interface RowItemProps {
  index: number;
  style: CSSProperties;
  data: {
    values: FiltersValuesType;
    onSelectedDataRemove: (value: string) => (e: ChangeEvent<HTMLInputElement>) => void;
    isChecked: (value: string) => boolean;
    selectedListSortingStatus: string | null;
    sortSelectedValueUp: (index: number) => () => void;
    sortSelectedValueDown: (index: number) => () => void;
  };
}

export const SelectedValuesRow = memo(({ index, style, data }: RowItemProps) => {
  const { values, onSelectedDataRemove, isChecked, selectedListSortingStatus, sortSelectedValueUp, sortSelectedValueDown } = data;
  const currentValue = values[index];
  const { count, value } = currentValue;

  const normalizedValue = value !== '' ? value : 'NULL';
  const key = `${value}_${count}`;

  return (
    <FlexContainer style={style} key={key} justifyContent="space-between" padding="0 4px" alignItems="center">
      <Checkbox
        value={normalizedValue}
        name={normalizedValue}
        id={normalizedValue}
        label={normalizedValue}
        onChange={onSelectedDataRemove(value)}
        checked={isChecked(value)}
        padding="8px"
      />
      <FlexContainer gap="8px" alignItems="center">
        {selectedListSortingStatus === SortingValueEnum.MANUALLY && (
          <FlexContainer gap="4px">
            <IconWrapper
              Icon={UpIcon}
              colorVar={ColorVarsEnum.Level_2}
              hoverColorVar={ColorVarsEnum.Level_2_btn}
              onClick={sortSelectedValueUp(index)}
              iconWidth="16px"
              iconHeight="16px"
            />
            <IconWrapper
              Icon={DownIcon}
              colorVar={ColorVarsEnum.Level_2}
              hoverColorVar={ColorVarsEnum.Level_2_btn}
              onClick={sortSelectedValueDown(index)}
              iconWidth="16px"
              iconHeight="16px"
            />
          </FlexContainer>
        )}
        <PrimaryTextSpan lineHeight="12px">{count}</PrimaryTextSpan>
      </FlexContainer>
    </FlexContainer>
  );
}, areEqual);

SelectedValuesRow.displayName = 'SelectedValuesRow';
