import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { defaultScrollbarMixin } from 'constants/styles';

export const TableContainer = styled.div`
  display: inline-grid;
  table-layout: fixed;
  width: 100%;
  height: calc(100% - 20px);
`;

export const TableCell = styled.div`
  display: table-cell;
  overflow: auto;
  ${defaultScrollbarMixin};
  width: 100%;
  position: relative;
`;

export const Table = styled.table`
  border: none;
  border-collapse: collapse;

  td,
  th {
    border: none;
    text-align: left;
    height: 32px !important;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    width: max-content;
    text-overflow: ellipsis;
  }

  th {
    color: ${`var(${ColorVarsEnum.Level_1})`};
    padding: 0 10px;
  }

  tr {
    border-bottom: 1px solid ${`var(${ColorVarsEnum.Level_4})`};
  }

  td {
    padding: 0 10px;
  }
`;

export const TableTH = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 0;
`;

export const TableTD = styled.td`
  transition: all 150ms;
  color: var(${ColorVarsEnum.Level_2});
`;
