import { useSelector } from 'react-redux';
import { getUserRole } from 'store/reducers/auth';
import { ROLES_ENUM } from 'enums/RolesEnum';
import { FC } from 'react';
import { ReactChildrenType } from 'types/global';

interface MainRolesWrapperType {
  children: ReactChildrenType;
  accessList: Array<ROLES_ENUM>;
}

export const RolesWrapper: FC<MainRolesWrapperType> = ({ children, accessList }) => {
  const userRole = useSelector(getUserRole);

  if (userRole && accessList.includes(userRole)) return <>{children}</>;

  return null;
};

type RoleWrapperType = Pick<MainRolesWrapperType, 'children'>;

export const AdminWrapper: FC<RoleWrapperType> = ({ children }) => (
  <RolesWrapper accessList={[ROLES_ENUM.admin]}>{children}</RolesWrapper>
);

export const NotAdminWrapper: FC<RoleWrapperType> = ({ children }) => (
  <RolesWrapper accessList={[ROLES_ENUM.developer, ROLES_ENUM.analyst, ROLES_ENUM.viewer]}>{children}</RolesWrapper>
);

export const ViewerWrapper: FC<RoleWrapperType> = ({ children }) => (
  <RolesWrapper accessList={[ROLES_ENUM.viewer]}>{children}</RolesWrapper>
);

export const NotViewerWrapper: FC<RoleWrapperType> = ({ children }) => (
  <RolesWrapper accessList={[ROLES_ENUM.admin, ROLES_ENUM.developer, ROLES_ENUM.analyst]}>{children}</RolesWrapper>
);
