import { ListUIGroupItemInterface } from 'modules/ui/lists/MapList/list/types';
import { TableIcon2 } from 'assets/icons/withContainer';
import { TextBlock } from 'assets/icons/forDelete';
import { FilterDataIcon } from 'assets/icons/filterType';
import { NumberIcon } from 'assets/icons/editor';
import { ListInterface } from 'modules/settingsContainer/List/types';

const iconMapper = {
  default: TextBlock,
  String: TextBlock,
  UUID: TextBlock,
  Int: NumberIcon,
  Decimal: NumberIcon,
  Float: NumberIcon,
  Date: FilterDataIcon,
};

const iconKeys = Object.keys(iconMapper) as (keyof typeof iconMapper)[];

const getIconByType = (type: string) => {
  const iconKey = iconKeys.find((key) => type?.indexOf(key) !== -1) || 'default';

  return iconMapper[iconKey];
};

export const getListOptions: (modelMetaData: ListInterface[]) => ListUIGroupItemInterface[] = (modelMetaData) =>
  modelMetaData.map(({ title, id, type, items }) => ({
    itemId: id,
    title: title,
    id: title,
    Icon: TableIcon2,
    type,
    items: items ? items?.map((item) => ({ id: item?.title, Icon: getIconByType(item?.type), title: item?.title })) : [],
  }));
