import { HeaderLayout } from 'components/layouts/HeaderLayout';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { Desktop, Home } from 'assets/icons/forDelete';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { HeaderMenu } from 'modules/ui/menu/HeaderMenu';
import { goToBoardUrl, goToProjectUrl, LoadRoute, ProjectIdParam, RoutesURL } from 'constants/Routes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getLastPath } from 'utils/utils';
import { useSelector } from 'react-redux';
import { getFirstPageId } from 'store/reducers/projectPages/getters';
import { HeaderItemInterface } from 'modules/ui/menu/HeaderMenu/types';

type LoadHeaderProps = {
  menuItems: HeaderItemInterface<LoadRoute>[];
};

export const LoadHeader = ({ menuItems }: LoadHeaderProps) => {
  const navigate = useNavigate();

  const { projectId } = useParams<ProjectIdParam>();
  const { pathname } = useLocation();
  const firstPageId = useSelector(getFirstPageId);

  const activeKey = Object.entries(RoutesURL).reduce<string>(
    (result, [key, value]) => (value === getLastPath(pathname) ? key : result),
    '',
  ) as LoadRoute;

  const onMenuClick = (key: LoadRoute) => navigate(goToProjectUrl(RoutesURL[key], projectId));

  return (
    <HeaderLayout
      LeftSide={
        <IconWrapper
          hoverColorVar={ColorVarsEnum.Level_2}
          colorVar={ColorVarsEnum.Level_3}
          iconHeight="24px"
          iconWidth="24px"
          Icon={Home}
          onClick={() => navigate(RoutesURL.projectsList)}
        />
      }
      MiddleSide={<HeaderMenu items={menuItems} activeItemKey={activeKey} onItemClick={onMenuClick} />}
      RightSide={
        <IconWrapper
          hoverColorVar={ColorVarsEnum.Level_2}
          colorVar={ColorVarsEnum.Level_3}
          iconHeight="24px"
          iconWidth="24px"
          Icon={Desktop}
          onClick={() => navigate(goToBoardUrl(projectId, firstPageId || ''))}
        />
      }
    />
  );
};
