import React, { useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button } from 'modules/ui/index';
import { Combobox } from 'modules/ui/Combobox';
import { FlowType } from 'components/projectsManager/Modals/MoveProjectModal/types';
import { NoopType, NoopValueType } from 'types/global';

interface MoveProjectModalUIProps {
  onMoveProjectModal: NoopValueType<FlowType>;
  flowsItems: FlowType[];
  onClose: NoopType;
  titleSelect?: string;
}

export const MoveProjectModalUI = ({
  onClose,
  flowsItems,
  onMoveProjectModal,
  titleSelect = 'В поток',
}: MoveProjectModalUIProps) => {
  const [selectFlows, setSelectFlows] = useState<FlowType>(flowsItems[0]);

  return (
    <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" padding="12px" height="100%">
      <FlexContainer width="100%" flexDirection="column" height="100%" gap="32px">
        <Combobox
          title={titleSelect}
          name="flows"
          placeholder="Выберите поток"
          options={flowsItems}
          tooltipDisableHoverListener
          width="100%"
          value={selectFlows}
          onChange={(value) => setSelectFlows(value)}
        />

        <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
          <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={false} onClick={onClose} />
          <Button
            text="Переместить"
            iconSize="normal"
            needBackground={true}
            heightSize="normal"
            onClick={() => onMoveProjectModal({ value: selectFlows?.value, name: selectFlows?.name })}
          />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
