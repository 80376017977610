import React from 'react';
import { Button, Select } from 'modules/ui';
import { useAppDispatch } from 'store';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import {
  createFlowAccessAction,
  loadFlowAccessAction,
  updateFlowGroupAccessAction,
  updateFlowsAccessAction,
  updateFlowUserAccessAction,
} from 'store/reducers/adminFlows/actions';
import { getActiveFlow } from 'store/reducers/adminFlows/getters';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { MultiSelect, MultiSelectType } from 'modules/ui/MultiSelect';
import { Form } from './styles';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { accessItems, defaultSelectedAccess } from 'components/admin/groups/constants';
import { ModeForm } from 'types/store';
import { FlowAccessFormType } from 'components/admin/flows/types';
import { FlowAccessListInterface } from 'store/reducers/adminFlows/types';
import { ModalComponentType } from 'store/reducers/modals/types';

interface FlowAccessModalProps {
  mode: ModeForm;
  flowAccess?: FlowAccessListInterface;
  multiSelectData?: MultiSelectType<FastBoard.API.ApiAdminUserGroupListItemResponseDTO>[];
}

export const FlowAccessModal: ModalComponentType<FlowAccessModalProps> = ({ onClose, flowAccess, mode, multiSelectData }) => {
  const dispatch = useAppDispatch();

  const isAdd = mode === 'add';
  const activeFlow = useSelector(getActiveFlow);
  const activeFlowId = activeFlow?.id;

  const flowAccessId = flowAccess?.id;
  const flowAccessEntity = flowAccess?.entity;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitting },
  } = useForm<FlowAccessFormType>({
    defaultValues: {
      type: flowAccess?.type || defaultSelectedAccess,
      groups: [],
      users: [],
    },
    mode: 'onSubmit',
  });

  const handleChangeUserGroups = (userGroups: MultiSelectType<FastBoard.API.ApiAdminUserGroupListItemResponseDTO>[]) => {
    const groups: string[] = [];
    const users: string[] = [];

    userGroups.forEach((userGroup) => {
      const dataEntity = userGroup?.data?.entity;
      const dataId = userGroup?.data?.id;

      if (dataId) {
        dataEntity === 'group' && groups.push(dataId);
        dataEntity === 'user' && users.push(dataId);
      }
    });

    setValue('groups', groups);
    setValue('users', users);
  };

  const onSubmit: SubmitHandler<FlowAccessFormType> = async ({ groups, users, type }) => {
    try {
      if (!isDirty || !activeFlowId || isSubmitting) {
        return null;
      }

      const params = {
        flowId: activeFlowId,
        type,
      };

      if (isAdd) {
        const response = await dispatch(createFlowAccessAction({ users, groups, ...params }));

        if (response) {
          dispatch(loadFlowAccessAction({ flowId: activeFlowId }));
        }
      }

      if (!isAdd && flowAccess && flowAccessId) {
        let response;
        if (flowAccessEntity === 'user') {
          response = await dispatch(updateFlowUserAccessAction({ userId: flowAccessId, ...params }));
        }

        if (flowAccessEntity === 'group') {
          response = await dispatch(updateFlowGroupAccessAction({ groupId: flowAccessId, ...params }));
        }

        if (response) {
          dispatch(updateFlowsAccessAction({ access: { ...flowAccess, type } }));
        }
      }

      onClose();
    } catch (e) {
      return;
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={isSubmitting} />

      <Controller
        render={({ field }) => <Select title="Права" options={accessItems} width="100%" {...field} value={field.value} />}
        name="type"
        control={control}
      />

      {isAdd && multiSelectData && (
        <MultiSelect
          label="Пользователю/группе"
          noOptionsText="Группы/пользователи не найдены"
          options={multiSelectData}
          onChange={handleChangeUserGroups}
        />
      )}

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Добавить" iconSize="normal" heightSize="normal" type="submit" />
      </FlexContainer>
    </Form>
  );
};
