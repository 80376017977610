import { statusData } from 'components/admin/wrappers/ActiveDirectoriesAndTasksWrapper/constants';
import { formatCron } from 'components/TaskRadio';
import { defaultDateTimeFormat } from 'constants/dates';
import { Button } from 'modules/ui';
import { DetailsItem } from 'modules/ui/DetailsItem';
import React, { FC } from 'react';
import { TaskInterface } from 'store/reducers/adminTasks/types';
import { FlexContainer } from 'styles/FlexContainer';
import { NoopType } from 'types/global';
import { formatDateString } from 'utils/dates';

type DetailsUiProps = {
  data?: TaskInterface | null;
  onRunProjectScript?: NoopType;
};

export const Details: FC<DetailsUiProps> = ({ data, onRunProjectScript }) => {
  const onChangeStartTask = () => {
    if (data && onRunProjectScript) {
      onRunProjectScript();
    }
  };

  return (
    <>
      <FlexContainer padding="16px 0 0 16px">
        <Button text="Запустить задание" iconSize="normal" heightSize="normal" onClick={onChangeStartTask} />
      </FlexContainer>
      <FlexContainer padding="16px" flexDirection="column" gap="16px">
        <DetailsItem title="Проект" description={data?.name} />
        <DetailsItem title="ID проекта" description={data?.id} />
        <DetailsItem
          title="Дата начала"
          description={formatDateString({ dateString: data?.startedTask, format: defaultDateTimeFormat })}
        />
        <DetailsItem
          title="Дата завершения"
          description={formatDateString({ dateString: data?.finishedTask, format: defaultDateTimeFormat })}
        />
        <DetailsItem title="Продолжительность" description={data?.duration} />
        <DetailsItem title="График запуска" description={data?.cron ? formatCron(data.cron) : undefined} />
        <DetailsItem
          title="Статус"
          description={data?.status ? statusData[data.status]?.name : '-'}
          color={data?.status ? statusData[data.status]?.color : '-'}
        />
        <DetailsItem title="Сообщение" description={data?.message} />
      </FlexContainer>
    </>
  );
};
