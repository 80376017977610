import { useAppDispatch } from 'store';
import { getActiveUser } from 'store/reducers/adminUsers/getters';
import { createUserGroupAccessAction, loadUserGroupsAction } from 'store/reducers/adminUsers/actions';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { Button, TextField } from 'modules/ui';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { Form } from './styles';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { ModalComponentType } from 'store/reducers/modals/types';
import { AddUserGroupAccessFormType, SelectItemInterface } from 'components/admin/usersPage/elemets/Groups/types';
import { MultiSelect, MultiSelectType } from 'modules/ui/MultiSelect';
import React from 'react';

interface UserGroupAccessModalProps {
  selectData: MultiSelectType<SelectItemInterface>[];
}

export const UserGroupAccessModal: ModalComponentType<UserGroupAccessModalProps> = ({ onClose, selectData }) => {
  const dispatch = useAppDispatch();

  const activeUser = useSelector(getActiveUser);
  const activeUserId = activeUser?.id || '';

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<AddUserGroupAccessFormType>({
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<AddUserGroupAccessFormType> = async ({ groups }) => {
    try {
      if (!isDirty || !activeUserId || isSubmitting) {
        return null;
      }

      const response = await dispatch(createUserGroupAccessAction({ userId: activeUserId, groupsId: groups }));

      if (response) {
        dispatch(loadUserGroupsAction({ userId: activeUserId }));
      }

      onClose();
    } catch (e) {
      return;
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={isSubmitting} />

      {activeUser && (
        <TextField needBackground={false} name="login" label="Пользователь" value={activeUser.login} disabled width="100%" />
      )}

      <Controller
        name="groups"
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange } }) => (
          <MultiSelect
            label="В группу"
            noOptionsText="Группы не найдены"
            onChange={(item) => onChange(item.map((el) => el.value))}
            options={selectData}
          />
        )}
      />

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Добавить" iconSize="normal" heightSize="normal" type="submit" />
      </FlexContainer>
    </Form>
  );
};
