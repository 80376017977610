import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button } from 'modules/ui';

import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { NoopType } from 'types/global';
import { UpLeftIcon } from 'assets/icons/withContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';

interface HeaderFoldersProps {
  path: string;
  onBack: NoopType;
  disabledOnBack: boolean;
}
export const HeaderFolders = ({ path, disabledOnBack = false, onBack }: HeaderFoldersProps) => {
  return (
    <>
      <FlexContainer padding="0 0 16px 0" borderBottom={`1px solid var(${ColorVarsEnum.Level_5})`}>
        <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_2})`} fontSize="14px" lineHeight="14px">
          <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_1})`} fontSize="14px" lineHeight="14px">
            Путь:{' '}
          </PrimaryTextSpan>
          {path}
        </PrimaryTextSpan>
      </FlexContainer>
      <FlexContainer padding="5px" borderBottom={`1px solid var(${ColorVarsEnum.Level_5})`}>
        <Button disabled={disabledOnBack} leftIcon={<UpLeftIcon />} heightSize="small" needBackground={false} onClick={onBack} />
      </FlexContainer>
    </>
  );
};
