import { DrillDownIcon } from 'assets/icons/editor';
import { SettingsRadioProps } from 'modules/settingsContainer/SettingsRadio';
import { RadioButtonWrapper, RadioItem, RadioItemProps } from 'modules/ui/RadioButtonSelector/styles';
import { OverflowValueEnum } from 'store/reducers/visualisations/types';

interface RadioButtonSelectorProps<ValueType extends string | number>
  extends SettingsRadioProps<ValueType>,
    Partial<Pick<RadioItemProps, 'size'>> {
  overflowStyles?: OverflowValueEnum | string;
  position?: string;
  gapStyles?: string;
}

export const RadioButtonSelector = <ValueType extends string | number>({
  activeValue,
  options,
  onChange,
  size = 'normal',
  isDrillDown = false,
  overflowStyles,
  position,
  gapStyles,
}: RadioButtonSelectorProps<ValueType>) => {
  return (
    <RadioButtonWrapper gap={gapStyles} position={position} overflowStyles={overflowStyles} isDrillDown={isDrillDown}>
      {options.map((option, index) => {
        const { value, label, disabled } = option;

        return (
          <>
            <RadioItem
              size={size}
              disabled={disabled}
              selected={activeValue.value === value}
              onClick={() => !disabled && onChange(option)}
              style={{ maxHeight: '35px', width: 'fit-content' }}
              key={value}
            >
              {label}
            </RadioItem>
            {isDrillDown && index < options.length - 1 && <DrillDownIcon />}
          </>
        );
      })}
    </RadioButtonWrapper>
  );
};
