import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSliceFn } from 'constants/store';
import { initialAdminUsersStoreState } from 'store/reducers/adminUsers/constants';
import {
  loadDraftProjectsAction,
  loadDraftsOfAllUsersAction,
  loadUserDraftProjectHistoryVersionAction,
  loadUserFlowsAction,
  loadUsersAction,
  loadUserGroupsAction,
  loadUserSourcesAction,
} from './actions';
import { loadFlowDraftInfoAction } from 'store/reducers/projectManager/actions';
import {
  UserFlowsListInterface,
  UsersGroupListInterface,
  UsersListInterface,
  UserSourcesListInterface,
} from 'store/reducers/adminUsers/types';
import { IdInterface } from 'types/store';
import { FlowListInterface, SortProject } from 'store/reducers/projectManager/types';

export const adminSlice = createSlice({
  name: 'adminUsers',
  initialState: initialAdminUsersStoreState,
  reducers: {
    addUser: (state, { payload: data }: PayloadAction<UsersListInterface>) => {
      state.users.usersList = [data, ...state.users.usersList];
    },
    changeActiveUser: (state, { payload }: PayloadAction<UsersListInterface>) => {
      state.activeUser = payload;
    },
    updateUsers: (state, { payload: users }: PayloadAction<UsersListInterface[]>) => {
      state.users.usersList = users;
    },
    deleteByIdUser: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.users.usersList = state.users.usersList.filter((page) => page.id !== id);
    },
    updateUserDraftProjects: (state, { payload: draftProjects }: PayloadAction<FlowListInterface[]>) => {
      state.usersDraftProjects.flowProjectsList = draftProjects;
    },
    setActiveUserDraftProjectId: (state, { payload: activeUserDraftProjectId }: PayloadAction<string | null>) => {
      state.activeUserDraftProjectId = activeUserDraftProjectId;
    },
    deleteByIdUserDraftProject: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.usersDraftProjects.flowProjectsList = state.usersDraftProjects.flowProjectsList.filter(
        (project) => project.id !== id,
      );
    },
    changeShortUserDraftProject: (state, { payload }: PayloadAction<SortProject>) => {
      state.shortUserDraftProject = payload;
    },

    addUserSource: (state, { payload: data }: PayloadAction<UserSourcesListInterface>) => {
      state.userSources.userSourcesList = [data, ...state.userSources.userSourcesList];
    },
    updateUserSources: (state, { payload: sources }: PayloadAction<UserSourcesListInterface[]>) => {
      state.userSources.userSourcesList = sources;
    },
    deleteByIdUserSource: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.userSources.userSourcesList = state.userSources.userSourcesList.filter((page) => page.id !== id);
    },

    deleteByIdUserGroupAccess: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.userGroups.groupsList = state.userGroups.groupsList.filter((page) => page.id !== id);
    },
    updateUserGroupsAccess: (state, { payload: groups }: PayloadAction<UsersGroupListInterface[]>) => {
      state.userGroups.groupsList = groups;
    },

    addUserFlow: (state, { payload: data }: PayloadAction<UserFlowsListInterface>) => {
      state.userFlows.userFlowsList = [data, ...state.userFlows.userFlowsList];
    },
    updateUserFlows: (state, { payload: flows }: PayloadAction<UserFlowsListInterface[]>) => {
      state.userFlows.userFlowsList = flows;
    },
    deleteByIdUserFlow: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.userFlows.userFlowsList = state.userFlows.userFlowsList.filter((page) => page.id !== id);
    },
    setSlice: setSliceFn,
  },
  extraReducers: (builder) => {
    builder.addCase(loadUsersAction.pending, (state) => {
      state.users = { ...state.users, loading: true };
      state.users.usersList = [];
    });
    builder.addCase(loadUsersAction.fulfilled, (state, { payload }) => {
      state.users = { ...state.users, loading: false };
      state.users.usersList = payload;
    });
    builder.addCase(loadUsersAction.rejected, (state) => {
      state.users = { ...state.users, loading: false };
      state.users.usersList = [];
    });

    builder.addCase(loadDraftProjectsAction.pending, (state) => {
      state.usersDraftProjects = { ...state.usersDraftProjects, loading: true };
      state.usersDraftProjects.flowProjectsList = [];
    });
    builder.addCase(loadDraftProjectsAction.fulfilled, (state, { payload }) => {
      state.usersDraftProjects = { ...state.usersDraftProjects, loading: false };
      state.usersDraftProjects.flowProjectsList = payload;
    });
    builder.addCase(loadDraftProjectsAction.rejected, (state) => {
      state.usersDraftProjects = { ...state.usersDraftProjects, loading: false };
      state.usersDraftProjects.flowProjectsList = [];
    });

    builder.addCase(loadUserDraftProjectHistoryVersionAction.pending, (state) => {
      state.usersDraftProjectHistoryVersion.loading = true;
      state.usersDraftProjectHistoryVersion.flowProjectHistoryVersionList = [];
    });
    builder.addCase(loadUserDraftProjectHistoryVersionAction.fulfilled, (state, { payload }) => {
      state.usersDraftProjectHistoryVersion.loading = false;
      state.usersDraftProjectHistoryVersion.flowProjectHistoryVersionList = payload;
    });
    builder.addCase(loadUserDraftProjectHistoryVersionAction.rejected, (state) => {
      state.usersDraftProjectHistoryVersion.loading = false;
      state.usersDraftProjectHistoryVersion.flowProjectHistoryVersionList = [];
    });

    builder.addCase(loadDraftsOfAllUsersAction.pending, (state) => {
      state.draftsOfAllUsers.loading = true;
      state.draftsOfAllUsers.draftsOfAllUsersList = [];
    });
    builder.addCase(loadDraftsOfAllUsersAction.fulfilled, (state, { payload }) => {
      state.draftsOfAllUsers.loading = false;
      state.draftsOfAllUsers.draftsOfAllUsersList = payload;
    });
    builder.addCase(loadDraftsOfAllUsersAction.rejected, (state) => {
      state.draftsOfAllUsers.loading = false;
      state.draftsOfAllUsers.draftsOfAllUsersList = [];
    });

    builder.addCase(loadFlowDraftInfoAction.pending, (state) => {
      state.adminDraftInfo = null;
    });
    builder.addCase(loadFlowDraftInfoAction.fulfilled, (state, { payload }) => {
      state.adminDraftInfo = payload;
    });
    builder.addCase(loadFlowDraftInfoAction.rejected, (state) => {
      state.adminDraftInfo = null;
    });

    builder.addCase(loadUserSourcesAction.pending, (state) => {
      state.userSources = { ...state.userSources, loading: true };
      state.userSources.userSourcesList = [];
    });
    builder.addCase(loadUserSourcesAction.fulfilled, (state, { payload }) => {
      state.userSources = { ...state.userSources, loading: false };
      state.userSources.userSourcesList = payload;
    });
    builder.addCase(loadUserSourcesAction.rejected, (state) => {
      state.userSources = { ...state.userSources, loading: false };
      state.userSources.userSourcesList = [];
    });

    builder.addCase(loadUserGroupsAction.pending, (state) => {
      state.userGroups = { ...state.userGroups, loading: true };
      state.userGroups.groupsList = [];
    });
    builder.addCase(loadUserGroupsAction.fulfilled, (state, { payload }) => {
      state.userGroups = { ...state.userGroups, loading: false };
      state.userGroups.groupsList = payload;
    });
    builder.addCase(loadUserGroupsAction.rejected, (state) => {
      state.userGroups = { ...state.userGroups, loading: false };
      state.userGroups.groupsList = [];
    });

    builder.addCase(loadUserFlowsAction.pending, (state) => {
      state.userFlows = { ...state.userFlows, loading: true };
      state.userFlows.userFlowsList = [];
    });
    builder.addCase(loadUserFlowsAction.fulfilled, (state, { payload }) => {
      state.userFlows = { ...state.userFlows, loading: false };
      state.userFlows.userFlowsList = payload;
    });
    builder.addCase(loadUserFlowsAction.rejected, (state) => {
      state.userFlows = { ...state.userFlows, loading: false };
      state.userFlows.userFlowsList = [];
    });
  },
});

export const {
  changeActiveUser,
  addUser,
  setActiveUserDraftProjectId,
  deleteByIdUser,
  updateUsers,
  updateUserDraftProjects,
  deleteByIdUserDraftProject,
  changeShortUserDraftProject,
  deleteByIdUserSource,
  updateUserSources,
  addUserSource,
  deleteByIdUserGroupAccess,
  updateUserGroupsAccess,
  addUserFlow,
  deleteByIdUserFlow,
  updateUserFlows,
  setSlice,
} = adminSlice.actions;

export default adminSlice.reducer;
