import { IdInterface, LoadingInterface, RoleType } from 'types/store';

export interface LicensesListInterface extends IdInterface {
  login: string;
}

export interface AdminLicenseInterface {
  licenseNum: string;
  companyName: string;
  type: 'online' | 'offline';
  expires: string;
}

export interface AdminLicenseUsersInterface extends IdInterface {
  login: string;
  role: RoleType;
  lastVisit?: string;
}

export interface AdminLicenseMetaInterface {
  role: 'AD' | 'AV';
  usedCount: number;
  count: number;
}

export interface AdminLicenseDataInterface extends LoadingInterface {
  adminLicenseData: {
    adminInfo: AdminLicenseInterface;
    meta: AdminLicenseMetaInterface[];
    users: AdminLicenseUsersInterface[];
  } | null;
}

export interface AdminLicensesSliceStateInterface {
  adminLicense: AdminLicenseDataInterface;
}

/* Action Types */
export enum AdminLicensesActionsTypes {
  LOAD_ADMIN_LICENSE = 'LOAD_ADMIN_LICENSE',
  UPLOAD_LICENSE_FILE = 'UPLOAD_LICENSE_FILE',
  DELETE_LICENSE_USER = 'DELETE_LICENSE_USER',
  DELETE_LICENSE_BY_ID_USER = 'DELETE_LICENSE_BY_ID_USER',
  CREATE_LICENSE_USER = 'CREATE_LICENSE_USER',
  UPDATE_LICENSE_USER = 'UPDATE_LICENSE_USER',
  ADD_LICENSE_BY_ID_USER = 'ADD_LICENSE_BY_ID_USER',
  UPDATE_ROLE_USER = 'UPDATE_ROLE_USER',
  LOAD_USER_NO_LICENSE = 'LOAD_USER_NO_LICENSE',
}

/* Payload Interfaces */

export interface PayloadUsersNoLicense {
  licenseNum: string;
}

export type PayloadUpdateLicenseUser = {
  oldUserId: string;
  newUserId: string;
};

export type PayloadUpdateRoleUser = {
  userId: string;
  role: RoleType;
};
