import { TState } from 'store/index';
import { ISelectField } from 'interface';
import { defaultPageSizes } from 'store/reducers/projectPages/constants';
import { getMapObject, isArrayEquals } from 'utils/utils';
import { createSelector } from 'reselect';
import { getState } from 'store/utils';

export const getPagesStore = createSelector(getState, (state) => state.projectPages);

export const getPages = createSelector(getPagesStore, (state) => state.projectPages);

export const getRLSUserGroupAnfGroup = createSelector(getPagesStore, (state) => state.rlsUserGroupAnfGroup);

export const getServerStateOfProjectPages = createSelector(getPagesStore, (state) => state.serverStateOfProjectPages);
export const getHasChangesOfPages = createSelector([getServerStateOfProjectPages, getPages], (serverState, currentState) =>
  serverState === null ? false : !isArrayEquals(serverState || [], currentState),
);
export const getPagesLoading = createSelector(getPagesStore, (state) => state.pagesLoading);

export const getMapPages = createSelector(getPages, (pages) => getMapObject(pages, 'id'));

export const getPageIds = createSelector(getPages, (pages) => pages.map(({ id }) => id));

export const getFirstPageId = createSelector(getPagesStore, (state) => state.firstPageId);

export const getActivePageId = createSelector(getPagesStore, (state) => state.activePageId);

export const getActivePage = createSelector([getActivePageId, getPages], (activePageId, pages) =>
  pages.find(({ id }) => id === activePageId),
);

export const getActivePageSize = createSelector(
  getActivePage,
  (activePage) => activePage?.boardSettings?.sizes || defaultPageSizes,
);

export const getPagesAtSelectItems: (state: TState) => ISelectField[] = createSelector(getPages, (pages) =>
  pages.map(({ name, id }) => ({ name, value: id })),
);
