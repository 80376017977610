import ReactECharts, { EChartsReactProps } from 'echarts-for-react';
import { WrapperECharts } from 'modules/visualisations/components/WrappedReactECharts/styles';
import { forwardRef } from 'react';

type WrapperReactEChartsProps = EChartsReactProps;

// eslint-disable-next-line react/display-name
export const WrappedReactECharts = forwardRef<ReactECharts, WrapperReactEChartsProps>((eChartsReactProps, ref) => {
  return (
    <WrapperECharts>
      <ReactECharts ref={ref} {...eChartsReactProps} />
    </WrapperECharts>
  );
});
