import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';

export const overflowPositionOptions: SettingsRadioItem<string>[] = [
  {
    value: 'sequentially',
    label: 'Последовательно',
  },
  {
    value: 'horizontally',
    label: 'Горизонтально',
  },
  {
    value: 'vertically',
    label: 'Вертикально',
  },
];
