import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FlexContainer } from 'styles/FlexContainer';
import { safeAreaInsetBottom } from 'constants/styles';

export const ListBlockWrapper = styled(FlexContainer)`
  display: flex;
  flex-direction: row;
  align-content: start;
  width: calc(100% - 25px);
  gap: 24px;
  margin: 0 24px 24px;
  flex-wrap: wrap;
  overflow: auto;
  max-height: calc(-245px + 100vh);

  @media (max-width: 1024px) {
    max-height: calc(-150px + 100vh);
    margin: 0;
    padding: 0 12px;
    width: 100%;
    margin-top: 24px;
  }

  ${safeAreaInsetBottom}
`;

export const GroupWrapper = styled.div`
  border-top: 1px solid var(${ColorVarsEnum.Level_5});
`;

export const ItemsWrapper = styled.div`
  display: block;
  margin-left: 8px;
`;
