import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import {
  CreateTablesScriptActionPayload,
  FieldsPreviewTablesPayload,
  InformationTableSourcePayload,
  UploadSQLScriptPayload,
} from 'store/reducers/loadingScript/types';

export const loadSQLScript = (projectId: string): Promise<AxiosResponse<FastBoard.API.ImportScriptResponseDTO>> =>
  axiosClient.get(`/api/v1/script/${projectId}`);

export const runSQLScript = (projectId: string): Promise<AxiosResponse<string>> =>
  axiosClient.put(`/api/v1/script/${projectId}/run`);

export const loadFieldsPreviewTables = ({
  sourceId,
  tableName,
  schemaName,
}: FieldsPreviewTablesPayload): Promise<AxiosResponse<FastBoard.API.SourceTablePreviewResponseDTO>> => {
  const config: AxiosRequestConfig = {
    params: schemaName ? { schemaName } : {},
  };

  return axiosClient.get(`/api/v2/source/${sourceId}/table/${tableName}/preview`, config);
};

export const loadTables = (sourceId: string): Promise<AxiosResponse<FastBoard.API.SourceTablesResponseDTO>> =>
  axiosClient.get(`/api/v2/source/${sourceId}/tables`);

export const createTablesScript = ({ sourceId, projectId, tables }: CreateTablesScriptActionPayload) =>
  axiosClient.post<string, AxiosResponse<string>, FastBoard.API.ScriptAddTablesDTO>(`/api/v1/script/${projectId}/table`, {
    sourceId,
    tables,
  });

export const loadInformationTableSource = ({
  sourceId,
  tableName,
  schemaName,
}: InformationTableSourcePayload): Promise<AxiosResponse<FastBoard.API.SourceTableMetaResponseDTO>> => {
  const config: AxiosRequestConfig = {
    params: schemaName ? { schemaName } : {},
  };

  return axiosClient.get(`/api/v2/source/${sourceId}/table/${tableName}/meta`, config);
};

export const uploadSQLScript = ({ script, projectId }: UploadSQLScriptPayload) =>
  axiosClient.put<
    FastBoard.API.ImportScriptSaveResponseDTO,
    AxiosResponse<FastBoard.API.ImportScriptSaveResponseDTO>,
    FastBoard.API.ImportScriptSaveDTO
  >(`/api/v1/script/${projectId}`, { script: script });

export const loadSQLScriptStatus = (loadingId: string): Promise<AxiosResponse<FastBoard.API.ProjectsLoadingStatusResponseDTO>> =>
  axiosClient.get(`/api/v1/projects/loading_status`, { params: { loading_id: loadingId } });

export const loadCopyURLScript = (projectId: string): Promise<AxiosResponse<string>> =>
  axiosClient.get(`/api/v1/l/url/${projectId}`);
