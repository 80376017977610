import { useCallback, useEffect, useRef, useState } from 'react';
import { useLongPolling } from 'utils/hooks/useLongPolling';

interface Task {
  sourceId: string;
  taskId: string;
}

export interface TaskStatus {
  status: 'pending' | 'active' | 'success' | 'aborted';
  started: string;
  progress: number;
  loaded: number;
  message?: string | null;
}

type FetchStatusFunction = (taskId: string) => Promise<TaskStatus>;

type TaskStatusesType = {
  tasks: Task[];
  fetchStatus: FetchStatusFunction;
  delay?: number;
};

const TASK_STATUSES = ['success', 'aborted'];

export const useTaskStatuses = ({ tasks, fetchStatus, delay = 10000 }: TaskStatusesType) => {
  const [taskStatuses, setTaskStatuses] = useState<Record<string, TaskStatus & { loading: boolean }>>({});
  const allTimeoutRef = useRef<NodeJS.Timeout[]>([]);

  const updateTaskStatus = useCallback(
    (task: Task) => {
      fetchStatus(task.taskId)
        .then((status) => {
          if (status) {
            setTaskStatuses((prev) => ({ ...prev, [task.sourceId]: { ...status, loading: true } }));
            if (!TASK_STATUSES.includes(status.status)) {
              const timer = setTimeout(() => {
                return updateTaskStatus(task);
              }, delay);

              allTimeoutRef.current.push(timer);
            }
            if (TASK_STATUSES.includes(status.status)) {
              setTaskStatuses((prev) => {
                const updated = { ...prev };
                delete updated[task.sourceId];
                return updated;
              });
            }
          }
        })
        .catch((error) => {
          console.error('Ошибка при получении статуса задачи: ', error);
        });
    },
    [fetchStatus, delay],
  );

  const fetchAllTaskStatuses = useCallback(() => {
    tasks.forEach((task) => {
      if (!taskStatuses[task.sourceId]) {
        updateTaskStatus(task);
      }
    });
  }, [tasks, updateTaskStatus, taskStatuses]);

  useLongPolling({ intervalCallback: fetchAllTaskStatuses, delay });

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      allTimeoutRef.current.forEach(clearTimeout);
    };
  }, []);

  return taskStatuses;
};
