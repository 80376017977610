import { ByConnectionIcon, ByFileIcon, ByRestAPIIcon } from 'assets/icons/source';
import { SourcesTypeListInterface } from 'components/console/elements/sideBar/WizardSourceModalType/steps/SourcesTypeStep/types';

export const sourcesTypeList: SourcesTypeListInterface[] = [
  {
    type: 'file',
    name: 'Файл',
    Icon: ByFileIcon,
  },
  {
    type: 'database',
    name: 'База данных',
    Icon: ByConnectionIcon,
  },
  {
    type: 'rest',
    name: 'REST API',
    Icon: ByRestAPIIcon,
    disabled: true,
  },
];
