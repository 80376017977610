import Box from '@mui/material/Box';
import { DeleteIcon } from 'assets/icons/withContainer';
import { Select, Switcher } from 'modules/ui';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MultipleFilterActivation } from '../MultipleFilter';
import { SingleFilterActivation } from '../SingleFilter';
import { DateFilterActivation } from '../DateFilter';
import { FilterMapInterface } from 'store/reducers/filters/types';
import { ActiveObjectFilters } from 'store/reducers/visualisations/types';
import { useSqlRequest } from 'modules/visualisations/hooks/sqlRequest';
import { useSelector } from 'react-redux';
import { getFilterById } from 'store/reducers/filters/getters';

enum FILTER_TYPE {
  MULTIPLE = 'multiple',
  SINGLE = 'single',
  DATE = 'date',
}

interface FilterCardProps {
  id: string;
  data: FilterMapInterface;
  isActive: boolean;
  onDelete: () => void;
  currentFilters: ActiveObjectFilters[];
  handleChangeActivationStatus: () => void;
  selectedValues: any;
  elementId: string;
  onUpdateFilter: (elementId: string, payload: Partial<ActiveObjectFilters>) => void;
}

export const FilterCard = ({
  id,
  data,
  onDelete,
  isActive,
  currentFilters,
  handleChangeActivationStatus,
  selectedValues,
  elementId,
  onUpdateFilter,
}: FilterCardProps) => {
  const [currentFilterId, setCurrentFilterId] = useState<string>(id || '');
  const { getSqlFilter } = useSqlRequest();
  const filterSettings = useSelector(getFilterById(currentFilterId));

  const handleNameOption = useCallback(
    (key: string) => {
      const dataByKey = data[key];

      if (dataByKey) {
        const { id, nameSettings } = dataByKey;
        const filterSettingsId = filterSettings?.id;
        const filterNameSettings = filterSettings?.nameSettings;

        return filterSettingsId === id && filterSettings ? filterNameSettings?.name : nameSettings.name;
      }
    },
    [filterSettings, data],
  );

  const filterDataOptions = useMemo(
    () =>
      Object.keys(data).map((key) => ({
        value: data[key]?.id,
        name: handleNameOption(key),
        disabled: currentFilters?.some((filter) => filter.id === data[key]?.id),
      })),
    [data, currentFilters, handleNameOption],
  );

  const currentFilterData = data[currentFilterId]!;
  const type = currentFilterData?.type;

  const onSelectChange = (value: string) => {
    const filterData = data[value];

    setCurrentFilterId(value);
    onUpdateFilter(elementId, { id: filterData?.id, selectedValues: null });
  };

  return (
    <Box sx={{ padding: '12px', border: `1px solid var(${ColorVarsEnum.Level_5})`, borderRadius: '3px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <Switcher id={uuidv4()} value={isActive} onClick={handleChangeActivationStatus} />
        <Select
          placeholder="Выбрать фильтр"
          options={filterDataOptions}
          onChange={onSelectChange}
          value={currentFilterId}
          width="100%"
          name="filterSelect"
        />
        <DeleteIcon onClick={onDelete} style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
      </Box>
      {type === FILTER_TYPE.MULTIPLE && (
        <MultipleFilterActivation
          data={currentFilterData}
          onUpdateFilter={onUpdateFilter}
          elementId={elementId}
          whereQuery={getSqlFilter(id)}
          selectedValues={selectedValues}
        />
      )}
      {type === FILTER_TYPE.SINGLE && (
        <SingleFilterActivation
          data={currentFilterData}
          onUpdateFilter={onUpdateFilter}
          elementId={elementId}
          whereQuery={getSqlFilter(id)}
          selectedValues={selectedValues}
        />
      )}
      {type === FILTER_TYPE.DATE && (
        <DateFilterActivation
          data={currentFilterData}
          onUpdateFilter={onUpdateFilter}
          elementId={elementId}
          whereQuery={getSqlFilter(id)}
          selectedValues={selectedValues}
        />
      )}
    </Box>
  );
};
