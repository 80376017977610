import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import styled from 'styled-components';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: all 200ms;

  &:hover {
    border: 1px solid var(${ColorVarsEnum.Accent});
  }
`;
