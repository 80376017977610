import { ClearIcon } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Button } from 'modules/ui/index';
import { Menu } from 'modules/ui/menu/Menu';
import { BodyWrapper, SettingsTabsWrapper } from 'modules/ui/tabs/NavigationsTabs/styles';
import { ErrorWrapper } from 'modules/visualisations/components/ErrorWrapper';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Loader } from 'shared/ui/Loader';
import { FlexContainer } from 'styles/FlexContainer';
import { NoopType } from 'types/global';
import { Tab } from '../types';

interface NavigationsTabsProps {
  tabs: Tab[];
  width?: string;
  height?: string;
  disabledTabs?: boolean;
  typeMenu?: 'small' | 'big';
  onCloseMenu?: NoopType;
  overflowBodyY?: string;
  maxBodyHeight?: string;
  bgNavigationsTabs?: string;
  IconHeader?: ReactElement;
  isOpen?: boolean;
}

/* TODO: It will be necessary to merge or replace SettingsTabs and NavigationsTabs */

export const NavigationsTabs = ({
  tabs,
  width,
  height,
  disabledTabs = false,
  onCloseMenu,
  typeMenu = 'big',
  bgNavigationsTabs,
  overflowBodyY,
  maxBodyHeight,
  isOpen = true,
  IconHeader = <ClearIcon />,
}: NavigationsTabsProps) => {
  const defaultTab = useMemo(() => tabs[0], [tabs]);
  const [activeTabId, setActiveTabId] = useState(defaultTab.id);

  const activeTab = useMemo(() => tabs.find(({ id }) => id === activeTabId) || defaultTab, [activeTabId, defaultTab, tabs]);

  const { Component, isLoading } = activeTab;

  const ComponentRender = useCallback(() => {
    return (
      <ErrorWrapper>
        <Component />
      </ErrorWrapper>
    );
  }, [Component]);

  /* TODO: It’s not clear whether this is needed here */
  // useEffect(() => {
  //   return () => {
  //     setActiveTabId(activeTabId || defaultTab.id);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tabs]);

  const paddingContainerMenu = useMemo(() => (typeMenu === 'small' ? '0px 16px' : '0px 32px'), [typeMenu]);
  const heightMenu = useMemo(() => (typeMenu === 'small' ? '30px' : '35px'), [typeMenu]);
  const fontSizeMenu = useMemo(() => (typeMenu === 'small' ? '12px' : '14px'), [typeMenu]);

  return (
    <SettingsTabsWrapper width={width} height={height} bgNavigationsTabs={bgNavigationsTabs}>
      <FlexContainer padding={paddingContainerMenu} justifyContent="space-between" alignItems="center">
        {!disabledTabs && (
          <Menu
            height={heightMenu}
            items={tabs}
            activeItemId={activeTabId}
            onItemClick={setActiveTabId}
            disabled={disabledTabs}
            fontSize={fontSizeMenu}
          />
        )}
        {onCloseMenu && <Button leftIcon={IconHeader} needBackground={false} heightSize="small" onClick={onCloseMenu} />}
      </FlexContainer>
      <FlexContainer bottom="0" left="0" width="100%" height="1px" backgroundColor={`var(${ColorVarsEnum.Level_5})`} />

      <BodyWrapper overflowY={isOpen ? 'none' : overflowBodyY} maxHeight={maxBodyHeight} isOpen={isOpen}>
        <ComponentRender />
        {isLoading && (
          <FlexContainer zIndex="1" position="absolute" width="100%" height="100%" alignItems="center" left="0" top="0">
            <Loader size="small" title="Загрузка настроек" />
          </FlexContainer>
        )}
      </BodyWrapper>
    </SettingsTabsWrapper>
  );
};
