import { PaddingVisualisationInterface } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { OnValueChange } from 'types/global';
import { pxUnit } from 'constants/global';
import { showPaddingVisualisationTitle } from './constants';

type PaddingVisualisationProps = OnValueChange<PaddingVisualisationInterface>;

export const PaddingVisualisationSettings = ({ value: visualisationPaddings, onChange }: PaddingVisualisationProps) => {
  const {
    isShow,
    paddingsVisualisation: { topPadding, bottomPadding, leftPadding, rightPadding },
  } = visualisationPaddings;

  return (
    <MainContainerSettings
      titleText={showPaddingVisualisationTitle}
      switcherState={isShow}
      switcherChange={() => onChange({ ...visualisationPaddings, isShow: !isShow })}
    >
      <GroupContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Сверху"
            name="fontSize"
            unit={pxUnit}
            value={topPadding}
            onChange={(topPadding) =>
              onChange({
                ...visualisationPaddings,
                paddingsVisualisation: {
                  ...visualisationPaddings.paddingsVisualisation,
                  topPadding,
                },
              })
            }
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Снизу"
            name="fontSize"
            unit={pxUnit}
            value={bottomPadding}
            onChange={(bottomPadding) =>
              onChange({
                ...visualisationPaddings,
                paddingsVisualisation: {
                  ...visualisationPaddings.paddingsVisualisation,
                  bottomPadding,
                },
              })
            }
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Слева"
            name="fontSize"
            unit={pxUnit}
            value={leftPadding}
            onChange={(leftPadding) =>
              onChange({
                ...visualisationPaddings,
                paddingsVisualisation: {
                  ...visualisationPaddings.paddingsVisualisation,
                  leftPadding,
                },
              })
            }
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Справа"
            name="fontSize"
            unit={pxUnit}
            value={rightPadding}
            onChange={(rightPadding) =>
              onChange({
                ...visualisationPaddings,
                paddingsVisualisation: {
                  ...visualisationPaddings.paddingsVisualisation,
                  rightPadding,
                },
              })
            }
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
