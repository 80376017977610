import { GroupType } from 'types/store';

export const createGroup = 'createGroup';
export const editGroup = 'editGroup';

export const editGroupFlow = 'editGroupFlow';
export const addGroupFlow = 'addGroupFlow';

export const editGroupSource = 'editGroupSource';
export const addGroupSource = 'addGroupSource';

export const addGroupUsersAccess = 'addGroupUsersAccess';

export const accessItems: Array<{ name: string; value: GroupType }> = [
  { name: 'Использование', value: 'reader' },
  { name: 'Управление', value: 'owner' },
];

export const accessText = {
  owner: 'Управление',
  reader: 'Использование',
};

export const defaultSelectedAccess = accessItems[0].value;
