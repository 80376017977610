import isArray from 'lodash/isArray';
import { ElementContainerSettings, GroupContainerSettings } from 'modules/settingsContainer';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { ColorValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { ColorByCondition } from 'modules/settingsContainer/common/ColorByCondition';
import {
  addColorByValueMarginTop,
  gradientLineHeight,
  gradientLineMarginBottom,
} from 'modules/settingsContainer/common/ColorByValue/constants';
import { ConditionEditorField } from 'modules/settingsContainer/common/ConditionEditorField';
import { FixedAreaSettings } from 'modules/settingsContainer/common/FixedAreaSettings';
import { WrapperByTabsType } from 'modules/settingsContainer/common/WrapperBySettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { AddColorButton } from 'modules/ui/buttons/AddColorButton';
import { ColorByPicker } from 'modules/ui/ColorByPicker';
import { GradientBar } from 'modules/ui/colors/GradientBar';
import React, { useMemo, useRef } from 'react';
import { colorAlias } from 'store/reducers/visualisations/constants';
import { ColorAndImageByInterfaceType, ColorByInterface, ColorByItem } from 'store/reducers/visualisations/types';
import { FlexContainer } from 'styles/FlexContainer';
import { NoopValueType } from 'types/global';
import { useModalState } from 'utils/hooks/modalState';
import { conditionDefault } from './constants';

export interface ColorManagingProps {
  titleText: string;
  colorBy: ColorByInterface;
  onChange: NoopValueType<ColorByInterface>;
  onChangeProperties?: NoopValueType<boolean>;
  options: SettingsRadioItem<ColorAndImageByInterfaceType>[];
  isRealData: boolean;
  children?: React.ReactNode;
  disabledComponentByValue?: boolean;
  disabledComponentByCondition?: boolean;
  changeFillColor?: boolean;
}

export const ColorManaging = ({
  titleText,
  colorBy,
  children,
  onChange,
  options,
  isRealData,
  changeFillColor,
  onChangeProperties,
  disabledComponentByValue,
  disabledComponentByCondition,
}: ColorManagingProps) => {
  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  const { isOpen, onOpen, onClose } = useModalState();

  const usingByValue = useMemo(() => !disabledComponentByValue, [disabledComponentByValue]);
  const usingByCondition = useMemo(() => !disabledComponentByCondition, [disabledComponentByCondition]);

  const { getColorValues, defaultColor } = useColorValues();
  const colors = colorBy?.byValue?.colors?.[colorAlias] || [],
    activeType = colorBy.type,
    colorGradient = isArray(colors) ? colors.map(({ value }) => String(getColorValues(value)) || defaultColor) : [];

  const onChangeColor = (index: number) => (change: ColorValuesByThemeType | null) => {
    if (!isArray(colors)) {
      return;
    }
    const newColors: ColorByItem[] = colors.map((color, indexColor) => {
      if (index === indexColor) {
        return {
          value: change,
        };
      }
      return color;
    });

    onChange({
      ...colorBy,
      byValue: {
        ...colorBy.byValue,
        colors: {
          [colorAlias]: newColors,
        },
      },
    });
  };

  const onAddColor = () => {
    onChange({
      ...colorBy,
      byValue: {
        ...colorBy.byValue,
        colors: {
          [colorAlias]: colors.length ? [...colors, { value: null }] : [{ value: null }],
        },
      },
    });
  };

  const colorByTabs: WrapperByTabsType = {
    condition: {
      Activator: () =>
        usingByCondition ? <ConditionEditorField value={colorBy.byCondition.sqlCondition || ''} onOpenChange={onOpen} /> : null,
      Settings: () =>
        usingByCondition ? (
          <FixedAreaSettings
            onClose={onClose}
            open={isOpen}
            headerText="Изменить условие"
            width="565px"
            minWidth="200px"
            anchorEl={mainContainerRef.current}
          >
            <ColorByCondition
              metric={colorBy.byCondition}
              onChange={(byCondition) =>
                onChange({
                  ...colorBy,
                  byCondition,
                })
              }
            />
          </FixedAreaSettings>
        ) : null,
    },
    value: {
      Activator: () =>
        usingByValue ? (
          <>
            {!!colors?.length && (
              <FlexContainer marginRight="8px" flexDirection="column" overflow="auto">
                <FlexContainer marginBottom={gradientLineMarginBottom} width="100%" height={gradientLineHeight}>
                  <GradientBar colors={colorGradient} />
                </FlexContainer>
                <FlexContainer gap="12px">
                  {colors.map(({ value }, index) => (
                    <ColorByPicker
                      onDelete={() =>
                        onChange({
                          ...colorBy,
                          byValue: {
                            ...colorBy.byValue,
                            colors: {
                              [colorAlias]: colors.filter((_, indexEl) => indexEl !== index),
                            },
                          },
                        })
                      }
                      onChange={onChangeColor(index)}
                      value={value}
                      key={index}
                    />
                  ))}
                </FlexContainer>
              </FlexContainer>
            )}
            <FlexContainer margin={`${addColorByValueMarginTop} 0 0 0`}>
              <AddColorButton onAdd={onAddColor} />
            </FlexContainer>
          </>
        ) : null,
    },
    default: {
      Activator: () => <>{children}</>,
    },
  };

  const { Activator, Settings } = colorByTabs[isRealData ? activeType : conditionDefault.value];

  return (
    <div ref={mainContainerRef}>
      <GroupContainerSettings
        titleText={titleText}
        switcherState={changeFillColor}
        switcherChange={onChangeProperties && (() => onChangeProperties(!changeFillColor))}
      >
        <ElementContainerSettings>
          <SettingsRadio
            onChange={({ value }) => onChange({ ...colorBy, type: value })}
            activeValue={
              isRealData ? getActiveRadioValue(options, activeType) : getActiveRadioValue(options, conditionDefault.value)
            }
            options={isRealData ? options : [conditionDefault]}
          />
        </ElementContainerSettings>
        <FlexContainer width="100%">
          <Activator />
        </FlexContainer>
        {Settings && <Settings />}
      </GroupContainerSettings>
    </div>
  );
};
