import { useCallback, useMemo, useState } from 'react';
import { DataZoomParamsInterface, DataZoomType } from 'types/echarts';
import { StartAndEndInterface } from 'types/store';
import { getLengthOfGraphicString } from 'utils/generateConfigGraphic';
import { getMaximumStringLengthOfArrayData } from 'utils/utils';
import {
  ExtendedSettingsInterface,
  VisualisationValuesInterface,
  ZoomSettingsInterface,
} from 'store/reducers/visualisations/types';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { getSliderZoomWithGridDimensions } from 'modules/visualisations/common/constants';
import { NoopValueType } from 'types/global';
import { startAndEnd } from 'constants/global';
import { useSettingsChange } from 'utils/hooks/visualisation/useSettingsChange';

export interface DataZoomProps {
  data: VisualisationValuesInterface;
  legendSettings: ExtendedSettingsInterface;
  horizontalZoom: ZoomSettingsInterface;
  verticalZoom: ZoomSettingsInterface;
  dataZoomHorizontalStartAndEnd: StartAndEndInterface | undefined;
  dataZoomVerticalStartAndEnd: StartAndEndInterface | undefined;
  onDataZoomHorizontalStartAndEndSettingsChange: NoopValueType<StartAndEndInterface>;
  onDataZoomVerticalStartAndEndSettingsChange: NoopValueType<StartAndEndInterface>;
}

export const useDataZoom = ({
  data,
  legendSettings,
  horizontalZoom,
  verticalZoom,
  dataZoomHorizontalStartAndEnd,
  dataZoomVerticalStartAndEnd,
  onDataZoomHorizontalStartAndEndSettingsChange,
  onDataZoomVerticalStartAndEndSettingsChange,
}: DataZoomProps) => {
  const { activeThemeSchema } = useColorValues();

  const showHorizontalZoom = horizontalZoom.isShow;
  const showVerticalZoom = verticalZoom.isShow;

  const [startAndEndHorizontal, setStartAndEndHorizontal] = useState<StartAndEndInterface>(
    dataZoomHorizontalStartAndEnd || startAndEnd,
  );
  const [startAndEndVertical, setStartAndEndVertical] = useState<StartAndEndInterface>(
    dataZoomVerticalStartAndEnd || startAndEnd,
  );

  const getZoomValue = useCallback(
    (type: 'slider' | 'inside', id: DataZoomType, position: 'bottom' | 'right') => {
      const isActiveLegend = legendSettings.isShow && legendSettings.location.type === position;

      return getSliderZoomWithGridDimensions({
        id,
        type,
        isShow: position === 'bottom' ? showHorizontalZoom : showVerticalZoom,
        position,
        activeThemeSchema,
        isActiveLegend,
        maxIndicatorStringLength: getLengthOfGraphicString(getMaximumStringLengthOfArrayData(Object.keys(data))),
        horizontal: {
          start: showHorizontalZoom ? startAndEndHorizontal?.start || 0 : 0,
          end: showHorizontalZoom ? startAndEndHorizontal?.end || 0 : 100,
        },
        vertical: {
          start: showVerticalZoom ? startAndEndVertical?.start || 0 : 0,
          end: showVerticalZoom ? startAndEndVertical?.end || 0 : 100,
        },
        horizontalZoom,
        verticalZoom,
      });
    },
    [
      activeThemeSchema,
      data,
      horizontalZoom,
      legendSettings.isShow,
      legendSettings.location.type,
      showHorizontalZoom,
      showVerticalZoom,
      startAndEndHorizontal?.end,
      startAndEndHorizontal?.start,
      startAndEndVertical?.end,
      startAndEndVertical?.start,
      verticalZoom,
    ],
  );

  const zoomValues = useMemo(
    () => ({
      horizontalSliderXZoomValue: getZoomValue('slider', 'horizontalSliderX', 'bottom'),
      horizontalInsideYZoomValue: getZoomValue('inside', 'horizontalInsideY', 'bottom'),
      verticalInsideXZoomValue: getZoomValue('slider', 'verticalInsideX', 'right'),
      verticalSliderYZoomValue: getZoomValue('inside', 'verticalSliderY', 'right'),
    }),
    [getZoomValue],
  );

  const { horizontalSliderXZoomValue, horizontalInsideYZoomValue, verticalInsideXZoomValue, verticalSliderYZoomValue } =
    zoomValues;

  const onDataZoom = useCallback((params: DataZoomParamsInterface) => {
    const { batch, dataZoomId, start, end } = params;

    if (batch && batch.length > 1) {
      const startHorizontal = batch[1].start,
        endHorizontal = batch[1].end;
      setStartAndEndHorizontal({ start: startHorizontal, end: endHorizontal });

      const startVertical = batch[0].start,
        endVertical = batch[0].end;
      setStartAndEndVertical({ start: startVertical, end: endVertical });
    }

    if (batch && batch.length <= 1) {
      const start = batch[0].start,
        end = batch[0].end,
        horizontalSliderXId = horizontalSliderXZoomValue.config.id,
        horizontalInsideYId = horizontalInsideYZoomValue.config.id,
        verticalSliderYId = verticalSliderYZoomValue.config.id,
        verticalInsideXId = verticalInsideXZoomValue.config.id,
        isHorizontalZoom = [horizontalSliderXId, horizontalInsideYId].some((id) => id === batch[0]?.dataZoomId),
        isVerticalZoom = [verticalSliderYId, verticalInsideXId].some((id) => id === batch[0]?.dataZoomId);

      if (isHorizontalZoom) {
        setStartAndEndHorizontal({ start, end });
      }

      if (isVerticalZoom) {
        setStartAndEndVertical({ start, end });
      }
    }

    const horizontalSliderXId = horizontalSliderXZoomValue.config.id,
      horizontalInsideYId = horizontalInsideYZoomValue.config.id,
      verticalSliderYId = verticalSliderYZoomValue.config.id,
      verticalInsideXId = verticalInsideXZoomValue.config.id,
      isHorizontalZoom = [horizontalSliderXId, horizontalInsideYId].some((id) => id === dataZoomId),
      isVerticalZoom = [verticalSliderYId, verticalInsideXId].some((id) => id === dataZoomId);

    if (isHorizontalZoom) {
      setStartAndEndHorizontal({ start, end });
    }

    if (isVerticalZoom) {
      setStartAndEndVertical({ start, end });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSettingsChange({
    value: startAndEndHorizontal,
    onChange: onDataZoomHorizontalStartAndEndSettingsChange,
  });

  useSettingsChange({
    value: startAndEndVertical,
    onChange: onDataZoomVerticalStartAndEndSettingsChange,
  });

  return { zoomValues, onDataZoom };
};
