import styled from 'styled-components';
import { defaultScrollbarMixin } from 'constants/styles';

export const BoardWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 20px;
`;

export const BoardContentWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

interface BoardContentProps {
  zoom: number;
}

export const BoardContent = styled.div<BoardContentProps>`
  transform: scale(${({ zoom }) => zoom});
  width: calc(100% * 1 / ${({ zoom }) => zoom}) !important;
  height: calc(100% * 1 / ${({ zoom }) => zoom}) !important;
  position: absolute;
  transform-origin: top left;
  overflow: auto;
  ${defaultScrollbarMixin}
`;

export const ControlButtonWrapper = styled.div`
  position: absolute;
  top: -15px;
  right: 20px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 10px;
  z-index: 1;

  button:first-child {
    margin-bottom: 10px;
  }
`;

export const ModelsListWrapper = styled.div`
  position: absolute;
  top: -15px;
  left: 20px;
  z-index: 1;
`;
