import React, { useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button } from 'modules/ui';
import { ModalComponentType } from 'store/reducers/modals/types';
import { NoopValueType } from 'types/global';
import { Combobox } from 'modules/ui/Combobox';
import { FlowType } from 'components/projectsManager/Modals/MoveProjectModal/types';
import { SelectItemInterface } from 'components/admin/licenses/Modal/SelectAddAndEditModal/types';

interface SelectModalProps {
  title: string;
  onClick: NoopValueType<string>;
  selectItem?: SelectItemInterface;
  selectData: SelectItemInterface[];
  confirmationButtonText: string;
}

export const SelectAddAndEditModal: ModalComponentType<SelectModalProps> = ({
  title,
  onClose,
  selectItem,
  selectData,
  onClick,
  confirmationButtonText,
}) => {
  const [select, setSelect] = useState<FlowType>(selectItem || selectData[0]);

  return (
    <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" height="100%">
      <FlexContainer width="100%" flexDirection="column" height="100%" gap="32px">
        <Combobox
          title={title}
          name={title}
          placeholder={title}
          options={selectData}
          tooltipDisableHoverListener
          width="100%"
          value={select}
          onChange={(value) => setSelect(value)}
        />

        <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
          <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={false} onClick={onClose} />
          <Button
            disabled={select?.name === selectItem?.name || !select?.name}
            text={confirmationButtonText}
            iconSize="normal"
            needBackground={true}
            heightSize="normal"
            onClick={() => onClick(select?.name)}
          />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
