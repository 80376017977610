import { createSlice, isAllOf, PayloadAction } from '@reduxjs/toolkit';
import { setSliceFn } from 'constants/store';
import {
  getPaletteItem,
  initialPalettesStoreState,
  removePaletteByIdFunc,
  updatePaletteByIdFunc,
} from 'store/reducers/palettes/constants';
import { AddNewPalettePayload, ReturnNewPaletteInterface } from 'store/reducers/palettes/types';
import { getMapObject } from 'utils/utils';
import { loadPalettesAction, loadPalettesFromSnapshotAction } from 'store/reducers/palettes/actions';
import { uploadDashboardSettingsAction } from 'store/reducers/projectSettings/actions';

export const palettesSlice = createSlice({
  name: 'palettes',
  initialState: initialPalettesStoreState,
  reducers: {
    addNewPalette: (state, { payload: { id, ...params } }: PayloadAction<AddNewPalettePayload>) => {
      state.palettes = {
        ...state.palettes,
        [id]: getPaletteItem({ id, ...params }),
      };
    },

    removePaletteById: removePaletteByIdFunc,

    updatePaletteById: updatePaletteByIdFunc,

    //----------------------RETURN FOR HISTORY----------------------

    returnNewPalette: (state, { payload: { id, data } }: PayloadAction<ReturnNewPaletteInterface>) => {
      state.palettes = {
        ...state.palettes,
        [id]: data,
      };
    },

    returnRemovePaletteById: removePaletteByIdFunc,

    returnPaletteById: updatePaletteByIdFunc,

    setSlice: setSliceFn,
  },
  extraReducers: (builder) => {
    builder.addCase(loadPalettesAction.pending, (state) => {
      state.palettes = {};
      state.palettesLoading = true;
    });

    builder.addCase(loadPalettesAction.fulfilled, (state, { payload: palettes }) => {
      state.palettes = getMapObject(palettes, 'id');
      state.serverStateOfPalettes = getMapObject(palettes, 'id');
      state.palettesLoading = false;
    });

    builder.addCase(loadPalettesAction.rejected, (state) => {
      state.palettes = {};
      state.palettesLoading = false;
    });

    builder.addCase(loadPalettesFromSnapshotAction.fulfilled, (state, { payload: palettes }) => {
      state.palettes = getMapObject(palettes, 'id');
    });
    builder.addMatcher(isAllOf(uploadDashboardSettingsAction.fulfilled), (state) => {
      if (state.serverStateOfPalettes) {
        state.serverStateOfPalettes = state.palettes;
      }
    });
  },
});

export const {
  addNewPalette,
  updatePaletteById,
  returnPaletteById,
  returnRemovePaletteById,
  returnNewPalette,
  removePaletteById,
  setSlice,
} = palettesSlice.actions;

export default palettesSlice.reducer;
