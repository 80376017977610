import { StatusType } from 'types/store';
import { ErrorIcon, ErrorWarningIcon, IconType, SuccessIcon, UpdateIcon } from 'assets/icons/withContainer';

export const statusData: Record<StatusType, { name: string; Icon: IconType; color: string }> = {
  success: { name: 'Успех', Icon: SuccessIcon, color: '#33BB69' },
  error: { name: 'Ошибка', Icon: ErrorIcon, color: '#D95050' },
  warning: { name: 'Предупреждение', Icon: ErrorWarningIcon, color: '#FFAA00' },
  pending: { name: 'Загрузка...', Icon: UpdateIcon, color: '#26A5ED' },
  aborted: { name: 'Ошибка', Icon: ErrorIcon, color: '#D95050' },
  active: { name: 'Загрузка...', Icon: UpdateIcon, color: '#26A5ED' },
};
