import { CallbackDataParams } from 'echarts/types/dist/shared';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { onActiveNextIncisionIdChange, onDeleteFiltersForDrillDownVisualisation } from 'modules/visualisations/common/constants';
import { formatResult } from 'modules/visualisations/common/onChangeFunctions';
import { SingleIncisionLayout } from 'modules/visualisations/components/SingleIncisionLayout';
import { WrappedReactECharts } from 'modules/visualisations/components/WrappedReactECharts';
import { useActiveIncision } from 'modules/visualisations/hooks/activeIncision';
import { useDefaultIndicator } from 'modules/visualisations/hooks/defaultIndicator';
import { useManualResize } from 'modules/visualisations/hooks/manualResize';
import { useVisualisation } from 'modules/visualisations/hooks/visualisation';
import {
  defaultColors,
  getFormatSeries,
  getPieLegendData,
  getPieMapData,
  legendPositionAtGrid,
  onActiveIncisionIdChange,
} from 'modules/visualisations/Pie/visualisation/constants';
import { PieEChartsOption, PieNode } from 'modules/visualisations/Pie/visualisation/types';
import { VisualisationOriginInterface } from 'modules/workspace/components/VisualisationArea/types';
import React, { memo, useCallback, useMemo } from 'react';
import { defaultPieDataSettings } from 'store/reducers/visualisations/constants';
import { PieVisualisationType } from 'store/reducers/visualisations/types';
import { ItemValueColor } from 'types/echarts';

export const PieVisualisationComponent: VisualisationOriginInterface<PieVisualisationType> = ({ data, sqlRequest }) => {
  const { id, dataSettings, viewSettings, sqlData, events } = data;

  const { colorBySettings, colorByValueSettings, isDrillDown } = dataSettings,
    {
      shadowColorSettings: { shadowColorBy },
    } = viewSettings.shadowSettings;

  const {
    visualisationNormalizedValues,
    updateFilter,
    eChartRef,
    getColorByValue,
    getVisualisationColorsAndImagesData,
    isColorByCondition,
    formattingParams: { formatting },
    enabledFilters,
  } = useVisualisation({
    sqlData,
    id,
    dataSettings,
    colorsBy: [colorBySettings, colorByValueSettings, shadowColorBy],
    events,
    limit: dataSettings.limit,
    sqlRequest,
  });

  const { getColorValues, activeThemeSchema } = useColorValues();

  const activeIncision = useActiveIncision({
    activeIncisionId: dataSettings.activeIncisionId,
    defaultIncision: defaultPieDataSettings.incisions[0],
    incisions: dataSettings.incisions,
  });

  const mainIndicator = useDefaultIndicator({
    indicators: dataSettings.indicators,
    defaultIndicator: defaultPieDataSettings.indicators[0],
  });

  const colors = useMemo(
    () => getColorValues(activeIncision.data.colors) || defaultColors(activeThemeSchema),
    [activeIncision.data.colors, activeThemeSchema, getColorValues],
  );

  const pieMapData = useMemo(
    () => getPieMapData(dataSettings, visualisationNormalizedValues),
    [dataSettings, visualisationNormalizedValues],
  );

  const legendData = useMemo(
    () => getPieLegendData(dataSettings, visualisationNormalizedValues),
    [dataSettings, visualisationNormalizedValues],
  );

  useManualResize({ eChartRef: eChartRef.current, deps: [viewSettings.description, viewSettings.header] });

  const circlePosition = useMemo(() => {
    if (viewSettings?.legendSettings.isShow) {
      return legendPositionAtGrid[viewSettings.legendSettings.location.type] || legendPositionAtGrid['default'];
    }

    return legendPositionAtGrid['default'];
  }, [viewSettings?.legendSettings.isShow, viewSettings.legendSettings.location]);

  const legendPositionInGrid = useCallback(
    (vertical: boolean) => {
      return viewSettings.legendSettings.position.type !== 'flex-end'
        ? viewSettings.legendSettings.position.type
        : vertical
        ? 'right'
        : 'bottom';
    },
    [viewSettings.legendSettings.position],
  );

  const positionLegend = useMemo(() => {
    switch (viewSettings.legendSettings.location.type) {
      case 'top': {
        return {
          top: 0,
          left: legendPositionInGrid(true),
        };
      }
      case 'bottom': {
        return {
          bottom: 0,
          left: legendPositionInGrid(true),
        };
      }
      case 'left': {
        return {
          left: 0,
          top: legendPositionInGrid(false),
        };
      }
      case 'right': {
        return {
          right: 0,
          top: legendPositionInGrid(false),
        };
      }
    }
  }, [legendPositionInGrid, viewSettings.legendSettings.location.type]);

  const labelFormat = mainIndicator.data.settings.labelFormat;
  const valueFormat = mainIndicator.data.settings.valueFormat;
  const properties = mainIndicator.data.settings.properties;
  const {
    outerRadius,
    innerRadius,
    centerCoordinates: { centerYCoordinates, centerXCoordinates, isActive: isActiveCenterCoordinates },
    arcStartAngle,
    arcEndAngle,
  } = mainIndicator.data.settings.styleSettings;

  const innerRadiusResult = formatResult(innerRadius);
  const outerRadiusResult = formatResult(outerRadius);
  const centerXCoordinatesResult = formatResult(centerXCoordinates);
  const centerYCoordinatesResult = formatResult(centerYCoordinates);

  const formattingFunction = formatting[mainIndicator.fieldName];

  const formatSeries = useMemo(() => getFormatSeries(labelFormat, valueFormat), [labelFormat, valueFormat]);

  const isHorizontalLegendLocation = useMemo(
    () => viewSettings.legendSettings.location.type === 'left' || viewSettings.legendSettings.location.type === 'right',
    [viewSettings.legendSettings.location],
  );

  const getItemColor = useMemo(
    () =>
      colorBySettings.type !== 'default'
        ? (params: CallbackDataParams) => {
            const { value: dataValue } = params.data as PieNode,
              alias = colorBySettings.byCondition.alias;

            let value: string | number | null | undefined = dataValue;

            if (isColorByCondition(alias)) {
              value = getVisualisationColorsAndImagesData(alias)[params.dataIndex];
            }

            return getColorByValue({ value, indicatorName: params.seriesName || '', alias });
          }
        : undefined,
    [colorBySettings, getColorByValue, isColorByCondition, getVisualisationColorsAndImagesData],
  );

  const getItemValueColor = useMemo(
    () =>
      colorByValueSettings.type !== 'default'
        ? (params: ItemValueColor) => {
            const { data, dataIndex } = params,
              alias = colorByValueSettings.byCondition.alias;

            let value: string | number | null | undefined = data as number;

            if (isColorByCondition(alias)) {
              value = getVisualisationColorsAndImagesData(alias)[dataIndex];
            }

            return getColorByValue({ value, indicatorName: mainIndicator.fieldName || '', alias });
          }
        : undefined,
    [
      colorByValueSettings.type,
      colorByValueSettings.byCondition.alias,
      isColorByCondition,
      getColorByValue,
      mainIndicator.fieldName,
      getVisualisationColorsAndImagesData,
    ],
  );

  const pieDataResult = useMemo(
    () =>
      pieMapData?.map(({ value, tableColumn, name }, index) => {
        const isDefaultTypeColor = colorByValueSettings.type === 'default',
          itemValueColor = !isDefaultTypeColor
            ? getItemValueColor && getItemValueColor({ data: value as number, dataIndex: index, seriesName: name })
            : undefined;

        const color = itemValueColor || getColorValues(properties?.fontColor) || activeThemeSchema[ColorVarsEnum.Level_1];

        return {
          name,
          value,
          tableColumn,
          label: {
            color,
          },
        };
      }),
    [pieMapData, colorByValueSettings.type, getItemValueColor, getColorValues, properties?.fontColor, activeThemeSchema],
  );

  const option = useMemo<PieEChartsOption>(
    () => ({
      textStyle: {
        color: activeThemeSchema[ColorVarsEnum.Level_2],
      },

      tooltip: {
        trigger: 'item',
        show: viewSettings.showTips,
        formatter: formattingFunction
          ? (data: any) => {
              const valueType = String(valueFormat.includes('percent') ? data.percent : data.value);
              const name = labelFormat.includes('name') ? data.name : '';
              const value = labelFormat.includes('value') ? data.value : '';
              const space = name && value ? '  ' : ' ';

              return name + space + formattingFunction?.(valueType) + `(${data.percent}%)`;
            }
          : '{b}: {c} ({d}%)',
        axisPointer: {
          lineStyle: {
            color: activeThemeSchema[ColorVarsEnum.Level_4],
          },
          crossStyle: {
            color: activeThemeSchema[ColorVarsEnum.Level_4],
          },
        },
      },

      toolbox: {
        iconStyle: {
          normal: {
            borderColor: activeThemeSchema[ColorVarsEnum.Level_4],
          },
        },
      },

      color: colors,

      yAxis: {
        show: false,
      },

      xAxis: {
        show: false,
      },

      grid: {
        show: false,
      },

      legend: {
        data: legendData,
        show: viewSettings.legendSettings.isShow,
        type: 'scroll',
        orient: isHorizontalLegendLocation ? 'vertical' : 'horizontal',
        textStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_2],
          fontSize: '14px',
          overflow: 'breakAll',
          width: isHorizontalLegendLocation ? viewSettings.legendSettings.width - 32 : undefined,
        },
        pageTextStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_2],
        },
        ...positionLegend,
      },

      series: [
        {
          name: mainIndicator.fieldName,
          type: 'pie',

          roseType: dataSettings.roseType === 'pie' ? undefined : dataSettings.roseType,
          center: isActiveCenterCoordinates ? [centerXCoordinatesResult, centerYCoordinatesResult] : circlePosition,
          silent: !mainIndicator.data.settings.styleSettings.pieAnimationElement,
          avoidLabelOverlap: false,
          yAxisLabel: 0,
          radius: [innerRadiusResult, outerRadiusResult],

          itemStyle: {
            borderColor: viewSettings.showBackground
              ? activeThemeSchema[ColorVarsEnum.Level_4_widget]
              : activeThemeSchema[ColorVarsEnum.Level_5_application],
            borderRadius: mainIndicator.data.settings.styleSettings.borderRadius,
            borderWidth: mainIndicator.data.settings.styleSettings.borderWidth,
            color: getItemColor,
          },

          startAngle: arcStartAngle,
          endAngle: arcEndAngle,

          markPoint: {
            symbol: 'circle',
            symbolSize: 200,
            symbolOffset: [13, 13],
            symbolRotate: 23,
          },

          lineStyle: {
            width: 3,
            type: 'solid',
            opacity: 1,
          },

          label: {
            overflow: 'truncate',
            trigger: 'item',
            position: 'outside',

            formatter: formattingFunction
              ? (data) => {
                  const valueType = String(valueFormat.includes('percent') ? data.percent : data.value);
                  const value = labelFormat.includes('value') ? data.value : '';
                  const name = labelFormat.includes('name') ? data.name : '';
                  const space = name && value ? ' - ' : ' ';
                  const formattedValue = value ? formattingFunction?.(valueType) : '';

                  return name + space + formattedValue;
                }
              : formatSeries,

            show: labelFormat.includes('name') || labelFormat.includes('value'),

            /* Font settings */
            lineHeight: 12,
            fontSize: properties.fontSize,
            fontWeight: properties.fontStyle.bold ? 'bold' : 'normal',
            fontStyle: properties.fontStyle.italic ? 'italic' : 'normal',
          },

          labelLine: {
            show: true,
          },

          axisLine: {
            lineStyle: {
              color: activeThemeSchema[ColorVarsEnum.Level_4],
            },
          },
          axisTick: {
            lineStyle: {
              color: activeThemeSchema[ColorVarsEnum.Level_4],
            },
          },
          axisLabel: {
            textStyle: {
              color: activeThemeSchema[ColorVarsEnum.Level_2],
            },
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: activeThemeSchema[ColorVarsEnum.Level_4],
            },
          },
          splitArea: {
            areaStyle: {
              color: activeThemeSchema[ColorVarsEnum.Level_4],
            },
          },

          data: pieDataResult,
        },
      ],
    }),
    [
      activeThemeSchema,
      viewSettings.showTips,
      viewSettings.legendSettings.isShow,
      viewSettings.legendSettings.width,
      viewSettings.showBackground,
      formattingFunction,
      colors,
      legendData,
      isHorizontalLegendLocation,
      positionLegend,
      mainIndicator.fieldName,
      mainIndicator.data.settings.styleSettings.pieAnimationElement,
      mainIndicator.data.settings.styleSettings.borderRadius,
      mainIndicator.data.settings.styleSettings.borderWidth,
      dataSettings.roseType,
      isActiveCenterCoordinates,
      centerXCoordinatesResult,
      centerYCoordinatesResult,
      circlePosition,
      innerRadiusResult,
      outerRadiusResult,
      getItemColor,
      arcStartAngle,
      arcEndAngle,
      formatSeries,
      labelFormat,
      properties.fontSize,
      properties.fontStyle.bold,
      properties.fontStyle.italic,
      pieDataResult,
      valueFormat,
    ],
  );

  const onChartClick = useCallback(
    (params: CallbackDataParams) => {
      const node = params.data as PieNode;
      onActiveNextIncisionIdChange({
        id,
        dataSettings,
        events,
        activeIncisionId: dataSettings.activeIncisionId,
        onChange: onActiveIncisionIdChange,
      });
      updateFilter({ selectedValue: params.name, fieldName: node.tableColumn });
    },
    [updateFilter, dataSettings, events, id],
  );

  const onChangeActiveIncision = (activeIncisionId: string | null) => {
    if (enabledFilters && dataSettings.activeIncisionId && activeIncisionId) {
      onDeleteFiltersForDrillDownVisualisation({
        dataSettings,
        enabledFilters,
        lastActiveIncisionId: dataSettings.activeIncisionId,
        nextActiveIncisionId: activeIncisionId,
      });
    }

    activeIncisionId && onActiveIncisionIdChange({ activeIncisionId, id });
  };

  const onEvents = {
    click: onChartClick,
  };

  return (
    <SingleIncisionLayout
      incisions={dataSettings.incisions}
      value={dataSettings.activeIncisionId}
      incisionSelectorPosition={viewSettings.incisionSelectorPosition}
      isDrillDown={!!isDrillDown}
      isVisible={viewSettings.isVisible}
      onChange={onChangeActiveIncision}
    >
      <WrappedReactECharts
        onEvents={onEvents}
        notMerge
        ref={(e) => {
          eChartRef.current = e?.getEchartsInstance();
        }}
        style={{ width: '100%', height: '100%' }}
        option={option}
      />
    </SingleIncisionLayout>
  );
};

export const PieVisualisation = memo(PieVisualisationComponent) as VisualisationOriginInterface<PieVisualisationType>;
