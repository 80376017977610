import React from 'react';
import { ZoomSettingsInterface } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import { AutoOrManualGroupValue } from 'modules/settingsContainer/common/AutoOrManualGroupValue';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';

type ZoomSettingsSettingsProps = OnValueChange<ZoomSettingsInterface> & {
  title: string;
};

export const ZoomSettings = ({ title, value, onChange }: ZoomSettingsSettingsProps) => {
  const { isShow, padding } = value;

  return (
    <>
      <GroupContainerSettings
        titleText={title}
        switcherState={isShow}
        switcherChange={() => onChange({ ...value, isShow: !isShow })}
      ></GroupContainerSettings>
      <ElementContainerSettings>
        <AutoOrManualGroupValue titleText="Отступ" value={padding} onChange={(padding) => onChange({ ...value, padding })} />
      </ElementContainerSettings>
    </>
  );
};
