import styled, { css } from 'styled-components';
import { Slider } from '@mui/material';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { colorBarHeight, halfThumbSize, sliderHeight, thumbSize } from 'modules/ui/colors/ColorSlider/constants';

interface ColorSliderWrapperProps {
  disabled?: boolean;
}

export const ColorSliderWrapper = styled.div<ColorSliderWrapperProps>`
  position: relative;
  width: 100%;
  padding: 0 ${thumbSize}px;
  height: ${sliderHeight}px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
    `}
`;

interface ColoredCanvasProps {
  top: number;
}

export const ColoredCanvas = styled.canvas<ColoredCanvasProps>`
  position: absolute;
  left: ${halfThumbSize}px;
  top: ${({ top }) => `${top}px`};
  border-radius: ${colorBarHeight}px;
`;

export const SliderExtended = styled(Slider)`
  .MuiSlider-rail {
    visibility: hidden;
  }
  .MuiSlider-track {
    visibility: hidden;
  }
  .MuiSlider-thumb {
    height: ${thumbSize}px;
    width: ${thumbSize}px;
    background: ${`var(${ColorVarsEnum.Level_2})`};
    border: 1px solid ${`var(${ColorVarsEnum.Level_1})`};
    box-sizing: border-box;
  }
  .MuiSlider-thumb {
    box-shadow: unset;

    :hover {
      box-shadow: unset;

      ::after {
        z-index: 20;
      }
    }
  }
  .Mui-active {
    box-shadow: unset !important;
  }
  z-index: 1;
`;
