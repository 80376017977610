import { createSelector } from 'reselect';
import { getState } from 'store/utils';

export const getAdminActiveDirectoriesStore = createSelector(getState, (state) => state.adminActiveDirectories);
export const getAdminActiveDirectoriesData = createSelector(
  getAdminActiveDirectoriesStore,
  (state) => state.activeDirectoriesData,
);

export const getAdminTasksInfo = createSelector(getAdminActiveDirectoriesStore, (state) => state.activeDirectorInfo);
