import React, { FC } from 'react';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FlexContainer } from 'styles/FlexContainer';
import { CustomCircularProgress } from 'modules/ui/CustomCircularProgress/CustomCircularProgress';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { NoopType } from 'types/global';

type StatisticsProgressProgress = {
  title: string;
  valueProgress: number;
  fullCountProgress: number;
  countProgress: number;
  active: boolean;
  onClick: NoopType;
  size?: number;
};
export const StatisticsProgress: FC<StatisticsProgressProgress> = ({
  title,
  valueProgress,
  fullCountProgress,
  countProgress,
  active,
  size,
  onClick,
}) => {
  return (
    <FlexContainer gap="9px" flexDirection="column" onClick={onClick} cursor="pointer">
      <PrimaryTextSpan
        fontSize="14px"
        lineHeight="14px"
        color={active ? `var(${ColorVarsEnum.Accent})` : `var(${ColorVarsEnum.Level_1})`}
      >
        {title}
      </PrimaryTextSpan>

      <FlexContainer flexDirection="row" height="36px" gap="8px">
        <CustomCircularProgress value={valueProgress} size={size} />
        <FlexContainer flexDirection="column" alignItems="flex-start" justifyContent="center" gap="4px">
          <PrimaryTextSpan fontSize="16px" lineHeight="16px" color={`var(${ColorVarsEnum.Level_2})`}>
            {Math.round(valueProgress)}%
          </PrimaryTextSpan>
          <PrimaryTextSpan fontSize="12px" lineHeight="12px" color={`var(${ColorVarsEnum.Level_2})`}>
            {fullCountProgress}/{countProgress}
          </PrimaryTextSpan>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
