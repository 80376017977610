export const canvasFiller = (canvas: HTMLCanvasElement | null, color: (value: number) => string | null) => {
  const context = canvas?.getContext('2d');

  if (canvas && context) {
    canvas.style.imageRendering = 'pixelated';

    const width = canvas.clientWidth;
    const height = canvas.clientHeight;

    for (let i = 0; i < width; ++i) {
      context.fillStyle = color(i / (width - 1)) || 'white';
      context.fillRect(i, 0, 1, height);
    }

    return canvas;
  }
};
