import { OnValueChange } from 'types/global';
import { OrientationType } from 'store/reducers/visualisations/types';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import React from 'react';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { orientationOptions } from 'modules/settingsContainer/common/OrientationGroupSettings/constants';

type OrientationGroupSettingsProps = OnValueChange<OrientationType>;

export const OrientationGroupSettings = ({ value, onChange }: OrientationGroupSettingsProps) => (
  <GroupContainerSettings titleText="Ориентация">
    <ElementContainerSettings>
      <SettingsRadio
        onChange={({ value }) => onChange(value)}
        activeValue={getActiveRadioValue(orientationOptions, value)}
        options={orientationOptions}
      />
    </ElementContainerSettings>
  </GroupContainerSettings>
);
