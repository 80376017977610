import { useState, useMemo, useEffect } from 'react';
import Fuse from 'fuse.js';
import { SourceDataInterface } from 'store/reducers/sources/types';
import { MultiSelectType } from 'modules/ui/MultiSelect';

interface UseFilteredDataParams {
  sourcesList: SourceDataInterface[];
  saveMultiFilter: MultiSelectType<string>[];
  sortType: string;
  deferredSearchText?: string;
}

export const useFilteredData = ({
  sourcesList,
  saveMultiFilter,
  sortType,
  deferredSearchText,
}: UseFilteredDataParams): SourceDataInterface[] => {
  const fuseOptions: Fuse.IFuseOptions<SourceDataInterface> = useMemo(
    () => ({
      keys: ['name'],
      threshold: 0.3,
      findAllMatches: true,
      shouldSort: true,
    }),
    [],
  );

  const fuse = useMemo(() => new Fuse(sourcesList, fuseOptions), [fuseOptions, sourcesList]);

  const sortFunctions: Record<string, (a: SourceDataInterface, b: SourceDataInterface) => number> = useMemo(
    () => ({
      ascendingAlphabet: (a, b) => a.name.localeCompare(b.name),
      descendingAlphabet: (a, b) => b.name.localeCompare(a.name),
      dataSortedAscending: (a, b) => +new Date(a.createdAt || 0) - +new Date(b.createdAt || 0),
      dataSortedDescending: (a, b) => +new Date(b.createdAt || 0) - +new Date(a.createdAt || 0),
    }),
    [],
  );

  const [filteredData, setFilteredData] = useState<SourceDataInterface[]>([]);

  useEffect(() => {
    const initialResults = deferredSearchText ? fuse.search(deferredSearchText).map((result) => result.item) : sourcesList;

    const typeFilters = saveMultiFilter.filter((f) => f.meta === 'type').map((f) => f.value);
    const driverFilters = saveMultiFilter.filter((f) => f.meta === 'driver').map((f) => f.value);

    const matchesFilters = (item: SourceDataInterface) => {
      const matchesType = typeFilters.length > 0 && typeFilters.includes(item.type);
      const matchesDriver = driverFilters.length > 0 && driverFilters.includes(item.driver);
      return matchesType || matchesDriver;
    };

    const filteredResults = initialResults.reduce<SourceDataInterface[]>((acc, item) => {
      if (typeFilters.length === 0 && driverFilters.length === 0) {
        acc.push(item);
      } else if (matchesFilters(item)) {
        acc.push(item);
      }
      return acc;
    }, []);

    const sortedResults = filteredResults.sort(sortFunctions[sortType]);

    setFilteredData(sortedResults);
  }, [deferredSearchText, fuse, saveMultiFilter, sortType, sourcesList, sortFunctions]);

  return filteredData;
};
