import { SettingsLayout } from 'modules/settingsContainer/SettingsLayout';
import { DataTab } from 'modules/filters/Date/settings/DataTab';

export const DateFilterMainSettings = () => {
  return (
    <SettingsLayout>
      <DataTab />
    </SettingsLayout>
  );
};
