import styled from 'styled-components';
import { backgroundOpacityMixin } from 'constants/styles';
import { FlexContainer } from 'styles/FlexContainer';

export const SourcesTypeItemContainer = styled(FlexContainer)`
  :after {
    transition: opacity 1s ease;
  }

  :hover:after {
    ${backgroundOpacityMixin};
    z-index: 0;
  }
`;
