import { useEffect } from 'react';
import { NoopType } from 'types/global';

interface LongPollingInterface {
  intervalCallback: NoopType;
  delay: number;
}

export const useLongPolling = ({ intervalCallback, delay }: LongPollingInterface) => {
  useEffect(() => {
    const intervalId = setInterval(intervalCallback, delay);

    return () => clearInterval(intervalId);
  }, [intervalCallback, delay]);
};
