import { createColumnHelper } from '@tanstack/react-table';
import { ActiveDirectoriesModal } from 'components/admin/activeDirectories/Modal/ActiveDirectoriesModal';
import {
  createActiveDirector,
  editActiveDirector,
} from 'components/admin/activeDirectories/Modal/ActiveDirectoriesModal/constants';
import { ActiveDirectorFormDataInterface } from 'components/admin/activeDirectories/Modal/ActiveDirectoriesModal/types';
import { UsersGroupListInterface } from 'components/admin/activeDirectories/types';
import { ActiveDirectoriesAndTasksWrapper } from 'components/admin/wrappers/ActiveDirectoriesAndTasksWrapper';
import { Details } from 'components/admin/wrappers/ActiveDirectoriesAndTasksWrapper/Details';
import { accessIcons } from 'components/projectsManager/elements/Projects/Access/constants';
import { TableCell } from 'modules/ui/Table/TableCell';
import { TableWithSearch } from 'modules/ui/TableWithSearch';
import { Tab } from 'modules/ui/tabs/types';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import {
  addActiveDirectorInfoAction,
  deleteActiveDirectorAction,
  deleteActiveDirectorByIdAction,
  loadActiveDirectorAction,
  loadActiveDirectoriesAction,
  loadConnectorUsersGroupsAction,
  synchronizeAction,
} from 'store/reducers/adminActiveDirectories/actions';
import { getAdminActiveDirectoriesData, getAdminTasksInfo } from 'store/reducers/adminActiveDirectories/getters';
import { AdminActiveDirectoriesInterface } from 'store/reducers/adminActiveDirectories/types';
import { closeModalAction, openConfirmationModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { FlexContainer } from 'styles/FlexContainer';
import { ModeForm } from 'types/store';
import { useToggle } from 'utils/hooks/toggle';

export const AdminActiveDirectories: FC = () => {
  const [open, toggleOpen] = useToggle(false);

  const dispatch = useAppDispatch();
  const { loading, adminActiveDirectoriesList } = useSelector(getAdminActiveDirectoriesData);
  const activeDirectorInfo = useSelector(getAdminTasksInfo);
  const [activeDirectorId, setActiveDirectorId] = useState<string | null>(null);

  const [usersGroupList, setUsersGroupList] = useState<UsersGroupListInterface[]>([]);

  useEffect(() => {
    dispatch(loadActiveDirectoriesAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addActiveDirectorInfo = useCallback(
    (data: AdminActiveDirectoriesInterface | null) => dispatch(addActiveDirectorInfoAction(data)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onDeleteActiveDirector = useCallback(
    async (activeDirector: AdminActiveDirectoriesInterface) => {
      try {
        const activeDirectorId = activeDirector?.id;
        if (activeDirectorId) {
          const activeDirectorRes = await dispatch(deleteActiveDirectorAction(activeDirectorId)).unwrap();

          if (activeDirectorRes) {
            dispatch(deleteActiveDirectorByIdAction(activeDirectorId));
            addActiveDirectorInfo(null);
            setUsersGroupList([]);
          }
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addActiveDirectorInfo],
  );

  const onDeleteActiveDirectorModal = useCallback(
    async (activeDirector: AdminActiveDirectoriesInterface) => {
      dispatch(
        openConfirmationModalAction({
          titleText: 'Удалить подключение к актвной директории',
          onConfirm: () => onDeleteActiveDirector(activeDirector),
          subTitleText: `Действительно удалить ${activeDirector.name}?`,
          confirmationButtonText: 'Да',
          width: '320px',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDeleteActiveDirector],
  );

  const onCloseTaskModal = useCallback(
    (name: string) => dispatch(closeModalAction(name)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onActiveDirectorModal = useCallback(
    ({
      modalName,
      data,
      mode,
      headerTextModal,
    }: {
      modalName: string;
      mode: ModeForm;
      headerTextModal: string;
      data?: ActiveDirectorFormDataInterface;
    }) => {
      dispatch(
        openModalTypedAction({
          Component: ActiveDirectoriesModal,
          componentProps: {
            onClose: () => onCloseTaskModal(modalName),
            dataEdit: data,
            mode,
          },
          modalSettings: {
            position: 'static',
            width: '575px',
            headerText: headerTextModal,
          },
          name: modalName,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseTaskModal],
  );

  const onCreate = useCallback(
    () =>
      onActiveDirectorModal({
        modalName: createActiveDirector,
        headerTextModal: 'Создание подключения к активной директории',
        mode: 'add',
      }),
    [onActiveDirectorModal],
  );

  const onEdit = useCallback(
    async (activeDirector: AdminActiveDirectoriesInterface) => {
      const activeDirectorId = activeDirector.id;
      try {
        const actionActiveDirectorRes = await dispatch(loadActiveDirectorAction({ id: activeDirectorId })).unwrap();

        if (actionActiveDirectorRes) {
          const { id, name, url, newUsers, isActive, filterUsers, filterGroups, login, cron, password, baseDN } =
            actionActiveDirectorRes;
          return onActiveDirectorModal({
            modalName: editActiveDirector,
            mode: 'edit',
            headerTextModal: 'Изменение подключения к активной директории',
            data: {
              id,
              name,
              domain: baseDN,
              authorizationLogin: login,
              authorizationPassword: password,
              groups: filterGroups,
              isAddNewUsers: newUsers,
              users: filterUsers,
              isActive,
              cron,
              url,
            },
          });
        }
      } catch (error) {
        console.error('Error ', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onActiveDirectorModal],
  );

  const onConnectorUsersGroups = useCallback(
    async () => {
      try {
        if (activeDirectorId) {
          const usersGroupRes = await dispatch(loadConnectorUsersGroupsAction({ id: activeDirectorId })).unwrap();

          if (usersGroupRes) {
            setUsersGroupList(usersGroupRes);
          }
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeDirectorId],
  );

  const onRowClick = useCallback(
    async (data: AdminActiveDirectoriesInterface) => {
      const activeDirectorId = data?.id;
      setActiveDirectorId(activeDirectorId);
      await onConnectorUsersGroups();
      addActiveDirectorInfo(data);
    },
    [addActiveDirectorInfo, onConnectorUsersGroups],
  );

  const onUpdateActiveDirectories = useCallback(
    async () => {
      await onConnectorUsersGroups();
      await dispatch(loadActiveDirectoriesAction());

      const adminActiveDirector = adminActiveDirectoriesList?.find(({ id }) => id === activeDirectorId);

      if (adminActiveDirector) {
        addActiveDirectorInfo(adminActiveDirector);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeDirectorId, addActiveDirectorInfo, adminActiveDirectoriesList, onConnectorUsersGroups],
  );

  const rows: UsersGroupListInterface[] = useMemo(() => {
    if (!Array.isArray(adminActiveDirectoriesList)) {
      return [];
    }

    return usersGroupList || [];
  }, [adminActiveDirectoriesList, usersGroupList]);

  const columnHelper = createColumnHelper<UsersGroupListInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Группа/пользователь',
        cell: (info) => <TableCell text={info.getValue()} Icon={accessIcons[info.row.original.entity]} />,
      }),
    ],
    [columnHelper],
  );

  const tabs: Tab[] = useMemo(
    () =>
      [
        {
          content: 'Детали',
          prompt: 'Детали',
          type: 'text',
          id: 'details',
          Component: () => (
            <Details
              data={activeDirectorInfo}
              onRunProjectScript={async () => {
                activeDirectorInfo && (await dispatch(synchronizeAction({ id: activeDirectorInfo.id })));
                await onUpdateActiveDirectories();
              }}
            />
          ),
        },
        {
          content: 'Пользователи',
          prompt: 'Пользователи',
          type: 'text',
          id: 'usersGroup',
          Component: () => (
            <FlexContainer padding="24px 0 0 0">
              <TableWithSearch
                addButtonName="Синхронизировать"
                filterColumnName="name"
                columns={columns}
                rowHeightVariant="small"
                tableWithSearchMaxHeightContainer="calc(100vh - 300px)"
                tableWithSearchHeightContainer="70%"
                tableHeight={open ? '50%' : '100%'}
                rows={rows}
                onAddModal={() => activeDirectorId && dispatch(synchronizeAction({ id: activeDirectorId }))}
                loading={loading}
              />
            </FlexContainer>
          ),
        },
      ].filter(({ Component }) => Component) as Tab[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeDirectorId, columns, loading, open, rows, activeDirectorInfo],
  );

  return (
    <ActiveDirectoriesAndTasksWrapper
      tabs={tabs}
      onDeleteItem={onDeleteActiveDirectorModal}
      onEdit={onEdit}
      onCreate={onCreate}
      dataList={adminActiveDirectoriesList}
      loadingDatalist={loading}
      onRowClick={onRowClick}
      onOpenDropdown={toggleOpen}
      openDropdown={open}
      onUpdate={onUpdateActiveDirectories}
    />
  );
};
