import { createSelector } from 'reselect';
import { getState } from 'store/utils';

export const getFlowsStore = createSelector(getState, (state) => state.adminFlows);

export const getActiveFlow = createSelector(getFlowsStore, (state) => state.activeFlow);

export const getFlows = createSelector(getFlowsStore, (state) => state.flows);

export const getFlowAccess = createSelector(getFlowsStore, (state) => state.flowAccess);

export const getFlowProjects = createSelector(getFlowsStore, (state) => state.flowProjects);

export const getSortFlowProject = createSelector(getFlowsStore, (state) => state.sortFlowProject);

export const getActiveFlowProjectId = createSelector(getFlowsStore, (state) => state.activeFlowProjectId);

export const getFlowProjectHistoryVersion = createSelector(getFlowsStore, (state) => state.flowProjectHistoryVersion);

export const getFlowsAndDraft = createSelector(getFlowsStore, (state) => [
  // state.,
  ...state.flows.flowsList,
]);
