import { store } from 'store';
import { updateTableViewSettingsAction } from 'store/reducers/visualisations/actions';
import {
  BeatType,
  TableBodySettingsInterface,
  TableHeaderSettingsInterface,
  TotalRowSettingsInterface,
} from 'store/reducers/visualisations/types';

const dispatch = store.dispatch;

export const onShowVerticalLineChange = (showVerticalLine: boolean) =>
  dispatch(updateTableViewSettingsAction({ showVerticalLine }));

export const onShowHorizontalLineChange = (showHorizontalLine: boolean) =>
  dispatch(updateTableViewSettingsAction({ showHorizontalLine }));

export const onAdaptiveChange = (isAdaptive: boolean) => dispatch(updateTableViewSettingsAction({ isAdaptive }));

export const onTotalBeatChange = (totalBeat: BeatType[]) => dispatch(updateTableViewSettingsAction({ totalBeat }));
export const onSubtotalsSettingsChange = (subtotalsSettings: TotalRowSettingsInterface) =>
  dispatch(updateTableViewSettingsAction({ subtotalsSettings }));
export const onHeaderSettingsChange = (headerSettings: TableHeaderSettingsInterface) =>
  dispatch(updateTableViewSettingsAction({ headerSettings }));
export const onBodySettingsChange = (bodySettings: TableBodySettingsInterface) =>
  dispatch(updateTableViewSettingsAction({ bodySettings }));
export const onHeaderBeatChange = (headerBeat: BeatType[]) => dispatch(updateTableViewSettingsAction({ headerBeat }));
export const onIncisionBeatChange = (incisionBeat: BeatType[]) => dispatch(updateTableViewSettingsAction({ incisionBeat }));

export const onTotalRowChange = (totalRowSettings: TotalRowSettingsInterface) =>
  dispatch(updateTableViewSettingsAction({ totalRowSettings }));
