import React, { useCallback } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { TableFieldsPreviewInterface } from 'store/reducers/loadingScript/types';
import { NoopValueType } from 'types/global';
import { Checkbox } from 'modules/ui';
import styled from 'styled-components';
import { SelectedColumn } from 'components/console/elements/SourcesConnection/DataSelectionModal/Table/types';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { SelectedTableNameAndSchemaType } from 'components/console/elements/SourcesConnection/DataSelectionModal/types';
import { SelectedItemInterface } from 'modules/ui/lists/MapList/list/types';

interface TableProps {
  data: TableFieldsPreviewInterface;
  nameTableAndSchema: SelectedTableNameAndSchemaType;
  columnTableSelected: SelectedColumn[];
  setColumnTableSelected: NoopValueType<SelectedColumn[]>;
  onPrevTableSelected: NoopValueType<SelectedItemInterface | null>;
  loading: boolean;
  rowCountTable: number;
  onStatusCheck?: NoopValueType<'primary' | 'secondary'>;
}

export const Table = ({
  nameTableAndSchema,
  columnTableSelected,
  setColumnTableSelected,
  data,
  loading,
  rowCountTable,
  onPrevTableSelected,
  onStatusCheck,
}: TableProps) => {
  const { selectedTableName, selectedSchema } = nameTableAndSchema;
  const onCheckboxClick = useCallback(
    (tableName: string, columnName: string) => {
      let tableFound = false;

      const newColumns = columnTableSelected.reduce<SelectedColumn[]>((acc, column) => {
        if (column.listId === tableName) {
          tableFound = true;
          const columnsArray = column.columns || [];
          const isColumnSelected = columnsArray.includes(columnName);
          const columns = isColumnSelected ? columnsArray.filter((col) => col !== columnName) : [...columnsArray, columnName];

          if (columns.length === 0) {
            onPrevTableSelected(null);
          }

          if (columns.length > 0) {
            acc.push({ ...column, columns });
          }
          return acc;
        } else {
          acc.push(column);
          return acc;
        }
      }, []);

      if (!tableFound) {
        const dataSelected: SelectedItemInterface = {
          listId: tableName,
          groupId: selectedSchema || tableName,
          columns: [columnName],
        };

        onPrevTableSelected(dataSelected);

        newColumns.push(dataSelected);
      }

      setColumnTableSelected(newColumns);
    },
    [setColumnTableSelected, onPrevTableSelected, selectedSchema, columnTableSelected],
  );

  const isCheckedField = useCallback(
    (fieldName: string): boolean => {
      const table = columnTableSelected.find((column) => column.listId === selectedTableName);
      if (!table || !table.columns) {
        return false;
      }

      return table.columns.includes(fieldName);
    },
    [columnTableSelected, selectedTableName],
  );

  const getCheckedStatus = useCallback(
    (tableName: string, columnName: string) => {
      const tableForCheck = columnTableSelected.find((el) => el.listId === tableName);

      if (!tableForCheck || !data?.meta) {
        return 'secondary'; // Если таблица не найдена или нет метаданных, считаем, что столбец не выбран
      }

      const { columns } = tableForCheck;

      if (!columns) {
        return 'secondary';
      }

      const isColumnChecked = columns.includes(columnName);
      const areAllColumnsChecked = data.meta.every((metaItem) => columns.includes(metaItem.name));

      const status = isColumnChecked && areAllColumnsChecked ? 'primary' : 'secondary';

      if (onStatusCheck) {
        onStatusCheck(status);
      }

      return status;
    },
    [columnTableSelected, onStatusCheck, data?.meta],
  );

  return (
    <FlexContainer width="100%" margin="24px 0" height="100%">
      <FlexContainer flexDirection="column" width="100%">
        <Container>
          {data && (
            <FlexContainer flexDirection="column" width="100%" padding="0 24px">
              <PrimaryTextParagraph fontWeight={700} lineHeight="24px" fontSize="24px" color={`var(${ColorVarsEnum.Level_1})`}>
                {selectedTableName}
              </PrimaryTextParagraph>
              <FlexContainer margin="16px 0" justifyContent="flex-end">
                <PrimaryTextParagraph fontWeight={400} lineHeight="24px" fontSize="12px" color={`var(${ColorVarsEnum.Level_2})`}>
                  Кол-во строк: {rowCountTable}
                </PrimaryTextParagraph>
              </FlexContainer>
            </FlexContainer>
          )}
          <LoadingOverlay loading={loading} />

          <TableContainer>
            <TableCell>
              <TableRightTable cellPadding={0} cellSpacing={0}>
                <thead>
                  <tr>
                    {data?.meta?.map((item) => {
                      const { name: columnName, type } = item;
                      const checkedStatus = getCheckedStatus(selectedTableName || '', columnName),
                        isChecked = isCheckedField(columnName),
                        checkType = isChecked ? checkedStatus : undefined;
                      return (
                        <th key={columnName}>
                          <FlexContainer alignItems="center" cursor="pointer">
                            <Checkbox
                              onChange={() => onCheckboxClick(selectedTableName || '', columnName)}
                              value={columnName}
                              name={columnName}
                              id={columnName}
                              checked={isChecked}
                              checkType={checkType}
                              label=""
                            />
                            <FlexContainer flexDirection="column">
                              <FlexContainer marginBottom="4px">
                                <Title checked={isChecked}>{columnName}</Title>
                              </FlexContainer>
                              <Subtitle>{type}</Subtitle>
                            </FlexContainer>
                          </FlexContainer>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((row, i) => (
                    <tr key={i}>
                      {data?.meta?.map(({ name }, j: number) => (
                        <TableTD key={i + j} checked={isCheckedField(name)}>
                          {row[name]}
                        </TableTD>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </TableRightTable>
            </TableCell>
          </TableContainer>
        </Container>
      </FlexContainer>
    </FlexContainer>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const TableContainer = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
`;

const TableCell = styled.div`
  display: table-cell;
  overflow-x: auto;
  width: 100%;
  position: relative;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    transform: rotate(90deg);
    top: 20px;
    right: 20px;
    position: absolute;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${`var(${ColorVarsEnum.Level_5})`};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${`var(${ColorVarsEnum.Level_4})`};
  }
`;

const TableRightTable = styled.table`
  border-bottom: 1px solid ${`var(${ColorVarsEnum.Level_4})`};
  border-top: 1px solid ${`var(${ColorVarsEnum.Level_4})`};
  border-collapse: collapse;
  max-height: 501px;

  td,
  th {
    border: none;
    text-align: left;
    height: 32px !important;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    width: max-content;
    text-overflow: ellipsis;
  }

  th {
    color: ${`var(${ColorVarsEnum.Level_1})`};
  }

  tr {
    border-bottom: 1px solid ${`var(${ColorVarsEnum.Level_4})`};
  }

  td {
    padding: 0 10px;
  }
`;

const TableTD = styled.td<IChecked>`
  transition: all 150ms;
  color: ${(props) => (props.checked ? `var(${ColorVarsEnum.Level_1})` : `var(${ColorVarsEnum.Level_2})`)};
`;

interface IChecked {
  checked: boolean;
}

const Title = styled.span<IChecked>`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  color: ${({ checked }) => `var(${checked ? ColorVarsEnum.Level_1 : ColorVarsEnum.Level_2})`};
`;
const Subtitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: var(${ColorVarsEnum.Level_2});
`;
