import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { NoopType } from 'types/global';
import { useEffect } from 'react';
import { loadDraftProjectsAction } from 'store/reducers/adminUsers/actions';
import { getUserDraftProjects } from 'store/reducers/adminUsers/getters';

interface UseUserDraftProjectsListInterface {
  id?: string;
  onClose?: NoopType;
}

export const useUserDraftProjectsList = ({ id, onClose }: UseUserDraftProjectsListInterface) => {
  const dispatch = useAppDispatch();
  const { loading, flowProjectsList } = useSelector(getUserDraftProjects);

  useEffect(
    () => {
      if (id) {
        dispatch(loadDraftProjectsAction({ userId: id }));
        onClose && onClose();
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, onClose],
  );

  return { loading, flowProjectsList };
};
