import { HexAndHLSColorInterface, IdInterface, NameInterface, ThemeColorType } from 'types/store';
import { MapRecordType } from 'types/global';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export interface ThemeColorItemInterface extends NameInterface, HexAndHLSColorInterface {
  cssVariable: ColorVarsEnum;
}

export interface ThemeItemInterface extends IdInterface, NameInterface {
  codeEditorTheme: ThemeColorType;
  elementColors: ThemeColorItemInterface[];
  backgroundColors: ThemeColorItemInterface[];
  subElementColors: ThemeColorItemInterface[];
  subBackgroundColors: ThemeColorItemInterface[];
}

export interface ReturnThemeSettingsByIdInterface {
  data: ThemeItemInterface;
}

export interface PaletteLinkInterface {
  themeId: string;
  paletteId: string;
}

export type ThemeColorsInterface = Record<ColorVarsEnum, string>;

/* Store SLice Type */

export interface ThemesStateInterface {
  themes: MapRecordType<ThemeItemInterface>;
  serverStateOfThemes: MapRecordType<ThemeItemInterface> | null;
  themesLoading: boolean;
  activeThemeId: string | null;
  serverStateOfActiveThemeId: string | null;
  activeThemeLoading: boolean;
  paletteLinks: PaletteLinkInterface[];
  serverStateOfPaletteLinks: PaletteLinkInterface[] | null;
  paletteLinksLoading: boolean;
}

/* Action Types */

export enum ThemesActionsTypes {
  SET_ACTIVE_THEME = 'SET_ACTIVE_THEME',
  ADD_THEME = 'ADD_THEME',
  ADD_THEME_BY_ID = 'ADD_THEME_BY_ID',
  REMOVE_THEME = 'REMOVE_THEME',
  UPDATE_THEME = 'UPDATE_THEME',
  ADD_PALETTE_LINKS = 'ADD_PALETTE_LINKS',
  REMOVE_PALETTE_LINKS = 'REMOVE_PALETTE_LINKS',
  UPDATE_PALETTE_LINKS = 'UPDATE_PALETTE_LINKS',
  LOAD_THEMES = 'LOAD_THEMES',
  LOAD_THEMES_FROM_SNAPSHOT = 'LOAD_THEMES_FROM_SNAPSHOT',
  LOAD_ACTIVE_THEME = 'LOAD_ACTIVE_THEME',
  LOAD_ACTIVE_THEME_FROM_SNAPSHOT = 'LOAD_ACTIVE_THEME_FROM_SNAPSHOT',
  LOAD_PALETTE_LINKS = 'LOAD_PALETTE_LINKS',
  LOAD_PALETTE_LINKS_FROM_SNAPSHOT = 'LOAD_PALETTE_LINKS_FROM_SNAPSHOT',
  CLEAR_THEMES_STORE = 'CLEAR_THEMES_STORE',
}

/* Payloads */

export type AddNewThemePayload = Omit<ThemeItemInterface, 'name'> & Partial<Pick<ThemeItemInterface, 'name'>>;

export interface ReturnNewThemeInterface extends ReturnThemeSettingsByIdInterface, IdInterface {}
