import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { FlexContainer } from 'styles/FlexContainer';
import { Checkbox, Select, TextField } from 'modules/ui';
import Snackbar from 'services/Snackbar';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { UserCallback } from 'components/admin/usersPage/Forms/UserForm/types';
import { getUsers } from 'store/reducers/adminUsers/getters';
import {
  addUserAction,
  changeActiveUserAction,
  createUserAction,
  updateUserAction,
  updateUsersAction,
} from 'store/reducers/adminUsers/actions';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { Form } from 'components/admin/usersPage/Modal/CreateUserModal/styles';
import { NoopType, ReactChildrenType } from 'types/global';
import { UserInfo } from 'store/reducers/adminUsers/types';
import { roleItems } from 'constants/constants';
import { ModeForm } from 'types/store';

interface UserFormProps {
  mode: ModeForm;
  onClose?: NoopType;
  footer?: ReactChildrenType;
  data?: UserInfo | null;
}

export const UserForm = ({ onClose, footer, mode, data }: UserFormProps) => {
  const dispatch = useAppDispatch();
  const { usersList } = useSelector(getUsers);

  const [loading, setLoading] = useState(false);

  const hasName = useCallback((login: string) => usersList.find((obj) => obj.login === login), [usersList]);
  const isAdd = mode === 'add';

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm<UserCallback>({
    defaultValues: {
      role: 'admin',
    },
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (data) {
      const { login, password, isLicense, role } = data;

      setValue('isLicense', isLicense);
      setValue('login', login);
      setValue('role', role);
      setValue('password', password);
    }
  }, [data, setValue]);

  const onSubmit: SubmitHandler<UserCallback> = useCallback(
    async ({ isLicense, login, role, password }) => {
      if (!isDirty) {
        return null;
      }

      setLoading(true);

      let actionUserRes;
      try {
        if (isAdd && password) {
          if (hasName(login)) {
            setLoading(false);

            return Snackbar.show('Пользователь с таким именем уже существует', 'error');
          }
          actionUserRes = await dispatch(createUserAction({ login, role, password, isLicense })).unwrap();

          if (actionUserRes) {
            const { id, login } = actionUserRes;
            dispatch(addUserAction({ id, login: actionUserRes.login }));
            dispatch(changeActiveUserAction({ id, login }));
          }
        }

        if (!isAdd && data?.id) {
          actionUserRes = await dispatch(
            updateUserAction({ userId: data?.id, role, ...(password ? { password } : {}), isLicense }),
          ).unwrap();

          if (actionUserRes) {
            const { id, login } = actionUserRes;
            dispatch(updateUsersAction({ user: { id, login } }));
          }
        }

        if (!actionUserRes) {
          setLoading(false);
          return;
        }

        if (onClose) {
          onClose();
        }
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.id, isDirty, hasName, isAdd, onClose],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {isAdd && <LoadingOverlay loading={loading} />}

      <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" padding="24px" height="100%">
        <FlexContainer width="100%" flexDirection="column" height="100%" gap="18px">
          <Controller
            name="login"
            rules={{ required: true }}
            control={control}
            render={({ field }) => <TextField error={!!errors.login} label="Логин" width="100%" {...field} disabled={!isAdd} />}
          />
          <Controller
            name="password"
            rules={{ required: isAdd }}
            control={control}
            render={({ field }) => (
              <TextField type="password" label="Пароль" width="100%" {...field} error={!!errors.password && isAdd} />
            )}
          />
          <Controller
            render={({ field }) => (
              <Select title="Роль" options={roleItems} width="100%" placeholder="СУБД" {...field} value={field.value} />
            )}
            name="role"
            control={control}
          />
          <Controller
            name="isLicense"
            control={control}
            render={({ field }) => (
              <Checkbox
                padding="0"
                name="isLicense"
                checked={field.value}
                label="Выдать лицензию"
                id="license"
                onChange={field.onChange}
              />
            )}
          />
          {footer}
        </FlexContainer>
      </FlexContainer>
    </Form>
  );
};
