import { HeaderItemInterface } from 'modules/ui/menu/HeaderMenu/types';
import { LoadRoute } from 'constants/Routes';

export const menuItemsConsole: HeaderItemInterface<LoadRoute>[] = [
  {
    key: 'console',
    name: 'Скрипт загрузки',
  },
  {
    key: 'model',
    name: 'Модель данных',
  },
];
