import {
  ModelControls,
  ModelItemContent,
  ModelItemRelations,
  TablePreview,
} from 'components/contents/Models/ModelItemControls/styles';
import { FlexContainer } from 'styles/FlexContainer';
import { TitledCombobox } from 'modules/ui/blocks/TitledCombobox';
import { TitledSelector } from 'modules/ui/blocks/TitledSelector';
import { MOCK_JOIN_TYPE, MOCK_OPERATION_TYPE } from 'constants/Mock';
import { Button } from 'modules/ui';
import { AddIcon, DeleteIcon } from 'assets/icons/withContainer';
import CircularProgress from '@mui/material/CircularProgress';
import { TablePreview as Table } from 'components/contents/Models/TablePreview';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getActiveModelItemAlias,
  getColumnsByAlias,
  getModelItemDataByAlias,
  getTableAliasesByAlias,
  getTablePreviewDataByName,
} from 'store/reducers/models/getters';
import { loadTablePreviewByNameAction } from 'store/reducers/models/actions';
import { ProjectIdInterface } from 'types/store';
import { ISelectItem } from 'modules/ui/Select';
import { JoinTypeControlValueInterface, RelationControlValueInterface } from 'components/contents/Models/ModelItemControls/types';
import { NoopType, NoopValueType, OnValueChange } from 'types/global';
import { defaultJoinTypeValue, defaultRelationValue } from 'components/contents/Models/ModelItemControls/constants';
import { Relation } from 'models/model/Relation';
import { useModelTab } from 'modules/models/hooks/modelTab';

interface RelationControlProps {
  titles: {
    left: string;
    operator: string;
    right: string;
  };
  options: {
    left: ISelectItem[];
    right: ISelectItem[];
  };
  onSave?: NoopType;
  onDelete?: NoopType;
  value: RelationControlValueInterface;
  onChange?: NoopValueType<RelationControlValueInterface>;
}

const RelationControl = ({ titles, options, value, onChange, onDelete, onSave }: RelationControlProps) => {
  const { leftColumn, operator, rightColumn } = value;

  const disableAddButton = !leftColumn || !operator || !rightColumn;

  return (
    <FlexContainer alignItems="flex-end" gap="8px">
      <TitledCombobox
        disabled={!onChange}
        title={titles.left}
        needBackground
        name="left_column"
        placeholder="Выберите столбец"
        options={options.left}
        width="160px"
        value={leftColumn}
        onChange={(leftColumn) => onChange && onChange({ ...value, leftColumn })}
      />
      <TitledSelector
        disabled={!onChange}
        title={titles.operator}
        needBackground
        name="operator"
        placeholder="Выберите оператор"
        options={MOCK_OPERATION_TYPE}
        width="65px"
        value={operator}
        onChange={(operator) => onChange && onChange({ ...value, operator })}
      />
      <TitledCombobox
        disabled={!onChange}
        title={titles.right}
        needBackground
        name="right_column"
        placeholder="Выберите столбец"
        options={options.right}
        width="160px"
        value={rightColumn}
        onChange={(rightColumn) => onChange && onChange({ ...value, rightColumn })}
      />
      <Button onClick={onDelete} leftIcon={<DeleteIcon />} />
      {onSave && <Button text="OK" onClick={onSave} disabled={disableAddButton} />}
    </FlexContainer>
  );
};

interface JoinTypeControlProps extends OnValueChange<JoinTypeControlValueInterface> {
  onAdd: NoopType;
  tablesOptions: ISelectItem[];
  alreadyAdd: boolean;
}

const JoinTypeControl = ({ tablesOptions, onChange, value, onAdd, alreadyAdd }: JoinTypeControlProps) => {
  const { joinType, alias } = value;

  const disableAddButton = !joinType || !alias;

  return (
    <FlexContainer justifyContent="space-between" gap="30px">
      <FlexContainer flexDirection="column" gap="10px">
        <TitledSelector
          title="Таблица"
          needBackground
          name="table"
          placeholder="Выберите таблицу"
          options={tablesOptions}
          width="160px"
          value={alias}
          onChange={(alias) => onChange({ ...value, alias })}
        />
        <TitledSelector
          title="Вид JOIN"
          needBackground
          name="join"
          placeholder="Выберите тип связи"
          options={MOCK_JOIN_TYPE}
          width="160px"
          value={joinType}
          onChange={(joinType) => onChange({ ...value, joinType })}
        />
      </FlexContainer>
      {!alreadyAdd && (
        <FlexContainer margin="10px 0 0 0 ">
          <Button leftIcon={<AddIcon />} text="Связать" disabled={disableAddButton} onClick={onAdd} />
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

type ModelItemControlsProps = ProjectIdInterface;

export const ModelItemControls = ({ projectId }: ModelItemControlsProps) => {
  const { dispatch, updaterTab } = useModelTab();

  const [joinTypeValue, setJoinTypeValue] = useState<JoinTypeControlValueInterface>(defaultJoinTypeValue);
  const [isActiveNewRelation, setIsActiveNewRelation] = useState(false);
  const [relationValue, setRelationValue] = useState<RelationControlValueInterface>(defaultRelationValue);

  const activeModelItemAlias = useSelector(getActiveModelItemAlias);

  const tableAliases = useSelector(getTableAliasesByAlias(activeModelItemAlias));

  const { tablePreview, tablePreviewLoading } = useSelector(getTablePreviewDataByName(activeModelItemAlias?.table || ''));

  const leftTableColumns = useSelector(getColumnsByAlias(activeModelItemAlias?.alias || '')),
    rightTableColumns = useSelector(getColumnsByAlias(joinTypeValue.alias || ''));

  const {
      model: leftModel,
      modelItem: leftModelItem,
      relations: leftRelations,
    } = useSelector(getModelItemDataByAlias(activeModelItemAlias?.alias || '')),
    { model: rightModel, modelItem: rightModelItem } = useSelector(getModelItemDataByAlias(joinTypeValue.alias || ''));

  const newRelation = useMemo(() => {
    if (
      joinTypeValue.joinType &&
      relationValue.leftColumn &&
      activeModelItemAlias?.alias &&
      joinTypeValue.alias &&
      relationValue.rightColumn
    ) {
      return new Relation({
        type: { type: joinTypeValue.joinType, mode: 'JOIN' },
        link: {
          left: {
            table: activeModelItemAlias.alias,
            column: relationValue.leftColumn.value,
            db: null,
          },
          right: {
            table: joinTypeValue.alias,
            column: relationValue.rightColumn.value,
            db: null,
          },
        },
      });
    }

    return null;
  }, [
    joinTypeValue.joinType,
    relationValue.leftColumn,
    activeModelItemAlias?.alias,
    joinTypeValue.alias,
    relationValue.rightColumn,
  ]);

  const setDefaultValues = () => {
    setJoinTypeValue(defaultJoinTypeValue);
    setIsActiveNewRelation(false);
    setRelationValue(defaultRelationValue);
  };

  const addItselfRelation = () => {
    if (newRelation && leftModel) {
      updaterTab(() => {
        leftModel.addRelationByAlias({ alias: activeModelItemAlias?.alias || '', relation: newRelation });
        setDefaultValues();
      });
    }
  };

  const unionModels = () => {
    if (newRelation && leftModel && rightModel) {
      updaterTab((activeTab) => {
        const filteredModels = activeTab?.models?.filter((model) => model !== leftModel && model !== rightModel) || [];

        const unitedModel = leftModel.unionWith({ model: rightModel, relation: newRelation });

        setDefaultValues();

        return [...filteredModels, unitedModel];
      });
    }
  };

  const addRelationToModel = () => {
    if (newRelation && leftModel && leftModel === rightModel) {
      updaterTab(() => {
        leftModel.addRelationByAlias({ alias: leftModelItem?.alias || '', relation: newRelation });

        setDefaultValues();
      });
    }
  };

  const onAddRelation = () => {
    if (activeModelItemAlias?.alias === joinTypeValue.alias) {
      addItselfRelation();
      return;
    }

    if (leftModel !== rightModel && rightModelItem?.isHead) {
      unionModels();
      return;
    }

    addRelationToModel();
  };

  const onDeleteNewRelation = () => {
    setDefaultValues();
  };

  useEffect(() => {
    if (activeModelItemAlias) {
      dispatch(loadTablePreviewByNameAction({ projectId, table: activeModelItemAlias.table }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModelItemAlias]);

  useEffect(() => {
    setDefaultValues();
  }, [activeModelItemAlias]);

  return (
    <ModelItemContent>
      <ModelControls>
        {activeModelItemAlias ? (
          <>
            {!!leftRelations.length && (
              <ModelItemRelations isList>
                {leftRelations.map((relation, index) => {
                  const key = `${relation.key}_${index}`,
                    relativeRelation = relation.getRelative(leftModelItem?.alias || ''),
                    leftTable = relativeRelation.link.left,
                    rightTable = relativeRelation.link.right;

                  return (
                    <RelationControl
                      key={key}
                      value={{
                        leftColumn: { name: leftTable.column, value: leftTable.column },
                        operator: defaultRelationValue.operator,
                        rightColumn: { name: rightTable.column, value: rightTable.column },
                      }}
                      titles={{ left: leftTable.table, operator: relation.type.type, right: rightTable.table }}
                      options={{
                        left: [],
                        right: [],
                      }}
                      onDelete={() =>
                        updaterTab((activeTab) => {
                          const filteredModels = activeTab?.models?.filter((currentModel) => currentModel !== leftModel) || [];

                          const newModels = leftModel?.deleteRelation(relation) || [];

                          return [...filteredModels, ...newModels];
                        })
                      }
                    />
                  );
                })}
              </ModelItemRelations>
            )}
            {isActiveNewRelation && (
              <ModelItemRelations>
                <RelationControl
                  value={relationValue}
                  onChange={(relationValue) => setRelationValue(relationValue)}
                  titles={{
                    left: activeModelItemAlias.alias,
                    operator: joinTypeValue.joinType || '',
                    right: joinTypeValue.alias || '',
                  }}
                  options={{
                    left: leftTableColumns.map((name) => ({ name, value: name })),
                    right: rightTableColumns.map((name) => ({ name, value: name })),
                  }}
                  onDelete={onDeleteNewRelation}
                  onSave={onAddRelation}
                />
              </ModelItemRelations>
            )}
            <JoinTypeControl
              onAdd={() => setIsActiveNewRelation(true)}
              value={joinTypeValue}
              onChange={(joinTypeValue) => setJoinTypeValue(joinTypeValue)}
              tablesOptions={tableAliases.map(({ alias }) => ({ name: alias, value: alias }))}
              alreadyAdd={isActiveNewRelation}
            />
          </>
        ) : (
          <FlexContainer padding="20px" width="100%" height="100%" alignItems="center" justifyContent="center">
            <PrimaryTextParagraph textAlign="center" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
              Выберите таблицу для создания связи
            </PrimaryTextParagraph>
          </FlexContainer>
        )}
      </ModelControls>
      <TablePreview>
        {tablePreviewLoading ? (
          <FlexContainer width="100%" height="100%" alignItems="center" justifyContent="center">
            <CircularProgress size="24px" />
          </FlexContainer>
        ) : tablePreview ? (
          <Table data={tablePreview} />
        ) : (
          <FlexContainer padding="20px" width="100%" height="100%" alignItems="center" justifyContent="center">
            <PrimaryTextParagraph textAlign="center" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
              Выберите таблицу для просмотра превью
            </PrimaryTextParagraph>
          </FlexContainer>
        )}
      </TablePreview>
    </ModelItemContent>
  );
};
