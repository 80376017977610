import { ListItem, TableListWrapper } from 'modules/ui/lists/TableList/styles';
import { ListItemType } from 'modules/ui/lists/TableList/types';
import { DNMIcon } from 'assets/icons/withContainer';
import { cssVar, parseToRgb, rgba } from 'polished';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import ColorTheme from 'constants/ColorTheme';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { NoopValueType } from 'types/global';

interface TableListProps {
  list: ListItemType[];
  onItemClick?: NoopValueType<string>;
}

export const TableList = ({ list, onItemClick }: TableListProps) => {
  return (
    <TableListWrapper>
      {list.map(({ key, name, alreadyAdded = false }) => {
        const activeBackgroundColor = rgba({
          ...parseToRgb(cssVar(ColorVarsEnum.Level_1, ColorTheme.default.level_1) as string),
          alpha: 0.03,
        });

        return (
          <ListItem
            isActive={alreadyAdded}
            activeBackgroundColor={activeBackgroundColor}
            key={key}
            onClick={onItemClick ? () => onItemClick(key) : undefined}
          >
            <PrimaryTextParagraph
              whiteSpace="nowrap"
              lineHeight="12px"
              fontSize="12px"
              color={`var(${ColorVarsEnum.Level_1})`}
              overflow="hidden"
              width="80%"
              textOverflow="ellipsis"
            >
              {name}
            </PrimaryTextParagraph>
            {!alreadyAdded && <DNMIcon width="20%" />}
          </ListItem>
        );
      })}
    </TableListWrapper>
  );
};
