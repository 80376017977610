import React, { useEffect, useMemo, useState } from 'react';
import { MultiSelect, MultiSelectType } from 'modules/ui/MultiSelect';
import { Button, Select } from 'modules/ui';
import { Text } from './styles';
import { loadUsersGroupsAction } from 'store/reducers/projectManager/actions';
import { useAppDispatch } from 'store';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { FlexContainer } from 'styles/FlexContainer';
import { accessSourceEditSelect, accessIcons, accessItems, defaultSelectedAccess } from '../constants';
import { useSelector } from 'react-redux';
import { ModalComponentType } from 'store/reducers/modals/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { UsersGroupsInterface } from 'store/reducers/projectManager/types';
import { GroupType } from 'types/store';
import { AddUsersGroupsSourceAccessPayload } from 'types/types';
import { addUsersGroupsSourceAccessAction, loadSourceUsersAndGroupsAction } from 'store/reducers/sources/actions';
import { getSourceUsersGroups } from 'store/reducers/sources/getters';

type AddAccessModalProps = {
  activeSourceId: string;
};

export const AddAccessModal: ModalComponentType<AddAccessModalProps> = ({ onClose, activeSourceId }) => {
  const dispatch = useAppDispatch();

  const { loading, usersGroupsList } = useSelector(getSourceUsersGroups);

  const [selectedValue, setSelectedValue] = useState<MultiSelectType<UsersGroupsInterface>[]>([]);
  const [selectedAccess, setSelectedAccess] = useState<GroupType>(defaultSelectedAccess);

  const options = useMemo(
    () => usersGroupsList.map((el) => ({ title: el.name, value: el.id, icon: accessIcons[el.entity], data: el })),
    [usersGroupsList],
  );

  useEffect(() => {
    if (activeSourceId) {
      dispatch(loadUsersGroupsAction({ sourceId: activeSourceId }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSourceId]);

  const onAdd = async () => {
    if (!selectedValue?.length) {
      return onClose();
    }

    try {
      const params: AddUsersGroupsSourceAccessPayload = {
        type: selectedAccess,
        sourceId: activeSourceId || '',
        users: [],
        groups: [],
      };

      selectedValue.forEach((usersGroup) => {
        const userOrGroup = usersGroup.data as UsersGroupsInterface;

        const entityMapping = {
          group: params.groups,
          user: params.users,
        };

        entityMapping[userOrGroup.entity]?.push(userOrGroup.id);
      });

      const response = await dispatch(addUsersGroupsSourceAccessAction(params)).unwrap();

      if (response && activeSourceId) {
        dispatch(loadSourceUsersAndGroupsAction({ id: activeSourceId }));
      }
    } catch (error) {
      /* TODO: Error output in the future */
      console.error(error);
    } finally {
      onClose();
    }
  };

  return (
    <FlexContainer position="relative" width="100%" flexDirection="column" padding="4px" gap="16px" height="100%">
      <LoadingOverlay loading={loading} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />

      <FlexContainer flexDirection="column">
        <Text>Права</Text>
        <Select
          needBackground={false}
          width="100%"
          name={accessSourceEditSelect}
          value={selectedAccess}
          onChange={setSelectedAccess}
          options={accessItems}
        />
      </FlexContainer>

      <FlexContainer flexDirection="column">
        <Text>Пользователю/группе</Text>
        <MultiSelect noOptionsText="Пользователи/группы не найдены" options={options} onChange={setSelectedValue} />
      </FlexContainer>

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Добавить" iconSize="normal" heightSize="normal" onClick={onAdd} />
      </FlexContainer>
    </FlexContainer>
  );
};
