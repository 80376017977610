import { useAppDispatch } from 'store';
import { closeModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { ModalInfo } from 'store/reducers/modals/types';
import { FlowListInterface } from 'store/reducers/projectManager/types';
import { NoopValueType } from 'types/global';
import { AvatarProjectInterface } from 'types/types';

export interface ModalProps {
  projectId: string;
  projectName: string;
  flowId: string;
  activeFlowId: string;
  isProtected?: boolean;
  activeProject?: FlowListInterface;
  isDraftFlow?: boolean;
  onChangeAvatar?: NoopValueType<AvatarProjectInterface>;
}
export const useModalActions = <T extends string>(
  modalConfigs: Record<T, Omit<ModalInfo<ModalProps>, 'name' | 'componentProps'>>,
) => {
  const dispatch = useAppDispatch();

  const openModal = (modalName: T, props: ModalProps) => {
    const config = modalConfigs[modalName];
    if (config) {
      const { Component, modalSettings } = config;

      dispatch(
        openModalTypedAction({
          Component,
          componentProps: { ...props, onClose: () => closeModal(modalName) },
          modalSettings,
          name: modalName,
        }),
      );
    }
  };

  const closeModal = (modalName: T) => {
    dispatch(closeModalAction(modalName));
  };

  return { openModal, closeModal };
};
