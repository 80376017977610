import { CircularProgress } from '@mui/material';

export const LoaderVisualizationBlock = () => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 32,
        height: 32,
        borderRadius: '50%',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      }}
    >
      <CircularProgress size={24} />
    </div>
  );
};
