import { Button, Select } from 'modules/ui';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import {
  changeGroupSourceAccessTypeAction,
  changeUserSourceAccessTypeAction,
  updateSourceAccessByIdAction,
} from 'store/reducers/adminSources/actions';
import { getAdminActiveSource } from 'store/reducers/adminSources/getters';
import { ModalComponentType } from 'store/reducers/modals/types';
import { FlexContainer } from 'styles/FlexContainer';
import { GroupType } from 'types/store';
import { AccessInterface } from 'types/types';
import { accessEditSelect, accessItems } from '../constants';
import { AccessName, Text } from './styles';

interface EditAccessModalProps {
  sourceAccess: AccessInterface;
}

export const EditAccessModal: ModalComponentType<EditAccessModalProps> = ({ sourceAccess, onClose }) => {
  const dispatch = useAppDispatch();

  const activeSource = useSelector(getAdminActiveSource);

  const [type, setType] = useState<GroupType>(sourceAccess.type);

  const activeSourceId = activeSource?.id;
  const sourceAccessId = sourceAccess?.id;

  const onEdit = useCallback(
    async () => {
      try {
        if (activeSourceId) {
          const response =
            sourceAccess.entity === 'group'
              ? await dispatch(
                  changeGroupSourceAccessTypeAction({ sourceId: activeSourceId, type, groupId: sourceAccessId }),
                ).unwrap()
              : await dispatch(
                  changeUserSourceAccessTypeAction({ sourceId: activeSourceId, type, userId: sourceAccessId }),
                ).unwrap();

          if (response) {
            dispatch(updateSourceAccessByIdAction({ sourceAccess: { ...sourceAccess, type } }));
          }
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      } finally {
        onClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeSourceId, sourceAccess, type, sourceAccessId, onClose],
  );

  return (
    <FlexContainer width="100%" flexDirection="column" padding="4px" gap="16px" height="100%">
      <FlexContainer flexDirection="column">
        <Text>Группа/пользователь</Text>
        <AccessName>{sourceAccess.name}</AccessName>
      </FlexContainer>

      <FlexContainer flexDirection="column">
        <Text>Права</Text>
        <Select
          needBackground={false}
          width="100%"
          name={accessEditSelect}
          value={type}
          onChange={setType}
          options={accessItems}
        />
      </FlexContainer>

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Изменить" iconSize="normal" heightSize="normal" onClick={onEdit} />
      </FlexContainer>
    </FlexContainer>
  );
};
