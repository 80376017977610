import isArray from 'lodash/isArray';
import { CronDateTypes, CronDay, CronHour, CronMonth, CronSchedule, CronWeek } from 'types/types';
import { CronTypeEnum, ScheduleType } from './types';

export const timeLineRadioOptions = [
  { value: CronTypeEnum.HOUR, label: 'Ежечасно' },
  { value: CronTypeEnum.DAY, label: 'Ежедневно' },
  { value: CronTypeEnum.WEEK, label: 'Еженедельно' },
  { value: CronTypeEnum.MONTH, label: 'Ежемесячно' },
  { value: CronTypeEnum.CRON, label: 'CRON' },
];

export const weekPickerOptions = [
  { value: 1, label: 'ПН' },
  { value: 2, label: 'ВТ' },
  { value: 3, label: 'СР' },
  { value: 4, label: 'ЧТ' },
  { value: 5, label: 'ПТ' },
  { value: 6, label: 'СБ' },
  { value: 7, label: 'ВС' },
];

export const monthPickerOptions = Array.from({ length: 31 }).map((_, i) => ({ value: i + 1, label: `${i + 1}` }));

export const scheduleInit = {
  minutes: 0,
  hours: 0,
  days: 0,
  schedule: '',
};

export const formattingValueTime = (value: string) => {
  let totalValue = value;

  if (totalValue.length === 3 && !totalValue.includes(':')) {
    totalValue = value.replace(/(\d{1,})(\d{1})/, '$1:$2');
  }

  const [hoursInString, minutesInString] = (totalValue || '').split(':');
  const hours = isNaN(+hoursInString) ? 0 : +hoursInString <= 23 ? hoursInString : 23;
  const minute = isNaN(+minutesInString) ? 0 : +minutesInString <= 59 ? minutesInString : 59;

  return `${hours}${minute ? `:${minute}` : ''}`;
};

export const getDefaultCronDate = (value: CronDateTypes) => {
  switch (value.type) {
    case CronTypeEnum.HOUR:
      return { minutes: value.minutes, hours: value.hours, days: 0, schedule: '' };
    case CronTypeEnum.DAY:
      return { minutes: value.minutes, hours: value.hours, days: value.days, schedule: '' };
    case CronTypeEnum.WEEK:
      return { minutes: value.minutes, hours: value.hours, days: value.days, schedule: '' };
    case CronTypeEnum.MONTH:
      return { minutes: value.minutes, hours: value.hours, days: value.days, schedule: '' };
    case CronTypeEnum.CRON:
      return { minutes: 0, hours: 0, days: 0, schedule: value.schedule };
    default:
      return scheduleInit;
  }
};

export const getCronDate = {
  hour: (cron: ScheduleType): CronHour => ({ type: CronTypeEnum.HOUR, minutes: cron.minutes, hours: cron.hours }),
  day: (cron: ScheduleType): CronDay => ({
    type: CronTypeEnum.DAY,
    minutes: cron.minutes,
    hours: cron.hours,
    days: isArray(cron.days) ? cron.days[0] : cron.days,
  }),
  week: (cron: ScheduleType): CronWeek => ({
    type: CronTypeEnum.WEEK,
    minutes: cron.minutes,
    hours: cron.hours,
    days: !isArray(cron.days) ? [] : cron.days,
  }),
  month: (cron: ScheduleType): CronMonth => ({
    type: CronTypeEnum.MONTH,
    minutes: cron.minutes,
    hours: cron.hours,
    days: !isArray(cron.days) ? [] : cron.days,
  }),
  cron: (cron: ScheduleType): CronSchedule => ({
    type: CronTypeEnum.CRON,
    schedule: cron.schedule ? cron.schedule : '',
  }),
};

const getDayLabel = (dayValue: number): string => {
  const day = weekPickerOptions.find((option) => option.value === dayValue);
  return day ? day.label : '';
};

export const formattingCronDataToDate = {
  hour: (cron: CronHour): string => `Каждый ${cron.hours > 1 ? `${cron.hours}-й ` : ''}час на ${cron.minutes}-й минуте`,
  day: (cron: CronDay): string =>
    `Каждый ${!isArray(cron.days) && cron.days > 1 ? `${cron.days}-й ` : ''}день в ${cron.hours}:${cron.minutes}`,
  week: (cron: CronWeek): string =>
    `Каждый ${isArray(cron.days) ? `${cron.days.map(getDayLabel).join(', ')} ` : getDayLabel(cron.days)}в ${cron.hours}:${
      cron.minutes
    }`,
  month: (cron: CronMonth): string =>
    `Каждое ${isArray(cron.days) ? cron.days.map((day) => `${day}-е `).join(', ') : `${cron.days}-е `}число каждого месяца`,
  cron: (cron: CronSchedule): string => cron.schedule || '',
};

export const formatCron = (cron: CronDateTypes): string => {
  switch (cron.type) {
    case 'hour':
      return formattingCronDataToDate.hour(cron);
    case 'day':
      return formattingCronDataToDate.day(cron);
    case 'week':
      return formattingCronDataToDate.week(cron);
    case 'month':
      return formattingCronDataToDate.month(cron);
    case 'cron':
      return formattingCronDataToDate.cron(cron);
    default:
      return '';
  }
};
