import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { ColorAndImageByInterfaceType } from 'store/reducers/visualisations/types';

export const conditionDefault: SettingsRadioItem<ColorAndImageByInterfaceType> = {
  value: 'default',
  label: 'Вручную',
};

export const conditionValue: SettingsRadioItem<ColorAndImageByInterfaceType> = {
  value: 'value',
  label: 'По значению',
};

export const conditionByСondition: SettingsRadioItem<ColorAndImageByInterfaceType> = {
  value: 'condition',
  label: 'По условию',
};

export const conditionIncision = [conditionDefault, conditionByСondition];

export const conditionIndicator = [conditionDefault, conditionValue, conditionByСondition];
