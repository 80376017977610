import { EventsSettingsInterface } from 'store/reducers/visualisations/types';
import { useNavigate, useParams } from 'react-router-dom';
import { goToBoardUrl, ProjectIdParam } from 'constants/Routes';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRole } from 'utils/hooks/useRole';
import { getProjectSettings } from 'store/reducers/projectSettings/getters';
import { addProtocolToLink } from 'utils/utils';

export const useVisualisationEvents = ({ goTo, goToHref }: EventsSettingsInterface) => {
  const navigate = useNavigate();
  const { projectId } = useParams<ProjectIdParam>();
  const { isViewMode } = useSelector(getProjectSettings);
  const { isViewer } = useRole();

  const isAllowClick = isViewMode || isViewer;
  const isActiveClick = (goTo.isActive || goToHref.isActive) && isAllowClick;

  const onGoToClick = useCallback(() => {
    if (goTo.isActive && goTo.to?.type === 'page' && goTo.to?.id) {
      navigate(goToBoardUrl(projectId, goTo.to.id));
    }
  }, [goTo, navigate, projectId]);

  const onGoToHrefClick = useCallback(() => {
    if (goToHref.isActive && goToHref.to !== '' && goToHref.to) {
      window.open(addProtocolToLink(goToHref.to), `_${goToHref.openWindowType}`, 'noreferrer');
    }
  }, [goToHref]);

  const onClick = useMemo(
    () =>
      isActiveClick
        ? () => {
            onGoToClick();
            onGoToHrefClick();
          }
        : undefined,
    [isActiveClick, onGoToClick, onGoToHrefClick],
  );

  return { onClick };
};
