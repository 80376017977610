import { OnValueChange } from 'types/global';
import { LimitSettingInterface } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import React from 'react';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';

type LimitSettingsProps = OnValueChange<LimitSettingInterface> & { title?: string; width?: string };

export const LimitSettings = ({ value, onChange, title, width }: LimitSettingsProps) => {
  const { value: limit, isActive } = value;

  return (
    <MainContainerSettings
      titleText={title || 'Лимит'}
      switcherState={isActive}
      switcherChange={() => onChange({ ...value, isActive: !isActive })}
    >
      <GroupContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Значение"
            name="limit"
            value={limit}
            width={width}
            debounced
            onChange={(limit) => onChange({ ...value, value: limit })}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
