import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { AccessGroupIcon, AccessUserIcon } from 'assets/icons/access';

const iconStyles = css`
  svg,
  path {
    stroke: var(${ColorVarsEnum.Level_3});
  }
  height: 20px;
  width: 20px;
`;

export const GroupSvg = styled(AccessGroupIcon)`
  ${iconStyles}
`;

export const UserSvg = styled(AccessUserIcon)`
  ${iconStyles}
`;
