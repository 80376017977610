import { OnValueChange } from 'types/global';
import { MinAndMaxInterface } from 'store/reducers/visualisations/types';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';

type MinAndMaxSettingsProps = OnValueChange<MinAndMaxInterface>;

export const MinAndMaxSettings = ({ title, value, onChange }: MinAndMaxSettingsProps & { title: string }) => {
  const { isShow, min, max } = value;

  return (
    <MainContainerSettings
      titleText={title}
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
    >
      <GroupContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            width="150"
            properties="Минимальное"
            name="min"
            value={min}
            onChange={(min) => onChange({ ...value, min })}
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            width="150"
            properties="Максимальное"
            name="min"
            value={max}
            onChange={(max) => onChange({ ...value, max })}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
