import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ColorCanvas, GradientBarWrapper } from 'modules/ui/colors/GradientBar/styles';
import { canvasFiller } from 'modules/ui/colors/GradientBar/constants';
import { getGradientInterpolator } from 'utils/utils';

interface GradientBarProps {
  colors: string[];
}

export const GradientBar = ({ colors }: GradientBarProps) => {
  const [componentSizes, setComponentSizes] = useState({ width: 0, height: 0 });
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const componentRef = useRef<HTMLDivElement | null>(null),
    componentWidth = componentRef.current?.clientWidth || 0,
    componentHeight = componentRef.current?.clientHeight || 0;

  const colorInterpolate = getGradientInterpolator({ colors });

  useEffect(() => {
    canvasFiller(canvas.current, colorInterpolate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colors, colorInterpolate, canvas.current]);

  useLayoutEffect(() => {
    setComponentSizes({ width: componentWidth, height: componentHeight });
  }, [componentWidth, componentHeight, colors]);

  return (
    <GradientBarWrapper ref={componentRef}>
      <ColorCanvas width={componentSizes.width} height={componentSizes.height} ref={canvas} />
    </GradientBarWrapper>
  );
};
