import { createSelector } from 'reselect';
import { getState } from 'store/utils';

export const getAdminLicensesStore = createSelector(getState, (state) => state.adminLicenses);

export const getAdminLicense = createSelector(getAdminLicensesStore, (state) => state.adminLicense);

export const getAdminLicenseInfo = createSelector(getAdminLicense, (state) => state.adminLicenseData?.adminInfo);

export const getAdminLicenseUsers = createSelector(getAdminLicense, (state) => state.adminLicenseData?.users);

export const getAdminLicenseMeta = createSelector(getAdminLicense, (state) => state.adminLicenseData?.meta);
