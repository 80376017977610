import {
  GroupByMaskOfDayType,
  GroupByMaskOfOtherType,
  GroupByMaskOfYearType,
  GroupByType,
} from 'store/reducers/visualisations/types';
import { SettingsSelectItemGroupByDate } from 'modules/settingsContainer/common/data/GroupByDateSettings/types';

export const GROUP_BY_TIME_INTERVAL: SettingsSelectItemGroupByDate<GroupByType>[] = [
  { name: 'По дням', value: 'day' },
  { name: 'По неделям', value: 'dayOfWeek' },
  { name: 'По месяцам', value: 'month' },
  { name: 'По кварталам', value: 'quarter' },
  { name: 'По году', value: 'year' },
];

export const GROUP_BY_DAY_MASK: SettingsSelectItemGroupByDate<GroupByMaskOfDayType>[] = [
  { name: 'DD.MM.YYYY', value: '%d.%m.%Y' },
  { name: 'MM/DD/YYYY', value: '%m/%d/%Y' },
  { name: 'YYYY.MM.DD', value: '%Y.%m.%d' },
];

export const GROUP_BY_YEAR_MASK: SettingsSelectItemGroupByDate<GroupByMaskOfYearType>[] = [
  { name: 'YYYY', value: '%Y' },
  { name: 'YY', value: '%y' },
];

export const GROUP_BY_LONG_OR_SHORT: SettingsSelectItemGroupByDate<GroupByMaskOfOtherType>[] = [
  { name: 'Полное', value: 'long' },
  { name: 'Сокращенное', value: 'short' },
];

export const getGroupByMaskByType = (groupByType: GroupByType) => {
  return groupByType === 'day' ? GROUP_BY_DAY_MASK : groupByType === 'year' ? GROUP_BY_YEAR_MASK : GROUP_BY_LONG_OR_SHORT;
};

export const getDefaultGroupByMaskByType = (groupByType: GroupByType) => {
  return groupByType === 'day'
    ? GROUP_BY_DAY_MASK[0].value
    : groupByType === 'year'
    ? GROUP_BY_YEAR_MASK[0].value
    : GROUP_BY_LONG_OR_SHORT[0].value;
};
