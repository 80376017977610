import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSliceFn } from 'constants/store';
import { initialProjectManagerStoreState } from './constants';
import {
  loadDraftFlowAction,
  loadFlowAccessAction,
  loadFlowAction,
  loadFlowDraftInfoAction,
  loadFlowProjectHistoryVersionAction,
  loadFlowsAction,
  loadUsersGroupsAction,
} from 'store/reducers/projectManager/actions';
import { IdInterface } from 'types/store';
import {
  ChangeActiveFlowPayload,
  FlowAccessInterface,
  FlowListInterface,
  FlowsListInterface,
  SortProject,
} from 'store/reducers/projectManager/types';

export const projectManagerSlice = createSlice({
  name: 'projectManager',
  initialState: initialProjectManagerStoreState,
  reducers: {
    addFlow: (state, { payload: data }: PayloadAction<FlowsListInterface>) => {
      state.flows.flowsList = [data, ...state.flows.flowsList];
    },
    addFlowProject: (state, { payload: data }: PayloadAction<FlowListInterface>) => {
      state.draftFlow.flowProjectsList = [data, ...state.draftFlow.flowProjectsList];
    },
    changeActiveFlowId: (state, { payload }: PayloadAction<ChangeActiveFlowPayload>) => {
      state.activeFlow = payload;
    },
    changeShortProject: (state, { payload }: PayloadAction<SortProject>) => {
      state.shortProject = payload;
    },
    updateFlows: (state, { payload: flow }: PayloadAction<FlowsListInterface[]>) => {
      state.flows.flowsList = flow;
    },
    updateFlowProject: (state, { payload: flow }: PayloadAction<FlowListInterface[]>) => {
      state.flow.flowProjectsList = flow;
    },
    updateDraftFlowProject: (state, { payload: flow }: PayloadAction<FlowListInterface[]>) => {
      state.draftFlow.flowProjectsList = flow;
    },
    updateFlowAccess: (state, { payload: access }: PayloadAction<FlowAccessInterface[]>) => {
      state.flowAccesses.flowAccessList = access;
    },
    deleteByIdFlowAccess: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.flowAccesses.flowAccessList = state.flowAccesses.flowAccessList.filter((page) => page.id !== id);
    },
    deleteByIdFlow: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.flows.flowsList = state.flows.flowsList.filter((page) => page.id !== id);
    },
    deleteByIdFlowProject: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.flow.flowProjectsList = state.flow.flowProjectsList.filter((project) => project.id !== id);
    },
    deleteByIdDraftProject: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.draftFlow.flowProjectsList = state.draftFlow.flowProjectsList.filter((page) => page.id !== id);
    },
    setActiveFlowProjectId: (state, { payload: activeProjectId }: PayloadAction<string | null>) => {
      state.activeProjectId = activeProjectId;
    },
    setSlice: setSliceFn,
  },
  extraReducers: (builder) => {
    builder.addCase(loadFlowsAction.pending, (state) => {
      state.flows = { ...state.flows, loading: true };
      state.flows.flowsList = [];
    });
    builder.addCase(loadFlowsAction.fulfilled, (state, { payload }) => {
      state.flows = { ...state.flows, loading: false };
      state.flows.flowsList = payload;
    });
    builder.addCase(loadFlowsAction.rejected, (state) => {
      state.flows = { ...state.flows, loading: false };
      state.flows.flowsList = [];
    });

    builder.addCase(loadFlowAction.pending, (state) => {
      state.flow = { ...state.flow, loading: true };
      state.flow.flowProjectsList = [];
    });
    builder.addCase(loadFlowAction.fulfilled, (state, { payload }) => {
      state.flow = { ...state.flow, loading: false };
      state.flow.flowProjectsList = payload;
    });
    builder.addCase(loadFlowAction.rejected, (state) => {
      state.flow = { ...state.flow, loading: false };
      state.flow.flowProjectsList = [];
    });

    builder.addCase(loadDraftFlowAction.pending, (state) => {
      state.draftFlow.loading = true;
      state.draftFlow.flowProjectsList = [];
    });
    builder.addCase(loadDraftFlowAction.fulfilled, (state, { payload }) => {
      state.draftFlow.loading = false;
      state.draftFlow.flowProjectsList = payload;
    });
    builder.addCase(loadDraftFlowAction.rejected, (state) => {
      state.draftFlow.loading = false;
      state.draftFlow.flowProjectsList = [];
    });

    builder.addCase(loadFlowProjectHistoryVersionAction.pending, (state) => {
      state.flowProjectHistoryVersion.loading = true;
      state.flowProjectHistoryVersion.flowProjectHistoryVersionList = [];
    });
    builder.addCase(loadFlowProjectHistoryVersionAction.fulfilled, (state, { payload }) => {
      state.flowProjectHistoryVersion.loading = false;
      state.flowProjectHistoryVersion.flowProjectHistoryVersionList = payload;
    });
    builder.addCase(loadFlowProjectHistoryVersionAction.rejected, (state) => {
      state.flowProjectHistoryVersion.loading = false;
      state.flowProjectHistoryVersion.flowProjectHistoryVersionList = [];
    });

    builder.addCase(loadFlowDraftInfoAction.pending, (state) => {
      state.flowDraftInfo = null;
    });
    builder.addCase(loadFlowDraftInfoAction.fulfilled, (state, { payload }) => {
      state.flowDraftInfo = payload;
    });
    builder.addCase(loadFlowDraftInfoAction.rejected, (state) => {
      state.flowDraftInfo = null;
    });

    builder.addCase(loadFlowAccessAction.pending, (state) => {
      state.flowAccesses.loading = true;
      state.flowAccesses.flowAccessList = [];
    });
    builder.addCase(loadFlowAccessAction.fulfilled, (state, { payload }) => {
      state.flowAccesses.loading = false;
      state.flowAccesses.flowAccessList = payload;
    });
    builder.addCase(loadFlowAccessAction.rejected, (state) => {
      state.flowAccesses.loading = false;
      state.flowAccesses.flowAccessList = [];
    });

    builder.addCase(loadUsersGroupsAction.pending, (state) => {
      state.usersGroupsList.loading = true;
      state.usersGroupsList.usersGroupsList = [];
    });
    builder.addCase(loadUsersGroupsAction.fulfilled, (state, { payload }) => {
      state.usersGroupsList.loading = false;
      state.usersGroupsList.usersGroupsList = payload;
    });
    builder.addCase(loadUsersGroupsAction.rejected, (state) => {
      state.usersGroupsList.loading = false;
      state.usersGroupsList.usersGroupsList = [];
    });
  },
});

export const {
  changeActiveFlowId,
  deleteByIdFlow,
  deleteByIdFlowProject,
  deleteByIdDraftProject,
  deleteByIdFlowAccess,
  updateFlows,
  updateDraftFlowProject,
  updateFlowProject,
  updateFlowAccess,
  setActiveFlowProjectId,
  addFlow,
  addFlowProject,
  changeShortProject,
  setSlice,
} = projectManagerSlice.actions;

export default projectManagerSlice.reducer;
