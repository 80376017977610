import { VersionStrategyFormType } from './types';

export type ProjectVersionsStorageType = 'time' | 'items';

export interface ErrorResult {
  value: keyof VersionStrategyFormType | '';
  message: string;
}

const isInteger = (num: number): boolean => Number.isInteger(num);

const validateCount = (count: number): ErrorResult | null => {
  count = Number(count);
  const validations = [
    { isValid: () => !count, message: 'Поле не может быть пустым' },
    { isValid: () => count === 0, message: 'Количество версий не может быть нулевым' },
    { isValid: () => count < 0, message: 'Количество версий не может быть отрицательным' },
    { isValid: () => !isInteger(count), message: 'Количество версий не может быть дробным' },
    { isValid: () => count > 9999999, message: 'Количество версий не может быть больше 9999999' },
  ];

  for (const validation of validations) {
    if (validation.isValid()) {
      return { value: 'count', message: validation.message };
    }
  }
  return null;
};

const validateTime = (ageVersion: number): ErrorResult | null => {
  if (!ageVersion) {
    return { value: 'ageVersion', message: 'Поле не может быть пустым' };
  }
  return null;
};

const validationRules = {
  time: validateTime,
  items: validateCount,
};

export const handleVersionStrategyFieldError = (
  type: ProjectVersionsStorageType,
  ageVersion: number,
  count: number,
): ErrorResult => {
  const errorResult = validationRules[type](type === 'time' ? ageVersion : count);
  return errorResult ?? { value: '', message: '' };
};
