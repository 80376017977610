import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { LineAndBarIndicatorType, LineType } from 'store/reducers/visualisations/types';

export const elementTypeOptions: SettingsRadioItem<LineAndBarIndicatorType>[] = [
  { value: 'bar', label: 'Столбик' },
  { value: 'line', label: 'Линия' },
];

export const lineTypeOptions: SettingsRadioItem<LineType>[] = [
  { value: 'broken', label: 'Ломанная' },
  { value: 'smooth', label: 'Сглаженная' },
  { value: 'stepByStep', label: 'Шагом' },
];
