import { FC, useEffect } from 'react';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { VersionStrategyForm } from 'components/admin/settings/elements/System/Forms/VersionStrategyForm';
import { AdminSettingsList } from 'components/AdminSettingsList';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { AdminSettingsItemType } from 'components/AdminSettingsList/types';
import { loadVersionStrategyAction } from 'store/reducers/adminSettings/actions';
import { getAdminSettingsVersionStrategy } from 'store/reducers/adminSettings/getters';

export const System: FC = () => {
  const dispatch = useAppDispatch();

  const { loading } = useSelector(getAdminSettingsVersionStrategy);

  useEffect(() => {
    dispatch(loadVersionStrategyAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settingsList: AdminSettingsItemType[] = [
    { title: 'Стратегия хранения версий проектов', component: <VersionStrategyForm /> },
  ];

  return (
    <>
      <LoadingOverlay loading={loading} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />

      {!loading && <AdminSettingsList settingsList={settingsList} />}
    </>
  );
};
