import React from 'react';
import { TextSettingInterface } from 'store/reducers/visualisations/types';
import { updateViewSettingsAction } from 'store/reducers/visualisations/actions';
import { useAppDispatch } from 'store';
import { TextSettingsControlInterface } from 'modules/settingsContainer/common/TextSettingsGroup/types';
import { TextSettingsGroup } from 'modules/settingsContainer/common/TextSettingsGroup';

type DescriptionSettingsProps = TextSettingsControlInterface;

export const DescriptionSettings = ({ value, onChange: onExternalChange }: DescriptionSettingsProps) => {
  const dispatch = useAppDispatch();

  const onLocalChange = (description: TextSettingInterface) => {
    dispatch(updateViewSettingsAction({ description }));
  };

  const onChange = onExternalChange || onLocalChange;

  return <TextSettingsGroup value={value} onChange={onChange} titleText="Дополнительный" />;
};
