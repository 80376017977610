import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FlexContainer } from 'styles/FlexContainer';
import React, { memo, useCallback, useRef } from 'react';
import { IconType } from 'assets/icons/withContainer';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { useModalState } from 'utils/hooks/modalState';
import Popper from '@mui/material/Popper/Popper';
import { NoopType } from 'types/global';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { isEqualProps } from 'utils/utils';
import { LoaderBlock } from 'shared/ui/Loader';

export interface BoardMenuItemProps {
  Icon: IconType;
  placement?: 'right-start' | 'left-start';
  ModalData?: (props: { onClose: NoopType }) => JSX.Element;
  onClick?: NoopType;
  closeOnClickAway?: boolean;
  loading?: boolean;
  disabled?: boolean;
  modalDataWidth?: string;
  id?: string;
  externalModalState?: {
    onClose: NoopType;
    onOpen: NoopType;
    isOpen: boolean;
  };
}

export const BoardMenuItemComponent = ({
  Icon,
  placement = 'right-start',
  ModalData,
  onClick,
  closeOnClickAway = true,
  modalDataWidth,
  id,
  externalModalState,
  loading,
  disabled,
}: BoardMenuItemProps) => {
  const { onClose, isOpen, onOpen } = useModalState();

  const onLocalClose = externalModalState ? externalModalState.onClose : onClose;
  const onLocalOpen = externalModalState ? externalModalState.onOpen : onOpen;
  const localIsOpen = externalModalState ? externalModalState.isOpen : isOpen;

  const anchorEl = useRef<HTMLDivElement>(null);

  const onItemClick = useCallback(() => {
    if (ModalData) {
      localIsOpen ? onLocalClose() : onLocalOpen();
    }

    onClick && onClick();
  }, [ModalData, localIsOpen, onLocalClose, onLocalOpen, onClick]);

  const onClickAway = useCallback(() => {
    closeOnClickAway && onLocalClose();
  }, [closeOnClickAway, onLocalClose]);

  return (
    <>
      <FlexContainer
        backgroundColor={localIsOpen ? `var(${ColorVarsEnum.Level_3_menu})` : ''}
        width="100%"
        height="56px"
        justifyContent="center"
        alignItems="center"
        cursor={disabled ? 'not-allowed' : 'pointer'}
        onClick={!disabled ? onItemClick : undefined}
        ref={anchorEl}
        id={id}
        position="relative"
      >
        {loading ? (
          <FlexContainer left="0" top="0" position="absolute" width="100%" height="100%">
            <LoaderBlock size="small" />
          </FlexContainer>
        ) : (
          <IconWrapper
            cursor={disabled ? 'not-allowed' : 'pointer'}
            iconHeight="23px"
            iconWidth="23px"
            containerWidth="100%"
            containerHeight="100%"
            Icon={Icon}
          />
        )}
      </FlexContainer>
      {ModalData && (
        <Popper
          style={{ zIndex: 100, width: modalDataWidth }}
          open={localIsOpen}
          anchorEl={anchorEl.current}
          popperOptions={{ placement, strategy: 'fixed' }}
        >
          <ClickAwayListener onClickAway={onClickAway}>
            <FlexContainer>
              <ModalData onClose={onLocalClose} />
            </FlexContainer>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
};

export const BoardMenuItem = memo(BoardMenuItemComponent, isEqualProps);
