import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export interface SettingsFieldWrapperProps {
  isActive?: boolean;
  isSelected?: boolean;
}

export const SettingsFieldWrapper = styled.div<SettingsFieldWrapperProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 150ms;
  border: 1px solid var(${ColorVarsEnum.Level_3_menu});

  :hover {
    border: 1px solid var(${ColorVarsEnum.Level_5});
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid var(${ColorVarsEnum.Level_5});
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid var(${ColorVarsEnum.Accent});
      :hover {
        border: 1px solid var(${ColorVarsEnum.Accent});
      }
    `}
`;
