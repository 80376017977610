import {
  MetricAstMapDataType,
  PositionedGenericType,
  UsedMetricsType,
} from 'modules/settingsContainer/common/data/SqlSettings/types';
import {
  getColumnsWithoutSelect,
  getFieldNameFromColumn,
  getSelectColumnsFromSqlString,
  isBasicColumn,
  isBasicFunctionColumn,
} from 'utils/SQL/genereteAst';
import { AST } from 'types/ast';
import { Changing } from 'types/changing';
import { IncisionsAstType, IndicatorAstType } from 'store/reducers/ast/types';
import { VisualisationOperationTypesType } from 'store/reducers/visualisations/types';

export const codeEditorTitle = 'Редактор Кода';

export const generateMetricAstMapData = <T extends { as?: string | null }>(astData: T[]) =>
  astData.reduce<MetricAstMapDataType<T>>(
    (result, ast, position) => (ast?.as ? { ...result, [ast.as]: { ...ast, position } } : result),
    {},
  );

export const getDeletedMetrics = <T extends { as?: string | null }>({
  metricsAstMapData,
  usedAlias,
}: {
  metricsAstMapData: MetricAstMapDataType<T>;
  usedAlias: UsedMetricsType;
}) =>
  Object.values(metricsAstMapData).reduce<Changing.Delete[]>((result, value) => {
    const alias = value?.as;

    return alias && !usedAlias[alias] ? [...result, { type: 'delete', alias }] : result;
  }, []);

/* `Incisions` */

/* any from node-sql-parser */
export const getDifferenceBetweenIncisions = (
  oldIncisions: PositionedGenericType<IncisionsAstType>,
  newIncisions: PositionedGenericType<any>,
) => {
  const oldIsBasicColumn = isBasicColumn(oldIncisions),
    newIsBasicColumn = isBasicColumn(newIncisions),
    order =
      oldIncisions.position !== newIncisions.position ? { from: oldIncisions.position, to: newIncisions.position } : undefined,
    alias = oldIncisions?.as,
    commonReturnParams = { type: 'change', alias, order };

  const oldCustomRequest = getColumnsWithoutSelect([{ ...oldIncisions, as: null }]),
    newCustomRequest = getColumnsWithoutSelect([{ ...newIncisions, as: null }]);

  if (newIsBasicColumn) {
    /* New has Basic Column Structure */
    const newFieldName = getFieldNameFromColumn((newIncisions as AST.BasicColumn).expr);

    if (oldIsBasicColumn) {
      /* Both has Basic Column Structure */
      const oldFieldName = getFieldNameFromColumn((oldIncisions as AST.BasicColumn).expr);

      return {
        ...commonReturnParams,
        fieldName: oldFieldName !== newFieldName ? { from: oldFieldName, to: newFieldName } : undefined,
      } as Changing.ChangeIncision;
    }

    /* Only New has Basic Column Structure */
    return {
      ...commonReturnParams,
      fieldName: { from: '', to: newFieldName },
      customRequest: {
        from: oldCustomRequest,
        to: null,
      },
    } as Changing.ChangeIncision;
  }

  if (oldIsBasicColumn) {
    /* Only old has Basic Column Structure */
    return {
      ...commonReturnParams,
      customRequest: {
        from: null,
        to: newCustomRequest,
      },
    } as Changing.ChangeIncision;
  }

  /* Both was with Custom Request  */
  return {
    ...commonReturnParams,
    customRequest:
      oldCustomRequest !== newCustomRequest
        ? {
            from: oldCustomRequest,
            to: newCustomRequest,
          }
        : undefined,
  } as Changing.ChangeIncision;
};

/* any from node-sql-parser */
export const getNewIncisionChanges: (newIncisions: any) => Changing.CreateIncision = (newIncisions) => {
  const alias = newIncisions?.as as string | null;

  return isBasicColumn(newIncisions)
    ? {
        type: 'create',
        alias,
        fieldName: getFieldNameFromColumn((newIncisions as AST.BasicColumn).expr),
        customRequest: null,
      }
    : {
        type: 'create',
        alias,
        fieldName: null,
        customRequest: getColumnsWithoutSelect([{ ...newIncisions, as: null }]),
      };
};

type IncisionsChangesFunctionType = (params: {
  incisionsAstMapData: MetricAstMapDataType<IncisionsAstType>;
  incisionRequest?: string;
}) => Changing.IncisionActionType[];

export const getIncisionsChanges: IncisionsChangesFunctionType = ({ incisionsAstMapData, incisionRequest }) => {
  const incisionAstParsed = getSelectColumnsFromSqlString(incisionRequest);

  let usedAlias: UsedMetricsType = {};

  const incisionChanging: Changing.IncisionActionType[] = incisionAstParsed.map((newIncision, position) => {
    const alias = newIncision?.as as string | null,
      existIncision = incisionsAstMapData[alias || ''];

    if (alias && usedAlias[alias]) {
      return { type: 'pass' };
    }

    if (alias) {
      usedAlias = { ...usedAlias, [alias]: true };
    }

    if (existIncision) {
      return getDifferenceBetweenIncisions(existIncision, { ...newIncision, position });
    }

    return getNewIncisionChanges(newIncision);
  });

  const deletedIncisions = getDeletedMetrics({ metricsAstMapData: incisionsAstMapData, usedAlias });

  return [...incisionChanging, ...deletedIncisions];
};

/* Indicators */

/* any from node-sql-parser */
export const getDifferenceBetweenIndicators = (
  oldIndicators: PositionedGenericType<IndicatorAstType>,
  newIndicators: PositionedGenericType<any>,
) => {
  const oldIsBasicFunctionColumn = isBasicFunctionColumn(oldIndicators),
    newIsBasicFunctionColumn = isBasicFunctionColumn(newIndicators),
    order =
      oldIndicators.position !== newIndicators.position
        ? { from: oldIndicators.position, to: newIndicators.position }
        : undefined,
    alias = oldIndicators?.as,
    commonReturnParams = { type: 'change', alias, order };

  const oldCustomRequest = getColumnsWithoutSelect([{ ...oldIndicators, as: null }]),
    newCustomRequest = getColumnsWithoutSelect([{ ...newIndicators, as: null }]);

  if (newIsBasicFunctionColumn) {
    /* New has Basic Function Column Structure */
    const {
        expr: {
          name,
          args: { expr },
        },
      } = newIndicators as AST.BasicFunctionColumn,
      newFieldName = getFieldNameFromColumn(expr),
      newOperationType = name.toLowerCase();

    if (oldIsBasicFunctionColumn) {
      /* Both has Basic Function Column Structure */
      const {
          expr: {
            name,
            args: { expr },
          },
        } = oldIndicators as AST.BasicFunctionColumn,
        oldFieldName = getFieldNameFromColumn(expr),
        oldOperationType = name.toLowerCase();

      return {
        ...commonReturnParams,
        fieldName: oldFieldName !== newFieldName ? { from: oldFieldName, to: newFieldName } : undefined,
        operationType: oldOperationType !== newOperationType ? { from: oldOperationType, to: newOperationType } : undefined,
      } as Changing.ChangeIndicator;
    }

    /* Only New has Basic Function Column Structure */
    return {
      ...commonReturnParams,
      fieldName: { from: '', to: newFieldName },
      operationType: { from: 'other', to: newOperationType },
      customRequest: {
        from: oldCustomRequest,
        to: null,
      },
    } as Changing.ChangeIndicator;
  }

  if (oldIsBasicFunctionColumn) {
    /* Only old has Basic Function Column Structure */
    const oldOperationType = (oldIndicators as AST.BasicFunctionColumn).expr.name.toLowerCase();

    return {
      ...commonReturnParams,
      customRequest: {
        from: null,
        to: newCustomRequest,
      },
      operationType: { from: oldOperationType, to: 'other' },
    } as Changing.ChangeIndicator;
  }

  /* Both was with Custom Request  */
  return {
    ...commonReturnParams,
    customRequest:
      oldCustomRequest !== newCustomRequest
        ? {
            from: oldCustomRequest,
            to: newCustomRequest,
          }
        : undefined,
  } as Changing.ChangeIndicator;
};

/* any from node-sql-parser */
export const getNewIndicatorChanges: (newIndicator: any) => Changing.CreateIndicator = (newIndicator) => {
  const alias = newIndicator?.as as string | null;

  if (isBasicFunctionColumn(newIndicator)) {
    const {
      expr: {
        name,
        args: { expr },
      },
    } = newIndicator as AST.BasicFunctionColumn;

    return {
      type: 'create',
      alias,
      fieldName: getFieldNameFromColumn(expr),
      operationType: name.toLowerCase() as VisualisationOperationTypesType,
      customRequest: null,
    };
  }

  return {
    type: 'create',
    alias,
    fieldName: null,
    operationType: 'other',
    customRequest: getColumnsWithoutSelect([{ ...newIndicator, as: null }]),
  };
};

type IndicatorsChangesFunctionType = (params: {
  indicatorsAstMapData: MetricAstMapDataType<IndicatorAstType>;
  indicatorRequest?: string;
}) => Changing.IndicatorActionType[];

export const getIndicatorsChanges: IndicatorsChangesFunctionType = ({ indicatorsAstMapData, indicatorRequest }) => {
  const indicatorAstParsed = getSelectColumnsFromSqlString(indicatorRequest);

  let usedAlias: UsedMetricsType = {};

  const indicatorsChanging: Changing.IndicatorActionType[] = indicatorAstParsed.map((newIndicator, position) => {
    const alias = newIndicator?.as as string | null,
      existIndicator = indicatorsAstMapData[alias || ''];

    if (alias && usedAlias[alias]) {
      return { type: 'pass' };
    }

    if (alias) {
      usedAlias = { ...usedAlias, [alias]: true };
    }

    if (existIndicator) {
      return getDifferenceBetweenIndicators(existIndicator, { ...newIndicator, position });
    }

    return getNewIndicatorChanges(newIndicator);
  });

  const deletedIndicators = getDeletedMetrics({ metricsAstMapData: indicatorsAstMapData, usedAlias });

  return [...indicatorsChanging, ...deletedIndicators];
};

/* Service */

/* any from node-sql-parser */
export const getDifferenceBetweenService = (
  oldService: PositionedGenericType<AST.ColumnFromParser>,
  newService: PositionedGenericType<AST.ColumnFromParser>,
) => {
  const order = oldService.position !== newService.position ? { from: oldService.position, to: newService.position } : undefined,
    alias = oldService?.as,
    commonReturnParams = { type: 'change', alias, order };

  const oldCustomRequest = getColumnsWithoutSelect([{ ...oldService, as: null }]),
    newCustomRequest = getColumnsWithoutSelect([{ ...newService, as: null }]);

  return {
    ...commonReturnParams,
    customRequest:
      oldCustomRequest !== newCustomRequest
        ? {
            from: oldCustomRequest,
            to: newCustomRequest,
          }
        : undefined,
  } as Changing.ChangeService;
};

/* any from node-sql-parser */
export const getNewServiceChanges: (newService: AST.ColumnFromParser) => Changing.CreateService = (newService) => {
  const alias = newService?.as as string | null;

  return {
    type: 'create',
    alias,
    customRequest: getColumnsWithoutSelect([{ ...newService, as: null }]),
  };
};

type ServiceChangesFunctionType = (params: {
  servicesAstMapData: MetricAstMapDataType<AST.ColumnFromParser>;
  serviceRequest?: string;
}) => Changing.ServiceActionType[];

export const getServiceChanges: ServiceChangesFunctionType = ({ servicesAstMapData, serviceRequest }) => {
  const serviceAstParsed = getSelectColumnsFromSqlString(serviceRequest);

  let usedAlias: UsedMetricsType = {};

  const servicesChanging: Changing.ServiceActionType[] = serviceAstParsed.map((newService, position) => {
    const alias = newService?.as as string | null,
      existService = servicesAstMapData[alias || ''];

    if (alias && usedAlias[alias]) {
      return { type: 'pass' };
    }

    if (alias) {
      usedAlias = { ...usedAlias, [alias]: true };
    }

    if (existService) {
      return getDifferenceBetweenService(existService, { ...newService, position });
    }

    return getNewServiceChanges(newService);
  });

  const deletedIndicators = getDeletedMetrics({ metricsAstMapData: servicesAstMapData, usedAlias });

  return [...servicesChanging, ...deletedIndicators];
};
