import { FC, NoopType, OnValueChange } from 'types/global';
import { getConditionOptions } from 'modules/settingsContainer/common/ColorBySettings/constants';
import React, { useMemo, useRef } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button } from 'modules/ui';
import { ColorByInterface, DefaultDataSettingsInterface } from 'store/reducers/visualisations/types';
import { WrapperBySettings, WrapperByTabsType } from 'modules/settingsContainer/common/WrapperBySettings';
import { ConditionEditorField } from 'modules/settingsContainer/common/ConditionEditorField';
import { FixedAreaSettings } from 'modules/settingsContainer/common/FixedAreaSettings';
import { useModalState } from 'utils/hooks/modalState';
import { ColorByCondition } from '../ColorByCondition';
import { ColorByValue } from 'modules/settingsContainer/common/ColorByValue';

export interface ColorBySettingsProps
  extends Partial<Pick<DefaultDataSettingsInterface, 'indicators'>>,
    OnValueChange<ColorByInterface> {
  isMainContainerSettings?: boolean;
  disabledComponentByValue?: boolean;
  disabledComponentByCondition?: boolean;
  title?: string;
  titleMainContainerText?: string;
  showBottomLine?: boolean;
  switcherColorBy?: {
    switcherChangeColorBy?: NoopType;
    switcherStateColorBy?: boolean;
  };
}

export const ColorBySettings: FC<ColorBySettingsProps> = ({
  disabledComponentByValue,
  disabledComponentByCondition,
  value,
  onChange,
  indicators,
  children,
  isMainContainerSettings = false,
  title,
  titleMainContainerText,
  showBottomLine,
  switcherColorBy,
}) => {
  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  const { isOpen, onOpen, onClose } = useModalState();

  const usingByValue = useMemo(() => !disabledComponentByValue, [disabledComponentByValue]);
  const usingByCondition = useMemo(() => !disabledComponentByCondition, [disabledComponentByCondition]);
  const conditionOptions = useMemo(() => getConditionOptions(usingByValue, usingByCondition), [usingByValue, usingByCondition]);

  const colorByTabs: WrapperByTabsType = {
    condition: {
      Activator: () =>
        usingByCondition ? <ConditionEditorField value={value.byCondition.sqlCondition || ''} onOpenChange={onOpen} /> : <>/</>,
      Settings: () =>
        usingByCondition ? (
          <FixedAreaSettings
            onClose={onClose}
            open={isOpen}
            headerText={settingsText || ''}
            width={width}
            minWidth={minWidth}
            anchorEl={mainContainerRef.current}
          >
            <ColorByCondition metric={value.byCondition} onChange={(byCondition) => onChange({ ...value, byCondition })} />
          </FixedAreaSettings>
        ) : (
          <></>
        ),
      settingsText: 'Изменить условие',
      width: '565px',
    },
    value: {
      Activator: () => (
        <FlexContainer width="100%" alignItems="center" justifyContent="center">
          <Button text="Настроить" onClick={onOpen} />
        </FlexContainer>
      ),
      Settings: () =>
        usingByValue ? (
          <FixedAreaSettings
            onClose={onClose}
            open={isOpen}
            headerText={settingsText || ''}
            width={width}
            minWidth={minWidth}
            anchorEl={mainContainerRef.current}
          >
            <ColorByValue
              metric={value.byValue}
              onChange={(byValue) => onChange({ ...value, byValue })}
              indicators={indicators}
            />
          </FixedAreaSettings>
        ) : (
          <></>
        ),
      settingsText: 'Изменить диапазон',
      minWidth: '180px',
    },
    default: {
      Activator: () => <>{children}</>,
    },
  };

  const { width, minWidth, settingsText } = colorByTabs[value.type];

  return (
    <WrapperBySettings
      mainContainerRef={mainContainerRef}
      colorByTabs={colorByTabs}
      conditionOptions={conditionOptions}
      value={value}
      isMainContainerSettings={isMainContainerSettings}
      title={title}
      titleMainContainerText={titleMainContainerText}
      onChange={onChange}
      showBottomLine={showBottomLine}
      switcherColorBy={switcherColorBy}
    />
  );
};
