import { TRenderByValueType } from 'modules/settingsContainer/common/WrapperBySettings/types';
import { ColorByValueInterface } from 'store/reducers/visualisations/types';
import { useLocalValues } from 'utils/hooks/localValues';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { ColorValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { FlexContainer } from 'styles/FlexContainer';
import { getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { GradientBar } from 'modules/ui/colors/GradientBar';
import { SaveCancelButtons } from 'modules/ui/blocks/SaveCancelButtons';
import React from 'react';
import { addColorByValueMarginTop, gradientLineHeight, gradientLineMarginBottom } from './constants';
import { ColorByPicker } from 'modules/ui/ColorByPicker';
import { AddColorButton } from 'modules/ui/buttons/AddColorButton';

export const ColorByValue: TRenderByValueType<ColorByValueInterface> = ({ metric: value, onChange, indicators }) => {
  const { localValues, setLocalValues, onSave, onCancel, hasChanges } = useLocalValues({ value, onChange });

  const { getColorValues, defaultColor } = useColorValues();

  const { colors } = localValues;

  const getColorsValueByIndicatorId = (indicatorId: string) => colors?.[indicatorId] || [];

  const onAddColor = (indicatorId: string) => () =>
    setLocalValues((localValues) => {
      const currentColorValue = getColorsValueByIndicatorId(indicatorId),
        newColorsValue = [
          ...currentColorValue,
          {
            value: null,
          },
        ];

      return {
        ...localValues,
        colors: {
          ...colors,
          [indicatorId]: newColorsValue,
        },
      };
    });

  const onDeleteColor = (deletedIndex: number, indicatorId: string) => () =>
    setLocalValues((localValues) => {
      const newColorsValue = getColorsValueByIndicatorId(indicatorId).filter((_, index) => index !== deletedIndex);

      return {
        ...localValues,
        colors: {
          ...colors,
          [indicatorId]: newColorsValue,
        },
      };
    });

  const onChangeColor = (colorIndex: number, indicatorId: string) => (colorValue: ColorValuesByThemeType | null) =>
    setLocalValues((localValues) => {
      const newColorsValue = getColorsValueByIndicatorId(indicatorId).map((color, index) =>
        index === colorIndex ? { ...color, value: colorValue } : color,
      );

      return {
        ...localValues,
        colors: {
          ...colors,
          [indicatorId]: newColorsValue,
        },
      };
    });

  return (
    <>
      <GroupContainerSettings>
        <ElementContainerSettings>
          <FlexContainer maxHeight="500px" overflow="auto" padding="24px 8px 10px" width="100%" flexDirection="column" gap="20px">
            {indicators?.map(({ id: indicatorId, name, fieldName, settings: { nameFromDatabase } }) => {
              const indicatorName = getVisualisationFieldName({ name, fieldName, nameFromDatabase }),
                colorsValue = getColorsValueByIndicatorId(indicatorId),
                gradientColors = colorsValue.map(({ value }) => getColorValues(value) || defaultColor);

              return (
                <FlexContainer key={indicatorId} width="100%" flexDirection="column" gap="8px">
                  <PrimaryTextSpan lineHeight="12px" fontSize="12px" color={`var(${ColorVarsEnum.Level_1})`}>
                    {indicatorName}
                  </PrimaryTextSpan>
                  <FlexContainer>
                    {!!colorsValue.length && (
                      <FlexContainer marginRight="8px" flexDirection="column">
                        <FlexContainer marginBottom={gradientLineMarginBottom} width="100%" height={gradientLineHeight}>
                          <GradientBar colors={gradientColors} />
                        </FlexContainer>
                        <FlexContainer gap="12px">
                          {colorsValue.map(({ value }, index) => (
                            <ColorByPicker
                              onDelete={onDeleteColor(index, indicatorId)}
                              onChange={onChangeColor(index, indicatorId)}
                              value={value}
                              key={index}
                            />
                          ))}
                        </FlexContainer>
                      </FlexContainer>
                    )}
                    <FlexContainer margin={`${addColorByValueMarginTop} 0 0 0`}>
                      <AddColorButton onAdd={onAddColor(indicatorId)} />
                    </FlexContainer>
                  </FlexContainer>
                </FlexContainer>
              );
            })}
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
      <GroupContainerSettings>
        <ElementContainerSettings>
          <FlexContainer padding="0 10px 10px 2px" flexDirection="column" width="100%">
            <FlexContainer justifyContent="flex-end" margin="10px 8px 0 0">
              <SaveCancelButtons onCancel={onCancel} onSave={onSave} hasChanges={hasChanges} />
            </FlexContainer>
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
    </>
  );
};
