import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import React, { ChangeEventHandler } from 'react';
import { TextSettingInterface } from 'store/reducers/visualisations/types';
import { TextArea, TextField } from 'modules/ui';
import { OnValueChange } from 'types/global';

export interface TextSettingsProps extends OnValueChange<TextSettingInterface> {
  titleText: string;
  type?: 'text' | 'textarea';
  withoutSwitcher?: boolean;
}

export const TextSettings = ({
  value: { isShow, text },
  titleText,
  onChange,
  type = 'text',
  withoutSwitcher,
}: TextSettingsProps) => {
  const textProps: { value: string; onChange: ChangeEventHandler<HTMLInputElement> } = {
    value: text,
    onChange: (e) => onChange({ isShow, text: e.target.value }),
  };

  return (
    <MainContainerSettings
      titleText={titleText}
      switcherState={isShow}
      switcherChange={withoutSwitcher ? undefined : () => onChange({ isShow: !isShow, text })}
    >
      <GroupContainerSettings>
        <ElementContainerSettings>
          {type === 'text' ? (
            <TextField name="h3_text" width="100%" needBackground={false} needBorderBottom={false} {...textProps} />
          ) : (
            <TextArea
              name="text"
              width="100%"
              needBackground={true}
              needBorderBottom={true}
              placeholder="Текст"
              rows={5}
              {...textProps}
            />
          )}
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
