import { IconType } from 'assets/icons/withContainer';
import { GroupSvg, UserSvg } from './styles';
import { EntityType, GroupType } from 'types/store';

export const accessEdit = 'accessEdit';
export const accessAdd = 'accessAdd';
export const accessSearch = 'accessSearch';
export const accessEditSelect = 'accessEditSelect';

export const accessIcons: Record<EntityType, IconType> = {
  group: GroupSvg,
  user: UserSvg,
};

export const accessText = {
  owner: 'Управление',
  reader: 'Использование',
};

export const accessItems: Array<{ name: string; value: GroupType }> = [
  { name: 'Использование', value: 'reader' },
  { name: 'Управление', value: 'owner' },
];

export const defaultSelectedAccess = accessItems[0].value;
