import React, { FC } from 'react';
import { LoadLayoutAdmin } from 'components/layouts/LoadLayoutAdmin';
import { System } from 'components/admin/settings/elements/System';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { NavigationsTabs } from 'modules/ui/tabs/NavigationsTabs';
import { Tab } from 'modules/ui/tabs/types';
import styled from 'styled-components';

export const AdminSettings: FC = () => {
  const tabs: Tab[] = [
    {
      content: 'Система',
      prompt: 'Система',
      type: 'text',
      id: 'system',
      Component: System,
    },
  ].filter(({ Component }) => Component) as Tab[];

  return (
    <LoadLayoutAdmin>
      <Body>
        <NavigationsTabs
          tabs={tabs}
          width="100%"
          height="100%"
          overflowBodyY="none"
          bgNavigationsTabs={`var(${ColorVarsEnum.Level_5_application})`}
        />
      </Body>
    </LoadLayoutAdmin>
  );
};

const Body = styled.div`
  position: relative;
  max-width: 750px;
  margin: 0 auto;
  padding: 24px 0;
  width: 100%;
  height: 100%;
  background: ${`var(${ColorVarsEnum.Level_5_application})`};
`;
