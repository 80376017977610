import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

// TODO: replace to new mixin
export const SizeSVGWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  path,
  circle {
    stroke: ${`var(${ColorVarsEnum.Level_3})`};
    transition: all 450ms;
  }
`;
