import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ContainerSettingsInterface } from 'types/store';
import { BackgroundSettingsInterface, VisualisationValuesInterface } from 'store/reducers/visualisations/types';
import { useColorBy } from 'modules/visualisations/hooks/colorBy';
import { useMemo } from 'react';

type BackgroundContainerColorsParams = BackgroundSettingsInterface;

export const useBackgroundContainerColors = (backgroundSettings: BackgroundContainerColorsParams) => {
  const { getColorValues, activeThemeSchema } = useColorValues();

  const {
    colorSettings: {
      background: { color: backgroundColor, isShow: isBackgroundShow },
      active: { color: activeBackgroundColor, isShow: isActiveBackgroundShow },
      hover: { color: hoverBackgroundColor, isShow: isHoverBackgroundShow },
    },
  } = backgroundSettings;

  const [backgroundColorHex, activeBackgroundColorHex, hoverBackgroundColorHex] = getColorValues([
    backgroundColor,
    activeBackgroundColor,
    hoverBackgroundColor,
  ]);

  const backgroundColorValue = isBackgroundShow
    ? backgroundColorHex || activeThemeSchema[ColorVarsEnum.Level_4_widget]
    : 'transparent';

  /* TODO: Make logic for isActive param of text visualisation */
  const isActiveState = false;

  const activeBackgroundColorValue = isActiveState && isActiveBackgroundShow ? activeBackgroundColorHex : null;
  const hoverBackgroundColorValue = isHoverBackgroundShow
    ? hoverBackgroundColorHex || activeThemeSchema[ColorVarsEnum.Level_4_widget]
    : null;

  return {
    backgroundColor: backgroundColorValue,
    activeBackgroundColor: activeBackgroundColorValue,
    hoverBackgroundColor: hoverBackgroundColorValue,
  };
};

type ContainerColorsParams = Pick<ContainerSettingsInterface, 'borderSettings' | 'shadowSettings' | 'showBackground'> & {
  visualisationNormalizedValues?: VisualisationValuesInterface;
};

export const useContainerColors = ({
  borderSettings,
  shadowSettings,
  showBackground,
  visualisationNormalizedValues,
}: ContainerColorsParams) => {
  const {
    shadowColorSettings: {
      shadowColorBy,
      shadowColor: { isShow: isShowShadowColor, color: colorShadow },
      hover: { isShow: isShowHover, color: colorHover },
    },
  } = shadowSettings;

  const { getColorValueByCondition, isColorByCondition, getVisualisationColorsAndImagesData } = useColorBy({
    colorsBy: [shadowColorBy],
    visualisationValues: visualisationNormalizedValues,
  });

  const { getColorValues, defaultColor, activeThemeSchema } = useColorValues();

  const [borderColor, shadowColor, hoverColor] = getColorValues([borderSettings.color, colorShadow, colorHover]);

  const shadowColorValue = isShowShadowColor ? shadowColor || defaultColor : 'transparent';

  const hoverShadowColorValue = isShowHover ? hoverColor || defaultColor : null;

  const conditionalTextColorValue = useMemo(() => {
    const alias = shadowColorBy.byCondition.alias,
      colorFontsSQL = getVisualisationColorsAndImagesData(alias)?.[0];

    return shadowColorBy.byCondition.sqlCondition && isColorByCondition(alias)
      ? getColorValueByCondition(alias, colorFontsSQL)
      : undefined;
  }, [
    shadowColorBy.byCondition.alias,
    shadowColorBy.byCondition.sqlCondition,
    getVisualisationColorsAndImagesData,
    isColorByCondition,
    getColorValueByCondition,
  ]);

  return {
    borderColor: borderColor || defaultColor,
    shadowColor: conditionalTextColorValue || shadowColorValue,
    hoverColor: conditionalTextColorValue || hoverShadowColorValue,
    backgroundColor: showBackground ? activeThemeSchema[ColorVarsEnum.Level_4_widget] : 'transparent',
    activeBackgroundColor: null,
    hoverBackgroundColor: null,
  };
};
