import React from 'react';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { TextField } from 'modules/ui';
import { IndicatorEmptyValuesInterface } from 'store/reducers/visualisations/types';
import { NoopValueType } from 'types/global';

interface EmptyValuesElementSettingsProps {
  switcherValue: boolean;
  value: string;
  onChange: NoopValueType<IndicatorEmptyValuesInterface>;
}

export const EmptyValuesElementSettings = ({ value, switcherValue, onChange }: EmptyValuesElementSettingsProps) => {
  return (
    <MainContainerSettings
      titleText="Пустые значения"
      switcherState={switcherValue}
      switcherChange={() => onChange({ value, isEmptyValue: !switcherValue })}
    >
      <TextField
        name="emptyValue"
        value={value}
        width="100%"
        needBackground={false}
        onChange={(e) => onChange({ isEmptyValue: switcherValue, value: e.target.value })}
      />
    </MainContainerSettings>
  );
};
