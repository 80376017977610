import { ElementContainerSettings, GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import { Select } from 'modules/ui';
import { GroupByDateInterface, GroupByMaskGeneric, GroupByType, GroupByTypeGeneric } from 'store/reducers/visualisations/types';
import { FlexContainer } from 'styles/FlexContainer';
import { OnValueChange } from 'types/global';
import {
  getDefaultGroupByMaskByType,
  getGroupByMaskByType,
  GROUP_BY_TIME_INTERVAL,
} from 'modules/settingsContainer/common/data/GroupByDateSettings/constants';

export const GroupByDateSettings = ({ value, onChange }: OnValueChange<GroupByDateInterface<GroupByType>>) => {
  const { isShow, groupByType, groupByMask } = value;

  return (
    <MainContainerSettings
      titleText="Группировка по дате"
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
    >
      <GroupContainerSettings>
        <ElementContainerSettings>
          <FlexContainer gap="16px" width="100%">
            <Select
              name="symbol_select"
              value={groupByType}
              onChange={(groupByType: GroupByTypeGeneric) =>
                onChange({ ...value, groupByType, groupByMask: getDefaultGroupByMaskByType(groupByType) })
              }
              options={GROUP_BY_TIME_INTERVAL}
              needBackground={false}
              heightSize="normal"
              width="100%"
            />
            <Select
              name="symbol_select"
              value={groupByMask}
              onChange={(groupByMask: GroupByMaskGeneric) => onChange({ ...value, groupByMask })}
              options={getGroupByMaskByType(groupByType)}
              needBackground={false}
              heightSize="normal"
              width="100%"
            />
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
