import { BarTypeInterface } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import React from 'react';
import { typeOptions } from 'modules/settingsContainer/common/data/TypeOfLineAndBarSettings/constants';
import { Button } from 'modules/ui';
import { FlexContainer } from 'styles/FlexContainer';

type TypeOfLineAndBarSettingsProps = OnValueChange<BarTypeInterface> & { disabled?: boolean };

export const TypeOfLineAndBarSettings = ({ value, onChange, disabled = false }: TypeOfLineAndBarSettingsProps) => {
  const options = typeOptions(disabled);

  return (
    <MainContainerSettings titleText="Тип представления">
      <SettingsRadio
        onChange={({ value: type }) => onChange({ ...value, type })}
        activeValue={getActiveRadioValue(options, value.type)}
        options={options}
      />
      {value.type === 'pile' && (
        <FlexContainer flexDirection="row" alignItems="center" gap="8px">
          <Button
            text="Объединить столбцы"
            needBackground={true}
            heightSize="small"
            onClick={() => onChange({ ...value, activePile: true })}
          />
          <Button
            text="Разъединить столбцы"
            needBackground={true}
            heightSize="small"
            onClick={() => onChange({ ...value, activePile: false })}
          />
        </FlexContainer>
      )}
    </MainContainerSettings>
  );
};
