import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { pieIndicatorFormatValueOptions } from 'modules/settingsContainer/common/data/PieFormatValueSettings/constants';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { OnValueChange } from 'types/global';
import { PieIndicatorValueFormatType } from 'store/reducers/visualisations/types';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';

type PieValueFormatSettingsProps = OnValueChange<PieIndicatorValueFormatType>;

export const PieFormatValueSettings = ({ value: formatValue, onChange }: PieValueFormatSettingsProps) => {
  return (
    <MainContainerSettings titleText="Формат значения">
      <GroupContainerSettings>
        <ElementContainerSettings>
          <SettingsRadio
            activeValue={getActiveRadioValue(pieIndicatorFormatValueOptions, formatValue)}
            options={pieIndicatorFormatValueOptions}
            onChange={({ value: formatValue }) => onChange(formatValue)}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
