import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { getFlow, getFlowDraft, getFlowDraftInfoId } from 'store/reducers/projectManager/getters';
import { NoopType } from 'types/global';
import { useEffect } from 'react';
import { loadDraftFlowAction, loadFlowAction } from 'store/reducers/projectManager/actions';

interface UseFlowListInterface {
  id: string;
  onClose?: NoopType;
}

export const useFlowList = ({ id, onClose }: UseFlowListInterface) => {
  const dispatch = useAppDispatch();
  const { loading, flowProjectsList } = useSelector(getFlow);
  const { loading: loadingDraft, flowProjectsList: flowProjectsDraftList } = useSelector(getFlowDraft);
  const flowDraftInfoId = useSelector(getFlowDraftInfoId);

  const isDraftFlow = id === flowDraftInfoId;

  useEffect(() => {
    if (!isDraftFlow && id) {
      dispatch(loadFlowAction(id));
    }

    if (isDraftFlow) {
      dispatch(loadDraftFlowAction());
    }

    onClose && onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isDraftFlow, onClose]);

  const { flowProjectsDataList, flowProjectsLoading } = {
    flowProjectsDataList: !isDraftFlow ? flowProjectsList : flowProjectsDraftList,
    flowProjectsLoading: !isDraftFlow ? loading : loadingDraft,
  };

  return { flowProjectsDataList, flowProjectsLoading, isDraftFlow };
};
