import {
  EnabledFilterDataType,
  EnabledFiltersDependencyInterface,
  EnabledFiltersMapInterface,
  FilterDataType,
} from 'store/reducers/filters/types';
import { getActiveBoardElement } from 'store/reducers/board/getters';
import { getAstOfEnabledFilterById } from 'store/reducers/ast/getters';
import { AST } from 'types/ast';
import { getWhereString } from 'utils/SQL/genereteAst';
import { getDefaultModelId } from 'store/reducers/projectSettings/getters';
import { createSelector } from 'reselect';
import { getState } from 'store/utils';
import { getActivePageId, getPageIds } from 'store/reducers/projectPages/getters';
import { handleInfluenceStatus, sortByYCoordinateFn } from 'utils/utils';
import isEqual from 'lodash/isEqual';

export const getFilterStore = createSelector(getState, (state) => state.filters);

export const getAlreadyLoadedContent = createSelector(getFilterStore, (state) => state.alreadyLoadedContent);

export const getFilters = createSelector(getFilterStore, (state) => state.filters);
export const getServerStateOfFilters = createSelector(getFilterStore, (state) => state.serverStateOfFilters);
export const getHasChangesOfFilters = createSelector([getServerStateOfFilters, getFilters], (serverState, currentState) =>
  serverState === null ? false : !isEqual(serverState, currentState),
);

export const getFilterById = (id: string) => createSelector(getFilters, (filters) => filters[id]);
export const getProjectFilterById = (id: string) => createSelector(getFilterByProject, (filters) => filters[id]);

export const getFilterIdsByPage = createSelector(getFilterStore, (state) => state.filtersByPages);

export const getFiltersValues = createSelector(getFilterStore, (state) => state.filtersData.filtersValues);
export const getFiltersValuesLoadingList = createSelector(getFilterStore, (state) => state.filtersValuesLoadingList);
export const getFiltersErrorsList = createSelector(getFilterStore, (state) => state.filtersErrorsList);
export const getFilterByProject = createSelector(getFilterStore, (state) => state.filtersByProject);

export const getFiltersValuesStateByFilterId = (filterId: string) =>
  createSelector([getFiltersValues, getFiltersValuesLoadingList], (filterValues, loadingFilterValue) => ({
    filterValues: filterValues[filterId] || [],
    loadingFilterValue: loadingFilterValue[filterId],
  }));

export const getArrayFilters = createSelector(getFilters, (filters) => (Object.values(filters) as FilterDataType[]) || []);

export const getFilterIdsByPageId = (pageId: string) =>
  createSelector(getFilterIdsByPage, (filtersByPages) => filtersByPages[pageId]);

export const getFilterIdsByPageAsArray = (pageId?: string) =>
  createSelector(getState, (state) => Array.from(getFilterIdsByPageId(pageId || '')(state) || []));

export const getFiltersByPageId = (pageId?: string) =>
  createSelector([getState, getFilterIdsByPageAsArray(pageId)], (state, filterIds) =>
    filterIds
      .reduce<FilterDataType[]>((result, id) => {
        const filter = getFilterById(id)(state);

        if (filter) {
          return [...result, filter];
        }

        return result;
      }, [])
      .sort(sortByYCoordinateFn),
  );

export const getFiltersAlreadyLoaded = (pageId: string) =>
  createSelector(getAlreadyLoadedContent, (alreadyLoadedContent) => alreadyLoadedContent.filters.has(pageId));

export const getActiveFilter = createSelector([getState, getActiveBoardElement], (state, activeBoardElement) =>
  getFilterById(activeBoardElement || '')(state),
);

/* Enabled Filters */

export const getEnabledFilters = createSelector(getFilterStore, (state) => state.enabledFilters);
export const getServerStateOfEnabledFilters = createSelector(getFilterStore, (state) => state.serverStateOfEnabledFilters);
export const getHasChangesOfEnabledFilters = createSelector(
  [getServerStateOfEnabledFilters, getEnabledFilters],
  (serverState, currentState) => (serverState === null ? false : !isEqual(serverState, currentState)),
);

export const getEnabledFilterById = (id: string) => createSelector(getEnabledFilters, (enabledFilters) => enabledFilters[id]);

export const getEnabledFilterIdsByPage = createSelector(getFilterStore, (state) => state.enabledFiltersByPages);

export const getEnabledFilterIdsByPageId = (pageId: string) =>
  createSelector(getEnabledFilterIdsByPage, (enabledFiltersByPages) => enabledFiltersByPages[pageId]);

export const getEnabledFilterIdsByPageAsArray = (pageId?: string) =>
  createSelector(getState, (state) => Array.from(getEnabledFilterIdsByPageId(pageId || '')(state) || []));

export const getEnabledFiltersByPageId = (pageId?: string) =>
  createSelector([getState, getEnabledFilterIdsByPageAsArray(pageId)], (state, enabledFilterIds) =>
    enabledFilterIds.reduce<EnabledFilterDataType[]>((result, id) => {
      const enabledFilter = getEnabledFilterById(id)(state);

      if (enabledFilter) {
        return [...result, enabledFilter];
      }

      return result;
    }, []),
  );

export const getArrayEnabledFilters = createSelector(
  getEnabledFilters,
  (enabledFilters) => (Object.values(enabledFilters) || []) as EnabledFilterDataType[],
);

export const getEnabledFiltersDataDependency = createSelector(getArrayEnabledFilters, (enabledFilters) =>
  enabledFilters.reduce<EnabledFiltersDependencyInterface[]>(
    (result, { nameSettings: { fieldName }, isRealData, selectedValues, id, type, incisionRequest }) => [
      ...result,
      {
        id,
        isRealData,
        fieldName: fieldName || '',
        values: selectedValues,
        type,
        incisionRequest,
      },
    ],
    [],
  ),
);

export const getLocalEnabledFiltersByPageId = (activePageId: string) =>
  createSelector(getEnabledFiltersByPageId(activePageId), (arrayEnabledFilters) =>
    arrayEnabledFilters.filter(({ isGlobal }) => !isGlobal),
  );

export const getGlobalEnabledFilters = createSelector(getArrayEnabledFilters, (arrayEnabledFilters) =>
  arrayEnabledFilters.filter(({ isGlobal }) => isGlobal),
);

export const getEnabledFiltersOnPage = (activePageId: string) =>
  createSelector([getLocalEnabledFiltersByPageId(activePageId), getGlobalEnabledFilters], (localFilters, globalFilters) => [
    ...localFilters,
    ...globalFilters,
  ]);

export const isHasFiltersByPageId = (activePageId: string) =>
  createSelector(getEnabledFiltersOnPage(activePageId), (filters) => !!filters.length);

export const getEnabledFiltersAlreadyLoaded = (pageId: string) =>
  createSelector(getAlreadyLoadedContent, (alreadyLoadedContent) => alreadyLoadedContent.enabledFilters.has(pageId));

export const getMapEnabledFiltersByFilterId = createSelector(getArrayEnabledFilters, (enabledFiltersAsArray) =>
  enabledFiltersAsArray.reduce<EnabledFiltersMapInterface>((result, enabledFilter) => {
    const { link, linkId } = enabledFilter;

    if (link === 'filter') {
      return { ...result, [linkId]: enabledFilter };
    }

    return { ...result };
  }, {}),
);

export const getEnabledFilterByFilterId = (filterId: string) =>
  createSelector(getMapEnabledFiltersByFilterId, (mapEnabledFiltersByFilterId) => mapEnabledFiltersByFilterId[filterId]);

export const getMapEnabledFiltersByVisualisationId = createSelector(getArrayEnabledFilters, (enabledFiltersAsArray) =>
  enabledFiltersAsArray.reduce<Record<string, EnabledFilterDataType[] | undefined>>((result, enabledFilter) => {
    const { link, linkId } = enabledFilter;

    if (link === 'visualisation') {
      const enabledFilters = result[linkId] || [];
      return { ...result, [linkId]: [...enabledFilters, enabledFilter] };
    }

    return { ...result };
  }, {}),
);

export const getEnabledFiltersByVisualisationId = (visualisationId: string) =>
  createSelector(
    getMapEnabledFiltersByVisualisationId,
    (mapEnabledFiltersByVisualisationId) => mapEnabledFiltersByVisualisationId[visualisationId],
  );

export const getFilterByPages = createSelector([getState, getPageIds], (state, pageIds) =>
  pageIds.reduce((results, pageId) => {
    if (getFiltersAlreadyLoaded(pageId)(state)) {
      return [...results, { pageId, filters: getFiltersByPageId(pageId)(state) }];
    }

    return results;
  }, [] as Array<{ pageId: string; filters: FilterDataType[] }>),
);

export const getEnabledFilterByPages = createSelector([getState, getPageIds], (state, pageIds) =>
  pageIds.reduce((results, pageId) => {
    if (getEnabledFiltersAlreadyLoaded(pageId)(state)) {
      return [...results, { pageId, enabledFilters: getEnabledFiltersByPageId(pageId)(state) }];
    }

    return results;
  }, [] as Array<{ pageId: string; enabledFilters: EnabledFilterDataType[] }>),
);

/* AST */

export const getAstEnabledFiltersByParams = (
  pageId: string,
  modelIdValue: string,
  excludeVisualisationIds: string[],
  visualizationId: string,
) =>
  createSelector([getState, getDefaultModelId], (state, defaultModelId) => {
    const enabledFilters = getEnabledFiltersOnPage(pageId)(state);

    return enabledFilters.reduce<AST.WhereIn[]>((result, { id, modelId, linkId, filterInfluences }) => {
      const value = getAstOfEnabledFilterById(id)(state);
      const isFilterInfluence = handleInfluenceStatus(filterInfluences, visualizationId);

      if (!isFilterInfluence) {
        return result;
      }

      if (value && (modelId || defaultModelId) === modelIdValue && !excludeVisualisationIds.includes(linkId)) {
        return [...result, value];
      }

      return result;
    }, []);
  });

/* SQL Filter string */

export const getSqlFilterStringById = createSelector(
  [getState, getDefaultModelId, getActivePageId],
  (state, defaultModelId, activePageId) => (filterId: string) => {
    const enabledFilters = getEnabledFiltersOnPage(activePageId)(state),
      filter = getFilterById(filterId)(state),
      modelIdValue = filter?.modelId || defaultModelId || '',
      whereParams = enabledFilters.reduce<AST.WhereIn[]>((result, { linkId, id, modelId, filterInfluences }) => {
        const value = getAstOfEnabledFilterById(id)(state);
        const isFilterInfluence = handleInfluenceStatus(filterInfluences, filterId);

        if (!isFilterInfluence) {
          return result;
        }

        if (linkId !== filterId && value && (modelId || defaultModelId) === modelIdValue) {
          return [...result, value];
        }
        return result;
      }, []);

    return getWhereString(whereParams);
  },
);
