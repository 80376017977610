import { differenceWith, isEqual } from 'lodash';
import { usePrevious } from 'utils/hooks/previous';

export const useArrayHaveBeenChanged = <T>(array: T[]) => {
  const previousArray = usePrevious(array) || [];

  const diffCurrentAndPrevious = differenceWith(array, previousArray, isEqual);
  const diffPreviousAndCurrent = differenceWith(previousArray, array, isEqual);

  return !!diffCurrentAndPrevious.length || !!diffPreviousAndCurrent.length;
};
