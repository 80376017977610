import React, { ChangeEvent, useState } from 'react';
import { Button } from 'modules/ui/index';
import { SearchInput } from 'modules/ui/inputs/SearchInput';
import { Table } from 'modules/ui/Table';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { accessSearch } from 'components/projectsManager/elements/Projects/Access/constants';
import { FlexContainer } from 'styles/FlexContainer';
import { RowHeightVariants } from 'modules/ui/Table/types';
import { ColumnDef, ColumnFiltersState } from '@tanstack/react-table';
import { NoopType } from 'types/global';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

interface TableWithSearchProps<T extends object, D> {
  onAddModal: NoopType;
  rows: T[];
  columns: ColumnDef<T, D>[];
  onRowClick?: (data: T) => void;
  filterColumnName?: string;
  loading?: boolean;
  addButtonName?: string;
  tableWithSearchMaxHeightContainer?: string;
  tableWithSearchHeightContainer?: string;
  tableHeight?: string;
  rowHeightVariant?: RowHeightVariants;
  onUpdate?: NoopType;
  updateButtonName?: string;
}

export const TableWithSearch = <T extends object, D>({
  onAddModal,
  loading = false,
  filterColumnName = 'group',
  addButtonName = 'Добавить',
  tableWithSearchMaxHeightContainer = 'calc(100vh - 300px)',
  tableWithSearchHeightContainer,
  tableHeight,
  updateButtonName,
  onUpdate,
  ...props
}: TableWithSearchProps<T, D>) => {
  const [searchString, setSearchString] = useState<string>('');
  const [filter, setFilter] = useState<ColumnFiltersState>([]);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchString(value);
    setFilter([{ id: filterColumnName, value }]);
  };

  return (
    <>
      <LoadingOverlay loading={loading} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />
      <FlexContainer height="100%" flexDirection="column" width="100%" gap="24px">
        <FlexContainer gap="24px">
          <SearchInput
            name={accessSearch}
            width="360px"
            needBackground={false}
            value={searchString}
            onChange={handleChangeSearch}
          />
          {onUpdate && <Button text={updateButtonName} iconSize="normal" heightSize="normal" onClick={onUpdate} />}
          <Button text={addButtonName} iconSize="normal" heightSize="normal" onClick={onAddModal} />
        </FlexContainer>

        <FlexContainer
          flexDirection="column"
          width="100%"
          maxHeight={tableWithSearchMaxHeightContainer}
          height={tableWithSearchHeightContainer}
          position="relative"
        >
          <Table filters={filter} height={tableHeight} {...props} />
        </FlexContainer>
      </FlexContainer>
    </>
  );
};
