import { CSSProperties, NamedExoticComponent, useMemo } from 'react';
import { FixedSizeList as List } from 'react-window';
import styled from 'styled-components';
import { defaultScrollbarMixin } from 'constants/styles';

interface VirtualizedListProps {
  children: NamedExoticComponent<any>;
  style?: CSSProperties;
  data: any;
}

const StyledList = styled(List)`
  ${defaultScrollbarMixin};
`;

export const VirtualizedList = ({ children, style, data }: VirtualizedListProps) => {
  const { values } = data;

  const itemCount = values?.length;
  const itemSize = 35;

  const minHeight = useMemo(() => {
    const height = Math.max(itemCount * itemSize, itemSize);

    if (itemCount > 10) {
      return 1000;
    }

    return height;
  }, [itemCount]);

  return (
    <StyledList style={{ ...style }} itemCount={itemCount} itemSize={itemSize} itemData={data} height={minHeight} width="100%">
      {children}
    </StyledList>
  );
};
