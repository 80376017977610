import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React, { useCallback } from 'react';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ModalCustomRequest, ModalCustomRequestProps } from 'modules/settingsContainer/common/data/ModalCustomRequest';
import { useModalState } from 'utils/hooks/modalState';
import { useLocalValues } from 'utils/hooks/localValues';
import { IconType } from 'assets/icons/withContainer';
import { FunctionIcon } from 'assets/icons/forDelete';
import { formatSql } from 'utils/SQL/formatSQL';

export interface CustomRequestSettingsProps extends Pick<ModalCustomRequestProps, 'onChange' | 'value' | 'modelMetaData'> {
  likeMainContainer?: boolean;
  Icon?: IconType;
}

export const CustomRequestSettings = ({
  value,
  onChange,
  likeMainContainer = false,
  Icon,
  modelMetaData,
}: CustomRequestSettingsProps) => {
  const { isOpen, onOpen, onClose } = useModalState();

  const { localValues, setLocalValues, onSave, onCancel, hasChanges } = useLocalValues({
    value,
    onChange,
  });

  const onSaveEditor = useCallback(() => {
    if (localValues) {
      const expression = formatSql(localValues);
      expression && onChange(expression);
    }

    onSave();
    onClose();
  }, [onSave, onClose, localValues, onChange]);

  const onCloseEditor = useCallback(() => {
    onCancel();
    onClose();
  }, [onCancel, onClose]);

  return (
    <>
      {likeMainContainer ? (
        <MainContainerSettings titleText="Выражение" ButtonIcon={Icon || FunctionIcon} onClickButtonIcon={onOpen} />
      ) : (
        <IconWrapper
          hoverColorVar={ColorVarsEnum.Level_1}
          colorVar={localValues ? ColorVarsEnum.Accent : ColorVarsEnum.Level_3}
          containerWidth="20px"
          containerHeight="20px"
          iconHeight="16px"
          iconWidth="16px"
          onClick={onOpen}
          Icon={Icon || FunctionIcon}
        />
      )}
      <ModalCustomRequest
        value={localValues || ''}
        onChange={(value) => setLocalValues(value)}
        onSave={onSaveEditor}
        onCancel={onCancel}
        isOpen={isOpen}
        onClose={onCloseEditor}
        hasChanges={hasChanges}
        modelMetaData={modelMetaData}
      />
    </>
  );
};
