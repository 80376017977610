import { RoleKeyType } from 'components/admin/licenses/FilterRoleStatistics/types';
import { AdminLicenseUsersInterface } from 'store/reducers/adminLicenses/types';

export const addLicenseUser = 'addLicenseUser';
export const editLicenseUser = 'editLicenseUser';
export const roleUser = 'roleUser';
export const filterUsersByRole = (roleKey: RoleKeyType, adminLicenseUsers?: AdminLicenseUsersInterface[]) => {
  const roleMap = {
    AD: ['admin', 'developer'],
    AV: ['viewer', 'analyst'],
  };

  if (roleKey === 'general') return adminLicenseUsers;

  const rolesToFilter = roleMap[roleKey] || [];

  return adminLicenseUsers?.filter((user) => rolesToFilter.includes(user.role));
};
