import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { Button } from 'modules/ui';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import React from 'react';
import { ApplyIcon } from 'assets/icons/FilterInfluenceIcons/Apply';
import { DenyIcon } from 'assets/icons/FilterInfluenceIcons/Deny';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { WrapperIconEnum } from 'assets/icons/FilterInfluenceIcons/FilterInfluenceIconWrapper';
import { ApplyToAllPagesElementSettings } from '../ApplyToAllPagesSettings';
import { OnValueChange } from 'types/global';

interface FilterInfluenceSettingsElementProps {
  onToggleInfluenceMode: () => void;
  influenceModeValue: boolean;
  onApplyToAllFilterInfluences: (payload: boolean) => () => void;
  titleText?: string;
}

interface FilterInfluenceSettingsProps extends OnValueChange<boolean>, FilterInfluenceSettingsElementProps {}

export const FilterInfluenceElementSettings = ({
  onToggleInfluenceMode,
  influenceModeValue,
  onApplyToAllFilterInfluences,
  titleText = 'Влияние на виджеты',
}: FilterInfluenceSettingsElementProps) => {
  return (
    <ElementContainerSettings>
      <FlexContainer display="flex" flexDirection="column" width="100%">
        <PrimaryTextSpan lineHeight="12px">{titleText}</PrimaryTextSpan>
        <FlexContainer display="flex" justifyContent="space-between" width="100%" marginTop={titleText ? '10px' : '0px'}>
          <Button onClick={onToggleInfluenceMode} active={influenceModeValue} text="Настроить" heightSize="small" width="76px" />
          <FlexContainer display="flex" alignItems="center" gap="4px">
            <PrimaryTextSpan lineHeight="12px">Применить ко всем</PrimaryTextSpan>
            <ApplyIcon onClick={onApplyToAllFilterInfluences(true)} type={WrapperIconEnum.WRAP} />
            <DenyIcon onClick={onApplyToAllFilterInfluences(false)} type={WrapperIconEnum.WRAP} />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </ElementContainerSettings>
  );
};

export const FilterInfluenceSettings = ({
  influenceModeValue,
  onToggleInfluenceMode,
  value,
  onChange,
  onApplyToAllFilterInfluences,
}: FilterInfluenceSettingsProps) => {
  return (
    <MainContainerSettings titleText="Влияние на виджеты">
      <ApplyToAllPagesElementSettings value={value} onChange={onChange} />
      <FilterInfluenceElementSettings
        influenceModeValue={influenceModeValue}
        onToggleInfluenceMode={onToggleInfluenceMode}
        onApplyToAllFilterInfluences={onApplyToAllFilterInfluences}
        titleText=""
      />
    </MainContainerSettings>
  );
};
