import { HeaderItemInterface } from 'modules/ui/menu/HeaderMenu/types';
import { HeaderItem, HeaderMenuWrapper } from 'modules/ui/menu/HeaderMenu/styles';

export interface HeaderMenuProps<T> {
  activeItemKey: T;
  items: HeaderItemInterface<T>[];
  onItemClick: (key: T) => void;
}

export const HeaderMenu = <T extends string>({ activeItemKey, items, onItemClick }: HeaderMenuProps<T>) => {
  return (
    <HeaderMenuWrapper>
      {items.map(({ name, key }) => (
        <HeaderItem onClick={() => onItemClick(key)} isActive={activeItemKey === key} key={key}>
          {name}
        </HeaderItem>
      ))}
    </HeaderMenuWrapper>
  );
};
