import React, { RefObject, useRef } from 'react';

import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { IconType, LeftIcon, RightIcon } from 'assets/icons/withContainer';
import ReactCarousel, { Images, ObjectFit } from 'react-gallery-carousel';
import { ButtonWrapper, CarouselButtonWrapper } from './styles';
import { NoopType } from 'types/global';
import { TextCarouselAutoPlayInterface } from 'store/reducers/visualisations/types';

interface CarouselButtonProps {
  left?: number;
  right?: number;
  Icon: IconType;
  onClick: NoopType;
}

export const CarouselButton = ({ left, right, Icon, onClick }: CarouselButtonProps) => (
  <ButtonWrapper left={left} right={right} onClick={onClick}>
    <IconWrapper
      Icon={Icon}
      colorVar={ColorVarsEnum.Level_5}
      iconHeight="18px"
      iconWidth="18px"
      hoverColorVar={ColorVarsEnum.Level_5}
      opacity={0}
    />
  </ButtonWrapper>
);

interface CarouselProps {
  carouselPositionSize?: ObjectFit;
  imagesData: Images;
  options: TextCarouselAutoPlayInterface;
}

export const Carousel = ({ imagesData, carouselPositionSize, options }: CarouselProps) => {
  const carouselRef: RefObject<any> = useRef(null);
  const { isActive, value: interval } = options;

  return (
    <>
      <ReactCarousel
        hasSizeButton={false}
        hasMediaButton={false}
        hasLeftButton={false}
        canAutoPlay={isActive}
        hasRightButton={false}
        isAutoPlaying={true}
        autoPlayInterval={interval}
        objectFit={carouselPositionSize}
        images={imagesData}
        ref={carouselRef}
        hasThumbnails={false}
        hasIndexBoard="bottomCenter"
        shouldSwipeOnMouse={false}
        style={{ position: 'absolute', backgroundColor: 'transparent' }}
      />
      <CarouselButtonWrapper>
        <CarouselButton left={0} Icon={LeftIcon} onClick={() => carouselRef?.current.goLeft()} />
        <CarouselButton right={0} Icon={RightIcon} onClick={() => carouselRef?.current.goRight()} />
      </CarouselButtonWrapper>
    </>
  );
};
