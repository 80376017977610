import React, { memo } from 'react';
import { useFlowProjectInfo } from 'components/admin/flows/elements/Projects/hooks/useFlowProjectInfo';
import { InfoProjectUI } from 'modules/ui/ManagerOrAdminComponentsUI/InfoProjectUI';
import { NoopValueType } from 'types/global';
import { AvatarProjectInterface } from 'types/types';

interface InfoProjectProps {
  activeProjectId: string;
  padding?: string;
  onChangeAvatar?: NoopValueType<AvatarProjectInterface>;
}

export const InfoProjectComponent = ({ activeProjectId, padding, onChangeAvatar }: InfoProjectProps) => {
  const { loadingInfoProject, infoProject: infoProjectData, changeProjectAvatar } = useFlowProjectInfo(activeProjectId || '');

  const handleChangeAvatar = ({ avatar, projectId }: AvatarProjectInterface) => {
    changeProjectAvatar(avatar);
    onChangeAvatar && onChangeAvatar({ avatar, projectId });
  };

  return (
    <InfoProjectUI
      title={infoProjectData?.title}
      id={infoProjectData?.id}
      isProtected={infoProjectData?.isProtected}
      createdAt={infoProjectData?.createdAt}
      created={infoProjectData?.created}
      avatar={infoProjectData?.avatar}
      updated={infoProjectData?.updated}
      updatedDataAt={infoProjectData?.updatedDataAt}
      isLoading={loadingInfoProject}
      padding={padding}
      onChangeAvatar={handleChangeAvatar}
    />
  );
};

export const InfoProject = memo(InfoProjectComponent);
