import React, { ChangeEvent } from 'react';
import { NoopType, NoopValueType } from 'types/global';
import { SearchInput } from 'modules/ui/inputs/SearchInput';
import { MultiSelectType } from 'modules/ui/MultiSelect';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { CloseFilterIcon, OpenFilterIcon, ShortIcon } from 'assets/icons/filter';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { MenuListComponent } from 'components/shared/ui/menu/MenuIconList';
import { Option } from 'components/shared/ui/menu/MenuIconList/types';

interface SearchSortSelectProps<ShortType extends string> {
  searchText: string;
  onChangeSearchText: NoopValueType<string>;
  selectedSort: ShortType;
  onSelectedSort: NoopValueType<ShortType>;
  onOpenMultiFilter: NoopType;
  selectedMultiFilterValues: MultiSelectType<string>[];
  defaultSelectedMultiFilterValues?: string;
  onClearMultiFilter: NoopType;
  optionsShort: Option<ShortType>[];
}

export const SearchSortSelect = <ShortType extends string>({
  searchText,
  onChangeSearchText,
  selectedSort,
  onSelectedSort,
  onOpenMultiFilter,
  onClearMultiFilter,
  selectedMultiFilterValues,
  optionsShort,
  defaultSelectedMultiFilterValues = 'Все подключения',
}: SearchSortSelectProps<ShortType>) => {
  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeSearchText(event.target.value);
  };

  const selectedMultiFilterValueText = selectedMultiFilterValues.map((item) => item.title).join(', ');
  const isEmptyFilterSelection = selectedMultiFilterValues.length === 0;

  return (
    <FlexContainer
      flexDirection="column"
      gap="5px"
      padding="0 12px 10px"
      borderBottom={`1px solid var(${ColorVarsEnum.Level_5})`}
    >
      <FlexContainer flexDirection="row" justifyContent="space-between" height="40px" alignItems="center" gap="10px">
        <FlexContainer
          onClick={onOpenMultiFilter}
          flexDirection="row"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
          cursor="pointer"
        >
          <PrimaryTextParagraph
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            lineHeight="14px"
            fontSize="14px"
            fontWeight="bold"
            maxWidth="200px"
            color={`var(${ColorVarsEnum.Level_1})`}
          >
            {isEmptyFilterSelection ? defaultSelectedMultiFilterValues : selectedMultiFilterValueText}
          </PrimaryTextParagraph>
          <IconWrapper
            hoverColorVar={ColorVarsEnum.Level_3}
            colorVar={ColorVarsEnum.Level_3}
            containerWidth="20px"
            containerHeight="20px"
            iconHeight="20px"
            iconWidth="20px"
            Icon={isEmptyFilterSelection ? CloseFilterIcon : OpenFilterIcon}
            onClick={(e) => {
              if (!isEmptyFilterSelection) {
                e.stopPropagation();
                return onClearMultiFilter();
              }

              if (isEmptyFilterSelection) {
                return onOpenMultiFilter();
              }
            }}
          />
        </FlexContainer>

        <MenuListComponent options={optionsShort} Icon={ShortIcon} onSelect={onSelectedSort} selected={selectedSort} />
      </FlexContainer>

      <SearchInput
        name="search_field"
        placeholder="Поиск"
        needBackground={false}
        width="100%"
        value={searchText}
        onChange={onSearchChange}
        onClear={() => onChangeSearchText('')}
        useDebounce
      />
    </FlexContainer>
  );
};
