import { SortOrderType, SortType } from './types';

export const sortDescending: Array<{ name: string; value: SortOrderType }> = [
  { name: 'По возрастанию', value: 'ascending' },
  { name: 'По убыванию', value: 'descending' },
];

export const sortLastModified: Array<{ name: string; value: SortType }> = [
  { name: 'Последнее изменение', value: 'lastModified' },
  { name: 'Алфавит', value: 'alphabet' },
];
