import { createAsyncThunk } from '@reduxjs/toolkit';
import { closeModal, openModal, updateConfirmationModal, updateWizardModal } from 'store/reducers/modals/index';
import { ConfirmationModalInterface, ModalInfo, ModalsActionsTypes, WizardModalData, WizardModalInterface } from './types';
import { WizardsStepDataInterface } from 'types/global';
import { defaultConfirmationModal, defaultWizardModal } from 'store/reducers/modals/constants';

export const openModalAction = createAsyncThunk<void, ModalInfo>(
  ModalsActionsTypes.OPEN_MODAL,
  async (modalInfo, { dispatch }) => {
    dispatch(openModal(modalInfo));
  },
);

export const openModalTypedAction = <Props = unknown>(modalInfo: ModalInfo<Props>) => openModalAction(modalInfo as ModalInfo);

export const closeModalAction = createAsyncThunk<void, string>(
  ModalsActionsTypes.CLOSE_MODAL,
  async (modalName, { dispatch }) => {
    dispatch(closeModal(modalName));
  },
);

export const openConfirmationModalAction = createAsyncThunk<void, Omit<ConfirmationModalInterface, 'isOpen'>>(
  ModalsActionsTypes.OPEN_CONFIRMATION_MODAL,
  async (modalConfirmationInfo, { dispatch }) => {
    dispatch(updateConfirmationModal({ ...modalConfirmationInfo, isOpen: true }));
  },
);

export const closeConfirmationModalAction = createAsyncThunk<void>(
  ModalsActionsTypes.CLOSE_CONFIRMATION_MODAL,
  async (_, { dispatch }) => {
    dispatch(updateConfirmationModal(defaultConfirmationModal));
  },
);

export const openWizardModalAction = createAsyncThunk<void, Omit<WizardModalInterface, 'isOpen'>>(
  ModalsActionsTypes.OPEN_WIZARD_MODAL,
  async (modalWizardInfo, { dispatch }) => {
    dispatch(updateWizardModal({ ...modalWizardInfo, isOpen: true }));
  },
);

export const openWizardModalTypedAction = <WizardStepDataList extends WizardsStepDataInterface<keyof WizardStepDataList>>(
  modalWizardInfo: WizardModalData<WizardStepDataList>,
) => openWizardModalAction(modalWizardInfo as Omit<WizardModalInterface, 'isOpen'>);

export const closeWizardModalAction = createAsyncThunk<void>(ModalsActionsTypes.CLOSE_WIZARD_MODAL, async (_, { dispatch }) => {
  dispatch(updateWizardModal({ ...defaultWizardModal }));
});
