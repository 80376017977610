import { useState } from 'react';
import { SelectedItemInterface } from 'modules/ui/lists/MapList/list/types';
import { Ace } from 'ace-builds';
import { SqlAutocompleteProps } from 'modules/ui/SqlAutocomplete';

export const usePasteToSqlEditor = () => {
  const [selectedColumns, setSelectedColumns] = useState<SelectedItemInterface[]>([]);
  const [editorRef, setEditorRef] = useState<Ace.Editor | null>(null);

  const onEditorFocus: SqlAutocompleteProps['onFocus'] = (event, editor) => {
    setEditorRef(editor || null);
  };

  const onAddToEditor = () => {
    if (editorRef) {
      const textForPaste = selectedColumns.reduce(
        (result, { groupId, listId }) => `${result ? result + ', ' : result}${groupId}.${listId}`,
        '',
      );

      editorRef?.execCommand('paste', textForPaste);
      setSelectedColumns([]);
    }
  };

  return { onEditorFocus, onAddToEditor, selectedColumns, setSelectedColumns };
};
