import AceEditor, { IAceEditorProps } from 'react-ace';
import {
  addCompleter,
  keyWordCompleter,
  setCompleters,
  snippetCompleter,
  textCompleter,
} from 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-pgsql';
import 'ace-builds/src-noconflict/theme-sqlserver';
import 'ace-builds/src-noconflict/theme-github_dark';
import { FC, useEffect, useId } from 'react';
import { AdviceEditor } from 'modules/ui/SqlAutocomplete/types';
import { useModelIdValue } from 'utils/hooks/visualisation/modelIdValue';
import { useSelector } from 'react-redux';
import { getCompletionsByModelId } from 'store/reducers/models/getters';
import { getActiveVisualisationSettings } from 'store/reducers/visualisations/getters';
import { getActiveThemeCodeEditorTheme } from 'store/reducers/themes/getters';

export interface SqlAutocompleteProps {
  value: string;
  handleChange: (e: string) => void;
  height?: string;
  adviceEditor?: AdviceEditor[];
  autofocus?: boolean;
  onFocus?: IAceEditorProps['onFocus'];
}

export const SqlAutocomplete: FC<SqlAutocompleteProps> = ({
  value = '',
  handleChange = () => {},
  height,
  adviceEditor = [],
  autofocus = false,
  onFocus,
}) => {
  const id = useId();
  const modelId = useSelector(getActiveVisualisationSettings)?.dataSettings?.modelId;
  const codeEditorTheme = useSelector(getActiveThemeCodeEditorTheme);
  const modelIdValue = useModelIdValue(modelId || '');
  const completions = useSelector(getCompletionsByModelId(modelIdValue));

  useEffect(() => {
    return () => {
      setCompleters([textCompleter, keyWordCompleter, snippetCompleter]);
    };
  }, []);

  useEffect(() => {
    addCompleter({
      getCompletions: (
        editor: any,
        session: any,
        pos: any,
        prefix: any,
        callback: (error: any, completions: AdviceEditor[]) => void,
      ) => {
        callback(null, [...adviceEditor, ...completions]);
      },
    });
  }, [adviceEditor, completions]);

  return (
    <AceEditor
      mode="pgsql"
      theme={codeEditorTheme === 'dark' ? 'github_dark' : 'sqlserver'}
      name={id}
      width="100%"
      onChange={(text) => handleChange(text)}
      fontSize={14}
      height={height}
      showPrintMargin={false}
      showGutter={false}
      highlightActiveLine={false}
      value={value}
      onFocus={onFocus}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: false,
        showLineNumbers: true,
        wrap: true,
        tabSize: 2,
        cursorStyle: 'ace',
      }}
      focus={autofocus}
    />
  );
};
