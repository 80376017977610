import React, { useRef, useState } from 'react';
import { StyledMenuItem } from 'modules/ui/menu/ContextMenu/styles';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ClickAwayListener, Popover } from '@mui/material';
import { MenuItemProps } from 'modules/ui/menu/ContextMenu/index';

export const MenuItem: React.FC<MenuItemProps> = ({ title, onClick, hotKeyParams, disabled = false, subMenuItems }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverPosition, setPopoverPosition] = useState<{ anchorOrigin: any; transformOrigin: any }>({
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  });
  const itemRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    updatePopoverPosition();
  };

  const updatePopoverPosition = () => {
    if (itemRef.current) {
      const { right } = itemRef.current.getBoundingClientRect();
      const spaceRight = window.innerWidth - right;
      const neededSpace = 300;

      if (spaceRight < neededSpace) {
        setPopoverPosition({
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        setPopoverPosition({
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        });
      }
    }
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const onItemClick = (event: React.MouseEvent<HTMLElement>, itemClick: () => void) => {
    itemClick();
  };

  return (
    <FlexContainer ref={itemRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {!subMenuItems ? (
        <StyledMenuItem
          onMouseDown={() => !disabled && onClick && onClick()}
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        >
          <FlexContainer width="100%" justifyContent="space-between">
            <PrimaryTextSpan
              padding="0 10px 0 0"
              color={!disabled ? `var(${ColorVarsEnum.Level_1})` : `var(${ColorVarsEnum.Level_3})`}
              fontSize="14px"
            >
              {title}
            </PrimaryTextSpan>
            {hotKeyParams && (
              <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_2})`} fontSize="12px">
                {hotKeyParams.icon}
              </PrimaryTextSpan>
            )}
          </FlexContainer>
        </StyledMenuItem>
      ) : (
        <>
          <StyledMenuItem>
            <FlexContainer width="100%" justifyContent="space-between">
              <PrimaryTextSpan padding="0 10px 0 0" color={`var(${ColorVarsEnum.Level_1})`} fontSize="14px" cursor="pointer">
                {title}
              </PrimaryTextSpan>
            </FlexContainer>
          </StyledMenuItem>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleMouseLeave}
            anchorOrigin={popoverPosition.anchorOrigin}
            transformOrigin={popoverPosition.transformOrigin}
          >
            <ClickAwayListener onClickAway={handleMouseLeave}>
              <FlexContainer flexDirection="column" height="100%" overflowY="auto" overflowX="hidden">
                {subMenuItems.map((item, index) => (
                  <StyledMenuItem
                    style={{ width: 200 }}
                    key={index}
                    onMouseDown={(event) => item.onClick && onItemClick(event, item.onClick)}
                  >
                    <FlexContainer width="100%" justifyContent="space-between">
                      <PrimaryTextSpan
                        padding="0 10px 0 0"
                        color={`var(${ColorVarsEnum.Level_1})`}
                        fontSize="14px"
                        cursor="pointer"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                      >
                        {item.title}
                      </PrimaryTextSpan>
                    </FlexContainer>
                  </StyledMenuItem>
                ))}
              </FlexContainer>
            </ClickAwayListener>
          </Popover>
        </>
      )}
    </FlexContainer>
  );
};
