import { IconType } from 'assets/icons/withContainer';
import { GroupSvg, UserSvg } from './styles';
import { EntityType, GroupType } from 'types/store';

export const accessSourceEdit = 'accessSourceEdit';
export const accessSourceAdd = 'accessSourceAdd';
export const accessSourceEditSelect = 'accessSourceEditSelect';
export const accessSource = 'accessSource';

export const accessIcons: Record<EntityType, IconType> = {
  group: GroupSvg,
  user: UserSvg,
};

export const accessText = {
  owner: 'Управление',
  reader: 'Использование',
};

export const accessItems: Array<{ name: string; value: GroupType }> = [
  { name: 'Использование', value: 'reader' },
  { name: 'Управление', value: 'owner' },
];

export const defaultSelectedAccess = accessItems[0].value;
