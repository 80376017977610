import { FlexContainer } from 'styles/FlexContainer';
import Button from 'modules/ui/Button';
import { DeleteIcon } from 'assets/icons/withContainer';
import React, { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DownSVG } from './styles';
import { useAppDispatch } from 'store';
import {
  getEnabledFiltersDataDependency,
  getEnabledFiltersOnPage,
  getGlobalEnabledFilters,
  getLocalEnabledFiltersByPageId,
} from 'store/reducers/filters/getters';
import { EnabledFilterDataType } from 'store/reducers/filters/types';
import { disableFilterByIdAction } from 'store/reducers/filters/actions';
import { updateAstOfEnabledFiltersActions } from 'store/reducers/ast/actions';
import { isEqualProps } from 'utils/utils';
import { getActivePageId } from 'store/reducers/projectPages/getters';
import { NoopType } from 'types/global';
import { enabledFilterView } from 'modules/workspace/components/FilterRow/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';

interface FilterBlockProps {
  filter: EnabledFilterDataType;
  onDelete: NoopType;
}

const FilterBlock = ({ filter: { nameSettings, selectedValues, type }, onDelete }: FilterBlockProps) => {
  const Component = enabledFilterView[type];

  return <Component onDelete={onDelete} selectedValues={selectedValues} nameSettings={nameSettings} />;
};

const MemoizedFilterBlock = memo(FilterBlock, isEqualProps);

export const FilterRow = () => {
  const dispatch = useAppDispatch();
  const activePageId = useSelector(getActivePageId);

  const globalLFiltersList = useSelector(getGlobalEnabledFilters);
  const localFiltersList = useSelector(getLocalEnabledFiltersByPageId(activePageId));
  const filtersOnPageList = useSelector(getEnabledFiltersOnPage(activePageId));

  const onDeleteFilter = useCallback(
    (filterId: string) => () => dispatch(disableFilterByIdAction(filterId)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disableFilterByIdAction, globalLFiltersList, localFiltersList],
  );

  const onRemoveAllFilters = useCallback(() => {
    filtersOnPageList.forEach(({ id }) => onDeleteFilter(id)());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filtersOnPageList)]);

  /* For update Enabled Filters data in AST store  */
  const enabledFiltersDataDependency = useSelector(getEnabledFiltersDataDependency);

  useEffect(() => {
    dispatch(updateAstOfEnabledFiltersActions(enabledFiltersDataDependency));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(enabledFiltersDataDependency)]);

  return (
    <>
      {!!filtersOnPageList.length && (
        <>
          <FlexContainer flexWrap="nowrap" gap="4px" flexDirection="row" overflow="auto">
            {globalLFiltersList.map((filter) => (
              <MemoizedFilterBlock key={filter.id} filter={filter} onDelete={onDeleteFilter(filter.id)} />
            ))}
            <FlexContainer alignItems="center" justifyContent="center" margin="0 14px">
              <IconWrapper
                Icon={DownSVG}
                colorVar={ColorVarsEnum.Level_3}
                iconHeight="14px"
                iconWidth="14px"
                containerWidth="24px"
                containerHeight="24px"
                hoverColorVar={ColorVarsEnum.Level_3}
                cursor="default"
                opacity={0}
              />
            </FlexContainer>

            {localFiltersList.map((filter) => (
              <MemoizedFilterBlock key={filter.id} filter={filter} onDelete={onDeleteFilter(filter.id)} />
            ))}
          </FlexContainer>
          <Button needBackground={false} leftIcon={<DeleteIcon />} onClick={onRemoveAllFilters} />
        </>
      )}
    </>
  );
};
