import React, { useMemo } from 'react';
import { ACTIVE_BOARD_AREA_LABEL } from 'modules/workspace/constans';
import { SettingsLayoutWrapper } from 'modules/settingsContainer/SettingsLayout/styles';
import { SettingsTabs } from 'modules/ui/tabs/SettingsTabs';
import { Tab } from 'modules/ui/tabs/types';
import { ModesIcon, PagesIcon, PaletteIcon, ThemesIcon } from 'assets/pagesSettings';
import { SettingsTab } from 'modules/workspace/components/panelSettingsApp/tabsContent/SettingsTab';
import { PagesTab } from 'modules/workspace/components/panelSettingsApp/tabsContent/PagesTab/PagesTab';
import { PalettesTab } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab';
import { ThemesTab } from 'modules/workspace/components/panelSettingsApp/tabsContent/ThemesTab';
import { useSelector } from 'react-redux';
import { getProjectSettingsLoading, getThemeSettingsLoadingLoading } from 'store/reducers/projectSettings/getters';
import { getPagesLoading } from 'store/reducers/projectPages/getters';
import { getPalettesLoading } from 'store/reducers/palettes/getters';

export const PanelModal = () => {
  const projectSettingsLoading = useSelector(getProjectSettingsLoading);
  const themeSettingsLoading = useSelector(getThemeSettingsLoadingLoading);
  const pagesLoading = useSelector(getPagesLoading);
  const palettesLoading = useSelector(getPalettesLoading);

  const settingsTabs: Tab[] = useMemo(
    () => [
      {
        content: ModesIcon,
        type: 'icon',
        id: 'modes',
        Component: SettingsTab,
        isLoading: projectSettingsLoading,
      },
      {
        content: PagesIcon,
        type: 'icon',
        id: 'pages',
        Component: PagesTab,
        isLoading: pagesLoading,
      },
      {
        content: PaletteIcon,
        type: 'icon',
        id: 'palette',
        Component: PalettesTab,
        isLoading: palettesLoading,
      },
      {
        content: ThemesIcon,
        type: 'icon',
        id: 'themes',
        Component: ThemesTab,
        isLoading: themeSettingsLoading,
      },
    ],
    [projectSettingsLoading, pagesLoading, palettesLoading, themeSettingsLoading],
  );

  return (
    <SettingsLayoutWrapper id={ACTIVE_BOARD_AREA_LABEL}>
      <SettingsTabs tabs={settingsTabs} />
    </SettingsLayoutWrapper>
  );
};
