import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
  deleteByIdFlowAccessAction,
  deleteFlowGroupAccessAction,
  deleteFlowUserAccessAction,
  loadFlowAccessAction,
  loadNoFlowAccessAction,
} from 'store/reducers/adminFlows/actions';
import {
  closeConfirmationModalAction,
  closeModalAction,
  openConfirmationModalAction,
  openModalTypedAction,
} from 'store/reducers/modals/actions';
import { getActiveFlow, getFlowAccess } from 'store/reducers/adminFlows/getters';
import { FlowAccessModal } from 'components/admin/flows/elements/Access/Modal/FlowAccessModal';
import { TableCell } from 'modules/ui/Table/TableCell';
import { createColumnHelper } from '@tanstack/react-table';
import { TableWithSearch } from 'modules/ui/TableWithSearch';
import { useAppDispatch } from 'store';
import { createFlowAccess, updateFlowAccess } from 'components/admin/flows/constants';
import { accessIcons } from 'components/projectsManager/elements/Projects/Access/constants';
import { useSelector } from 'react-redux';
import { accessText } from 'components/admin/groups/constants';
import { FlexContainer } from 'styles/FlexContainer';
import { FlowAccessListInterface } from 'store/reducers/adminFlows/types';

export const AdminFlowsAccessTab: FC = () => {
  const dispatch = useAppDispatch();

  const activeFlow = useSelector(getActiveFlow);
  const activeFlowId = activeFlow?.id;

  const { loading, flowAccessList } = useSelector(getFlowAccess);

  useEffect(() => {
    if (activeFlowId) {
      dispatch(loadFlowAccessAction({ flowId: activeFlowId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFlowId]);

  const onDelete = useCallback(
    async (flowAccess: FlowAccessListInterface) => {
      try {
        if (!activeFlowId) {
          return;
        }

        const flowAccessId = flowAccess.id;
        const flowAccessEntity = flowAccess.entity;

        let response;

        if (flowAccessEntity === 'user') {
          response = await dispatch(deleteFlowUserAccessAction({ flowId: activeFlowId, userId: flowAccessId })).unwrap();
        }

        if (flowAccessEntity === 'group') {
          response = await dispatch(deleteFlowGroupAccessAction({ flowId: activeFlowId, groupId: flowAccessId })).unwrap();
        }

        if (response) {
          dispatch(deleteByIdFlowAccessAction(flowAccessId));
          dispatch(closeConfirmationModalAction());
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFlowId],
  );

  const onDeleteModal = useCallback(
    async (flowAccess: FlowAccessListInterface) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          description: `Действительно удалить доступ «${flowAccess.name}» к потоку?`,
          onConfirm: () => onDelete(flowAccess),
          titleText: 'Удаление доступа',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  const onCloseUpdateFlowAccessModal = () => dispatch(closeModalAction(updateFlowAccess));

  const onEditModal = useCallback(
    (flowAccess: FlowAccessListInterface) =>
      dispatch(
        openModalTypedAction({
          Component: FlowAccessModal,
          componentProps: {
            onClose: onCloseUpdateFlowAccessModal,
            flowAccess,
            mode: 'edit',
          },
          modalSettings: {
            headerText: 'Изменение прав доступа',
            width: '320px',
          },
          name: updateFlowAccess,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseUpdateFlowAccessModal],
  );

  const onCloseAddFlowAccessModal = () => dispatch(closeModalAction(createFlowAccess));

  const onAddModal = useCallback(
    async () => {
      try {
        if (!activeFlowId) {
          return;
        }

        const noFlowUserGroups = await dispatch(loadNoFlowAccessAction({ flowId: activeFlowId })).unwrap();
        const multiSelectData = noFlowUserGroups.map((userGroup) => ({
          title: userGroup.name,
          value: userGroup.id,
          icon: accessIcons[userGroup.entity],
          data: userGroup,
        }));

        dispatch(
          openModalTypedAction({
            Component: FlowAccessModal,
            componentProps: {
              onClose: onCloseAddFlowAccessModal,
              mode: 'add',
              multiSelectData,
            },
            modalSettings: {
              headerText: 'Добавление доступа',
            },
            name: createFlowAccess,
          }),
        );
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseAddFlowAccessModal, activeFlowId],
  );

  const columnHelper = createColumnHelper<FlowAccessListInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Группа/пользователь',
        cell: (info) => {
          const rowData = info.row.original;

          return (
            <TableCell
              Icon={accessIcons[rowData.entity]}
              text={info.getValue()}
              actions={{
                onRemove: async () => {
                  await onDeleteModal(info.row.original);
                },
              }}
            />
          );
        },
      }),
      columnHelper.accessor('type', {
        header: 'Права',
        cell: (info) => (
          <TableCell
            text={accessText[info.getValue()]}
            actions={{
              onEdit: async () => {
                await onEditModal(info.row.original);
              },
            }}
          />
        ),
      }),
    ],
    [columnHelper, onDeleteModal, onEditModal],
  );

  return (
    <FlexContainer position="relative" width="100%" padding="24px" maxWidth="800px">
      <TableWithSearch
        filterColumnName="name"
        columns={columns}
        rows={flowAccessList}
        onAddModal={onAddModal}
        loading={loading}
      />
    </FlexContainer>
  );
};
