import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan, TextLink } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const HintChildrenCron = () => (
  <FlexContainer flexDirection="column">
    <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_2})`} lineHeight="17px" fontSize="14px">
      Последовательность 5 символов, разделенных пробелами: 1 2 3 4 5 Где цифры обозначают:
      <br />
      <br />
      1: Минуты (0-59) <br /> 2: Часы (0-23) <br /> 3: День (0-31) <br /> 4: Месяц (0-12 [12 == Декабрь]) <br /> 5: День недели
      (0-7 [7 или 0 == sunday]) <br />
      Пример: 0 1 * * * — каждый день в 1 час ночи <br />
      <br />
      <TextLink
        target="_blank"
        href="https://book.winsolutions.ru/books/rukovodstvo-administratora/page/sintaksis-zadanii-dlia-cron"
        color={`var(${ColorVarsEnum.Accent})`}
      >
        Подробнее
      </TextLink>
    </PrimaryTextSpan>
  </FlexContainer>
);
