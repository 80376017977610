import React, { useCallback, useEffect, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCell } from 'modules/ui/Table/TableCell';
import {
  closeConfirmationModalAction,
  closeModalAction,
  openConfirmationModalAction,
  openModalTypedAction,
} from 'store/reducers/modals/actions';
import { useAppDispatch } from 'store';
import { EditAccessModal } from './EditAccessModal';
import { AddAccessModal } from './AddAccessModal';
import { TableWithSearch } from 'modules/ui/TableWithSearch';
import { FlexContainer } from 'styles/FlexContainer';
import { accessSourceAdd, accessSourceEdit } from './constants';
import { accessIcons, accessText } from './constants';
import { useSelector } from 'react-redux';
import { AccessInterface } from 'types/types';
import { ModalComponentType } from 'store/reducers/modals/types';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import {
  deleteByIdSourceAccessAction,
  deleteSourceAccessGroupAction,
  deleteSourceAccessUserAction,
  loadSourceUsersAndGroupsAction,
} from 'store/reducers/sources/actions';
import { getSourceUsersAndGroups } from 'store/reducers/sources/getters';

type AccessSourceProps = {
  activeSourceId: string;
};

export const AccessSource: ModalComponentType<AccessSourceProps> = ({ activeSourceId }) => {
  const dispatch = useAppDispatch();
  const { loading, sourcesUsersAndGroupsList } = useSelector(getSourceUsersAndGroups);

  useEffect(() => {
    if (activeSourceId) {
      dispatch(loadSourceUsersAndGroupsAction({ id: activeSourceId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSourceId]);

  const onCloseEditAccessModal = () => dispatch(closeModalAction(accessSourceEdit));
  const onCloseAddAccessModal = () => dispatch(closeModalAction(accessSourceAdd));

  const onDelete = useCallback(
    async (sourceAccess: AccessInterface) => {
      try {
        const flowAccessId = sourceAccess.id;

        const response =
          sourceAccess.entity === 'group'
            ? await dispatch(deleteSourceAccessGroupAction({ sourceId: activeSourceId || '', groupId: flowAccessId })).unwrap()
            : await dispatch(deleteSourceAccessUserAction({ sourceId: activeSourceId || '', userId: flowAccessId })).unwrap();

        if (response) {
          dispatch(deleteByIdSourceAccessAction(flowAccessId));
        }
      } catch (error) {
        /* TODO: Error output in the future */
        console.error(error);
      } finally {
        dispatch(closeConfirmationModalAction());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeSourceId],
  );

  const onDeleteModal = useCallback(
    async (sourceAccess: AccessInterface) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          description: `Действительно удалить доступ «${sourceAccess.name}» к группе?`,
          onConfirm: () => onDelete(sourceAccess),
          titleText: 'Удаление доступа',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  const onEditModal = useCallback(
    (sourceAccess: AccessInterface) =>
      dispatch(
        openModalTypedAction({
          Component: EditAccessModal,
          componentProps: {
            onClose: onCloseEditAccessModal,
            sourceAccess,
            activeSourceId,
          },
          modalSettings: {
            headerText: 'Изменение прав доступа',
            width: '320px',
          },
          name: accessSourceEdit,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseEditAccessModal, activeSourceId],
  );

  const onAddModal = useCallback(
    () =>
      dispatch(
        openModalTypedAction({
          Component: AddAccessModal,
          componentProps: {
            onClose: onCloseAddAccessModal,
            activeSourceId,
          },
          modalSettings: {
            headerText: 'Добавление доступа',
          },
          name: accessSourceAdd,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseAddAccessModal, activeSourceId],
  );

  const columnHelper = createColumnHelper<AccessInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Группа/пользователь',
        cell: (info) => {
          const rowData = info.row.original;

          return (
            <TableCell
              text={info.getValue()}
              Icon={accessIcons[rowData.entity]}
              actions={{
                onRemove: async () => {
                  await onDeleteModal(rowData);
                },
              }}
            />
          );
        },
      }),
      columnHelper.accessor('type', {
        header: 'Права',
        cell: (info) => (
          <TableCell
            text={accessText[info.getValue()]}
            actions={{
              onEdit: async () => {
                await onEditModal(info.row.original);
              },
            }}
          />
        ),
      }),
    ],
    [columnHelper, onDeleteModal, onEditModal],
  );

  return (
    <FlexContainer position="relative" width="100%" padding="24px" height="590px" maxWidth="800px">
      <LoadingOverlay loading={loading} />

      <TableWithSearch
        filterColumnName="name"
        columns={columns}
        rows={sourcesUsersAndGroupsList}
        onAddModal={onAddModal}
        loading={loading}
      />
    </FlexContainer>
  );
};
