import { FC } from 'types/global';
import { BezierLine, BezierWrapper, Path } from 'modules/ui/blocks/BezierBoard/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BezierSettingItem, LineDataType } from 'modules/ui/blocks/BezierBoard/types';
import { IdInterface } from 'types/store';
import { getLine } from './constants';
import { getMapObject } from 'utils/utils';

type BezierItemProps = IdInterface;

export const BezierItem: FC<BezierItemProps> = ({ children, id }) => <div id={id}>{children}</div>;

export interface BezierBoardProps {
  scale?: number;
  settings: BezierSettingItem[];
}

export const BezierBoard: FC<BezierBoardProps> = ({ children = false, scale = 1, settings }) => {
  const content = useRef<HTMLDivElement | null>(null);

  const [linesObj, setLinesObj] = useState<Record<string, LineDataType>>({});
  const [settingsGroup, setSettingsGroup] = useState<Record<string, BezierSettingItem[]>>({});

  const update = useCallback(
    (settings: BezierSettingItem[], oldLinesObj = {}) => {
      const lines = settings.map((item) => ({
        ...getLine(item, content.current, scale),
        id: `${item.from.id}_${item.from.key};${item.to.id}_${item.to.key}`,
      }));

      const updateLinesObj = getMapObject(lines, 'id');

      setLinesObj({
        ...oldLinesObj,
        ...updateLinesObj,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [content.current, scale],
  );

  useEffect(() => {
    update(settings);
    const group = settings.reduce<Record<string, BezierSettingItem[]>>((results, item) => {
      results[item.from.id] = [...(results[item.from.id] || []), item];
      results[item.to.id] = [...(results[item.to.id] || []), item];

      return results;
    }, {});

    setSettingsGroup(group);
  }, [update, settings]);

  useEffect(() => {
    if (content.current) {
      const observer = new MutationObserver((data) => {
        const setList = new Set();

        for (const { target } of data) {
          const element = target as Element,
            id = element.id;

          if (settingsGroup[id]) {
            settingsGroup[id].forEach(setList.add, setList);
          } else {
            const matchAll = element?.innerHTML.matchAll(/id=['"`]{1}(.+?)['"`]{1}/g);

            const ids = Array.from(matchAll).map((val) => val[1]);
            for (const id of ids) {
              if (settingsGroup[id]) {
                settingsGroup[id].forEach(setList.add, setList);
              }
            }
          }
        }
        const settings = Array.from(setList) as BezierSettingItem[];

        if (settings.length) {
          window.requestAnimationFrame(() => update(settings, linesObj));
        }
      });

      observer.observe(content.current, {
        attributes: true,
        characterData: true,
        subtree: true,
        childList: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  });

  return (
    <BezierWrapper ref={content}>
      {children}
      {Object.values(linesObj).map((v, i) => (
        <React.Fragment key={i}>
          <BezierLine className="bezier-line">
            <Path
              id={`path-${i}`}
              d={`M${v.cord0.x} ${v.cord0.y} C ${v.cord1.x} ${v.cord1.y}, ${v.cord2.x} ${v.cord2.y}, ${v.cord3.x} ${v.cord3.y}`}
              className={v.style}
            />
          </BezierLine>
        </React.Fragment>
      ))}
    </BezierWrapper>
  );
};
