import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import styled from 'styled-components';

export const WrapperContainer = styled.div`
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(${ColorVarsEnum.Level_1_hover_btn});
  }

  &:not(:hover) {
    background-color: transparent;
  }
`;
