import styled from 'styled-components';

export const GradientBarWrapper = styled.div`
  position: relative;
  height: 8px;
  width: 100%;
`;

interface ColorCanvasProps {
  width?: number;
  height?: number;
}

export const ColorCanvas = styled.canvas<ColorCanvasProps>`
  position: absolute;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  left: 0;
  top: 0;
`;
