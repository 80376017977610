import { BooleanIcon, DateIcon, DrobIcon, NumberIcon, OtherIcon, TxtIcon } from 'assets/icons/editor';

export const columnTypeIcon = {
  Int: NumberIcon,
  Float: DrobIcon,
  Decimal: DrobIcon,
  UUID: OtherIcon,
  Date: DateIcon,
  String: TxtIcon,
  Boolean: BooleanIcon,
  default: DrobIcon,
};

export const columnTypes = Object.keys(columnTypeIcon) as (keyof typeof columnTypeIcon)[];
