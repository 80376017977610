export const defaultPointShift = -3;
export const pointSize = 5;
export const halfPointSize = pointSize / 2;

export const getPointShift = (params: { borderRadius: number; additionalShift: number } | null) => {
  if (params) {
    const { borderRadius, additionalShift } = params;
    const squareDiagonal = Math.sqrt(2) * borderRadius,
      shiftHypotenuse = squareDiagonal - borderRadius,
      shift = shiftHypotenuse / Math.sqrt(2);

    return shift + defaultPointShift + additionalShift;
  }

  return defaultPointShift;
};
