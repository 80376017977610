import React, { FC } from 'react';
import { IconProps } from './types';

export const VerticalEnd: FC<IconProps> = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.33301 18.668H18.6663" stroke={isActive ? '#26A5ED' : '#9DA1AF'} strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M11.9997 5.33398L11.9997 14.5007M11.9997 14.5007L15.333 11.5786M11.9997 14.5007L8.66634 11.5786"
        stroke={isActive ? '#26A5ED' : '#9DA1AF'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
