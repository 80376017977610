import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { FlexContainer } from 'styles/FlexContainer';
import TextField from 'modules/ui/TextField';
import React from 'react';
import { AutoOrManualInterface } from 'store/reducers/visualisations/types';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { autoOrManualOptions } from 'modules/settingsContainer/common/AutoOrManualGroupValue/constants';
import { OnValueChange } from 'types/global';

interface AutoOrManualGroupValueProps extends OnValueChange<AutoOrManualInterface> {
  titleText: string;
}

export const AutoOrManualGroupValue = ({ titleText, value, onChange }: AutoOrManualGroupValueProps) => (
  <GroupContainerSettings titleText={titleText}>
    <ElementContainerSettings>
      <FlexContainer gap="8px">
        <SettingsRadio
          onChange={({ value: type }) => onChange({ ...value, type })}
          activeValue={getActiveRadioValue(autoOrManualOptions, value.type)}
          options={autoOrManualOptions}
        />
        <TextField
          onChange={(e) => onChange({ ...value, value: Number(e.target.value) })}
          value={String(value.value)}
          type="number"
          name="width"
          needBackground={false}
          needBorderBottom={false}
          width="50px"
        />
      </FlexContainer>
    </ElementContainerSettings>
  </GroupContainerSettings>
);
