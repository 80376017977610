import { store } from 'store';
import { updateWaterfallViewSettingsAction } from 'store/reducers/visualisations/actions';
import {
  AxisSettingsInterface,
  LegendSettingsInterface,
  PaddingVisualisationInterface,
  ZoomSettingsInterface,
} from 'store/reducers/visualisations/types';
import { PositionSettingType, StartAndEndInterface } from 'types/store';

const dispatch = store.dispatch;

export const onAxisIncisionChange = (axisIncisionSettings: AxisSettingsInterface) =>
  dispatch(updateWaterfallViewSettingsAction({ axisIncisionSettings }));

export const onAxisIndicatorChange = (axisIndicatorSettings: AxisSettingsInterface<'indicator'>) =>
  dispatch(
    updateWaterfallViewSettingsAction({
      axisIndicatorSettings,
    }),
  );

export const onLegendSettingsChange = (legendSettings: LegendSettingsInterface) =>
  dispatch(updateWaterfallViewSettingsAction({ legendSettings }));

export const onDataZoomHorizontalStartAndEndSettingsChange = (dataZoomHorizontalStartAndEnd: StartAndEndInterface) =>
  dispatch(updateWaterfallViewSettingsAction({ dataZoomHorizontalStartAndEnd }));

export const onDataZoomVerticalStartAndEndSettingsChange = (dataZoomVerticalStartAndEnd: StartAndEndInterface) =>
  dispatch(updateWaterfallViewSettingsAction({ dataZoomVerticalStartAndEnd }));

export const onShowTipsChange = (showTips: boolean) => dispatch(updateWaterfallViewSettingsAction({ showTips }));

export const onIncisionSelectorPositionChange = (incisionSelectorPosition: PositionSettingType) =>
  dispatch(updateWaterfallViewSettingsAction({ incisionSelectorPosition }));

export const onChangePaddingVisualisation = (visualisationPaddings: PaddingVisualisationInterface) =>
  dispatch(
    updateWaterfallViewSettingsAction({
      visualisationPaddings,
    }),
  );

export const onChangeHorizontalZoomChange = (horizontalZoom: ZoomSettingsInterface) =>
  dispatch(updateWaterfallViewSettingsAction({ horizontalZoom }));

export const onChangeVerticalZoomChange = (verticalZoom: ZoomSettingsInterface) =>
  dispatch(updateWaterfallViewSettingsAction({ verticalZoom }));
