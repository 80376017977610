import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { ColorAndImageByInterfaceType } from 'store/reducers/visualisations/types';

export const getConditionOptions = (usingByValue?: boolean, usingByCondition?: boolean) => {
  const byValue = usingByValue
    ? [
        {
          value: 'value',
          label: 'По значению',
        },
      ]
    : [];

  const byCondition = usingByCondition
    ? [
        {
          value: 'condition',
          label: 'По условию',
        },
      ]
    : [];

  return [
    {
      value: 'default',
      label: 'Вручную',
    },
    ...byValue,
    ...byCondition,
  ] as SettingsRadioItem<ColorAndImageByInterfaceType>[];
};
