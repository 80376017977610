import { defaultScrollbarMixin, safeAreaInsetBottom } from 'constants/styles';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import styled, { css } from 'styled-components';

interface MenuContainerProps {
  isOpen: boolean;
}

export const MenuContainer = styled.div<MenuContainerProps>`
  width: 100%;
  flex-direction: column;
  height: calc(100vh - 110px);
  overflow: auto;
  position: absolute;
  top: 57px;
  background-color: var(${ColorVarsEnum.Level_3_menu});
  z-index: 1;
  transform: translateY(-100%);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s linear, transform 0.3s linear;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s linear, transform 0.3s linear;
    `}

  ${safeAreaInsetBottom};
  ${defaultScrollbarMixin};
`;
