import React, { useCallback } from 'react';
import { MultiSelect, MultiSelectType } from 'modules/ui/MultiSelect';
import { Button, Select } from 'modules/ui';
import { useAppDispatch } from 'store';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { getActiveGroup } from 'store/reducers/adminGroups/getters';
import {
  createGroupFlowAction,
  loadGroupFlowsAction,
  updateGroupFlowAction,
  updateGroupFlowsAction,
} from 'store/reducers/adminGroups/actions';
import { Form } from './styles';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { accessItems, defaultSelectedAccess } from 'components/admin/groups/constants';
import { ModalComponentType } from 'store/reducers/modals/types';
import { GroupFlowFormType } from 'components/admin/groups/elements/Flows/types';
import { SelectItemInterface } from 'components/admin/groups/types';
import { GroupFlowsListInterface } from 'store/reducers/adminGroups/types';
import { ModeForm } from 'types/store';

interface AddFlowModalProps {
  selectData?: MultiSelectType<SelectItemInterface>[];
  flow?: GroupFlowsListInterface;
  mode: ModeForm;
}

export const FlowModal: ModalComponentType<AddFlowModalProps> = ({ onClose, selectData, flow, mode }) => {
  const dispatch = useAppDispatch();

  const isAdd = mode === 'add';

  const activeGroup = useSelector(getActiveGroup);
  const activeGroupId = activeGroup?.id;

  const flowId = flow?.id;
  const flowName = flow?.name;

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<GroupFlowFormType>({
    defaultValues: {
      access: flow?.groupType || defaultSelectedAccess,
      ...(selectData && { flow: selectData[0].value }),
    },
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<GroupFlowFormType> = useCallback(
    async ({ access, flows }) => {
      try {
        if (!isDirty || !activeGroupId || isSubmitting) {
          return null;
        }

        if (isAdd) {
          const response = await dispatch(
            createGroupFlowAction({
              flows,
              type: access,
              groupId: activeGroupId,
            }),
          );

          if (response) {
            dispatch(loadGroupFlowsAction(activeGroupId));
          }
        }

        if (!isAdd && flowId && flowName) {
          const response = await dispatch(
            updateGroupFlowAction({
              flowId,
              groupId: activeGroupId,
              type: access,
            }),
          ).unwrap();

          if (response) {
            dispatch(updateGroupFlowsAction({ flow: { id: flowId, name: flowName, groupType: access } }));
          }
        }

        onClose();
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeGroupId, flowId, flowName, isSubmitting, isAdd, isDirty, onClose],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={isSubmitting} />

      <Controller
        render={({ field }) => <Select title="Права" options={accessItems} width="100%" {...field} value={field.value} />}
        name="access"
        control={control}
      />

      {isAdd && selectData && (
        <Controller
          name="flows"
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange } }) => (
            <MultiSelect
              label="К потоку"
              noOptionsText="Потоки не найдены"
              onChange={(item) => onChange(item.map((el) => el.value))}
              options={selectData}
            />
          )}
        />
      )}

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Добавить" iconSize="normal" heightSize="normal" type="submit" />
      </FlexContainer>
    </Form>
  );
};
