import styled, { css } from 'styled-components';
import { backgroundOpacityMixin } from 'constants/styles';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

interface FolderContainerProps {
  isSelected: boolean;
}

export const FolderElementContainer = styled(FlexContainer)<FolderContainerProps>`
  position: relative;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: var(${ColorVarsEnum.Level_5_application});

      :after {
        ${backgroundOpacityMixin};
        opacity: 0.1;
        z-index: 0;
      }
    `}

  :hover:after {
    ${backgroundOpacityMixin};
    opacity: 0.05;
    z-index: 0;
  }
`;
