import { useSelector } from 'react-redux';
import { getModals } from 'store/reducers/modals/getters';
import { ModalUniversal } from 'modules/ui/ModalUniversal';
import React from 'react';

export const GlobalModals = () => {
  const modalsData = useSelector(getModals);

  return (
    <>
      {modalsData.map(({ name, componentProps, Component, modalSettings }) => {
        const { onClose } = componentProps || {},
          { headerText, maxHeight, width, padding, position, disableModalPortal } = modalSettings || {};

        if (Component) {
          return (
            <ModalUniversal
              key={name}
              disabledOutsideClose
              headerText={headerText || 'Изменить'}
              onClose={onClose}
              width={width || '500px'}
              maxHeight={maxHeight || '90vh'}
              height="auto"
              position={position}
              padding={padding}
              disablePortal={disableModalPortal}
              open
            >
              <Component {...componentProps} />
            </ModalUniversal>
          );
        }

        return null;
      })}
    </>
  );
};
