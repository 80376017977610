import { AddIcon } from 'assets/icons/withContainer';
import { Button } from 'modules/ui';
import { v4 as uuidv4 } from 'uuid';
import { FlexContainer } from 'styles/FlexContainer';
import { ActivateObjectInterface, ActiveObjectFilters } from 'store/reducers/visualisations/types';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import { FilterCard } from './components/FilterCard';
import { FilterMapInterface } from 'store/reducers/filters/types';

interface FilterActivationProps {
  data: FilterMapInterface;
  onChange: (payload: ActivateObjectInterface) => void;
}

export const FilterActivation = ({ data, onChange }: FilterActivationProps) => {
  const { events } = useGetActiveVisualisationSettings(),
    { activateObject } = events,
    { filters, isActive } = activateObject;

  const uniqueIdentification = uuidv4();

  const onAddNewFilter = (elementId: string) => () => {
    const newFilter: ActiveObjectFilters = { id: '', selectedValues: null, isActive: true, elementId };
    const payload = filters ? [...filters, newFilter] : [newFilter];

    onChange({ isActive, filters: payload });
  };

  const onDeleteFilter = (id: string) => () => {
    const newFilters = filters?.filter(({ elementId }: { elementId: string }) => elementId !== id);

    newFilters && onChange({ isActive, filters: newFilters });
  };

  const onUpdateFilter = (id: string, payload: Partial<ActiveObjectFilters>) => {
    if (filters) {
      const updatedFilters = filters.map((filter) => (filter.elementId === id ? { ...filter, ...payload } : filter));

      onChange({ isActive, filters: updatedFilters });
    }
  };

  return (
    <>
      {filters?.map(({ isActive, selectedValues, elementId, id }) => (
        <FilterCard
          key={elementId}
          id={id}
          elementId={elementId}
          data={data}
          currentFilters={filters}
          isActive={isActive}
          onDelete={onDeleteFilter(elementId)}
          selectedValues={selectedValues}
          onUpdateFilter={onUpdateFilter}
          handleChangeActivationStatus={() => onUpdateFilter(elementId, { isActive: !isActive })}
        />
      ))}
      <FlexContainer maxWidth="132px" marginTop="8px">
        <Button
          leftIcon={<AddIcon />}
          text="Добавить фильтр"
          width="auto"
          iconSize="normal"
          heightSize="small"
          onClick={onAddNewFilter(uniqueIdentification)}
        />
      </FlexContainer>
    </>
  );
};
