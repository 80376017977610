import { TaskRadio } from 'components/TaskRadio';
import { defaultCronDate } from 'constants/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Button, Switcher, TextField } from 'modules/ui';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch } from 'store';
import {
  addActiveDirectorAction,
  addActiveDirectorInfoAction,
  createActiveDirectorAction,
  loadActiveDirectorInfoAction,
  updateActiveDirectorAction,
  updateActiveDirectoriesAction,
} from 'store/reducers/adminActiveDirectories/actions';
import { ModalComponentType } from 'store/reducers/modals/types';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { ModeForm } from 'types/store';
import { v4 as uuidv4 } from 'uuid';
import { Form } from './styles';
import { ActiveDirectorFormDataCallbackInterface, ActiveDirectorFormDataInterface } from './types';

type ActiveDirectoriesModalProps = {
  mode: ModeForm;
  dataEdit?: ActiveDirectorFormDataInterface;
};

export const ActiveDirectoriesModal: ModalComponentType<ActiveDirectoriesModalProps> = ({ dataEdit, mode, onClose }) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const dataEditId = dataEdit?.id;

  const isAdd = mode === 'add';

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm<ActiveDirectorFormDataCallbackInterface>({
    mode: 'onSubmit',
    defaultValues: {
      isActive: true,
      cron: defaultCronDate,
      isAddNewUsers: false,
    },
  });

  const onSubmit: SubmitHandler<ActiveDirectorFormDataCallbackInterface> = useCallback(
    async (formData) => {
      if (!isDirty) {
        return null;
      }

      const { cron, url, isActive, groups, isAddNewUsers, users, authorizationPassword, authorizationLogin, name, domain } =
        formData;

      setLoading(true);
      try {
        const payload = {
          isActive,
          name,
          url,
          newUsers: isAddNewUsers,
          baseDN: domain,
          filterUsers: users,
          filterGroups: groups,
          login: authorizationLogin,
          cron,
        };

        let actionRes;

        if (isAdd) {
          actionRes = await dispatch(createActiveDirectorAction({ ...payload, password: authorizationPassword })).unwrap();
        } else if (dataEditId) {
          actionRes = await dispatch(
            updateActiveDirectorAction({
              id: dataEditId,
              ...payload,

              ...(authorizationPassword ? { password: authorizationPassword } : {}),
            }),
          ).unwrap();
        }

        if (!actionRes) {
          return;
        }

        const infoRes = await dispatch(loadActiveDirectorInfoAction({ id: actionRes.id })).unwrap();
        isAdd ? dispatch(addActiveDirectorAction(infoRes)) : dispatch(updateActiveDirectoriesAction({ activeDirector: infoRes }));
        dispatch(addActiveDirectorInfoAction(infoRes));

        if (!actionRes) {
          setLoading(false);
          return;
        }

        if (onClose) {
          onClose();
        }
      } catch (e) {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataEditId, isAdd, isDirty, onClose],
  );

  useEffect(() => {
    if (dataEdit) {
      const { authorizationLogin, authorizationPassword, groups, isAddNewUsers, users, isActive, cron, url, name, domain } =
        dataEdit;

      setValue('groups', groups);
      setValue('name', name);
      setValue('cron', cron);
      setValue('isActive', isActive);
      setValue('isAddNewUsers', isAddNewUsers);
      setValue('authorizationPassword', authorizationPassword);
      setValue('authorizationLogin', authorizationLogin);
      setValue('url', url);
      setValue('users', users);
      setValue('domain', domain);
    }
  }, [dataEdit, setValue]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={loading} />
      <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" height="100%" gap="12px" padding="12px">
        <Controller
          rules={{ required: true }}
          render={({ field }) => <TextField label="Название" width="100%" error={!!errors.name} {...field} />}
          name="name"
          control={control}
        />
        <Controller
          rules={{ required: true }}
          render={({ field }) => <TextField label="URL" width="100%" error={!!errors.url} {...field} />}
          name="url"
          control={control}
        />

        <FlexContainer flexDirection="column" gap="4px" width="100%">
          <PrimaryTextParagraph lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
            Авторизация
          </PrimaryTextParagraph>
          <FlexContainer flexDirection="row" alignItems="center" gap="8px">
            <Controller
              rules={{ required: true }}
              render={({ field }) => <TextField label="Логин" width="100%" error={!!errors.authorizationLogin} {...field} />}
              name="authorizationLogin"
              control={control}
            />
            <Controller
              rules={{ required: isAdd }}
              render={({ field }) => (
                <TextField type="password" label="Пароль" width="100%" error={!!errors.authorizationPassword} {...field} />
              )}
              name="authorizationPassword"
              control={control}
            />
          </FlexContainer>
        </FlexContainer>

        <FlexContainer flexDirection="column" gap="4px" width="100%">
          <PrimaryTextParagraph lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
            Фильтры импорта
          </PrimaryTextParagraph>
          <Controller
            rules={{ required: true }}
            render={({ field }) => <TextField label="Домен" width="100%" error={!!errors.domain} {...field} />}
            name="domain"
            control={control}
          />
          <Controller
            render={({ field }) => <TextField label="Пользователи" width="100%" {...field} />}
            name="users"
            control={control}
          />
          <Controller
            render={({ field }) => <TextField label="Группы" width="100%" {...field} />}
            name="groups"
            control={control}
          />
        </FlexContainer>

        <Controller
          name="isActive"
          control={control}
          render={({ field }) => (
            <FlexContainer flexDirection="row" alignItems="center" gap="8px">
              <Switcher id={uuidv4()} value={field.value} onClick={field.onChange} />

              <PrimaryTextParagraph lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                Регулярная синхронизация
              </PrimaryTextParagraph>
            </FlexContainer>
          )}
        />

        <Controller
          name="cron"
          control={control}
          render={({ field }) => <TaskRadio onChange={field.onChange} value={field.value} />}
        />

        <Controller
          name="isAddNewUsers"
          control={control}
          render={({ field }) => (
            <FlexContainer flexDirection="row" alignItems="center" gap="8px" marginTop="19px">
              <Switcher id={uuidv4()} value={field.value} onClick={field.onChange} />

              <PrimaryTextParagraph lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                Добавлять новых пользователей
              </PrimaryTextParagraph>
            </FlexContainer>
          )}
        />

        <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
          <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={false} onClick={onClose} />
          <Button
            text={isAdd ? 'Создать' : 'Изменить'}
            iconSize="normal"
            needBackground={true}
            heightSize="normal"
            type="submit"
          />
        </FlexContainer>
      </FlexContainer>
    </Form>
  );
};
