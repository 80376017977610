import { Combobox } from 'modules/ui/Combobox';
import TextField from 'modules/ui/TextField';
import { DefaultDataSettingsInterface } from 'store/reducers/visualisations/types';
import { ISelectField } from 'interface';
import { FictionalMetricFieldInterface } from 'modules/settingsContainer/common/FictionalMetricField/types';
import { FlexContainer } from 'styles/FlexContainer';
import React from 'react';
import { CustomRequestSettings, CustomRequestSettingsProps } from 'modules/settingsContainer/common/data/CustomRequestSettings';

export interface FictionalMetricFieldProps<IsFunctionButtonHide extends boolean = false>
  extends Pick<DefaultDataSettingsInterface, 'isRealData'>,
    Pick<CustomRequestSettingsProps, 'modelMetaData'> {
  options: ISelectField[];
  value: FictionalMetricFieldInterface;
  onChange: (params: Partial<FictionalMetricFieldInterface>) => void;
  disabled?: boolean;
  onCustomRequestChange?: IsFunctionButtonHide extends false ? CustomRequestSettingsProps['onChange'] : undefined;
  customRequest?: IsFunctionButtonHide extends false ? CustomRequestSettingsProps['value'] : undefined;
  isFunctionButtonHide?: IsFunctionButtonHide;
}

export const FictionalMetricField = <IsFunctionButtonHide extends boolean = false>({
  isRealData,
  options,
  value,
  onChange,
  disabled,
  isFunctionButtonHide = false as IsFunctionButtonHide,
  onCustomRequestChange,
  customRequest,
  modelMetaData,
}: FictionalMetricFieldProps<IsFunctionButtonHide>) => {
  const { fictionalData, fieldName } = value;

  return (
    <FlexContainer gap="10px" flex="1 1 0" alignItems="center">
      {!isFunctionButtonHide && (
        <FlexContainer flex="1 1 0">
          <CustomRequestSettings
            value={customRequest || ''}
            onChange={onCustomRequestChange as CustomRequestSettingsProps['onChange']}
            modelMetaData={modelMetaData}
          />
        </FlexContainer>
      )}
      {isRealData ? (
        <Combobox
          disableClearable
          needBackground={false}
          placeholder="Table_name"
          options={options}
          name="incisionFieldName"
          width="100%"
          disabled={disabled}
          minWidth="300px"
          value={
            fieldName && {
              name: fieldName,
              value: fieldName,
            }
          }
          onChange={({ value: fieldName }) => onChange({ fieldName })}
        />
      ) : (
        <TextField
          name="fictionalData"
          width="100%"
          needBackground={false}
          needBorderBottom={false}
          value={fictionalData.join(', ')}
          onChange={(e) => onChange({ fictionalData: e.target.value.split(', ') })}
          useDebounce
        />
      )}
    </FlexContainer>
  );
};
