import { createColumnHelper } from '@tanstack/react-table';
import { TableCell } from 'modules/ui/Table/TableCell';
import React, { useMemo } from 'react';
import {
  ActiveDirectoriesAndTasksTableType,
  DataListInterface,
} from 'components/admin/wrappers/ActiveDirectoriesAndTasksWrapper/types';
import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { LoadLayoutAdmin } from 'components/layouts/LoadLayoutAdmin';
import { FlexContainer } from 'styles/FlexContainer';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { formatDateString } from 'utils/dates';
import { defaultDateTimeFormat } from 'constants/dates';
import { TableWithSearch } from 'modules/ui/TableWithSearch';
import { statusData } from 'components/admin/wrappers/ActiveDirectoriesAndTasksWrapper/constants';
import { NavigationsTabs } from 'modules/ui/tabs/NavigationsTabs';
import { Tab } from 'modules/ui/tabs/types';
import { ArrowIcon } from 'modules/ui/icons/ArrowIcon/styles';
import { NoopType, NoopValueType } from 'types/global';

type ActiveDirectoriesAndTasksWrapperProps = {
  dataList: DataListInterface[];
  loadingDatalist: boolean;
  tabs: Tab[];
  onDeleteItem: NoopValueType<DataListInterface>;
  onCreate: NoopType;
  onEdit: NoopValueType<DataListInterface>;
  onUpdate: NoopType;
  onRowClick: NoopValueType<DataListInterface>;
  onOpenDropdown: NoopType;
  openDropdown: boolean;
};

export const ActiveDirectoriesAndTasksWrapper = ({
  dataList,
  loadingDatalist,
  tabs,
  onDeleteItem,
  onCreate,
  onUpdate,
  onRowClick,
  onEdit,
  onOpenDropdown,
  openDropdown,
}: ActiveDirectoriesAndTasksWrapperProps) => {
  const columnHelper = createColumnHelper<ActiveDirectoriesAndTasksTableType>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Название',
        cell: (info) => (
          <TableCell
            text={info.getValue()}
            actions={{
              onRemove: async () => {
                await onDeleteItem(info.row.original.activeDirector);
              },
              onEdit: async () => {
                await onEdit(info.row.original.activeDirector);
              },
            }}
          />
        ),
      }),
      columnHelper.accessor('active', {
        header: 'Активно',
        cell: (info) => <TableCell text={info.getValue()} />,
      }),
      columnHelper.accessor('lastExecution', {
        header: 'Последнее выполнение',
        cell: (info) => <TableCell text={info.getValue()} />,
      }),
      columnHelper.accessor('status', {
        header: 'Статус',
        cell: (info) => {
          const { status } = info.row.original.activeDirector;

          const { name, Icon } = status ? statusData[status] : { name: '', Icon: undefined };

          return <TableCell text={name} Icon={Icon} />;
        },
      }),
      columnHelper.accessor('nextExecution', {
        header: 'Следующее выполнение',
        cell: (info) => <TableCell text={info.getValue()} />,
      }),
    ],
    [columnHelper, onDeleteItem, onEdit],
  );

  const rows: ActiveDirectoriesAndTasksTableType[] = useMemo(() => {
    if (!Array.isArray(dataList)) {
      return [];
    }

    return (dataList || [])?.map((activeDirector) => {
      const { name, isActive, lastStartAt, status, nextStartAt, id } = activeDirector;
      const activeText = isActive ? 'Да' : 'Нет';
      const lastExecutionDate = formatDateString({ dateString: lastStartAt, format: defaultDateTimeFormat });
      const nextExecutionDate = formatDateString({ dateString: nextStartAt, format: defaultDateTimeFormat });

      return {
        id,
        activeDirector,
        name: name,
        active: activeText,
        lastExecution: lastExecutionDate,
        status: status || '',
        nextExecution: nextExecutionDate,
      };
    });
  }, [dataList]);

  return (
    <LoadLayoutAdmin>
      <Body>
        <LoadingOverlay loading={loadingDatalist} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />

        <FlexContainer
          flexDirection="column"
          width="100%"
          height={openDropdown ? '50%' : '100%'}
          position="relative"
          transition="width 0.5s ease, height 0.5s ease"
          overflow="hidden"
        >
          <TableWithSearch
            updateButtonName="Обновить"
            addButtonName="Создать"
            filterColumnName="name"
            columns={columns}
            tableWithSearchMaxHeightContainer="calc(100vh - 220px)"
            tableWithSearchHeightContainer={openDropdown ? '85%' : 'auto'}
            tableHeight={openDropdown ? '50%' : '100%'}
            rowHeightVariant="small"
            rows={rows}
            onAddModal={onCreate}
            onUpdate={onUpdate}
            loading={loadingDatalist}
            onRowClick={(rowData: ActiveDirectoriesAndTasksTableType) => {
              onRowClick(rowData.activeDirector);
              !openDropdown && onOpenDropdown();
            }}
          />
        </FlexContainer>

        <FlexContainer width="100%" maxHeight="49%">
          <NavigationsTabs
            isOpen={openDropdown}
            IconHeader={<ArrowIcon isActive={openDropdown} />}
            onCloseMenu={onOpenDropdown}
            typeMenu="small"
            disabledTabs={false}
            tabs={tabs}
            width="100%"
            maxBodyHeight="calc(100vh - 260px)"
            bgNavigationsTabs={`var(${ColorVarsEnum.Level_5_application})`}
          />
        </FlexContainer>
      </Body>
    </LoadLayoutAdmin>
  );
};

const Body = styled.div`
  position: relative;
  max-width: 1464px;
  margin: 0 auto;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 32px;
  flex: 1;
  background: ${`var(${ColorVarsEnum.Level_5_application})`};
  flex-wrap: nowrap;
  gap: 5px;
`;
