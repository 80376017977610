import { FlexContainer } from 'styles/FlexContainer';
import { ModalUniversal } from 'modules/ui/ModalUniversal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getWizardModals } from 'store/reducers/modals/getters';

export const WizardModal = () => {
  const { onClose, stepDataList, defaultTitle, firstStepComponent, isOpen, projectId } = useSelector(getWizardModals);

  const [activeStepComponent, setActiveStepComponent] = useState<keyof typeof stepDataList>(firstStepComponent);
  const [historySteps, setHistorySteps] = useState<(keyof typeof stepDataList)[]>([firstStepComponent]);

  const activeStepData = useMemo(
    () => stepDataList[activeStepComponent || firstStepComponent] || {},
    [activeStepComponent, firstStepComponent, stepDataList],
  );

  const { title, Component: ActiveStepComponent, width, maxHeight, height } = activeStepData;

  const onNext = useCallback((type: keyof typeof stepDataList) => {
    setHistorySteps((historyStep) => [...historyStep, type]);
    setActiveStepComponent(type);
  }, []);

  const goBack = useCallback(() => {
    const updatedHistoryStep = historySteps.slice(0, -1);
    const previousStep = updatedHistoryStep[updatedHistoryStep.length - 1];

    setActiveStepComponent(previousStep);
    setHistorySteps(updatedHistoryStep);
  }, [historySteps]);

  useEffect(() => {
    setActiveStepComponent(firstStepComponent);
  }, [firstStepComponent]);

  const onCloseModal = useCallback(() => {
    onClose();
    setHistorySteps([]);
  }, [onClose]);

  return (
    <ModalUniversal
      headerText={title || defaultTitle}
      onClose={onCloseModal}
      width={width || '500px'}
      maxHeight={maxHeight || '90vh'}
      height={height || 'auto'}
      open={isOpen}
      disabledOutsideClose
      position="static"
    >
      <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" padding="12px">
        {ActiveStepComponent && <ActiveStepComponent onNext={onNext} onClose={onClose} onBack={goBack} projectId={projectId} />}
      </FlexContainer>
    </ModalUniversal>
  );
};
