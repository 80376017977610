import {
  IdInterface,
  LoadingInterface,
  NameInterface,
  PositionConfigInterface,
  ProjectIdInterface,
  UsersAndGroupInterface,
} from 'types/store';
import { Model } from 'models/model/Model';
import { MapRecordType } from 'types/global';
import { ExportType } from 'modules/settingsContainer/ContextMenuWrapper/types';

export interface Table {
  table: string;
}

export interface DB {
  db: string | null;
}

export interface Alias {
  alias: string;
}

/* For Column`s meta sata like type (data-time, string, number) etc */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MetaColumn {}

export type Columns = Record<string, MetaColumn>;

export interface Column extends Table, DB {
  column: string;
}

export interface Custom {
  value: string;
}

export type JoinTypeType = 'INNER' | 'FULL' | 'RIGHT' | 'LEFT';

export type JoinType = {
  mode: 'JOIN';
  type: JoinTypeType;
};

export type EqualRelationType = '=';
export type ConditionRelationType = '>' | '≥' | '<' | '≤' | '≠';
export type OperatorRelationType = EqualRelationType | ConditionRelationType;
export type ANDType = 'AND';
export type ORType = 'OR';
export type OperatorType = OperatorRelationType | ANDType | ORType;

export type ConditionValueType = Column | Custom;

export interface LinkRelationType {
  left: Column;
  right: Column;
}

export interface Condition {
  left: ConditionValueType | Condition;
  operator: OperatorType;
  right: ConditionValueType | Condition;
}

export interface RelationInterface {
  type: JoinType;
  link: LinkRelationType;
  condition: Condition | null;
}

export interface IsHeadInterface {
  isHead: boolean;
}

export interface ModelItemType extends Table, DB, Alias, IsHeadInterface {
  relations: RelationInterface[] | null;
}

export interface MetaModelItemType extends Alias, Table {
  config: PositionConfigInterface;
}

export interface ModelTabInterface extends IdInterface, NameInterface {
  zoom: number;
  models: Model[];
  localModels?: boolean;
}

export type TableType = Record<string, { type: string }>;
export type TablesType = MapRecordType<TableType>;

export type TabsType = MapRecordType<ModelTabInterface>;

export type TablePreviewType = FastBoard.API.ClickHouseResponse;
export type TablePreviewsType = MapRecordType<TablePreviewType>;

export interface ActiveModelItemAliasInterface extends Table, Alias {}

export interface MetaColumnType {
  name: string;
  type: string;
}

export interface ModelFromMetaType extends Alias, Table {
  columns: MetaColumnType[];
}

export interface ModelFromItemType extends NameInterface, IdInterface {
  from: string | null;
  meta: ModelFromMetaType[];
}

export type ModelsFromType = Record<string, ModelFromItemType | undefined>;

export interface AllModelRulesListInterface extends IdInterface {
  name: string;
  isActive: boolean;
}

export interface AllModelRulesInterface extends LoadingInterface {
  allModelRulesList: AllModelRulesListInterface[];
}

export interface VariableValuesInterface {
  name: string;
  value: string;
}

export interface AllVariableValuesInterface {
  name: string;
  defaultValue: string;
}

export interface ModelRuleUserAndGroupInterface extends IdInterface {
  name: string;
  variableValues: VariableValuesInterface[];
}

export interface ModelRuleDataInterface extends IdInterface {
  projectId: string;
  modelId: string;
  name: string;
  query: string;
  isActive: boolean;
  variableNames: {
    name: string;
  }[];
}

export interface ModelRuleInterface extends LoadingInterface {
  modelRulesData: ModelRuleDataInterface | null;
}

export interface ModelRuleDataUsersAndGroupsInterface {
  modelRule: ModelRuleInterface;
  modelRuleUsers: ModelRuleUserAndGroupInterface[];
  modelRuleGroups: ModelRuleUserAndGroupInterface[];
  activeModelRuleGroup: ModelRuleUserAndGroupInterface | null;
  activeModelRuleUser: ModelRuleUserAndGroupInterface | null;
  users: UsersAndGroupInterface[];
  groups: UsersAndGroupInterface[];
  allVariables: AllVariableValuesInterface[];
}

/* Store SLice Type */

export interface ModelsStateInterface {
  tabs: TabsType;
  tabsLoading: boolean;
  tables: TablesType;
  tablesLoading: boolean;
  tablePreviews: TablePreviewsType;
  tablePreviewLoading: Set<string>;
  activeTabId: string | null;
  activeModelItemAlias: ActiveModelItemAliasInterface | null;
  modelsFrom: ModelsFromType;
  modelsFromLoading: boolean;
  allModelRules: AllModelRulesInterface;
  modelRuleDataUsersAndGroups: ModelRuleDataUsersAndGroupsInterface;
}

/* Action Types */

export enum ModelsActionsTypes {
  SET_ACTIVE_TAB_ID = 'SET_ACTIVE_TAB_ID',
  SET_ACTIVE_MODEL_ITEM_ALIAS = 'SET_ACTIVE_MODEL_ITEM_ALIAS',
  ADD_TAB = 'ADD_TAB',
  ADD_TABLE_TO_ACTIVE_TAB = 'ADD_TABLE_TO_ACTIVE_TAB',
  REMOVE_TAB = 'REMOVE_TAB',
  UPDATE_TAB = 'UPDATE_TAB',
  UPDATE_ZOOM = 'UPDATE_ZOOM',
  LOAD_MODELS = 'LOAD_MODELS',
  UPLOAD_MODELS = 'UPLOAD_MODELS',
  LOAD_META_MODELS = 'LOAD_META_MODELS',
  UPLOAD_META_MODELS = 'UPLOAD_META_MODELS',
  LOAD_TABLES = 'LOAD_TABLES',
  LOAD_TABS = 'LOAD_TABS',
  UPDATE_MODEL_RULE_BY_ID = 'UPDATE_MODEL_RULE_BY_ID',
  UPLOAD_TABS = 'UPLOAD_TABS',
  LOAD_MODELS_FROM_DATA = 'LOAD_MODELS_FROM_DATA',
  LOAD_TABLE_PREVIEW = 'LOAD_TABLE_PREVIEW',
  LOAD_ALL_MODEL_RULES = 'LOAD_ALL_MODEL_RULES',
  LOAD_TABLE_PREVIEW_BY_NAME = 'LOAD_TABLE_PREVIEW_BY_NAME',
  CLEAR_MODEL_STORE = 'CLEAR_MODEL_STORE',
  CLEAR_RULE_MODEL = 'CLEAR_RULE_MODEL',
  DELETE_MODEL_RULE = 'DELETE_MODEL_RULE',
  DELETE_BY_ID_MODEL_RULE = 'DELETE_BY_ID_MODEL_RULE',
  ADD_MODEL_RULE = 'ADD_MODEL_RULE',
  CREATE_MODEL_RULE = 'CREATE_MODEL_RULE',
  MODEL_RULE = 'MODEL_RULE',
  DELETE_BY_ID_RULE_USER = 'DELETE_BY_ID_RULE_USER',
  DELETE_BY_ID_RULE_GROUP = 'DELETE_BY_ID_RULE_GROUP',
  LOAD_GROUPS_RULE_MODEL = 'LOAD_GROUPS_RULE_MODEL',
  LOAD_USERS_RULE_MODEL = 'LOAD_USERS_RULE_MODEL',
  CREATE_RULE_USERS = 'CREATE_RULE_USERS',
  UPDATE_RULE_USERS = 'UPDATE_RULE_USERS',
  CREATE_RULE_GROUPS = 'CREATE_RULE_GROUPS',
  UPDATE_RULE_MOVE_TO_GROUPS = 'UPDATE_RULE_MOVE_TO_GROUPS',
  ACTIVE_RULE_MOVE_USER_TO_ID = 'ACTIVE_RULE_MOVE_USER_TO_ID',
  UPDATE_RULE_MODEL = 'UPDATE_RULE_MODEL',
  ACTIVE_RULE_MOVE_GROUP_TO_ID = 'ACTIVE_RULE_MOVE_GROUP_TO_ID',
  UPDATE_MODEL_RULE = 'UPDATE_MODEL_RULE',
  UPDATE_ALL_VARIABLES = 'UPDATE_ALL_VARIABLES',
  LOAD_EXPORTING_USERS_AND_GROUPS = 'LOAD_EXPORTING_USERS_AND_GROUPS',
  IMPORTING_USERS_AND_GROUPS = 'IMPORTING_USERS_AND_GROUPS',
  UPDATE_MODEL_RULE_NAME = 'UPDATE_MODEL_RULE_NAME',
}

/* Payloads */

export interface ModelPayloadInterface extends Pick<ModelTabInterface, 'id' | 'name'> {
  modelItems: ModelItemType[];
}

export interface MetaModelPayloadInterface extends IdInterface {
  modelItems: MetaModelItemType[];
  zoom: number;
}

export interface LoadMetaModelsPayloads {
  models: MetaModelPayloadInterface[];
}

export type TableFieldResponse = TablesType;

export type AddNewTabPayload = ModelTabInterface;

export interface UpdateTabByIdPayload extends IdInterface {
  data: Partial<ModelTabInterface>;
}

export interface LoadTablePreviewPayloads extends ProjectIdInterface, Table {}

export interface AllModelRulesPayload {
  projectId: string;
  modelId: string;
}

export interface ModelRulePayload {
  projectId: string;
  ruleId: string;
}

export interface UpdateModelRuleNamePayload {
  id: string;
  name: string;
  projectId: string;
}

export interface ExcludeGroupsAndUsersPayload {
  excludeGroups?: boolean;
  excludeUsers?: boolean;
}

export interface CreateModelRulePayload {
  name: string;
  projectId: string;
  modelId: string;
  file?: File | null;
}

export interface UpdateRulePayload {
  data: FastBoard.API.CreateAndUpdateRuleDTO;
  projectId: string;
}

export interface LoadExportingUsersAndGroupsPayload {
  projectId: string;
  ruleId: string;
  typeFile: ExportType;
}

export interface LoadExportingUsersAndGroupsPayload {
  projectId: string;
  ruleId: string;
  typeFile: ExportType;
}

export type ImportingUsersAndGroupsRulePayload = {
  ruleId: string;
  projectId: string;
  file: File;
};

export interface UpdateModelRuleByIdPayload {
  modelRule: AllModelRulesListInterface;
}
