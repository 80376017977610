import { FilterMapDataType, FilterType } from 'store/reducers/filters/types';
import { useSelector } from 'react-redux';
import { getActiveFilter } from 'store/reducers/filters/getters';
import { referenceFilters } from 'store/reducers/filters/constants';
import { useModelIdValue } from 'utils/hooks/visualisation/modelIdValue';
import { getTableColumnsByModelId } from 'store/reducers/models/getters';

interface GetActiveFilterParams<Type extends FilterType> {
  type: Type;
}

export const useGetActiveFilter = <Type extends FilterType>({ type }: GetActiveFilterParams<Type>) => {
  const data = useSelector(getActiveFilter) || referenceFilters[type];

  const modelIdValue = useModelIdValue(data.modelId || '');
  const tableFields = useSelector(getTableColumnsByModelId(modelIdValue));

  return { data: data as FilterMapDataType[Type], modelIdValue, tableFields };
};
