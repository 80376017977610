import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { PositionSettingType, AutoEndManualType } from 'types/store';
import { AbsolutePositionType } from 'types/styles';

export const locationOptions: SettingsRadioItem<AbsolutePositionType>[] = [
  { value: 'bottom', label: 'Внизу' },
  { value: 'top', label: 'Вверху' },
  { value: 'left', label: 'Слева' },
  { value: 'right', label: 'Справа' },
];

export const positionOptions: (isHorizontalLocation: boolean) => SettingsRadioItem<PositionSettingType>[] = (
  isHorizontalLocation,
) => [
  { value: 'flex-start', label: isHorizontalLocation ? 'Вверху' : 'Слева' },
  { value: 'center', label: 'По центру' },
  { value: 'flex-end', label: isHorizontalLocation ? 'Внизу' : 'Справа' },
];

export const typeOptions: SettingsRadioItem<AutoEndManualType>[] = [
  { value: 'auto', label: 'Авто' },
  { value: 'manual', label: 'Вручную' },
];
