import { UsersSliceStateInterface } from 'store/reducers/adminUsers/types';

export const initialAdminUsersStoreState: UsersSliceStateInterface = {
  users: {
    usersList: [],
    loading: false,
  },
  userSources: {
    userSourcesList: [],
    loading: false,
  },
  userGroups: {
    groupsList: [],
    loading: false,
  },
  userFlows: {
    userFlowsList: [],
    loading: false,
  },
  activeUser: null,
  usersDraftProjects: {
    flowProjectsList: [],
    loading: false,
  },
  usersDraftProjectHistoryVersion: {
    flowProjectHistoryVersionList: [],
    loading: false,
  },
  activeUserDraftProjectId: null,
  shortUserDraftProject: {
    sortOrder: 'ascending',
    sortType: 'lastModified',
  },
  adminDraftInfo: null,
  draftsOfAllUsers: {
    draftsOfAllUsersList: [],
    loading: false,
  },
};
