import { FormattingInterface } from 'store/reducers/visualisations/types';
import { useMemo } from 'react';
import { chainFormatter } from 'utils/formatting';

export type FormattingFunctionReturnType = (params: string | number | null | undefined) => string;
export type FormattingRecordType = Record<string, FormattingFunctionReturnType | undefined>;

export const useFormat = (data: Record<string, FormattingInterface>) => {
  const formatting = useMemo(
    () =>
      Object.entries(data).reduce<FormattingRecordType>(
        (data, [fieldName, formatting]) => ({
          ...data,
          [fieldName]: formatting.isShow ? (value) => chainFormatter(formatting.formats, String(value)) : undefined,
        }),
        {},
      ),
    [data],
  );

  return {
    formatting,
  };
};
