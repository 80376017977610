import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { OpenWindowType } from 'store/reducers/visualisations/types';

export const deleteButtonMarginRight = '8px';
export const deleteButtonSize = '30px';
export const addButtonMarginLeft = parseInt(deleteButtonMarginRight) + parseInt(deleteButtonSize) + 'px';

export const openWidowTypeOptions: SettingsRadioItem<OpenWindowType>[] = [
  { value: 'self', label: 'В том же окне' },
  { value: 'blank', label: 'В новом окне' },
];
