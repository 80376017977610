import { ColorPicker, ColorPickerProps, LargeCircleActivator } from 'modules/settingsContainer/ColorPicker';
import { NoopType } from 'types/global';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { DeleteIcon } from 'assets/icons/withContainer';
import React from 'react';
import { DeleteButtonWrapper, PickerItemWrapper } from './styles';

interface ColorAndImageByPickerProps extends Pick<ColorPickerProps, 'value' | 'onChange'> {
  name?: string | number;
  onDelete: NoopType;
}

export const ColorByPicker = ({ value, onChange, name, onDelete }: ColorAndImageByPickerProps) => (
  <PickerItemWrapper>
    <ColorPicker onChange={onChange} Activator={LargeCircleActivator} closeOnClick type="color" value={value} />
    {name && (
      <PrimaryTextSpan lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
        {name}
      </PrimaryTextSpan>
    )}
    <DeleteButtonWrapper onClick={onDelete}>
      <IconWrapper
        hoverColorVar={ColorVarsEnum.Level_3}
        colorVar={ColorVarsEnum.Level_3}
        iconWidth="15px"
        iconHeight="13px"
        Icon={DeleteIcon}
      />
    </DeleteButtonWrapper>
  </PickerItemWrapper>
);
