import { keysTrackingTypes, trackingPaths } from 'store/reducers/history/constants';
import { addPaths, exceptionPaths, TrackingPathsTypes } from 'store/reducers/history/types';
import { clearHistoryAction, setStepInHistoryAction } from 'store/reducers/history/actions';
import { ThunkMiddleware } from 'redux-thunk/src/types';

const loggerAllSteps: ThunkMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const beforeStore = getState();
    const result = next(action);
    const afterStore = getState();

    if (
      beforeStore.projectPages.activePageId !== afterStore.projectPages.activePageId &&
      afterStore.projectPages.activePageId === ''
    ) {
      dispatch(clearHistoryAction());
    }

    if (keysTrackingTypes.includes(action.type)) {
      const paths = trackingPaths[action.type as TrackingPathsTypes].paths;
      const isExceptions = trackingPaths[action.type as TrackingPathsTypes]?.exceptions?.(action);
      const beforeData = paths(beforeStore, action.payload.id);
      const afterData = paths(afterStore, action.payload.id);

      if (!isExceptions) {
        if (
          JSON.stringify(beforeData) !== JSON.stringify(afterData) ||
          ((exceptionPaths.includes(action.type) || addPaths.includes(action.type)) && (beforeData || afterData))
        ) {
          const data = {
            step: {
              id: action.payload.id || null,
              data: beforeData || afterData,
              type: action.type,
              pageId: afterStore.projectPages.activePageId,
              contextLayers: beforeStore.board.pagesLayers[beforeStore.projectPages.activePageId],
            },
          };
          dispatch(setStepInHistoryAction(data));
        }
      }
    }

    return result;
  };

export default loggerAllSteps;
