import React from 'react';
import { BooleanSettings } from 'modules/settingsContainer/common/BooleanSettings';
import { NoopValueType } from 'types/global';

interface AlwaysOpenSettingsInterface {
  value: boolean;
  onChange: NoopValueType<boolean>;
}

export const AlwaysOpenSettings = ({ value, onChange }: AlwaysOpenSettingsInterface) => {
  return <BooleanSettings titleText="Всегда открыт" value={value} onChange={onChange} />;
};
