import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCell } from 'modules/ui/Table/TableCell';
import { FlowModal } from 'components/admin/groups/elements/Flows/Modal/FlowModal';
import { useAppDispatch } from 'store';
import {
  deleteByIdGroupFlowAction,
  deleteGroupFlowAction,
  loadGroupFlowsAction,
  loadNoGroupFlowsAction,
} from 'store/reducers/adminGroups/actions';
import { getActiveGroup, getGroupFlows } from 'store/reducers/adminGroups/getters';
import { TableWithSearch } from 'modules/ui/TableWithSearch';
import {
  closeConfirmationModalAction,
  closeModalAction,
  openConfirmationModalAction,
  openModalTypedAction,
} from 'store/reducers/modals/actions';
import { useSelector } from 'react-redux';
import { FlexContainer } from 'styles/FlexContainer';
import { accessText, addGroupFlow, editGroupFlow } from 'components/admin/groups/constants';
import { GroupFlowsListInterface } from 'store/reducers/adminGroups/types';

export const AdminGroupsFlowsTab: FC = () => {
  const dispatch = useAppDispatch();

  const activeGroup = useSelector(getActiveGroup);
  const activeGroupId = activeGroup?.id;
  const { loading, groupFlowsList } = useSelector(getGroupFlows);

  useEffect(() => {
    if (activeGroupId) {
      dispatch(loadGroupFlowsAction(activeGroupId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGroup]);

  const onDelete = useCallback(
    async (flow: GroupFlowsListInterface) => {
      try {
        if (!activeGroupId) {
          return;
        }

        const flowId = flow.id;
        const response = await dispatch(deleteGroupFlowAction({ flowId, groupId: activeGroupId })).unwrap();

        if (response) {
          dispatch(deleteByIdGroupFlowAction(flowId));
          dispatch(closeConfirmationModalAction());
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeGroupId],
  );

  const onDeleteModal = useCallback(
    async (flow: GroupFlowsListInterface) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          description: `Действительно удалить доступ группы к потоку «${flow.name}»?`,
          onConfirm: () => onDelete(flow),
          titleText: 'Удаление доступа',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  const onCloseEditGroupFlowModal = () => dispatch(closeModalAction(editGroupFlow));

  const onEditModal = useCallback(
    (flow: GroupFlowsListInterface) =>
      dispatch(
        openModalTypedAction({
          Component: FlowModal,
          componentProps: {
            onClose: onCloseEditGroupFlowModal,
            flow,
            mode: 'edit',
          },
          modalSettings: {
            headerText: 'Изменение прав доступа',
            width: '320px',
          },
          name: editGroupFlow,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseEditGroupFlowModal],
  );

  const onCloseAddGroupFlowModal = () => dispatch(closeModalAction(addGroupFlow));

  const onAddModal = useCallback(
    async () => {
      try {
        if (!activeGroupId) {
          return;
        }

        const noGroupFlows = await dispatch(loadNoGroupFlowsAction({ groupId: activeGroupId })).unwrap();
        const selectData = noGroupFlows.map((flow) => ({ title: flow.name, value: flow.id }));

        dispatch(
          openModalTypedAction({
            Component: FlowModal,
            componentProps: {
              onClose: onCloseAddGroupFlowModal,
              selectData,
              mode: 'add',
            },
            modalSettings: {
              headerText: 'Добавление доступа',
            },
            name: addGroupFlow,
          }),
        );
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeGroupId, onCloseAddGroupFlowModal],
  );

  const columnHelper = createColumnHelper<GroupFlowsListInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Название потока',
        cell: (info) => (
          <TableCell
            text={info.getValue()}
            actions={{
              onRemove: async () => {
                await onDeleteModal(info.row.original);
              },
            }}
          />
        ),
      }),
      columnHelper.accessor('groupType', {
        header: 'Права',
        cell: (info) => {
          const rowData = info.row.original;

          return (
            <TableCell
              text={accessText[rowData.groupType]}
              actions={{
                onEdit: async () => {
                  await onEditModal(info.row.original);
                },
              }}
            />
          );
        },
      }),
    ],
    [columnHelper, onDeleteModal, onEditModal],
  );

  return (
    <FlexContainer position="relative" width="100%" padding="24px" maxWidth="800px">
      <TableWithSearch
        filterColumnName="name"
        columns={columns}
        rows={groupFlowsList}
        onAddModal={onAddModal}
        loading={loading}
      />
    </FlexContainer>
  );
};
