import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSliceFn } from 'constants/store';
import { loadVersionStrategyAction } from 'store/reducers/adminSettings/actions';
import { VersionStrategy } from 'store/reducers/adminSettings/types';
import { initialAdminSettingsStoreState } from './constants';

export const adminSettingsSlice = createSlice({
  name: 'adminSettings',
  initialState: initialAdminSettingsStoreState,
  reducers: {
    setSlice: setSliceFn,
    setVersionStrategy: (state, { payload }: PayloadAction<VersionStrategy>) => {
      state.versionStrategy.versionStrategyData = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadVersionStrategyAction.pending, (state) => {
      state.versionStrategy = { ...state.versionStrategy, loading: true };
      state.versionStrategy.versionStrategyData = null;
    });
    builder.addCase(loadVersionStrategyAction.fulfilled, (state, { payload }) => {
      state.versionStrategy = { ...state.versionStrategy, loading: false };
      state.versionStrategy.versionStrategyData = payload;
    });
    builder.addCase(loadVersionStrategyAction.rejected, (state) => {
      state.versionStrategy = { ...state.versionStrategy, loading: false };
      state.versionStrategy.versionStrategyData = null;
    });
  },
});

export const { setSlice, setVersionStrategy } = adminSettingsSlice.actions;

export default adminSettingsSlice.reducer;
