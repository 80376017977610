import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { ColorAndImageByInterfaceType } from 'store/reducers/visualisations/types';

export const getConditionOptions = () => {
  return [
    {
      value: 'value',
      label: 'Вручную',
    },
    {
      value: 'condition',
      label: 'По условию',
    },
  ] as SettingsRadioItem<ColorAndImageByInterfaceType>[];
};
