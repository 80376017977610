import React from 'react';
import { useAppDispatch } from 'store';
import { updateDataSettingsAction } from 'store/reducers/visualisations/actions';
import { BooleanSettings } from 'modules/settingsContainer/common/BooleanSettings';
import { BooleanSettingsControlInterface } from 'modules/settingsContainer/common/BooleanSettings/types';

type RealDataSettingsProps = BooleanSettingsControlInterface;

export const RealDataSettings = ({ value, onChange: onExternalChange }: RealDataSettingsProps) => {
  const dispatch = useAppDispatch();

  const onLocalChange = (isRealData: boolean) => {
    dispatch(updateDataSettingsAction({ isRealData }));
  };

  const onChange = onExternalChange || onLocalChange;

  return <BooleanSettings titleText="Реальные данные" value={value} onChange={onChange} />;
};
