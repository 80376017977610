import React from 'react';
import { TextSettingInterface } from 'store/reducers/visualisations/types';
import { updateViewSettingsAction } from 'store/reducers/visualisations/actions';
import { useAppDispatch } from 'store';
import { TextSettings } from 'modules/settingsContainer/common/TextSettings';
import { TextSettingsControlInterface } from 'modules/settingsContainer/common/TextSettings/types';

type TextHintSettingsProps = TextSettingsControlInterface;

export const TextHintSettings = ({ value, onChange: onExternalChange }: TextHintSettingsProps) => {
  const dispatch = useAppDispatch();

  const onLocalChange = (hint: TextSettingInterface) => {
    dispatch(updateViewSettingsAction({ hint }));
  };

  const onChange = onExternalChange || onLocalChange;

  return <TextSettings value={value} onChange={onChange} titleText="Инфо" />;
};
