import { useContainerColors } from 'modules/visualisations/hooks/containerColors';
import { BoardContainer } from 'modules/workspace/components/BoardContainer';
import { FilterRender } from 'modules/workspace/components/FilterArea/constants';
import { AreaInterface } from 'modules/workspace/components/WorkAreaSpace/types';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { updateFilterActionById } from 'store/reducers/filters/actions';
import { getActiveFilter } from 'store/reducers/filters/getters';
import { FilterDataType } from 'store/reducers/filters/types';
import { getActiveVisualisationSettings } from 'store/reducers/visualisations/getters';
import { BoardPositionConfigInterface } from 'types/store';
import { FilterInfluencePopup } from '../FilterInfluencePopup';

export const Filter: AreaInterface<FilterDataType> = ({
  data,
  scale,
  isShowMode,
  isActive,
  whereQuery,
  isLoading,
  isError,
  isInfluenceEditing,
  onInfluenceChange,
}) => {
  const dispatch = useAppDispatch();

  const { id, positionConfig, type, disableDragging, borderSettings, showBackground, shadowSettings } = data;
  const visualizationData = useSelector(getActiveVisualisationSettings);
  const filterData = useSelector(getActiveFilter);

  const containerColors = useContainerColors({ borderSettings, shadowSettings, showBackground });

  const { Filter, MainSettings } = FilterRender[type];

  const visualizationInfluences = visualizationData?.events?.filterSettings?.filterInfluences;
  const filterVisualizationInfluences = filterData?.filterInfluences;
  const filterInfluences = visualizationInfluences || filterVisualizationInfluences || {};

  const onPositionConfigChange = (config: Partial<BoardPositionConfigInterface>) => {
    dispatch(
      updateFilterActionById({
        id,
        data: { positionConfig: { ...positionConfig, ...config } },
      }),
    );
  };

  const handleFilterInfluence = (payload: boolean) => () => {
    const events = visualizationData?.events;
    const filterSettings = events?.filterSettings;

    const data = {
      visualizationInfluences: {
        ...events,
        filterSettings: { ...filterSettings, filterInfluences: { ...filterInfluences, [id]: payload } },
      },
      filterInfluences: { ...filterInfluences, [id]: payload },
      isVisualization: !!visualizationData?.visualisationType,
    };

    onInfluenceChange(data);
  };

  return (
    <BoardContainer
      isLoading={isLoading}
      isShowMode={isShowMode}
      isActive={isActive}
      isError={isError}
      isInfluenceEditing={isInfluenceEditing}
      scale={scale}
      id={id}
      SettingComponent={MainSettings}
      config={positionConfig}
      onChange={onPositionConfigChange}
      disableDragging={disableDragging}
      shadowSettings={shadowSettings}
      borderSettings={borderSettings}
      {...containerColors}
    >
      {isInfluenceEditing && (
        <FilterInfluencePopup
          isActive={isActive}
          id={id}
          isInfluenceEditing={isInfluenceEditing}
          handleFilterInfluence={handleFilterInfluence}
          filterInfluences={filterInfluences}
        />
      )}
      <Filter data={data} whereQuery={whereQuery} isLoading={isLoading} />
    </BoardContainer>
  );
};

export const FilterArea = memo(Filter);
