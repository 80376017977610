import {
  BackgroundImagesSettingsInterface,
  TextCarouselAutoPlayInterface,
  TextImageType,
} from 'store/reducers/visualisations/types';
import { updateTextBackgroundSettingsAction } from 'store/reducers/visualisations/actions';
import { store } from 'store';
import { ProportionsImageSettingType } from 'types/store';

const dispatch = store.dispatch;

export const onChangeBackgroundTypeLink = (imagesLinkType: TextImageType) =>
  dispatch(
    updateTextBackgroundSettingsAction({
      imagesLinkType,
    }),
  );

export const onChangeProportionsType = (proportionsImageType: ProportionsImageSettingType) =>
  dispatch(
    updateTextBackgroundSettingsAction({
      proportionsImageType,
    }),
  );

export const onChangeCarouselSettings = (carouselAutoPlay: TextCarouselAutoPlayInterface) =>
  dispatch(
    updateTextBackgroundSettingsAction({
      carouselAutoPlay,
    }),
  );

export const onChangeBackgroundImage = (backgroundImages: BackgroundImagesSettingsInterface) =>
  dispatch(
    updateTextBackgroundSettingsAction({
      backgroundImages,
    }),
  );
