import { createAsyncThunk } from '@reduxjs/toolkit';
import { TState } from 'store/index';
import {
  addRedoHistoryStep,
  addUndoHistoryStep,
  setIsAnotherPage,
  setRedoHistoryStep,
  setSlice,
  setUndoHistoryStep,
} from 'store/reducers/history';
import { getHistoryStore, getRedoLengthStore, getUndoLengthStore } from 'store/reducers/history/getters';
import {
  CounteractionAddToLayerActionInterface,
  DataInterface,
  HistoryActionsTypes,
  ReturnBackgroundSettingsActionInterface,
  ReturnDataSettingsByIdTypedActionInterface,
  ReturnEventsSettingsActionInterface,
  ReturnFilterInterface,
  ReturnFilterSettingsActionInterface,
  ReturnHistoryAction,
  ReturnPositionConfigByIdActionInterface,
  ReturnProjectSettingsActionInterface,
  ReturnRemoveVisualisationByIdActionInterface,
  ReturnViewSettingsActionInterface,
  ReturnVisualisationActionInterface,
  SetIsAnotherPageActionPayload,
  StepInterface,
} from 'store/reducers/history/types';
import { initialHistoryStoreState, trackingPaths } from 'store/reducers/history/constants';
import { returnFromLayer, setActiveBoardElement } from 'store/reducers/board';
import {
  removeVisualisationById,
  returnBackgroundSettingsData,
  returnDataSettingsData,
  returnEventsData,
  returnPositionConfig,
  returnViewSettingsData,
} from 'store/reducers/visualisations';
import {
  addVisualisationByData,
  removeFromVisualisationLoadingListAction,
  removeVisualisationValuesAction,
} from 'store/reducers/visualisations/actions';
import { removeAstOfVisualisationActions } from 'store/reducers/ast/actions';
import { returnProjectSettings } from 'store/reducers/projectSettings';
import { addNewFilterByData, returnFilterById, returnFilterSettings } from 'store/reducers/filters';
import { getEnabledFilterByFilterId } from 'store/reducers/filters/getters';
import {
  disableFilterByIdAction,
  removeFilterValuesAction,
  removeFromFilterLoadingListAction,
} from 'store/reducers/filters/actions';
import { returnNewPalette, returnPaletteById, returnRemovePaletteById } from 'store/reducers/palettes';
import { ReturnNewPaletteInterface, ReturnPaletteByIdInterface } from 'store/reducers/palettes/types';
import { getActiveThemeId, getPaletteLinks } from 'store/reducers/themes/getters';
import { getPalettesAsArray } from 'store/reducers/palettes/getters';
import { updatePaletteLinkAction } from 'store/reducers/themes/actions';
import { returnNewTheme, returnRemoveThemeById, returnThemeSettingsById } from 'store/reducers/themes';
import { ReturnNewThemeInterface, ReturnThemeSettingsByIdInterface } from 'store/reducers/themes/types';
import { IdInterface } from 'types/store';
import { deleteFromLayerByIdAction, updatesLayersAction } from '../board/actions';

export const undoHistoryStepAction = createAsyncThunk(
  HistoryActionsTypes.UNDO_HISTORY_STEP_ACTION,
  (_, { dispatch, getState }) => {
    const state = getHistoryStore(getState() as TState);
    const store = getState() as TState;
    const undoLength = getUndoLengthStore(getState() as TState);

    if (undoLength) {
      const undoLastStep = state.undo[undoLength - 1];
      const { pageId, id, data, type, contextLayers } = undoLastStep;
      const sliceUndo = state.undo.slice(0, -1);
      const { paths, type: trackingPathsType, counterAction } = trackingPaths[type];

      if (pageId !== store.projectPages?.activePageId) {
        dispatch(
          setIsAnotherPageAction({
            value: {
              isAnother: true,
              redirectPageId: pageId,
            },
          }),
        );
        return;
      }
      if (id && !store.projectSettings.projectSettings.isViewMode) {
        dispatch(setActiveBoardElement(id));
      }

      if (contextLayers) {
        dispatch(updatesLayersAction({ pageId, layers: contextLayers }));
      }

      if (trackingPathsType === 'selfRepeating') {
        dispatch(
          counterAction({
            id,
            data,
          }),
        );
        dispatch(setUndoHistoryStep({ undo: sliceUndo }));
        dispatch(addRedoHistoryStep({ redo: undoLastStep }));
      } else {
        const present = {
          data: paths(store, id),
          id,
          type,
          pageId,
          contextLayers,
        };

        if (id) {
          dispatch(counterAction({ id, data }));
        } else {
          dispatch(counterAction({ data }));
        }
        dispatch(setUndoHistoryStep({ undo: sliceUndo }));
        dispatch(addRedoHistoryStep({ redo: present }));
      }
    }
  },
);

export const redoHistoryStepAction = createAsyncThunk(
  HistoryActionsTypes.REDO_HISTORY_STEP_ACTION,
  (_, { dispatch, getState }) => {
    const state = getHistoryStore(getState() as TState);
    const store = getState() as TState;
    const redoLength = getRedoLengthStore(getState() as TState);

    if (redoLength) {
      const redoLastStep = state.redo[redoLength - 1];
      const { pageId, id, data, type, contextLayers } = redoLastStep;
      const sliceRedo = state.redo.slice(0, -1);
      const { paths, type: trackingPathsType, counterAction, repeatAction } = trackingPaths[type];

      if (pageId !== store.projectPages?.activePageId) {
        dispatch(
          setIsAnotherPageAction({
            value: {
              isAnother: true,
              redirectPageId: redoLastStep?.pageId,
            },
          }),
        );
        return;
      }

      if (id && !store.projectSettings.projectSettings.isViewMode) {
        dispatch(setActiveBoardElement(id));
      }

      if (contextLayers) {
        dispatch(updatesLayersAction({ pageId, layers: contextLayers }));
      }

      if (trackingPathsType === 'selfRepeating') {
        repeatAction &&
          dispatch(
            repeatAction({
              id,
              data,
            }),
          );

        dispatch(setRedoHistoryStep({ redo: sliceRedo }));
        dispatch(addUndoHistoryStep({ undo: redoLastStep }));
      } else {
        const present = {
          data: paths(store, id),
          id,
          type,
          pageId,
          contextLayers,
        };

        if (redoLastStep.id) {
          dispatch(counterAction({ id, data }));
        } else {
          dispatch(counterAction({ data }));
        }
        dispatch(setRedoHistoryStep({ redo: sliceRedo }));
        dispatch(addUndoHistoryStep({ undo: present }));
      }
    }
  },
);

export const setStepInHistoryAction = createAsyncThunk(
  HistoryActionsTypes.SET_PAST_HISTORY_STEP_ACTION,
  (data: DataInterface, { dispatch, getState }) => {
    const state = getHistoryStore(getState() as TState);

    if (state.undo.length >= state.limitOfHistory) {
      const sliceUndo = state.undo.slice(1);
      dispatch(setUndoHistoryStep({ undo: sliceUndo }));
    }

    dispatch(setRedoHistoryStep({ redo: [] }));
    dispatch(addUndoHistoryStep({ undo: data.step }));
  },
);

export const clearHistoryAction = createAsyncThunk(HistoryActionsTypes.CLEAR_HISTORY_ACTION, (_, { dispatch }) => {
  dispatch(setSlice(initialHistoryStoreState));
});

export const setIsAnotherPageAction = createAsyncThunk<void, SetIsAnotherPageActionPayload>(
  HistoryActionsTypes.SET_IS_ANOTHER_PAGE_ACTION,
  ({ value }, { dispatch }) => {
    dispatch(setIsAnotherPage({ value }));
  },
);

//--------------------------------RETURN-------------------------------

export const returnPositionConfigByIdAction = createAsyncThunk<void, ReturnPositionConfigByIdActionInterface>(
  ReturnHistoryAction.RETURN_POSITION_CONFIG_BY_ID,
  ({ id, data }, { dispatch }) => {
    dispatch(returnPositionConfig({ id, positionConfig: data }));
  },
);

export const returnDataSettingsByIdTypedAction = createAsyncThunk<void, ReturnDataSettingsByIdTypedActionInterface>(
  ReturnHistoryAction.RETURN_DATA_SETTINGS,
  ({ id, data }, { dispatch }) => {
    dispatch(returnDataSettingsData({ id, dataSettings: data }));
  },
);

export const returnFilterSettingsAction = createAsyncThunk<void, ReturnFilterSettingsActionInterface>(
  ReturnHistoryAction.RETURN_FILTER_SETTINGS,
  ({ id, data }, { dispatch }) => {
    dispatch(returnFilterSettings({ id, data }));
  },
);

export const returnViewSettingsAction = createAsyncThunk<void, ReturnViewSettingsActionInterface>(
  ReturnHistoryAction.RETURN_VIEW_SETTINGS,
  ({ id, data }, { dispatch }) => {
    dispatch(returnViewSettingsData({ id, viewSettings: data }));
  },
);

export const returnEventsSettingsAction = createAsyncThunk<void, ReturnEventsSettingsActionInterface>(
  ReturnHistoryAction.RETURN_EVENTS_SETTINGS,
  ({ id, data }, { dispatch }) => {
    dispatch(returnEventsData({ id, events: data }));
  },
);

export const returnBackgroundSettingsAction = createAsyncThunk<void, ReturnBackgroundSettingsActionInterface>(
  ReturnHistoryAction.RETURN_BACKGROUND_SETTINGS,
  ({ id, data }, { dispatch }) => {
    dispatch(returnBackgroundSettingsData({ id, backgroundSettings: data }));
  },
);

export const returnPaletteSettingsAction = createAsyncThunk<void, ReturnPaletteByIdInterface>(
  ReturnHistoryAction.RETURN_PALETTE_SETTINGS,
  ({ data }, { dispatch }) => {
    dispatch(returnPaletteById({ ...data }));
  },
);

export const returnThemeSettingsAction = createAsyncThunk<void, ReturnThemeSettingsByIdInterface>(
  ReturnHistoryAction.RETURN_THEME_SETTINGS,
  ({ data }, { dispatch }) => {
    dispatch(returnThemeSettingsById({ ...data }));
  },
);

export const returnProjectSettingsAction = createAsyncThunk<void, ReturnProjectSettingsActionInterface>(
  ReturnHistoryAction.RETURN_PROJECT_SETTINGS,
  ({ data }, { dispatch, getState }) => {
    const store = getState() as TState;

    if (!store.projectSettings.projectSettings.isViewMode && !data.isViewMode) {
      dispatch(returnProjectSettings(data));
    }
  },
);

export const returnFilterByIdAction = createAsyncThunk<void, string>(
  ReturnHistoryAction.RETURN_FILTER_ID,
  (id: string, { dispatch, getState }) => {
    dispatch(removeFilterValuesAction(id));
    dispatch(removeFromFilterLoadingListAction(id));
    dispatch(returnFilterById({ id }));
    dispatch(deleteFromLayerByIdAction(id));

    const enabledFilter = getEnabledFilterByFilterId(id)(getState() as TState);
    if (enabledFilter) {
      dispatch(disableFilterByIdAction(enabledFilter.id));
    }
  },
);

//-------------------SELF REPEATING ACTION-------------------------

export const counteractionFilterFromLayerAction = createAsyncThunk<void, IdInterface>(
  ReturnHistoryAction.COUNTERACTION_FILTER_FROM_LAYER,
  (data, { dispatch }) => {
    if (data.id) {
      dispatch(returnFilterByIdAction(data.id));
    }
  },
);

export const repeatFilterFromLayerAction = createAsyncThunk<void, ReturnFilterInterface>(
  ReturnHistoryAction.REPEAT_FILTER_FROM_LAYER,
  (data, { dispatch }) => {
    dispatch(addNewFilterByData(data.data));
  },
);

export const counteractionPaletteAction = createAsyncThunk<void, IdInterface>(
  ReturnHistoryAction.COUNTERACTION_PALETTE,
  (data, { dispatch, getState }) => {
    const state = getState() as TState,
      paletteLinks = getPaletteLinks(state),
      newPaletteId = getPalettesAsArray(state).filter((palette) => data.id !== palette.id)[0]?.id;
    const updateLinksList = paletteLinks.filter(({ paletteId }) => paletteId === data.id);

    if (data.id && newPaletteId) {
      dispatch(returnRemovePaletteById({ id: data.id }));
      updateLinksList.forEach(({ themeId }) => dispatch(updatePaletteLinkAction({ themeId, paletteId: newPaletteId })));
    }
  },
);

export const repeatPaletteAction = createAsyncThunk<void, ReturnNewPaletteInterface>(
  ReturnHistoryAction.REPEAT_PALETTE,
  (data, { dispatch, getState }) => {
    const newData = data.data;
    const activeThemeId = getActiveThemeId(getState() as TState);

    if (activeThemeId && data.id) {
      dispatch(returnNewPalette({ id: newData.id, data: newData }));
      dispatch(updatePaletteLinkAction({ themeId: activeThemeId, paletteId: data.id }));
    }
  },
);

export const counteractionAddToLayerAction = createAsyncThunk<void, CounteractionAddToLayerActionInterface>(
  ReturnHistoryAction.COUNTERACTION_ADD_TO_LAYER,
  (data, { dispatch }) => {
    const newData = data.data;

    dispatch(returnRemoveVisualisationByIdAction({ id: newData.id, pageId: newData.pageId }));
  },
);

export const repeatAddToLayerAction = createAsyncThunk<void, ReturnVisualisationActionInterface>(
  ReturnHistoryAction.REPEAT_ADD_TO_LAYER,
  (data, { dispatch }) => {
    const newData = data.data;

    dispatch(addVisualisationByData(newData));
  },
);

export const returnRemoveVisualisationByIdAction = createAsyncThunk<void, ReturnRemoveVisualisationByIdActionInterface>(
  ReturnHistoryAction.RETURN_REMOVE_VISUALISATION_BY_ID,
  ({ id, pageId }, { dispatch }) => {
    dispatch(removeVisualisationById(id));
    dispatch(returnFromLayer({ id, pageId }));
    dispatch(removeAstOfVisualisationActions(id));
    dispatch(removeVisualisationValuesAction(id));
    dispatch(removeFromVisualisationLoadingListAction(id));
  },
);

export const counteractionThemeAction = createAsyncThunk<void, StepInterface>(
  ReturnHistoryAction.COUNTERACTION_THEME,
  (data, { dispatch }) => {
    if (data.id) {
      dispatch(returnRemoveThemeById({ id: data.id }));
    }
  },
);

export const repeatThemeAction = createAsyncThunk<void, ReturnNewThemeInterface>(
  ReturnHistoryAction.REPEAT_THEME,
  (data, { dispatch }) => {
    const newData = data.data;
    dispatch(returnNewTheme({ id: newData.id, data: newData }));
  },
);
